import { FormMode } from "components/common/CommonEnums";
import Coefficient from "../componests/Coeffcient";

export const initialState = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "general",
      title: "General",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "contactsPersons",
      title: "Contacts Persons",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "address",
      title: "Address",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "location",
      title: "Location",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "employeeTypeAndCoeffcient",
      title: "Employee & Coeffcient",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "coefficient",
      title: "Coefficient",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "specialAgreement",
      title: "Special Agreement",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "invoice",
      title: "Invoice",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  general: {
    data: {
      id: "",
      vat: "",
      businessUnitNumber: "",
      name: "",
      type: "",
      code: "",
      company: "",
      info: "",
      isActive: true,
      retentionCount:"",
      retentionPeriod:"",
    },
    errors: {
      vat: "",
      businessUnitNumber: "",
      name: "",
      type: "",
      code: "",
      company: "",
    },
  },
  contactsPersons: {
    data: {
      selectedContactPersons: [],
      contactSubformIsVisible: false,
      contactsPersons: [],
    },
    errors: {
      selectedContactPersons: "",
      contactsPersons: [],
    },
  },
  address: {
    data: {
      street: "",
      number: "",
      box: "",
      zipCode: "",
      country: null,
      city: "",
      biStreet: "",
      biNumber: "",
      biBox: "",
      biZipCode: "",
      biCountry: null,
      biCity: "",
      hqAddress: 0,
      sameAddress: 1,
    },
    errors: {
      street: "",
      number: "",
      zipCode: "",
      country: "",
      city: "",
      biStreet: "",
      biNumber: "",
      biZipCode: "",
      biCountry: "",
      biCity: "",
      hqAddress: "",
      sameAddress: "",
    },
  },
  location: {
    data: [
      {
        id: "",
        locationName: "",
        locationEmail: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCity: "",
        locationCountry: null,
      },
    ],
    errors: [
      {
        id: "",
        locationName: "",
        locationEmail: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCity: "",
        locationCountry: "",
      },
    ],
  },
  inVoice: {
    data: {
      paymentTerm: null,
      amount: "",
      inVoiceNumber: "",
      inVoiceDate: "",
      inVoiceDocumnet: "",
      inVoiceStatus: null,
    },
  },
  wageElement: [],
  employeeCoefficient: {
    whiteCollar: [],
    blueCollar: [],
    errors: {
      bluePc: "",
      blueEmployeeType: "",
      whitePc: "",
      whiteEmployeeType: "",
    },
  },
  companyParitairCommittee: [],
  currentTab: "general",
  coefficient:[],


  options: {
    businessUnit: [],
    tempAgencyTypes: [],
    company: [],
    locations: [],
    companyUsers: [],
    companyParitairCommittee: [],
    gender: [],
    language: [],
    roles: [],
    functions: [],
    templates: [],
    shifts: [],
    vatRate: [],
    billingRate: [],
    paymentTerm: [],
    InvoiceStatus: [],
    coefficient: [],
    countries: [],
    paritairCommitte: [],
    blueCollar: [],
    whiteCollar: [],
    whiteEmployeeType: [],
    blueEmployeeType: [],
    retentionPeriod:[],
    coefficientLevel:[],
  },
};
