import {
  ContactPersonTableHeader,
  ExcludeContactCompanyData,
  RoleTableHeader,
} from "TableHeader";
import SearchModel from "components/atoms/SearchModel";
import TableStructure from "components/atoms/TableStructure";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useEffect, useState, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import {
  CONTACT_PERSON_DATA,
  EDIT_CONTACT_PERSON,
  DELETE_CONTACT_PERSON,
  GET_COMPANY,
} from "routes/ApiEndpoints";
import { ERROR, M_COMPANY_CREATION } from "constants/Constants";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import Popup from "components/molecules/Popup";
import FormatData from "components/molecules/FormatData";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import { IconData } from "services/IconData";

interface ContactPerson {
  id?: number;
  name?: string;
  company?: string;
  location?:string;
  companyEmail?: string;
  companyPhoneNumber?: string;
  user_id: number;
}

interface ContactData {
  contactPerson: any;
  company: any;
}
const ManageContactPerson: React.FC = () => {
  // State to manage the message
  const [contactPerson, setContactPerson] = useState<ContactPerson[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const [searchData, setSearchData] = useState({
    contactPerson: queryParams.get("contactPerson") ?? "",
    company: queryParams.get("company") ?? "",
    email: queryParams.get("email") ?? "",
    phoneNumber: queryParams.get("phoneNumber") ?? "",
  });
  const [filterOptions, setFilterptions] = useState({
    companies: [],
  });

  //Modal popup for company details
  const [popup, setPopup] = useState({
    isModelOpen: false,
  });
  //set company details in modal
  const [modalData, setModalData] = useState<any>(null);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await ApiCall.getService(
          GET_COMPANY,
          "GET",
          M_COMPANY_CREATION,
          false
        );
        setFilterptions((prevState) => ({
          ...prevState,
          companies: response.company,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = {
          page: currentPage,
          search: searchData,
        };
        const response = await ApiCall.service(
          CONTACT_PERSON_DATA,
          "POST",
          postData,
          false,
          M_COMPANY_CREATION
        );
        setTotalPages(response?.totalPages);

        const modifiedContactPersons: ContactPerson[] = response.data.map(
          (person: ContactPerson) => ({
            ...person,
          })
        );
        setContactPerson(modifiedContactPersons);
      } catch (error) {
        console.error(ERROR, error);
      }
    };
    fetchData();
  }, [currentPage, submit]);

  const closeModal = () => {
    setPopup((prev) => ({ ...prev, isModelOpen: false }));
  };

  // Handle delete change
  const deleteContactPerson = (person: ContactPerson | any) => {
    setShowModal(true);
    setDeleteId(person.user_id);
  };

  // Handle edit change
  const editContactPerson = (person: ContactPerson | any) => {
    if (person.user_id) {
      navigate(`/create/contact-person/?edit=${person.user_id}`);
    }
  };

  const handleSearchChange = (event: any) => {
    const { name, value } = event.target;
    setSearchData((prevState) => ({ ...prevState, [name]: value }));
  };

  //Handle page Cahnges
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //handle close model
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete?"));
  };

  //handle archive changes
  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const url = `${DELETE_CONTACT_PERSON}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_COMPANY_CREATION);
      if (response.status === 403) {
        setModelBody(response.msg);
        setShowModal(true);
      } else if (response.status === 200) {
        const newContactPersonObj = contactPerson.filter(
          (item: ContactPerson) => item.user_id !== deleteID
        );
        setContactPerson(newContactPersonObj);
        CustomNotify({ type: "success", message: response.msg });
      } else {
        CustomNotify({ type: "error", message: response.msg });
      }
    }
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: ContactData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearchData({
        contactPerson: "",
        company: "",
        email: "",
        phoneNumber: "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(searchData);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const columns = ["name", "companyName","location" ,"companyEmail", "companyPhoneNumber"];
  const userData = useSelector(selectAuth);
  function handleClick(userData: any): void {
    navigate(`/create/contact-person/?edit=${userData.user_id}`);
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Contact person",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <div className="row header-sticky position-sticky">
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">
                  {t("Manage contact person")}
                </h1>
              </div>
            </div>
            <div className="row pb-3 search-bar">
              <div className="col-3 mb-3">
                <div className="">
                  <label className="form-label field-label">
                    {t("Contact person")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Search by Contact person name")}
                  name="contactPerson"
                  value={searchData.contactPerson}
                />
              </div>
              <div className="col-3">
                <div className="">
                  <label className="form-label field-label">
                    {t("Company")}
                  </label>
                </div>
                <select
                  className="form-select shadow-none"
                  name="company"
                  id="company"
                  onChange={handleSearchChange}
                  value={searchData.company}
                >
                  <option value="">{t("Select company")}</option>
                  {filterOptions.companies?.map((optn: any, key: any) => (
                    <option key={key} value={optn.value}>
                      {optn.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <div className="">
                  <label className="form-label field-label">
                    {t("Email")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Email")}
                  name="email"
                  value={searchData.email}
                />
              </div>
              <div className="col-3">
                <div className="">
                  <label className="form-label field-label">
                    {t("Phone number")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Phone number")}
                  name="phoneNumber"
                  value={searchData.phoneNumber}
                />
              </div>
              <div className="col mt-auto text-end">
                <Button
                  title={IconData.ResetIcon}
                  type="submit"
                  handleClick={(e) => handleSubmit(e, true)}
                  className="btn delete-btn  shadow-none text-uppercase me-3 "
                />
                <Button
                  title="Search"
                  type="submit"
                  handleClick={handleSubmit}
                  className="btn form-button  shadow-none text-uppercase px-3 search-btns"
                />
              </div>
              <div className="p-5 border rounded-4 table-custom mt-4">
                <div className="table-top-buttons-block">
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Contact person",
                        create: true,
                      },
                    ]}
                    override={userData.isSuperAdmin}
                  >
                    <Link
                      to="/create/contact-person"
                      className="table-button"
                    >
                      + {t("Create Contact Person")}
                    </Link>
                  </AccessControl>
                </div>
                <div className="table-responsive managetags">
                  <TableStructure
                    isAction
                    headers={ContactPersonTableHeader}
                    data={contactPerson}
                    values={columns}
                    handleEdit={editContactPerson}
                    handleDelete={deleteContactPerson}
                    handleClick={handleClick}
                    permissionType={"Contact person"}
                    clickStatus={true}
                  />
                </div>
                <div className="pagination justify-content-center align-items-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center my-4">
            <Link
              to="/manage-companies"
              className="text-uppercase back-btn text-decoration-underline"
            >
              Back
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={"Yes"}
        />
        {popup.isModelOpen && (
          <Popup
            title={t("Company Details")}
            body={
              <FormatData
                data={modalData}
                excludeData={ExcludeContactCompanyData}
              />
            }
            cancel={closeModal}
            modalSize="lg"
            notext="Close"
            cancelButtonClass="shadow-none float-end d-flex justify-content-center"
          />
        )}
      </>
    </AccessControl>
  );
};

export default ManageContactPerson;