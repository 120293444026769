import React from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import { DISABLED_DAYS, WEEK_DAYS } from "../context/Constants";
import ShiftColumn from "components/organism/projects/shiftOrganism/ShiftColumn";
import { calculateTotalTime } from "services/util/TotalTimeCalculator";
import { validateTimeRange } from "services/validation/ValidationService";
import { validate } from "../Utils";

const DaysOfWeekTable: React.FC<{
  selectedShift: any;
  tableIndex: number | string | undefined;
}> = ({ selectedShift, tableIndex }) => {
  const { state, dispatch } = useFormContext();

  const index = tableIndex ?? 1;

  const handleInputChange = (
    day: string,
    field: string,
    value: string,
    shiftName?: string | number
  ) => {
    // If the "Default" column is updated, apply changes to all days
    if (day === "Default") {
      WEEK_DAYS.forEach((day) => {
        dispatchUpdate(day, field, value);
        // setTimeout(() => updateTotal(day), 10); // Update total for each day
      });
    } else {
      tableIndex && validate(state, dispatch, day, {field, value}, tableIndex);
      dispatchUpdate(day, field, value);
      // setTimeout(() => updateTotal(day), 10); // Update total for the specific day
    }
  };

  // Function to dispatch update action
  const dispatchUpdate = (day: any, field: any, value: any) => {
    dispatch({
      type: "UPDATE_SHIFT_TIME",
      day: day,
      field: field,
      value: value,
      shiftName: tableIndex,
    });
  };

  // const updateTotal = (day: string) => {
  //   const shifts = state.shifts.data.shiftTable;
  //   const from = JSON.parse(JSON.stringify(shifts[index]?.[day]?.from));
  //   const till = shifts[index]?.[day].till;
  //   const pause = shifts[index]?.[day].pause;
  //   const totalValue = calculateTotalTime(from, till, pause);
  //   if (totalValue) {
  //     dispatch({
  //       type: "UPDATE_SHIFT_TIME",
  //       day: day,
  //       field: "total",
  //       value: totalValue,
  //       shiftName: tableIndex,
  //     });
  //   }
  // };
  const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
  };

  return (
    <div key={`${tableIndex}-projects-shift`}>
      <div className="d-flex text-center">
        <table style={tableStyle}>
          <thead>
            <tr>
              <td className="invisible py-2">#</td>
            </tr>
          </thead>
          <tbody>
            {["From", "Till", "Pause", "Total"].map((field) => (
              <tr key={`${tableIndex}-${field}`}>
                <td>{field}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {WEEK_DAYS.map((day) => (
          <table style={tableStyle} key={`${tableIndex}-${day}-table`}>
            <thead>
              <tr>
                <td className="py-2">{day}</td>
              </tr>
            </thead>
            <ShiftColumn
              key={`${tableIndex}-${day}`}
              day={day}
              error={tableIndex && state?.shifts?.errors?.shifts?.[tableIndex]?.[day]}
              disabled={DISABLED_DAYS?.includes(day)}
              shiftData={state?.shifts?.data?.shiftTable?.[index]?.[day] || {}}
              onInputChange={handleInputChange}
            />
          </table>
        ))}
      </div>
    </div>
  );
};

export default DaysOfWeekTable;
