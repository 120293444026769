import CustomNotify from "components/atoms/CustomNotify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import Reset from "static/images/ResetIcon";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Pagination from "components/atoms/Pagination";
import { Spinner } from "react-bootstrap";
import EditIcon from "static/images/EditIcon";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { GET_COEFFICIENT_DEVIATION } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { IconData } from "services/IconData";

interface manageData {
  name: string;
  code: string;
  id: number;
}

interface formDataProps {
  code: number | null;
  coeffDevname: string;
}

const ManageCoeffDeviation: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formDataProps>({
    code: null,
    coeffDevname: "",
  });

  const navigate = useNavigate();

  //state for to store the functions linked countries
  const [data, setData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const userData = useSelector(selectAuth);

  useEffect(() => {
    fetchDataBySearch();
  }, []);

  //API call for to fetch Employee types
  const fetchDataBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "coeffDeviation",
    };

    const response = await ApiCall.service(
      GET_COEFFICIENT_DEVIATION,
      "POST",
      requestData,
      false,
      M_MASTER_DATA
    );

    if (response.status === 200) {
      console.log(response.data);
      setData(response.data);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.coeffDevname.length === 0 && formData.code === null) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchDataBySearch();
    } else {
      fetchDataBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      code: null,
      coeffDevname: "",
    });
    setCurrentPage(1);
    fetchDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataBySearch(formData, newPage);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Coefficient deviation",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="px-4">
        <Title title={t("Coefficient deviations")} />
        <div className="form-height-dashboard">
          <div className="row  manage-function-search pb-3">
            <div className="col-xxl-9 col-xl-8 col-md-12 pb-3 pb-xl-0">
              <div className="row">
                <div className="col">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    name="code"
                    value={formData.code ?? ""}
                    label={t("Coefficient code")}
                    type="number"
                    errorclass="d-none"
                  />
                </div>
                <div className="col">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    name="coeffDevname"
                    value={formData.coeffDevname ?? ""}
                    label={t("Coefficient name")}
                    errorclass="d-none"
                  />
                </div>
              </div>
            </div>
            <div className="col text-end mt-auto">
              <Button
                title={IconData.ResetIcon}
                type="reset"
                handleClick={handleReset}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title={t("Search")}
                type="submit"
                icon={faMagnifyingGlass}
                handleClick={handleSubmit}
                className="btn form-button rounded-3 shadow-none  px-3 search-btns text-start position-relative"
              />
            </div>
          </div>
          
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4">{t("Code")}</th>
                    <th>{t("Name")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Coefficient deviation",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                      override={userData.isSuperAdmin}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="border-0">
                      <td
                        className="text-center border-0 spinner-wrapper"
                        colSpan={3}
                      >
                        <Spinner size="sm" className="me-2" /> {t("Loading")}
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data && data.length > 0 ? (
                        data.map((item) => (
                          <tr
                            key={item.id}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            {/* {/ <td className="ps-lg-4">{pageIndex++}</td> /} */}
                            <td className="ps-lg-4" data-label={t("Code")}>
                              {item.code}
                            </td>
                            <td data-label={t("Name")}>{t(`${item.name}`)}</td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Coefficient deviation",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              override={userData.isSuperAdmin}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Coefficient deviation",
                                      update: true,
                                    },
                                  ]}
                                  override={userData.isSuperAdmin}
                                  actions={true}
                                  strict={false}
                                >
                                  <div className="d-none d-md-none d-lg-block">
                                    <Button
                                      title={<EditIcon />}
                                      className={"table-action-btn"}
                                      handleClick={() => navigate(`/edit/coeff-deviation/${item.id}`)}
                                      tooltip={t("Edit")}
                                    />
                                  </div>
                                  {/* {/ PWA /} */}
                                  <div className="d-block d-md-block d-lg-none">
                                    <Link
                                      to={`/edit/coeff-deviation/${item.id}`}
                                      className="btn p-0 border-0 w-100"
                                      title={t("Edit")}
                                    >
                                      <Button
                                        title={t("Edit")}
                                        className=" mb-2 button-width edit-btn rounded-3"
                                      />
                                    </Link>
                                  </div>
                                </AccessControl>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <tr className="border rounded-3">
                          <td
                            colSpan={7}
                            className="border-0 text-center py-3 no-records"
                          >
                            <span className="text-danger  w-100 d-block text-center">
                              {t("No records")}
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 align-self-center my-4">
          <Link
            to=""
            className=" back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageCoeffDeviation;
