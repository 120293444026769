import LabelField from "../atoms/LabelField";
import React from "react";
import metadata from 'libphonenumber-js/metadata.full.json'; // Use the appropriate metadata file
import PhoneInputWithCountrySelect, {
  DefaultInputComponentProps,
} from "react-phone-number-input";
import { parsePhoneNumberFromString, CountryCode, E164Number } from "libphonenumber-js/core";

interface ILabelWithMobileFieldMolecule {
  label: string;
  name: string;
  isMandatory: boolean;
  value: string;
  error: string;
  handleChange(value?: string): void;

  placeHolder?: string;
  defaultCountry?: CountryCode;
  isInternational?: boolean;
  phoneInputProps?: DefaultInputComponentProps;
}

/**
 * Label with input for mobile and phone number with flag and country code prefix
 *
 * @example
 * const handlePhoneNumberChange = (
    phNumber: string | undefined,
    index?: number
  ) => {
    // Your code
  }
 * 
 * <LabelWithMobileFieldMolecule
  label={t("Phone number")}
  isMandatory={true}
  value={value ?? ""}
  error={error}
  handleChange={(value) => handlePhoneNumberChange(value, index)}
  isInternational={true}
  ></LabelWithMobileFieldMolecule>;
 *
 * @see https://catamphetamine.gitlab.io/react-phone-number-input/
 */
const LabelWithMobileFieldMolecule: React.FC<ILabelWithMobileFieldMolecule> = ({
  label,
  name,
  isMandatory,
  value,
  error,
  handleChange,
  placeHolder,
  phoneInputProps,
  defaultCountry = "BE",
  isInternational = false,
}) => {
   // Ensure value is in E164 format
   const formatToE164 = (phone: string, country: CountryCode): E164Number | undefined => {
    const parsedNumber = parsePhoneNumberFromString(phone, country, metadata);
    return parsedNumber ? parsedNumber.format('E.164') as E164Number : undefined;
  };
  const formattedValue = formatToE164(value, defaultCountry);
  return (
    <div className="col-md-12">
      <LabelField title={label} isMandatory={isMandatory} />
      <div className="form-control rounded-0">
        <PhoneInputWithCountrySelect
          name={name}
          initialValueFormat={phoneInputProps?.initialValueFormat}
          placeholder={placeHolder}
          defaultCountry={defaultCountry} // Belgium country code
          international={isInternational}
          value={formattedValue}
          onChange={handleChange}
        />
      </div>
      <div className="height-20 mb-2">
        {error && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );
};

export default LabelWithMobileFieldMolecule;
