import React from "react";
// import { FormProvider } from "pages/microservices/project/context/Context";
import { ProjectForm } from "pages/microservices/project/Index";
import TempAgencyForm from "pages/microservices/tempAgancy/Index";
import { FormProvider } from "pages/microservices/tempAgancy/context/Context";

export const TempAgencyFormProvider = () => {
  return (
    <>
      <FormProvider>
        <TempAgencyForm />
      </FormProvider>
    </>
  );
};
