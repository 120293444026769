import React from "react";

interface TitleAtomProps {
  title?: string;
}

const TitleAtom: React.FC<TitleAtomProps> = ({ title }) => {
  return (
    <div className="col-md-12">
      <h1 className="py-4 page-title mb-0">{title}</h1>
    </div>
  );
};
export default TitleAtom;
