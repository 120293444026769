import { s3, S3_BUCKET_NAME, S3_FOLDER } from "config/awsConfig";

export const handleSingleFileUpload = async (
  file: File,
  dirStructure: string
): Promise<{ fileName: string; accessUrl: string } | null> => {
  try {
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `${S3_FOLDER}/${dirStructure}/${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    const data = await s3.upload(params).promise();
    console.log("File uploaded successfully:", data.Location);

    const accessUrl = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/${S3_FOLDER}/${dirStructure}/${encodeURIComponent(
      file.name
    )}`;

    return { fileName: file.name, accessUrl };
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};

export const handleMultipleFileUpload = async (
  files: File[],
  dirStructure: string
): Promise<Array<{ fileName: string; accessUrl: string }> | null> => {
  const uploadedFilesData: Array<{ fileName: string; accessUrl: string }> = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const uploadResult = await handleSingleFileUpload(file, dirStructure);
    if (uploadResult) {
      uploadedFilesData.push(uploadResult);
    }
  }

  return uploadedFilesData.length > 0 ? uploadedFilesData : null;
};
