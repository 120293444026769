import React, { useState } from "react";
import SearchModel from "components/atoms/SearchModel";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ButtonWithChildren from "components/atoms/ButtonWithChildren";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { DELETE_ROLE } from "routes/ApiEndpoints";
import "static/css/roles.css";
import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { RoleTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface RolesType {
  id?: number;
  name?: string;
}

interface Props {
  rolesObj: RolesType[];
}

const ManageRolesPage: React.FC<Props> = ({ rolesObj }) => {
  const [roles, setRoles] = useState(rolesObj);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Filter the data based on the search query
  const filteredData = roles.filter((item: RolesType) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  const deleteRole = (RolesType: RolesType | any) => {
    setShowModal(true);
    setDeleteId(RolesType.id);
  };

  const EditRole = (RolesType: RolesType | any) => {
    if (RolesType.id) {
      navigate(`/role/edit/${RolesType.id}`)
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete?"));
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    let body = {
      roleId: deleteID,
      force: false,
    };
    if (modalBody !== t("Are you sure want to delete?")) {
      body.force = true;
    }
    const response = await ApiCall.service(DELETE_ROLE, "POST", body);
    if (response.status === 403) {
      setModelBody(response.msg);
      setShowModal(true);
    } else if (response.status === 200) {
      const newRolesObj = roles.filter((item: RolesType) => item.id !== deleteID);
      setRoles(newRolesObj);
      CustomNotify({ type: "success", message: response.msg });
    } else {
      CustomNotify({ type: "error", message: response.msg });
    }
  };

  const columns = ['name'];
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Role",
        read: true,
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <div className="row header-sticky position-sticky">
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">{t("Manage roles")}</h1>
              </div>
            </div>
            <div className="row pb-3 search-bar">

              <div className="col-8">
                <SearchModel
                  className="form-control"
                  placeHolder="Search by name..."
                  onChange={handleSearchChange}
                />
              </div>

            </div>
            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                  <AccessControl
                    requiredPermissions={[{
                      permission: "Role",
                      create: true,
                    }]}
                    override={userData.isSuperAdmin}
                  >
                    <Link
                      to="/role/create"
                      className="table-button"
                    >
                      + {t("Create role")}
                    </Link>
                  </AccessControl>
                </div>
              <div className="table-responsive manage-roles">
                <TableStructure
                  isAction
                  headers={RoleTableHeader}
                  data={currentItems}
                  values={columns}
                  handleEdit={EditRole}
                  handleDelete={deleteRole}
                  permissionType="Role"
                />
                <div className="pagination justify-content-center align-items-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 align-self-center my-4">
            <Link to="/" className='text-uppercase back-btn text-decoration-underline'>{t("Back")}</Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageRolesPage;
