
export const initialFilterProps = {
    state: {
        companies: [],
        projects: [],
        businessUnits: [],
        employeeTypes: [],
        companyProjects: [],
        projectBusinessUnits: [],
        shifts: [],
        employees: [],
        projectlist: [],
        holidaycode: []
    },
    filteredState: {
        company_id: [],
        project_id: [],
        businessUnits: [],
        employeeTypes: [],
        startdate: "",
        enddate: "",
        employeesid: [],
        salesview: null
    }
}
