import AccessControl from "services/AccessControl";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { getTiles } from "./DashboardConstants";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import CardTitle from "components/atoms/CardTitle";
import CardCircle from "components/atoms/CardCircle";

const HomePage: React.FC = () => {
  const userData = useSelector(selectAuth);
  const tiles = getTiles(userData);
  return (
    <>
      <div className="row">
        <div className="col-md-12 dashboard-tiles">
          {tiles &&
            tiles.map((tab, index) => (
              <AccessControl
                key={index}
                requiredPermissions={tab?.requiredPermissions}
                override={userData.isSuperAdmin}
              >
                <Link to={tab.path} className="dashboard-tiles-block rounded-3">
                  <RecruitmentCard
                    className={`d-flex justify-content-between align-items-center w-100 h-100`}
                  >
                    <CardTitle
                      title={tab.name}
                      className={`dashboard-card-title`}
                    />
                    <CardCircle
                      width={60}
                      height={60}
                      backgroundColor={"var(--sideBarBgColor)"}
                      boxShadow="rgb(0, 165, 206) 0px 2px 8px 0px"
                      className="float-end justify-content-center hoverShadow"
                    >
                      {tab?.icon}
                    </CardCircle>
                  </RecruitmentCard>
                  {/* <div className="tab">{t(tab.name)}</div> */}
                </Link>
              </AccessControl>
            ))}
        </div>
      </div>
    </>
  );
};

export default HomePage;
