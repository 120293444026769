import React, { useEffect, useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';



interface LeaveTreeTableProps {
  data: any[];
}

const LeaveTreeTable: React.FC<LeaveTreeTableProps> = ({ data }) => {

  return (
    <div className="">
      <TreeTable  value={data} selectionMode="single"  tableStyle={{ width: '100%' }} className='p-0 tableHeader'>
        <Column expander style={{ width: '3em' }} className='py-4 text-center'  headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}} />
        <Column field="employee_name" header="Employee name" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}/>
        <Column field="leaves" header="Holiday code" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}></Column>
        <Column field="category" header="Unit" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}></Column>
        <Column field="all" header="All" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}></Column>
        <Column field="used" header="Used" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}></Column>
        <Column field="requested" header="Requested" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}></Column>
        <Column field="balance" header="Balance" headerStyle={{backgroundColor:"#e3e6e8",fontSize:"18px",fontFamily:"vfutura-heavy"}}></Column>
      </TreeTable>
    </div>
  );
};

export default LeaveTreeTable;
