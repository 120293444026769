import React, { useContext, createContext, useReducer, useState } from "react";
import { employeeReducer } from "../../reducers/EmployeeReducer/EmployeeReducer";

const EmployeeContext = createContext(null);
const EmployeeDispatchContext = createContext(null);
const initialEmployeeProps = {
  employees: [],
  employee_id: null
};
export function EmployeeProvider({ children }) {
  const [initialEmployeeState, dispatchEmployee] = useReducer(
    employeeReducer,
    initialEmployeeProps
  );

  return (
    <EmployeeContext.Provider value={{ initialEmployeeState }}>
      <EmployeeDispatchContext.Provider value={{ dispatchEmployee }}>
        {children}
      </EmployeeDispatchContext.Provider>
    </EmployeeContext.Provider>
  );
}

export function useEmployee() {
  return useContext(EmployeeContext);
}

export function useEmployeeDispatch() {
  return useContext(EmployeeDispatchContext);
}
