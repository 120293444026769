import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import ManageUsersPage from "./ManageUsersPage";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { GET_USERS } from "routes/ApiEndpoints";

export interface UserData {
  user_id?: number;
  email: string;
  status: any;
  first_name: string;
  last_name: string;
  role: string;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: UserData;
  initialCall?: boolean;
}

const ManageUsers: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const statusValue = queryParams.get("status");
  const status: number | string = statusValue !== null ? parseInt(statusValue) : '';
  const [users, setUsers] = useState<UserData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState<UserData>({
    first_name: queryParams.get("first_name") ?? "",
    last_name: queryParams.get("last_name") ?? "",
    email: queryParams.get("email") ?? "",
    role: queryParams.get("role") ?? "",
    status: status, 
  });
  const [options, setOptions] = useState({
    roles: [],
    initialCall: true
  });
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
      initialCall: options?.initialCall
    };

    fetchUsers(data);
  }, [currentPage, submit]);

  const fetchUsers = async (data: DataProps) => {
    const response = await ApiCall.service(GET_USERS, "POST", data);
    if (response?.status === 200) {      
      setUsers(response.data);
      setTotalPages(response.totalPages);
      if (options?.initialCall && response?.options) {
        setOptions((prev)=> ({ ...prev, roles: response?.options, initialCall: false }))
      }
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleDeleteData = (deleteId: number) => {
    setUsers((prevdata) => prevdata.filter((users) => users.user_id !== deleteId));
    setCurrentPage(1);
  }

  // Function to create query parameters from the search object
  const createQueryParams = (data: UserData) => {    
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    return queryParams;
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" })
      setSearch({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        status: '',
      });
    }

    if ((createQueryParams(search)) !== '' && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: 'Search input not provided.' });
    }
    setSubmit(!submit);
    setCurrentPage(1);
  }

  return (
    <>
      <ManageUsersPage
        usersObj={users}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        searchValue={handleSearch}
        deleteFilter={handleDeleteData}
        handleSubmitAction={handleSubmit}
        searchData={search}
        options={options}
      />
    </>
  );
};

export default ManageUsers;
