import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA } from "constants/Constants";
import { GET_PC_CATEGORIES, GET_SALARY_BENEFITS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";

export const updateCategoryFunctions = async (payload: any, id: any, dataObj: any) => {
  let { state, fieldData } = payload;
  if (id !== null) {
    try {
      const response = await getOptions(id);
      if (response.status == 200) {
        return { ...state, options: response.data, value: fieldData?.value }
      }
      else {
        CustomNotify({ type: "error", message: response.message })
      }
    }
    catch (error) {
      console.log('error');
    }
  }
  return state;
}

export const getOptions = (fieldValue: any) => {
  return ApiCall.service(
    GET_PC_CATEGORIES,
    'POST',
    { id: fieldValue },
    false,
    M_MASTER_DATA,
  )
    .then((res: any) => {
      return res;
    })
    .catch((error: any) => {
      console.error(error);
      throw error;  // Re-throw the error after logging it
    });
};


export const fetchSalaryBenefit = async (payload: any) => {
  const { fieldData } = payload;
  const options = fieldData.value;

  try {
    let id = options.map((item: any) => item.value)
    if (id.length != 0) {
      const response = await ApiCall.service(
        GET_SALARY_BENEFITS,
        "POST",
        { id },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        return response.data;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

// let newState = state.map((item: any, index: number) => {
//   if (index == fieldData?.mainIndex) {
//     item.options = response.data;
//     return item;
//   }
//   return item;
// });
// return newState;

export const getEmployeeTypes = async (payload:any) =>{
  const { fieldData, state } = payload;
  const updatedItems = state.map((item: any) => {
    if (item?.dependencyAction === "employeeType") {
      return {
        ...item, // Copy the existing field
        value: fieldData?.value, // Update the value
        error: fieldData?.value ? "" : item.error // Update error if value is present
      };
    }
    // If the condition is not met, return the item unchanged
    return item;
  });
  return updatedItems;
}
