import { t } from 'pages/microservices/masterData/translation/Translation';
import React from 'react';

interface Header {
    name: string;
    alias: string;
    width: number;
    span?: number;
}

interface RowHeading {
    alias: string;
    span: number;
    value: string;
}

interface DataRow {
    id: number | string;
    rowHeadings?: RowHeading[];
    [key: string]: any;
}

interface DynamicTableProps {
    tableWidth: number;
    headers: Header[];
    data: DataRow[][];
    mainclass?: string;
}

const DynamicTable: React.FC<DynamicTableProps> = ({ tableWidth, headers, data, mainclass }) => {
    const renderHeader = () => (
        <tr>
            {headers.map((header, index) => (
                <th
                    key={index}
                    className='border-bottom'
                    colSpan={header.span || 1}
                    style={{width: `${header.width}%`}}
                >
                    {t(header.name)}
                </th>
            ))}
        </tr>
    );

    const renderRow = (row: DataRow) => {
        let skipCount = 0;
        return (
            <tr key={row.id}>
                {headers?.map((header, index) => {
                    if (skipCount > 0) {
                        skipCount--;
                        return null;
                    }
                    const cellKey = `${row?.id}-${index}`;
                    const heading = row?.rowHeadings?.find(h => h?.alias === header?.alias);
                    if (heading) {
                        skipCount = heading?.span - 1;
                        return (
                            <th
                                key={cellKey}
                                className='text-break ps-3 border'
                                colSpan={heading?.span}
                            >
                                {t(heading?.value)}
                            </th>
                        );
                    }
                    if (row?.hasOwnProperty(header?.alias)) {
                        return (
                            <td
                                key={cellKey}
                                className='text-break ps-3 border'
                                colSpan={header?.span || 1}
                                style={{ width: `${header?.width}%`}}
                            >
                                {row[header?.alias]}
                            </td>
                        );
                    }
                    return <td
                        key={cellKey}
                        className='ps-3 border'
                        colSpan={header?.span || 1}
                        style={{ width: `${header?.width}%`}}
                    />;
                })}
            </tr>
        );
    };

    return (
        <table className={`table-responsive manage-users mt-2 table ${mainclass}`} style={{ width: `${tableWidth}%`, borderCollapse: 'collapse', textAlign: 'center', verticalAlign: 'middle' }}>
            <thead className='TableHeader'>{renderHeader()}</thead>
                <tbody>
                    {data?.map((dataSet, index) => (
                        <React.Fragment key={index}>
                            {dataSet?.map((row) => renderRow(row))}
                            {index < data?.length - 1 && (
                                <tr>
                                    <td colSpan={headers?.length} style={{ height: '10px' }}></td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
        </table>
    );
};

export default DynamicTable;
