// ContactForm.tsx
import React from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import {
  ResponsiblePerson,
  ResponsiblePersonErrors,
} from "pages/microservices/project/context/Interfaces";
import { t } from "pages/microservices/masterData/translation/Translation";

interface ContactFormProps {
  onSelectChange: (e: any, field: string) => void;
  companyUsers: Option[];
  responsiblePersonData: ResponsiblePerson;
  responsiblePersonErrors: ResponsiblePersonErrors;
}

const ResponsiblePersonForm: React.FC<ContactFormProps> = ({
  onSelectChange,
  companyUsers,
  responsiblePersonData,
  responsiblePersonErrors,
}) => {
  const data = responsiblePersonData;
  const error = responsiblePersonErrors;

  return (
    <form>
      <div className="create-form-height-company form-border p-5 mt-3">
        <div className="">
          <div className="field-set">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Time sheet")}
                  name="timeSheet"
                  isMandatory={true}
                  search={true}
                  options={companyUsers}
                  placeHolder={t("Select")}
                  value={data.timeSheet}
                  onChange={(e) => onSelectChange(e, "timeSheet")}
                  isMulti={true}
                  className="select-field"
                  error={error.timeSheet}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Billing")}
                  name="billing"
                  isMandatory={true}
                  search={true}
                  options={companyUsers}
                  placeHolder={t("Select")}
                  value={data.billing}
                  onChange={(e) => onSelectChange(e, "billing")}
                  isMulti={true}
                  className="select-field"
                  error={error?.billing}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Leave")}
                  name="leave"
                  isMandatory={true}
                  search={true}
                  options={companyUsers}
                  placeHolder={t("Select")}
                  value={data.leave}
                  onChange={(e) => onSelectChange(e, "leave")}
                  isMulti={true}
                  className="select-field"
                  error={error.leave}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Sickness")}
                  name="sickness"
                  isMandatory={true}
                  search={true}
                  options={companyUsers}
                  placeHolder={t("Select")}
                  value={data.sickness}
                  onChange={(e) => onSelectChange(e, "sickness")}
                  isMulti={true}
                  className="select-field"
                  error={error.sickness}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Holiday")}
                  name="holiday"
                  isMandatory={true}
                  search={true}
                  options={companyUsers}
                  placeHolder={t("Select")}
                  value={data.holiday}
                  onChange={(e) => onSelectChange(e, "holiday")}
                  isMulti={true}
                  className="select-field"
                  error={error.holiday}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Safety")}
                  name="safety"
                  isMandatory={true}
                  search={true}
                  options={companyUsers}
                  placeHolder={t("Select")}
                  value={data.safety}
                  onChange={(e) => onSelectChange(e, "safety")}
                  isMulti={true}
                  className="select-field"
                  error={error.safety}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResponsiblePersonForm;
