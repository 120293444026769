import React, { useRef, useState, useCallback } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LabelField from "../atoms/LabelField";
import "static/css/calender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

export interface CalenderProps {
  onChange: (date: Date | null, name: string) => void;
  selectedDate?: Date | string | null;
  label?: string;
  isMandatory?: boolean;
  name?: string;
  error?: string | undefined | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  isDisabled?: boolean;
  placeHolder?: string;
  pickerType?: "date" | "month" | "year";
  classname?: string;
}

const Calender: React.FC<CalenderProps> = ({
  onChange,
  selectedDate,
  label,
  isMandatory = false,
  name = "",
  error,
  minDate = null,
  maxDate = null,
  isDisabled = false,
  placeHolder,
  pickerType = "date",
  classname,
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);
  const [currentPickerType, setCurrentPickerType] = useState(pickerType);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const setSelectedValue = (
    value: Date | string | null | undefined
  ): Date | null => {
    if (value) {
      if (value instanceof Date) {
        return value;
      } else if (typeof value === "string") {
        return new Date(value);
      }
    }
    return null;
  };

  const handleDateChange = (date: Date | null) => {
    // setCurrentPickerType(pickerType);
    onChange(date, name || "");
  };

  let dateFormat = "dd-MM-yyyy";
  let showMonthYearPicker = false;
  let showYearPicker = false;

  switch (currentPickerType) {
    case "month":
      dateFormat = "MM-yyyy";
      showMonthYearPicker = true;
      break;
    case "year":
      dateFormat = "yyyy";
      showYearPicker = true;
      break;
    default:
      dateFormat = "dd-MM-yyyy";
      break;
  }

  return (
    <>
      {label && <LabelField title={label} isMandatory={isMandatory} />}
      <div className="position-relative">
        <ReactDatePicker
          selected={setSelectedValue(selectedDate)}
          onChange={handleDateChange}
          onKeyDown={(e: any) => {
            if (
              e.target.value.length >= dateFormat?.length &&
              e.key !== "Backspace" &&
              e.key !== "Delete"
            ) {
              e.preventDefault();
            }
          }}
          showMonthDropdown={!showYearPicker}
          showYearDropdown
          dropdownMode="select"
          dateFormat={dateFormat}
          className={`form-control shadow-none ps-2 position-relative ${classname}`}
          placeholderText={placeHolder ?? dateFormat}
          minDate={minDate ? new Date(minDate.getTime() + 86400000) : null}
          maxDate={maxDate ? new Date(maxDate.getTime() - 86400000) : null}
          calendarStartDay={1}
          name={name}
          // customInput={
          //   <input
          //     onBeforeInput={(e) => {
          //       e.preventDefault();
          //     }}
          //   />
          // }
          closeOnScroll={true}
          fixedHeight
          autoComplete="off"
          ref={datePickerRef}
          disabled={isDisabled}
          showMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
        />
        <div className="input-group-append calender-icon position-absolute">
          <span
            className="input-group-text bg-transparent border-0"
            onClick={handleIconClick}
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-lg" />
          </span>
        </div>
      </div>

      <div className="mb-2 height-20">
        {error && <span className="text-danger mt-2">{error}</span>}
      </div>
    </>
  );
};

export default Calender;
