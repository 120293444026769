import React from "react";
import FormSubTitle from "components/atoms/FormSubTitle";

interface FormSectionTitleProps {
  title?: string;
}

const FormSectionTitle: React.FC<FormSectionTitleProps> = ({ title }) => {
  return <div className="row">
    <FormSubTitle title = {title} />
  </div>;
};
export default FormSectionTitle;