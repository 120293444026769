export const Days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const MarginParameter = [
    {
        id: 'statute',
        name: 'Statute',
    },
    {
        id: 'employee',
        name: 'Employee',
    },
    {
        id: 'dare',
        name: 'Dare',
    },
    {
        id: 'material',
        name: 'Material'
    },
    {
        id: 'dynamic',
        name: 'Dynamic'
    }
];

export const MarginMapping: any = {
    statute: ['employee'],
    employee: ['statute'],
    shifts: ['durationAndAllowance'],
}

export const ProjectDependency: any = {
    project: ['projectParameters', 'timeTable'],
}

export const EmployeeTypeDependency: any = {
    statute: ['employee', 'dare', 'material', 'dynamic']
}

export const DurationAndAllowance = [
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'
    },
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'
    },
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'
    },
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'

    },
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'
    },
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'
    },
    {
        hours: '0,00',
        projectAllowance: '',
        technicianAllowance: '',
        startTime: '00:00',
        endTime: '00:00',
        pauseTime: '00:00',
        projectAllowanceHours: '',
        technicianAllowanceHours: '',
        revenue: '0,00',
        cost: '0,00',
        marginCost: '0,00',
        marginPercent: '0,00%'
    },
];
