import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import CustomNotify from "components/atoms/CustomNotify";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import { MANAGE_PUBLIC_HOLIDAYS } from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  EDIT_HOLIDAY,
  GET_OPTIONS_FOR_HOLIDAY,
  SAVE_HOLIDAY,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";

interface CreateHoliday {
  id: string;
  holiday_title: string;
  holiday_code: any;
  holiday_date: any;
  country: any;
  region: string;
  isHolidayRepeat?: boolean;
}

interface HolidayErrors {
  holiday_title: string;
  holiday_code: string;
  holiday_date: string;
  country: string;
  region: string;
  isHolidayRepeat?: string;
}

const CreateHoliday = () => {
  const [holidayData, setHolidayData] = useState<CreateHoliday>({
    id: "",
    holiday_title: "",
    holiday_code: "",
    holiday_date: "",
    country: "",
    region: "",
    isHolidayRepeat: false,
  });

  const [holidayDataError, setHolidayDataError] = useState<HolidayErrors>({
    holiday_title: "",
    holiday_code: "",
    holiday_date: "",
    country: "",
    region: "",
  });
  const navigate = useNavigate();
  const [holidayDetails, setHolidayDetails] = useState<any>([]);
  const [editIndex, setEditIndex] = useState(null);
  const { id: id } = useParams();
  const [holidayOptions, setHolidayOptions] = useState({
    holiday_codes: [],
    countries: [],
  });

  useEffect(() => {
    fetchOptions();
    fetchData();
  }, []);

  const fetchData = async () => {
    if (id) {
      try {
        const response = await ApiCall.service(
          EDIT_HOLIDAY,
          "POST",
          { id: id },
          false,
          M_MASTER_DATA
        );
        if (response?.status == 200) {
          const holidayDataFromResponse = response?.data;
          setHolidayData({
            id: id || "",
            holiday_title: holidayDataFromResponse.holiday_name || "",
            holiday_code:
              holidayDataFromResponse.holiday_code || "",
            holiday_date: holidayDataFromResponse.date || "",
            country: holidayDataFromResponse.country || "",
            region: holidayDataFromResponse.region || "",
            isHolidayRepeat: holidayDataFromResponse.repeat_per_year || false,
          });
        }
      } catch (error) {
        console.error("Error");
      }
    }
  };

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_OPTIONS_FOR_HOLIDAY,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        setHolidayOptions({
          holiday_codes: response?.data?.holidayCode || [],
          countries: response?.data?.country || [],
        });
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    setHolidayData((prevHolidayData) => ({
      ...prevHolidayData,
      [name]: checked ? checked : value,
    }));
    if (name !== 'isHolidayRepeat') {
      validationFunction(name, value, true);
    }
  };

  const handleSelectChange = (event: any, category: string) => {
    setHolidayData((prevHolidayData) => ({
      ...prevHolidayData,
      [category]: event,
    }));
    validationFunction(category, event, true);
  };

  const handleDateChange = (event: any, categoryName: string) => {
    if (event) {
      const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
        ? "0" + (event.getMonth() + 1)
        : event.getMonth() + 1
        }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
      setHolidayData((prevState) => ({ ...prevState, [categoryName]: date }));
      validationFunction(categoryName, date, true);
    }
  };

  const handleAddAnotherEvent = () => {
    if (validationFunction()) {
      setHolidayDetails((prevHolidayDetails: any) => [
        ...prevHolidayDetails,
        holidayData,
      ]);
      setHolidayData((prevHolidayData) => ({
        ...prevHolidayData,
        holiday_title: "",
        holiday_code: "",
        holiday_date: "",
        country: "",
        region: "",
        isHolidayRepeat: false,
      }));
      setHolidayDataError((prevErrorData) => ({
        ...prevErrorData,
        holiday_title: "",
        holiday_code: "",
        holiday_date: "",
        country: "",
        region: "",
      }));
    }
  };

  const validationRules: any = {
    holiday_title: [validateRequired],
    holiday_code: [validateSelectField],
    holiday_date: [validateRequired],
    country: [validateSelectField],
    region: [validateRequired],
  };

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationErrors: any = validateForm(
      { ...holidayData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setHolidayDataError((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setHolidayDataError(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      }
      else {
        return true;
      }
    }
  };

  const handleEdit = (entryId: any) => {
    setHolidayData((prevState) => ({
      ...prevState,
      ...holidayDetails[entryId],
    }));
    setEditIndex(entryId);
    setHolidayDataError({
      holiday_title: "",
      holiday_code: "",
      holiday_date: "",
      country: "",
      region: "",
    });
  };

  const handleArchive = (deleteId: number) => {
    setHolidayDetails((prevState: any) => {
      const filteredData = prevState.filter(
        (eachHolidayData: any, index: number) => index !== deleteId
      );
      return filteredData;
    });
  };

  const handleSaveParticularData = () => {
    validationFunction();
    setHolidayDetails((prevstate: any) => {
      const updatedData = prevstate.map(
        (eachHolidayData: any, index: number) => {
          if (index === editIndex) {
            return { ...eachHolidayData, ...holidayData };
          }
          return eachHolidayData;
        }
      );
      return updatedData;
    });
    setHolidayData((prevData) => ({
      ...prevData,
      holiday_title: "",
      holiday_code: "",
      holiday_date: "",
      country: "",
      region: "",
      isHolidayRepeat: false,
    }));
    setEditIndex(null);
  };

  const handleOnSubmit = async () => {
    if (validationFunction()) {
      let finalHolidayCodes = null;
      if (holidayDetails.length === 0) {
        finalHolidayCodes = [holidayData];
      } else if (editIndex === null) {
        finalHolidayCodes = [...holidayDetails, holidayData];
      }
      else {
        finalHolidayCodes = [...holidayDetails];
      }
      try {
        const response = await ApiCall.service(
          SAVE_HOLIDAY,
          "POST",
          { data: finalHolidayCodes },
          false,
          M_MASTER_DATA
        );
        if (response.status === 200) {
          CustomNotify({ type: "success", message: response.message });
          navigate(MANAGE_PUBLIC_HOLIDAYS);
        } else {
          CustomNotify({ type: "error", message: response.message });
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  return (
    <>
      <TitleFieldMolecule
        title={
          id !== undefined
            ? t("Edit public holiday")
            : t("Create public holiday")
        }
      />
      <div className="form-height">
        <div className="form-border p-5 mb-4">
          <form>
            <div className="row">
              <div className="col-6">
                <LabelWithInputField
                  type="text"
                  placeholder="Enter holiday title"
                  handleChange={handleChange}
                  label={t("Holiday title")}
                  value={holidayData.holiday_title}
                  isMandatory={true}
                  name={"holiday_title"}
                  id={"holiday_title"}
                  error={holidayDataError.holiday_title}
                />
              </div>
              <div className="col-6">
                <SelectWithSearch
                  onChange={(event) => {
                    handleSelectChange(event, "holiday_code");
                  }}
                  title={t("Holiday code")}
                  placeHolder={t("Select holiday codes")}
                  search={true}
                  options={holidayOptions.holiday_codes}
                  value={holidayData.holiday_code}
                  isDisabled={false}
                  isMandatory={true}
                  error={holidayDataError.holiday_code}
                  name={"holiday_code"}
                  id={"holiday_code"}
                />
              </div>
              <div className="col-6 position-relative">
                <Calender
                  onChange={(event) => {
                    handleDateChange(event, "holiday_date");
                  }}
                  selectedDate={holidayData.holiday_date}
                  label={t("Date")}
                  isMandatory={true}
                  name={"holiday_date"}
                  error={holidayDataError.holiday_date}
                  isDisabled={false}
                  minDate={new Date()}
                />
              </div>
              <div className="col-6">
                <SelectWithSearch
                  onChange={(event) => {
                    handleSelectChange(event, "country");
                  }}
                  title={t("Country")}
                  placeHolder={t("Select country")}
                  search={true}
                  options={holidayOptions.countries}
                  value={holidayData.country}
                  isMulti={true}
                  isDisabled={false}
                  isMandatory={true}
                  error={holidayDataError.country}
                  name={"country"}
                  id={"country"}
                />
              </div>
              <div className="col-6">
                <LabelWithInputField
                  type="text"
                  placeholder={t("Enter region")}
                  handleChange={handleChange}
                  label={t("Region")}
                  value={holidayData.region}
                  isMandatory={true}
                  name={"region"}
                  id={"region"}
                  error={holidayDataError.region}
                />
              </div>
              <div className="col-12">
                <CheckBoxField
                  label={t(
                    "Check if this holiday repeats every year with same date"
                  )}
                  name={"isHolidayRepeat"}
                  id={"isHolidayRepeat"}
                  isChecked={holidayData.isHolidayRepeat}
                  onChangeHandler={handleChange}
                />
              </div>
            </div>
            {id === undefined && (
              <div className="col-md-12 text-lg-end text">
                <Button
                  title={editIndex !== null ? t("Save") : t("+ Add another")}
                  type={"button"}
                  handleClick={
                    editIndex !== null
                      ? handleSaveParticularData
                      : handleAddAnotherEvent
                  }
                  className={
                    "btn form-button  text-uppercase rounded-0 shadow-none"
                  }
                />
              </div>
            )}
          </form>
          {holidayDetails.length > 0 && (
            <table className="table table-hover">
              <thead className="TableHeader">
                <tr>
                  <th>{t("Holiday title")}</th>
                  <th>{"Holiday codes"}</th>
                  <th>{"Date"}</th>
                  <th>{"Actions"}</th>
                </tr>
              </thead>
              <tbody>
                {holidayDetails.map((eachHolidayData: any, index: number) => (
                  <tr key={index}>
                    <td>{eachHolidayData.holiday_title}</td>
                    <td>{eachHolidayData.holiday_code.name}</td>
                    <td>{eachHolidayData.holiday_date}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleEdit(index)}
                        style={{
                          cursor: "pointer",
                          color: "#808080",
                          marginRight: 10,
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("Edit")}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleArchive(index)}
                        style={{ cursor: "pointer", color: "#808080" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("Delete")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between my-3 align-items-center">
        <div className="">
          <Link
            to={MANAGE_PUBLIC_HOLIDAYS}
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>

        <div className="">
          <Button
            title={id ? t("Save") : t("Submit")}
            type={"submit"}
            handleClick={handleOnSubmit}
            className={"btn form-button  text-uppercase rounded-0 shadow-none"}
          />
        </div>
      </div>
    </>
  );
};
export default CreateHoliday;
