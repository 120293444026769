import { EMPLOYEE_ACTIONS } from "../../actions/EmployeesActions"
import {updateObject} from '../../../utils/utils';

export const employeeReducer = (employees, actions) => {
    switch(actions.type){
        case EMPLOYEE_ACTIONS.SET_EMPLOYEE: 
            {return set_employee(employees, actions);}
       
        default:
            throw Error('Unknown action: ' + actions.type);
    }
}

function set_employee(employees, actions){
    return updateObject(employees, actions.payload);
}
