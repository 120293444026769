import React, { ReactNode, useEffect, useRef, useState } from 'react';
import WorkPayBillRollFilters from './organisms/WorkPayBillRollFilters';
import { t } from '../masterData/translation/Translation';
import { MANAGE_PAYROLL_CONSTANTS } from './constants/WorkPayBillRollConstants';
import { useReactToPrint } from 'react-to-print';
import { useFilterOptions, useFilterOptionsDispatch } from './work-pay-bill-roll-redux/context/FilterOptionsContext';
import { getDate, handleFilterChange, handleTableDataChange } from './utils/Utils';
import { useNavigate } from 'react-router-dom';
import { useTable, useTableDispatch } from './work-pay-bill-roll-redux/context/TableContext';
import generatePDF, { Margin, Options, Resolution } from "react-to-pdf";
import { getManageData } from './utils/ApiCalls';

interface PageLayoutProps {
    children: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    const filterState = useFilterOptions();
    const filterDispatch = useFilterOptionsDispatch();
    const tableState = useTable();
    const tableDispatch = useTableDispatch();
    const componentRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const getFileName = () => {
        return tableState?.overview
        ? `${t("Payroll of")} ${tableState?.overview?.business_unit} - ${tableState?.overview?.employee} ${t("on")} ${getDate(filterState?.selected_date, { month: 'short', year: 'numeric' })}`
        : t(MANAGE_PAYROLL_CONSTANTS?.MANAGE_PAYROLL)
    }

    const handlePrint = async (type: any, page?: string) => {
        let filters: any = {
            filterState, tableDispatch, tableState
        }
        switch (type) {
            case 'export':
                await getManageData(filters)
                    .then(async () => {
                        setLoading(true);
                    })
                    .catch((error) => {
                        console.error("Error during data management:", error);
                    });
                break;
            case 'exportoverview':
                await handleTableDataChange(tableState, tableDispatch, 'overview', page).then(async () => {
                    setLoading(true);
                })
                .catch((error) => {
                    console.error("Error during data management:", error);
                });
                break;
            case 'print':
                printPdf(null, () => componentRef.current);
                break;
            default:
                break;
        }
    }
    
    useEffect(() => {
        if (loading === true) {
            // Trigger download once the component has updated
            downloadPdf();
            setLoading(false);
            ((tableState?.overview || tableState?.selected_data?.length > 0) && tableState?.overview != 'overview')
                ? handleTableDataChange(tableState, tableDispatch, 'overview', false)
                : handleFilterChange(filterState, filterDispatch, 'submit');
        }
    }, [loading === true]);

    const downloadPdf = async () => {
         const options: Options = {
            filename: `Export-payroll-${new Date().getDate()}-${new Date().toLocaleString('default', { month: 'short' })}-${new Date().getFullYear()}-${new Date().toLocaleTimeString().replace(/:/g, '-')}`,
            method: 'save',
            resolution: Resolution.MEDIUM,
            page: {
                margin: Margin.SMALL,
                format: 'a4',
                orientation: 'landscape',
            },
            overrides: {
                pdf: { compress: true },
                canvas: { useCORS: true }
            },
        }; 

        if (componentRef?.current) {
            generatePDF(componentRef, options);
        }
    }

    const printPdf = useReactToPrint({
        onBeforePrint: () => {console.log("before printing...");},
        onAfterPrint: () => {console.log("after printing...");},
        documentTitle: getFileName(),
        removeAfterPrint: true,
        bodyClass: 'mt-5',
        pageStyle: ""
    });

    return (
        <div className="page-layout">
            <div className="row header-sticky position-sticky">           
             <h1 className='py-4 page-title mb-0'>{t(MANAGE_PAYROLL_CONSTANTS?.MANAGE_PAYROLL)}</h1>
            </div>
            <header className='search-bar'>
                <WorkPayBillRollFilters handlePrint={handlePrint}/>
            </header>
            <div ref={componentRef} className="content">{children}</div>
            <div className="col-md-6 align-self-center my-4">
                <span
                    onClick={() => tableState?.overview ? handleTableDataChange(tableState, tableDispatch, 'overview', false) : navigate("/dashboard")}
                    className="cursor-pointer text-uppercase back-btn text-decoration-underline"
                >
                    {t(MANAGE_PAYROLL_CONSTANTS?.BACK)}
                </span>
            </div>
        </div>
    );
};

export default PageLayout;
