import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CountProps {
  value?: string | number;
  totalValue?: string | number;
  color?: string;
  backgroundColor?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  icon?: IconProp;
  iconWidth?: string | number; // icon width
  iconHeight?: string | number; // icon height
  top?: string | number;
  right?: string | number;
  left?: string | number;
  bottom?: string | number
  iconSrc?: string; // New prop for the file icon source
  children?: React.ReactNode;
  value_punctuation?: string | number;

}

const Count: React.FC<CountProps> = ({
  value,
  totalValue,
  color,
  backgroundColor,
  width,
  height,
  className,
  icon,
  iconWidth,
  iconHeight,
  top,
  bottom,
  left,
  iconSrc,
  right,
  children,
  value_punctuation
}) => {
  // count color
  const RecruitmentCountStyle: React.CSSProperties = {
    color
  };
  // vertical line
  const RecruitmentVerticalLine: React.CSSProperties = {
    width,
    height,
    backgroundColor
  };
  // Icon style
  const iconStyle: React.CSSProperties = {
    width: iconWidth,
    height: iconHeight,
  };

  // Absolute style
  const iconPosition: React.CSSProperties = {
    top,
    bottom,
    left,
    right
  }
  return (
    <>
      <div className="d-flex align-items-center">
        <div style={RecruitmentVerticalLine}></div>
        {(value !== undefined || totalValue !== undefined) && (
          <div className={`position-relative w-100`}>
            <span style={RecruitmentCountStyle} className={`${className}`}>
              {value} {value_punctuation} {totalValue}
            </span>
            <span className='position-absolute' style={iconPosition}>
              {icon && <FontAwesomeIcon icon={icon} style={iconStyle} />}
              {iconSrc && <img src={iconSrc} alt="File Icon" style={iconStyle} />}
            </span>
            {children}
          </div>
        )}

      </div>
    </>
  );
};

export default Count;