import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_VACANCY_CREATE,
  PATH_MATCHING,
  PATH_VACANCY_EDIT,
} from "constants/Paths";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  VACANCY_DELETE,
  DOWNLOAD_PROJECT_PDF,
  COMPANY_LIST,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import TableHeaderView from "components/atoms/TableHeaderView";
import { IconData } from "services/IconData";

interface ManageVacanciesProps {
  data?: any[];
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  functions: any[];
  projects: any[];
  companies: any[];
  handleGetVacany: any;
  companyProjects: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

export const ManageVacancies: React.FC<ManageVacanciesProps> = ({
  data,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  functions,
  projects,
  companies,
  handleGetVacany,
  companyProjects,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [companyList, setCompanyList] = useState<CompanyItem[] | null>(null);

  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    searchValue(name, value);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        VACANCY_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetVacany({
          page: 1,
          nOR: 10,
          search: {
            name: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          COMPANY_LIST,
          "get",
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          setCompanyList(response?.data);
        } else {
          console.error(ERROR);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_VACANCY_EDIT}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const handleProjectOptionAccordingToTheCompany = () => {
    if (companyProjects) {
      let projectList = companyProjects.filter(
        (item: any) => item.company_id == searchData?.company
      );
      let filteredProjects =
        searchData?.company && searchData?.company != ""
          ? projects.filter((project) =>
              projectList.some(
                (company: any) => project.value == company.project_id
              )
            )
          : projects;
      return filteredProjects.length > 0 ? (
        <>
          <option value="">{t("Project")}</option>
          {filteredProjects.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </>
      ) : (
        <option value="">{t("No projects available")}</option>
      );
    }
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Vacancy",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="row header-sticky position-sticky">
            {/* <div className="col-md-12">
              <TableHeaderView
                heading={t("My vacancies")}
                belowtext={"Let's present to fill them!"}
              />
            </div> */}
            <div className="col-md-12">
              <h1 className="py-4 page-title mb-0">Manage vacancies</h1>
            </div>
          </div>
          <div className="row pb-4 search-bar">
            <div className="col-md-6 col-lg-3 mb-3">
              <div className="d-block ">
                <label className="form-label field-label">{t("Company")}</label>
              </div>
              <select
                className="form-select shadow-none rounded-0"
                name="company"
                id="company"
                onChange={handleSearchChange}
                value={searchData?.company}
              >
                {companies && companies.length > 0 ? (
                  <>
                    <option value="">{t("Company")}</option>
                    {companies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No companies available")}</option>
                )}
              </select>
            </div>

            <div className="col-md-6 col-lg-3 mb-3">
              <div className="d-block ">
                <label className="form-label field-label">{t("Project")}</label>
              </div>
              <select
                className="form-select shadow-none rounded-0"
                name="project"
                id="project"
                onChange={handleSearchChange}
                value={searchData?.project}
              >
                {handleProjectOptionAccordingToTheCompany()}
              </select>
            </div>

            <div className="col-md-6 col-lg-3 mb-3">
              <div className="d-block ">
                <label className="form-label field-label">
                  {t("Function")}
                </label>
              </div>
              <select
                className="form-select shadow-none rounded-0"
                name="function"
                id="function"
                onChange={handleSearchChange}
                value={searchData?.function}
              >
                {companyList && companyList.length > 0 ? (
                  <>
                    <option value="">{t("Function")}</option>
                    {functions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No function available")}</option>
                )}
              </select>
            </div>

            <div className="col justify-content-end d-flex align-items-center mt-3">
              <div className="d-flex">
                <Button
                  title={IconData.ResetIcon}
                  type="submit"
                  handleClick={(e) => handleSubmit(e, true)}
                  className="btn delete-btn  shadow-none text-uppercase me-3 "
                />
                <Button
                  title="Search"
                  type="submit"
                  handleClick={handleSubmit}
                  className="btn form-button  shadow-none text-uppercase px-3 search-btns"
                />
              </div>
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Vacancy",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_VACANCY_CREATE}
                  className="table-button"
                >
                  + {"Create Vacancy"}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive manage-companies">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Company")}</th>
                    <th>{t("Project")}</th>
                    <th>{t("Function")}</th>
                    <th className="table_actions">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Vacancy",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record, key) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">{record.company_name}</td>

                          <td className="align-middle">{record.project_name}</td>
                          {record.functions && record.functions.length > 0 ? (
                            <td className="align-middle">
                              {record.functions
                                .map((fun: any) => fun.function_name)
                                .join(", ")}
                            </td>
                          ) : null}
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleDelete={handleArchive}
                              value={record.id}
                              permission={"Vacancy"}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 align-self-center my-4">
          <Link
            to={"/dashboard"}
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManageVacancies;
