import React from "react";
import CommonServices from "services/CommonService";

const ProjectParameterHeader = ({ headerColumns }) => {
  const fieldsToDisplay = {
    template_name: [],
    capacity: [],
    project_rate: [],
    overtime_percentage: [],
    km_to_be_invoiced: [],
    km_package_fee: [],
    round_trip_km: [],
    overtime: [
      "Day limit", "Exception friday", "Exception saturday", "Exception saturday & holidays",
      "Exception monday", "Exception tuesday", "Exception wednesday", "Exception thursday",
      "night", "weekend_saturdays", "weekend_sundays_and_holidays"
    ],
  };

  const renderRow = (key, value) => {    
    if (typeof value === 'object' && value !== null && key === 'overtime') {
      return Object.entries(value)
        .filter(([subKey]) => (fieldsToDisplay[key]?.includes(subKey)))
        .map(([subKey, subValue]) => (          
          <tr key={subKey}>
            <td className="border">{CommonServices.capitalizeLabel(subKey)}</td>
            <td className="border">{`${subValue?.value ?? subValue} ${subValue?.parameter === 'time' ? "hour" : subValue?.parameter ?? ""}`}</td>
          </tr>
        ));
    }
    return (
      <tr key={key}>
        <td className="border">{CommonServices.capitalizeLabel(key)}</td>
        <td className="border">{`${(value?.value) ? (value?.value ?? "") : (typeof value === 'object' ? "0" : value)} ${value?.parameter === 'time' ? "hour" : value?.parameter ?? ""}`}</td>
      </tr>
    );
  };

  return (
    <table>
      <thead>
        <tr>
          <th colSpan="2" className="border">Project Parameters</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(headerColumns)
          .filter(([key]) => fieldsToDisplay.hasOwnProperty(key))
          .flatMap(([key, value]) => renderRow(key, value))}
      </tbody>
    </table>
  );
};

export default ProjectParameterHeader;
