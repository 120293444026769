import { NEXT_TAB } from "utils/multiTabForm/context/Constants";

export const getNewTabIndex = (
  tabs: any,
  index: Number,
  tabsCount: number,
  type: string
) => {
  const currentTabIndex = tabs.findIndex((tab: any) => tab.index === index);
  var newTab;
  if (type === NEXT_TAB) {
    newTab = (currentTabIndex + 1) % tabsCount;
  } else {
    newTab = (currentTabIndex - 1) % tabsCount;
  }
  return tabs[newTab].index;
};

export const getCurrentFieldFromTabForm = (tabForm: any, name: string) => {
  return tabForm.find((field: any) => field.name === name);
};
