
export const initialFilterProps = {
    state: {
        companies: [],
        projects: [],
        businessUnits: [],
        employeeTypes: [],
        companyProjects: [],
        projectBusinessUnits: [],
        shifts: [],
        employees: [],
        projectlist: [],
        performanceTypes: [],
        reimbursementTypes: [],
        regimen: [],
    },
    filteredState: {
        company_id: [],
        project_id: [],
        businessUnits: [],
        employeesid: []
    },
    refresh: false,
}
