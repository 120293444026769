import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { TodoHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { DELETE_TODO, USER_LIST } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import {
  PATH_MASTER_DATA,
  PATH_TODO_CREATE,
  PATH_TODO_VIEW,
} from "constants/Paths";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { Todo } from "./Manage";
import { TodoObject } from "./Manage"
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { IconData } from "services/IconData";

interface TodoPageProps {
  todoObject: TodoObject | undefined;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

// interface UserItem {
//   value: number;
//   label: string;
//   name: string;
// }

const ManageTodoPage: React.FC<TodoPageProps> = ({
  todoObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  // const [userList, setUserList] = useState<UserItem[] | null>(null);

  // useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await ApiCall.getService(
    //       USER_LIST,
    //       "get",
    //       M_MASTER_DATA
    //     );
    //     if (response?.status === 200) {
    //       setUserList(response?.data);
    //     } else {
    //       console.error(ERROR);
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
    // fetchData();
  // }, []);

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_TODO}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response?.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleDelete = (Todo: Todo | any) => {
    setShowModal(true);
    setDeleteId(Todo.id);
  };

  const handleEdit = (Data: Todo | any) => {
    if (Data.id) {
      navigate(`${PATH_TODO_CREATE}/${Data.id}`);
    }
  };

  const handleView = (Data: Todo | any) => {
    if (Data.id) {
      navigate(`${PATH_TODO_VIEW}/${Data.id}`);
    }
  };

  const handleChange = (entity: any, fieldName: string) => {
    searchValue(fieldName, entity.value);
  }
  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Todo",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="form-height-dashboard">
        <TitleFieldMolecule title={t("Manage todo followup")} />
        <div className="row pb-4 search-bar mb-4">
              <div className="col-2">
                <LabelWithInputField
                  className="form-control"
                  type="date"
                  label={t("Start date")}
                  value={searchData.start_date}
                  handleChange={handleSearchChange}
                  name="start_date"
                  placeholder={t("Start date")}
                />
              </div>
              <div className="col-2">
                <LabelWithInputField
                  className="form-control"
                  type="date"
                  label={t("End date")}
                  handleChange={handleSearchChange}
                  placeholder={t("End date")}
                  name="end_date"
                  value={searchData.end_date}
                />
              </div>
              <div className="col-2">
                <div className="">
                  <label className="form-label field-label">
                    {t("Search type")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Search type")}
                  name="entity_type"
                  value={searchData?.entity_type}
                />
              </div>
              <div className="col-2">
                <div className="">
                  <label className="form-label field-label">
                    {t("Todo type")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Search for company/project/employee")}
                  name="todo_type"
                  value={searchData.todo_type}
                />
              </div>
              <div className="col-2">
                <div className="">
                  <label className="form-label field-label">
                    {t("Search info")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Search info")}
                  name="info"
                  value={searchData.info}
                />
              </div>
              <div className="col-2">
                <div className="">
                  <label className="form-label field-label">
                    {t("Business unit")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Find business unit")}
                  name="business_unit"
                  value={searchData.business_unit}
                />
              </div>
              <div className="col-2">
                <div className="">
                  <label className="form-label field-label">
                    {t("User")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("User search")}
                  name="name"
                  value={searchData.name}
                />
              </div>
              <div className="col-2">
                <div className="">
                  <label className="form-label field-label">
                    {t("Todo status")}
                  </label>
                </div>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Todo status")}
                  name="todo_status"
                  value={searchData?.todo_status}
                />
              </div>
            
          <div className="col text-end mt-auto">
            <Button
              title={IconData.ResetIcon}
              type="submit"
              handleClick={(e) => handleSubmit(e, true)}
              className="btn delete-btn  shadow-none text-uppercase me-3 "
            />
            <Button
              title={t("Search")}
              type="submit"
              handleClick={handleSubmit}
              className="btn form-button shadow-none text-uppercase px-3 search-btns position-relative text-center"
            />
          </div>
        </div>
          <div className="p-5 border rounded-4 table-custom mt-2">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Todo",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_TODO_CREATE}
                  className="table-button"
                >
                  + {t("Create follow up todo")}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive">
              <DynamicTableStructure
                isAction
                headers={TodoHeader}
                data={todoObject ? todoObject?.todoData : []}
                permission={"Todo"}
                handleEdit={handleEdit}
              />
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          <div className="my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={t("Delete confirmation")}
            body={t("Are you sure want to delete?")}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            closeTitle={t("No")}
            confirmTitle={t("Yes")}
          />
      </div>
    </AccessControl>
  );
};
export default ManageTodoPage;
