import Button from 'components/atoms/Button';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
interface OfferProps {
    comments: string;
}


const OfferDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const [formData, setFormData] = useState<OfferProps>({
        comments: "",
    });

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        console.log(formData);
        navigate(`/requirement/${id}`);
    }


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-center py-4 page-title mb-0`">
                    <h1>Create vacancy</h1>
                    <p>Hey, thanks for providing the general info and competences, please go ahead to provide the offer and requirement details</p>
                    <p>so we can craft a perfect job description for your vacancy</p>
                    <h2>Offer details</h2>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="comments"
                            handleChange={handleFieldChange}
                            value={formData.comments}
                            id="comments"
                            label="Comments"
                            type="text"
                        />
                    </div>
                </div>
                <div className='d-none d-md-block d-lg-block'>
                    <div className="row mb-4">
                        <div className="col-md-4 align-self-center">
                            <Link to={`/employment/${id}`} className='text-uppercase back-btn text-decoration-underline'>Back</Link>
                        </div>
                        <div className="col-md-8 text-end">
                            <Button title="Next" type="submit" className="btn form-button rounded-0 shadow-none text-uppercase" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default OfferDetails;
