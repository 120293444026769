import React from "react";

const DownloadContractIcon = () => {
  return (
    <span title="Download contract">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="25"
        viewBox="0 0 91 100"
        fill="none"
      >
        <path
          d="M70.678 60.1888C65.3968 60.1888 60.334 62.2865 56.604 66.0208C52.8696 69.7513 50.772 74.8138 50.772 80.0948C50.772 85.3758 52.8697 90.4388 56.604 94.1688C60.3345 97.9032 65.397 100.001 70.678 100.001C75.959 100.001 81.022 97.9031 84.752 94.1688C88.4864 90.4383 90.584 85.3758 90.584 80.0948C90.584 74.8138 88.4863 69.7508 84.752 66.0208C81.0215 62.2864 75.959 60.1888 70.678 60.1888ZM80.2092 84.3258L72.6154 91.9235C72.1037 92.4352 71.4084 92.7243 70.6818 92.7243C69.9553 92.7243 69.2599 92.4352 68.7482 91.9235L61.1505 84.3258C60.0841 83.2594 60.0841 81.525 61.1505 80.4586C62.2169 79.3922 63.9513 79.3922 65.0177 80.4586L67.9474 83.3883V70.2003C67.9474 68.6886 69.1701 67.4659 70.6818 67.4659C72.1935 67.4659 73.4162 68.6886 73.4162 70.2003V83.3883L76.3459 80.4586C77.4123 79.3922 79.1467 79.3922 80.2131 80.4586C81.2795 81.525 81.2795 83.2594 80.2131 84.3258H80.2092Z"
          fill="black"
        />
        <path
          d="M80.99 34.9308C79.6892 34.962 78.3884 34.9855 77.0994 34.9855H77.0955C71.6072 34.9855 66.1185 34.6925 60.6575 34.1105H60.6106C57.3528 33.7355 54.1653 32.1183 51.6028 29.5636C49.0403 27.0089 47.4348 23.8136 47.0559 20.5558V20.5089C46.3294 13.7198 46.052 6.89188 46.2278 0.0718804V0.0679741C35.9658 -0.178116 25.6968 0.243754 15.4898 1.33747C8.66953 2.12653 2.13482 8.66557 1.34183 15.4855C-0.447275 32.2125 -0.447275 63.2745 1.34183 80.0055C2.13089 86.8219 8.66993 93.3645 15.4898 94.1535V94.1496C27.0638 95.3918 38.7168 95.7707 50.3488 95.2863C45.3488 88.5793 43.9582 79.8453 46.6379 71.9153H19.3879C17.8762 71.9153 16.6535 70.6926 16.6535 69.1809C16.6535 67.6731 17.8762 66.4465 19.3879 66.4465H49.2709C51.4428 63.0598 54.3803 60.2317 57.849 58.1887H19.388C17.8763 58.1887 16.6536 56.966 16.6536 55.4543C16.6536 53.9426 17.8763 52.7199 19.388 52.7199H61.771C63.2827 52.7199 64.5054 53.9426 64.5054 55.4543V55.4699C70.0366 54.0988 75.8644 54.6144 81.0674 56.9347C81.2159 49.673 81.1931 42.0518 80.99 34.9308ZM48.107 44.462H19.396C17.8843 44.462 16.6616 43.2393 16.6616 41.7276C16.6616 40.2159 17.8843 38.9932 19.396 38.9932H48.107C49.6187 38.9932 50.8414 40.2159 50.8414 41.7276C50.8414 43.2393 49.6187 44.462 48.107 44.462Z"
          fill="black"
        />
        <path
          d="M61.213 28.9068C67.7208 29.6021 74.264 29.8677 80.807 29.7037L80.7914 29.6802C73.0648 18.0162 63.0884 8.01221 51.4474 0.258212C51.2794 6.83241 51.5451 13.4102 52.2443 19.9462C52.7482 24.2665 56.8887 28.4147 61.213 28.9068Z"
          fill="black"
        />
      </svg>
    </span>
  );
};

export default DownloadContractIcon;
