import { TIMESHEET_FILTER_ACTIONS } from "../../actions/TimesheetFilterActions";

export const filtersReducer = (filterState, actions) => {    
    switch(actions.type){
        case TIMESHEET_FILTER_ACTIONS.UPDATE_TIMESHEET_SELECTED_FIELD: 
            {return update_selected_field(filterState, actions);}
        
        case TIMESHEET_FILTER_ACTIONS.BULK_UPDATE_TIMESHEET_SELECTED_FILEDS:
            {return update_bulk_selected_fields(filterState, actions)}
        
        case TIMESHEET_FILTER_ACTIONS.UPDATE_TIMESHEET_OPTIONS_STATE:
            {return update_options_state_field(filterState, actions)}

        case TIMESHEET_FILTER_ACTIONS.BULK_UPDATE_TIMESHEET_OPTIONS_STATE:
            {return update_bulk_timesheet_options_state(filterState, actions)}
            
        case TIMESHEET_FILTER_ACTIONS.REFRESH:
            return { ...filterState, refresh: !filterState?.refresh };

        default:
            throw Error('Unknown action: ' + actions.type);
    }
}

function update_selected_field(filterState, actions) {
    return {
        ...filterState,
        filteredState: {
            ...filterState.filteredState,
            [actions.payload.field]: actions.payload.value
        }
    };
}

function update_bulk_selected_fields(filterState, actions) {
    return {
        ...filterState,
        filteredState: {
            ...filterState.filteredState,
            ...actions.payload
        }
    };
}

function update_options_state_field(filterState, actions) {
    return {
        ...filterState,
        filteredState: {
            ...filterState.state,
            [actions.payload.field]: actions.payload.value
        }
    };
}

function update_bulk_timesheet_options_state(filterState, actions){
    return {
        ...filterState,
        state: {
            ...filterState.state,
            ...actions.payload
        }
    };
}