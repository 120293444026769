import React from "react";

const Matching = () => {
  return (
    <span title="Matching">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
      >
        <path d="M8 5v5H4v4h4v5l5-7-5-7zm8 14v-5h4v-4h-4V5l-5 7 5 7z"  fill="currentColor" />
        <path d="M0 0h24v24H0z" fill="none"/>
      </svg>
    </span>
  );
};

export default Matching;
