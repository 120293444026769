import React, { useEffect, useState } from 'react';
import { Navigation} from '../formNavigation/Navigation';
import { useFormContext } from '../../context/Context';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import Calender from 'components/molecules/Calender';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import CheckBoxField from 'components/atoms/CheckBoxField';
import LabelField from 'components/atoms/LabelField';
import RadioField from 'components/atoms/RadioField';
import {
  validateForm,
  validateRequired,
  validateDate,
  validateRadioField,
  validateSelectField,
  validateMultiSelectField,
  Option,
  validatePCEmployeeType,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";
import Button from "components/atoms/Button";
import PCTable from "./PCTable";
import { mapToSelect } from 'utils/MapToSelect';

interface pcProps {
  PC: Option | null,
  employeeType: Option | null,
}

interface editProps {
  id: number | null,
  type: string,
}

const GeneralTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const [edit, setEdit] = useState<editProps>({
    id: null,
    type: ''
  });

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  
  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const [PCWhite, setPCWhite] = useState<pcProps>({
    PC: null,
    employeeType: null,
  });

  const [PCBlue, setPCBlue] = useState<pcProps>({
    PC: null,
    employeeType: null,
  });

  const validation = (
    name: string,
    value: string
      | number
      | boolean
      | Date
      | Option
      | object[]
      | null
      | undefined
      | object,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      company: [validateRequired],
      startDate: [validateDate],
      consultant: [validateSelectField],
      officeNumber: [validateSelectField],
      province: [validateMultiSelectField],
      type: [validateRadioField],
      vatInclusive: [validateRadioField],
      coefficient: [validateSelectField],
      paritairBlue: [validatePCEmployeeType],
      paritairWhite: [validatePCEmployeeType],
      empBlue: [validateMultiSelectField],
      empWhite: [validateMultiSelectField],
      PC: [validateSelectField],
      employeeType: [validateMultiSelectField],
      PC2: [validateSelectField],
      employeeType2: [validateMultiSelectField]
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: name,
        error: validationErrors[name],
      });
    } else {
      dispatch({ type: "UPDATE_FIELD_ERRORS", errors: validationErrors });
    }


    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: name, error: "" });
    }


    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    if (type === "radio") {
      const newValue = parseInt(value);
      dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value: newValue });
      validation(name, newValue, true);
    } else if (type === "checkbox") {
      dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value: checked });
    } else {
      dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value: value });
      validation(name, value, true);
    }
  };
  const handleDateChange = (date: Date | null, name: string) => {
    dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value: date });
    validation(name, date, true);
  };

  const handleSelectField = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const handlePCFields = (selectedOption: any, name: string) => {
    if (name === "PC" || name === "employeeType") {
      setPCWhite({
        ...PCWhite,
        [name]: selectedOption,
      });
      validation(name, selectedOption, true);
    } else {
      const fieldName = name === "PC2" ? "PC" : "employeeType";
      setPCBlue({
        ...PCBlue,
        [fieldName]: selectedOption,
      });
      validation(name, selectedOption, true);
    }
  };

  const getWhiteCollarPCs = () => {
    const selectedPCs = state.general.paritairWhite.map((item:any) => item.PC.value);
    const PCs = state.whiteCollarPCs.filter((item) => !selectedPCs.includes(item.id));
  
    const PCOptions = PCs.map((item:any) => ({
      value: item.id,
      label: `(${item.pc_number}) ${item.name}`
    }));
  
    return PCOptions;
  };

  const getBlueCollarPCs = () => {
    const selectedPCs = state.general.paritairBlue.map((item:any) => item.PC.value);
    const PCs = state.blueCollarPCs.filter((item) => !selectedPCs.includes(item.id));
  
    const PCOptions = PCs.map((item:any) => ({
      value: item.id,
      label: `(${item.pc_number}) ${item.name}`
    }));
  
    return PCOptions;
  };
  

  const addParitiarComitee = (type: string) => {
    let hasValidationErrors = false;

    if (type === "whiteCollar") {
      if (PCWhite.PC === null || PCWhite.PC.value === null) {
        dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: 'PC', error: "This field is required" });
        hasValidationErrors = true;
      }
      if (PCWhite.employeeType === null) {
        dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: 'employeeType', error: "This field is required" });
        hasValidationErrors = true;
      }
      if (!hasValidationErrors && state.fieldError.PC === '' && state.fieldError.employeeType === '') {
        edit.id === null ? dispatch({ type: "ADD_WHITECOLLAR_FIELD", newData: PCWhite }) : dispatch({ type: "UPDATE_WHITECOLLAR_FIELD", newData: PCWhite, index: edit.id });
        setPCWhite({
          PC: null,
          employeeType: null
        });
        performValidation();
      }
    } else {
      if (PCBlue.PC === null) {
        dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: 'PC2', error: "This field is required" });
        hasValidationErrors = true;
      }
      if (PCBlue.employeeType === null) {
        dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: 'employeeType2', error: "This field is required" });
        hasValidationErrors = true;
      }
      if (!hasValidationErrors && state.fieldError.PC2 === '' && state.fieldError.employeeType2 === '') {
        edit.id === null ? dispatch({ type: "ADD_BLUECOLLAR_FIELD", newData: PCBlue }) : dispatch({ type: "UPDATE_BLUECOLLAR_FIELD", newData: PCBlue, index: edit.id });
        setPCBlue({
          PC: null,
          employeeType: null
        });
        performValidation();
      }
    }    
  };

  const performValidation = () => {
    validation('paritairBlue', state.general.paritairBlue, true);
    validation('paritairWhite', state.general.paritairWhite, true);
  }
  // for employeetype validation for all the selected pcs
  useEffect(() => {
    performValidation();
  }, [state.general.paritairWhite, state.general.paritairWhite]);


  const pcChangeHandler = (id: number, type: string) => {
    if (type === 'whiteCollar') {
      const record = state.general.paritairWhite.filter((item, index) => (index === id));
      let data: pcProps = record[0] as pcProps;
      setPCWhite(data);
      setEdit({
        id: id,
        type: type
      });
    } else {
      const record = state.general.paritairBlue.filter((item, index) => (index === id));
      let data: pcProps = record[0] as pcProps;
      setPCBlue(data);
      setEdit({
        id: id,
        type: type
      });
    }
  }

  const pcDeleteHandler = (indexToRemove: number, type: string) => {
    if (type === 'whiteCollar') {
      dispatch({ type: "REMOVE_WHITE_PC", indexToRemove });
    } else {
      dispatch({ type: "REMOVE_BLUE_PC", indexToRemove });
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {

    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;

  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-border p-3 mb-3">
          <div className="row">
            <div className="col-md-6">
              <LabelWithInputField
                isMandatory={false}
                name="vat"
                label="VAT number"
                handleChange={(e) => {
                  handleInputField(e);
                }}
                type="text"
                value={state.general.vat}
              />
            </div>
            <div className="col-md-6">
              <LabelWithInputField
                isMandatory={true}
                name="company"
                label="Company"
                handleChange={(e) => {
                  handleInputField(e);
                }}
                type="text"
                error={state.fieldError.company}
                value={state.general.company}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 position-relative">
              <Calender
                isMandatory={true}
                onChange={(date) => {
                  handleDateChange(date, "startDate");
                }}
                label="Start date of agreement"
                minDate={new Date()}
                error={state.fieldError.startDate}
                selectedDate={state.general.startDate}
              />
            </div>
            <div className="col-md-6">
              <SelectWithSearch
                isMandatory={true}
                search={true}
                options={state.consultantList}
                value={state.general.consultant}
                onChange={(e) => {
                  handleSelectField(e, "consultant");
                }}
                placeHolder="Select"
                name="consultant"
                className="select-field"
                title="Absolute jobs consultant"
                error={state.fieldError.consultant}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <SelectWithSearch
                isMandatory={true}
                search={false}
                options={state.consultantList}
                value={state.general.officeNumber}
                onChange={(e) => {
                  handleSelectField(e, "officeNumber");
                }}
                className="select-field"
                placeHolder="Select"
                name="officeNumber"
                title="Absolute jobs office number"
                error={state.fieldError.officeNumber}
              />
            </div>
            <div className="col-md-6">
              <SelectWithSearch
                isMulti={true}
                isMandatory={true}
                search={true}
                options={state.consultantList}
                value={state.general.province}
                onChange={(e) => {
                  handleSelectField(e, "province");
                }}
                className="select-field"
                placeHolder="Select"
                name="province"
                title="Location"
                error={state.fieldError.province}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <CheckBoxField
                name="addProject"
                label='Activate "add project"'
                onChangeHandler={(e) => {
                  handleInputField(e);
                }}
                isChecked={state.general.addProject}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <LabelField title="Type" isMandatory={true} />
              </div>
              <div className="row mb-2">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.type === 0}
                  label="Work"
                  value={0}
                  name="type"
                />
              </div>
              <div className="row mb-2">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.type === 1}
                  label="Construct"
                  value={1}
                  name="type"
                />
              </div>
              <div className="row mb-2">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.type === 2}
                  label="International"
                  value={2}
                  name="type"
                />
              </div>
              <div className="row mb-2">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.type === 3}
                  label="Professional"
                  value={3}
                  name="type"
                />
              </div>
              {state.general.type === 3 && (
                <div className="row">
                  <div className="col-md-6">
                    <SelectWithSearch
                      search={false}
                      options={[
                        {'value': 1, 'label':'Fee based'},
                        {'value': 2, 'label': 'Coefficient'}
                      ]}
                      value={state.general.feeType}
                      onChange={(e) => {
                        handleSelectField(e, "feeType");
                      }}
                      placeHolder="Select"
                      name="feeType"
                    />
                  </div>
                </div>
              )}
              <div className="row text-danger height-20">
                <div className="co-md-12">
                  {state.fieldError.type}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <LabelField title="Work type" isMandatory={true} />
              </div>
              <div className="row mb-2">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.workType === 1}
                  label="Work + home + transport (AJ)"
                  value={1}
                  name="workType"
                />
              </div>
              <div className="row mb-2">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.workType === 2}
                  label="Work + home + transport (Client)"
                  value={2}
                  name="workType"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <LabelField title="VAT inclusive" isMandatory={true} />
              <div className="col-md-12">
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.vatInclusive === 0}
                  label="Yes"
                  value={0}
                  name="vatInclusive"
                  className="me-3"
                />
                <RadioField
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  ischecked={state.general.vatInclusive === 1}
                  label="No"
                  value={1}
                  name="vatInclusive"
                />
              </div>
              <div className="row text-danger height-20">
                <div className="col-md-12">
                  {state.fieldError.type}
                </div>
              </div>
            </div>
          </div>

          {/* paritiar commitee */}
          <div className="field-set border p-3 mb-4 rounded-0">
            <div className="row">
              <div className="col-lg-6 border-end">
                <h5>
                  <LabelField
                    title="Paritair comité for white collar"
                    className='color-dark-purple fw-bold'
                  />
                </h5>
                <div>
                  <SelectWithSearch
                    isMulti={false}
                    isMandatory={true}
                    search={true}
                    options={getWhiteCollarPCs()}
                    value={PCWhite.PC}
                    onChange={(e) => {
                      handlePCFields(e, "PC");
                    }}
                    placeHolder="Select"
                    name="PC"
                    title="Paritair comite (PC) 1"
                    error={state.fieldError.PC}
                    className='select-field'
                  />
                </div>
                <div>
                  <SelectWithSearch
                    isMulti={true}
                    isMandatory={true}
                    search={true}
                    options={state.employeeTypes}
                    value={PCWhite.employeeType}
                    onChange={(e) => {
                      handlePCFields(e, "employeeType");
                    }}
                    placeHolder="Select"
                    name="employeeType"
                    title="Selection of employee types (statuut) that can be used"
                    error={state.fieldError.employeeType}
                    className='select-field'
                  />
                </div>
                <div className='row mb-3'>
                  <div className="col-md-12">
                    <Button
                      title="Extra Paritair comité workers (arbeiders)"
                      handleClick={() => addParitiarComitee("whiteCollar")}
                      className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                    />
                  </div>
                </div>
                <PCTable data={state.general.paritairWhite} pcChangeHandler={pcChangeHandler} type="whiteCollar" pcDeleteHandler={pcDeleteHandler} />
                {state.fieldError.paritairWhite && <div className='text-danger'>{state.fieldError.paritairWhite}</div>}
              </div>

              <div className="col-lg-6">
                <h5>
                  <LabelField
                    title="Paritair comité for blue collar"
                    className='color-dark-purple fw-bold'
                  />
                </h5>
                <div>
                  <SelectWithSearch
                    isMulti={false}
                    isMandatory={true}
                    search={true}
                    options={getBlueCollarPCs()}
                    value={PCBlue.PC}
                    onChange={(e) => {
                      handlePCFields(e, "PC2");
                    }}
                    placeHolder="Select"
                    name="PC2"
                    title="Paritair comite (PC) 2"
                    error={state.fieldError.PC2}
                    className='select-field'
                  />
                </div>
                <div>
                  <SelectWithSearch
                    isMulti={true}
                    isMandatory={true}
                    search={true}
                    options={state.employeeTypes}
                    value={PCBlue.employeeType}
                    onChange={(e) => {
                      handlePCFields(e, "employeeType2");
                    }}
                    placeHolder="Select"
                    name="employeeType2"
                    title="Selection of employee types (statuut) that can be used"
                    error={state.fieldError.employeeType2}
                    className='select-field'
                  />
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <Button
                      title="Extra Paritair comité servants (bedienden)"
                      handleClick={() => addParitiarComitee("blueCollar")}
                      className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                    />
                  </div>
                </div>
                <PCTable data={state.general.paritairBlue} pcChangeHandler={pcChangeHandler} type="blueCollar" pcDeleteHandler={pcDeleteHandler} />
                {state.fieldError.paritairBlue && <div className='text-danger'>{state.fieldError.paritairBlue}</div>}
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};
export default GeneralTab;
