import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCall } from "services/ApiServices";
import { EDIT_TAG, STORE_TAGS } from "routes/ApiEndpoints";
import { M_MASTER_DATA, TAG_EXISTS } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_MANAGE_TAGS } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface InputItem {
  tag_name: any;
}

interface ValidationRules {
  [key: string]: Function[];
}

function CreateTags() {
  const [formData, setFormData] = useState<any>({
    tag_name: "",
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<InputItem[]>([]);
  const [errors, setErrors] = useState<InputItem | any>({
    tag_name: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const url = `${EDIT_TAG}/${id}`;
          console.log(url);

          const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
          if (response?.status === 200) {
            setFormData(response?.data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [id]);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    validateInput(name, value);
  };

  const validateInput = (
    name: string | any,
    value: string | boolean | any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      tag_name: [validateRequired],
    };

    // Validate the form data based on the rules
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );

    // Update errors state based on the validation results
    if (!isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRemove = (index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const handleEdit = (index: number) => {
    const editedData = tableData[index];
    setFormData(editedData);
    setEditIndex(index);
  };

  const handleAddClick = () => {
    const isFormValid = validateInput("tag_name", formData.tag_name, true);

    if (isFormValid) {
      // Check if editing or adding new entry
      if (editIndex !== null) {
        const updatedTableData = [...tableData];
        updatedTableData[editIndex] = formData;
        setTableData(updatedTableData);

        // Reset the form and edit state
        setFormData({
          tag_name: "",
        });
        setEditIndex(null);
      } else {
        // Check if the tag_name already exists in tableData
        const isDuplicate = tableData.some(
          (entry) => entry.tag_name === formData.tag_name
        );

        if (isDuplicate) {
          setErrors({
            tag_name: t(`${TAG_EXISTS}`),
          });
        } else {
          // Add the new entry to tableData
          setTableData((prevTableData) => [...prevTableData, formData]);
          resetFormData();
        }
      }
    }
  };

  const resetFormData = () => {
    setFormData({
      tag_name: "",
    });
    setErrors({
      tag_name: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let finalData = [...tableData];

    let isFormValid = true;

    isFormValid = validateInput("tag_name", formData.tag_name, true);

    if (Object.keys(formData).length !== 0) {
      finalData.push(formData);
    }

    if (isFormValid) {
      const response = await ApiCall.service(
        STORE_TAGS,
        "POST",
        finalData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${PATH_MANAGE_TAGS}`);
      } else if (response?.status === 422) {
        CustomNotify({ type: "warning", message: response.message });
      }
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Tags",
  }
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth)

  return (
    <AccessControl
      requiredPermissions={[
        permissionObject
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">
              {id ? t("Edit tag") : t("Create tags")}
            </h1>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="">
            <div className="form-border p-5">
              <div className="row">
                <div className="form-group col-md-12">
                  <LabelWithInputField
                    isMandatory={true}
                    name="tag_name"
                    handleChange={(e) => changeHandler(e)}
                    value={formData.tag_name}
                    id="tag_name"
                    label={t("Tag")}
                    placeholder={t("Tag")}
                    type="text"
                    error={errors.tag_name}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-12 text-lg-end text">
              {id === undefined && (
                <Button
                  title={editIndex !== null ? t("Save") : t("+ Add another tag")}
                  handleClick={handleAddClick}
                  className="btn form-button rounded-0 shadow-none text-uppercase px-3 button-widths"
                />
              )}
            </div>
          </div>
        </form>

        {tableData.length > 0 && (
          <div className="row">
            {tableData.map((item, index) => (
              <div
                key={index}
                className="col-md-2 mb-3 mx-3 btn border border-dark rounded"
              >
                <div className="d-flex align-items-center justify-content-center py-2 align-items-center  position-relative">
                  <p className="mb-0">{item.tag_name}</p>
                  <FontAwesomeIcon
                    className="position-absolute end-0 top-0"
                    icon={faTimes}
                    onClick={() => handleRemove(index)}
                    style={{ cursor: "pointer", color: "#FF0000" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={t("Delete")}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="d-flex justify-content-between my-3 align-items-center">
          <div className="">
            <Link
              to={PATH_MANAGE_TAGS}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>

          <div className="">
            <Button
              title={t("Save")}
              handleClick={handleSubmit}
              className="btn form-button  shadow-none text-uppercase px-3 button-widths"
            />
          </div>
        </div>
      </>
    </AccessControl>
  );
}

export default CreateTags;
