import React, { useEffect, useState } from 'react'
import { useFilterOptions, useFilterOptionsDispatch } from '../work-pay-bill-roll-redux/context/FilterOptionsContext';
import { MANAGE_PAYROLL_CONSTANTS, filterValues } from '../constants/WorkPayBillRollConstants';
import { t } from 'pages/microservices/masterData/translation/Translation';
import Button from 'components/atoms/Button';
import { renderFormElements } from 'services/form/FormElements';
import { handleFilterChange } from '../utils/Utils';
import { useTable } from '../work-pay-bill-roll-redux/context/TableContext';
import { IconData } from 'services/IconData';

const ManagePayrollFilters = () => {
    const filterState = useFilterOptions();
    const filterDispatch = useFilterOptionsDispatch();
    const tableState = useTable();   
    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        let row = filterValues();
        setRows(row);
    }, []);

    return (
        <>
            {!tableState?.overview && <div className="d-flex gap-3">
                    {rows.map((value: any) => (                    
                        <div className={`flex-fill w-25`} key={value?.name}>
                            {
                                renderFormElements(
                                    { type: value?.type, name: value?.name, value },
                                    (e: any) => handleFilterChange(filterState, filterDispatch, value?.name, e?.target?.value ?? ""),
                                    filterState,
                                )
                            }
                        </div>
                    ))}
                <div className="d-flex m-auto">
                    <Button
                        className="btn delete-btn  shadow-none text-uppercase me-3 "
                        handleClick={() => handleFilterChange(filterState, filterDispatch, 'reset')}
                        // title={t(MANAGE_PAYROLL_CONSTANTS?.RESET)}
                        title={IconData.ResetIcon}
                    />
                    <Button
                        handleClick={() => handleFilterChange(filterState, filterDispatch, 'submit')}
                        className='btn form-button shadow-none text-uppercase px-3 search-btns'
                        title={t(MANAGE_PAYROLL_CONSTANTS?.SUBMIT)}
                    />
                </div>
            </div>}
        </>
    )
}

export default ManagePayrollFilters