import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { DocumentHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { PATH_MASTER_DATA } from "constants/Paths";
import Popup from "components/molecules/Popup";
import { ApiCall } from "services/ApiServices";
import { GET_DOCUMENTS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import Search from "components/atoms/Search";
import CustomNotify from "components/atoms/CustomNotify";
import Button from "components/atoms/Button";
import Pagination from "components/atoms/Pagination";
import DocView from "components/molecules/DocView";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";

interface Document {
  id: number;
  emp_doc_id: number;
  file_name: string;
  file_path: string;
  title: string;
  url: string;
  type: string;
}

const ManageDocumentPage: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [showPopup, setShowPopup] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [submit, setSubmit] = useState(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState({
    file_name: queryParams.get("name") ?? "",
  });
  const navigate = useNavigate();
  const [selectedDocument, setSelectedDocument] = useState<Document | null>({
    id: 0,
    emp_doc_id: 0,
    file_name: "",
    file_path: "",
    title: "Document viewer",
    url: "",
    type: "",
  });

  useEffect(() => {
    const data = {
      page: currentPage,
      search: search,
    };

    fetchDocuments(data);
  }, [currentPage, submit]);

  const fetchDocuments = async (data: any) => {
    try {
      const response = await ApiCall.service(
        GET_DOCUMENTS,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setDocuments(response.data);
        setTotalPages(response.totalPages);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    if (clear) {
      navigate({ search: "" });
      setSearch({
        file_name: "",
      });
    } else {
      const queryParams = Object.entries(search)
        .filter(([key, value]) => value !== undefined && value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
      if (queryParams !== "") {
        navigate(`?${queryParams}`);
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      handleSearch(name, value);
    }, debounceTime);
  };

  const handleAction = (document: Document, action: string) => {    
    if (!document || !document?.file_path) {
      console.error("Invalid document data");
      return;
    }
    const fileInfo = JSON.parse(document?.file_path);
    const { fileName, accessUrl } = fileInfo;
    const fileType = fileName?.split(".").pop()?.toLowerCase();
    if (fileType !== undefined && fileType !== "") {
      setSelectedDocument((prevState: any) => ({
        ...prevState,
        title: fileName,
        url: accessUrl,
        type: fileType,
      }));
    }

    switch (action) {
      case "view":
        openPopup();
        break;
      case "download":
        downloadFile(fileName, accessUrl);
        break;
      default:
        console.error("Invalid action");
    }
  };

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);

  const downloadFile = (fileName: string, accessUrl: string) => {
    fetch(accessUrl)
      ?.then((response) => response?.blob())
      ?.then((blob) => {
        const blobURL = URL?.createObjectURL(blob);
        const link = document?.createElement("a");
        link.href = blobURL;
        link.download = fileName;
        document?.body?.appendChild(link);
        link?.click();
        document?.body?.removeChild(link);
      })
      ?.catch((error) => console.error("Error downloading file:", error));
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[{
        permission: "Document",
        read: true
      }]}
      override={userData.isSuperAdmin}
    >
      <div>
        <div className="form-height-dashboard">
          <TitleFieldMolecule title={t("Manage documents")} />
          <div className="row pb-4 search-bar">
            <div className="mb-3"></div>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-xxl-9 col-md-12 mb-3 mb-lg-0">
                  <div className="col">
                    <div className="">
                      <label className="form-label field-label">
                        {t("Document name")}
                      </label>
                    </div>
                    <Search
                      className="form-control"
                      handleChange={handleSearchChange}
                      placeholder={t("Document")}
                      name="file_name"
                      value={search.file_name}
                    />
                  </div>
                </div>
                <div className="col text-end mt-auto">
                  <Button
                    title={IconData.ResetIcon}
                    type="submit"
                    handleClick={(e) => handleSubmit(e, true)}
                    className="btn delete-btn  shadow-none text-uppercase me-3 "
                  />
                  <Button
                    title={t("Search")}
                    type="submit"
                    handleClick={handleSubmit}
                    className="btn form-button shadow-none text-uppercase px-3 search-btns position-relative text-center"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-responsive">
              <DynamicTableStructure
                isAction
                headers={DocumentHeader}
                data={documents}
                handleView={(document: Document) => handleAction(document, "view")}
                handleDownload={(document: Document) =>
                  handleAction(document, "download")
                }
                permission={"Document"}
              />
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
          {showPopup && documents.length > 0 && selectedDocument && (
            <Popup
              title={t(selectedDocument.title)}
              body={
                <>
                  {selectedDocument.type === "pdf" ? (
                    <iframe
                      src={selectedDocument.url}
                      title={selectedDocument.title}
                      style={{ width: "100%", height: "100vh" }}
                    />
                  ) : (
                    <DocView
                      fileType={selectedDocument.type}
                      fileUrl={selectedDocument.url}
                    />
                  )}
                </>
              }
              close={closePopup}
              modalSize={"xl"}
              notext="Close"
              cancel={closePopup}
            />
          )}

          <div className="my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageDocumentPage;
