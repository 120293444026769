import React, { useEffect, useRef, useState } from "react";
import CloseFile from "static/images/CloseFile";
// import { generateFollowUpUrl } from "components/CentralDataMangement/taskDeterminer/ManageFollowUps";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import LoadingIcon from "utils/LoadingIcon";
import { t } from "../translation/Translation";
import AddPlusIcon from "static/images/AddPlusIcon";
import TitleFieldMolecule from "components/molecules/TitleField";
import { FETCH_FOLLOWUP_INFO } from "routes/ApiEndpoints";

export interface FollowUpEntity {
    businessUnit?: number | string | null;
    entityId: number | string | null;
}
interface RightSideBarProps {
    onClose: () => void;
    sidebarWidth?: string | number;
    title?: string;
    getWidth?: (value: any) => void;
    entityType: "company" | "employee" | "vacancy"| "project";
    recordsLimit?: number;
    businessUnit?: number | string | null;
    entityId: number | string | null;
    className?: string;
    titleClassName?: string;
    destination?: string;
}

interface FollowUpData {
    id: number;
    date: string;
    time: string;
    info: string;
    relatedEntity: string;
    createdby: string;
    taskType: string;
}

const RightSideBar: React.FC<RightSideBarProps> = ({
    onClose,
    sidebarWidth,
    getWidth,
    className,
    titleClassName,
    title,
    entityType,
    entityId = null,
    businessUnit = null,
    recordsLimit = 10,
    destination = null
}) => {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [followUps, setFollowUps] = useState<FollowUpData[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchFollowUps();
        if (sidebarRef.current && getWidth) {
            const sidebarWidth = sidebarRef.current.offsetWidth;
            getWidth(sidebarWidth);
        }
    }, [sidebarWidth, entityId]);

    const fetchFollowUps = async () => {
        setLoading(true);
        const data = {
            entityType: entityType,
            entityId: entityId,
            businessUnit: businessUnit,
            nOR: recordsLimit
        };
        const response = await ApiCall.service(
            FETCH_FOLLOWUP_INFO,
            "POST",
            data,
            false,
            M_MASTER_DATA
        );
        if (response.status === 200) {
            setFollowUps(response.data);
        }
        setLoading(false);
    };
    function generateFollowUpUrl(type?: string, id?: string, businessUnits?: any): string {
        // console.log("type, id, businessUnit", type, id, businessUnit);

        const params = new URLSearchParams();

        if (type !== undefined) {
            params.append('type', type);
        }

        if (id !== undefined) {
            params.append('entityId', id);
        }

        if (businessUnits !== undefined) {
            if (Array.isArray(businessUnit)) {
                console.log("array");
                businessUnits.forEach((businessUnit: { businessUnitId: string, businessUnitNumber: string }) => {
                    params.append('businessUnit', businessUnit.businessUnitId);
                });
            } else {
                console.log("not array");
                
                params.append('businessUnit', businessUnits);
            }


        }

        return `/todo/create?${params.toString()}`;
    }

    return (
        <>
            <TitleFieldMolecule title="Followup" />
            <div ref={sidebarRef} className={className}>
                {onClose &&
                    <div className="row">
                        <div className="col-12">
                            <button onClick={onClose} className="follow-up-close-btn table-action-icons float-end pe-0">
                                <span data-bs-toggle="tooltip" title={t("Close")}>
                                    <CloseFile />
                                </span>
                            </button>
                        </div>
                    </div>
                }
                <div className={`${titleClassName} row mb-3`}>
                    <div className="col-9 text-break ">
                        <div className="tab-subtitle text-break">{t(`${title ?? ""}`)}</div>
                    </div>
                    <div className="col-3 pe-1">
                        <button
                            onClick={() => { navigate(generateFollowUpUrl(entityType, entityId?.toString(), businessUnit) + `${destination ? `&destination=${destination}` : ""}`) }}
                            className="table-action-icons border-0 p-0 bg-transparent float-end">
                            <span data-bs-toggle="tooltip" title={t("Add follow-ups")}>
                                <AddPlusIcon />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="recruiter-action-tabs">
                    {loading ? (
                        <LoadingIcon />
                    ) : (
                        <>
                            {
                                followUps.length > 0 ? (
                                    <>{
                                        followUps.map((data: FollowUpData, index) => (
                                            <a href={`/todo/create/${data.id}${destination ? `?destination=${destination}` : ""}`} className="text-decoration-none" key={index}>
                                                <div className="card rounded-3 p-3 border-0 card-shadow justify-content-center mb-3">
                                                    <div className="followUpTimings mb-2">{`${data.date ?? ""}  - ${data.taskType ?? ""} (${data.createdby ?? ""})`}</div>
                                                    <div className="mb-2">{data.relatedEntity ?? "-"}</div>
                                                    <div>{data.info.substring(0, 60)}</div>
                                                </div>
                                            </a>
                                        ))
                                    }</>
                                ) : (<div className="text-center">{t("No follow-ups found")}</div>)
                            }
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default RightSideBar;
