import CheckBoxField from "components/atoms/CheckBoxField";
import CustomNotify from "components/atoms/CustomNotify";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Title from "components/atoms/Title";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  GET_FUNCTION_FOR_CATEGORY,
  GET_FUNCTION_FOR_PC,
  GET_QUESTION_DETAILS,
  STORE_QUESTIONS,
  UPDATE_QUESTIONS,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import {
  scrollToTop,
  validateDichtmsOptions,
  validateDichtmsOptionsAnswer,
  validateForm,
  validateMultiChoice,
  validateMultiChoiceAnswer,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps, ValidationRules } from "utils/TypeAnnotations";
import { t } from "../translation/Translation";
import CloseFile from "static/images/CloseFile";
import Button from "components/atoms/Button";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import LoadingIcon from "utils/LoadingIcon";
import { Option } from "utils/TypeAnnotations";
import { M_MASTER_DATA } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface commonDataProps {
  // questionCategory: Option | null;
  pcSection: Option | null;
  function: object[];
  pcCategory: object[];
}

interface QuestionProps {
  [key: string]:
  | string
  | number
  | null
  | boolean
  | Option
  | OptionProps[]
  | number[]
  | string[];
  questionLinkedId: number | null;
  questionType: Option | null;
  questionTitle: string;
  question: string;
  options: string[];
  answers: number[];
  description: string;
  questionStatus: number;
}

const AddQuestions: React.FC = () => {
  ///********* */ Common form start********///
  const { id } = useParams<{ id: string }>();
  const [commonData, setCommonData] = useState<commonDataProps>({
    pcSection: null,
    function: [],
    pcCategory: [],
  });

  const [dropDownData, setDropDownData] = useState({
    function: [] as OptionProps[],
    pcSection: [] as OptionProps[],
    questionTypes: [] as OptionProps[],
    pcCategory: [] as OptionProps[],
  });

  const initialCommonErrors: { [key: string]: string } = {};
  const [commonErrors, setCommonErrors] = useState<{ [key: string]: string }>(
    initialCommonErrors
  );

  const location = useLocation();

  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const isCloneMode = location.pathname.includes("clone");
  const isEditMode = location.pathname.includes("edit");
  const isViewMode = location.pathname.includes("view");
  const [loading, setLoading] = useState(false);
  const [pcValue, setPcvalue] = useState([]);

  const commonValidation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      pcSection: [validateSelectField],
      function: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...commonData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setCommonErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setCommonErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setCommonData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (fieldName === "pcSection") {
      setPcvalue(selectedOption);
      getFunctionForPC(selectedOption);
    }

    if (fieldName === "pcCategory" ){
      if( selectedOption.length === 0) {
        getFunctionForPC(pcValue);
      }else{
        getFunctionForCategory(selectedOption);
      }
    }
    commonValidation(fieldName, selectedOption, true);
  };

  const getFunctionForPC = async (pc: any) => {
    const response = await ApiCall.service(
      GET_FUNCTION_FOR_PC,
      "POST",
      pc,
      false,
      M_MASTER_DATA
    );
    const functions = response.data.functions ? mapToSelect(response?.data?.functions, "name") : [];
    const categories = response.data.pcCategories ? mapToSelect(response?.data?.pcCategories, "name"): [];
    setDropDownData((prevData) => ({
      ...prevData,
      function: functions,
      pcCategory: categories,
    }));
    setCommonData((prev) => ({
      ...prev,
      function: [],
      pcCategory: [],
    }));
  };

  const getFunctionForCategory = async (category: any) => {
    const response = await ApiCall.service(
      GET_FUNCTION_FOR_CATEGORY,
      "POST",
      category,
      true,
      M_MASTER_DATA
    );
    const functions = mapToSelect(response?.data, "name");
    setDropDownData((prevData) => ({
      ...prevData,
      function: functions,
    }));
  };

  //****Commom form end */

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      const url = id ? `${GET_QUESTION_DETAILS}/${id}` : GET_QUESTION_DETAILS;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_MASTER_DATA,
        true
      );

      if (response.status === 200) {
        const questionsData = response.data["questionData"];
        const pcSection = questionsData["pcSection"];
        const pcCategoryList = mapToSelect(response.data['pcCategory']);
        const questionCategory = questionsData["questionCategory"];
        const functionList = questionsData["function"];
        const questionList = questionsData["questions"];
        if (isCloneMode) {
          // Set questionLinkedId to null for each question
          questionList.forEach((question: any) => {
            question.questionLinkedId = null;
          });
        }
        if (Object.keys(questionsData).length > 0) {
          setCommonData((prevData) => ({
            ...prevData,
            questionCategory: isCloneMode ? null : questionCategory,
            pcSection: isCloneMode ? null : pcSection,
            function: isCloneMode ? [] : functionList,
            pcCategory: isCloneMode ? [] :pcCategoryList,
          }));
          setQuestions(questionList);
        }
        const questionCategories = mapToSelect(
          response.data["questionCategories"]
        );
        const pcSections = mapToSelect(response.data["pcSection"], "pc_number");
        const questionTypes = mapToSelect(response.data["questionTypes"]);
        setDropDownData((prevData) => ({
          ...prevData,
          pcSection: pcSections,
          questionTypes: questionTypes,
        }));
      }
    };
    fetchQuestionDetails();
  }, [id, isCloneMode]);

  // ***Dynamic form start

  const [questions, setQuestions] = useState<QuestionProps[]>([
    {
      questionLinkedId: null,
      questionType: null,
      questionTitle: "",
      question: "",
      options: [""],
      answers: [],
      description: "",
      questionStatus: 1,
    },
  ]);
  const initialErrors: { [key: string]: string }[] = [];
  const [errors, setErrors] =
    useState<{ [key: string]: string }[]>(initialErrors);

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | number
      | null
      | Option
      | number[]
      | OptionProps[]
      | string[],
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRulesByType: any = {
      1: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        options: [validateMultiChoice],
        answers: [validateMultiChoiceAnswer],
      },
      2: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        description: [validateRequired],
      },
      3: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        options: [validateDichtmsOptions],
        answers: [validateDichtmsOptionsAnswer],
      },
      4: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
      },
    };
    const questionType: number = questions[index].questionType?.value || 4;
    const validationErrors = validateForm(
      { ...questions[index], [name]: value },
      validationRulesByType[questionType],
      isSingleFieldValidation ? name : undefined
    );
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      if (isSingleFieldValidation) {
        // Single-field validation
        if (!newErrors[index]) {
          newErrors[index] = {}; // Initialize the object if it doesn't exist
        }
        newErrors[index][name] = validationErrors[name];
      } else {
        // Full-form validation
        newErrors[index] = validationErrors;
        questions[index].options.forEach((optionValue, optionIndex) => {
          newErrors[index][`option-${index}-${optionIndex}`] =
            optionValue.trim() === "" ? "This field is required" : "";
        });
      }

      return newErrors;
    });

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const addOption = (questionIndex: number) => {
    const newFields = [...questions];
    if (newFields[questionIndex].questionType?.value === 1) {
      if (newFields[questionIndex].options.length < 4) {
        newFields[questionIndex].options.push("");
        setQuestions(newFields);
      }
    }
    if (newFields[questionIndex].questionType?.value === 3) {
      if (newFields[questionIndex].options.length < 2) {
        newFields[questionIndex].options.push("");
        setQuestions(newFields);
      }
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;

    if (type === "radio") {
      const answer = parseInt(event.target.value);
      const newAnswers: number[] = [answer];
      const newFields: QuestionProps[] = [...questions];
      newFields[index]["answers"] = newAnswers;
      setQuestions(newFields);
    }
    // Check if the field is a checkbox
    else if (type === "checkbox" && name.startsWith("answers")) {
      const newFields: QuestionProps[] = [...questions];
      const answersList = newFields[index].answers || []; // Get the existing checked options array

      if ((event.target as HTMLInputElement).checked) {
        // If the checkbox is checked, add the value to the array
        answersList.push(parseInt(value));
      } else {
        // If the checkbox is unchecked, remove the value from the array
        const indexToRemove = answersList.indexOf(parseInt(value));
        if (indexToRemove !== -1) {
          answersList.splice(indexToRemove, 1);
        }
      }

      newFields[index].answers = answersList;
      validation(name, answersList, index, true);
      setQuestions(newFields);
    } else if (type === "checkbox" && name === "questionStatus") {
      const newFields: QuestionProps[] = [...questions];

      // Check if the checkbox is checked
      const isChecked = (event.target as HTMLInputElement).checked;

      // Set questionStatus to 1 if checked, else set it to 0
      newFields[index][name] = isChecked ? 1 : 0;

      setQuestions(newFields);
    } else {
      // Handle other input fields as before
      const newFields: QuestionProps[] = [...questions];
      newFields[index][name] = value;
      setQuestions(newFields);
      validation(name, value, index, true);
    }
  };

  const handleDescriptionFieldChange = (
    event: any,
    editor: any,
    index: number,
    name: string
  ) => {
    const content = editor.getData();
    const newFields: QuestionProps[] = [...questions];
    newFields[index][name] = content;
    setQuestions(newFields);
    validation(name, content, index, true);
  };

  const handleOptionFieldChange = (
    event: any,
    editor: any,
    index: number,
    optionIndex: number
  ) => {
    const content = editor.getData();

    // Update the state with the changed option content
    const newQuestions = [...questions];
    newQuestions[index].options[optionIndex] = content;
    setQuestions(newQuestions);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) {
        newErrors[index] = {};
      }
      newErrors[index][`option-${index}-${optionIndex}`] =
        content.trim() === "" ? "This field is required" : "";
      return newErrors;
    });
  };

  const addFieldSet = (): void => {
    const newField: QuestionProps = {
      questionLinkedId: null,
      questionType: null,
      questionTitle: "",
      question: "",
      options: [""],
      answers: [],
      description: "",
      questionStatus: 1,
    };

    setQuestions([...questions, newField]);
    setErrors([...errors, {}]);
  };

  const removeFieldSet = (index: number): void => {
    const newFields: QuestionProps[] = [...questions];
    newFields.splice(index, 1);
    setQuestions(newFields);
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleDynamicSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    const newFields: QuestionProps[] = [...questions];
    if (
      selectedOption.value === 1 ||
      selectedOption.value === 2 ||
      selectedOption.value === 3
    ) {
      newFields[index] = {
        ...newFields[index],
        questionTitle: "",
        question: "",
        options: [""],
        answers: [],
        description: "",
        [fieldName]: selectedOption,
      };
      const newErrors = [...errors];
      newErrors[index] = {};
      setErrors(newErrors);
    }
    newFields[index] = {
      ...newFields[index],
      [fieldName]: selectedOption,
    };
    setQuestions(newFields);
    validation(fieldName, selectedOption, index, true);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    let hasErrors = false;

    for (let i = 0; i < questions.length; i++) {
      const { name, value } = questions[i];
      const validationResult = validation(name as string, value, i);
      if (validationResult !== true) {
        hasErrors = true;
      }
    }

    let commonValidationResult =
      category === "General" ? true : commonValidation(name, value);

    if (commonValidationResult && !hasErrors) {
      const mode = isCloneMode ? "clone" : isEditMode ? "edit" : "add";
      const formData = {
        mode: mode,
        questionCategory: category,
        pcSection: commonData.pcSection,
        pcCategory: commonData.pcCategory,
        functions: commonData.function,
        questions: questions,
      };

      //API call for store and update
      const url = isEditMode ? `${UPDATE_QUESTIONS}/${id}` : STORE_QUESTIONS;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_MASTER_DATA
      );

      if (response.status === 200) {
        navigate("/manage-questions");
        CustomNotify({ type: "success", message: response.msg });
      } else if (response.status === 400) {
        scrollToTop();
        setCommonErrors({ function: response.msg });
        // setCommonErrors({pcCategory: response.msg});
        CustomNotify({ type: "error", message: response.msg });
      }
    } else {
      scrollToTop();
    }
    setLoading(false);
  };
  //Category
  useEffect(() => {
    if (location.pathname.includes("General") === true) {
      setCategory("General");
    } else if (location.pathname.includes("Function") === true) {
      setCategory("Function");
    } else {
      setCategory("");
    }
  }, []);

  const userData = useSelector(selectAuth);

  return (
    <>
      <div className="row">
        {isViewMode ? (
          <AccessControl
            requiredPermissions={[{
              permission: "Questions",
              read: true,
            }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
          >
            <div className="col-12">
              <div className="px-4">
                <Title title={t("View questions")} />
                <div className="view-questions-height">
                  <div className="position-relative mb-5">
                    <div className="form-border p-5">
                      {questions.map((question: any, index: number) => (
                        <div key={index}>
                          <h5
                            className="questionName"
                            dangerouslySetInnerHTML={{
                              __html: index + 1 + ") " + question.question,
                            }}
                          />

                          {question.questionType?.value === 1 &&
                            question.options.length > 0 && (
                              <div className="my-1" key={index}>
                                {question.options.map(
                                  (option: any, optionIndex: number) => (
                                    <div className="d-flex" key={optionIndex}>
                                      <div className="">
                                        <CheckBoxField
                                          className="ps-1"
                                          id={`checkbox-${optionIndex}`}
                                          value={option}
                                          name=""
                                          disable={true}
                                          isChecked={question.answers.includes(
                                            optionIndex
                                          )}
                                        />
                                      </div>

                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: option.replace(
                                            /<a/g,
                                            '<a target="_blank"'
                                          ),
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {question.questionType?.value === 2 && (
                            <p
                              className="questionOptions my-1"
                              dangerouslySetInnerHTML={{
                                __html: question.description.replace(
                                  /<a/g,
                                  '<a target="_blank"'
                                ),
                              }}
                            />
                          )}
                          {question.questionType?.value === 3 &&
                            question.options.length > 0 && (
                              <div className="my-1" key={index}>
                                {question.options.map(
                                  (option: any, optionIndex: number) => (
                                    <div className="d-flex" key={optionIndex}>
                                      <div className="">
                                        <RadioField
                                          className="ps-1"
                                          id={`checkbox-${index}-${optionIndex}`}
                                          value={optionIndex}
                                          name={`dichotomous-${index}-${optionIndex}`}
                                          disable={true}
                                          ischecked={question.answers.includes(
                                            optionIndex
                                          )}
                                          handleChange={function (
                                            event: ChangeEvent<HTMLInputElement>
                                          ): void {
                                            throw new Error(
                                              "Function not implemented."
                                            );
                                          }}
                                          label={undefined}
                                        />
                                      </div>

                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: option.replace(
                                            /<a/g,
                                            '<a target="_blank"'
                                          ),
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <Link
                    to=""
                    className=" back-btn text-decoration-underline"
                    onClick={() => navigate(-1)}
                  >
                    {t("Back")}
                  </Link>
                </div>
              </div>
            </div>
          </AccessControl>
        ) : (
          <AccessControl
            requiredPermissions={[{
              permission: "Questions",
              create: true,
              update: true,
            }]}
            renderNoAccess={true}
            strict={false}
            override={userData.isSuperAdmin}
          >
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit} className="px-4">
                    <Title
                      title={
                        isEditMode
                          ? t("Edit questions")
                          : isCloneMode
                            ? t("Clone questions")
                            : t("Add questions")
                      }
                    />
                    <div className="row my-3">
                      {category !== "General" && (
                        <>
                      <div className="col-lg-4 col-md-6">
                        <SelectWithSearch
                          // title={t("Question category")}
                          title={t("Paritair comitee")}
                          isMandatory={true}
                          search={true}
                          options={dropDownData.pcSection}
                          onChange={(e) => handleSelectChange(e, "pcSection")}
                          isMulti={false}
                          name="pcSection"
                          isDisabled={isEditMode}
                          value={commonData.pcSection}
                          error={commonErrors.pcSection}
                          placeHolder="Select"
                        />
                      </div>
                          <div className="col">
                            <SelectWithSearch
                              title={t("Category")}
                              isMandatory={false}
                              search={true}
                              options={dropDownData.pcCategory}
                              onChange={(e) =>
                                handleSelectChange(e, "pcCategory")
                              }
                              isMulti={true}
                              name="pcCategory"
                              isDisabled={isEditMode}
                              value={commonData.pcCategory}
                              error={commonErrors.pcCategory}
                              placeHolder="Select"
                            />
                          </div>
                      <div className="col-md-6 col-lg-4">
                        <SelectWithSearch
                          title={t("Function")}
                          isMandatory={true}
                          search={true}
                          options={dropDownData.function}
                          onChange={(e) => handleSelectChange(e, "function")}
                          isMulti={true}
                          name="function"
                          isDisabled={isEditMode}
                          value={commonData.function}
                          error={commonErrors.function}
                          placeHolder="Select"
                        />
                      </div>
                        </>
                      )}


                      <div className="position-relative mb-5">
                        <div className="form-border pb-5 pt-3 pt-lg-5 px-lg-5 px-4">
                          {questions.map((question: any, index: number) => (
                            <div
                              key={index}
                              className="field-set border p-4 mb-4 rounded-4"
                            >
                              {index > 0 && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <>
                                      <div className="d-block d-md-block d-lg-none">
                                        <Button
                                          title={t("Remove")}
                                          handleClick={() => removeFieldSet(index)}
                                          className="link-button float-end  rounded-0 shadow-none mt-4"
                                        />
                                      </div>
                                      <div className="d-none d-md-none d-lg-block">
                                        <span
                                          title={t("Remove")}
                                          onClick={() => removeFieldSet(index)}
                                          className="table-action-icons cursor-pointer float-end  rounded-0 shadow-none"
                                        >
                                          <CloseFile />
                                        </span>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              )}
                              <div className="col-sm-12 col-md-12">
                                <SelectWithSearch
                                  title={t("Question type")}
                                  isMandatory={true}
                                  search={true}
                                  options={dropDownData.questionTypes}
                                  id={`questionType-${index}`}
                                  placeHolder={t("Select question type")}
                                  onChange={(e) =>
                                    handleDynamicSelectChange(
                                      e,
                                      "questionType",
                                      index
                                    )
                                  }
                                  isMulti={false}
                                  className="select-field"
                                  name="questionType"
                                  value={question.questionType}
                                  error={errors[index]?.questionType}
                                  isDisabled = {question.questionLinkedId === null ? false : true}
                                />
                              </div>
                              <div className="col-sm-12 col-md-12">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="questionTitle"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={question.questionTitle}
                                  id={`questionTitle-${index}`}
                                  label={t("Question title")}
                                  type="text"
                                  error={errors[index]?.questionTitle}
                                />
                              </div>
                              <div className="col-sm-12 col-md-12">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="question"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={question.question}
                                  id={`question-${index}`}
                                  label={t("Question")}
                                  type="text"
                                  error={errors[index]?.question}
                                />
                              </div>
                              {/**Multiple choice start*/}
                              {question.questionType &&
                                question.questionType.value === 1 && (
                                  <>
                                    <div className="p-4" key={index}>
                                      <div className="col-md-12">
                                        <div className="row gx-5">
                                          {question.options.map(
                                            (option: any, optionIndex: number) => (
                                              <div
                                                key={optionIndex}
                                                className="col-6"
                                              >
                                                <div className="d-flex justify-content-center align-items-center multipleChoiceQns">
                                                  <div className="">
                                                    <CheckBoxField
                                                      name={`answers-${index}-${optionIndex}`}
                                                      id={`answers-${index}-${optionIndex}`}
                                                      onChangeHandler={(event) =>
                                                        handleFieldChange(
                                                          event,
                                                          index
                                                        )
                                                      }
                                                      value={optionIndex}
                                                      isChecked={question.answers.includes(
                                                        optionIndex
                                                      )}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    <LabelWithCKEditorField
                                                      isMandatory={true}
                                                      label={`Option ${optionIndex + 1
                                                        }`}
                                                      name={`option-${index}-${optionIndex}`}
                                                      placeholder={`Option ${optionIndex + 1
                                                        }`}
                                                      handleChange={(
                                                        event,
                                                        editor
                                                      ) =>
                                                        handleOptionFieldChange(
                                                          event,
                                                          editor,
                                                          index,
                                                          optionIndex
                                                        )
                                                      }
                                                      value={
                                                        question.options[
                                                        optionIndex
                                                        ]
                                                      }
                                                      id={`option-${index}-${optionIndex}`}
                                                      error={
                                                        errors[index]?.[
                                                        `option-${index}-${optionIndex}`
                                                        ]
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      {errors[index]?.options && (
                                        <span className="text-danger">
                                          {errors[index]?.options}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      {errors[index]?.answers && (
                                        <span className="text-danger">
                                          {errors[index]?.answers}
                                        </span>
                                      )}
                                    </div>
                                    {question.options.length < 4 && (
                                      <div className="row">
                                        <div className="col-md-12 px-0">
                                          <Button
                                            title={t("Add option")}
                                            handleClick={() => addOption(index)}
                                            className="btn form-button rounded-3 float-end shadow-none "
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              {/**Multiple choice end*/}

                              {/**Boolean start */}
                              {question.questionType &&
                                question.questionType.value === 3 && (
                                  <div className="col-md-12 px-3" key={index}>
                                    <div className="row gx-5">
                                      {question.options.map(
                                        (option: any, optionIndex: number) => (
                                          <div key={optionIndex} className="col-6">
                                            <div className="d-flex justify-content-center align-items-center radioFieldQns">
                                              <div className="">
                                                <RadioField
                                                  name={`answers-${index}`}
                                                  id={`answers-${index}-${optionIndex}`}
                                                  handleChange={(event) => {
                                                    handleFieldChange(event, index);
                                                  }}
                                                  value={optionIndex}
                                                  ischecked={question.answers.includes(
                                                    optionIndex
                                                  )}
                                                  label=""
                                                />
                                              </div>
                                              <div className="col-md-12">
                                                <LabelWithCKEditorField
                                                  isMandatory={true}
                                                  label={`Option ${optionIndex + 1
                                                    }`}
                                                  placeholder={`Option ${optionIndex + 1
                                                    }`}
                                                  name={`option-${index}-${optionIndex}`}
                                                  handleChange={(event, editor) =>
                                                    handleOptionFieldChange(
                                                      event,
                                                      editor,
                                                      index,
                                                      optionIndex
                                                    )
                                                  }
                                                  value={
                                                    question.options[optionIndex]
                                                  }
                                                  id={`option-${index}-${optionIndex}`}
                                                  error={
                                                    errors[index]?.[
                                                    `option-${index}-${optionIndex}`
                                                    ]
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      {errors[index]?.options && (
                                        <span className="text-danger">
                                          {errors[index]?.options}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                      {errors[index]?.answers && (
                                        <span className="text-danger">
                                          {errors[index]?.answers}
                                        </span>
                                      )}
                                    </div>
                                    {question.options.length < 2 && (
                                      <div className="row">
                                        <div className="col-md-12 px-0">
                                          <Button
                                            title={t("Add option")}
                                            handleClick={() => addOption(index)}
                                            className="btn form-button rounded-3 float-end shadow-none "
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              {/* Boolean end */}

                              {/* Descriptive start */}
                              {question.questionType &&
                                question.questionType.value === 2 && (
                                  <div>
                                    <div className="col-lg-12">
                                      <LabelWithCKEditorField
                                        name="description"
                                        handleChange={(event, editor) =>
                                          handleDescriptionFieldChange(
                                            event,
                                            editor,
                                            index,
                                            "description"
                                          )
                                        }
                                        label={t("Question description")}
                                        isMandatory={true}
                                        value={question.description}
                                        error={errors[index]?.description}
                                      />
                                    </div>
                                  </div>
                                )}
                              {/* Descriptive end*/}
                              <div className="row">
                                <div className="col-md-12">
                                  <CheckBoxField
                                    label={t("Publish question")}
                                    name="questionStatus"
                                    onChangeHandler={(event) =>
                                      handleFieldChange(event, index)
                                    }
                                    isChecked={question.questionStatus === 1}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="row py-4">
                            <div className="col-md-12">
                              <Button
                                title={t("+ Add another")}
                                handleClick={addFieldSet}
                                className="btn form-button float-end  rounded-3 shadow-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-4">
                      <div className="col-6 align-self-center">
                        <Link
                          to=""
                          className=" back-btn text-decoration-underline"
                          onClick={() => navigate(-1)}
                        >
                          {t("Back")}
                        </Link>
                      </div>
                      <div className="col-md-6 text">
                        {!loading ? (
                          <Button
                            title={t("Save")}
                            type="submit"
                            className="btn float-end form-button rounded-3 shadow-none  px-3"
                          />
                        ) : (
                          <LoadingIcon
                            iconType="bars"
                            color="#00a5ce"
                            className="ms-auto"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </AccessControl>
        )}
      </div>
    </>
  );
};
export default AddQuestions;
