import React, { useRef } from "react";
import Select from "react-select";
import LabelField from "./LabelField";
import { Option } from "components/common/CommonInterfaces";

// interface Option {
//   value?: number | string | null;
//   label?: string;
// }

/**
 * @property onChange
 * Expects a function to handle the changes.
 * value: Option | Option[]
 * actionMetadata: ActionMeta<any>, passes metadata like field name, action name, etc.
 */
interface DropdownSelectProps {
  search: boolean;
  options: any;
  isMulti?: boolean;
  value?: any;
  // todo: update value: type
  //* onChange: (value: Option | Option[], actionMetadata?: ActionMeta<any>) => void;
  // onChange: (value: any, actionMetadata?: ActionMeta<any>) => void;
  onChange: (value: any) => void;
  placeHolder: string;
  name: string;
  id?: string;
  error?: number | null | undefined | string;
  className?: string;
  containerClassName?: string;
  title?: string;
  isMandatory?: boolean;
  isDisabled?: boolean;
  isMenuPlacement?: boolean;
  isClearable?: boolean;
  emptyOptionNotRequired?: boolean;
  style?:any;
}

/**
 * Dropdown field with label and search and multi-select feature.
 *
 * For a simple dropdown use: `src/components/molecules/LabelwithSelectField.tsx`
 *
 * @param properties DropdownSelectProps
 *
 * @example
 * <SelectWithSearch
  onChange={}
  title={t("")}
  placeHolder={t("")}
  search={true}
  options={}
  value={}
  isDisabled={false}
  isMulti={true}
  isMandatory={true}
  // className={""}
  error={}
  name={"myFieldName"}
  id={"myFieldId"}
></SelectWithSearch>;
 *
 */
const SelectWithSearch: React.FC<DropdownSelectProps> = ({
  search,
  options,
  isMulti,
  value,
  id,
  onChange,
  placeHolder,
  name,
  error,
  className,
  containerClassName = "mb-4 position-relative",
  title,
  isMandatory,
  isDisabled = false,
  isMenuPlacement = false,
  isClearable = false,
  emptyOptionNotRequired = false,
  style,
}) => {
  const defaultOption: Option = { value: "", label: "Select" };
  const updatedOptions = [defaultOption, ...(options ? options : [])];

  const getValues = (value: any, list: any, isMulti: any) => {
    if (value && (typeof value === "object" || Array.isArray(value))) {
      return value;
    }
    let selectedObj = list?.find((option: Option) => option.value == value);
    return selectedObj !== undefined ? selectedObj : "";
  };
  const menuPortalTarget = useRef(document.body);

  return (
    <div className={containerClassName}>
      {!!title &&
        <LabelField title={title} isMandatory={isMandatory} />
      }

      <Select
        isSearchable={search}
        id={id}
        className={className}
        options={(isMulti || emptyOptionNotRequired) ? options : updatedOptions}
        isMulti={isMulti}
        value={getValues(value, options, isMulti)}
        // value={value}
        placeholder={placeHolder}
        onChange={onChange}
        name={name}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPlacement={isMenuPlacement ? "top" : "auto"}
        menuPortalTarget={menuPortalTarget.current}
        // menuIsOpen
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            minHeight: "2.344vw"
          }),
          menu: (provided: any, state) => ({
            ...provided,
            overflow: "visible", 
          }),
          menuPortal: (base: any) => ({ ...base, zIndex: 99999999 }),
          menuList: (provided: any, state) => ({
            ...provided,
            maxHeight: "150px",
            overflowY: "auto", 
            zIndex:"99999999"
          }),
        }}
      />
      <div className="position-absolute">
        {error && (
          <div className="mb-2 height-20 text-danger">
            {error !== undefined ? error : ""}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectWithSearch;
