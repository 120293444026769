import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import {
  PATH_MANAGE_CAR,
  PATH_MANAGE_CLIENT,
  PATH_MANAGE_CLOTHES,
  PATH_MANAGE_MATERIAL,
  PATH_MANAGE_PURCHASE_ORDER,
  PATH_MANAGE_SUPPLIER,
} from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccessControl from "services/AccessControl";

const FleetAndMaterial: React.FC = () => {
  const userData = useSelector(selectAuth);
  return (
    <>
      <WindowHeightComponent>
        <div className="d-flex flex-column h-100">
          <div className="px-1 flex-1 overflow-auto">
            <div className=" dashboard-tabs  ">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Holiday codes",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_MATERIAL} className="dashboard-tab-links">
                  <div className="tab">{t("Material")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Public holidays",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_CAR} className="dashboard-tab-links">
                  <div className="tab">{t("Car")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Holiday per PC",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_CLOTHES} className="dashboard-tab-links">
                  <div className="tab">{t("Clothes")}</div>
                </Link>
              </AccessControl>

              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Holiday per PC",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_SUPPLIER} className="dashboard-tab-links">
                  <div className="tab">{t("Supplier")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Holiday per PC",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_CLIENT} className="dashboard-tab-links">
                  <div className="tab">{t("Client")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Holiday per PC",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_PURCHASE_ORDER} className="dashboard-tab-links">
                  <div className="tab">{t("Purchase order")}</div>
                </Link>
              </AccessControl>
              
            </div>
          </div>

          <div className="my-2 px-1">
            <Link
              to="/"
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
      </WindowHeightComponent>
    </>
  );
};
export default FleetAndMaterial;
