import {
    validateEmail,
    validateLinkedinUrl,
    validateMultiSelectField,
    validatePhoneNumber,
    validateRequired,
    validateSelectField,
    validateTextFieldAlpha,
    validateStartDate,
    validateAlphaNumeric,
    validateMaxLength,
    validateMinLength,
    validateNoSpaces,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";


// GeneralTab
export const GeneralTabValidationRules: ValidationRules = {
    vat: [
        validateRequired,
        validateAlphaNumeric,
        (value: string) => validateMaxLength(value, 15),
        (value: string) => validateMinLength(value, 11),
        validateNoSpaces,
    ],
    businessUnitNumber: [validateRequired, validateMultiSelectField],
    name: [validateRequired, validateTextFieldAlpha],
    code: [validateRequired, validateTextFieldAlpha],
    type: [validateRequired, validateSelectField],
    company: [validateRequired, validateSelectField],
};
export const AddressTabValidationRules: ValidationRules = {
    street: [validateRequired],
    number: [validateRequired],
    zipCode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
    biStreet: [validateRequired],
    biZipCode: [validateRequired],
    biNumber: [validateRequired],
    biCity: [validateRequired],
    biCountry: [validateSelectField],
};


export const GeneralTabSubformLocationRules: ValidationRules = {
    company: [validateRequired, validateSelectField],
    location: [validateRequired],
    phone: [validateRequired],
    street: [validateRequired],
    number: [validateRequired],
    zipcode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
    // info: [],
};
export const LocationTabValidationRules: ValidationRules = {
    locationStreet: [validateRequired],
    locationName: [validateRequired],
    locationEmail: [validateRequired, validateEmail],
    locationNumber: [validateRequired],
    locationZipCode: [validateRequired],
    locationCity: [validateRequired],
    locationCountry: [validateSelectField],
};

// ContactsTab
export const ContactsTabValidationRules: ValidationRules = {
    selectedContactPersons: [validateRequired, validateMultiSelectField],
}

export const ContactsTabSubformValidationRules: ValidationRules = {
    fName: [validateRequired, validateTextFieldAlpha],
    lName: [validateRequired, validateTextFieldAlpha],
    email: [validateRequired, validateEmail],
  //  company: [validateSelectField], // validateRequired
    mobNumber: [validatePhoneNumber],
    phNumber: [validatePhoneNumber],
    gender: [validateSelectField],
    language: [validateSelectField],
    linkedIn: [validateLinkedinUrl],
    roles: [validateMultiSelectField],
    functionTitle: [validateSelectField],
    locations: [validateMultiSelectField],
    info: [],
    selectedContactPersons: [validateRequired, validateMultiSelectField],
};

//billing tab
export const BillingTabValidationRules: ValidationRules = {
    vatRate: [validateSelectField],
    paymentTerm: [validateSelectField],
    billingStatus: [validateSelectField]
};

export const ShiftsTabValidationRules: ValidationRules = {
    shifts: [validateMultiSelectField],
};

export const SequenceTabValidationRules: ValidationRules = {
    sequenceName: [validateRequired],
};

export const ProjectParametersTabValidationRules: ValidationRules = {
    templates: [validateSelectField],
};
