import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { PURCHASE_ORDERS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ManagePurchaseOrder from "./ManagePurchaseOrder";

// Define the prop types for the component
interface PurchaseOrderProps {
    id?: string | number;
}

interface DataProps {
    page: number;
    nOR: number;
    search?: Partial<PurchaseOrderProps>;
}

// Functional component using TypeScript
const PurchaseOrder: React.FC<PurchaseOrderProps> = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const itemsPerPage = 10;
    const [vacaciesData, setVacanciesData] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [submit, setSubmit] = useState(true);
    const [search, setSearch] = useState<any>({
        company: queryParams.get("company") ?? "",
        brand: queryParams.get("brand") ?? "",
        model: queryParams.get("model") ?? "",
        status: queryParams.get("status") ?? "",
        businessUnit: queryParams.get("businessUnit") ?? "",
    });

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const postData: DataProps = {
            page: currentPage,
            nOR: itemsPerPage,
            search: search,
        };
        getVacanciesData(postData);
    }, [currentPage, submit]);

    const getVacanciesData = async (postData: DataProps) => {
        const response = await ApiCall.service(
            PURCHASE_ORDERS,
            "POST",
            postData,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setVacanciesData(response?.data?.data);
            setTotalPages(response?.data?.totalPages);
        } else {
            console.log("error");
        }
    };

    const handleSearch = (name: string, value: string | number) => {
        setSearch((prevSearch: any) => ({ ...prevSearch, [name]: value }));
    };

    // Function to create query parameters from the search object
    const createQueryParams = (data: {
        [key: string]: string | number | boolean;
    }) => {
        const queryParams = Object.entries(data)
            .filter(([key, value]) => value !== undefined && value !== "")
            .map(
                ([key, value]: [string, string | number | boolean]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");

        return queryParams;
    };

    const handleSearchSubmit = (clear: boolean) => {
        if (clear) {
            navigate({ search: "" });
            setSearch({
                company: "",
                brand: "",
                model: "",
                status: "",
                businessUnit: "",
            });
        }

        if (createQueryParams(search) !== "" && !clear) {
            navigate(`?${createQueryParams(search)}`);
        } else if (!clear) {
            CustomNotify({ type: "warning", message: "Search input not provided." });
        }

        setSubmit(!submit);
        setCurrentPage(1);
    };



    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}...</div>
                }
            >

                <ManagePurchaseOrder
                    data={vacaciesData}
                    handleData={setVacanciesData}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    searchValue={handleSearch}
                    handleSubmitAction={handleSearchSubmit}
                    searchData={search}
                    handleGetVacany={getVacanciesData}
                />
            </Suspense>
        </>
    );
};

export default PurchaseOrder;
