import React, { ChangeEvent } from "react";
import LabelField from "../atoms/LabelField";
import InputTextAreaField from "../atoms/InputTextAreaField";

export interface LabelWithTextAreaFieldProps {
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMandatory?: boolean;
  // handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleChange: (event: ChangeEvent<any>) => void; // Use ChangeEvent<any>
  rows?: number | undefined;
  error?: string;
}

/**
 * 
 * @param param0 
 * @returns 
 * @example
 * 
  <LabelWithTextAreaField
    label={t("")}
    name={"MyFieldName"}
    value={}
    isDisabled={false}
    isMandatory={true}
    handleChange={}
    // placeholder={""}
    // rows = 4,
    error={}
  ></LabelWithTextAreaField>;
 */
const LabelWithTextAreaField: React.FC<LabelWithTextAreaFieldProps> = ({
  label,
  name,
  value,
  placeholder = "",
  isDisabled = false,
  isMandatory,
  handleChange,
  rows = 4,
  error,
}) => {
  return (
    <div>
      <LabelField title={label} isMandatory={isMandatory} />
      <InputTextAreaField
        name={name}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        handleChange={handleChange}
        rows={rows}
        error={error}
      />
    </div>
  );
};

export default LabelWithTextAreaField;
