import {
  validateForm,
  validateNumberIfNotRequired,
  validateRequired,
  validateSSN,
} from "services/validation/ValidationService";
import { getRequiredRuleForNumber, getRequiredRuleForPercentage, getRequiredRuleForTime, getRequiredRuleForVat, getRequiredRuleForWeekTime, getRequiredRulesForFieldType } from "./FieldRules";
import { isValidIBANNumber } from "services/validation/IBANValidation";

export const fieldValidator = (field: any, currentValue?: any) => {
  const validationRules = getValidationFunctions(field);
  return validation(
    field.name,
    currentValue ? currentValue.value : field.value,
    true,
    validationRules
  );
};

export const getValidationFunctions = (field: any) => {
  const rules = field?.validationRules;
  const fieldName = field?.name;
  const functions: any = [];

  if (rules && fieldName) {
    functions.push(
      ...rules.map((item: string) => getValidationFunctionProvider(item, field))
    );
  }

  return { [fieldName]: functions };
};

export const getValidationFunctionProvider = (rule: string, field: any) => {
  switch (rule) {
    case "required":
      return getRequiredRulesForFieldType(field);
    case "vat-number":
      return getRequiredRuleForVat(field);
    case "amount":
      return getRequiredRuleForNumber(field);
    case "time":
      return getRequiredRuleForTime(field);
    case "weektime":
      return getRequiredRuleForWeekTime(field);
    case "percentage":
      return getRequiredRuleForPercentage(field);
    case "ssn":
      return validateSSN;
    case "numberValidate":
      return validateNumberIfNotRequired;
    case "IBANValidate":
      return isValidIBANNumber;
    default:
      return validateRequired;
  }
};

export const validation = (
  name: string,
  value: string | boolean,
  isSingleFieldValidation: boolean = false,
  validationRules: any
) => {
  const validationErrors = validateForm(
    { [name]: value },
    validationRules,
    isSingleFieldValidation ? name : undefined,
    value
  );
  return validationErrors;
};
