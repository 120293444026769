import React, { ChangeEvent, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "components/atoms/Button";
import InputTextField from "components/atoms//InputTextField";
import { ApiCall } from "services/ApiServices";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import {
  validateRequired,
  validateTextFiledSpace,
  validateForm,
} from "services/validation/ValidationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import LoadingIcon from "utils/LoadingIcon";
import {
  EDIT_ROLE,
  CLASS_BY_PERMISSIONS,
  CREATE_ROLE,
} from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CheckBoxField from "components/atoms/CheckBoxField";

interface CheckboxProps {
  classification: string;
  userPermission: string;
  action: string;
  checked: boolean;
  onChange: (userPermission: string, action: string, checked: boolean) => void;
}

interface PermissionCred {
  id: number;
  crud_name: string;
  unique_key: string;
}

interface TabsProps {
  data: {
    permissionsWithClassifications: Record<
      string,
      { permissions: Record<number, string> }
    >;
    permissionCred: PermissionCred[];
  };
  activeTab?: string;
  onTabChange: (userPermission: string) => void;
}

interface RoleData {
  roleName: string;
  action: Record<string, Record<string, boolean>>;
  type?: string;
  roleId?: string;
  selectAll: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  classification,
  userPermission,
  action,
  checked,
  onChange,
}) => {
  const checkboxName = `${classification.toLowerCase()}-${action}`;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange(userPermission, action, checked);
  };

  return (
    <InputTextField
      className="form-check-input rounded-0 shadow-none"
      type="checkbox"
      name={checkboxName}
      checked={checked}
      handleChange={handleCheckboxChange}
    />
  );
};

const Tabs: React.FC<TabsProps> = ({ data, activeTab, onTabChange }) => (
  <>
    <div className="d-none d-md-none d-lg-block">
      <ul className="nav nav-tabs border-0 create-role-tabs">
        {Object.keys(data.permissionsWithClassifications).map(
          (classification) => (
            <li className="nav-item me-4" key={classification}>
              <Button
                title={classification}
                handleClick={() => onTabChange(classification)}
                className={`btn ${activeTab === classification
                  ? "active border-0 ps-0"
                  : "border-0 ps-0"
                  }`}
              />
            </li>
          )
        )}
      </ul>
    </div>
    <div className="d-block d-md-block d-lg-none role-dropdown">
      <div className="dropdown">
        <button
          className="btn dropdown-toggle rounded-0 shadow-none"
          type="button"
          id="classificationDropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {activeTab} {/* Display the currently selected classification */}
        </button>
        <div
          className="dropdown-menu rounded-0"
          aria-labelledby="classificationDropdown"
        >
          {Object.keys(data.permissionsWithClassifications).map(
            (classification) => (
              <button
                key={classification}
                className="dropdown-item"
                onClick={() => onTabChange(classification)}
              >
                {classification}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  </>
);

const RolePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [permission, SetPermissions] = useState<any>([]);
  const [roleData, setRoleData] = useState<RoleData>({
    roleName: "",
    action: {},
    selectAll: false,
  });
  const [data, setData] = useState<{
    permissionsWithClassifications: Record<
      string,
      { permissions: Record<number, string> }
    >;
    permissionCred: PermissionCred[];
  } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRolesAndPermission();
    if (id) {
      const fetchRoleById = async () => {
        const response = await ApiCall.getService(`${EDIT_ROLE}/${id}`, "GET");
        setMessage(response.msg);
        if (response.status === 200) {
          const { roleName, action, selectAll } = response.data;
          setRoleData({ roleName, action, selectAll });
        }
      };

      fetchRoleById();
    }
  }, [id]);

  const fetchRolesAndPermission = async () => {
    const response = await ApiCall.service(CLASS_BY_PERMISSIONS, "POST", {});
    if (response.status === 200) {      
      const permissionsWithClassifications =
        response.data.permissionsWithClassifications;
      const classificationKeys = Object.keys(permissionsWithClassifications);
      const updatedPermissions = classificationKeys.map((eachClassification) => (
        Object.keys(permissionsWithClassifications[eachClassification].permissions)
      )).flat();
      SetPermissions((prevState: any) => ([...prevState, ...updatedPermissions]));
      setData({
        permissionsWithClassifications,
        permissionCred: response.data.permissionCred,
      });
      setActiveTab(classificationKeys[0]);
    }
  };

  const handleAllCheckBoxChange = () => {
    if (!activeTab) return;
    // Prepare data
    let localArray = data?.permissionsWithClassifications[activeTab]?.permissions;
    const selectStatus = !areAllCheckboxesChecked(); // Toggle the selection status
    let newPermissions = { ...roleData?.action };
    for (let key in localArray) {
      if (localArray.hasOwnProperty(key)) {
        newPermissions[key] = { create: selectStatus, read: selectStatus, update: selectStatus, delete: selectStatus };
      }
    }
    // Perform error checking based on newPermissions
    const anyPermissionChecked = Object.values(newPermissions).some(permission =>
      permission.create || permission.read || permission.update || permission.delete
    );
    // Update state with newPermissions
    setRoleData(prevState => {
      return { ...prevState, selectAll: selectStatus, action: newPermissions };
    });
    // Set errors based on newPermissions
    setErrors(preError => {
      if (!anyPermissionChecked) {
        return { ...preError, action: 'Please select at least one action.' };
      }
      const { action, ...remainingErrors } = preError;
      return { ...remainingErrors };
    });
  };

  const handleCheckboxChange = (
    userPermission: string,
    action: string,
    checked: boolean
  ) => {
    setRoleData((prevRoleData) => {
      const updatedAction = {
        ...prevRoleData.action,
        [userPermission]: {
          ...(prevRoleData.action[userPermission] || {}),
        },
      };

      if (checked) {
        updatedAction[userPermission][action] = true;
      } else {
        delete updatedAction[userPermission][action];
        if (Object.keys(updatedAction[userPermission]).length === 0) {
          delete updatedAction[userPermission];
        }
      }

      const hasAction =  Object.values(updatedAction).some(permission =>
        permission.create || permission.read || permission.update || permission.delete
      );
      console.log(hasAction);
      
      setErrors((prevErrors) => {
        if (!hasAction) {
          return { ...prevErrors, action: 'Please select at least one action.' };
        }
        const { action, ...remainingErrors } = prevErrors;
        return { ...remainingErrors };
      });

      return {
        ...prevRoleData,
        action: updatedAction,
      };
    });
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules = {
      roleName: [validateRequired, validateTextFiledSpace],
    };

    const validationErrors = validateForm(
      { roleName: value, ...roleData.action },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    let error = false;

    if ((isSingleFieldValidation && Object.keys(errors).length > 0) && (validationErrors.hasOwnProperty(name))) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (!Object.keys(roleData.action).length) {
      error = true;
      setErrors(() => ({
        ...validationErrors,
        action: "Please select at least one action.",
      }));
    }

    return Object.keys(validationErrors).length === 0 && !error;
  };

  const handleTabChange = (userPermission: string | undefined) => {
    setActiveTab(userPermission);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (validation("roleName", roleData.roleName, true) && Object.keys(errors).length === 0) {
      if (id) {
        roleData.type = "update";
        roleData.roleId = id;
      }
      const response = await ApiCall.service(CREATE_ROLE, "POST", roleData);
      if (response.status === 200) {
        navigate("/manage-roles");
        CustomNotify({ type: "success", message: response.msg });
      } else {
        CustomNotify({ type: "error", message: response.msg });
      }
    }
    setLoading(false);
  };

  const handleRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRoleData((prevRoleData) => ({
      ...prevRoleData,
      roleName: value,
    }));

    validation(name, value, true);
  };
  const buttonText: string = id ? t("Save role") : t("Create role");
  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Role",
  }
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  
  function areAllCheckboxesChecked(): boolean {
    let status: boolean = true;
    if (activeTab) {
      let localArray: any = data?.permissionsWithClassifications[activeTab]?.permissions;
  
      if (!localArray || Object.keys(localArray).length === 0) {
        return false;
      }
  
      for (const key in localArray) {
        if (localArray.hasOwnProperty(key)) {
          const permission = roleData?.action?.[key];
          if (!permission || !permission.create || !permission.read || !permission.update || !permission.delete) {
            status = false;
            break;
          }
        }
      }
    } else {
      status = false;
    }
    return status;
  }

  return (
    data && (
      <AccessControl
        requiredPermissions={[
          permissionObject
        ]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <>
          <div className="row header-sticky position-sticky">
            <div className="col-md-12">
              <h1 className="py-4 page-title mb-0">
                {id ? t("Update") : t("Create")} {t("role")}
              </h1>
            </div>
          </div>
          <div className="form-height">
            <div className="row">
              <div className="col-6">
                <LabelWithInputField
                  name="roleName"
                  type="text"
                  value={roleData.roleName}
                  handleChange={handleRoleNameChange}
                  placeholder={t("Role name")}
                  label={t("Role name")}
                  error={errors.roleName}
                />
              </div>
            </div>
            <Tabs
              data={data}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />
            
            <div className="row mt-4">
              <div className="col-md-12">
              <div className="p-5 border rounded-4 table-custom">
                <div className="mb-2">
                  <CheckBoxField
                    label={t('Select all')}
                    name='checkAll'
                    id='checkAll'
                    onChangeHandler={handleAllCheckBoxChange}
                    isChecked={areAllCheckboxesChecked()}
                  />
                </div>
                <div className="tab-content create-roles mt-4">
                  {activeTab &&
                    data.permissionsWithClassifications[activeTab] && (
                      <div className={`tab-pane active`}>
                        <table className="table table-hover">
                          <thead>
                            <tr className="border-0 TableHeader">
                              <th className="border-0"></th>
                              {data.permissionCred.map((cred) => (
                                <th key={cred.unique_key} className="border-0">
                                  {cred.crud_name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(
                              data.permissionsWithClassifications[activeTab]
                                .permissions
                            ).map(([permissionId, permissionName]) => (
                              <tr
                                key={permissionId}
                                className="border mb-3 box-shadow"
                              >
                                <td className="text-break px-2">
                                  {t(`${permissionName}`)}
                                </td>
                                {data.permissionCred.map((cred) => {
                                  return (<td
                                    key={cred.unique_key}
                                    data-label={cred.crud_name}
                                  >
                                    <Checkbox
                                      classification={activeTab}
                                      userPermission={permissionId}
                                      action={cred.unique_key}
                                      checked={
                                        roleData.action[permissionId]?.[
                                        cred.unique_key
                                        ] || false
                                      }
                                      onChange={handleCheckboxChange}
                                    />
                                  </td>
                                  )
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
                </div>
              </div>
            </div>
            {errors.action && <div className="text-danger">{errors.action}</div>}
          </div>
          {/* Desktop */}
          <div className="d-none d-md-block d-lg-block my-4">
            <div className="row mb-4">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-roles"
                  className="text-uppercase back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              <div className="col-md-8">
                {!loading ? (
                  <Button
                    title={buttonText}
                    className="btn form-button px-3 float-end text-uppercase"
                    handleClick={handleSubmit}
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#00a5ce"
                    className="float-end"
                  />
                )}
              </div>
            </div>
          </div>
          {/* IPAD */}
          <div className="d-block d-md-none d-lg-none">
            <div className="row mb-4">
              <div className="col-md-8 mb-3">
                {!loading ? (
                  <Button
                    title={buttonText}
                    className="btn form-button rounded-0 px-3 float-end text-uppercase w-100"
                    handleClick={() => handleSubmit}
                  />
                ) : (
                  <LoadingIcon iconType="bars" color="#00a5ce" />
                )}
              </div>
              <div className="col-md-4">
                <Link
                  to="/manage-roles"
                  className="text-uppercase back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
          </div>
          {message && <div className="col-md-12">{message}</div>}
        </>
      </AccessControl>
    )
  );
};

export default RolePage;

