import React, { ChangeEvent } from "react";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";
import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";

interface MovingCostsMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  colClass?: any;
  disabled: boolean;
  data?: any;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const MovingCostsMolecule: React.FC<MovingCostsMoleculeProps> = ({
  unit,
  name,
  value,
  colClass,
  disabled,
  error,
  handleChange,
  field,
  data,
}) => {
  const renderField = () => {
    switch (field.field_name) {
      case "Do not invoice for km":
        if (field.field_id === 43) {
          return (
            <div className="row py-2">
              <RadioField
                name={field.form_section_title}
                label={t(field?.field_name)}
                ischecked={field?.field_value === "1"}
                handleChange={handleChange}
                disable={disabled}
                containerClassName={colClass}
              />
            </div>
          );
        } else {
          return (
            <div className="row py-2">
              <CheckBoxField
                label={t(field?.field_name)}
                name={field?.field_name}
                isChecked={field.field_value === "1"}
                onChangeHandler={handleChange}
                disable={disabled}
              />
            </div>
          );
        }
      case "Km compensation ($/km)":
      case "Package per day($)":
      case "":
        return (
          <>
            {field?.field_name === "Km compensation ($/km)" && (
              <RadioField
                name={field.form_section_title}
                label={t("Km compensation (€/km)")}
                ischecked={field?.field_value === "1"}
                handleChange={handleChange}
                disable={disabled}
                containerClassName={colClass}
              />
            )}
            {field?.field_name === "Package per day($)" && (
              <RadioField
                name={field.form_section_title}
                label={t("Package per day(€)")}
                ischecked={field?.field_value === "1"}
                handleChange={handleChange}
                disable={disabled}
                containerClassName={colClass}
              />
            )}
            {field?.field_name === "" && (
              <LabelWithInputFieldAndUnit
                isMandatory={false}
                value={value}
                handleChange={handleChange}
                type="text"
                readOnly={false}
                isDisabled={disabled}
                unit={
                  data?.[field?.field_id - 1]?.field_value == "1"
                    ? t("€/day")
                    : t("€/km")
                }
                error={t(error)}
              />
            )}
          </>
        );

      case "Km to be invoiced(round trip)":
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={false}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={t(error)}
            unit={t("km")}
          />
        );
      case "Extra Km to be invoiced":
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={false}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={error}
            unit={t("km")}
          />
        );
    }
  };

  return <>{renderField()}</>;
};

export default MovingCostsMolecule;
