import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface CapacityMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CapacityMolecule: React.FC<CapacityMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
}) => {
  const renderField = () => {
    return (
      <>
        {field?.field_name == "Required capacity" && (
          <LabelWithInputFieldAndUnit
            isMandatory={true}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            unit={unit}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={t(error)}
          />
        )}
      </>
    );
  };

  return <>{renderField()}</>;
};

export default CapacityMolecule;
