import React, { useContext, createContext, useReducer, ReactNode, Dispatch } from 'react';
import { initialTableProps } from '../../constants/WorkPayBillRollConstants';
import { tableReducer } from '../reducers/tableReducer';
import { PayRollAction, TableState } from '../actions/Interfaces';

interface TableProviderProps {
    children: ReactNode;
}

const TableContext = createContext<TableState | null>(null);
const TableDispatchContext = createContext<Dispatch<PayRollAction> | null>(null);

const initialTableOptions: TableState = initialTableProps;

export function TableProvider({ children }: TableProviderProps) {
    const [tableState, dispatchTable] = useReducer(tableReducer, initialTableOptions);

    return (
        <TableContext.Provider value={tableState}>
            <TableDispatchContext.Provider value={dispatchTable}>
                {children}
            </TableDispatchContext.Provider>
        </TableContext.Provider>
    );
}

export function useTable() {
    const context = useContext(TableContext);
    if (context === null) {
        throw new Error('useTable must be used within a TableProvider');
    }
    return context;
}

export function useTableDispatch() {
    const context = useContext(TableDispatchContext);
    if (context === null) {
        throw new Error('useTableDispatch must be used within a TableProvider');
    }
    return context;
}
