import LabelField from "components/atoms/LabelField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import { t } from "pages/microservices/masterData/translation/Translation";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import Button from "components/atoms/Button";
import ImageField from "components/atoms/ImageField";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import { Editor } from "components/atoms/Editor";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

import { LabelWithInputFieldAndUnit } from "pages/microservices/masterData/templates/atoms/LabelWithInputFieldAndUnit";

export const renderFormElements = (
  field?: any,
  handleChange?: any,
  formData?: any,
  errors?: any,
  formName?: string | null,
  formType?: string,
  handleDateChange?: any,
  handleAddSignature?: any,
  mode?: any,
  actionType?: any,
  apiKey?: string
) => {
  const { type, name, value } = field;
  switch (type) {
    case "text":
    case "string":
    case "number":
      return (
        <div className={value.class ? value.class : "col-sm-12 col-md-6"}>
          <LabelWithInputField
            label={value?.label}
            labelClassName={value?.labelClassName}
            name={value?.name}
            id={field?.name}
            placeholder={value?.placeholder}
            handleChange={handleChange}
            isMandatory={value?.required}
            isDisabled={mode ? true : value.disabled}
            value={formData?.[value?.name]}
            error={errors?.[value?.name]}
            type={value.type ? value.type : "text"}
            min={value?.min}
          />
        </div>
      );
    case "multi-select":      
      return (
        <div className={`${field?.display === false ? "d-none" : ""} ${value?.class ? value?.class : "col-sm-12 col-md-6" }`}>
          <SelectWithSearch
            search={value.search}
            onChange={handleChange}
            title={value.label}
            options={field.value.options}
            placeHolder={"Select"}
            isMulti={value.isMultiSelect}
            name={value.name}
            isMandatory={value.required}
            isDisabled={mode ? true : value.disabled}
            error={errors?.[value?.name]}
            value={formData?.[value?.name]}
            id={field.name}
            emptyOptionNotRequired={value?.emptyOptionNotRequired}
          />
        </div>
      );
    case "mobile-number":
      return (
        <div className="col-sm-12 col-md-6">
          <LabelField
            title={t("Phone number")}
            isMandatory={true}
            key="PhoneInput"
            htmlfor={field.name}
          />
          <div className="form-control rounded-0 shadow-none">
            <PhoneInput
              name={value.name}
              label={value.label}
              required={value.required}
              defaultCountry="BE"
              international
              initialValueFormat="national"
              placeholder="Phone number"
              onChange={handleChange}
              value={formData?.[value?.name] ? ("+" + formData?.[value?.name]?.replace(/\+/g, '')) : ""}
              id={field.name}
              disabled={mode}
            />
          </div>
          <div className="height-20 mb-2">
            {errors?.[value?.name] && (
              <span className="text-danger">{errors?.[value?.name]}</span>
            )}
          </div>
        </div>
      );

    case "status":
      if (formName === "User" && formType == "create") {
        return null;
      }
      return (
        <div className="col-sm-12 col-md-6">
          <div className="col-sm-12 col-md-6">
            <LabelField
              title={t("Status")}
              isMandatory={value.required}
              key="Status"
              htmlfor={field.name}
            />
            <div className="row">
              <div className="col-md-12">
                {field.value.options.map((statusValue: any) => (
                  <RadioField
                    key={statusValue.value}
                    name={value?.name}
                    value={statusValue?.value}
                    ischecked={
                      formData[value?.name] == statusValue.value ? true : false
                    }
                    handleChange={handleChange}
                    label={statusValue.label}
                    className={statusValue.value === "1" ? "mx-3" : ""}
                    id={field.name}
                  />
                ))}
              </div>
              <div className="height-20 mb-2">
                {errors?.[value?.name] && (
                  <span className="text-danger">{errors?.[value?.name]}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case "text-hidden":
      return (
        <div className="col-sm-12 col-md-6 d-none">
          <LabelWithInputField
            label={value.label}
            name={value.name}
            id={field.name}
            placeholder={value.placeholder}
            handleChange={handleChange}
            isMandatory={value.required}
            value={formData?.[value?.name]}
            error={errors?.[value?.name]}
          />
        </div>
      );
    case "file":
      let uploadedUrl = "";
      {
        if (
          formData?.uploadPhoto?.length != 0 &&
          typeof formData?.uploadPhoto === "string"
        ) {
          try {
            let url = JSON.parse(formData?.uploadPhoto);
            const { fileName, accessUrl } = url;
            uploadedUrl = accessUrl;
          } catch (error) {
            console.log(error);
          }
        }
      }
      return (
        <div
          className={`d-flex ${field.value.class
            ? field.value.class
            : "col-sm-12 col-md-6 col-xl-4"
            } `}
        >
          {uploadedUrl ? (
            <ImageField
              altText="Profile picture"
              className={`img-fluid object-fit-contain  me-3 ${field.value.class_img}`}
              src={uploadedUrl}
            />
          ) : (
            ""
          )}
          <div className="w-100">
            <MaxiconFileUpload
              label={value.label}
              name={value.name}
              id={value.name}
              isMandatory={false}
              edit={false}
              fileId={null}
              fileObj={formData?.[value?.name]}
              multiple={false}
              uploadPath="candidate-certificate"
              formats={value.formats}
              handleChange={handleChange}
              maxFileSize={10000}
              mode={mode ? true : value.disabled}
            />
          </div>
          <span className="text-danger">
            {/* {state.studiesErrors[index]?.certificateId} */}
          </span>
        </div>
      );
    case "date":
      return (
        <div className="col-sm-12 col-md-6 position-relative">
          <Calender
            onChange={(date) => handleDateChange(date, value.name)}
            label={t(value.label)}
            isMandatory={value.required}
            name={value.name}
            error={errors?.[value?.name]}
            selectedDate={formData?.[value?.name]}
            isDisabled={mode ? true : value.disabled}
            maxDate={value.maxdate ? new Date(Date.now() - 86400000) : null}
          ></Calender>
        </div>
      );
    case "signature":
      return (
        <div
          className={`d-flex ${field.value.class
            ? field.value.class
            : "col-sm-12 col-md-6 col-xl-4"
            } `}
        >
          {formData?.signature ? (
            <div
              className="border rounded me-2 bg-white"
              style={{ height: "100px" }}
            >
              <ImageField
                altText="Signature"
                className={`img-fluid object-fit-contain h-100 ${field.value.class_img}`}
                src={formData.signature}
              />
            </div>
          ) : (
            ""
          )}
          <div>
            <LabelField title={t("Signature")} />
            <div>
              <Button
                type="button"
                title={
                  formData.signature ? t("Edit Signature") : t("Add Signature")
                }
                handleClick={handleAddSignature}
                className="btn me-3 form-button shadow-none  text-uppercase"
              />
            </div>
          </div>
        </div>
      );

    case "text-area":
      return (
        <div
          className={`col-sm-12 col-md-12 ${field?.class} ${field?.display === false ? "d-none" : ""
            }`}
        >
          <TextAreaMolecule
            label={value.label}
            name={value.name}
            id={value.name}
            placeholder={value.placeholder}
            handleChange={handleChange}
            isMandatory={value.required}
            value={formData?.[value?.name]}
            error={errors?.[value?.name]}
            isDisabled={mode ? true : value.disabled}
          />
        </div>
      );

    case "editor":
      return (
        <Editor
          key={field.name}
          title={field.value.title}
          name={field.value.name}
          value={formData.template ? formData?.template : ""}
          index={1}
          styleMargin=""
          handleChange={handleChange}
          CustomStyle={field.style}
          required={field.value.required}
          isDisabled={mode || actionType === "clone" ? true : value?.disabled}
          error={errors?.[value?.name]}
        />
      );

    // case "text-with-unit":
    //   return (
    //     <div
    //       className={`${ value?.display === false ? "d-none" : ""} ${
    //          value?.class ?  value?.class : "col-sm-12 col-md-6 col-xl-4"
    //       }`}
    //     >
    //       <LabelWithInputFieldAndUnit
    //         label={ value?.label}
    //         name={ value?.name}
    //         id={ value?.name}
    //         placeholder={ value?.placeholder}
    //         handleChange={handleChange}
    //         isMandatory={ value?.required}
    //         value={ value?.value}
    //         unit={ value?.unit}
    //         error={ value?.error}
    //         containerClassName={"col-12"}
    //       />
    //     </div>
    //   );

    case "location":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-6"
            }`}
        >
          <label className="fs-5">{value.label}</label>
          <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
            <Map
              style={{ width: "100%", height: "20vh" }}
              defaultCenter={(formData?.location !== null) ? formData?.location : value?.value}
              defaultZoom={5}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              center={formData?.location}
              onClick={handleChange}
            />
            <Marker position={formData?.location} />
          </APIProvider>
        </div>
      );
    default:
      return null;
  }
};
