import { selectAuth } from "features/auth/AuthSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GET_USER, UPDATE_USER, REGISTER } from "routes/ApiEndpoints";
import AccessControl from "services/AccessControl";
import FormBuilder from "services/form/FormBuilder";
import { UserFormValidation, EditFormValidation } from "services/validation/ValidationRules";

interface MUserCreationFormProps {
  id?: number | string;
}

const CreateUser: React.FC<MUserCreationFormProps> = ({ id }) => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();
  const permission = path === "/user/create" ? "create" : "edit";
  const permissionObject: any = {
    permission: "Users",
  }
  permissionObject[permission] = true
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        permissionObject
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <FormBuilder
        actionType={path === "/user/create" ? "create" : "edit"} // action type create or edit
        entryId={id || routeId} // required only if actionType is edit
        getDataAPI={GET_USER} //required only if actionType is edit
        SaveDataAPI={path === "/user/create" ? REGISTER : UPDATE_USER} //Post's data to this endpoint
        formName={"User"} //Form name
        formType={path === "/user/create" ? "create" : "edit"}
        title={path === "/user/create" ? "Create user" : "Edit user"} // Form title
        redirect={"/manage-users"} // After submit redirect to this screen/route
        validationRules={
          path === "/user/create" ? UserFormValidation : EditFormValidation
        } //Validation rules to validate form on submit
      />
    </AccessControl>
  );
};

export default CreateUser;
