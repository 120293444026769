import React from "react";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_CONFIG_ELEMENTS_MANAGE } from "constants/Paths";
import { useLocation, useParams } from "react-router-dom";
import {
  MASTER_DATA_CONFIG_ELEMENT_BY_ID,
  MASTER_DATA_CONFIG_ELEMENT_CREATE,
  MASTER_DATA_CONFIG_FORM_ELEMENT,
} from "routes/ApiEndpoints";
import { BusinessFormValidation } from "services/validation/ValidationRules";
import ConfigFormBuilder from "services/form/ConfigFormBuilder";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface FormProps {
  id?: number | string;
}
const ConfigForm: React.FC<FormProps> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const element = queryParams.has("element") ? queryParams.get("element") : "";
  const id = queryParams.has("id") ? queryParams.get("id") : "";
  const permissionType = (id === "") ? "create" : "update";
  const permissionObject: any = {
    permission: "Config elements"
  }
  permissionObject[permissionType] = true
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        permissionObject
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <ConfigFormBuilder
          actionType={id === "" ? "Create" : "Edit"} // action type create or edit
          entryId={id} // required only if actionType is edit
          getDataAPI={MASTER_DATA_CONFIG_ELEMENT_BY_ID} //required only if actionType is edit
          getFormUrl={MASTER_DATA_CONFIG_FORM_ELEMENT} //Post's data to this endpoint
          SaveDataAPI={MASTER_DATA_CONFIG_ELEMENT_CREATE} //Post's data to this endpoint
          formName={element} //Form name
          title={id === "" ? "Create" : "Edit"} // Form title
          redirect={PATH_CONFIG_ELEMENTS_MANAGE} // After submit redirect to this screen/route
          validationRules={BusinessFormValidation} //Validation rules to validate form on submit
          microserviceName={M_MASTER_DATA} //micro service
        />
      </>
    </AccessControl>
  );
};

export default ConfigForm;
