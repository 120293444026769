import React from "react";
import ManageShift from "../shifts/Manage";

const ManageTimeTable: React.FC = ({}) => {
  return (
    <>
      <div>
        <ManageShift isTimeTable={true}/>
      </div>
    </>
  );
};

export default ManageTimeTable;
