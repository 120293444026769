import { useFormContext } from "pages/microservices/companyCreation/context/Context";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import CloseFile from "static/images/CloseFile";
import Button from "components/atoms/Button";
import { COMPANY_DETAILS, REGISTER_COMPANY } from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  scrollToTop,
  validateForm,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import { ValidationRules } from "utils/TypeAnnotations";

const Address = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, type, checked } = event.target;
    const processedValue = type === "number" ? String(value) : value;
    dispatch({
      type: "UPDATE_COMPANYLOCATION_FIELD",
      field: name,
      value: value,
      index,
    });
    validation(name, processedValue, index, true);
  };
  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      locationName: [validateRequired],
      locationStreet: [validateRequired],
      locationNumber: [validateRequired],
      //  locationBox: string;
      locationZipCode: [validateRequired],
      locationCountry: [validateSelectField],
      locationCity: [validateRequired],
      phoneNumber: [validateRequired],
    };
    const validationErrors = validateForm(
      { ...state.companyLocation[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.dynamicLocationErrors[index][name] = "";
    } else {
      state.dynamicLocationErrors[index][name] = validationErrors[name];
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_COMPANYLOCATION_FIELD",
      field: name,
      index: index,
      value: selectedOption,
    });
    validation(name, selectedOption, index, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: true,
      // isValid: validation.isValid,
      type: validation.type,
    });
  };
  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_LOCATION", indexToRemove });
  };

  const handleAddLocation = () => {
    dispatch({ type: "ADD_LOCATION" });
  };

  const fetchDataFromUrl = async () => {
    try {
      const url = !companyId
        ? COMPANY_DETAILS
        : `${COMPANY_DETAILS}/${companyId}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_COMPANY_CREATION,
        false
      );

      if (response?.status === 200) {
        const { locations } = response.data;
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: locations,
          field: "locationList",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      basic: state.basic,
      address: state.address,
      companyLocation: state.companyLocation,
    };
    formData.basic.companyId = companyId;
    if (state.basic.vat !== "" && state.basic.companyName !== "") {
      const url = !companyId
        ? REGISTER_COMPANY
        : `${REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: "Draft saved successfully!",
          });
          navigate("/manage-companies");
        } else {
          const locationResponse = response?.locationId;
          locationResponse &&
            locationResponse.length > 0 &&
            locationResponse.map((item: any, index: number) =>
              dispatch({
                type: "UPDATE_COMPANYLOCATION_FIELD",
                field: "id",
                value: item.locationId,
                index: item.id,
              })
            );
          fetchDataFromUrl();
          navigate(`/company/${response.companyId}`);
        }
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="create-form-height-company">
          {
            <div className="form-border p-5 mb-3">
              {state.companyLocation.map((companyLocation, index) => (
                <div
                  className="field-set border px-3 pt-4 pb-2 mb-4 rounded-2"
                  key={index}
                >
                  {index > 0 && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-block d-md-block d-lg-none">
                          {!isViewMode && (
                            <Button
                              title="Remove"
                              handleClick={() => handleRemoveContact(index)}
                              className="link-button float-end text-uppercase rounded-0 shadow-none my-2"
                            />
                          )}
                        </div>
                        <div className="d-none d-md-none d-lg-block">
                          {!isViewMode && (
                            <span
                              title="Delete"
                              onClick={() => handleRemoveContact(index)}
                              className="table-action-icons cursor-pointer float-end text-uppercase rounded-0 shadow-none"
                            >
                              <CloseFile />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationName"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationName ?? ""}
                        id={`locationName-${index}`}
                        label="Location name"
                        placeholder="Location name"
                        error={state.dynamicLocationErrors[index]?.locationName}
                        className="form-control shadow-none rounded-0"
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="phoneNumber"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.phoneNumber ?? ""}
                        id={`phoneNumber-${index}`}
                        label="Phone number"
                        placeholder="Phone number"
                        error={state.dynamicLocationErrors[index]?.phoneNumber}
                        className="form-control shadow-none rounded-0"
                        isDisabled={isViewMode}
                      />
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationStreet"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationStreet ?? ""}
                        id={`street-${index}`}
                        label="Street"
                        placeholder="Street"
                        error={
                          state.dynamicLocationErrors[index]?.locationStreet
                        }
                        className="form-control shadow-none rounded-0"
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationNumber"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationNumber ?? ""}
                        id={`number-${index}`}
                        label="Number"
                        placeholder="Number"
                        error={
                          state.dynamicLocationErrors[index]?.locationNumber
                        }
                        className="form-control shadow-none rounded-0"
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={false}
                        name="locationBox"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationBox}
                        id={`box-${index}`}
                        label="Box"
                        type="text"
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationZipCode"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationZipCode}
                        id={`zipCode-${index}`}
                        label="Zip code"
                        type="text"
                        error={
                          state.dynamicLocationErrors[index]?.locationZipCode
                        }
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationCity"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationCity}
                        id={`city-${index}`}
                        label="City"
                        type="text"
                        error={state.dynamicLocationErrors[index]?.locationCity}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Country"
                        name="locationCountry"
                        isMandatory={true}
                        search={true}
                        options={state.countryList}
                        placeHolder="Select"
                        value={companyLocation.locationCountry}
                        onChange={(e) =>
                          handleSelectChange(e, "locationCountry", index)
                        }
                        isMulti={false}
                        className="select-field"
                        error={
                          state.dynamicLocationErrors[index]?.locationCountry
                        }
                        isDisabled={isViewMode}
                      />
                    </div>

                    <div className="col-lg-12">
                      <LabelWithTextAreaField
                        name="locationInfo"
                        handleChange={(event) =>
                          handleFieldChange(event, index)
                        }
                        value={companyLocation.locationInfo ?? ""}
                        label="Info"
                        isDisabled={isViewMode}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="row my-4">
                <div className="col-md-12">
                  {!isViewMode && (
                    <Button
                      title="+ Add another"
                      handleClick={handleAddLocation}
                      className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                    />
                  )}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="">
          {!isViewMode && <Navigation validStatus={validStatus} />}
        </div>
      </form>
    </>
  );
};

export default Address;
