import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { MANAGE_TODO_DATA } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageTodoPage from "./Index";

export interface Todo {
  id?: number;
  todo_type: string;
  entity_type?: any;
  entity_id?: number;
  description?: string;
  owner?: number;
  assigned_by?: number;
  assigned_to?: any;
  todo_status?: any;
  feedback?: string;
  next_task?: string;
}
export interface TodoObject {
  todoData: Todo[] | undefined;
  entityTypeDropdown: [];
  todoStatusDropdown: [];
}

interface DataProps {
  page: number;
  search?: any;
}

const ManageTodo: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [Todo, setTodo] = useState<TodoObject>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    start_date: queryParams.get('start_date') ?? "",
    end_date: queryParams.get('end_date') ?? "",
    entity_type: queryParams.get("entity_type") ?? "",
    todo_type: queryParams.get("todo_type") ?? "",
    info: queryParams.get("info") ?? "",
    business_unit: queryParams.get("business_unit") ?? "",
    assigned_to: queryParams.get("assigned_to") ?? "",
    todo_status: queryParams.get("todo_status") ?? ""
  });
  const navigate = useNavigate();

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };

    fetchData(data);
  }, [currentPage, submit]);

  const fetchData = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        MANAGE_TODO_DATA,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        setTodo((prevData) => ({
          ...prevData,
          todoData: response?.data,
          entityTypeDropdown: response?.data?.entityTypeDropdown,
          todoStatusDropdown: response?.data?.todoStatusDropdown
        }));
        setTotalPages(response.totalPages);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const createQueryParams = (data: Todo) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "" && value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleDeleteData = (deleteId: number) => {
    setTodo((prevData: any) => {
      return ({
        ...prevData,
        todoData: prevData ? prevData.todoData.filter((todo: any) => todo.id !== deleteId) : undefined,
      })
    }
    )
    setCurrentPage(1);
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        start_date: "",
        end_date: "",
        entity_type: "",
        todo_type: "",
        info: "",
        business_unit: "",
        assigned_to: "",
        todo_status: ""
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  return (
    <ManageTodoPage
      todoObject={Todo}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      handleSubmitAction={handleSubmit}
      searchData={search}
    />
  );
};

export default ManageTodo;
