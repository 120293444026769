import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
  validateTimeFormat,
  validateTimeRange,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxField from "components/atoms/CheckBoxField";
import { ApiCall } from "services/ApiServices";
import { CREATE_SHIFT, GET_SHIFT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_MANAGE_SHIFTS } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface InputItem {
  id?: number;
  shift_name: string | any;
  from: any;
  to: any;
  pause: any;
  break_invoiced: any;
  break_paid: any;
}

interface ValidationRules {
  [key: string]: Function[];
}

function CreateShifts() {
  const [formData, setFormData] = useState<any>({
    shift_name: "",
    from: "",
    to: "",
    pause: "",
    break_invoiced: true,
    break_paid: "",
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<InputItem[]>([]);
  const [errors, setErrors] = useState<InputItem | any>({
    shift_name: "",
    from: "",
    to: "",
    pause: "",
    break_invoiced: "",
    break_paid: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const editData = { id: id };
          const response = await ApiCall.service(
            GET_SHIFT,
            "POST",
            editData,
            false,
            M_MASTER_DATA
          );
          if (response?.status === 200) {
            console.log(response?.data);
            const data = { ...response.data,
              from: response.data.from.slice(0, 5),
              to: response.data.to.slice(0, 5),
              pause: response.data.pause.slice(0, 5),
            };
            setFormData(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [id]);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData: any) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    }

    validateInput(name, value);
  };

  const validateInput = (
    name: string | any,
    value: string | boolean | any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      shift_name: [validateRequired],
      from: [validateRequired, validateTimeFormat],
      to: [validateRequired, validateTimeFormat],
      pause: [validateRequired],
    };

    // Validate the form data based on the rules
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );
    if (name === "pause") {
      const timeRangeError = validateTimeRange(formData?.from, formData.to, value);
      if (timeRangeError) {
        validationErrors[name] = timeRangeError;
      }
    } else if (name === "from" && formData.to) {
      const timeRangeError = validateTimeRange(value, formData.to);
      if (timeRangeError) {
        validationErrors[name] = timeRangeError;
      }
    } else if (name === "to" && formData.from) {
      const timeRangeError = validateTimeRange(formData.from, value);
      if (timeRangeError) {
        validationErrors[name] = timeRangeError;
      }
    }

    // Update errors state based on the validation results
    if (!isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRemove = (index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const handleEdit = (index: number) => {
    const editedData = tableData[index];
    setFormData(editedData);
    setEditIndex(index);
  };

  const getRowClass = (index: any) => {
    return editIndex === index ? "highlighted-row" : "";
  };

  const handleAddClick = () => {
    const isFormValid =
      validateInput("shift_name", formData.shift_name, true) &&
      validateInput("from", formData.from, true) &&
      validateInput("to", formData.to, true) &&
      validateInput("pause", formData.pause, true);

    if (isFormValid) {
      // Check if editing or adding new entry
      if (editIndex !== null) {
        // Editing existing entry
        const updatedTableData = [...tableData];
        updatedTableData[editIndex] = formData;
        setTableData(updatedTableData);

        // Reset the form and edit state
        setFormData({
          shift_name: "",
          from: "",
          to: "",
          pause: "",
          break_invoiced: true,
          break_paid: "",
        });
        setEditIndex(null);
      } else {
        setTableData((prevTableData) => [...prevTableData, formData]);
        setFormData({
          shift_name: "",
          from: "",
          to: "",
          pause: "",
          break_invoiced: true,
          break_paid: "",
        });
        setErrors({
          shift_name: "",
          from: "",
          to: "",
          pause: "",
          break_invoiced: true,
          break_paid: "",
        });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let finalData = [...tableData];
    let isFormValid = true;

    isFormValid =
      validateInput("shift_name", formData.shift_name, true) &&
      validateInput("from", formData.from, true) &&
      validateInput("to", formData.to, true) &&
      validateInput("pause", formData.pause, true);

    if (Object.keys(formData).length !== 0) {
      finalData.push(formData);
    }

    if (isFormValid) {
      const response = await ApiCall.service(
        CREATE_SHIFT,
        "POST",
        finalData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: `Shifts ${id ? 'updated' : 'created'} successfully` });
        navigate(`${PATH_MANAGE_SHIFTS}`); return;
      }
      CustomNotify({ type: "error", message: "Error while creating shifts" });
    }
  };

  const permissionType = id ? "update" : "create"
  const permissionObject: any = {
    permission: "Shifts",
  }
  permissionObject[permissionType] = true
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        permissionObject
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">{id ? t("Edit shifts") : t("Create shifts")}</h1>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="">
            <div className="form-border p-5">
              <div className="row">
                <div className="form-group col-md-12">
                  <LabelWithInputField
                    isMandatory={true}
                    name="shift_name"
                    handleChange={(e) => changeHandler(e)}
                    value={formData.shift_name}
                    id="shift_name"
                    label={t("Name")}
                    placeholder={t("Name")}
                    type="text"
                    error={errors.shift_name}
                  />
                </div>
              </div>
              <div className="row">
                <strong>{t("Shift timings")}</strong>
                <div className="form-group col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="from"
                    handleChange={(e) => changeHandler(e)}
                    value={formData.from}
                    id="from"
                    label={t("From")}
                    placeholder={t("From")}
                    type="time"
                    error={errors.from}
                  />
                </div>
                <div className="form-group col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="to"
                    handleChange={(e) => changeHandler(e)}
                    value={formData.to}
                    id="to"
                    label={t("To")}
                    placeholder={t("To")}
                    type="time"
                    error={errors.to}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="pause"
                    handleChange={(e) => changeHandler(e)}
                    value={formData.pause}
                    id="pause"
                    label={t("Pause")}
                    placeholder={t("Pause")}
                    type="time"
                    error={errors.pause}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <CheckBoxField
                    name="break_invoiced"
                    label="Break Invoiced"
                    isChecked={formData.break_invoiced}
                    id="break_invoiced"
                    onChangeHandler={changeHandler}
                  />
                  <CheckBoxField
                    name="break_paid"
                    label="Break Paid"
                    isChecked={formData.break_paid}
                    id="break_paid"
                    onChangeHandler={changeHandler}
                    className="ps-4"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-12 text-lg-end text">
              {id === undefined && (
                <Button
                  title={
                    editIndex !== null ? t("Save") : t("+ Add another shift")
                  }
                  handleClick={handleAddClick}
                  className="btn form-button rounded-0 shadow-none text-uppercase px-3 button-widths"
                />
              )}
            </div>
          </div>
        </form>

        {/* table below the form */}
        {tableData.length > 0 && (
          <table className="table table-bordered form-border p-3">
            <thead className="TableHeader">
              <tr>
                <th className="col-md-2">{t("Name")}</th>
                <th className="col-md-2">{t("From")}</th>
                <th className="col-md-2">{t("To")}</th>
                <th className="col-md-2">{t("Pause")}</th>
                <th className="col-md-2">{t("Break Invoiced")}</th>
                <th className="col-md-2">{t("Break Paid")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className={getRowClass(index)}>
                  <td>{item.shift_name}</td>
                  <td>{item.from}</td>
                  <td>{item.to}</td>
                  <td>{item.pause}</td>
                  <td>
                    {item.break_invoiced ? (
                      <FontAwesomeIcon icon="check" style={{ color: "green" }} />
                    ) : (
                      <FontAwesomeIcon icon="times" style={{ color: "red" }} />
                    )}
                  </td>
                  <td>
                    {item.break_paid ? (
                      <FontAwesomeIcon icon="check" style={{ color: "green" }} />
                    ) : (
                      <FontAwesomeIcon icon="times" style={{ color: "red" }} />
                    )}
                  </td>

                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => handleEdit(index)}
                      style={{
                        cursor: "pointer",
                        color: "#808080",
                        marginRight: 10,
                      }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={t("Edit")}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleRemove(index)}
                      style={{ cursor: "pointer", color: "#808080" }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={t("Delete")}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="d-flex justify-content-between my-3 align-items-center">
          <div className="">
            <Link
              to={PATH_MANAGE_SHIFTS}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>

          <div className="">
            <Button
              title={t("Save")}
              handleClick={handleSubmit}
              className="btn form-button  shadow-none text-uppercase px-3 button-widths"
            />
          </div>
        </div>
      </>
    </AccessControl>
  );
}

export default CreateShifts;
