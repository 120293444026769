import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { GET_BUSINESS_UNIT } from "routes/ApiEndpoints";
import ManageBusinessUnitPage from ".";

export interface BusinessUnit {
  id?: number;
  business_unit_name: string;
  business_unit_number: number | string;
  company: string;
  template: string;
  company_id?: number;
  phone_number: string | number;
  address: string;
  status: number | string;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: BusinessUnit | null;
}

const ManageBusinessUnit: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const statusValue = queryParams.get("status");
  
  const status: number | undefined =
    statusValue !== null ? parseInt(statusValue) : undefined;
  const [businessUnit, setBusinessUnit] = useState<BusinessUnit[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState<BusinessUnit>({
    business_unit_name: queryParams.get("name") ?? "",
    business_unit_number: queryParams.get("number") ?? "",
    company: queryParams.get("company") ?? "",
    template: queryParams.get("template") ?? "",
    phone_number: queryParams.get("phone_number") ?? "",
    address: queryParams.get("address") ?? "",
    status: queryParams.get("status") ?? "",
  });
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
    };

    fetchBusinessUnit(data);
  }, [currentPage, submit]);

  const fetchBusinessUnit = async (data: DataProps) => {
    const response = await ApiCall.service(GET_BUSINESS_UNIT, "POST", data, false,'m-masterdata' );
    
    if (response?.status === 200) {
      setBusinessUnit(response.data);
      setTotalPages(response.totalPages);
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleDeleteData = (deleteId: number) => {
    setBusinessUnit((prevdata) =>
      prevdata.filter((businessUnit) => businessUnit.id !== deleteId)
    );
    setCurrentPage(1);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: BusinessUnit) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        business_unit_name: "",
        business_unit_number: "",
        company: "",
        template: "",
        phone_number: "",
        address: "",
        status: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }

    setSubmit(!submit);
    setCurrentPage(1);
  };
  return (
    <ManageBusinessUnitPage
      businessUnitObj={businessUnit}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      itemsPerPage={itemsPerPage}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      handleSubmitAction={handleSubmit}
      searchData={search}
    />
  );
};

export default ManageBusinessUnit;
