import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import RadioField from "components/atoms/RadioField";
import InputTextfield from "components/atoms/InputTextField";
import { Navigation } from "../formNavigation/Navigation";

const CompositionTab = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, index:number) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      const newValue = parseInt(value);
      dispatch({ type: "UPDATE_COMPOSITION_FIELD", field: 'inclusiveOption', value: newValue, index:index });
    } else {
      dispatch({ type: "UPDATE_COMPOSITION_FIELD", field: name, value: value, index:index });
    }
  };  

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="create-form-height">
          <div className="form-border p-3">
            <div className="row">
              <div className="col-md-12">
                <table className="table composition-co-efficient-table">
                  <thead>
                    <tr className="border-0">
                      <th className="border-0">Composition co-efficient</th>
                      <th className="border-0 text-center">Included</th>
                      <th className="border-0 text-center">Not-included</th>
                      <th className="border-0">Value</th>
                      <th className="border-0">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.compositionData &&
                      state.compositionData.map((item: any, index: number) => (
                        <tr className="align-middle" key={index}>
                          <td className="border-0 text-break">
                            {item.title}
                          </td>
                          <td className="border-0 text-break text-center">
                            <RadioField
                              handleChange={(e) => changeHandler(e, index)}
                              label=""
                              ischecked={item.inclusiveOption === 0}
                              name={`inclusive-${item.extraInfoId}`}
                              value={0}
                            />
                          </td>
                          <td className="border-0 text-break text-center">
                            <RadioField
                              handleChange={(e) => changeHandler(e, index)}
                              ischecked={item.inclusiveOption === 1}
                              label=""
                              name={`inclusive-${item.extraInfoId}`}
                              value={1}
                            />
                          </td>
                          <td className={`border-0 text-break ${item.negotiability === 1 ? 'border-negotiable' : ''}`}>
                            <div className="input-group">
                              <InputTextfield
                                name="max"
                                handleChange={(event) => changeHandler(event, index)}
                                value={item.max ?? ''}
                                id={`max-${index}`}
                                type="text"
                                className="form-control shadow-none rounded-0"
                              />
                              {item.priceOrCoeff === 0 && (
                                <span className="input-group-text rounded-0" id="basic-addon1">€</span>
                              )}
                            </div>
                          </td>
                          <td className="border-0 text-break">
                            <InputTextfield 
                              handleChange={(e) => changeHandler(e, index)}
                              name="remarks"
                              value={item.remarks?? ''}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};
export default CompositionTab;
