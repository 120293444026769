import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { PC_GET } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManagePC from "./Manage";

export interface PC {
  id?: number;
  name: string;
}

interface DataProps {
  page: number;
  search?: any;
}

const ManagePCPage: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [Pc, setPc] = useState<PC[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    name: queryParams.get("name") ?? "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };

    fetchData(data);
  }, [currentPage, submit]);

  const fetchData = async (data: DataProps) => {
    const response = await ApiCall.service(
      PC_GET,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      setPc(response.data);
      setTotalPages(response.totalPages);
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: PC) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleDeleteData = (deleteId: number) => {
    setPc((prevdata) =>
      prevdata.filter((businessUnit) => businessUnit.id !== deleteId)
    );
    setCurrentPage(1);
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        name: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  return (
    <ManagePC
      pcObject={Pc}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      handleSubmitAction={handleSubmit}
      searchData={search}
    />
  );
};

export default ManagePCPage;
