import SelectWithSearch from "components/atoms/SelectWithSearch";
import React from "react";
import { t } from "../translation/Translation";
import { TodoData, commonData } from "./Create";
import Calender from "components/molecules/Calender";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";

interface TodoProps {
    dropdownOptions: TodoData,
    handleSelectChange: (selectedOption: any, fieldName: string, type: string) => void,
    commonData: commonData,
    commonErrors: { [key: string]: string },
    handleDateChange: (date: any, fieldName: string, type: string) => void,
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, type: string) => void,
    type: string,
    isEdit: boolean,
    url: string | null,
    assignedTo: number|null
}
const Todo: React.FC<TodoProps> = ({
    dropdownOptions,
    handleSelectChange,
    commonData,
    commonErrors,
    handleDateChange,
    handleChange,
    type,
    isEdit,
    url,
    assignedTo
}) => {

    return (
        <>
            <div className="form-border p-5 pb-4">
                <div className="row">
                    <div className="row">
                        <div className="col">
                            <SelectWithSearch
                                title={t("Entity type")}
                                isMandatory={true}
                                search={true}
                                options={dropdownOptions?.entityType}
                                onChange={(e) => handleSelectChange(e, "entity_type", type)}
                                isMulti={false}
                                name="entity_type"
                                value={commonData.entity_type}
                                error={commonErrors.entity_type}
                                placeHolder="Select"
                                isDisabled={url ? true : isEdit}
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Entity name")}
                                isMandatory={true}
                                search={true}
                                options={dropdownOptions?.entityRelatedEntityName}
                                onChange={(e) => handleSelectChange(e, "entity_id", type)}
                                isMulti={false}
                                name="entity_id"
                                value={commonData.entity_id}
                                error={commonErrors.entity_id}
                                placeHolder="Select"
                                isDisabled={url ? true : isEdit}
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Business unit")}
                                isMandatory={true}
                                search={true}
                                options={dropdownOptions?.bussinessUnit}
                                onChange={(e) => handleSelectChange(e, "bussiness_unit", type)}
                                isMulti={true}
                                name="bussiness_unit"
                                value={commonData.bussiness_unit}
                                error={commonErrors.bussiness_unit}
                                placeHolder="Select"
                                isDisabled={url ? true : isEdit}
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Entity related todo type")}
                                isMandatory={true}
                                search={true}
                                options={dropdownOptions?.entityRelatedTodoType}
                                onChange={(e) => handleSelectChange(e, "todo_type_id", type)}
                                isMulti={false}
                                name="todo_type_id"
                                value={commonData.todo_type_id}
                                error={commonErrors.todo_type_id}
                                placeHolder="Select"
                                isDisabled={isEdit}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className=" position-relative col">
                        <Calender
                            onChange={(date) => handleDateChange(date, "date", type)}
                            label={t("Date")}
                            isMandatory={true}
                            name="date"
                            error={commonErrors.date}
                            selectedDate={commonData.date}
                            isDisabled={false}
                            maxDate={commonData.date ? new Date(Date.now() - 86400000) : new Date()}
                        ></Calender>
                    </div>
                    <div className="col">
                        <SelectWithSearch
                            title={t("Assigned to")}
                            isMandatory={true}
                            search={true}
                            options={dropdownOptions?.assignedTo}
                            onChange={(e) => handleSelectChange(e, "assigned_to", type)}
                            isMulti={false}
                            name="assigned_to"
                            value={commonData.assigned_to}
                            error={commonErrors.assigned_to}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col">
                        <SelectWithSearch
                            title={t("Todo status")}
                            isMandatory={true}
                            search={true}
                            options={dropdownOptions?.todoStatus}
                            onChange={(e) => handleSelectChange(e, "todo_status", type)}
                            isMulti={false}
                            name="todo_status"
                            value={commonData.todo_status}
                            error={commonErrors.todo_status}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div>
                    <LabelWithTextAreaField
                        label={t("Info")}
                        name="info"
                        value={commonData.info}
                        placeholder={t("info")}
                        isDisabled={false}
                        isMandatory={true}
                        handleChange={(e) => handleChange(e, type)}
                        error={commonErrors.info}
                    />
                </div>
            </div>
        </>
    );
}

export default Todo;