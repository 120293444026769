import Button from "components/atoms/Button";
import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import Pagination from "components/atoms/Pagination";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import AddPartenaCalculation from "./AddPartenaCalculation";
import LabelField from "components/atoms/LabelField";
import { useLocation, useNavigate } from "react-router-dom";
import { PartenaData, initialExportSearch } from "./PartenaInterface";

const ManagePartena = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useState(initialExportSearch);
    const [partenaData, setPartenaData] = useState<PartenaData[]>();
    const [showModal, setShowModal] = useState(false);

    //state for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        const postData: any = {
            searchData: search
        }
        //    const response =  ApiCall.service("getPartenaDetails", postData,"POST", true, M_MASTER_DATA);
        const response = {
            modalStatus: true,
            data: [
                {
                    id: 1,
                    month: 202407,
                    businessUnit: 2,
                    noOfEmployees: 10,
                    numberSent: 20,
                    quantityNotSent: 30,
                    createdBy: "priyanka",
                    creationTime: '2024 -08 - 13 19:05',
                    status: 'pending'
                },

                {
                    id: 2,
                    month: 202407,
                    businessUnit: 2,
                    noOfEmployees: 10,
                    numberSent: 20,
                    quantityNotSent: 30,
                    createdBy: "priyanka",
                    creationTime: '2024 -08 - 13 19:05',
                    status: 'true'
                },
                {
                    id: 3,
                    month: 202407,
                    businessUnit: 2,
                    noOfEmployees: 10,
                    numberSent: 20,
                    quantityNotSent: 30,
                    createdBy: "priyanka",
                    creationTime: '2024 -08 - 13 19:05',
                    status: 'pending'
                },
                {
                    id: 4,
                    month: 202407,
                    businessUnit: 4,
                    noOfEmployees: 10,
                    numberSent: 20,
                    quantityNotSent: 30,
                    createdBy: "priyanka",
                    creationTime: '2024 -08 - 13 19:05',
                    status: 'inProgress'
                }
            ]
        }
        setPartenaData(response.data);
        const partenaData: PartenaData[] = response.data;
        setShowModal(!response?.modalStatus);
    }, [location]);


    const handleClick = (event: any, status: string = "") => {
        const popUpStatus = status === "add" ? true : status === "close" ? false : false;
        setShowModal(popUpStatus);
        status === "partenaCalculation" && navigate(`/partena/calculation/${event.id}`);
    }

    //Handle field changes
    const handleSearchChange = (e: any) => {
        const { name, value } = e.target;
        setSearch(prev => ({ ...prev, [name]: value }));
    }

    //Handle page Changes
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <>
            <div className="text-end">
                <Button
                    title={t("Add")}
                    handleClick={(e) => handleClick(e, "add")}
                    className={"btn form-button  me-5 rounded shadow-none px-5"}
                />
            </div>
            <div className="row my-3">
                <div className="col">
                    <SearchWithLabel
                        label={t("Search month")}
                        isMandatory={false}
                        showLabel={false}
                        name={"month"}
                        className="me-3"
                        type={"text"}
                        value={search?.month}
                        placeholder={t("Month")}
                        isDisabled={false}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Business unit")}
                        isMandatory={false}
                        showLabel={false}
                        name={"businessUnit"}
                        className="me-3"
                        type={"text"}
                        value={search?.businessUnit}
                        placeholder={t("Business unit")}
                        isDisabled={false}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Number of employees")}
                        isMandatory={false}
                        showLabel={false}
                        name={"numberOfEmployees"}
                        className="me-3"
                        type={"text"}
                        value={search?.numberOfEmployees}
                        placeholder={t("Number of employees")}
                        isDisabled={false}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Number sent")}
                        isMandatory={false}
                        showLabel={false}
                        name={"numberSent"}
                        className="me-3"
                        type={"text"}
                        value={search?.numberSent}
                        placeholder={t("Number sent")}
                        isDisabled={false}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Quantity not sent")}
                        isMandatory={false}
                        showLabel={false}
                        name={"quantityNotSent"}
                        className="me-3"
                        type={"text"}
                        value={search?.quantityNotSent}
                        placeholder={t("Quantity not sent")}
                        isDisabled={false}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Created by")}
                        isMandatory={false}
                        showLabel={false}
                        name={"createdBy"}
                        className="me-3"
                        type={"text"}
                        value={search?.createdBy}
                        placeholder={t("Created by")}
                        isDisabled={false}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <Button
                        title={t("Search")}
                        handleClick={(e) => handleClick(e)}
                        className={"btn form-button me-3  rounded shadow-none px-5"}
                    />
                </div>
            </div>

            <div className="mt-5 table table-responsive">
                <table className="table table-borderless">
                    <thead className="TableHeader">
                        <th>Month</th>
                        <th>BusinessUnit</th>
                        <th>Number of employees</th>
                        <th>Number sent</th>
                        <th>Quantity not sent</th>
                        <th>Created by</th>
                        <th>Time of creation</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                        {partenaData && partenaData.length > 0 ?
                            (partenaData.map((record: any) => (<tr className="cursor-pointer" onClick={() => handleClick(record, "partenaCalculation")}>
                                <td>{record.month}</td>
                                <td>{record.businessUnit}</td>
                                <td>{record.noOfEmployees}</td>
                                <td>{record.numberSent}</td>
                                <td>{record.quantityNotSent}</td>
                                <td>{record.createdBy}</td>
                                <td>{record.creationTime}</td>
                                <td>{record.status}</td>
                            </tr>
                            ))) : (<tr className="w-100 m-auto text-center bg-warning">
                                <td className="text-danger" colSpan={8}>
                                    No records found
                                </td>
                            </tr>)

                        }
                    </tbody>
                    <tr></tr>
                </table>
            </div>

            <div className="pagination justify-content-center align-items-center">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
            {showModal &&
                <>
                    <div className="position-fixed top-0 w-100 h-100 start-0 d-flex align-items-center justify-content-center add-partena-modal">
                        <div className="card w-50 ">
                            <div className="card-header text-center position-relative my-auto p-3">
                                <LabelField title={"Partena add new calculation"} className="mb-0" />
                                <button type="button" onClick={(e) => handleClick(e, "close")} className="btn-close me-0 position-absolute end-0 pe-4" aria-label="Close"></button>
                            </div>
                            <div className="card-body"><AddPartenaCalculation /></div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default ManagePartena;