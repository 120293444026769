
export const tableHeaders = [
    { name: 'Development string', alias: 'original_string', width: 40 },
    { name: 'Translation string', alias: 'translated_string', width: 40 },
];

export const MANAGE_TRANSLATION_CONSTANTS = {
    RESET: 'Reset',
    SUBMIT: 'Submit',
}

export const manageState = {
    filters: {
        language_id: JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}')?.user_language,
        site_key: 'backend',
        search: ""
    },
    popup: {
        editpopup: {
            string_id: null,
            showpopup: false,
            newstring: ""
        }
    },
    options: {} as any,
    data: [] as any,
    manage: true,
    initialCall: true,
    current_page: 1,
    limit: 10,
    status: null,
    totalPages: 0,
    offset: 0,
    totalCount: 0
};

export const filterValues = (options: any) => {
    let row = [
        {
            name: 'language_id',
            class: 'w-100',
            type: 'multi-select',
            placeholder: 'Select language',
            label: 'Language',
            value: [],
            options: options?.Language,
            emptyOptionNotRequired: true
        },
        {
            name: 'search',
            class: 'w-100',
            type: 'text',
            placeholder: 'Search development string',
            value: "",
            label: "Development string",
        },
    ]
    return row;
}
