import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageVacancies from "./ManageVacancies";
import { M_MASTER_DATA } from "constants/Constants";
import { VACANCIES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

// Define the prop types for the component
interface VacanciesProps {
  id?: string | number;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: Partial<VacanciesProps>;
}

// Functional component using TypeScript
const Vacancies: React.FC<VacanciesProps> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const itemsPerPage = 10;
  const [vacaciesData, setVacanciesData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState<any>({
    name: queryParams.get("name") ?? "",
  });
  const [functions, setFunctions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyProjects, setCompanyProjects] = useState([]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const postData: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
    };
    getVacanciesData(postData);
  }, [currentPage, submit]);

  const getVacanciesData = async (postData: DataProps) => {
    const response = await ApiCall.service(
      VACANCIES,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setVacanciesData(response.data);
      setTotalPages(response.totalPages);
      setFunctions(response.functions);
      setProjects(response.projects);
      setCompanies(response.companies);
      setCompanyProjects(response.companyProjects);
    } else {
      console.log("error");
    }
  };

  const handleSearch = (name: string, value: string | number) => {    
    if (name == "company") {
      let projectList = companyProjects.filter(
        (item: any) => item.company_id == value
      );
      let filteredProjects = projects.filter((project: any) =>
        projectList.some((company: any) => project.value == company.project_id)
      );
      let proj = filteredProjects?.some((item: any) => item?.value == search?.project) ? search?.project : "";
      setSearch((prevSearch: any) => ({ ...prevSearch, [name]: value, project: proj}));
    } else {
      setSearch((prevSearch: any) => ({ ...prevSearch, [name]: value }));
    }
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: {
    [key: string]: string | number | boolean;
  }) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]: [string, string | number | boolean]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSearchSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        function: "",
        company: "",
        project: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }

    setSubmit(!submit);
    setCurrentPage(1);
  };

  return (
    <>
      <Suspense
        fallback={
          <div className="text-center text-danger"></div>
        }
      >
        <ManageVacancies
          data={vacaciesData}
          handleData={setVacanciesData}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          searchValue={handleSearch}
          handleSubmitAction={handleSearchSubmit}
          searchData={search}
          functions={functions}
          projects={projects}
          companyProjects={companyProjects}
          companies={companies}
          handleGetVacany={getVacanciesData}
        />
      </Suspense>
    </>
  );
};

export default Vacancies;
