import React, { useEffect, useState } from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import General from "pages/microservices/project/components/General";
import ContactsPersons from "pages/microservices/project/components/ContactsPersons";
import Billing from "pages/microservices/project/components/Billing";
import ProjectParameters from "pages/microservices/project/components/ProjectParameters";
import ResponsiblePersons from "pages/microservices/project/components/ResponsiblePersons";
import Shifts from "pages/microservices/project/components/Shifts";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { PROJECTS_OPTIONS, PROJECT, PROJECT_CREATE } from "routes/ApiEndpoints";
import {
  SWITCH_TAB,
  SET_OPTIONS,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_TAB_DATA,
  PROJECT_OVERVIEW,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB,
  INITIAL_STATE,
  INITIAL_STATE_OBJECT,
  UPDATE_ERRORS_SEQUENCE_TAB,
  DISABLED_DAYS,
  SHIFT_UPDATE_FIELD,
  UPDATE_SELECTED_CONTACT_FIELD,
} from "pages/microservices/project/context/Constants";
import {
  UPDATE_ERRORS_BILLING_TAB,
  UPDATE_ERRORS_SHIFT_TAB,
  UPDATE_ERRORS_PARAMETER_TAB,
} from "pages/microservices/project/context/Constants";
import {
  BillingTabValidationRules,
  ContactsTabSubformValidationRules,
  ContactsTabValidationRules,
  GeneralTabSubformLocationRules,
  GeneralTabValidationRules,
  ProjectParametersTabValidationRules,
  ShiftsTabValidationRules,
  SequenceTabValidationRules,
} from "./validationRules/ProjectFormValidationRules";
import {
  validateForm,
  validateRequired,
  validateSequenceForm,
  validateTimeRange,
} from "services/validation/ValidationService";
import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import { FormMode } from "components/common/CommonEnums";
import { PATH_PROJECTS_MANAGE, PATH_PROJECT_PROPOSAL } from "constants/Paths";
import { useNavigate, useParams } from "react-router-dom";
import { isObjectEmpty } from "services/util/UtilService";
import Button from "components/atoms/Button";
import ProjectOverview from "./components/ProjectOverview";
import {
  ContactPersonTabErrors,
  ContactsPersons as IContactPersons,
  GeneralTabErrors,
  ContactPersonErrors,
  BillingTabErrors,
  ShiftsTabErrors,
  projectParametersTabErrors,
  SequenceErrors,
} from "./context/Interfaces";
import { Option } from "components/common/CommonInterfaces";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Sequence from "./components/Sequence";
import EncryptDecryptService from "services/EncryptDecryptService";
import CustomNotify from "components/atoms/CustomNotify";
import Popup from "components/molecules/Popup";
import { projectParametersValidations, validate } from "./Utils";


export const ProjectForm = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [users, setUsers] = useState([]);
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 60) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    dispatch(
      {
        type: INITIAL_STATE,
        payload: INITIAL_STATE_OBJECT
      }
    )

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const projectFormHasErrors = (): boolean => {
    let hasErrors = false;

    state.tabs.forEach((tab, index) => {
      let tabHasErrors = false;

      switch (tab.id) {
        case "general":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsGeneralTab(), {
            createLocationHasError: "",
            createLocation: "",
          });
          break;
        case "contactsPersons":
          const contactPersonsTabErrors =
            handleFieldValidationsContactPersonsTab();
          tabHasErrors =
            !isObjectEmpty(contactPersonsTabErrors, {
              contactsPersons: "",
              subformHasError: "",
            }) || contactPersonsTabErrors.subformHasError;
          break;
        // case "responsiblePerson":
        //   tabHasErrors = !isObjectEmpty(
        //     handleFieldValidationsResponsiblesTab()
        //   );
        //   break;
        case "projectParameters":
          // tabHasErrors = !isObjectEmpty(
          //   handleFieldValidationsProjectParametersTab()
          // );
          tabHasErrors = handleFieldValidationsProjectParametersTab();
          break;
        case "shifts":
          tabHasErrors = handleFieldValidationsShiftTab();
          break;
        case "sequence":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsSequenceTab());
          break;
        case "billing":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsBillingTab());
          break;
        default:
          break;
      }

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: index,
        error: tabHasErrors,
        draft: tab.draft,
      });

      if (tabHasErrors) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };

  // todo: move to validationService
  /**
   * @param formData
   * The formData in format {fieldKey: fieldValue}
   * fieldKey:   can be of any type
   * fieldValue: can be of any type
   *
   * @param ignoreKeys
   * Keys that must be ignored, when validating field
   * Like subforms {fieldKey1: "", fieldKey2: ""}
   *
   * @returns true
   * If at least 1 field is filled (has data present)
   *
   * @returns false
   * Otherwise
   */
  const formAnyFieldIsFilled = (formData: Object, ignoreKeys: Object = {}) => {
    for (const [key, value] of Object.entries(formData)) {
      let ignoreCurrentKey = ignoreKeys.hasOwnProperty(key as string);
      if (!ignoreCurrentKey && value) {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return false;
          }
          if (value.length > 0) {
            if (typeof value[0] === "object") {
              let selectOption = value[0] as Option;
              if (selectOption.value && selectOption.value !== "") {
                return true;
              }
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  };

  const handleFieldValidationsGeneralTab = (): GeneralTabErrors => {
    let newGeneralTabErrors = validateForm(
      state.general.data,
      GeneralTabValidationRules
    ) as Object;

    let newGeneralTabSubformErrors = {};
    if (state.general.data.createLocation) {
      let generalSubformIsFilled = formAnyFieldIsFilled(
        state.general.data.createLocation
      );
      if (generalSubformIsFilled) {
        newGeneralTabSubformErrors = validateForm(
          state.general.data.createLocation,
          GeneralTabSubformLocationRules
        );

        Object.assign(newGeneralTabErrors, {
          createLocationHasError: "subform has error",
        });
      }
    } else {
      newGeneralTabSubformErrors = {
        company: "",
        location: "",
        phone: "",
        street: "",
        number: "",
        box: "",
        zipcode: "",
        city: "",
        country: "",
        info: "",
      };

      Object.assign(newGeneralTabErrors, {
        createLocationHasError: "",
      });
    }
    // Merge subform errors with tab errors
    Object.assign(newGeneralTabErrors, {
      createLocation: newGeneralTabSubformErrors,
    });

    dispatch({
      type: SET_ERRORS_GENERAL_TAB,
      errors: newGeneralTabErrors as GeneralTabErrors,
    });

    return newGeneralTabErrors as GeneralTabErrors;
  };

  const handleFieldValidationsContactPersonsTab =
    (): ContactPersonTabErrors => {
      let contactsTabErrors = validateForm(
        state.contactsPersons.data,
        ContactsTabValidationRules
      ) as Object;

      let contactsPersonsSubformErrorIndexes = [];
      let contactPersonsInSubformErrors: ContactPersonErrors[] = [];
      const contactPersonsInSubform: IContactPersons[] =
        state.contactsPersons.data.contactsPersons;

      contactPersonsInSubform.forEach((subformRow: IContactPersons, i) => {
        let anyFieldFilled = formAnyFieldIsFilled(subformRow, { id: "" });

        if (anyFieldFilled) {
          let subformRowErrors = validateForm(
            subformRow,
            ContactsTabSubformValidationRules
          ) as Object;

          if (!isObjectEmpty(subformRowErrors)) {
            contactsPersonsSubformErrorIndexes.push(i);
          }

          contactPersonsInSubformErrors.push(
            subformRowErrors as ContactPersonErrors
          );
          // }
        } else {
          contactPersonsInSubformErrors.push({} as ContactPersonErrors);
        }
      });

      Object.assign(contactsTabErrors, {
        contactsPersons: contactPersonsInSubformErrors,
      });
      Object.assign(contactsTabErrors, {
        subformHasError: contactsPersonsSubformErrorIndexes.length > 0,
      });

      dispatch({
        type: UPDATE_ERRORS_CONTACTPERSONS_TAB,
        errors: contactsTabErrors as ContactPersonTabErrors,
      });

      return contactsTabErrors as ContactPersonTabErrors;
    };
  // const handleFieldValidationsResponsiblesTab = (): ResponsiblePersonErrors => {
  //   const responsiblePersonsTabErrors = validateForm(
  //     state.responsiblePerson.data,
  //     ResponsiblePersonsTabValidationRules
  //   ) as Object;

  //   dispatch({
  //     type: UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB,
  //     errors: responsiblePersonsTabErrors as ResponsiblePersonErrors,
  //   });

  //   return responsiblePersonsTabErrors as ResponsiblePersonErrors;
  // };

  const handleFieldValidationsShiftTab = (): boolean => {
    let errors: any = {
      name: "",
      shifts: {}
    };
    let hasError: any = state?.shifts?.data?.shifts?.length < 1;
    if (hasError) {
      errors = {
        ...errors, name: "The field is required"
      }
    } else {
      Object.keys(state?.shifts?.data?.shiftTable).map((shiftId: any) => {
        Object.keys(state?.shifts?.data?.shiftTable[shiftId]).map((day: string) => {
          if (!DISABLED_DAYS.includes(day)) {
            const { from, till, pause } = state?.shifts?.data?.shiftTable[shiftId][day];
            if (!errors[shiftId]) errors[shiftId] = {};
            if (!errors[shiftId][day]) errors[shiftId][day] = {};
            errors[shiftId][day]['from'] = validate(state, dispatch, day, { field: 'from', value: from }, shiftId);
            errors[shiftId][day]['till'] = validate(state, dispatch, day, { field: 'till', value: till }, shiftId);
            errors[shiftId][day]['pause'] = validate(state, dispatch, day, { field: 'pause', value: pause }, shiftId);
            if (!hasError && (errors[shiftId][day]['from'] || errors[shiftId][day]['till'] || errors[shiftId][day]['pause'])) {
              hasError = true;
            }
          }
        });
      });
    }
    return hasError;
  };

  /**
   * Handling the validations for sequence tab
   * @returns object
   */
  const handleFieldValidationsSequenceTab = (): SequenceErrors => {
    let SequenceTabErrors = validateSequenceForm(
      state.sequence.data,
    ) as Object;

    dispatch({
      type: UPDATE_ERRORS_SEQUENCE_TAB,
      errors: SequenceTabErrors as SequenceErrors,
    });

    return SequenceTabErrors as SequenceErrors;
  };

  const handleFieldValidationsBillingTab = (): BillingTabErrors => {
    const BillingTabErrors = validateForm(
      state.billing.data,
      BillingTabValidationRules
    ) as Object;

    dispatch({
      type: UPDATE_ERRORS_BILLING_TAB,
      errors: BillingTabErrors as BillingTabErrors,
    });

    return BillingTabErrors as BillingTabErrors;
  };

  const handleFieldValidationsProjectParametersTab =
    () => {
      const projectParametersTabErrors = validateForm(
        state.projectParameters.data,
        ProjectParametersTabValidationRules
      ) as Object;

      let validationData: any = {};
      let hasErrors = [];
      const sectionName: any = "Premium"
      let premiumValidation = state.projectParameters.data.sections;
      let premiumValue = premiumValidation?.[sectionName]?.['44']?.['field_value'];
      let premiumDependencies = [36, 37, 38, 39];

      for (let eachsection of Object.keys(state.projectParameters.data.sections)) {
        const sectionData = state.projectParameters.data.sections[eachsection as keyof typeof state.projectParameters.data.sections];
        let eachSectionData: any = {};
        for (let section of Object.keys(sectionData)) {
          const validate = (premiumValue == '0') ? (premiumDependencies.includes(parseInt(sectionData[section].field_id)) ? false : true) : true
          if ((sectionData[section].field_type !== 'checkbox' && sectionData[section].field_type !== 'radio') && validate) {
            const validationRules: any = projectParametersValidations(parseInt(section));
            const validationresult = validateForm({ [section]: sectionData[section].field_value }, validationRules);
            eachSectionData[section] = { ...sectionData[section], 'field_error': ((Object.keys(validationresult).length > 0) ? validationresult[section] : '') }
            hasErrors.push((Object.keys(validationresult).length > 0));
          }
          else {
            eachSectionData[section] = { ...sectionData[section], 'field_eroor': '' };
          }
        }
        validationData[eachsection] = eachSectionData;
      }

      dispatch({
        type: "UPDATE_PROJECT_PARAMETERS",
        payload: validationData,
      })

      dispatch({
        type: UPDATE_ERRORS_PARAMETER_TAB,
        errors: projectParametersTabErrors as projectParametersTabErrors,
      });

      //return projectParametersTabErrors as projectParametersTabErrors;
      return (hasErrors.includes(true) || Object.keys(projectParametersTabErrors).length > 0 );
    };

  // Save as Draft validation
  const draftValidation = () => {
    const tabFields = state.general;
    const fieldName = "projectName";
    const tabIndex = 0;
    const tab = "general";
    const fieldValue: any = tabFields.data[fieldName];
    const validationError = validateRequired(fieldValue);
    if (validationError) {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        tab: tab,
        fieldName: fieldName,
        error: validationError,
      });

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: tabIndex,
        error: true,
        draft: true,
      });
      return false;
    } else {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        tab: tab,
        fieldName: fieldName,
        error: "",
      });
      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: tabIndex,
        error: false,
        draft: true,
      });
    }
    return true;
  };

  const ConstructTabs = (tab: string | number) => {
    switch (tab) {
      case "general":
        return <General />;
      case "contactsPersons":
        return <ContactsPersons />;
      case "projectParameters":
        return <ProjectParameters />;
      case "shifts":
        return <Shifts />;
      case "sequence":
        return <Sequence />;
      case "billing":
        return <Billing />;
      default:
        break;
    }
  };

  useEffect(() => {
    getOptions();
  }, [state?.general?.data?.company, id]);

  async function getOptions() {
    if (state.general.data.company) {
      companyOptions();
    } else if (id) {
      projectDetails(id);
    } else {
      options();
    }
  }

  async function options() {
    let postData = {
      options: [
        "businessUnit",
        "projectTypes",
        "company",
        "gender",
        "language",
        "roles",
        "template",
        "shift",
        "country",
      ],
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const {
        businessUnit,
        company,
        gender,
        language,
        projectTypes,
        roles,
        template,
        shift,
        country
      } = response?.data;

      dispatch({
        type: SET_OPTIONS,
        field: "businessUnit",
        value: businessUnit,
      });
      dispatch({ type: SET_OPTIONS, field: "company", value: company });
      dispatch({ type: SET_OPTIONS, field: "countries", value: country });
      dispatch({ type: SET_OPTIONS, field: "gender", value: gender });
      dispatch({ type: SET_OPTIONS, field: "language", value: language });
      dispatch({
        type: SET_OPTIONS,
        field: "projectTypes",
        value: projectTypes,
      });
      dispatch({ type: SET_OPTIONS, field: "roles", value: roles });
      dispatch({ type: SET_OPTIONS, field: "templates", value: template });
      dispatch({ type: SET_OPTIONS, field: "shifts", value: shift });
    }
  }

  async function companyOptions() {
    const company = state.general.data.company;
    if (!company) {
      return;
    }
    const postData = {
      options: ["contactPersons", "companyLocations", "companyFunctions"],
      companyId: company.value,
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const { contactPersons, companyLocations, companyFunctions } =
        response?.data;
      dispatch({
        type: SET_OPTIONS,
        field: "companyUsers",
        value: contactPersons,
      });
      dispatch({
        type: UPDATE_SELECTED_CONTACT_FIELD,
        field: "selectedContactPersons",
        value: contactPersons,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "locations",
        value: companyLocations,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "functions",
        value: companyFunctions,
      });
    }
  }

  async function projectDetails(id: string) {
    if (!id) {
      return;
    }
    options();
    companyOptions();

    const response = await ApiCall.getService(
      `${PROJECT}/${id}`,
      "GET",
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      const {
        general,
        contactsPersons,
        responsiblePerson,
        projectParameters,
        shifts,
        sequence,
        billing,
      } = response?.data;

      let sequencedata = {
        sequenceName: '',
        weeklyShifts: {},
        sequence: sequence
      }


      dispatch({ type: UPDATE_TAB_DATA, field: "general", value: general });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "contactsPersons",
        value: contactsPersons,
      });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "responsiblePerson",
        value: responsiblePerson,
      });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "projectParameters",
        value: projectParameters,
      });
      let shiftdata = {
        data: {
          shiftTable: shifts?.shiftTable,
          shifts: shifts?.shifts
        },
        errors: {
          ...state?.shifts?.errors,
          shifts: shifts?.shiftErrors
        }
      }
      dispatch({ type: SHIFT_UPDATE_FIELD, field: "shifts", value: shiftdata });

      dispatch({ type: UPDATE_TAB_DATA, field: "sequence", value: sequencedata });

      dispatch({ type: UPDATE_TAB_DATA, field: "billing", value: billing });
    }
  }
  function handleTabSwitch(tab: string) {
    dispatch({
      type: SWITCH_TAB,
      field: "currentTab",
      value: tab,
    });
  }

  // Navigation tabs
  const handlePreviousStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex - 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleNextStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex + 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleSubmit = async () => {
    const formHasErrors = projectFormHasErrors();
    if (formHasErrors) {
      return;
    }
    const postData = {
      general: state.general.data,
      contactsPersons: state.contactsPersons.data,
      responsiblePerson: state.responsiblePerson.data,
      projectParameters: state.projectParameters.data,
      shifts: state.shifts.data,
      sequence: state.sequence.data.sequence,
      billing: state.billing.data,
      signatureImage: state.signatureImage || "",
      save: true,
    };
    const response = await ApiCall.service(
      PROJECT_CREATE,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const projectId = response?.result?.project_id;
      setProjectId(projectId);
      const users = response?.result?.users;
      setUsers(users);
      CustomNotify({ type: "success", message: `${response.message}` });
      setShowModel(true);
    } else if (response?.status === 500) {
      CustomNotify({ type: "error", message: `${response.message}` });
    }
  };
  const handleDraft = () => {
    draftValidation();
  };

  const presentOperation = id ? "update" : "create";
  const currentObj: any = {
    permission: "Project",
  };
  currentObj[presentOperation] = true;
  const userData = useSelector(selectAuth);
  const cancel = () => {
    setShowModel(false);
    navigate(PATH_PROJECTS_MANAGE);
  };
  const sendLetar = () => {
    navigate(PATH_PROJECTS_MANAGE);
  };
  const sendNow = () => {
    const data = {
      id: projectId,
      users: users,
    };

    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_PROJECT_PROPOSAL}/?data=${encodedData}`);
  };

  return (
    <AccessControl
      requiredPermissions={[currentObj]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="position-relative pt-5">
        <nav
          className={`company-step-tabs p-4 pb-2 ${isSticky
            ? "company-step-tabs-sticky position-sticky"
            : "company-step-tabs-absolute position-absolute w-100"
            }`}
        >
          <div className={`d-flex border-0 w-75 m-auto gap-3`}>
            {state.tabs.map((item: any) => (
              <div className={`flex-1 text-center`} key={item.id}>
                <div
                  className={`w-100 h-100 company-step-tabs-btn ${state.currentTab === item.id ? "active" : "inactive"
                    } ${item.error === true ? "border-danger text-danger" : ""}`}
                  onClick={() => handleTabSwitch(item.id)}
                >
                  {t(item.title)}
                </div>
              </div>
            ))}
          </div>
        </nav>
      </div>
      <div className="mt-2">{ConstructTabs(state.currentTab)}</div>
      <FormNavigationOrganism
        isFirstTab={state.currentTab === state.tabs[0].id}
        isSubmitTab={state.currentTab === state.tabs[state.tabs.length - 1].id}
        draftButtonIsVisible={state.mode === FormMode.CREATE}
        formExitBackTo={PATH_PROJECTS_MANAGE}
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        handleSubmit={handleSubmit}
        handleDraft={handleDraft}
      />
      {showModel && (
        <Popup
          body={t("Send project proposal")}
          nowSendText={t("Send now")}
          letarSendText={t("Send later")}
          cancel={cancel}
          sendLetar={sendLetar}
          sendNow={sendNow}
          footerclass="justify-content-between"
        />
      )}
    </AccessControl>
  );
};
export default ProjectForm;
