import React from "react";
import { useContext, useReducer, createContext } from "react";

import { initialWeekDayProps } from "./initialWeekAndDayProps";
import { WeekAndDayReducer } from "../../reducers/WeekAndDayReducer/WeekAndDayReducer";

const WeekAndDayContext = createContext(null);
const WeekAndDayDispatchContext = createContext(null);

export default function WeekAndDayProvider({ children }) {
  const [initialWeekAndDayPropsState, dispatchWeekAndDay] = useReducer(WeekAndDayReducer,initialWeekDayProps);
  return (
    <WeekAndDayContext.Provider value={{initialWeekAndDayPropsState}}>
      <WeekAndDayDispatchContext.Provider value={{dispatchWeekAndDay}}>
        {children}
      </WeekAndDayDispatchContext.Provider>
    </WeekAndDayContext.Provider>
  );
}

export const useWeekAndDay = () => {
  return useContext(WeekAndDayContext);
};

export const useWeekAndDayDispatch = () => {
  return useContext(WeekAndDayDispatchContext);
};
