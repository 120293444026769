import React from 'react'
import OverviewMolecule from '../molecules/OverviewMolecule';
import TableMolecule from '../molecules/TableMolecule';
import { useTable } from '../work-pay-bill-roll-redux/context/TableContext';

const WorkPayBillRollOrganism = () => {
    const tableState = useTable();   
    return (
        <>
            {tableState?.overview ?
                <>
                    <OverviewMolecule />
                </>
                :
                <>
                    <TableMolecule />
                </>
            }
        </>
    )
}

export default WorkPayBillRollOrganism