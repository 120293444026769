import React, { useState } from "react";
import {
  PATH_MASTER_DATA,
  PATH_PC_CREATE,
  PATH_PC_EDIT,
} from "constants/Paths";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import { t } from "../translation/Translation";
import { PC } from "./ManagePage";
import { PC_ARCHIVE, PC_GET } from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import Search from "components/atoms/Search";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { PCHeader } from "TableHeader";
import ModalPopup from "components/atoms/ModalPopup";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Pagination from "components/atoms/Pagination";
import { IconData } from "services/IconData";

interface ManagePCPageProps {
  pcObject: PC[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

const ManagePC: React.FC<ManagePCPageProps> = ({
  pcObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${PC_ARCHIVE}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleDelete = (PC: PC | any) => {
    setShowModal(true);
    setDeleteId(PC.id);
  };

  const handleEdit = (PC: PC | any) => {
    if (PC.id) {
      navigate(`${PATH_PC_EDIT}/${PC.id}`);
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Paritair commitee",
        read: true
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="row header-sticky position-sticky">
            <div className="col-md-12">
              <h1 className="py-4 page-title mb-0">{t("Manage paritair comitee")}</h1>
            </div>
          </div>
          <div className="row pb-4 search-bar">
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">
                  {t("Paritair comitee name")}
                </label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder={t("Paritair comitee name")}
                name="name"
                value={searchData.name}
              />
            </div>
            <div className="col text-end mt-auto">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[{
                  permission: "Paritair commitee",
                  create: true,
                }]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_PC_CREATE}
                  className="table-button"
                >
                  + {t("Create paritair commitee")}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive manage-companies">
              <DynamicTableStructure
                isAction
                headers={PCHeader}
                data={pcObject}
                handleEdit={handleEdit}
                handleArchive={handleDelete}
                permission={"Paritair commitee"}
              />
            </div>
            <div className="pagination justify-content-center align-items-center my-3">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 align-self-center my-4">
          <Link
            to={PATH_MASTER_DATA}
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};
export default ManagePC;
