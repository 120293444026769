import React from "react";

interface FormSubTitleProps {
  title?: string;
}

const FormSubTitle: React.FC<FormSubTitleProps> = ({ title }) => {
  return (
    <div className="col-md-12">
      <h5 className="py-4">{title}</h5>
    </div>
  );
};
export default FormSubTitle;
