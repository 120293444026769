import React from "react";
import { FormProvider } from "./Context";
import { CompanyForm } from "../create-company/formContent/CompanyForm";

export const MultiFormProvider = () => {
  return (
    <>
      <FormProvider>
          <CompanyForm/>
      </FormProvider>
    </>
  );
};