import React from "react";
import TimeField from "components/atoms/TimeField";

interface ShiftInputCellProps {
  value: string;
  type?: string;
  disabled?: boolean;
  onChange: any;
  error?: any;
}

const ShiftInputCell: React.FC<ShiftInputCellProps> = ({
  value,
  type,
  disabled = false,
  onChange,
  error
}) => {
  const inputStyle: React.CSSProperties = {
    width: "100%",
    padding: "8px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
  };

  const tdStyle: React.CSSProperties = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
  };

  const isTotal = (disabled || type === "total") ? true : false;

  return (
    <tr>
      <td style={tdStyle}>
        <TimeField
          name={type}
          type={"time"}
          customStyle={inputStyle}
          handleChange={onChange}
          isDisabled={isTotal}
          value={value}
          title={((error && error != "") ? error : "")}
          className={"form-control " + ((error && error != "") ? "border-danger" : "")}
        />
      </td>
    </tr>
  );
};

export default ShiftInputCell;
