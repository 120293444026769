import React from 'react';

interface RecruitmentCardProps {
  children: React.ReactNode;
  boxShadow?: string;
  className?: string | number;
  backgroundColor?: string;
}

const RecruitmentCard: React.FC<RecruitmentCardProps> = ({
  children,
  boxShadow,
  className,
  backgroundColor
}) => {
  const RecruitmentCardStyle: React.CSSProperties = {
    boxShadow,
    backgroundColor
  };

  return (
    <>
      <div style={RecruitmentCardStyle} className={`${className}`}>
        {children}
      </div>
    </>
  );
};

export default RecruitmentCard;