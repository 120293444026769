import { GET_MULTI_TAB_FORM } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { UPDATE_FIELD_DATA } from "utils/multiTabForm/context/Constants";

export const getForm = async (formName: string) => {
  try {
    const postData = {
      method: "POST",
      form: formName,
    };
    const response = await ApiCall.service(
      GET_MULTI_TAB_FORM,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const tempFormProvider = (data: any, tabs: any) => {
  var form: any = [];
  Object.keys(tabs).map((tab: any) => (form[tab] = data));
  return form;
};

export const handleProviderCheck = (fieldType: string) => {
  switch (fieldType) {
    case "mobile-number":
    case "multi-select":
      return true;
    default:
      return false;
  }
};
const getFormattedSSN = (value: any, state: any, dispatch: any) => {
  let dob = "";
  let formattedSSN = "";

  if (value !== " ") {
    let numericValue = value;
    if (value.length <= 15) {
      numericValue = value.replace(/[^0-9]/g, "");
    }
    if (numericValue.length <= 11) {
      for (let i = 0; i < numericValue.length; i++) {
        if (i === 2 || i === 4 || i === 9) {
          formattedSSN += ".";
        } else if (i === 6) {
          formattedSSN += "-";
        }
        formattedSSN += numericValue[i];
      }
      const birthdateString = formattedSSN
        .substring(0, 8)
        .replace(/\./g, ""); // Extract YYMMDD part and remove dots
      const year = birthdateString.substring(0, 2);
      let month = parseInt(birthdateString.substring(2, 4), 10);
      const day = parseInt(birthdateString.substring(4, 6));
      // Pad month and day with leading zeros if necessary
      const paddedMonth = month.toString().padStart(2, '0');
      const paddedDay = day.toString().padStart(2, '0');
      const century = parseInt(year) < 50 ? "20" : "19"; // Determine the century based on the year
      const fullYear = parseInt(century + year);
      if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
        dob = fullYear + "-" + paddedMonth + "-" + paddedDay;
      }
    }
    dispatch({
      type: UPDATE_FIELD_DATA,
      payload: {
        field: "dateOfBirth",
        value: dob,
        index: 0,
        valueField: "value",
        dynamic: false,
        mainIndex: 0,
        formIndex: 8,
      },
    });
  }
  return formattedSSN;
}
async function getBicForIBAN(iban: string, state?: any, dispatch?: any) {
    const url = `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const ibanData = await response.json();
      if (ibanData.valid) {

        dispatch({
          type: UPDATE_FIELD_DATA,
          payload: {
            field: "bicCode",
            value: ibanData.bankData.bic,
            index: 1,
            valueField: "value",
            dynamic: false,
            mainIndex: 1,
            formIndex: 12,
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
}
export const getValue = (fieldType: string, event: any, editor: any, dispatch?: any, state?: any) => {  
  if (event && event.target && event.target?.name === "ssnNumber") {
    const ssnNumber = event.target?.value;
    if (ssnNumber.length <= 15) {
      return getFormattedSSN(ssnNumber, state, dispatch);
    }else{
      return null;
    }
  } else if (event && event.target && event.target?.name === "bankAccountNumber") {
    const iban = event.target?.value;
    if (iban !== "" && iban.startsWith("BE") && iban.length == 16) {
      getBicForIBAN(event.target?.value, state, dispatch);
    } else {
      dispatch({
        type: UPDATE_FIELD_DATA,
        payload: {
          field: "bicCode",
          value: "",
          index: 1,
          valueField: "value",
          dynamic: false,
          mainIndex: 1,
          formIndex: 12,
        },
      });
      dispatch({
        type: UPDATE_FIELD_DATA,
        payload: {
          field: "bankAccountNumber",
          value: "There is no BIC for the provided IBAN",
          index: 1,
          valueField: "error",
          dynamic: false,
          mainIndex: 1,
          formIndex: 11,
        },
      });
    }
    return event.target?.value;
  } else {
    switch (fieldType) {
      case "mobile-number":
      case "multi-select":
        return event;
      case "checkbox":
        return event.target.checked;
      case "ck-text-area":
        const data = editor.getData();
        return data;
      case "location":
        return getLocationData(event);
      default:
        return event.target.value;
    }
  }
};

const getLocationData = (event: any) => {

  let location = { lat: event.detail.latLng.lat, lng: event.detail.latLng.lng }

  return location;
}

export const getDependentFields = () => { };

export const formateSubmitData = (
  state: any,
  type: string,
  id?: number | string
) => {
  let submitData: any = {};
  let formData = state.form;
  let tabs = state.tabs;
  tabs?.map((tab: any, key: number) => {
    let tabData = formData[key].map((field: any) => ({
      ...field,
      options: [],
    }));
    submitData[tab.id] = tabData;
  });
  if (id) {
    submitData["id"] = id;
  }
  if (type === "draft") {
    submitData["draft"] = true;
  }
  return submitData;
};

export const submitData = async (
  data: any,
  endpoint: string,
  microservices: string
) => {
  try {
    const postData = {
      method: "POST",
      data: { ...data },
    };
    const response = await ApiCall.service(
      endpoint,
      "POST",
      postData,
      true,
      microservices
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
