import React from 'react'

const FollowUpIcon: React.FC = () => {
    return (
       <span title='view followups'>
         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 22.51 22.002">
            <path id="Union_376" data-name="Union 376" d="M1.14,14.67A1,1,0,1,1,3.02,14,9,9,0,1,0,11.5,2,8.88,8.88,0,0,0,4.14,5.92L5.66,7.25A1,1,0,0,1,5,9H1A1,1,0,0,1,0,8V4.5a1,1,0,0,1,1.63-.75l1,.85A10.842,10.842,0,0,1,11.5,0,11,11,0,1,1,1.14,14.67Zm12.91.22-4-2A1,1,0,0,1,9.5,12V7a1,1,0,1,1,2,0V11.38l3.45,1.731A1,1,0,0,1,14.5,15h-.011A.926.926,0,0,1,14.05,14.89Z" fill='currentColor'/>
        </svg>
       </span>
    )
}

export default FollowUpIcon