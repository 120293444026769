import React, { useState, useEffect } from "react";
import CommonBox from "../atoms/commonBox";
import { useEmployeeDispatch } from "../../timesheets/timesheets-redux/context/EmployeeContext/EmployeeContext";
import {
  useProject,
  useProjectDispatch,
} from "../../timesheets/timesheets-redux/context/ProjectContext/ProjectContext";
import { handleProjects } from "../utils/utils";
import { useWeekAndDay } from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { useProjectParameterDispatchContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";
import { PROJECT_ACTIONS } from "../timesheets-redux/actions/ProjectActions";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";

const TimesheetsProjects = () => {
  const { projectState } = useProject();
  const { filterOptionsState } = useFilterOptions()
  const { projectDispatch } = useProjectDispatch();
  const { dispatchEmployee } = useEmployeeDispatch();
  const { initialWeekAndDayPropsState } = useWeekAndDay();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { projectParameterDispatch } = useProjectParameterDispatchContext();
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {    
    handleProjectClick?.(null, projectState?.project_id);
  }, [projectState?.refresh])

  const handleProjectClick = async (e, project_id) => {
    setSelectedProjectId(project_id);    
    projectDispatch({
      type: PROJECT_ACTIONS.SET_PROJECT,
      payload: {
        project_id: project_id,
      },
    });
    // Set the selected project ID
    let planningPayload = {
      company_id: [],
      project_id: [project_id],
      businessUnits: [],
      employeeTypes: [],
      strict: true,
    };
    try {
      await handleProjects({
        e, project_id, planningPayload, dispatchEmployee, initialWeekAndDayPropsState,
        projectParameterDispatch, timesheetDispatch, initialWeekAndDayPropsState, filterOptionsState
      })
    } catch (error) {
      console.error("Failed to fetch employees:", error);
    }
  };

  const tableBodyStyle = {
    // maxHeight: "900px",
    // overflowY: "scroll",
    // display: "block",
  };

  return (
    <table className="w-100 border-end h-100 overflow-auto">
      <thead className="position-sticky top-0 ">
        <tr className="border-0">
          <th className="py-3 bg-lightskyblue">Projects</th>
        </tr>
      </thead>
      <tbody style={tableBodyStyle}>
        {projectState?.projects?.map((project, index) => (                   
          <tr key={project?.value} className="border-0 d-block cursor-pointer">
            <td style={{ height: "70px" }}
                className="d-block"
            >
              <CommonBox
                data={project?.label ?? ""}
                bg_color={
                  (selectedProjectId == project?.value || (!selectedProjectId && index === 0)) ? "#00a5ce" : "#e8f8fb"
                }
                color={"black"}
                handleBoxClick={(e) => handleProjectClick(e, project?.value)}
                zbox_id={project?.value}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TimesheetsProjects;
