import React, { createContext, useContext, useReducer, ReactNode } from "react";
import initialState from "./State";

import { MarginReducer } from "./MarginReducer";

const MarginContext = createContext<any>({
});

interface FormProviderProps {
    children: ReactNode;
}

export const useFormContext = () => {
    const context = useContext(MarginContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const MarginProvider: React.FC<FormProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(MarginReducer, initialState);

    return (
        <MarginContext.Provider value={{ state, dispatch }}>
            {children}
        </MarginContext.Provider>
    );
};
