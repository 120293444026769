import React, { useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { Link, useNavigate } from "react-router-dom";
import { PATH_EDIT_APPLICATION_CONFIG, PATH_MASTER_DATA } from "constants/Paths";
import { ApiCall } from "services/ApiServices";
import { GET_ENTITY } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { ApplicationConfiguration } from "TableHeader";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const AppConfiguration: React.FC = () => {

    const [entityData, setEntityData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    // To get list of entity types 

    const getData = async () => {
        try {
            const response = await ApiCall.getService(
                GET_ENTITY,
                "GET",
                M_MASTER_DATA,
                false
            );
            if (response.length > 0) {
                setEntityData(response);
            }
        }
        catch (error) {
            console.error("There was an error fetching the data", error);
        }
    }

    const handleEdit = (entities: any) => {
        if (entities.id) {
            navigate(`${PATH_EDIT_APPLICATION_CONFIG}/${entities.entity_name.toLowerCase().replace(' ', '')}/${entities.id}`);
        }
    };

    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[{
                permission: "Application configuration",
                read: true
            }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <div className="col-md-12 header-sticky position-sticky">
                    <h1 className="py-4 page-title mb-0">{t("Manage application configuration")}</h1>
                </div>
                <div className="p-5 border rounded-4 table-custom">
                    <DynamicTableStructure
                        isAction
                        headers={ApplicationConfiguration}
                        data={entityData}
                        values={entityData}
                        handleEdit={handleEdit}
                        permission={"Application configuration"}
                    // handleArchive={deleteLocation}
                    // searchStatus={searchData}
                    />
                </div>

                <div className="row my-4">
                    <div className="col-md-6 align-self-center">
                        <Link
                            to={`${PATH_MASTER_DATA}`}
                            className="text-uppercase back-btn text-decoration-underline"
                        >
                            {t("Back")}
                        </Link>
                    </div>
                </div>
            </>
        </AccessControl>
    )
}

export default AppConfiguration;