// ContactForm.tsx
import React from "react";
import PhoneInput from "react-phone-number-input";
import Button from "components/atoms/Button";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import LabelField from "components/atoms/LabelField";
import CloseFile from "static/images/CloseFile";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  ContactPersonErrors,
  ContactsPersons,
} from "pages/microservices/project/context/Interfaces";
import LabelWithMobileFieldMolecule from "components/molecules/LabelWithMobileFieldMolecule";

// interface Contact {
//   fName: string;
//   lName: string;
//   email: string;
//   company: string;
//   phNumber: string;
//   mobNumber: string;
//   gender: string;
//   language: string;
//   linkedIn: string;
//   roles: string[];
//   functionTitle: string;
//   location: string[];
//   info: string;
// }

interface ContactFormProps {
  index: number;
  contacts: ContactsPersons;
  contactErrors: ContactPersonErrors[];
  onRemoveContact: (index: number) => void;
  onFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  onPhoneNumberChange: (fieldName: string, value: any, index: number) => void;
  onSelectChange: (e: any, field: string, index: number) => void;
  onSaveContact?: (contact: any, index: number) => void;
  companyOptions?: any;
  genderOptions?: any;
  languageOptions?: any;
  rolesOptions?: any;
  functionsOptions?: any;
  locationsOptions?: any;
  fromType?: string;
  mode?: string;
}

const ContactForm: React.FC<ContactFormProps> = ({
  index,
  contacts,
  contactErrors,
  onRemoveContact,
  onFieldChange,
  onPhoneNumberChange,
  onSelectChange,
  onSaveContact,
  companyOptions,
  genderOptions,
  languageOptions,
  rolesOptions,
  // functionsOptions,
  locationsOptions,
  fromType,
  mode,
}) => {
  const removeStatus = fromType === "project" ? index >= 0 : index > 0;
  return (
    <form>
      <div className="create-form-height-company">
        <div className="form-border p-5 ">
          <div
            className="field-set border px-3 pt-4 pb-2 mb-4 rounded-0"
            key={index}
          >
            {removeStatus && (
              <div className="row">
                <div className="col-md-12">
                  <div className="d-block d-md-block d-lg-none">
                    <Button
                      title="Remove"
                      handleClick={() => onRemoveContact(index)}
                      className="link-button float-end text-uppercase rounded-0 shadow-none my-2"
                    />
                  </div>
                  <div className="d-none d-md-none d-lg-block">
                    <span
                      title="Delete"
                      onClick={() => onRemoveContact(index)}
                      className="table-action-icons cursor-pointer float-end text-uppercase rounded-0 shadow-none"
                    >
                      <CloseFile />
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="fName"
                  handleChange={(event) => onFieldChange(event, index)}
                  value={contacts.fName ?? ""}
                  id={`fname-${0}`}
                  label={t("First name")}
                  placeholder={t("First name")}
                  error={contactErrors[index]?.fName}
                  className="form-control shadow-none rounded-0"
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="lName"
                  handleChange={(event) => onFieldChange(event, index)}
                  value={contacts.lName ?? ""}
                  id={`lname-${0}`}
                  label={t("Last name")}
                  placeholder={t("Last name")}
                  // error={state.contactsPersons.errors[index]?.lName}
                  error={contactErrors[index]?.lName}
                  className="form-control shadow-none rounded-0"
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="email"
                  handleChange={(event) => onFieldChange(event, index)}
                  value={contacts.email ?? ""}
                  id={`email-${0}`}
                  label={t("Email")}
                  placeholder="Email"
                  type="email"
                  // error={state.contactsPersons.errors[index]?.email}
                  error={contactErrors[index]?.email}
                  className="form-control shadow-none rounded-0"
                />
              </div>
              {mode !== "tempAgency" && (
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title={t("Company")}
                    name="company"
                    isMandatory={true}
                    search={true}
                    options={companyOptions}
                    placeHolder={t("Select")}
                    // value={""}
                    value={contacts.company}
                    onChange={(e) => onSelectChange(e, "company", index)}
                    isMulti={false}
                    className="select-field"
                    // error={state.contactsPersons.errors[index]?.company}
                    error={contactErrors[index]?.company}
                  />
                </div>
              )}

              <div className="col-sm-12 col-md-6">
                <LabelWithMobileFieldMolecule
                  label={t("Mobile number")}
                  name={"mobNumber"}
                  isMandatory={true}
                  value={contacts.mobNumber ?? ""}
                  error={contactErrors[index]?.mobNumber}
                  handleChange={(value) =>
                    onPhoneNumberChange("mobNumber", value, index)
                  }
                  isInternational={true}
                ></LabelWithMobileFieldMolecule>

                {/* <LabelField
                  title={t("Mobile number")}
                  isMandatory={true}
                  key="PhoneInput"
                />
                <div className="form-control rounded-0 shadow-none">
                  <PhoneInput
                    defaultCountry="BE"
                    international
                    placeholder={t("Enter mobile number")}
                    onChange={(mobNumber) =>
                      onPhoneNumberChange(mobNumber, index)
                    }
                    value={contacts.mobNumber ?? ""}
                  />
                </div>
                <div className="height-20 mb-2 text-danger">
                  {contactErrors[index]?.phNumber && (
                    <span className="text-danger">
                      {contactErrors[index]?.phNumber}
                    </span>
                  )}
                </div> */}
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={false}
                  name="phNumber"
                  handleChange={(event) => onFieldChange(event, index)}
                  value={contacts.phNumber ?? ""}
                  label={t("Phone number")}
                  placeholder="Phone number"
                  type="text"
                  className="form-control shadow-none rounded-0"
                  // error={contactErrors[index]?.phNumber}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Gender")}
                  name="gender"
                  isMandatory={true}
                  search={true}
                  options={genderOptions}
                  placeHolder={t("Select")}
                  value={contacts.gender}
                  onChange={(e) => onSelectChange(e, "gender", index)}
                  isMulti={false}
                  className="select-field"
                  // error={state.contactsPersons.errors[index]?.gender}
                  error={contactErrors[index]?.gender}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Language")}
                  name="language"
                  isMandatory={false}
                  search={true}
                  options={languageOptions}
                  placeHolder={t("Select")}
                  value={contacts.language}
                  onChange={(e) => onSelectChange(e, "language", index)}
                  isMulti={false}
                  className="select-field"
                  // error={state.contactsPersons.errors[index]?.language}
                  error={contactErrors[index]?.language}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={false}
                  name="linkedIn"
                  handleChange={(event) => onFieldChange(event, index)}
                  value={contacts.linkedIn ?? ""}
                  id="linkedIn"
                  label={t("LinkedIn profile URL")}
                  type="text"
                  // error={state.contactsPersons.errors[index]?.linkedIn}
                  error={contactErrors[index]?.linkedIn}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Roles")}
                  name="role"
                  isMandatory={false}
                  search={true}
                  options={rolesOptions}
                  placeHolder={t("Select")}
                  value={contacts.roles}
                  onChange={(e) => onSelectChange(e, "roles", index)}
                  isMulti={true}
                  className="select-field"
                  // error={state.contactsPersons.errors[index]?.roles?.toString()}
                  error={contactErrors[index]?.roles?.toString()}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={false}
                  name="functionTitle"
                  handleChange={(e) => onFieldChange(e, index)}
                  value={contacts.functionTitle ?? " "}
                  label={t("Function")}
                  placeholder={t("function")}
                  className="form-control shadow-none rounded-0"
                  // error={errors[i]?.function}
                  //  isDisabled={isEdit}
                />
              </div>
              <div className="col-sm-12 col-md-6 mb-3">
                <SelectWithSearch
                  title={t("Location")}
                  name="location"
                  isMandatory={false}
                  search={true}
                  options={locationsOptions}
                  placeHolder={t("Select")}
                  value={contacts.locations}
                  onChange={(e) => onSelectChange(e, "locations", index)}
                  isMulti={true}
                  className="select-field"
                  // error={state.contactsPersons.errors[
                  error={contactErrors[index]?.locations?.toString()}
                />
              </div>
              <div className="col-lg-12">
                <LabelWithTextAreaField
                  name="info"
                  handleChange={(event) => onFieldChange(event, index)}
                  value={contacts.info ?? ""}
                  label={t("Info")}
                />
              </div>
            </div>
            {onSaveContact && (
              <div className="col-12 text-center">
                <Button
                  title={t("Save")}
                  handleClick={() => onSaveContact?.(contacts, index)} // Optional chaining
                  className="btn btn-primary shadow-none rounded-0"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
