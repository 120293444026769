import {
  EMPLOYEES_CREATE,
  EMPLOYEES_EDIT,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_EMPLOYEE_MANAGE } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import {
  addressFieldFunction,
  addressFunction,
  documentsFunction,
  experienceFunction,
} from "./CustomFunctions";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export type CustomFnType = (payload: any) => any;

export const CreateEmployees = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = (payload: any) => {
    switch (payload.type) {
      case "generalAddress":
        return addressFunction(payload);
      case "addressField":
        return addressFieldFunction(payload);
      case "workExperience":
        return experienceFunction(payload, [
          "experience",
          "jobTitle",
          "jobDomain",
          "functions",
          "employer",
          "info",
          "from",
          "to",
          "reasonForLeaving"
        ]);
      case "workReference":
        return experienceFunction(payload, [
          "company",
          "externalCompany",
          "firstName",
          "lastName",
          "email",
          "phoneNumber",
          "referenceInfo",
        ]);
      case "documents":
        return documentsFunction(payload, ["workPermit", "from", "to"]);

      default:
        return "Called with wrong key";
    }
  };
  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Employees",
  }
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        permissionObject
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <FormProviderWrapper
        backToManage={PATH_EMPLOYEE_MANAGE}
        formName={"Employees"}
        dataSubmissionEndPoint={EMPLOYEES_CREATE}
        microservice={M_MASTER_DATA}
        dataFetchEndPoint={EMPLOYEES_EDIT}
        entryId={routeId}
        customFn={customActions}
      />
    </AccessControl>
  );
};
