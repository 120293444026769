import TitleFieldMolecule from "components/molecules/TitleField"
import { LabelWithInputField } from "components/molecules/LabelWithInputField"
import SelectWithSearch from "components/atoms/SelectWithSearch"
import { useState, ChangeEvent, useEffect } from "react"
import { validateForm, validateMultiSelectField, validateRequired, validateSelectField } from "services/validation/ValidationService"
import Button from "components/atoms/Button"
import Calender from "components/molecules/Calender"
import TableActions from "components/organism/Actions/TableAction"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"

import { ApiCall } from "services/ApiServices"
import { Link, useNavigate, useParams } from "react-router-dom"
import { EDIT_HOLIDAY_CODES, GET_HOLIDAY_CODES_DATA, GET_OPTIONS_FOR_HOLIDAY_CODES, SAVE_HOLIDAY_CODES } from "routes/ApiEndpoints"
import { M_MASTER_DATA } from "constants/Constants"
import CustomNotify from "components/atoms/CustomNotify"
import { MANAGE_HOLIDAY_CODES } from "constants/Paths"
import { t } from "../../translation/Translation"
import { LabelWithInputFieldAndUnit } from "../../templates/atoms/LabelWithInputFieldAndUnit"

interface HolidayData {
    holiday_name: string,
    holiday_code: string,
    holiday_from: string | Date,
    holiday_to: string | Date,
    holiday_type: any,
    contract_type: any,
    employee_category: any,
    holiday_count: string,
    count_type: any,
}

const CreateHolidayCodes = () => {

    useEffect(() => {
        fetchOptions();
    }, []);

    const fetchOptions = async () => {
        try {
            const response = await ApiCall.getService(
                GET_OPTIONS_FOR_HOLIDAY_CODES,
                'GET',
                M_MASTER_DATA,
                false,
            )
            if (response?.status === 200) {
                setHolidayCodeOptions((prevState) => ({
                    ...prevState,
                    holiday_types: response.data.holidayType,
                    contract_type: response.data.contractTypes,
                    employee_category: response.data.employeeCategory
                }));
                fetchData();
            }
            else {
                CustomNotify({ type: "warning", message: response.message });
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const [holidayData, setHolidayData] = useState<HolidayData>({
        holiday_name: "",
        holiday_code: "",
        holiday_from: "",
        holiday_to: "",
        holiday_type: "",
        contract_type: "",
        employee_category: "",
        holiday_count: "",
        count_type: "",
    });
    const [error, setErrorData] = useState({
        holiday_name: "",
        holiday_code: "",
        holiday_from: "",
        holiday_to: "",
        holiday_type: "",
        contract_type: "",
        employee_category: "",
        holiday_count: "",
        count_type: "",
    });

    const countType = [
        { 'value': 1, 'name': "Hour's", 'label': "Hour's" },
        { 'value': 2, 'name': "Day's", 'label': "Day's" },
    ];

    const [holidayDetails, setHolidayDetails] = useState<Array<object>>([]);
    const [editIndex, setEditIndex] = useState(null)
    const { id: id } = useParams();
    const navigate = useNavigate();
    const [holidayCodeOptions, setHolidayCodeOptions] = useState({
        holiday_types: [],
        contract_type: [],
        employee_category: [],
    })

    useEffect(() => {
        fetchOptions();
    }, []);

    const fetchData = async () => {
        if (id) {
            try {
                const response = await ApiCall.service(
                    GET_HOLIDAY_CODES_DATA,
                    "POST",
                    { id: id },
                    false,
                    M_MASTER_DATA
                );
                if (response.status == 200) {
                    setHolidayData((prevState) => ({ ...prevState, ...response.data }));
                }

            } catch (error) {
                console.error('Error', error);
            }
        }
    }

    const handleInputChange = (event: any, category: null | string = null) => {
        const name = (category === null) ? event.target.name : category;
        const value = (event.target === undefined) ? event : event.target.value;
        setHolidayData((prevState) => ({ ...prevState, [name]: value }));
        validateHolidayData(name, value, true);
    }

    const handleSelectChange = (event: any, category: string) => {
        setHolidayData((prevState) => {
            return { ...prevState, [category]: [...event] }
        });
        validateHolidayData(category, event, true);
    }

    const addAnotherData = () => {
        if (validateHolidayData()) {
            setHolidayDetails((prevState: any) => ([...prevState, holidayData]));
            setHolidayData((prevData) => ({
                ...prevData, holiday_name: '',
                holiday_code: "",
                holiday_from: "",
                holiday_to: "",
                holiday_type: "",
                contract_type: "",
                employee_category: "",
                holiday_count: "",
                count_type: "",
            }));
            setErrorData({
                holiday_name: "",
                holiday_code: "",
                holiday_from: "",
                holiday_to: "",
                holiday_type: "",
                contract_type: "",
                employee_category: "",
                holiday_count: "",
                count_type: "",
            });
        }
    }

    const handleOnSubmit = async () => {
        if (validateHolidayData()) {
            let finalHolidayCodes = null;
            if (holidayDetails.length === 0) {
                finalHolidayCodes = [holidayData];
            }
            else {
                finalHolidayCodes = [...holidayDetails, holidayData];
            }
            try {
                const response = await ApiCall.service(
                    SAVE_HOLIDAY_CODES,
                    "POST",
                    { data: id ? { ...finalHolidayCodes, id } : finalHolidayCodes },
                    false,
                    M_MASTER_DATA,
                )
                if (response?.status === 200) {
                    CustomNotify({ type: "success", message: response?.message });
                    navigate(`${MANAGE_HOLIDAY_CODES}`)
                }
                else {
                    CustomNotify({ type: "error", message: response?.message });
                }
            }
            catch (error) {
                console.error('Error', error);
            }
        }
    }

    const handleDateChange = (event: any, categoryName: string) => {
        if (event) {
            const date = `${event.getFullYear()}-${(event.getMonth() + 1) < 10 ? ('0' + (event.getMonth() + 1)) : (event.getMonth() + 1)}-${event.getDate() < 10 ? ('0' + event.getDate()) : (event.getDate())}`;
            setHolidayData((prevState) => ({ ...prevState, [categoryName]: date }));
            validateHolidayData(categoryName, date, true);
        }
    }

    const validateHolidayData = (name: any = null, value: any = null, isSingleFieldValidation = false) => {
        const validationRule: any = {
            holiday_name: [validateRequired],
            holiday_code: [validateRequired],
            holiday_from: [validateRequired],
            holiday_to: [validateRequired],
            holiday_type: [validateSelectField],
            contract_type: [validateMultiSelectField],
            employee_category: [validateSelectField],
            holiday_count: [validateRequired],
            count_type: [validateSelectField]
        }
        const validationErrors: any = validateForm({ ...holidayData, [name]: value }, isSingleFieldValidation ? validationRule[name] : validationRule);
        const endDateValidation = new Date(name === 'holiday_from' ? value : holidayData.holiday_from) <= new Date(name === 'holiday_to' ? value : holidayData.holiday_to);
        if ((!endDateValidation)) {
            validationErrors['holiday_to'] = t('End date must be greater than or equal to start date');
        }

        if (isSingleFieldValidation) {
            setErrorData((prevErrors: any) => {
                if (validationErrors.hasOwnProperty(name)) {
                    return { ...prevErrors, [name]: validationErrors[name] }
                }
                const { [name]: nameValue, ...remains } = prevErrors;
                return { ...remains }
            })
        }
        else {
            setErrorData(validationErrors);
            if (Object.keys(validationErrors).length > 0) {
                return false
            }
            else {
                return true;
            }
        }
    }

    const handleEdit = (entryId: any) => {
        setHolidayData((prevState) => ({ ...prevState, ...holidayDetails[entryId] }));
        setEditIndex(entryId);
        setErrorData({
            holiday_name: "",
            holiday_code: "",
            holiday_from: "",
            holiday_to: "",
            holiday_type: "",
            contract_type: "",
            employee_category: "",
            holiday_count: "",
            count_type: "",
        });
    }

    const handleSaveParticularData = () => {
        setHolidayDetails((prevstate: any) => {
            const updatedData = prevstate.map((eachHolidayData: any, index: number) => {
                if (index === editIndex) {
                    return { ...eachHolidayData, ...holidayData };
                }
                return eachHolidayData;
            })
            return updatedData;
        });
        setHolidayData((prevData) => ({ ...prevData, holiday_name: '', holiday_code: "", holiday_from: "", holiday_to: "", holiday_type: "", contract_type: "", employee_category: "", holiday_count: "", }))
        setEditIndex(null);

    }

    const handleArchive = (deleteId: number) => {
        setHolidayDetails((prevState) => {
            const filteredData = prevState.filter((eachHolidayData, index) => (index !== deleteId));
            return filteredData;
        })
    }

    return (
        <>
            <TitleFieldMolecule
                title={id ? (t("Edit holiday codes")) : (t("Create holiday codes"))}
            />
            <div className="form-height">
                <div className="form-border p-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-6">
                                <LabelWithInputField
                                    type={"text"}
                                    placeholder={"Enter holiday name"}
                                    label={t("Name")}
                                    isMandatory={true}
                                    name="holiday_name"
                                    id="holiday_name"
                                    handleChange={handleInputChange}
                                    value={holidayData.holiday_name}
                                    error={error.holiday_name}
                                />
                            </div>
                            <div className="col-6">
                                <LabelWithInputField
                                    type={"text"}
                                    placeholder={"Enter holiday code"}
                                    label={t("Codes")}
                                    isMandatory={true}
                                    name="holiday_code"
                                    id="holiday_code"
                                    handleChange={handleInputChange}
                                    value={holidayData.holiday_code}
                                    error={error.holiday_code}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 position-relative">
                                <Calender
                                    onChange={(date) => handleDateChange(date, 'holiday_from')}
                                    label={t("From")}
                                    isMandatory={true}
                                    name="holiday_from"
                                    error={error.holiday_from}
                                    selectedDate={holidayData.holiday_from}
                                    minDate={new Date()}
                                    isDisabled={false} />
                                    
                            </div>
                            <div className="col-sm-12 col-md-6 position-relative">
                                <Calender
                                    onChange={(date) => handleDateChange(date, 'holiday_to')}
                                    label={t("To")}
                                    isMandatory={true}
                                    name="holiday_to"
                                    error={error.holiday_to}
                                    selectedDate={holidayData.holiday_to}
                                    minDate={new Date()}
                                    isDisabled={false} />
                            </div>
                            <div className="col-6">
                                <SelectWithSearch
                                    title={t("Holiday type")}
                                    placeHolder={t("Select holiday type")}
                                    search={true}
                                    options={holidayCodeOptions.holiday_types}
                                    name="holiday_type"
                                    isMandatory={true}
                                    id="holiday_type"
                                    onChange={(e) => { handleInputChange(e, 'holiday_type') }}
                                    value={holidayData.holiday_type}
                                    error={error.holiday_type}
                                />
                            </div>
                            <div className="col-6">
                                <SelectWithSearch
                                    title={t("Contract type")}
                                    placeHolder={t("Select contract type")}
                                    search={true}
                                    isMulti={true}
                                    options={holidayCodeOptions.contract_type}
                                    name="contract_type"
                                    id="contract_type"
                                    isMandatory={true}
                                    onChange={(e) => { handleSelectChange(e, 'contract_type') }}
                                    value={holidayData.contract_type}
                                    error={error.contract_type}
                                />
                            </div>
                            <div className="col-6">
                                <SelectWithSearch
                                    title={t("Employee category")}
                                    placeHolder={t("Select employee category")}
                                    search={true}
                                    options={holidayCodeOptions.employee_category}
                                    name="employee_category"
                                    id="employee_category"
                                    className="w-100"
                                    isMulti={true}
                                    isMandatory={true}
                                    onChange={(e) => { handleInputChange(e, 'employee_category') }}
                                    value={holidayData.employee_category}
                                    error={error.employee_category}
                                />
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <LabelWithInputField
                                            label={t("Count")}
                                            type="number"
                                            name="holiday_count"
                                            id="holiday_count"
                                            placeholder={"Enter count"}
                                            min={0}
                                            handleChange={handleInputChange}
                                            isMandatory={true}
                                            value={holidayData.holiday_count}
                                            error={error.holiday_count}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectWithSearch
                                            title={t("Count type")}
                                            placeHolder={t("Select count type")}
                                            search={true}
                                            options={countType}
                                            name="count_type"
                                            id="count_type"
                                            className="w-100"
                                            isMandatory={true}
                                            onChange={(e) => { handleInputChange(e, 'count_type') }}
                                            value={holidayData.count_type}
                                            error={error.count_type}
                                        />
                                    </div>
                                </div>
                            </div>
                            {id === undefined && (
                                <div className="col-md-12 text-lg-end text">
                                    <Button
                                        title={editIndex !== null ? t("Save") : t("+ Add another")}
                                        type={"button"}
                                        handleClick={editIndex !== null ? handleSaveParticularData : addAnotherData}
                                        className={"btn form-button  text-uppercase rounded-0 shadow-none"}
                                    />
                                </div>
                            )}
                        </div>
                    </form>

                    {(holidayDetails.length > 0 && id === undefined) &&
                        <table className="table table-hover">
                            <thead className="TableHeader">
                                <tr>
                                    <th>{t("Contract type")}</th>
                                    <th>{t("Holiday type")}</th>
                                    <th>{t("Employee category")}</th>
                                    <th>{t("Counts")}</th>
                                    <th>{t("Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {holidayDetails.map((eachHolidayData: any, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            {eachHolidayData.contract_type.map((eachContractType: any) => (eachContractType.label)).join(',')}
                                        </td>
                                        <td className="align-middle">
                                            {eachHolidayData.holiday_type.label}
                                        </td>
                                        <td className="align-middle">
                                            {eachHolidayData.employee_category.map((eachCategory: any) => (eachCategory.label)).join(',')}
                                        </td>
                                        <td className="align-middle">
                                            {`${eachHolidayData.holiday_count} ${eachHolidayData?.count_type?.label}`}
                                        </td>
                                        <td>

                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                onClick={() => handleEdit(index)}
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#808080",
                                                    marginRight: 10,
                                                }}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={t("Edit")}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                onClick={() => handleArchive(index)}
                                                style={{ cursor: "pointer", color: "#808080" }}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={t("Delete")}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-between my-3 align-items-center">
                <div className="">
                    <Link
                        to={MANAGE_HOLIDAY_CODES}
                        className="text-uppercase back-btn text-decoration-underline"
                    >
                        {t("Back")}
                    </Link>
                </div>

                <div className="">
                    <Button
                        title={id ? t("Save") : t("Submit")}
                        type={"submit"}
                        handleClick={handleOnSubmit}
                        className={"btn form-button  text-uppercase rounded-0 shadow-none"}
                    />
                </div>
            </div>
        </>
    )
}
export default CreateHolidayCodes
