import React, {useContext, createContext, useReducer} from 'react'
import { filtersReducer } from '../../reducers/filtersReducer/FiltersReducer';
import { initialFilterProps } from './intialFiltersProps';

const FilterOptionsContext = createContext(null);
const FilterOptionsDispatchContext = createContext(null);


const intialFilterOptions = initialFilterProps;

export function FilterOptionsProvider({children}){
    const [filterOptionsState, dispatchFilterOptions] = useReducer(filtersReducer, intialFilterOptions);

    return (
        <FilterOptionsContext.Provider value={{filterOptionsState}}>
            <FilterOptionsDispatchContext.Provider value={{dispatchFilterOptions}}>
            {children}
            </FilterOptionsDispatchContext.Provider>
        </FilterOptionsContext.Provider>
    )
}


export function useFilterOptions(){
    return useContext(FilterOptionsContext);
}

export function useFilterOptionsDispatch(){
    return useContext(FilterOptionsDispatchContext);
}
