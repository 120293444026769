import Button from "components/atoms/Button";
import { Navigation } from "../formNavigation/Navigation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelField from "components/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import CloseFile from "static/images/CloseFile";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useState } from "react";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateTextFieldAlpha,
  validateEmail,
  validatePhoneNumber,
  validateSelectField,
  scrollToTop,
  validateDate,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import * as END_POINTS from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import Calender from "components/molecules/Calender";
import CheckBoxField from "components/atoms/CheckBoxField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { useFormContext } from "../../context/Context";
import { formatToE164 } from "../../../../../../utils/phoneNumberFormat";
import { E164Number } from 'libphonenumber-js';

const ContactsTab = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams<{ companyId: string }>();

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fName: [validateRequired, validateTextFieldAlpha],
      lName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      phNumber: [validatePhoneNumber],
      title: [validateSelectField],
      dob: [validateDate]
    };
    const validationErrors = validateForm(
      { ...state.contacts[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_CONTACTS_FIELD_ERROR",
      field: name,
      error: validationErrors[name],
      index,
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.dynamicErrors[index][name] = "";
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked;
      const newValue = checked  ? 1 : 0;
      dispatch({ type: "UPDATE_CONTACTS_FIELD", field: name, value: newValue, index});
      validation(name, checked, index, true);
    } else {
      dispatch({ type: "UPDATE_CONTACTS_FIELD", field: name, value, index });
      validation(name, value, index, true);
    }
  };

  const handlePhoneNumberChange = (
    phNumber: string | E164Number,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      index,
      field: "phNumber",
      value: phNumber,
    });
    validation("phNumber", phNumber, index, true);
  };
  const handleAddContact = () => {
    dispatch({ type: "ADD_CONTACT" });
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_CONTACT", indexToRemove });
  };

  const handleDateChange = (date: Date | null, name: string, index: number) => {
    const updatedDate = date !== null ? date : new Date();

    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: updatedDate,
      index,
    });
    validation(name, updatedDate, index, true);
  };

  const currentDate = new Date();
  const below15 = new Date();
  below15.setFullYear(currentDate.getFullYear() - 15);

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: selectedOption,
      index,
    });
    validation(name, selectedOption, index, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;

    state.contacts.forEach((contact, index) => {
      if (contact.email.trim() !== "") {
        if (emailSet.has(contact.email)) {
          state.dynamicErrors[index].email = "Duplicate email";
          hasDuplicate = true;
        } else {
          emailSet.add(contact.email);
        }
      }

      if (contact.phNumber != undefined && contact.phNumber.trim() !== "") {
        if (phoneSet.has(contact.phNumber)) {
          state.dynamicErrors[index].phNumber = "Duplicate phone number";
          hasDuplicate = true;
        } else {
          phoneSet.add(contact.phNumber);
        }
      }
    });

    return hasDuplicate;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="create-form-height-company">
          <div className="form-border p-3 pb-0">
            {state.contacts.map((contact, index) => (
              <div
                className="field-set border px-3 pt-4 pb-2 mb-4 rounded-0"
                key={index}
              >
                {index > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-block d-md-block d-lg-none">
                        <Button
                          title="Remove"
                          handleClick={() => handleRemoveContact(index)}
                          className="link-button float-end text-uppercase rounded-0 shadow-none my-2"
                        />
                      </div>
                      <div className="d-none d-md-none d-lg-block">
                        <span
                          title="Delete"
                          onClick={() => handleRemoveContact(index)}
                          className="table-action-icons cursor-pointer float-end text-uppercase rounded-0 shadow-none"
                        >
                          <CloseFile />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12 col-md-2">
                    <SelectWithSearch
                      title="Title"
                      name="title"
                      isMandatory={true}
                      search={true}
                      options={[]}
                      placeHolder="Select"
                      value={contact.title}
                      onChange={(e) => handleSelectChange(e, "title", index)}
                      isMulti={false}
                      className="select-field"
                      error={state.dynamicErrors[index]?.title}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <LabelWithInputField
                      isMandatory={true}
                      name="fName"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.fName ?? ''}
                      id={`fname-${index}`}
                      label="First name"
                      placeholder="First name"
                      error={state.dynamicErrors[index]?.fName}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <LabelWithInputField
                      isMandatory={true}
                      name="lName"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.lName ?? ''}
                      id={`lname-${index}`}
                      label="Last name"
                      placeholder="Last name"
                      error={state.dynamicErrors[index]?.lName}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 position-relative">
                    <Calender
                      onChange={(e) => handleDateChange(e, "dob", index)}
                      selectedDate={contact.dob}
                      label="Date of birth"
                      isMandatory={true}
                      name="dob"
                      maxDate={below15}
                      error={
                        state.dynamicErrors[index]?.dob
                          ? state.dynamicErrors[index]?.dob.toString()
                          : undefined
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="email"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.email ?? ''}
                      id={`email-${index}`}
                      label="Email"
                      placeholder="Email"
                      type="email"
                      error={state.dynamicErrors[index]?.email}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelField
                      title="Phone number"
                      isMandatory={true}
                      key="PhoneInput"
                    />
                    <div className="form-control rounded-0 shadow-none">
                      <PhoneInput
                        defaultCountry="BE"
                        international
                        placeholder="Enter phone number"
                        onChange={(phNumber:E164Number) =>
                          handlePhoneNumberChange(phNumber, index)
                        }
                        value={formatToE164(contact.phNumber, "BE")}
                      />
                    </div>
                    <div className="height-20 mb-2 text-danger">
                      {state.dynamicErrors[index]?.phNumber && (
                        <span className="text-danger">
                          {state.dynamicErrors[index]?.phNumber}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="teleNumber"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.teleNumber ?? ''}
                      id={`teleNumber-${index}`}
                      label="Telephone"
                      placeholder="Telephone"
                      type="number"
                      error={state.dynamicErrors[index]?.teleNumber}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="gsm"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.gsm ?? ''}
                      id={`gsm-${index}`}
                      label="Gsm"
                      placeholder="Gsm"
                      type="text"
                      error={state.dynamicErrors[index]?.gsm}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="contact"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.contact ?? ''}
                      isDisabled={true}
                      id={`contact-${index}`}
                      label="Contact"
                      placeholder="Contact"
                      type="text"
                      error={state.dynamicErrors[index]?.contact}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="functionTitle"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.functionTitle ?? ''}
                      id={`functionTitle-${index}`}
                      label="Function"
                      placeholder="Function"
                      type="text"
                      error={state.dynamicErrors[index]?.functionTitle}
                      className="form-control shadow-none rounded-0"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <SelectWithSearch
                      title="Preferred language"
                      name="language"
                      isMandatory={false}
                      search={true}
                      options={[]}
                      placeHolder="Select"
                      value={contact.language}
                      onChange={(e) => handleSelectChange(e, "language", index)}
                      isMulti={false}
                      className="select-field"
                      error={state.dynamicErrors[index]?.language}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <CheckBoxField
                      label="Decision maker"
                      name="decisionMaker"
                      onChangeHandler={(event) =>
                        handleFieldChange(event, index)
                      }
                      className="me-5 pe-4"
                      isChecked={contact.decisionMaker}
                    />
                    <CheckBoxField
                      label="Influencer"
                      name="influencer"
                      onChangeHandler={(event) =>
                        handleFieldChange(event, index)
                      }
                      className="me-5 pe-4"
                      isChecked={contact.influencer}
                    />
                    <CheckBoxField
                      label="Contact may be called"
                      name="contactCalled"
                      onChangeHandler={(event) =>
                        handleFieldChange(event, index)
                      }
                      className="me-5 pe-4"
                      isChecked={contact.contactCalled}
                    />
                    <CheckBoxField
                      label="Contact may be emailed"
                      name="contactEmailed"
                      onChangeHandler={(event) =>
                        handleFieldChange(event, index)
                      }
                      isChecked={contact.contactEmailed}
                    />
                  </div>
                  <div className="col-lg-12">
                    <LabelWithTextAreaField
                      name="info"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.info ?? ''}
                      label="Info"
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="row my-4">
              <div className="col-md-12">
                <Button
                  title="+ Add another"
                  handleClick={handleAddContact}
                  className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                />
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} isLoading={loading} />
      </form>
    </>
  );
};

export default ContactsTab;