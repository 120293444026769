import React from "react";
import DaysOfWeekTable from "pages/microservices/project/components/shiftTable";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import { calculateWeeklyTotal } from "pages/microservices/project/Utils";
interface ShiftTableProps {
  selectedOption: any;
}

const ShiftTable: React.FC<ShiftTableProps> = ({ selectedOption }) => {
  const { state, dispatch } = useFormContext();
  const currentShift: any = state?.shifts?.data?.shiftTable;

  return (
    <div key={selectedOption.value}>
      <div className="d-flex justify-content-between">
      <h3>{selectedOption.label}</h3>
      {currentShift?.[selectedOption.value] && <>
        <h3>{t("Total weekly time")}: {calculateWeeklyTotal(currentShift?.[selectedOption.value])}</h3>
      </>}
    </div>
      <DaysOfWeekTable
        selectedShift={selectedOption}
        tableIndex={selectedOption.value}
      />
    </div>
  );
};

export default ShiftTable;
