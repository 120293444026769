export const initialWeekDayProps = {
  currentWeek: 1,
  currentMonth: new Date(),
  weekNumber: getCurrentWeekNumber(),
  currentDate: new Date(),
};

function getCurrentWeekNumber() {
  const today = new Date();
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear = (today - firstDayOfYear) / 86400000; // milliseconds in a day
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}


