import React, { useState } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import { UserStatus } from "constants/Constants";
import { UserData } from "./ManageUsers";
import ButtonWithChildren from "components/atoms/ButtonWithChildren";
import ArchieveIcon from "static/images/ArchiveIcon";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import Button from "components/atoms/Button";
import "static/css/users.css";
import { DELETE_USER, UNARCHIVE_USER } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { UserTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { IconData } from "services/IconData";

interface ManageUsersPageProps {
  usersObj: UserData[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  options?: any;
}

const ManageUsersPage: React.FC<ManageUsersPageProps> = ({
  usersObj,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
  options,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any
  ) => {
    const { name, value } = e?.target ?? e;

    // Debounce time in milliseconds
    const debounceTime = 10;
    // Clear any previously set timeout to avoid rapid consecutive calls
    clearTimeout((handleSearchChange as any).timeoutId);
    // Set a new timeout to execute searchValue after debounceTime
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };
  // start of index
  let pageIndex = (currentPage - 1) * itemsPerPage + 1;
  const columns = ["first_name", "last_name", "email", "role", "status"];
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_USER}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        const url = `archiveUser/${deleteID}`; //archive user in candidate-creation
        await ApiCall.getService(url, "GET", "candidate-creation");
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleUnArchiveChanges = async (userId: number) => {
    if (userId) {
      const url = `${UNARCHIVE_USER}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        deleteFilter(userId);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const deleteUser = (userData: UserData | any) => {
    setShowModal(true);
    setDeleteId(userData.user_id);
  };

  const handleEdit = (userData: UserData | any) => {
    if (userData.user_id) {
      navigate(`/user/edit/${userData.user_id}`);
    }
  };

  const handleUnArchive = (userData: UserData | any) => {
    if (userData.user_id) {
      handleUnArchiveChanges(userData.user_id);
    }
  };

  function handleClick(userData: any): void {
    navigate(`/candidate/details/${userData.user_id}`);
  }

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <div className="row header-sticky position-sticky">
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">{t("Manage users")}</h1>
              </div>
            </div>
            <div className="row search-bar">
              <div className="col-md-6 col-lg-3 mb-3">
                <div>
                  <label className="form-label field-label">First name</label>
                </div>
                <Search
                  className="form-control "
                  handleChange={handleSearchChange}
                  placeholder={t("First name")}
                  name="first_name"
                  value={searchData.first_name}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="">
                  <label className="form-label field-label">Last name</label>
                </div>
                <Search
                  className="form-control "
                  handleChange={handleSearchChange}
                  placeholder={t("Last name")}
                  name="last_name"
                  value={searchData.last_name}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="">
                  <label className="form-label field-label">Email</label>
                </div>
                <Search
                  className="form-control "
                  handleChange={handleSearchChange}
                  placeholder={t("Email")}
                  name="email"
                  value={searchData.email}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="">
                  <label className="form-label field-label">Status</label>
                </div>
                <SelectWithSearch
                  containerClassName="mb-0"
                  search
                  onChange={(value) =>
                    handleSearchChange({
                      value: value?.value ?? null,
                      name: "role",
                    })
                  }
                  options={options?.roles}
                  placeHolder={"Role"}
                  name={"role"}
                  value={searchData?.role}
                  isClearable
                  id={"role"}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="">
                  <label className="form-label field-label">Status</label>
                </div>
                <select
                  className="form-select shadow-none  "
                  name="status"
                  id="status"
                  onChange={handleSearchChange}
                  value={searchData.status}
                >
                  <option value="">{t("Status")}</option>
                  {UserStatus?.map((optn, key) => (
                    <option key={key} value={key}>
                      {optn}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col mt-auto text-end">
                <Button
                  title={IconData.ResetIcon}
                  type="submit"
                  handleClick={(e) => handleSubmit(e, true)}
                  className="btn delete-btn  shadow-none text-uppercase me-3 "
                />
                <Button
                  title={t("Search")}
                  type="submit"
                  handleClick={handleSubmit}
                  className="btn form-button  shadow-none text-uppercase px-3 search-btns"
                />

              </div>
            </div>
            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Users",
                      create: true,
                    },
                  ]}
                  override={userData.isSuperAdmin}
                >
                  <Link
                    to="/user/create"
                    className="table-button"
                  >
                    + {t("Create user")}
                  </Link>
                </AccessControl>
              </div>
              <div className="table-responsive manage-users">
                <TableStructure
                  isAction
                  headers={UserTableHeader}
                  data={usersObj}
                  values={columns}
                  handleEdit={handleEdit}
                  handleArchive={deleteUser}
                  handleUnArchive={handleUnArchive}
                  searchStatus={searchData.status}
                  permissionType={"Users"}
                />
              </div>
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          </div>
          
          <div className="col-md-6 align-self-center my-4">
            <Link
              to="/"
              className="text-uppercase back-btn text-decoration-underline"
            >
              Back
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageUsersPage;
