import React, { useState } from "react";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "../translation/Translation";
import { AccomplishData, AccomplishmentDataProps } from "./PartenaInterface";

const AccomplishmentData: React.FC<AccomplishmentDataProps> = ({
    accomplishData,
    handleChange,
    accomplishSearchData,
    dropdownData
}) => {    
    return (
        <>
            <div className="row mt-5">
                <div className="col">
                    <SearchWithLabel
                        isMandatory={false}
                        showLabel={false}
                        name={"employeeName"}
                        className="me-3"
                        type={"text"}
                        value={accomplishSearchData.employeeName}
                        placeholder={t("Find a employee")}
                        isDisabled={true}
                        handleChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        isMandatory={false}
                        showLabel={false}
                        name={"date"}
                        className="me-3"
                        type={"text"}
                        value={accomplishSearchData.date}
                        placeholder={t("Date")}
                        isDisabled={true}
                        handleChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        isMandatory={false}
                        showLabel={false}
                        name={"department"}
                        className=" me-3"
                        type={"text"}
                        value={accomplishSearchData.department}
                        placeholder={t("Departement")}
                        isDisabled={true}
                        handleChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        name={t("all")}
                        isMandatory={false}
                        search={true}
                        placeHolder="Select"
                        options={dropdownData}
                        value={accomplishSearchData.all}
                        onChange={(e) => handleChange(e, "all")}
                        isMulti={false}
                        className="select-field"
                        error={""}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        isMandatory={false}
                        showLabel={false}
                        name={"wageCode"}
                        className="me-3"
                        type={"text"}
                        value={accomplishSearchData.wageCode}
                        placeholder={t("Wage code")}
                        isDisabled={true}
                        handleChange={(e) => handleChange(e)}
                    />
                </div>
            </div>
            <div className="mt-0 table table-responsive">
                <table className="table table-borderless">
                    <thead className="TableHeader">
                        <th>Employee</th>
                        <th>Date</th>
                        <th>Department</th>
                        <th>Type</th>
                        <th>Wage code</th>
                        <th>Shift code</th>
                        <th>Hours</th>
                    </thead>
                    <tbody>
                        {accomplishData.length > 0 && accomplishData.map((accomplish: AccomplishData) => (
                            <tr>
                                <td>{accomplish.employeeName}</td>
                                <td>{accomplish.date}</td>
                                <td>{accomplish.department}</td>
                                <td>{accomplish.type}</td>
                                <td>{accomplish.wageCode}</td>
                                <td>{accomplish.shiftCode}</td>
                                <td>{accomplish.hours}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default AccomplishmentData;