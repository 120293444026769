import DynamicTableStructure from 'components/atoms/DynamicTableStructure'
import React, { useEffect } from 'react'
import { useFilterOptions, useFilterOptionsDispatch } from '../work-pay-bill-roll-redux/context/FilterOptionsContext';
import { useTable, useTableDispatch } from '../work-pay-bill-roll-redux/context/TableContext';
import { handleFilterChange, handleTableDataChange } from '../utils/Utils';
import Pagination from 'components/atoms/Pagination';
import { getManageData } from '../utils/ApiCalls';

const TableMolecule = () => {
    const filterState = useFilterOptions();
    const tableState = useTable();
    const tableDispatch = useTableDispatch();

    useEffect(() => {
        let filters = {
            filterState, tableDispatch, tableState
        }
        getManageData(filters);
    }, [tableState?.current_page, filterState?.selected_date, filterState?.refresh]);

    return (
        <>
            <div className="p-5 border rounded-4 table-custom">
                <DynamicTableStructure
                    data={tableState?.data}
                    headers={tableState?.headers}
                    selectAll={tableState?.select_all}
                    handleSelect={(e) => handleTableDataChange(tableState, tableDispatch, (e?.target?.name ?? 'selected_data'), (e?.target?.checked ?? e))}
                    isChecked={(e: any) => tableState?.selected_data?.includes(e?.id)}
                    handleRowClick={(e) => handleTableDataChange(tableState, tableDispatch, 'overview', e)}
                />
                <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                    <Pagination
                        totalPages={tableState?.totalPages}
                        currentPage={tableState?.current_page}
                        onPageChange={(page: any) =>  handleTableDataChange(tableState, tableDispatch, 'current_page', page)}
                    />
                </div>
            </div>
        </>
    )
}

export default TableMolecule