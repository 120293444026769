import { M_MASTER_DATA } from "constants/Constants";
import { GET_PC_CATEGORIES, GET_PLANNING_FILTER_DATA, GET_VDAB_COMPETENCES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";

export const customExperieneceFunction = (
  payload: any,
  dependentFields: any
) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    return item;
  });
  return newState;
};

export const commonFunction = (payload: any, dependentFields: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;

  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    return item;
  });
  return newState;
};

export const experineceFunction = (payload: any, []) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    if (fieldData.field == "starter" && item.name == "starterWage") {
      item.display = fieldValue === true ? true : false;
    }
    if (fieldData.field == "experienced" && item.name == "experiencedWage") {
      item.display = fieldValue === true ? true : false;
    }

    return item;
  });

  return newState;
};

export const numberOfHoursFunction = (payload: any, []) => {
  let { fieldData, state } = payload;
  const newValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      if (
        (parseInt(newValue) >= 1 && parseInt(newValue) <= 40) ||
        newValue === ""
      ) {
        item.value = newValue;
        item.error = fieldData.error;
      }
    }
    return item;
  });

  return newState;
};

export const filterFunction = async (
  payload: any,
  dependentFields: any,
  dataObj: any
) => {
  let { fieldData, state } = payload;
  const fieldValue =
    fieldData.value.value == undefined
      ? fieldData.value
      : fieldData.value.value;
  var response = await getOptions(fieldValue);
  let newState = state.map((item: any) => {
    if (item.name == "project") {
      item.options = response;

      if (dataObj != null || dataObj != undefined) {
        item.value = dataObj.project;
      }
    }
    if (item.dependencyAction == "filterProjets") {
      item.value = fieldData.value;
    }
    return item;
  });
  return newState;
};

const getOptions = async (value: any) => {
  try {
    const response = await ApiCall.service(
      GET_PLANNING_FILTER_DATA,
     "post",
      {},
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      let projectList = response?.data.companyProjects.filter(
        (item: any) => item.company_id == value
      );
      let filteredProjects = response?.data.projects.filter((project: any) =>
        projectList.some((company: any) => project.value == company.project_id)
      );
      return filteredProjects;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPcFunctionOptions = async (value: any) => {
  try {
    let { fieldData, state } = value;
    let payload: any = {
      type: "pcfunctions",
      pcid: fieldData?.value?.map((item: any) => item?.value ?? item)
    }    
    const response = await ApiCall.service(
      GET_PC_CATEGORIES,
      "POST",
      payload,
      false,
      M_MASTER_DATA,
    );    
    if (response?.status === 200) {      
      let newState = state?.map((item: any) => { 
        if (item?.name == "function") {
          item.options = response?.data;        
          let value: any = [];
          if (item?.value && Array?.isArray(item?.value) && item?.value?.length > 0) {
            value = item?.value?.map((val: any) => val?.value ?? val);            
          }
          item.value = response?.data?.filter((responseItem: any) => value?.includes(responseItem?.value));
        }
        if (item?.dependencyAction === "filterFunctions") {
          item.value = item?.options?.filter((value: any) => payload?.pcid?.includes(value?.value));
        }
        return item;
      });
      return newState;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getVdabCompetences = async (payload: any) => {
  try {
    let { fieldData, state } = payload;    
    const vdabTemplateId = fieldData?.value?.template_id
    if (vdabTemplateId !== 0) {
      const response = await ApiCall.getService(`${GET_VDAB_COMPETENCES}/${vdabTemplateId}`, "GET", M_MASTER_DATA, true);
      if (response.status === 200) {
        const vdabCompetences = mapToSelect(response?.data);
        let newState = state?.map((item: any) => {
          if (item?.dependencyAction === "vdabTemplate") {
            item.value = fieldData?.value;
            item.error = fieldData?.value ? "" : item.error;
            state.map((record: any) => {
              if (record?.name === "vdab_competence") {
                record.value = fieldData?.value?.vdab_competence ?? "";
                record.class = "d-block";
                record.options = vdabCompetences
              }
            })
          }
          return item;
        });
        return newState;
      } else {
        return state;
      }
    }
  } catch (error) {
    console.log(error);
  }
};