import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import '../../static/css/siidebar.css';
import HeaderMenus from './HeaderMenu';

const SideMenuBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='d-none d-lg-block'>
      <nav className={`main-menu pt-3 ${isExpanded ? 'expanded' : ''}`}>
        <div className="scrollbar" id="style-1">
          <ul className='list-unstyled px-3'>
            <li className='text-center pb-5 mb-3 logo'>
              <a href="/dashboard">
                {isExpanded ?
                  <img src='/static/images/logo.svg' alt='logo' className='img-fluid expanded-sidebar-logo pt-2' /> :
                  <img src='/static/images/favicon.ico' alt='logo' className='img-fluid sidebar-logo' />
                }
              </a>
            </li>
            <HeaderMenus />
          </ul>
          <ul className='list-unstyled position-absolute bottom-0 end-0'>
            <li className='pb-4'>
              <button onClick={handleToggleMenu} className='rounded-3 sidebar-icon d-flex align-items-center justify-content-center ms-auto me-3'>
                <span className="nav-text">
                  {isExpanded ? <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />}
                </span>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default SideMenuBar;
