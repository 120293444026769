import {PLANNING_MODAL_ACTIONS} from "../../actions/PlanningModalActions"

export const planningModalReducer = (planningModalState, actions) => {
    switch(actions.type){
        case PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODEL_FIELD: 
            {return update_field(planningModalState, actions);}
        
        case PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_FIELD:
            {return update_builk_field(planningModalState, actions)}

        case PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODEL_ERROR_FIELD:
            {return update_error_field(planningModalState, actions)}

        case PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_ERROR_FIELD:
            {return update_builk_error_field(planningModalState, actions)}

        case PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODAL_DEPENDENT_FIELD:
            {return update_dependent_field(planningModalState, actions)}
        
        case PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD:
            {return update_bulk_dependent_field(planningModalState, actions)}

        default:
            throw Error('Unknown action: ' + actions.type);
    }
}

function update_field(planningModalState, actions) {
    return {
        ...planningModalState,
        state: {
            ...planningModalState.state,
            [actions.payload.field]: actions.payload.value
        }
    };
}

function update_builk_field(planningModalState, actions){
    return {
        ...planningModalState,
        state: {
            ...planningModalState.state,
            ...actions.payload
        }
    };
}

function update_error_field(planningModalState, actions) {
    return {
        ...planningModalState,
        errorState: {
            ...planningModalState.errorState,
            [actions.payload.field]: actions.payload.value
        }
    };
}


function update_builk_error_field(planningModalState, actions){
    return {
        ...planningModalState,
        errorState: {
            ...planningModalState.errorState,
            ...actions.payload
        }
    };
}


function update_dependent_field(planningModalState, actions) {
    return {
        ...planningModalState,
        dependentState: {
            ...planningModalState.dependentState,
            [actions.payload.field]: actions.payload.value
        }
    };
}

function update_bulk_dependent_field(planningModalState, actions) {
    return {
        ...planningModalState,
        dependentState: {
            ...planningModalState.dependentState,
            ...actions.payload
        }
    };
}