import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  APPROVE_PROJECT_PROPOSAL,
  APPROVE_TEMP_AGENCY_PROPOSAL,
  GENERATE_PROJECT_PROPOSAL,
  GENERATE_TEMP_AGENCY_PROPOSAL,
  SEND_PROJECT_PROPOSAL,
  SEND_TEMP_AGENCY_PROPOSAL,
} from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useNavigate, useParams } from "react-router-dom";
import {
  PATH_ACCESS_DENIED,
  PATH_MANAGE_TEMP_AGENCY,
  PATH_PROJECTS_MANAGE,
  PATH_TEMP_AGENCY_PROPOSAL,
} from "constants/Paths";
import Button from "components/atoms/Button";
import SignatureCanvas from "react-signature-canvas";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import EncryptDecryptService from "services/EncryptDecryptService";
import CustomNotify from "components/atoms/CustomNotify";
import SignatureModal from "pages/microservices/project/Proposals/SignatureModel";
import EmployerAuthenticModal from "pages/microservices/project/Proposals/EmployerAuthenticModal";

interface ProjectsProps {
  id?: string | number;
}

const TempAgencyOverview: React.FC<ProjectsProps> = () => {
  const url = new URL(window.location.href);
  const dataParam = url.searchParams.get("data");
  const aggreeParam = url.searchParams.get("aggree");
  const userParam = url.searchParams.get("user");
  const [id, setId] = useState("");
  const [showApproveButton, setShowApproveButton] = useState(false);
  const [showSignatureButton, setShowSignatureButton] = useState(false);
  const [clientMailStatus, setClientMailStatus] = useState(false);
  const [managerMailStatus, setManagerMailStatus] = useState(false);
  const navigate = useNavigate();

  const userAuth = useSelector(selectAuth);
  const [htmlPath, setHtmlPath] = useState("");
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (dataParam) {
      const decryptedTempAgencyID = atob(dataParam);
      if (aggreeParam !== null) {
        setId(decryptedTempAgencyID);
        setShowSignatureButton(true);
        if (userParam !== null) {
          const decryptedUsertId = Number(atob(userParam)); // Convert to number
          if (decryptedUsertId !== userAuth.userId) {
            navigate(PATH_ACCESS_DENIED);
          }
        }
      } else {
        const decodedData = decodeURIComponent(dataParam);
        const append = EncryptDecryptService.decryptData(decodedData);
        const parsedData = JSON.parse(append);
        if (parsedData.users !== null) {
          setUsers(parsedData.users);
        }
        if (parsedData.id !== null && parsedData.status !== null) {
          setId(parsedData.id);
          if (parsedData.approve === "approve") {
            setShowApproveButton(true);
          }
          if (
            parsedData.ManagerMailStatus !== null &&
            parsedData.clientMailStatus !== null
          ) {
            if (parsedData.ManagerMailStatus === 1) {
              setManagerMailStatus(true);
            }
            if (parsedData.clientMailStatus === 1) {
              setClientMailStatus(true);
            }
          }
        }
      }
    }
  }, [dataParam, aggreeParam, userParam, userAuth.userId, navigate]);

  useEffect(() => {
    if (id) {
      generateTempAgencyProposal(id);
    }
  }, [id]);

  const generateTempAgencyProposal = async (id: any) => {
    try {
      const response = await ApiCall.service(
        GENERATE_TEMP_AGENCY_PROPOSAL,
        "POST",
        { id: id },
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setHtmlPath(response?.proposalPath.html_file_path);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response?.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({ type: "error", message: "An error occurred." });
    }
  };
  
  const [showModal, setShowModal] = useState(false);
  const [showCheckBox, setShowCheckbox] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showSignatureModal, setSignatureModal] = useState(false);
  const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
  const [checked, setChecked] = useState({
    check: 0,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const signatureRef = useRef<SignatureCanvas | null>(null);

  const handleBackButtonClick = () => {
    navigate(`${PATH_MANAGE_TEMP_AGENCY}`);
  };

  const handleSendToManager = async () => {
    const response = await ApiCall.service(
      SEND_TEMP_AGENCY_PROPOSAL,
      "POST",
      { tempAgencyId: id, toManager: true },
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      CustomNotify({ type: "success", message: `${response.message}` });
      navigate(`${PATH_MANAGE_TEMP_AGENCY}`);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setChecked((prevState) => ({
      ...prevState,
      check: 0,
    }));
    setSignatureModal(false);
  };

  const handleAuthenticationSuccess = (
    success: any,
    email: any,
    signatureImage: any
  ) => {
    if (success) {
      setShowCheckbox(false);
      setChecked((prevState) => ({
        ...prevState,
        check: 1,
      }));
      setIsAuthenticated(success);
    } else {
      setShowCheckbox(true);
      setChecked((prevState) => ({
        ...prevState,
        check: 1,
      }));
      setIsAuthenticated(success);
    }
  };

  const handleSendToEmployer = async () => {
    const response = await ApiCall.service(
      SEND_TEMP_AGENCY_PROPOSAL,
      "POST",
      { tempAgencyId: id },
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      CustomNotify({ type: "success", message: `${response.message}` });
      navigate(`${PATH_MANAGE_TEMP_AGENCY}`);
    }
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };

  const handleSaveSignature = async () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL();
      handleModalClose();
      setSignatureErrorMessage("");
      const url = `${GENERATE_TEMP_AGENCY_PROPOSAL}`;
      const data = {
        signature: signatureImage,
        tempAegencyId: id,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setShowSignatureButton(false);
        const htmlFilePath = response?.proposalPath.html_file_path;
        const data = {
          id: id,
          html_path: htmlFilePath,
          approve: "approve",
        };
        const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
        const encodedData = encodeURIComponent(append);
        navigate(`${PATH_TEMP_AGENCY_PROPOSAL}?data=${encodedData}`);
      }
    } else {
      setSignatureErrorMessage(t("Please add signature before saving."));
    }
  };

  const handleCheckbox = (checked: boolean) => {
    setChecked((prevState) => ({
      ...prevState,
      check: checked ? 1 : 0,
    }));
    if (checked) {
      setShowModal(true);
    }
  };
  const handleSignAsEmployer = () => {
    setShowCheckbox(true);
    setShowButton(false);
  };
  const handleAddSignature = () => {
    setSignatureModal(true);
  };
  const handleAppoveProposal = async () => {
    if (id) {
      const url = `${APPROVE_TEMP_AGENCY_PROPOSAL}`;

      const response = await ApiCall.service(
        url,
        "POST",
        { tempAgencyId: id },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: `${response.message}` });
        navigate(`${PATH_MANAGE_TEMP_AGENCY}`);
      }
    }
  };
  return (
    <>
      <WindowHeightComponent>
        <div className="d-flex h-100 overflow-auto flex-column">
          <div className="flex-1 overflow-auto">
            {htmlPath ? (
              <iframe
                src={`${process.env.REACT_APP_CONTRACT_URL}/${htmlPath}`}
                height="99%"
                width="100%"
              />
            ) : (
              <p>No document available</p> // Optionally show a message or nothing
            )}
          </div>
          <div>
            {showCheckBox && (
              <CheckBoxField
                className=" p-2 border rounded"
                name={"checkbox"}
                onChangeHandler={(e) => handleCheckbox(e.target.checked)}
                isChecked={checked.check === 1}
                label={t(
                  "I hereby declare that I have reviewed the document and confirming that I agree with all details"
                )}
              />
            )}
            <div className="d-flex justify-content-between my-3">
              <Button
                type="button"
                title={t("Back")}
                handleClick={handleBackButtonClick}
                className="btn form-button px-3 shadow-none  text-uppercase"
              />

              {showButton && !showApproveButton && !showSignatureButton && (
                <div className="align-self-center ">
                  <Button
                    type="button"
                    title={t("Sign as client")}
                    handleClick={handleSignAsEmployer}
                    className="btn me-3 form-button shadow-none text-uppercase"
                  />

                  <Button
                    type="button"
                    title={
                      clientMailStatus
                        ? t("Resent to client")
                        : t("Send to client")
                    }
                    handleClick={handleSendToEmployer}
                    className="btn me-3 form-button shadow-none text-uppercase"
                  />
                  <Button
                    type="button"
                    title={
                      managerMailStatus
                        ? t("Resent to Manager")
                        : t("Send to Manager")
                    }
                    handleClick={handleSendToManager}
                    className="btn me-3 form-button shadow-none text-uppercase"
                  />
                </div>
              )}
              {(isAuthenticated && !showApproveButton) ||
              showSignatureButton ? (
                <Button
                  type="button"
                  title={t("Add Signature")}
                  handleClick={handleAddSignature}
                  className="btn form-button shadow-none text-uppercase"
                />
              ) : null}
              {showApproveButton && ( // Conditionally render buttons based on authentication status
                <Button
                  type="button"
                  title={t("Approve")}
                  handleClick={handleAppoveProposal}
                  className="btn  form-button shadow-none  text-uppercase"
                />
              )}
            </div>
            {showModal && (
              <EmployerAuthenticModal
                handleClose={handleModalClose}
                onAuthenticationSuccess={handleAuthenticationSuccess}
                users={users}
              />
            )}
            <div>
              {showSignatureModal && (
                <SignatureModal
                  handleClose={handleModalClose}
                  handleClearSignature={handleClearSignature}
                  handleSaveSignature={handleSaveSignature}
                  signatureRef={signatureRef}
                  signatureErrorMessage={signatureErrorMessage}
                />
              )}
            </div>
          </div>
        </div>
      </WindowHeightComponent>
    </>
  );
};

export default TempAgencyOverview;
