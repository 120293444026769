import React, { useEffect, useState } from "react";
import LabelField from "components/atoms/LabelField";
import InputTextfield from "components/atoms/InputTextField";
import HidePassword from "static/images/Hide.svg";
import ShowPassword from "static/images/Show.svg";
import { useLocation, useNavigate } from "react-router-dom";
import EncryptDecryptService from "services/EncryptDecryptService";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import LoadingIcon from "utils/LoadingIcon";
import {
    validateForm,
    validateRequired,
    validatePassword,
    validateMatchPassword,
} from 'services/validation/ValidationService';
import ImageField from "components/atoms/ImageField";
import CustomNotify, { NotifyType } from "components/atoms/CustomNotify";
import { RESET_PASSWORD } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_IDENTITY_MANAGER } from "constants/Constants";

const ResetPasswordForm: React.FC = () => {
    const navigate = useNavigate();
    interface FormFields {
        password?: string;
        confirmPassword?: string;
    }

    interface PasswordTypes {
        passwordType?: string;
        confirmPasswordType?: string;
    }

    interface ValidationRules {
        [key: string]: Function[];
    }

    let location = useLocation();

    const urlSearchParams = new URLSearchParams(location.search);

    const queryParamValue = urlSearchParams.get("email");

    const decryptedEmail = queryParamValue ? atob(queryParamValue) : "";
    const splitArray = decryptedEmail.split("email-");
    const email = splitArray[1];

    const [passwordTypes, setPasswordTypes] = useState<PasswordTypes>({
        passwordType: "password",
        confirmPasswordType: "password",
    });

    const [formFields, setFormFields] = useState<FormFields>({
        password: "",
        confirmPassword: "",
    });

    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState<FormFields>({});

    const handleSubmit = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement;
        const formData = {
            password: EncryptDecryptService.encryptData(
                JSON.stringify(formFields.password)
            ),
            confirmPassword: EncryptDecryptService.encryptData(
                JSON.stringify(formFields.confirmPassword)
            ),
            email: email,
        };

        if (validation(name, value)) {
            const response = await ApiCall.service(RESET_PASSWORD, "POST", formData);
            if (response.status === 200) {
                const userDetails = response.userStatus;
                if (userDetails.userId !== null) {
                    const canResponse = await ApiCall.service("updateCandidateStatus", "POST", userDetails, false, 'candidate-creation');
                }
                let notificationType: NotifyType = 'success';
                setTimeout(() => {
                    navigate("/login");
                }, 2000); // Navigate after 5 seconds
                CustomNotify({ type: notificationType, message: response.msg });
            }
            else {
                navigate('/forgot-password');
                CustomNotify({ type: "warning", message: response.message });
            }
        }
        setLoading(false);
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields((prevData) => ({ ...prevData, [name]: value }));
        validation(name, value, true);
    };


    const validation = (
        name: string,
        value: string,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            password: [validateRequired, validatePassword],
            confirmPassword: [
                validateRequired,
                validatePassword,
                (value: string) => formFields.password ? validateMatchPassword(formFields.password, value) : undefined,
            ],
        };

        const validationErrors = validateForm(
            { ...formFields, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        if (isSingleFieldValidation && Object.keys(errors).length > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: validationErrors[name] }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const togglePassword = (fieldType: number) => {
        if (fieldType === 0) {
            passwordTypes.passwordType === "password"
                ? setPasswordTypes((prevData) => ({
                    ...prevData,
                    passwordType: "text",
                }))
                : setPasswordTypes((prevData) => ({
                    ...prevData,
                    passwordType: "password",
                }));
        } else {
            passwordTypes.confirmPasswordType === "password"
                ? setPasswordTypes((prevData) => ({
                    ...prevData,
                    confirmPasswordType: "text",
                }))
                : setPasswordTypes((prevData) => ({
                    ...prevData,
                    confirmPasswordType: "password",
                }));
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-7 col-xl-6 col-lg-6 vh-100 ps-0 d-none d-md-none d-lg-block">
                        <ImageField
                            altText="Banner"
                            className="w-100 h-100 img-fluid object-fit-cover"
                            src="/static/images/loginImage.jpg"
                        />
                    </div>
                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-8 m-auto register-form__fields p-xxl-3 p-xl-0 p-lg-0">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-xxl-8 col-xl-8 col-lg-9 my-4 m-auto px-xxl-1 px-lg-0">
                                <div className="col-md-12 text-center mb-5">
                                    <ImageField
                                        altText="Logo"
                                        className="text-center logo-width"
                                        src="static/images/logo.svg"
                                    />
                                </div>
                                <h4 className="text-center login-title mb-4">{t("Confirm password")}</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="col-lg-12">
                                        <LabelField title={t("Password")} isMandatory={true} htmlfor="password" />
                                        <div className="d-flex position-relative">
                                            <InputTextfield
                                                handleChange={changeHandler}
                                                type={passwordTypes.passwordType}
                                                value={formFields.password}
                                                id="password"
                                                placeholder={t("Enter password")}
                                                name="password"
                                                className="form-control shadow-none rounded-0"
                                            />
                                            <p
                                                onClick={() => togglePassword(0)}
                                                className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                                            >
                                                {passwordTypes.passwordType === "password" ? (
                                                    <img src={HidePassword} alt="Hide Password" />
                                                ) : (
                                                    <img src={ShowPassword} alt="Show Password" />
                                                )}
                                            </p>
                                        </div>
                                        <div className="height-20 mb-2">
                                            {errors.password && (
                                                <span className="text-danger">{errors.password}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <LabelField
                                            title={t("Confirm Password")}
                                            isMandatory={true}
                                            htmlfor="confirm-password"
                                        />
                                        <div className="d-flex position-relative">
                                            <InputTextfield
                                                handleChange={changeHandler}
                                                type={passwordTypes.confirmPasswordType}
                                                value={formFields.confirmPassword}
                                                id="confirm-password"
                                                placeholder={t("Confirm password")}
                                                name="confirmPassword"
                                                className="form-control shadow-none rounded-0"
                                            />
                                            <p
                                                onClick={() => togglePassword(1)}
                                                className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                                            >
                                                {passwordTypes.confirmPasswordType === "password" ? (
                                                    <img src={HidePassword} alt="Hide Password" />
                                                ) : (
                                                    <img src={ShowPassword} alt="Show Password" />
                                                )}
                                            </p>
                                        </div>
                                        <div className="height-20 mb-2">
                                            {errors.confirmPassword && (
                                                <span className="text-danger">{errors.confirmPassword}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-10 mx-auto">
                                            {!loading ? (
                                                <Button
                                                    title={t("Submit")}
                                                    type="submit"
                                                    className="btn form-button mb-3 w-100 shadow-none rounded-0 text-uppercase"
                                                />
                                            ) : (
                                                <LoadingIcon
                                                    iconType="bars"
                                                    color="#00a5ce"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordForm;
