import { classNames } from "primereact/utils";

const MANAGE_PAYROLL_HEADERS = [
    { name: "Business unit", alias: 'business_unit', width: '30' },
    { name: 'Emplyee name', alias: 'employee', width: '35' },
    { name: 'Contract type', alias: 'contract_type', width: '30' }
]

export const MANAGE_PAYROLL_CONSTANTS = {
    EXPORT: 'Export',
    PRINT: "Print",
    MANAGE_PAYROLL: "Manage payroll",
    PAYROLL_OVERVIEW: "Payroll overview",
    SEARCH: "Search",
    SEARCH_BUSINESS_UNIT: "Serach business unit",
    SEARCH_EMPLOYEE: "Search employee",
    SEARCH_CONTRACT_TYPE: "Search contract type",
    SUBMIT: "Submit",
    RESET: "Reset",
    BACK: "Back",
    ITEMS_PER_PAGE: 10,
}

export const initialFilterProps = {
    business_unit: '',
    employee: '',
    contract_type: '',
    selected_date: new Date(),
    popup: {
        export: {
            from: null,
            to: null,
            show: false
        }
    },
    print: false,
    refresh: false
};

export const initialTableProps = {
    data: [],
    headers: MANAGE_PAYROLL_HEADERS,
    select_all: false,
    selected_data: [],
    overview: false,
    totalPages: 1,
    totalCount: 0,
    current_page: 1,
};

export const initialOverviewProps = {
    data: [],
    headers: {
        maintableheader: [],
        hoursworkedheader: [],
        projecthourheader: [],
    },
};

export const PayRollReducerTypes = {
    SET_FILTER: 'SET_FILTER',
    SET_TABLE_DATA: 'SET_TABLE_DATA',
    SET_OVERVIEW_DATA: 'SET_OVERVIEW_DATA',
}

export const filterValues = (options?: any) => {
    let row = [
        {
            name: 'business_unit',
            type: 'text',
            placeholder: 'Serach business unit',
            value: "",
            class: "w-100",
            label: "Business unit"
        },
        {
            name: 'employee',
            type: 'text',
            placeholder: 'Serach employee',
            value: "",
            class: "w-100",
            label: "Employee"
        },
        {
            name: 'contract_type',
            type: 'text',
            placeholder: 'Serach contract type',
            value: "",
            class: "w-100",
            label: "Contract type"
        },
    ]
    return row;
}