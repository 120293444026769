import "../functionsProfile/function.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import ChildComponent from "./ChildComponent";
import CustomNotify from "components/atoms/CustomNotify";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "../../../../../constants/Constants";

interface WorkTypeName {
  whAj: number | null;
  whClient: number | null;
  wtAj: number | null;
  wtClient: number | null;
  whtAj: number | null;
  whtClient: number | null;
}

const WorkTypeExtra: React.FC = () => {
  const [formData, setFormData] = useState<WorkTypeName[]>([
    {
      whAj: 0,
      whClient: 0,
      wtAj: 0,
      wtClient: 0,
      whtAj: 0,
      whtClient: 0,
    },
  ]);

  const navigate = useNavigate();
  const [labelName, setLabelName] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await ApiCall.getService(
      ENDPOINTS.GET_WORK_TYPE_COEFFICIENT,
      "GET",
      M_COMPANY_CREATION,
      false
    );
    if (response.status === 200) {
      const coefficients = response.data["coefficients"];
      const workTypeLabel = response.data["workTypeLabel"];
      setLabelName(workTypeLabel);
      if (coefficients.length > 0) {
        setFormData(coefficients);
      }
    }
  };

  const changehandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const newFields = [...formData];
    newFields[index] = {
      ...newFields[index],
      [name]: value,
    };

    setFormData(newFields);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await ApiCall.service(
      ENDPOINTS.STORE_WORK_TYPE_COEFFICIENT,
      "POST",
      formData,
      false,
      M_COMPANY_CREATION
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      navigate("/config/settings");
    }
  };
  return (
    <>
      <div className="header-sticky position-sticky">
        <div className="col-md-12">
          <h1 className="py-4 page-title mb-0">Work type extra</h1>
        </div>
      </div>
      <div className="manage-function-profile-table">
        <form onSubmit={handleSubmit}>
          <div className="table-responsive form-border p-4">
            <table className="table">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-4">Work type extra</th>
                  <th>Factor</th>
                </tr>
              </thead>
              <tbody>
                {formData.map((item: any, index: number) => (
                  <ChildComponent
                    key={index}
                    label={labelName}
                    data={item}
                    id={index}
                    changeHandler={changehandler}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="row my-3">
            <div className="col-md-6 align-self-center">
              <Link
                to="/config/settings"
                className="text-uppercase back-btn text-decoration-underline"
              >
                Back
              </Link>
            </div>
            <div className="col-md-6 text-lg-end">
              <Button
                title="Submit"
                type="submit"
                className="btn form-button rounded-0 shadow-none text-uppercase px-3 button-width"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default WorkTypeExtra;
