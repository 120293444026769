import { PATH_MANAGE_TIMETABLE } from "constants/Paths";
import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { t } from "../translation/Translation";
import ShiftTableMolecule from "components/molecules/ShiftTableMolecule";
import {
  GET_SHIFT_DETAILS,
  GET_SHIFT_OPTIONS,
  STORE_TIMETABLE,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import ShiftTable from "./ShiftTable";
import CustomNotify from "components/atoms/CustomNotify";
import {
  DISABLED_DAYS,
  FILL_TIMESHEET_ERROR,
  FROM_REQUIRED,
  M_MASTER_DATA,
  TILL_REQUIRED,
  TIMETABLE_ERROR,
} from "constants/Constants";
import { isValidTime, validateTimeRange } from "services/validation/ValidationService";

interface item {
  id?: number;
  shift_name: string | any;
  value?: number;
}

const CreateTimeTable: React.FC = ({}) => {
  const [shiftOptions, setShiftOptions] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState<item[]>([]);
  const [shifts, setShifts] = useState<any[]>([]);
  const [errors, setErrors] = useState({
    name: "",
    shiftErrors: {}
  })
  let { id } = useParams<{ id: string }>();
  let data: any;
  const navigate = useNavigate();

  useEffect(() => {
    fetchShifts();
  }, [id]);

  const fetchShifts = async (selectedId?: any) => {
    data = { id: id };
    const response = await ApiCall.service(
      GET_SHIFT_OPTIONS,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      if (id) {
        //if id is there fetch all the details of particular shift
        const responseData = await ApiCall.service(
          GET_SHIFT_DETAILS,
          "POST",
          { id: [id], action:"edit" },
          false,
          M_MASTER_DATA
        );
        setShifts(responseData?.data?.shiftTable);
        setSelectedShifts(
          response?.data?.map((shift: item) => ({
            value: shift?.id,
            label: shift?.shift_name,
          }))
        );
        setShiftOptions(
          response?.data?.map((shift: item) => ({
            value: shift?.id,
            label: shift?.shift_name,
          }))
        );
        setErrors((prev) => ({ ...prev, shiftErrors: responseData?.data?.shiftErrors}));
      } else {
        //if id is not there set the remaining shifts in the option
        setShiftOptions(
          response?.data?.map((shift: item) => ({
            value: shift?.id,
            label: shift?.shift_name,
          }))
        );
      }
    }
  };

  const handleShiftChange = async (selectedValues: item[]) => {
    const selectedShiftIds = selectedValues.map(shift => shift.value);
    const currentShiftIds = Object.keys(shifts).map(Number);
    const newShiftIds = selectedShiftIds.filter((id: any) => !currentShiftIds.includes(id));
    const removedShiftIds = currentShiftIds.filter(id => !selectedShiftIds.includes(id));
    // Update selected shifts
    setSelectedShifts(selectedValues);
    if (newShiftIds.length > 0) {
      const response = await ApiCall.service(
        GET_SHIFT_DETAILS,
        "POST",
        { id: newShiftIds },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setShifts(prevShifts => ({
          ...prevShifts,
          ...response.data.shiftTable,
        }));
        setErrors(prevErrors => ({
          ...prevErrors,
          name: selectedValues.length > 0 ? "" : "The field is required",
          shiftErrors: {
            ...prevErrors.shiftErrors,
            ...response.data.shiftErrors,
          }
        }));
      }
    }
  
    // Remove the deselected shifts and their errors from the state
    if (removedShiftIds.length > 0) {
      setShifts(prevShifts => {
        const updatedShifts: any = { ...prevShifts };
        removedShiftIds.forEach(id => delete updatedShifts[id]);
        return updatedShifts;
      });
      setErrors(prevErrors => {
        const updatedErrors: any = { ...prevErrors.shiftErrors };
        removedShiftIds.forEach(id => delete updatedErrors[id]);
        return {
          ...prevErrors,
          name: selectedValues.length > 0 ? "" : "The field is required",
          shiftErrors: updatedErrors,
        };
      });
    }
  };   

  const validate = (day: any, value: any, id?: any) => {
    let error: any = "";    
    let shiftdata = shifts?.[id]?.[day];
    if (value?.field === "from" && (isValidTime(shiftdata?.till) || !isValidTime(value?.value))){
      error = validateTimeRange(value?.value, shiftdata?.till);
    } else if (value?.field === "till" && (isValidTime(shiftdata?.from) || !isValidTime(value?.value))) {
      error = validateTimeRange(shiftdata?.from, value?.value);
    } else if (value?.field === "pause" && isValidTime(shiftdata?.from) && isValidTime(shiftdata?.till)) {
      error = validateTimeRange(shiftdata?.from, shiftdata?.till, value?.value);
    }
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      shiftErrors: {
        ...prevErrors.shiftErrors,
        [id]: {
          ...prevErrors.shiftErrors[id],
          [day]: {
            ...prevErrors.shiftErrors[id][day],
            [value?.field]: error ?? "",
          }
        }
      }
    }));
    return error;
  }

  const handleSubmit = async () => {
    let errors: any = {};
    let hasError = selectedShifts?.length < 1;
    if (hasError) {
      setErrors((prev: any) => ({ ...prev, name: "The field is required" }))
    } else {
      Object.keys(shifts).map((shiftId: any) => {
        Object.keys(shifts[shiftId]).map((day: string) => {
          if (!DISABLED_DAYS.includes(day)) {
            const { from, till, pause } = shifts[shiftId][day];            
            if (!errors[shiftId]) errors[shiftId] = {};
            if (!errors[shiftId][day]) errors[shiftId][day] = {};
            errors[shiftId][day]['from'] = validate(day, { field: 'from', value: from }, shiftId);
            errors[shiftId][day]['till'] = validate(day, { field: 'till', value: till }, shiftId);
            errors[shiftId][day]['pause'] = validate(day, { field: 'pause', value: pause }, shiftId);
            if (!hasError && (errors[shiftId][day]['from'] || errors[shiftId][day]['till'] || errors[shiftId][day]['pause'])) {
              hasError = true;
            }
          }
        });
      });
      if (!hasError) {
        const response = await ApiCall.service(
          STORE_TIMETABLE,
          "POST",
          shifts,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({ type: "success", message: "Time table created successfully" });
          navigate(`${PATH_MANAGE_TIMETABLE}`); return;
        }
        CustomNotify({ type: "error", message: "Error while creating Time table" });
      }
    }
  };

  return (
    <>
      <form>
        <div className="form-border mb-3 p-5">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <ShiftTableMolecule
                title={t("Select shift")}
                options={shiftOptions}
                error={errors?.name}
                value={selectedShifts}
                onChange={handleShiftChange}
              />
              {selectedShifts?.map((selectedShift) => (
                <ShiftTable
                  shifts={shifts}
                  setShifts={setShifts}
                  error={errors?.shiftErrors}
                  validate={(day, value) => validate(day, value, selectedShift?.value)}
                  key={selectedShift.id}
                  selectedOption={selectedShift}
                />
              ))}
            </div>
          </div>
        </div>
      </form>
      <div className="d-flex justify-content-between my-3 align-items-center">
        <div className="">
          <Link
            to={PATH_MANAGE_TIMETABLE}
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>

        <div className="">
          <Button
            title={t("Save")}
            handleClick={handleSubmit}
            className="btn form-button  shadow-none text-uppercase px-3 button-widths"
          />
        </div>
      </div>
    </>
  );
};

export default CreateTimeTable;
