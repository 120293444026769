import React, { useEffect } from 'react'
import DynamicTable from '../atoms/DynamicTable'
import { useFilterOptions } from '../work-pay-bill-roll-redux/context/FilterOptionsContext';
import { t } from 'pages/microservices/masterData/translation/Translation';
import { getDate } from '../utils/Utils';
import { ApiCall } from 'services/ApiServices';
import { GET_PAYROLL } from 'routes/ApiEndpoints';
import { M_MASTER_DATA } from 'constants/Constants';
import { useOverview, useOverviewDispatch } from '../work-pay-bill-roll-redux/context/OverviewContext';
import { PayRollReducerTypes } from '../constants/WorkPayBillRollConstants';
import { useTable } from '../work-pay-bill-roll-redux/context/TableContext';
import { getOverviewData } from '../utils/ApiCalls';

const OverviewMolecule = () => {
    const tableState = useTable();
    const filterState = useFilterOptions();
    const overViewState = useOverview();
    const overViewDispatch = useOverviewDispatch();

    useEffect(() => {
        let filters = {
            filterState, overViewDispatch, tableState, overViewState
        }
        getOverviewData(filters);
    }, []);

    return (
        <>
            {overViewState?.data && Object?.values(overViewState?.data)?.map((employeeData: any, index: number) => (                             
                <div key={index}>
                    <h2>{t("Payroll of") + " " + (tableState?.overview?.business_unit ?? employeeData?.mainTableData[0]?.[0]?.business_unit) + " - " + (tableState?.overview?.employee ?? employeeData?.mainTableData[0]?.[0]?.employee) + ` ${t("on")} ` + getDate(employeeData?.mainTableData[0]?.[0]?.date, { month: 'short', year: 'numeric' })}</h2>
                    {employeeData?.mainTableData && <DynamicTable
                        tableWidth={100}
                        headers={overViewState?.headers?.maintableheader}
                        data={employeeData?.mainTableData}
                    />}
                    <div className="d-flex">
                        {employeeData?.hoursWorkedData && <DynamicTable
                            tableWidth={75}
                            headers={overViewState?.headers?.hoursworkedheader}
                            data={employeeData?.hoursWorkedData}
                        />}
                        {employeeData?.projectHoursData && <DynamicTable
                            mainclass='ms-2'
                            tableWidth={25}
                            headers={overViewState?.headers?.projecthourheader}
                            data={employeeData?.projectHoursData}
                        />}
                    </div>
                </div>
            ))}
        </>
    )
}

export default OverviewMolecule