import PlannedEventBox from "../atoms/plannedEventBox";
import { dateUtils } from "./dateUtils";
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}


export const getRandomInt = (min, max) => {
  return Date.now() + Math.random().toString(36).substring(7);
  // min = Math.ceil(min);
  // max = Math.floor(max);
  // return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}

export const setCurrentViewUnit = (view) => {
  //view structure =>  { value: "day", label: "Day" },
  switch (view.value) {
    case "week":
      return dateUtils.getCurrentWeek();
    case "day":
    case "month":
      return dateUtils.getCurrentMonth();
    case "year":
      return dateUtils.getCurrentMonth();
    default:
      return null;
  }
}

export const renderPlannedEvents = (planningState, employee, date, handleWeeklyClickEventOnPlannedUnit, frequency) => {
  let planslist = {};
  let employeePlans = planningState.planningEvents &&
    planningState.planningEvents[employee.id] &&
    planningState.planningEvents[employee.id].plans;

  if (employeePlans) {
    employeePlans.forEach((plan) => {
      let plandate = dateUtils.getFormattedDates(plan.plan_date);
      if (plandate === date['value']) {
        if (!planslist[plandate]) {
          planslist[plandate] = [];
        }
        planslist[plandate].push(plan);
      }
    });
  }

  return Object.entries(planslist).map(([date, plans]) => (
    <div className="position-absolute top-0 d-flex w-100 h-100">
      <PlannedEventBox
        key={date} // Use date as the unique key for each element
        date={date}
        employee_id={employee.id}
        // bg_color={"green"}
        handleWeeklyClickEventOnPlannedUnit={handleWeeklyClickEventOnPlannedUnit}
        plan={plans} // Pass the entire plans array for the date
        frequency={frequency}
        view={planningState.salesview.value}
        name = {employee.name}
      />
    </div>
  ));
}

export const getEmployeeNameById = (employeeData, id) => {
  const employee = employeeData.employees.find(emp => emp.id == id.toString());
  if (employee) {
    return employee.name;
  } else {
    return null; // Return null if employee with given ID is not found
  }
}

export const getList = (data, type, value, option = false) => {
  let list = [];
  try {
    if(option) {
      list = data.filter((item) => (item[type] == value && item['proposal_status'] == 1))
    } else {
      list = data.filter((item) => (item[type] == value))
    }
    return list;
  } catch (error) {
    return [];
  }
}