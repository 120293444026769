import React, { useState, useEffect } from "react";
import { PLANNING_FILTERS, PLANNING_VIEW_OPTIONS, SALES_VIEW_OPTIONS } from "../constants/planningConstants";
import Calender from "components/molecules/Calender";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { usePlanning, usePlanningsDispatch } from "../planning-redux/context/PlanningContext/PlanningContext";
import { PLANNING_ACTIONS } from "../planning-redux/actions/PlanningActions";
import { setCurrentViewUnit, getList } from "../utils/utils";
import { GET_PLANNING_FILTER_DATA } from "routes/ApiEndpoints";
import { PLANNING_FILTER_ACTIONS } from "../planning-redux/actions/PlanningFilterActions";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { useFilterOptions, useFilterOptionsDispatch } from "../planning-redux/context/FiltersContext/FiltersContext";
import { initialEmployeeProps } from "../planning-redux/context/EmployeeContext/intialEmployeeProps";
import { useEmployee, useEmployeeDispatch } from "../planning-redux/context/EmployeeContext/EmployeeContext";
import { dateUtils } from "../utils/dateUtils";
import { EMPLOYEE_ACTIONS } from "../planning-redux/actions/EmployeesActions";
import { usePlanningModal } from '../planning-redux/context/PlanningModalContext/PlanningModalContext';
import { useSearchParams } from 'react-router-dom';
import { SALES_VIEW_LIST, SALES_VIEW_EXCEPTION_LIST } from "../constants/planningConstants";

const PlanningFilters = ({ type }) => {
  const { planningState } = usePlanning();
  const { dispatchPlanning, setView } = usePlanningsDispatch();
  const { filterOptionsState } = useFilterOptions();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const { initialEmployeeState } = useEmployee();
  const { dispatchEmployee } = useEmployeeDispatch();
  const { planningModalState } = usePlanningModal();

  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    employee: "",
    project: ""
  })

  const [planningFilterState, setPlanningFilterState] = useState(PLANNING_FILTERS);
  const [filterData, setFilterData] = useState({
    companies: [],
    projects: [],
    businessUnits: [],
    employeeTypes: [],
    companyProjects: [],
    projectBusinessUnits: [],
    employees: [],
    salesview: null
  });

  const updatePlanningFiltersData = (value, type) => {
    setPlanningFilterState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [type]: value,
      },
    }));
  };
  useEffect(() => {
    fetchPlanningDetails()
  }, [filterOptionsState.filteredState.startdate && filterOptionsState.filteredState.enddate])

  useEffect(() => {
    const employee = searchParams.get('employee');
    const project = searchParams.get('project');
    if (searchParams.toString() !== '') {
      let list = [];
      let value, fieldname = '';
      if (employee != '' && employee != null) {
        fieldname = 'employeesid'
        value = employee
        list = { "employee": employee }
      }
      if (project != '' && project != null) {
        fieldname = 'project_id'
        value = project
        list = { "project": project }
      }
      updateFilterOptions(fieldname, [value])
      setParams({
        ...params,
        ...list
      })
      fetchPlanningDetails(list);
      // fetchHRPlanningDetails(list)
      //   searchParams.delete('employee');
      // setSearchParams(searchParams);
    } else {
      fetchPlanningDetails();
    }

  }, [searchParams, setSearchParams]);

  useEffect(() => {
    getFilterOptions();
  }, []);

  const fetchPlanningDetails = async (list = []) => {
    let params = filterOptionsState.filteredState;
    if (params.employeesid.length == 0 && list.employee != '' && list.employee != undefined) {
      params.employeesid = [list.employee]
    }
    if (type == 'sales') {
      params = {
        ...params,
        type: planningState.view.value,
        view: planningFilterState.state.salesview
      }
    }
    let details = await initialEmployeeProps(params, type)
    updatePlanningFiltersData(details.employees, "employeelist");
    updateEmployeeList(planningFilterState.state.salesview, details.employees, type)

    dispatchPlanning({
      type: PLANNING_ACTIONS.SET_PLANNING_EVENTS,
      payload: details.plans
    })

    if (filterData.employees.length == 0) {
      let filteredemplist = details.emplist.filter((item) =>
        !SALES_VIEW_LIST.includes(item.label)
      )
      setFilterData((prevState) => ({
        ...prevState,
        employees: filteredemplist
      }));
    }
  };

  const updateEmployeeList = (value, emplist, type = 'sales') => {
    let view = value.label
    if (view != 'All' && type == 'sales') {
      let viewlist = SALES_VIEW_LIST.filter((item) => item != view)
      emplist = planningFilterState.state.employeelist.filter((item) => {
        return !viewlist.includes(item.name);
      })
    }
    dispatchEmployee({
      type: EMPLOYEE_ACTIONS.SET_EMPLOYEE,
      payload: { employees: emplist }
    })
  }

  const getFilterOptions = async () => {
    try {
      const response = await ApiCall.service(
        GET_PLANNING_FILTER_DATA,
        "post",
        {},
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        updateBulkFilterOptions(response.data, PLANNING_FILTER_ACTIONS.BULK_UPDATE_PLANNING_OPTIONS_STATE)
        setFilterData((prevState) => ({
          ...prevState,
          ["companies"]: getList(response?.data.companies, 'status', 1),
          ["projects"]: getList(response?.data.projects, 'status', 2, true),
          ["businessUnits"]: response?.data.businessUnits,
          ["employeeTypes"]: response?.data.employeeTypes,
          ["companyProjects"]: response?.data.companyProjects,
          ["projectBusinessUnits"]: response?.data.projectBusinessUnits,
          ["shifts"]: response?.data.shifts,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChangeForProjectAndCompany = (value, type) => {
    setPlanningFilterState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [type]: value,
      },
    }));

    switch (type) {
      case "company":
        let selectedCompanyIds = [];
        let projectlist = filterOptionsState.state.projects;
        if (value.length > 0) {
          selectedCompanyIds = value.map((company) => company.value);
          const filteredProjects = filterData.companyProjects
            .filter((project) => selectedCompanyIds.includes(project.company_id))
            .map((project) => project.project_id);
          projectlist = filterOptionsState.state.projects.filter((project) =>
            filteredProjects.includes(project.value)
          )
        }
        updateFilterOptions('company_id', selectedCompanyIds)
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          projects: projectlist
        }));
        break;

      case "project":
        let selectedProjectIds = [];
        if (value.length > 0) {
          selectedProjectIds = value.map((project) => project.value);
        }
        updateFilterOptions('project_id', selectedProjectIds)
        break;
      case "bussiness_unit":
        let selectedbuIds = [];
        let companylist = getList(filterOptionsState.state.companies, 'status', 1);
        let projlist = getList(filterOptionsState.state.projects, 'status', 2);
        if (value.length > 0) {
          selectedbuIds = value.map((bu) => bu.value);
          const companyidslist = value.map((bu) => bu.companyid);
          companylist = filterOptionsState.state.companies.filter((company) =>
            companyidslist.includes(company.value.toString())
          )
          const filteredProjects = filterData.projectBusinessUnits
            .filter((project) => selectedbuIds.includes(project.bu_id))
            .map((project) => project.project_id);
          projlist = filterOptionsState.state.projects.filter((project) =>
            filteredProjects.includes(project.value)
          )
        }
        updateFilterOptions('businessUnits', selectedbuIds)
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          companies: companylist,
          projects: projlist
        }));
        break;
      case "startdate":
      case "enddate":
        let date = dateUtils.getFormattedDates(value)
        updateFilterOptions(type, date)
        break;
      case "employees":
        let selectedemployees = [];
        if (value.length > 0) {
          selectedemployees = value.map((employee) => employee.value);
        }
        updateFilterOptions('employeesid', selectedemployees)
        break;
      case "employeeTypes":
        let emptypes = value.map((item) => item.value);
        updateFilterOptions(type, emptypes)
      default:
        break;
    }
  };

  const updateFilterOptions = (fieldname, value) => {
    dispatchFilterOptions({
      type: PLANNING_FILTER_ACTIONS.UPDATE_PLANNING_SELECTED_FIELD,
      payload: {
        field: fieldname,
        value: value
      }
    })
  }

  const updateBulkFilterOptions = (list, type) => {
    dispatchFilterOptions({
      type: type,
      payload: list
    })
  }

  const handleSelectChange = (value, type, isMultiSelect) => {
    switch (type) {
      case "employees":
      case "company":
      case "project":
      case "bussiness_unit":
      case "employeeTypes":
        handleSelectChangeForProjectAndCompany(value, type);
        break;

      case "startdate":
      case "enddate":
        handleSelectChangeForProjectAndCompany(value, type);
        break;
      case "planning-view":
        setView(value);
        dispatchPlanning({
          type: PLANNING_ACTIONS.SET_PLANNING_VIEW,
          payload: value,
        });
        dispatchPlanning({
          type: PLANNING_ACTIONS.SET_CURRENT_VIEW_UNIT,
          payload: setCurrentViewUnit(value),
        });
        dispatchPlanning({
          type: PLANNING_ACTIONS.UPDATE_CURRENT_VIEW_YEAR,
          payload: new Date().getFullYear(),
        });
        break;
      case "salesview":
        let emplist = planningFilterState.state.employeelist
        updateEmployeeList(value, emplist)
        dispatchPlanning({
          type: PLANNING_ACTIONS.SET_SALES_VIEW,
          payload: value,
        });
        handleSelectChangeForProjectAndCompany(value, type);
        break;

    }
  };

  return (
    <>
      {/* <div>Filters</div> */}
      <div className="planning_filters">
        <div className=" p-0">
          <SelectWithSearch
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "bussiness_unit", false)
            }
            placeHolder={t("BU")}
            options={filterData.businessUnits}
            value={planningFilterState.state.bussiness_unit}
            isDisabled={params.project == '' ? false : true}
            isMulti={true}
            name={"bussiness_unit"}
            id={"bussiness_unit_planning_filter_id"}
          ></SelectWithSearch>
        </div>
        <div className=" p-0">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "company", false)}
            placeHolder={t("Company")}
            options={filterData.companies}
            value={planningFilterState.state.company}
            isDisabled={params.project == '' ? false : true}
            isMulti={true}
            name={"company"}
            id={"company_planning_filter_id"}
          ></SelectWithSearch>
        </div>
        <div className=" p-0">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "project", false)}
            placeHolder={t("Project")}
            options={filterData.projects}
            value={planningFilterState.state.project}
            isDisabled={params.project == '' ? false : true}
            isMulti={true}
            name={"project"}
            id={"project_planning_filter_id"}
          ></SelectWithSearch>
        </div>
        <div className=" p-0">
          <SelectWithSearch
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "employeeTypes", false)
            }
            placeHolder={t("Employee type")}
            options={filterData.employeeTypes}
            value={planningFilterState.state.employeeTypes}
            isDisabled={params.employee == '' ? false : true}
            isMulti={true}
            name={"employeeTypes"}
            id={"employee_type_planning_filter_id"}
          ></SelectWithSearch>
        </div>
        <div className=" p-0">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "employees", false)}
            placeHolder={t("Employees")}
            options={filterData.employees}
            value={planningFilterState.state.employees}
            isDisabled={params.employee == '' ? false : true}
            isMulti={true}
            name={"employees"}
            id={"employees_planning_filter_id"}
          ></SelectWithSearch>
        </div>


        <div className="">
          <Calender
            onChange={(date) => handleSelectChange(date, "startdate", false)}
            selectedDate={planningFilterState.state.startdate}
            placeHolder={"dd-mm-yyyy"}
            // label={t("End date")}
            isMandatory={false}
            name={"end_date"}
            classname={"h-45px"}
            // error={filterOptionsState.filteredState.end_date}
            isDisabled={false}
          ></Calender>
        </div>

        <div className="">
          <Calender
            onChange={(date) => handleSelectChange(date, "enddate", false)}
            selectedDate={planningFilterState.state.enddate}
            placeHolder={"dd-mm-yyyy"}
            // label={t("End date")}
            isMandatory={false}
            name={"end_date"}
            classname={"h-45px"}
            // error={filterOptionsState.filteredState.end_date}
            isDisabled={false}
          ></Calender>
        </div>

        <div>
          <button type="submit" class="btn form-button text-uppercase px-2 w-100" onClick={fetchPlanningDetails}> Search</button>
        </div>



      </div>
      <div className="d-flex gap-2 justify-content-end">
        {type == 'sales' &&
          <div className=" p-0 w-13">
            <SelectWithSearch
              title={t("")}
              name="planning_view"
              // placeHolder={t("Week")}
              isMandatory={true}
              search={true}
              options={SALES_VIEW_OPTIONS}
              value={planningState.salesview}
              onChange={(e) => handleSelectChange(e, "salesview")}
              isMulti={false}
              className="select-field"
            // error={formErrors.country}
            />
          </div>
        }
        <div className=" p-0 w-13">
          <SelectWithSearch
            title={t("")}
            name="planning_view"
            // placeHolder={t("Week")}
            isMandatory={true}
            search={true}
            options={PLANNING_VIEW_OPTIONS}
            value={planningState.view}
            onChange={(e) => handleSelectChange(e, "planning-view")}
            isMulti={false}
            className="select-field"
          // error={formErrors.country}
          />
        </div>
        {/* <div>
          <button type="submit" class="btn form-button text-uppercase px-2 w-100 h-45px" onClick={fetchPlanningDetails}> Search</button> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default PlanningFilters;
