import { ApiCall } from "services/ApiServices";
import { PayRollReducerTypes } from "../constants/WorkPayBillRollConstants";
import { GET_PAYROLL } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CommonServices from "services/CommonService";

const getManageData = async (props: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let postdata: any = {
                manage: true,
                date: CommonServices?.convertToISODate(props?.filterState?.selected_date),
                search: { 
                    business_unit: props?.filterState?.business_unit,
                    employee: props?.filterState?.employee,
                    contract_type: props?.filterState?.contract_type,
                 },
                current_page: props?.tableState?.current_page
            }
            if (props?.filterState?.popup?.export?.from && props?.filterState?.popup?.export?.to) {
                postdata = {
                    ...postdata,
                    start_month: CommonServices?.convertToISODate(props?.filterState?.popup?.export?.from),
                    end_month: CommonServices?.convertToISODate(props?.filterState?.popup?.export?.to)
                }
            }
            const response = await ApiCall?.service(GET_PAYROLL, 'POST', postdata, true, M_MASTER_DATA);
            let selecteddata = props?.tableState?.select_all ? response?.data?.map((item: any) => (!props?.tableState?.selected_data?.includes?.(item?.id) && item?.id)) : [];
            props?.tableDispatch({
                type: PayRollReducerTypes?.SET_TABLE_DATA,
                payload: {
                    ...props?.tableState,
                    totalPages: response?.totalPages,
                    totalCount: response?.totalCount,
                    data: response?.data,
                    selected_data: [...props?.tableState?.selected_data, ...selecteddata]
                }
            });
            resolve(true);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

const getOverviewData = async (props: any) => {
    return new Promise(async (resolve, reject) => {
        try {            
            let postdata: any = {
                date: CommonServices?.convertToISODate(props?.filterState?.selected_date),
                employees: props?.tableState?.selected_data?.length > 0 ? props?.tableState?.selected_data : [props?.tableState?.overview?.id],
            }
            const response = await ApiCall?.service(GET_PAYROLL, 'POST', postdata, true, M_MASTER_DATA);            
            props?.overViewDispatch({
                type: PayRollReducerTypes?.SET_OVERVIEW_DATA,
                payload: {
                    ...props?.overViewState,
                    headers: response?.headers,
                    data: response?.data,
                }
            });
            resolve(true);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

export {
    getManageData,
    getOverviewData
}
