import React from "react";
import { useReducer, useContext, createContext } from "react";
import { initialTImesheetModalProps } from "./initialTimesheetModalProps";
import { TimesheetModalReducer } from "../../reducers/TImesheetModalReducer/TimesheetModalReducer";

const TimesheetModalContext = createContext(null);
const TimesheetModalDispatchContext = createContext(null);

const TimeSheetModalProvider = ({ children }) => {
  const [initialTimesheetModalState, timesheetModalDispatch] = useReducer(
    TimesheetModalReducer,
    initialTImesheetModalProps
  );

  return (
    <TimesheetModalDispatchContext.Provider value={{ timesheetModalDispatch }}>
      <TimesheetModalContext.Provider value={{ initialTimesheetModalState }}>
        {children}
      </TimesheetModalContext.Provider>
    </TimesheetModalDispatchContext.Provider>
  );
};

export const useTimesheetModalContext = () => {
  return useContext(TimesheetModalContext);
};

export const useTimesheetModalDispatch = () => {
  return useContext(TimesheetModalDispatchContext);
};

export default TimeSheetModalProvider;
