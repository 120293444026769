import React from "react";


const CommonBox = ({
  width,
  data,
  bg_color,
  color,
  handleBoxClick,
  box_id,
}) => {
  return (
    <span
      className="d-flex align-items-center justify-content-center"
      style={{
        width: `${width}`,
        height: "70px",
        background: `${bg_color ? bg_color : "grey"}`,
        color: `${color ? color : "white"}`,
      }}
      onClick={(e) => handleBoxClick(e, box_id)}
    >
      {data}
    </span>
  );
};

export default CommonBox;
