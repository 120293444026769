import React, { useEffect } from "react";
import PaginationButton from "./PaginationButton";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface PaginationProps {
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (pageNumber: number) => void;
  maxVisiblePages?: number; // Define the maximum visible page numbers
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage = 1,
  totalPages = 1,
  onPageChange,
  maxVisiblePages = 3, // Default value for maximum visible pages
}) => {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  useEffect(() => {
    // Scroll to the top when the page changes
    window.scrollTo(0, 0);
  }, [currentPage]);

  const renderPageNumbers = () => {
    if (totalPages <= maxVisiblePages) {
      // Show all page numbers if total pages are less than or equal to maxVisiblePages
      return pageNumbers.map((pageNumber) => (
        <PaginationButton
          title={pageNumber}
          key={pageNumber}
          handleClick={() => onPageChange && onPageChange(pageNumber)}
          className={`btn border-0 page-num ${
            currentPage === pageNumber ? "page-active" : ""
          }`}
        />
      ));
    } else {
      const firstPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 3));
      const lastPage = Math.min(totalPages, firstPage + maxVisiblePages - 1);

      const pages = [];
      if (firstPage > 1) {
        pages.push(
          <PaginationButton
            title={1}
            key={1}
            handleClick={() => onPageChange && onPageChange(1)}
            className={`btn border-0 page-num`}
          />
        );
        if (firstPage > 2) {
          pages.push(
            <span key="ellipsis1" className="ellipsis">
              ...
            </span>
          );
        }
      }

      for (let i = firstPage; i <= lastPage; i++) {
        pages.push(
          <PaginationButton
            title={i}
            key={i}
            handleClick={() => onPageChange && onPageChange(i)}
            className={`btn border-0 page-num ${
              currentPage === i ? "page-active" : ""
            }`}
          />
        );
      }

      if (lastPage < totalPages) {
        if (lastPage < totalPages - 1) {
          pages.push(
            <span key="ellipsis2" className="ellipsis">
              ...
            </span>
          );
        }
        pages.push(
          <PaginationButton
            title={totalPages}
            key={totalPages}
            handleClick={() => onPageChange && onPageChange(totalPages)}
            className={`btn border-0 page-num`}
          />
        );
      }
      return pages;
    }
  };

  return (
    <>
      {totalPages > 1 && (
        <>
          <PaginationButton
            title={currentPage === 1 ? null : ""}
            handleClick={() => onPageChange && onPageChange(currentPage - 1)}
            className={`btn previous-btn ${
              currentPage === 1 ? "disabled" : ""
            }`}
            icon={faArrowLeft}
          />
          {renderPageNumbers()}
          <PaginationButton
            title={currentPage === totalPages ? null : ""}
            handleClick={() => onPageChange && onPageChange(currentPage + 1)}
            className={`btn next-btn ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            icon={faArrowRight}
          />
        </>
      )}
    </>
  );
};

export default Pagination;
