import React from "react";

const SendMailIcon = () => {
  return (
    <span title="Send proposal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
      >
        <path
          d="M21 4H3C2.45 4 2 4.45 2 5V19c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1zm0 2v2.19l-9 5.4-9-5.4V6h18zM4 8.62l8 4.8 8-4.8V18H4V8.62z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default SendMailIcon;
