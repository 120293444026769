import { PROJECT_ACTIONS } from "../../actions/ProjectActions";

export const ProjectReducer = (projects, action) => {
  switch (action.type) {
    case PROJECT_ACTIONS.SET_PROJECT:
      return { ...projects, project_id: action?.payload?.project_id };
   case PROJECT_ACTIONS.SET_PROJECTS:    
      return {
        ...projects,
        project_id: action.payload?.projects?.[0]?.value,
        projects: action.payload.projects,
        refresh: !projects.refresh
      }
    default:
      return projects;
  }
};
