import { PayRollReducerTypes } from "../../constants/WorkPayBillRollConstants";
import { FilterOptionsState, PayRollAction } from "../actions/Interfaces";

export const filtersReducer = (state: FilterOptionsState, action: PayRollAction): FilterOptionsState => {
    switch (action.type) {
        case PayRollReducerTypes?.SET_FILTER:                    
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
