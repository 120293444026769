import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import {
  MANAGE_HOLIDAY_CODES,
  MANAGE_HOLIDAY_PER_PC,
  MANAGE_PUBLIC_HOLIDAYS,
} from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccessControl from "services/AccessControl";

const HolidayConfig: React.FC = () => {
  const userData = useSelector(selectAuth);
  return (
    <>
      <WindowHeightComponent>
        <div className="d-flex flex-column h-100">
            <div className="px-1 flex-1 overflow-auto" >
          <div className=" dashboard-tabs  ">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Holiday codes",
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <Link to={MANAGE_HOLIDAY_CODES} className="dashboard-tab-links">
                <div className="tab">{t("Holiday codes")}</div>
              </Link>
            </AccessControl>
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Public holidays",
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <Link to={MANAGE_PUBLIC_HOLIDAYS} className="dashboard-tab-links">
                <div className="tab">{t("Public holidays")}</div>
              </Link>
            </AccessControl>
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Holiday per PC",
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <Link to={MANAGE_HOLIDAY_PER_PC} className="dashboard-tab-links">
                <div className="tab">{t("Holiday per PC")}</div>
              </Link>
            </AccessControl>
          </div>
          </div>

          <div className="my-2 px-1">
            <Link
              to="/"
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
      </WindowHeightComponent>
    </>
  );
};
export default HolidayConfig;
