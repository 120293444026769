import React, { useState, useEffect } from "react";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithSelectField from "components/molecules/LabelwithSelectField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import { ApiCall } from "services/ApiServices";
import { useNavigate, Link } from "react-router-dom";
import { MODULES, STORE_TEMPLATE } from "routes/ApiEndpoints";
import { t } from "../translation/Translation";
import { M_MASTER_DATA } from "../../../../constants/Constants";
import { PATH_EMAIL_TEMPLATE_MANAGE } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
interface TemplateProps {
  //Properties/ fields and there respective data type in email template form
  module?: string;
  name?: string;
  subject?: string;
  body?: string;
  signature?: string;
}

interface OptionProps {
  //fields related to module dropdown and their datatype
  value?: number;
  name?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

const CreateEmailTemplate: React.FC = () => {
  //component
  const [formData, setFormData] = useState<TemplateProps>({
    //formData with intial values
    module: "",
    name: "",
    subject: "",
    body: "",
    signature: "",
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState<TemplateProps>({}); //to maintain errors
  const [optionList, setOptionList] = useState<OptionProps[]>([]); //module dropdown values

  useEffect(() => {
    //To fetch values for module dropdown
    fetchModules();
  }, []);

  const fetchModules = async () => {
    const response = await ApiCall.getService(MODULES, "GET", M_MASTER_DATA);
    if (response.status === 200) {
      const data = response.data;
      // Convert the data array into the optionList format
      const updatedOptionList = data.map((item: any) => ({
        value: item.id,
        name: item.name,
      }));
      // Update the state with the new optionList
      setOptionList(updatedOptionList);
    }
  };

  const validation = (
    //validation function related field validations
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      module: [validateSelectField],
      name: [validateRequired],
      subject: [validateRequired],
      body: [validateRequired],
      signature: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    validation(name, content, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      const response = await ApiCall.service(
        STORE_TEMPLATE,
        "POST",
        formData,
        false,
        M_MASTER_DATA
      ); //api call to store formdata
      if (response.status === 200) {
        navigate(`${PATH_EMAIL_TEMPLATE_MANAGE}`);
      }
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Email template",
        create: true,
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">{t("Create email template")}</h1>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-height">
            <div className="form-border p-5">
              <div className="row">
                <div className="col-lg-6">
                  {/* Select module field */}
                  <div className="col-12">
                    <LabelWithSelectField
                      label={t("Select module")}
                      options={optionList}
                      placeholder={t("Select module")}
                      selectedValue={formData.module}
                      handleChange={changeHandler}
                      disabled={false}
                      isMultiSelect={false}
                      isMandatory={true}
                      name="module"
                      className=""
                      error={errors.module}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* Name field */}
                  <LabelWithInputField
                    isMandatory={true}
                    name="name"
                    handleChange={changeHandler}
                    value={formData.name}
                    id="name"
                    label={t("Name")}
                    placeholder={t("Name")}
                    type="text"
                    error={errors.name}
                  />
                </div>
              </div>
              <div className="row">
                {/* Subject field */}
                <LabelWithInputField
                  isMandatory={true}
                  name="subject"
                  handleChange={changeHandler}
                  value={formData.subject}
                  id="subject"
                  label={t("Subject")}
                  placeholder={t("Subject")}
                  type="text"
                  error={errors.subject}
                />
                <div className="col-md-12">
                  {/* Body field */}
                  <LabelWithCKEditorField
                    label={t("Body")}
                    name="body"
                    value={formData.body}
                    placeholder={t("Type here...")}
                    handleChange={(event, editor) =>
                      handleCKEditorChange(event, editor, "body")
                    }
                    isMandatory={true}
                    error={errors.body}
                  />
                </div>
                <div className="col-md-12">
                  {/* Signature field */}
                  <LabelWithCKEditorField
                    label={t("Signature")}
                    name="signature"
                    value={formData.signature}
                    placeholder={t("Type here...")}
                    handleChange={(event, editor) =>
                      handleCKEditorChange(event, editor, "signature")
                    }
                    isMandatory={true}
                    error={errors.signature}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="my-3 d-flex justify-content-between">
            <div>
              <Link
                to={PATH_EMAIL_TEMPLATE_MANAGE}
                className="text-uppercase back-btn text-decoration-underline"
              >
                Back
              </Link>
            </div>
            <div className="">
              <Button
                title={t("Create email template")}
                type="submit"
                className="btn form-button shadow-none text-uppercase px-3 button-width"
              />
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
};

export default CreateEmailTemplate;
