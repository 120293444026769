import React from "react";
import { FormProvider } from "../context/Context";
import { MultiTabForm } from "../Index";

type FormProviderWrapperProps = {
  backToManage: string;
  formName: string;
  dataSubmissionEndPoint: string;
  microservice: string;
  dataFetchEndPoint: string;
  entryId?: any
  customFn?: any;
};
export const FormProviderWrapper: React.FC<FormProviderWrapperProps> = ({
  backToManage,
  formName,
  dataSubmissionEndPoint,
  dataFetchEndPoint,
  microservice,
  entryId,
  customFn
}) => {
  return (
    <>
      <FormProvider>
        <MultiTabForm
          backToManage={backToManage}
          formName={formName}
          dataSubmissionEndPoint={dataSubmissionEndPoint}
          microservice={microservice}
          dataFetchEndPoint={dataFetchEndPoint}
          entryId={entryId}
          customFn={customFn}
        />
      </FormProvider>
    </>
  );
};
