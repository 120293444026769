import React from "react";
import { FormProvider } from "./Context";
import { AgreementForm } from "../create-agreement/formContent/AgreementForm";

export const MultiFormProviderAgreement = () => {
  return (
    <>
      <FormProvider>
        <AgreementForm />
      </FormProvider>
    </>
  );
};