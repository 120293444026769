import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { GET_EMPLOYEE_TYPE } from "routes/ApiEndpoints";
import ManageEmployeeTypePage from ".";
import { M_MASTER_DATA } from "constants/Constants";

export interface EmployeeType {
  category_id: number;
  id?: number;
  employee_type_name: string;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: EmployeeType | null;
}

const ManageEmployeeType: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const statusValue = queryParams.get("status");
  
  const status: number | undefined =
    statusValue !== null ? parseInt(statusValue) : undefined;
  const [employeeType, setEmployeeType] = useState<EmployeeType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
    };

    fetchEmployeeType(data);
  }, [currentPage, submit]);

  const filteredData = search ? employeeType.filter((item: any) =>
    item.name?.toLowerCase().includes(search.toLowerCase()) ||
    item.unique_key?.toLowerCase().includes(search.toLowerCase())
  ) : employeeType;

  const fetchEmployeeType = async (data: DataProps) => {
    const response = await ApiCall.service(GET_EMPLOYEE_TYPE, "POST", data, false, M_MASTER_DATA);
    
    if (response?.status === 200) {
      setEmployeeType(response.data);
      setTotalPages(response.totalPages);
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: any) => {    
    setSearch(value);
  };

  const handleDeleteData = (deleteId: number) => {
    setEmployeeType((prevdata) =>
      prevdata.filter((businessUnit) => businessUnit.id !== deleteId)
    );
    setCurrentPage(1);
  };
  
  const handleSubmit = (clear: boolean) => {
    setSubmit(!submit);
    setCurrentPage(1);
  };
  return (
    <ManageEmployeeTypePage
      employeeTypeObj={filteredData}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      itemsPerPage={itemsPerPage}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      handleSubmitAction={handleSubmit}
      searchData={search}
    />
  );
};

export default ManageEmployeeType;
