import React from "react";

const AddFleetIcon: React.FC = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3431 20.3431C23.4678 17.2185 28.5322 17.2185 31.6569 20.3431C34.7815 23.4678 34.7815 28.5322 31.6569 31.6569C28.5322 34.7815 23.4678 34.7815 20.3431 31.6569C17.2185 28.5322 17.2185 23.4678 20.3431 20.3431ZM21.5002 26.001C21.5002 25.4476 21.9475 25.0004 22.5008 25.0004H25.0004V22.5008C25.0004 21.9475 25.4476 21.5002 26.001 21.5002C26.5525 21.5002 27.0007 21.9484 27.0007 22.4999L26.9998 25.0004L29.4994 25.0004C30.0527 25.0004 30.4999 25.4476 30.4999 26.001C30.4999 26.5525 30.0518 27.0007 29.5003 27.0007H27.0007V29.5003C27.0007 30.0518 26.5525 30.4999 26.001 30.4999C25.4476 30.4999 25.0004 30.0527 25.0004 29.4994L25.0004 26.9998L22.4999 27.0007C21.9484 27.0007 21.5002 26.5525 21.5002 26.001Z" fill="black" />
            <path d="M19.2853 18.5896C19.042 18.8102 18.8096 19.0426 18.589 19.286H8.57124V18.2146C8.57124 17.6226 9.05068 17.1431 9.64264 17.1431H18.2139C18.8058 17.1431 19.2853 17.6226 19.2853 18.2146V18.5896Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.262 17.1915C20.8349 15.9176 19.632 15.0003 18.2139 15.0003H9.64264C7.86678 15.0003 6.42843 16.4387 6.42843 18.2146V30H3.21421C1.43836 30 0 28.5616 0 26.7858V11.7861C0 10.7737 0.475429 9.82276 1.28569 9.21475L13.2854 0.214951C13.6671 -0.0716504 14.1894 -0.0716504 14.5711 0.214951L26.5708 9.21475C27.3811 9.82276 27.8565 10.7737 27.8565 11.7861V16.172C27.2549 16.0591 26.6344 16 26 16C24.2857 16 22.6722 16.4314 21.262 17.1915ZM19.2853 12.8575H8.57124C7.97927 12.8575 7.49983 12.3781 7.49983 11.7861C7.49983 11.1942 7.97927 10.7147 8.57124 10.7147H19.2853C19.8772 10.7147 20.3567 11.1942 20.3567 11.7861C20.3567 12.3781 19.8772 12.8575 19.2853 12.8575Z" fill="black" />
            <path d="M16.004 25.7144C16.0013 25.8093 16 25.9045 16 26C16 26.6346 16.0591 27.2554 16.1721 27.8572H8.57124V25.7144H16.004Z" fill="black" />
            <path d="M17.1036 21.4288C16.7569 22.1022 16.4844 22.82 16.2969 23.5716H8.57124V21.4288H17.1036Z" fill="black" />
        </svg>
    )

}
export default AddFleetIcon