import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import classes from "static/css/Header.module.css";
import Logout from "static/images/Logout.svg";
import ImageField from "../atoms/ImageField";
import ButtonWithChildren from "../atoms/ButtonWithChildren";
import { selectAuth, removeUserDetails, addUserDetails } from "features/auth/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_PROFILE,
} from "constants/Paths";
import MultiSelectField from "components/atoms/MultiSelectField";
import { ApiCall } from "services/ApiServices";
import { FETCH_TRANSLATIONS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import Button from "components/atoms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getInitialDate = () => {
    const savedDate = localStorage.getItem('savedDate');
    return savedDate ? new Date(savedDate) : new Date();
  };
  const [date, setDate] = useState(getInitialDate);
  const location = useLocation();  
  const isDashboard = (location.pathname.includes('/dashboard') || location.pathname ==='/');

  useEffect(() => {
    localStorage.setItem('savedDate', date.toISOString());
  }, [date]);

  const logOutClick = () => {
    localStorage.clear();
    dispatch(removeUserDetails());
    navigate("/login");
  };
  const handleLanguageChange = async (e: any) => {
    let postdata = {
      language_id: Number(e?.target?.value),
    }
    try {
      dispatch(addUserDetails({ user_language: Number(e?.target?.value) }));
      const response = await ApiCall.service(FETCH_TRANSLATIONS, 'POST', postdata, false, M_MASTER_DATA);
      if (response?.status === 200) {
        localStorage.setItem('translations', JSON.stringify(response?.data?.data));
        window?.location?.reload();
      }
    } catch (error) {
      console.log(error);
    } 
  };
  const getStartOfWeek = (date: Date): Date => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  const incrementWeek = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
  };

  const decrementWeek = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
  };

  const getWeekNumber = (date: Date): number => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
    return Math.ceil((date.getDay() + 1 + days) / 7);
  };

  const setToCurrentDate = (selected: string) => {
    const currentDate = new Date();
    setDate(new Date(currentDate));
    if (selected === 'month') {
      const user = {
        dateInfo: {
          type: 'month',
          value: currentDate.getMonth() + 1,
          label: currentDate.toLocaleString('default', { month: 'long' }) + " " + currentDate.getFullYear(),
          year: currentDate.getFullYear()
        }
      };
      dispatch(addUserDetails(user));

    } else if (selected === 'year') {
      const user = {
        dateInfo: {
          type: 'year',
          value: currentDate.getFullYear(),
          label: currentDate.getFullYear(),
          year: currentDate.getFullYear()
        }
      };
      dispatch(addUserDetails(user));
    } else if (selected === 'week') {
      const startOfWeek = getStartOfWeek(currentDate);
      const weekNumber = getWeekNumber(startOfWeek);
      const user = {
        dateInfo: {
          type: 'week',
          value: weekNumber,
          label: `Week ${weekNumber} of ${startOfWeek.getFullYear()}`,
          year: startOfWeek.getFullYear()
        }
      };
      dispatch(addUserDetails(user));
    }
  };

  const increment = () => {
    let newDate;
    if (userAuth.dateInfo.type === 'year') {
      newDate = new Date(date.getFullYear() + 1, date.getMonth(), 1);
    } else if (userAuth.dateInfo.type === 'month') {
      newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    } else if (userAuth.dateInfo.type === 'week') {
      newDate = incrementWeek(date);
    } else {
      // This case should not happen if userAuth.dateInfo.type is correctly managed.
      newDate = date;
    }
    setDate(newDate);

    const user = {
      dateInfo: {
        type: userAuth.dateInfo.type,
        value: userAuth.dateInfo.type === 'year' ? newDate.getFullYear() :
          userAuth.dateInfo.type === 'month' ? newDate.getMonth() + 1 :
            getWeekNumber(newDate), // for week
        label: userAuth.dateInfo.type === 'year' ? newDate.getFullYear().toString() :
          userAuth.dateInfo.type === 'month' ? newDate.toLocaleString('default', { month: 'long' }) + " " + newDate.getFullYear() :
            `Week ${getWeekNumber(newDate)} of ${newDate.getFullYear()}`, // for week
        year: newDate.getFullYear()
      }
    };
    dispatch(addUserDetails(user));
  };

  const decrement = () => {
    let newDate;
    if (userAuth.dateInfo.type === 'year') {
      newDate = new Date(date.getFullYear() - 1, date.getMonth(), 1);
    } else if (userAuth.dateInfo.type === 'month') {
      newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    } else if (userAuth.dateInfo.type === 'week') {
      newDate = decrementWeek(date);
    } else {
      // This case should not happen if userAuth.dateInfo.type is correctly managed.
      newDate = date;
    }
    setDate(newDate);

    const user = {
      dateInfo: {
        type: userAuth.dateInfo.type,
        value: userAuth.dateInfo.type === 'year' ? newDate.getFullYear() :
          userAuth.dateInfo.type === 'month' ? newDate.getMonth() + 1 :
            getWeekNumber(newDate), // for week
        label: userAuth.dateInfo.type === 'year' ? newDate.getFullYear().toString() :
          userAuth.dateInfo.type === 'month' ? newDate.toLocaleString('default', { month: 'long' }) + " " + newDate.getFullYear() :
            `Week ${getWeekNumber(newDate)} of ${newDate.getFullYear()}`, // for week
        year: newDate.getFullYear()
      }
    };
    dispatch(addUserDetails(user));
  };

  return (
    <div className="row py-lg-2 pt-2 border--bottom">
      <div className="col-md-12">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <div className="border-sm-top d-flex justify-content-between pb-3 pb-lg-0">
              <Link className="navbar-brand mx-0" to="/dashboard">
                <ImageField
                  altText="Logo"
                  className="text-center w-50"
                  src="/static/images/logo.svg"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse justify-content-end mt-3"
              id="navbarNav"
            >
              <ul
                className={`navbar-nav align-items-lg-center ${classes.list}`}
              >
                {isDashboard &&
                  <li className="rounded" >
                    <Dropdown className="this-week-section d-flex align-items-center gap-2">


                      <button className='monthPrevIcon cursor-pointer sidebar-icon rounded-3 ' onClick={decrement}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <Dropdown.Toggle id="dropdown-basic" className="this-week-link d-flex align-items-center">
                        {userAuth?.dateInfo?.label ?? new Date()?.getFullYear().toString()}
                      </Dropdown.Toggle>
                      <button className='monthNextIcon cursor-pointer sidebar-icon rounded-3' onClick={increment}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>


                      <Dropdown.Menu className="profile-dropdown-links">
                        <Dropdown.Item onClick={() => { setToCurrentDate("week") }}>
                          {t("Current week")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setToCurrentDate("month") }}>
                          {t("Current month")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setToCurrentDate("year") }}>
                          {t("Current year")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                }
                <li className="rounded language_dropdown" style={{width:"150px"}}>
                  {userAuth && userAuth ? (
                    <>
                      <MultiSelectField
                        name="language"
                        selectedValue={userAuth?.languageOptions?.find((item: any) => item?.value === userAuth?.user_language)
                          ? userAuth.user_language
                          : userAuth?.languageOptions?.find((item: any) => item.key === 'en')?.value
                        }
                        emptyOptionNotRequired
                        options={userAuth?.languageOptions}
                        handleChange={handleLanguageChange}
                        placeholder=""
                        className="mt-0 px-3 h-100"
                        errorClass="d-none"
                      />
                    </> ) : null
                  }
                </li>
                <li className="">
                  {userAuth && userAuth ? (
                    <Link
                      to={PATH_PROFILE}
                      className="btn d-flex align-items-center px-3 form-button profile_btn"
                    >
                      {t("Profile")}
                    </Link>
                  ) : null}
                </li>
                <li className="pe-0 me-0 logout-btn">
                  {userAuth && userAuth ? (
                    <ButtonWithChildren
                      className="btn btn-link px-0 text-decoration-none"
                      onClick={logOutClick}
                    >
                      <div className="d-block d-md-block d-lg-none mt-3">
                        <img src={Logout} alt="Logout icon" title="Logout" />
                        <span className="color-dark-pink text-decoration-none ms-3 text-uppercase">
                          {t("Logout")}
                        </span>
                      </div>
                      <div className="d-none d-md-none d-lg-block">
                        <img src={Logout} alt="Logout icon" title="Logout" />
                        {/* <span className="color-dark-pink text-decoration-none">Logout</span> */}
                      </div>
                    </ButtonWithChildren>
                  ) : (
                    <Link
                      to="/login"
                      className="btn mt-5 rounded-0 px-3form-button"
                    >
                      {t("Login")}
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
