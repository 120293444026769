import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { addTimes, calculateWorkedTime } from "../utils/utils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { useProjectParameterContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";

const WorkTimings = (props) => {
  const { planTimingsData, header1, header2, total, type, isDisable, name } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()
  const { initialProjectParameterState } = useProjectParameterContext();

  const handleChange = (event, plan) => {
    const { value, name } = event.target;
    if (plan?.plan_id) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: plan?.plan_id,
          value: name == "pause_time" ? value : plan?.plan_date + " " + value,
          name: name,
          extraprops: {
            filterOptionsState,
            initialProjectParameterState,
          }
        },
      });
    }
  };

  var totalTime = "00:00";

  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className="border">
        {header1}
      </td>,
      <td key={"heading2"} className="border">{header2}</td>,
    ];
    const dataToRender = planTimingsData && planTimingsData.length > 0 ? planTimingsData : Array(7).fill({});
    dataToRender.forEach((plan, i) => {
      const value = plan[name] ? plan[name] : "";
      const totalHours = (plan["start_time"] && plan["end_time"] && plan["start_time"] != "" && plan["end_time"] != "")
        ? calculateWorkedTime(
            plan["start_time"].split(" ")[1],
            plan["end_time"].split(" ")[1],
            plan["pause_time"] ? plan["pause_time"] : "00:00:00"
          )
        : "";
      totalTime = addTimes(totalTime, totalHours === "" ? "00:00" : totalHours);  
      weekDays.push(
        <td key={i} className="border">
          <LabelWithInputField
            labelClassName="d-none"
            errorclass="d-none"
            label={""}
            name={name}
            isMandatory={false}
            value={name == "totalHours" ? totalHours : value}
            handleChange={(event) => handleChange(event, plan)}
            id={name}
            type={type ? type : "time"}
            error={""}
            isDisabled={isDisable}
          />
        </td>
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItems()}
      <td className="border">
        {total == "Weekly total" ? total : (name == "totalHours" ? (totalTime && totalTime !== 'NaN:NaN' ? totalTime : "") : "")}
      </td>
    </tr>
  );
};
export default WorkTimings;
