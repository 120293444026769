import React, { useState, useEffect } from "react";
import { LocationData } from "./ManageLocation";
import { Link, useNavigate, useParams } from "react-router-dom";
import Search from "components/atoms/Search";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { ARCHIVE_LOCATION } from "routes/ApiEndpoints";
import Pagination from "components/atoms/Pagination";
import "./location.css";
import {
  LOCATION,
  MANAGE_COMPANY,
  M_COMPANY_CREATION,
} from "../../../../constants/Constants";
import TableStructure from "components/atoms/TableStructure";
import {
  ExcludeLocationCompanyData,
  LocationColumn,
  LocationTableHeader,
} from "TableHeader";
import { t } from "pages/microservices/masterData/translation/Translation";
import Popup from "components/molecules/Popup";
import FormatData from "components/molecules/FormatData";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface ManageLocationPageProps {
  locationObject: LocationData[];
  currentPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  onPageChange: (pageNumber: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  totalPages: number;
}

const ManageLocationPage: React.FC<ManageLocationPageProps> = ({
  locationObject,
  searchValue,
  deleteFilter,
  searchData,
  handleSubmitAction,
  currentPage,
  totalPages,
  onPageChange
}) => {
  //Modal popup for company details
  const [popup, setPopup] = useState({
    isModelOpen: false,
  });
  //set company details in modal
  const [modalData, setModalData] = useState<any>(null);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = locationObject?.slice(indexOfFirstItem, indexOfLastItem);
  //Show comapny details on click
  currentItems = currentItems.map((item: any) => {
    item.company = (
      <span onClick={(e: any) => viewCompanyDetails(e, item)}>
        {item.company}
      </span>
    );
    return item;
  });

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const deleteLocation = (locationData: LocationData | any) => {
    setShowModal(true);
    setDeleteId(locationData.id);
  };

  const handleEdit = (locationData: LocationData | any) => {
    if (locationData.id) {
      navigate(`${LOCATION}/${locationData.id}`);
    }
  };
  const viewCompanyDetails = (
    event: React.MouseEvent<HTMLAnchorElement>,
    value: string
  ) => {
    event.preventDefault();
    setModalData(value);
    setPopup((prev) => ({ ...prev, isModelOpen: true }));
  };

  const closeModal = () => {
    setPopup((prev) => ({ ...prev, isModelOpen: false }));
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const response = await ApiCall.getService(
        `${ARCHIVE_LOCATION}/${deleteID}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">{t("Manage location")}</h1>
          </div>
        </div>
        <div className="col">
          <AccessControl
            requiredPermissions={[{
              permission: "Location",
              create: true
            }]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={LOCATION}
              className="form-button float-end d-flex align-items-center px-3 text-decoration-none text-uppercase"
            >
              + {t("Create location")}
            </Link>
          </AccessControl>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-xxl-9 col-md-12 mb-3 mb-lg-0">
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-3">
                  <Search
                    className="form-control"
                    handleChange={handleSearchChange}
                    placeholder={t("Location")}
                    name="location"
                    value={searchData.location}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <Search
                    className="form-control"
                    handleChange={handleSearchChange}
                    placeholder={t("Company")}
                    name="company"
                    value={searchData.company}
                  />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-12 ms-auto">
              <div className="row justify-content-end">
                <div className="col-lg-12 col-md-6 mb-lg-0 d-flex justify-content-end align-items-end">
                  <Button
                    title={t("Reset")}
                    type="submit"
                    handleClick={(e) => handleSubmit(e, true)}
                    className="btn delete-btn  shadow-none text-uppercase me-2 px-4 search-btns"
                  ></Button>
                  <Button
                    title={t("Search")}
                    type="submit"
                    handleClick={handleSubmit}
                    className="btn form-button shadow-none text-uppercase px-3 search-btns position-relative text-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive manage-companies"></div>
        <div className="table-responsive">
          <TableStructure
            isAction
            headers={LocationTableHeader}
            data={currentItems}
            values={LocationColumn}
            handleEdit={handleEdit}
            handleArchive={deleteLocation}
            searchStatus={searchData}
            permissionType={"Location"}
          />
        </div>
      </div>
      <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
      <div className="col-md-4 align-self-center">
        <Link
          to={MANAGE_COMPANY}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Archive confirmation"
        body="Are you sure want to archive?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
      {popup.isModelOpen && (
        <Popup
          title={t("Company Details")}
          body={
            <FormatData
              data={modalData}
              excludeData={ExcludeLocationCompanyData}
            />
          }
          cancel={closeModal}
          modalSize="lg"
          notext="Close"
          cancelButtonClass="shadow-none float-end d-flex justify-content-center"
        />
      )}
    </>
  );
};
export default ManageLocationPage;
