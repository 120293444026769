import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import LoadingIcon from "utils/LoadingIcon";
import {
  validateEmail,
  validateRequired,
  validateForm,
} from "services/validation/ValidationService";
import { Link, useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "routes/ApiEndpoints";
import ImageField from "components/atoms/ImageField";
import CustomNotify, { NotifyType } from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";

interface ValidationRules {
  [key: string]: Function[];
}
interface DataProps {
  email?: string;
}
export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState<DataProps>({
    email: "",
  });

  const [errors, setErrors] = useState<DataProps>({});
  const [loading, setLoading] = useState(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setdata({ ...data, [e.target.name]: e.target.value });
    validation(e.target.name, e.target.value, true);
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateEmail],
    };

    const validationErrors = validateForm(
      { ...data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;

    if (validation(name, value)) {
      setLoading(true);
      const response = await ApiCall.service(FORGOT_PASSWORD, "POST", data);
      if (response.status === 200) {
        let notificationType: NotifyType = "success";
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 2000); // Navigate after 5 seconds
        CustomNotify({ type: notificationType, message: response.msg });
      } else if (response.status === 400) {
        setLoading(false);
        setErrors({
          email: response.msg,
        });
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-7 col-xl-6 col-lg-6 vh-100 ps-0 d-none d-md-none d-lg-block">
            <ImageField
              altText="Banner"
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/loginImage.jpg"
            />
          </div>
          <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-8 m-auto register-form__fields p-xxl-3 p-xl-0 p-lg-0">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-xxl-8 col-xl-8 col-lg-9 my-4 m-auto px-xxl-1 px-lg-0">
                <form onSubmit={submitHandler}>
                  <div>
                    <div className="col-md-12 mb-5 text-center">
                      <ImageField
                        altText="Logo"
                        className="text-center logo-width"
                        src="static/images/logo.svg"
                      />
                    </div>
                    <h2 className="login-title text-center mb-4">
                      {t("Forgot password?")}
                    </h2>
                    <p>
                      {t(
                        "Change your password in three easy steps. This will help you to secure your password!"
                      )}
                    </p>
                    <ul>
                      <li>{t("Enter your email address below")}</li>
                      <li>
                        {t(
                          "Our system will send you an email with reset password link"
                        )}
                      </li>
                      <li>{t("Use the link to reset your password")}</li>
                    </ul>
                  </div>
                  <LabelWithInputField
                    isMandatory={true}
                    name="email"
                    handleChange={changeHandler}
                    value={data.email}
                    id="email"
                    label={t("Email")}
                    placeholder={t("Email")}
                    type="email"
                    error={errors.email}
                    className="form-control shadow-none rounded-0"
                  />
                  <div
                    className={`d-flex justify-content-between align-items-center mt-4`}
                  >
                    {!loading && (
                      <Button
                        title={
                          <Link to="/login" className="back-btn">
                            {t("Back to login")}
                          </Link>
                        }
                        className="btn ps-0 border-0"
                      />
                    )}
                    {!loading ? (
                      <Button
                        title={t("Reset Password")}
                        type="submit"
                        className="btn form-button text-uppercase rounded-0 shadow-none"
                      />
                    ) : (
                      <LoadingIcon iconType="bars" color="#00a5ce" />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
