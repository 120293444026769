import React, { useState } from "react";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
const PlanTimings = () => {
  const { initialTimesheetState } = useTImesheetContext();
  let timesheet_data = initialTimesheetState?.timesheet_data ? initialTimesheetState.timesheet_data: [];
  
  const renderWeekDays = () => {
    const weekDays = [
      <td key={"heading1"} className="border">
        Plannig
      </td>,
      <td key={"heading2"} className="border">Hours</td>,
    ];

    timesheet_data?.map((plan, i) => {
      const startdatestring = plan?.start_date_time?.split(' ');
      const enddatestring = plan?.end_date_time?.split(' ');     
      weekDays.push(
        plan?.plan_id ? (
          <td key={i} className="border">
            {(startdatestring?.[1]?.split?.("+")?.[0] ?? "00:00") + "-" + (enddatestring?.[1]?.split?.("+")?.[0] ?? "00:00")}
            <br />
            {plan?.break_hours ? plan?.break_hours : "00:00"}
          </td>
        ) : (
          <td key={i} className="border"></td>
        )
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderWeekDays()}
      <td key={'last'} className="border"></td>
    </tr>
  );
};
export default PlanTimings;
