import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ManagePublicHolidays } from "TableHeader";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import Search from "components/atoms/Search";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import TitleFieldMolecule from "components/molecules/TitleField";
import TableActions from "components/organism/Actions/TableAction";
import { M_MASTER_DATA } from "constants/Constants";
import {
  CREATE_PUBLIC_HOLIDAY,
  EDIT_PUBLIC_HOLIDAY,
  HOLIDAY_CONFIGS,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Link, useNavigate } from "react-router-dom";
import {
  CLONE_PUBLIC_HOLIDAY,
  DELETE_PUBLIC_HOLIDAY,
  GET_PUBLIC_HOLIDAYS,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";

interface HolidaysData {
  year: any;
  country: any;
  region: any;
  id?: any;
}
interface ManagePublicHolidayData {
  id: any;
  holiday_name: any;
  date: any;
  country: any;
  region: any;
}

interface DataProps {
  page: number;
  search?: any;
}
interface Option {
  label: string;
  value: string | number;
}

const ManageHolidays = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    year: queryParams.get("year") ?? "",
    country: queryParams.get("country") ?? "",
    region: queryParams.get("region") ?? "",
  });
  const [holidaysData, setHolidaysData] = useState<ManagePublicHolidayData[]>(
    []
  );
  const [fromYear, setFromYear] = useState<number | null>(null);
  const [toYear, setToYear] = useState<number | null>(null); //
  const [fromYearError, setFromYearError] = useState<string>("");
  const [toYearError, setToYearError] = useState<string>("");
  const [option, setOptions] = useState<{
    country: Option[];
  }>({
    country: [],
  });

  const [id, setId] = useState<number | undefined>(undefined);
  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };
    fetchPublicHolidays(data);
    setSearch((prevState) => ({ ...prevState, ["year"]: "2024" }));
  }, [currentPage, submit]);

  const fetchPublicHolidays = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_PUBLIC_HOLIDAYS,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setHolidaysData(response?.data?.holidayData);
        setOptions({
          country: response?.data?.countryOption || [],
        });
        const totalPages = response?.totalPages;
        setTotalPages(totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };
  //onChange event handler for select element
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    if (clear) {
      setSearch((prevState) => ({
        ...prevState,
        year: "",
      }));
    }
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({ year: "", country: "", region: "" });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: HolidaysData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //onClick edit handler
  const handleEdit = (id: any) => {
    navigate(`${EDIT_PUBLIC_HOLIDAY}/${id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (id: any) => {
    setId(id);
    setShowModal(true);
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_PUBLIC_HOLIDAY,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        setHolidaysData((prevdata) =>
          (prevdata as ManagePublicHolidayData[]).filter(
            (holidayData) => holidayData.id !== id
          )
        );

        setCurrentPage(1);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };
  const handleSearchChange = (event: any) => {
    const { name, value } = event.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleDateChange = (date: any, name: any) => {
    const value = date.year;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleFromYearChange = (e: DateObject | null) => {
    if (e !== null) {
      const year = e.year;
      setFromYear(year);
      setToYear(year + 1);
    } else {
      setFromYear(null);
      setToYear(null);
    }
  };

  const handleToYearChange = (e: DateObject | null) => {
    if (e !== null) {
      const year = e.year;
      setToYear(year);
    } else {
      setToYear(null);
    }
  };

  const handleReset = () => {
    setFromYear(null);
    setToYear(null);
    setFromYearError("");
    setToYearError("");
  };
  const handleClone = async () => {
    let isValid = true;
    if (fromYear === null) {
      setFromYearError("This field is required.");
      isValid = false;
    }
    if (toYear === null) {
      setToYearError("This field is required.");
      isValid = false;
    } else if (fromYear !== null && fromYear === toYear) {
      setToYearError("From and To years cannot be the same.");
      isValid = false;
    }
    if (isValid) {
      setFromYearError("");
      setToYearError("");

      // Create the request data object
      const requestData = {
        from: fromYear,
        to: toYear,
      };
      const response = await ApiCall.service(
        CLONE_PUBLIC_HOLIDAY,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        const data: DataProps = {
          page: currentPage,
          search: search,
        };
        fetchPublicHolidays(data);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };

  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <TitleFieldMolecule title={t("Manage public holidays")} />
          <div className="row pb-4 search-bar">
            <div
              className={`"col-12 d-flex mb-4 gap-3 ${
                toYearError ? "align-items-center" : "align-items-end"
              }`}
            >
              <div className="">
                <div className="form-label field-label">From</div>
                <DatePicker
                  inputClass="form-control field-shadow"
                  name="date"
                  placeholder={fromYear !== null ? fromYear.toString() : ""}
                  value={fromYear !== null ? new Date(fromYear, 0, 1) : null}
                  onChange={(e:any) => handleFromYearChange(e)}
                  format="YYYY"
                  onlyYearPicker={true}
                  minDate={new Date(2014, 0, 1)}
                  zIndex={1000}
                />
                {fromYearError && (
                  <div className="text-danger">{fromYearError}</div>
                )}
              </div>
              <div className="">
                <div className="form-label field-label">To</div>
                <DatePicker
                  inputClass="form-control field-shadow"
                  name="date"
                  placeholder={toYear !== null ? toYear.toString() : ""}
                  value={toYear !== null ? new Date(toYear, 0, 1) : null}
                  onChange={(e:any) => handleToYearChange(e)}
                  format="YYYY"
                  onlyYearPicker={true}
                  minDate={
                    fromYear !== null
                      ? new Date(fromYear + 1, 0, 1)
                      : new Date()
                  }
                  zIndex={1000}
                />
                {toYearError && (
                  <div className="text-danger">{toYearError}</div>
                )}
              </div>
              <div className="col mt-auto">
                <Button
                  title={IconData.ResetIcon}
                  type="submit"
                  handleClick={handleReset}
                  className="btn delete-btn  shadow-none text-uppercase me-3 "
                />
                <Button
                  title={t("Clone holiday")}
                  handleClick={handleClone}
                  className={"btn form-button px-3  text-uppercase shadow-none"}
                  type={"button"}
                  icon={faCopy}
                />
              </div>
            </div>
            <div className="col-2">
              <div className="">
                <label className="form-label field-label">Date</label>
              </div>
              <DatePicker
                //  value={search.year ? new Date(search.year, 0, 1) : null}
                inputClass="form-control field-shadow"
                name="date"
                placeholder={new Date().getFullYear().toString()}
                onChange={(e) => handleDateChange(e, "year")}
                format="YYYY"
                onlyYearPicker={true}
                minDate={new Date(2014, 0, 1)}
                zIndex={1000}
                // filterDate={(date: Date) => date.getFullYear() >= minimumYear} // Filter out dates before the minimum year
              />
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">Country</label>
              </div>
              <select
                className="form-select shadow-none rounded"
                name="country"
                id="country"
                onChange={handleSelectChange}
                value={search.country}
              >
                {option.country && option.country.length > 0 ? (
                  <>
                    <option value="">{t("Select country")}</option>
                    {option.country.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No country available")}</option>
                )}
              </select>
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">Region</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder={t("Select region")}
                name="region"
                value={search.region}
              />
            </div>
            <div className="col mt-auto text-end">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
          </div>
            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                <Link
                  to={CREATE_PUBLIC_HOLIDAY}
                  className="table-button"
                >
                  + {t("Add holiday")}
                </Link>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Holiday name")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Country")}</th>
                    <th>{t("Region")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {(holidaysData && holidaysData.length) > 0 ? (
                    holidaysData.map((eachItem, index) => (
                      <tr key={eachItem.id}>
                        <td className="align-middle">
                          {eachItem.holiday_name}
                        </td>
                        <td className="align-middle">{eachItem.date}</td>
                        <td className="align-middle">
                          {eachItem.country && eachItem.country.length > 0
                            ? eachItem.country
                                .map((eachCountry: any) => eachCountry.name)
                                .join(", ")
                            : null}
                          {eachItem.country.name}
                        </td>
                        <td className="align-middle">{eachItem.region}</td>
                        <td className="align-middle">
                          <TableActions
                            isAction={true}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            value={eachItem.id}
                            permission={"Public holidays"}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        {t("No data found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center my-4">
        <Link
          to={HOLIDAY_CONFIGS}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidays;
