import { selectAuth } from 'features/auth/AuthSlice';
import React from 'react'
import { useSelector } from 'react-redux';
import AccessControl from 'services/AccessControl';
import { FilterOptionsProvider } from './work-pay-bill-roll-redux/context/FilterOptionsContext';
import { OverviewProvider } from './work-pay-bill-roll-redux/context/OverviewContext';
import { TableProvider } from './work-pay-bill-roll-redux/context/TableContext';
import PageLayout from './WorkPayBillRollPage';
import WorkPayBillRollOrganism from './organisms/WorkPayBillRollOrganism';

const WorkPayBillRoll = () => {
    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[{
                permission: "Planning",
                read: true,
            }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <FilterOptionsProvider>
                <TableProvider>
                    <OverviewProvider>
                        <PageLayout>
                            <WorkPayBillRollOrganism />
                        </PageLayout>
                    </OverviewProvider>
                </TableProvider>
            </FilterOptionsProvider>
        </AccessControl>
    );
}

export default WorkPayBillRoll