import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import Button from "components/atoms/Button";
import { Navigation } from "../formNavigation/Navigation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelField from "components/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import CloseFile from "static/images/CloseFile";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useEffect, useState } from "react";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateTextFieldAlpha,
  validateEmail,
  validatePhoneNumber,
  validateSelectField,
  scrollToTop,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as END_POINTS from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { M_COMPANY_CREATION } from "constants/Constants";
import { mapToSelect } from "utils/MapToSelect";
import { COMPANY_DETAILS } from "routes/ApiEndpoints";
import { formatToE164 } from "../../../../../utils/phoneNumberFormat";
import { E164Number } from "libphonenumber-js";
const Contacts = () => {
  const { state, dispatch } = useFormContext();

  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fName: [validateRequired, validateTextFieldAlpha],
      lName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobileNumber: [validatePhoneNumber],
      gender: [validateSelectField],
      //linkedIn: [validateRequired],
    };
    const validationErrors = validateForm(
      { ...state.contacts[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.dynamicErrors[index][name] = "";
    } else {
      state.dynamicErrors[index][name] = validationErrors[name];
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    const processedValue = type === "number" ? String(value) : value;
    if (name === "phoneNumber" && /\D/.test(value)) {
      return;
    }
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: processedValue,
      index,
    });
    validation(name, processedValue, index, true);
  };

  const handlePhoneNumberChange = (
    mobileNumber: string | E164Number | undefined,
    index: number
  ) => {
    const processedValue = mobileNumber ? String(mobileNumber) : "";
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      index,
      field: "mobileNumber",
      value: processedValue,
    });
    validation("mobileNumber", processedValue, index, true);
  };
  const handleAddContact = () => {
    dispatch({ type: "ADD_CONTACT" });
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_CONTACT", indexToRemove });
  };

  const currentDate = new Date();
  const below15 = new Date();
  below15.setFullYear(currentDate.getFullYear() - 15);

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: selectedOption,
      index,
    });
    validation(name, selectedOption, index, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;

    state.contacts.forEach((contact, index) => {
      if (contact.email.trim() !== "") {
        if (emailSet.has(contact.email)) {
          state.dynamicErrors[index].email = "Duplicate email";
          hasDuplicate = true;
        } else {
          emailSet.add(contact.email);
        }
      }

      if (contact.mobileNumber.trim() !== "") {
        if (phoneSet.has(contact.mobileNumber)) {
          state.dynamicErrors[index].mobileNumber = "Duplicate phone number";
          hasDuplicate = true;
        } else {
          phoneSet.add(contact.mobileNumber);
        }
      }
    });

    return hasDuplicate;
  };

  // This handle final submit of the form with all the details
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const duplicates = checkForDuplicates();
    const formData = {
      basic: state.basic,
      address: state.address,
      companyLocation: state.companyLocation,
      //locationIds: state.locationIds,
      contacts: state.contacts,
    };
    formData.basic.companyId = companyId;
    if (!duplicates && validationStatus.isValid) {
      setLoading(true);
      const url = !companyId
        ? END_POINTS.REGISTER_COMPANY
        : `${END_POINTS.REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        true,
        M_COMPANY_CREATION
      );

      if (response?.status === 422) {
        CustomNotify({
          type: "warning",
          message: response?.msg,
        });
      }
      if (response?.status === 200) {
        const msg =
          state.basic.companyStatus === 1
            ? "Company updated successfully!"
            : "Company created successfully!";
        CustomNotify({
          type: "success",
          message: msg,
        });
        navigate("/manage-companies");
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response?.msg });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response?.msg;
      }

      // }
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="create-form-height-company">
        {
          <div className="form-border p-5 mb-3">
            {state.contacts.map((contact, index) => (
              <div
                className="field-set border px-3 pt-4 pb-2 mb-4 rounded-2"
                key={index}
              >
                {index > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-block d-md-block d-lg-none">
                        {!isViewMode && (
                          <Button
                            title="Remove"
                            handleClick={() => handleRemoveContact(index)}
                            className="link-button float-end text-uppercase rounded-0 shadow-none my-2"
                          />
                        )}
                      </div>
                      <div className="d-none d-md-none d-lg-block">
                        {!isViewMode && (
                          <span
                            title="Delete"
                            onClick={() => handleRemoveContact(index)}
                            className="table-action-icons cursor-pointer float-end text-uppercase rounded-0 shadow-none"
                          >
                            <CloseFile />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="fName"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.fName ?? ""}
                      id={`fname-${index}`}
                      label="First name"
                      placeholder="First name"
                      error={state.dynamicErrors[index]?.fName}
                      className="form-control shadow-none rounded-0"
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="lName"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.lName ?? ""}
                      id={`lname-${index}`}
                      label="Last name"
                      placeholder="Last name"
                      error={state.dynamicErrors[index]?.lName}
                      className="form-control shadow-none rounded-0"
                      isDisabled={isViewMode}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="email"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.email ?? ""}
                      id={`email-${index}`}
                      label="Email"
                      placeholder="Email"
                      type="email"
                      error={state.dynamicErrors[index]?.email}
                      className="form-control shadow-none rounded-0"
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelField
                      title="Mobile number"
                      isMandatory={true}
                      key="PhoneInput"
                    />
                    <div className="form-control rounded-0 shadow-none">
                      <PhoneInput
                        defaultCountry="BE"
                        international
                        placeholder=""
                        onChange={(mobileNumber: E164Number) =>
                          handlePhoneNumberChange(mobileNumber, index)
                        }
                        value={
                          contact.mobileNumber
                            ? String(contact.mobileNumber)
                            : ""
                        }
                        disabled={isViewMode}
                      />
                    </div>
                    <div className="height-20 mb-2 text-danger">
                      {state.dynamicErrors[index]?.mobileNumber && (
                        <span className="text-danger">
                          {state.dynamicErrors[index]?.mobileNumber}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="phoneNumber"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.phoneNumber ?? ""}
                      id={`phoneNumber-${index}`}
                      label="Phone number"
                      placeholder="Phone number"
                      type="text"
                      error={state.dynamicErrors[index]?.phoneNumber}
                      className="form-control shadow-none rounded-0"
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <SelectWithSearch
                      title="Gender"
                      name="gender"
                      isMandatory={true}
                      search={true}
                      options={state.genders}
                      placeHolder="Select"
                      value={contact.gender}
                      onChange={(e) => handleSelectChange(e, "gender", index)}
                      isMulti={false}
                      className="select-field"
                      isDisabled={isViewMode}
                      error={state.dynamicErrors[index]?.gender}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <SelectWithSearch
                      title="Language"
                      name="language"
                      isMandatory={false}
                      search={true}
                      options={state.languageList}
                      placeHolder="Select"
                      value={contact.language}
                      onChange={(e) => handleSelectChange(e, "language", index)}
                      isMulti={false}
                      className="select-field"
                      error={state.dynamicErrors[index]?.language}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="linkedIn"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.linkedIn ?? ""}
                      id="linkedIn"
                      label="Linkedin website url"
                      type="text"
                      error={state.dynamicErrors[index]?.linkedIn}
                      isDisabled={isViewMode}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <SelectWithSearch
                      title="Roles"
                      name="role"
                      isMandatory={false}
                      search={true}
                      options={state.rolesList}
                      placeHolder="Select"
                      value={contact.roles ?? ""}
                      onChange={(e) => handleSelectChange(e, "roles", index)}
                      isMulti={true}
                      className="select-field"
                      isDisabled={isViewMode}
                      // error={state.dynamicErrors[index]?.language}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="functionTitle"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.functionTitle ?? ""}
                      id={`functionTitle-${index}`}
                      label="Function"
                      placeholder="Function"
                      type="text"
                      error={state.dynamicErrors[index]?.functionTitle}
                      className="form-control shadow-none rounded-0"
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <SelectWithSearch
                      title="Location"
                      name="location"
                      isMandatory={false}
                      search={true}
                      options={state.locationList}
                      placeHolder="Select"
                      value={contact.location ?? ""}
                      onChange={(e) => handleSelectChange(e, "location", index)}
                      isMulti={true}
                      className="select-field"
                      isDisabled={isViewMode}
                      // error={state.dynamicErrors[index]?.language}
                    />
                  </div>
                  <div className="col-lg-12">
                    <LabelWithTextAreaField
                      name="info"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={contact.info ?? ""}
                      label="Info"
                      isDisabled={isViewMode}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="row my-4">
              <div className="col-md-12">
                {!isViewMode && (
                  <Button
                    title="+ Add another"
                    handleClick={handleAddContact}
                    className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                  />
                )}
              </div>
            </div>
          </div>
        }
      </div>

      {!isViewMode && (
        <Navigation
          validStatus={validStatus}
          // isLoading={loading}
          isLoading={false}
        />
      )}
    </form>
  );
};

export default Contacts;
