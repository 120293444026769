import React, {useContext, createContext, useReducer, useState} from 'react'
import {planningReducer} from '../../reducers/planningReducer/PlanningReducer';
import {initialPlanningProps} from '../PlanningContext/initialPlanningProp';
import { PLANNING_VIEW_OPTIONS } from 'pages/microservices/planning/constants/planningConstants';
const PlanningContext = createContext(null);
const PlanningDispatchContext = createContext(null);

const initialPlanning = initialPlanningProps;

export function PlanningProvider({children}){
    const [planningState, dispatchPlanning] = useReducer(planningReducer, initialPlanning);
    const [view, setView] = useState(PLANNING_VIEW_OPTIONS[0]);

    return (
        <PlanningContext.Provider value={{planningState, view}}>
            <PlanningDispatchContext.Provider value={{dispatchPlanning, setView}}>
            {children}
            </PlanningDispatchContext.Provider>
        </PlanningContext.Provider>
    )
}


export function usePlanning(){
    return useContext(PlanningContext);
}


export function usePlanningsDispatch(){
    return useContext(PlanningDispatchContext);
}
