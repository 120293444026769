import React, { ChangeEvent } from "react";
import SelectWithSearch from "./SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { MONTHS_TRANSLATABLE } from "constants/Constants";
import { Option } from "utils/TypeAnnotations";

interface MonthPickerProps {
    name: string;
    label: string;
    handleChange: (value: Option) => void;
    value: string;
    error: string;
}
const MonthPicker = ({
    name,
    label,
    handleChange,
    value,
    error
}: MonthPickerProps) => {
    const options = MONTHS_TRANSLATABLE.map((key, index) => ({
        value: index,
        label: key
    }));

    return (
        <>
            <SelectWithSearch
                onChange={handleChange}
                title={label}
                placeHolder={t("Select month")}
                search={true}
                options={options}
                value={value}
                isDisabled={false}
                isMulti={false}
                isMandatory={false}
                error={error}
                className="w-75"
                name={name}
            />
        </>
    );
}
export default MonthPicker;