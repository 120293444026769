import React, { useContext, createContext, useReducer, ReactNode, Dispatch } from 'react';
import { initialOverviewProps } from '../../constants/WorkPayBillRollConstants';
import { overviewReducer } from '../reducers/overviewReducer';
import { PayRollAction, OverviewState } from '../actions/Interfaces';

interface OverviewProviderProps {
    children: ReactNode;
}

const OverviewContext = createContext<OverviewState | null>(null);
const OverviewDispatchContext = createContext<Dispatch<PayRollAction> | null>(null);

const initialOverviewOptions: OverviewState = initialOverviewProps;

export function OverviewProvider({ children }: OverviewProviderProps) {
    const [overviewState, dispatchOverview] = useReducer(overviewReducer, initialOverviewOptions);

    return (
        <OverviewContext.Provider value={overviewState}>
            <OverviewDispatchContext.Provider value={dispatchOverview}>
                {children}
            </OverviewDispatchContext.Provider>
        </OverviewContext.Provider>
    );
}

export function useOverview() {
    const context = useContext(OverviewContext);
    if (context === null) {
        throw new Error('useOverview must be used within an OverviewProvider');
    }
    return context;
}

export function useOverviewDispatch() {
    const context = useContext(OverviewDispatchContext);
    if (context === null) {
        throw new Error('useOverviewDispatch must be used within an OverviewProvider');
    }
    return context;
}
