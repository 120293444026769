import React, { useState, ChangeEvent } from "react";
import { handleMultipleFileUpload } from "./Index";

const FileUploader: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);
    const uploadedFilesData = await handleMultipleFileUpload(selectedFiles, 'local/testing');
    setIsUploading(false);

    if (uploadedFilesData) {
      console.log("Uploaded files data:", uploadedFilesData);
    } else {
      console.log("No files were uploaded successfully.");
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} multiple />
      <button onClick={handleUpload} disabled={isUploading}>Upload Files</button>
      {isUploading && <div className="loader" style={loaderStyle}></div>} {/* Show custom circular loader while uploading */}
    </div>
  );
};

const loaderStyle: React.CSSProperties = {
  border: "4px solid rgba(0, 0, 0, 0.1)",
  borderLeftColor: "#4285f4", // Change color as per your preference
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  animation: "spin 1s linear infinite",
};

export default FileUploader;
