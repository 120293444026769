import { PayRollAction, TableState } from "../actions/Interfaces";

export const tableReducer = (state: TableState, action: PayRollAction): TableState => {
    switch (action.type) {
        case 'SET_TABLE_DATA':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
