import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from "react";
import { AgreementFormData, TabProps } from "../annotations/AgreementAnnotations";
import { OptionProps } from "utils/TypeAnnotations";
import { Option } from "services/validation/ValidationService";

const initialFormData: AgreementFormData = {
  tabDetails: [
    {
      id: "general",
      title: "General",
      showStatus: true,
      error:false,
      draft:false,
    },
    {
      id: "billing",
      title: "Billing",
      showStatus: false,
      error:false,
      draft:false
    },
    {
      id: "contact",
      title: "Contact",
      showStatus: false,
      error:false,
      draft:false
    },
    {
      id: "compositionData",
      title: "Wage elements",
      showStatus: false,
      error:false,
      draft:false
    },
    {
      id: "invoice",
      title: "Invoice",
      showStatus: false,
      error:false,
      draft:false
    },
  ],
  modalStatus: false,
  maxTabIndex:0,
  tab:0,
  general: {
    vat: "",
    company: "",
    startDate: null,
    consultant: null,
    officeNumber: null,
    province: [],
    addProject: false,
    type: null,
    workType:null,
    vatInclusive: null,
    feeType: null,
    paritairBlue: [],
    paritairWhite: []
  },
  contacts: [
    {
      fName: "",
      lName: "",
      email: "",
      phNumber: "",
      dob:null,
      gsm:"",
      contact:"",
      functionTitle:"",
      teleNumber: "",
      language: null,
      decisionMaker: false,
      influencer: false,
      contactCalled: false,
      contactEmailed: false,
      info:"",
      title: null,
      location: null,
    },
  ],
  invoice: {
    payment:null,
    billingMode: null,
    invoiceType: null,
    status: null,
    corrections: null,
    comments:''
  },
  consultantList: [],
  blueCollarPCs: [],
  whiteCollarPCs: [],
  employeeTypes:[],
  compositionTitle: [],
  compositionData: [{
    title: '',
    inclusiveOption: null,
    max : null,
    remarks: ''
  }],
  fieldError: {
    company: "",
    startDate: "",
    consultant: "",
    officeNumber: "",
    province: "",
    addProject: "",
    type: "",
    vatInclusive: "",
    feeType: "",
    PC: "",
    PC2: "",
    employeeType2: "",
    employeeType: "",
    payment: "",
    billingMode: "",
    invoiceType: "",
    status: "",
    corrections: "",
    comments: "",
    paritairBlue: "",
  paritairWhite: "",
  },
  dynamicErrors:[
    {
      fName: "",
      lName: "",
      email: "",
      phNumber: "",
      teleNumber: "",
      dob:"",
      gsm:"",
      contact:"",
      functionTitle:"",
      language: "",
      decisionMaker: "",
      influencer: "",
      contactCalled: "",
      contactEmailed: "",
      info:"",
      title: "",
      location: "",
    }
  ]
};

// Define form actions
type FormAction =
  | { type: "MODAL_STATUS"; value: boolean }
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  | { type: "UPDATE_TAB_DETAILS"; tabDetails: TabProps[] }
  | { type: "UPDATE_TAB_ERROR"; tabIndex: number; error: boolean }
  | { type: "SET_DROPDOWN"; dropdownValues: OptionProps[]; field: keyof AgreementFormData }
  | { type: "UPDATE_GENERAL_FIELD"; field: string; value: boolean | string | number | Date | null | undefined | Option | object[] }
  | { type: "UPDATE_INVOICE_FIELD"; field: string; value: string | number | null | undefined | Option }
  | { type: "ADD_COMPOSITION_FIELD"; field: string; value: string | number | null | undefined | Option; index:number }
  | { type: "UPDATE_COMPOSITION_FIELD"; field: string; value: string | number | null | undefined | Option; index:number }
  | { type: "UPDATE_FIELD_ERROR"; fieldName: string; error: string }
  | { type: "UPDATE_FIELD_ERRORS";  errors: any }
  | { type: "ADD_WHITECOLLAR_FIELD"; newData:any}
  | { type: "UPDATE_WHITECOLLAR_FIELD"; newData:any; index:number}
  | { type: "REMOVE_WHITE_PC"; indexToRemove: number }
  | { type: "REMOVE_BLUE_PC"; indexToRemove: number }
  | { type: "ADD_BLUECOLLAR_FIELD"; newData:any}
  | { type: "UPDATE_BLUECOLLAR_FIELD"; newData:any; index:number}
  | {
    type: "UPDATE_CONTACTS_FIELD";
    field: string;
    value: string | number | Date | null | undefined;
    index: number;
  }
| { type: "ADD_CONTACT" }
| { type: "REMOVE_CONTACT"; indexToRemove: number }
| { type: "ADD_UPDATE_DYNAMIC"; field: string; value: string | number | Date | null | undefined; index: number;}
| {
  type: "UPDATE_CONTACTS_FIELD_ERROR";
  field: string;
  error: string;
  index: number;
};


type Dispatch = (action: FormAction) => void;

const FormContext = createContext<
  | {
    state: AgreementFormData;
    dispatch: Dispatch;
  }
  | undefined
>(undefined);



const formReducer: Reducer<AgreementFormData, FormAction> = (state, action) => {
  switch (action.type) {
    case "MODAL_STATUS":
      return {
        ...state,
        modalStatus: action.value,
      };
      case "NEXT_STEP":
        const currentTab = state.tabDetails.find((tab) => tab.showStatus);
        if (currentTab) {
          const currentIndex = state.tabDetails.findIndex(
            (tab) => tab.id === currentTab.id
          );
          const nextIndex = (currentIndex + 1) % state.tabDetails.length;
  
          const updatedTabDetails = state.tabDetails.map((tab) => ({
            ...tab,
            showStatus: tab.id === state.tabDetails[nextIndex].id,
          }));
  
          return {
            ...state,
            tabDetails: updatedTabDetails,
          };
        }
        return state;
  
      case "PREVIOUS_STEP":
        const currentTabPrev = state.tabDetails.find((tab) => tab.showStatus);
        if (currentTabPrev) {
          const currentIndexPrev = state.tabDetails.findIndex(
            (tab) => tab.id === currentTabPrev.id
          );
          const previousIndex =
            (currentIndexPrev - 1 + state.tabDetails.length) %
            state.tabDetails.length;
  
          const updatedTabDetailsPrev = state.tabDetails.map((tab) => ({
            ...tab,
            showStatus: tab.id === state.tabDetails[previousIndex].id,
          }));
  
          return {
            ...state,
            tabDetails: updatedTabDetailsPrev,
          };
        }
        return state;
  
    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabDetails: action.tabDetails,
      };
    case "UPDATE_TAB_ERROR":
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab, index) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };
    case 'SET_DROPDOWN':
      return {
        ...state,
        [action.field]: action.dropdownValues,
      };
    case "UPDATE_GENERAL_FIELD":      
      return {
        ...state,
        general: {
          ...state.general,
          [action.field]: action.value,
        },
      };
    case "UPDATE_INVOICE_FIELD":      
      return {
        ...state,
        invoice: {
          ...state.invoice,
          [action.field]: action.value,
        },
      };
    case "ADD_COMPOSITION_FIELD":
      
      if (action.index === state.compositionData.length) {
        const newInfo = {
          title:'',
          inclusiveOption: null,
          max : null,
          remarks: ''
        };
    
        return {
          ...state,
          compositionData: [...state.compositionData, newInfo],
        };
      }
  
      return {
        ...state,
        compositionData: state.compositionData.map((info, index) =>
          index === action.index
            ? { ...info, [action.field]: action.value }
            : info
        ),
      }; 

    case "UPDATE_COMPOSITION_FIELD":
        
      return {
        ...state,
        compositionData: state.compositionData.map((item, index) => {
          if (index === action.index) {
            return {
              ...item,
              [action.field]: action.value,
            };
          } else {
            return item;
          }
        }),
      };

    case "UPDATE_FIELD_ERROR":          
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.fieldName]: action.error,
        },
      };
    case "UPDATE_FIELD_ERRORS":
      return {
        ...state,
        fieldError: {
          ...action.errors,
        },
      };
      
    case "ADD_WHITECOLLAR_FIELD":      
      return {
        ...state,
        general: {
          ...state.general,
          paritairWhite: [...state.general.paritairWhite, action.newData],
        },                
      };

    case "UPDATE_WHITECOLLAR_FIELD":
      return {
        ...state,
        general: {
          ...state.general,
          paritairWhite: state.general.paritairWhite.map((item, index) => {
            if (index === action.index) {
              return action.newData;
            } else {
              return item;
            }
          }),
        },
      };
      
    case "ADD_BLUECOLLAR_FIELD":  
      return {
        ...state,
        general: {
          ...state.general,
          paritairBlue: [...state.general.paritairBlue, action.newData],
        },                
      };

    case "UPDATE_BLUECOLLAR_FIELD":
      return {
        ...state,
        general: {
          ...state.general,
          paritairBlue: state.general.paritairBlue.map((item, index) => {
            if (index === action.index) {
              return action.newData;
            } else {
              return item;
            }
          }),
        },
      };
    
    case "REMOVE_WHITE_PC":
      const updatedDetails = [...state.general.paritairWhite];
      updatedDetails.splice(action.indexToRemove, 1);
      return {
        ...state,
        general: {
          ...state.general,
          paritairWhite: updatedDetails,
        },
      };

    case "REMOVE_BLUE_PC":
      const updatedData = [...state.general.paritairBlue];
      updatedData.splice(action.indexToRemove, 1);
      return {
        ...state,
        general: {
          ...state.general,
          paritairBlue: updatedData,
        },
      }; 
    
    case "UPDATE_CONTACTS_FIELD":      
      return {
        ...state,
        contacts: state.contacts.map((contact, index) => {
          if (index === action.index) {
            return {
              ...contact,
              [action.field]: action.value,
            };
          }
          return contact;
        }),
      };
    
    case "ADD_CONTACT":
        // Add a new contact to the contacts array
        const newContact = {
          fName: "",
          lName: "",
          email: "",
          phNumber: "",
          dob:null,
          gsm:"",
          contact:"",
          functionTitle:"",
          language: null,
          teleNumber: '',
          decisionMaker: false,
          influencer: false,
          contactCalled: false,
          contactEmailed: false,
          info:"",
          title: null,
          location: null,
        };
        const newContactErros = {
          fName: "",
          lName: "",
          email: "",
          phNumber: "",
          teleNumber: '',
          dob:"",
          gsm:"",
          contact:"",
          functionTitle:"",
          language: "",
          decisionMaker: "",
          influencer: "",
          contactCalled: "",
          contactEmailed: "",
          info:"",
          title: "",
          location: "",
        }
        return {
          ...state,
          contacts: [...state.contacts, newContact],
          dynamicErrors:[...state.dynamicErrors, newContactErros]
        };

      case "ADD_UPDATE_DYNAMIC":
        if (action.index === state.contacts.length) {
          const newContact = {
            fName: "",
            lName: "",
            email: "",
            phNumber: "",
            teleNumber: '',
            dob: null,
            gsm:"",
            contact:"",
            functionTitle:"",
            language: null,
            decisionMaker: false,
            influencer: false,
            contactCalled: false,
            contactEmailed: false,
            info:"",
            title: null,
            location: null,
          };

          const newContactErrors = {
            fName: "",
            lName: "",
            email: "",
            phNumber: "",
            teleNumber: '',
            dob:"",
            gsm:"",
            contact:"",
            functionTitle:"",
            language: "",
            decisionMaker: "",
            influencer: "",
            contactCalled: "",
            contactEmailed: "",
            info:"",
            title: "",
            location: "",
          };
      
          return {
            ...state,
            contacts: [...state.contacts, newContact],
            dynamicErrors: [...state.dynamicErrors, newContactErrors],
          };
        }
      
        return {
          ...state,
          contacts: state.contacts.map((contact, index) =>
            index === action.index
              ? { ...contact, [action.field]: action.value }
              : contact
          ),
        };  

      case "REMOVE_CONTACT":
          // Remove the contact at the specified index
          const updatedContacts = [...state.contacts];
          updatedContacts.splice(action.indexToRemove, 1);
          const updatedContactsErrors = [...state.dynamicErrors];
          updatedContactsErrors.splice(action.indexToRemove, 1);
          return {
            ...state,
            contacts: updatedContacts,
            dynamicErrors:updatedContactsErrors
          };
      case "UPDATE_CONTACTS_FIELD_ERROR":      
        return {
          ...state,
          dynamicErrors: state.dynamicErrors.map((error, index) => {
            if (index === action.index) {
              return {
                ...error,
                [action.field]: action.error,
              };
            }
            return error;
          }),
        };
    default:
      return state;
  }
};

// Create a FormProvider component to wrap the form
interface FormProviderProps {
  children: ReactNode;
}

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialFormData);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
