import React from "react";

const AddEmployeeIcon = () => {
  return (
    <span title="Add employee">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="87"
        height="25"
        viewBox="0 0 87 100"
        fill="none"
      >
        <path
          d="M43.3333 46.6667C47.9482 46.6667 52.4595 45.2982 56.2966 42.7343C60.1338 40.1704 63.1245 36.5262 64.8905 32.2626C66.6566 27.999 67.1186 23.3075 66.2183 18.7812C65.318 14.255 63.0957 10.0974 59.8325 6.83418C56.5693 3.57096 52.4117 1.34868 47.8854 0.448354C43.3592 -0.451968 38.6677 0.0101098 34.4041 1.77615C30.1404 3.5422 26.4963 6.53289 23.9324 10.37C21.3685 14.2072 20 18.7184 20 23.3333C20 29.5217 22.4583 35.4566 26.8342 39.8325C31.21 44.2083 37.145 46.6667 43.3333 46.6667ZM43.3333 6.66668C46.6297 6.66668 49.852 7.64416 52.5928 9.47552C55.3337 11.3069 57.4699 13.9098 58.7313 16.9553C59.9928 20.0007 60.3228 23.3518 59.6798 26.5848C59.0367 29.8179 57.4493 32.7876 55.1185 35.1185C52.7876 37.4493 49.8179 39.0367 46.5848 39.6798C43.3518 40.3228 40.0007 39.9928 36.9553 38.7313C33.9098 37.4699 31.3069 35.3337 29.4755 32.5928C27.6442 29.852 26.6667 26.6297 26.6667 23.3333C26.6667 18.9131 28.4226 14.6738 31.5482 11.5482C34.6738 8.42262 38.9131 6.66668 43.3333 6.66668Z"
          fill="black"
        />
        <path
          d="M84.9 71.2333C79.5544 65.5834 73.1126 61.083 65.9685 58.0074C58.8244 54.9317 51.128 53.3454 43.35 53.3454C35.572 53.3454 27.8756 54.9317 20.7315 58.0074C13.5874 61.083 7.14565 65.5834 1.80001 71.2333C0.641049 72.4712 -0.00262304 74.1043 8.03436e-06 75.8V93.3333C8.03436e-06 95.1014 0.702386 96.7971 1.95263 98.0474C3.20287 99.2976 4.89856 100 6.66667 100H80C81.7681 100 83.4638 99.2976 84.714 98.0474C85.9643 96.7971 86.6667 95.1014 86.6667 93.3333V75.8C86.6784 74.1088 86.0468 72.4764 84.9 71.2333ZM80 93.3333H6.66667V75.7667C11.3908 70.7923 17.0776 66.8311 23.3812 64.1243C29.6848 61.4175 36.4732 60.0216 43.3333 60.0216C50.1935 60.0216 56.9819 61.4175 63.2855 64.1243C69.589 66.8311 75.2759 70.7923 80 75.7667V93.3333Z"
          fill="black"
        />
      </svg>
    </span>
  );
};
export default AddEmployeeIcon;
