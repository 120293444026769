import React, { ChangeEvent, MouseEventHandler } from "react";
import InputTextfield from "components/atoms/InputTextField";
import Button from "components/atoms/Button";
import LabelField from "components/atoms/LabelField";

interface InputRange {
    inputtype: string;
    rangevalue: number;
    rangeclass?: string;
    name: string;
    min: number;
    max: number;
    step: number;
    title: string;
    id: string;
    error?: string;
    isMandatory: boolean;

    inputnumtype?: string;
    value: number;
    handleChange: ((event: ChangeEvent<HTMLInputElement>) => void);
    inputname: string;
    inputmin: number;
    inputmax: number;
    inputstep: number;
}

const InputRangeMolecule: React.FC<InputRange> = ({
    inputtype,
    rangevalue,
    rangeclass,
    name,
    min,
    max,
    step,
    title,
    id,
    error,
    isMandatory,

    inputnumtype,
    value,
    handleChange,
    inputname,
    inputmin,
    inputmax,
    inputstep,

}) => {

    return (
        <>
            <LabelField
                title={title}
                htmlfor={id}
                isMandatory={isMandatory}
            />
            <div className="row">
                <div className="col-5">
                    <InputTextfield
                        type={inputtype}
                        value={rangevalue}
                        handleChange={handleChange}
                        className={rangeclass}
                        name={name}
                        min={min}
                        max={max}
                        step={step}
                        error={error}
                    />
                </div>
                <div className="col-1">
                    <InputTextfield
                        type={inputnumtype}
                        value={value}
                        handleChange={handleChange}
                        name={inputname}
                        min={inputmin}
                        max={inputmax}
                        step={inputstep}
                        id={id}
                    />
                </div>
            </div>

        </>
    )

}
export default InputRangeMolecule