import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageClothes from "./ManageClothes";
import { M_MASTER_DATA } from "constants/Constants";
import { CLOTHES, CLOTHES_EDIT } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

// Define the prop types for the component
interface ClothesProps {
  id?: string | number;
  type?: string;
}

interface DataProps {
  page: number;
  nOR: number;
  type?: any;
  userId?: any;
  search?: Partial<ClothesProps>;
}

// Functional component using TypeScript
const Clothes: React.FC<ClothesProps> = ({ type }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { id: routeId } = useParams();

  const itemsPerPage = 10;
  const [materialData, setMaterialData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState<any>({
    company: queryParams.get("company") ?? "",
    businessUnit: queryParams.get("businessUnit") ?? "",
    brand: queryParams.get("brand") ?? "",
    model: queryParams.get("model") ?? "",
    status: queryParams.get("status") ?? "",
  });

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const postData: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      type: 'clothes',
      userId: routeId ?? null,
      search: search,
    };
    getMaterialData(postData);
  }, [currentPage, submit]);

  const getMaterialData = async (postData: DataProps) => {
    const response = await ApiCall.service(
      CLOTHES_EDIT,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setMaterialData(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } else {
      console.log("error");
    }
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch: any) => ({ ...prevSearch, [name]: value }));
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: {
    [key: string]: string | number | boolean;
  }) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]: [string, string | number | boolean]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSearchSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        company: "",
        businessUnit: "",
        brand: "",
        model: "",
        status: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }

    setSubmit(!submit);
    setCurrentPage(1);
  };

  return (
    <>
      <Suspense
        fallback={
          <div className="text-center text-danger">{t("Loading")}...</div>
        }
      >
        <ManageClothes
          data={materialData}
          fleetProps={type}
          handleData={setMaterialData}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          searchValue={handleSearch}
          handleSubmitAction={handleSearchSubmit}
          searchData={search}
          handleGetVacany={getMaterialData}
        />
      </Suspense>
    </>
  );
};

export default Clothes;
