import React, { useState } from "react";
import TimesheetsEmployees from "../molecules/timesheetsEmployees";
import TimesheetsProjects from "../molecules/timesheetsProjects";
import WeekCalendar from "../molecules/weekCalendar";
import { useWeekAndDayDispatch } from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import { WeekAndDayActions } from "../timesheets-redux/actions/WeekAndDayActions";
import { useWeekAndDay } from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import PlanTimings from "../atoms/planningTimings";
import WorkTimings from "../atoms/workTimings";
import Regime from "../atoms/regime";
import CommonCheckBox from "../atoms/commonCheckbox";
import ProjectParameters from "../molecules/projectParameters";
import Popup from "components/molecules/Popup";
import InvoiceModal from "../molecules/invoiceModal";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import Button from "components/atoms/Button";
import Work from "../molecules/work";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { getWeekDays } from "../utils/utils";
import { dateFormat } from "../utils/utils";
import { fetchWeeklyTimesheetData } from "../utils/utils";
import { ApiCall } from "services/ApiServices";
import { CREATE_TIMESHEET } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import PerformanceModal from "../molecules/performanceModal";
import {
  useTimesheetModalContext,
  useTimesheetModalDispatch,
} from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import LoadingOverlay from "utils/LoadingOverlay";
import ReimbursementModal from "../molecules/reimbursementModal";
import { useProject } from "../timesheets-redux/context/ProjectContext/ProjectContext";
import { useEmployee } from "../timesheets-redux/context/EmployeeContext/EmployeeContext";
import { useFilterOptionsDispatch } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";
import ProjectParameterHeader from "../atoms/projectParameterHeader";
import { useProjectParameterContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function TimesheetsOverview() {
  const { dispatchWeekAndDay } = useWeekAndDayDispatch();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { initialWeekAndDayPropsState } = useWeekAndDay();
  const { initialTimesheetState } = useTImesheetContext();
  const { projectState } = useProject();
  const { initialEmployeeState } = useEmployee();
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const { initialProjectParameterState } = useProjectParameterContext();
  const [loading, setLoading] = useState(false);
  const [isFirstColumnOpen, setIsFirstColumnOpen] = useState(true);
  const [isLastColumnOpen, setIsLastColumnOpen] = useState(true);
  let timesheet_data = initialTimesheetState?.timesheet_data ? initialTimesheetState.timesheet_data : [];
  const handleWeekChange = (value) => {
    dispatchWeekAndDay({
      type: WeekAndDayActions.CHANGE_WEEK,
      payload: value,
    });
    hadleTimsheetDipatch(value);
  };

  const hadleTimsheetDipatch = async (value) => {
    const date = new Date(initialWeekAndDayPropsState.currentDate);
    date.setDate(date.getDate() + value);
    let weekDays = getWeekDays(date);
    let startDate = dateFormat(weekDays[0]);
    let endDate = dateFormat(weekDays[6]);
    const data = {
      project_id: projectState.project_id,
      employee_id: initialEmployeeState.employee_id,
      from_date: startDate,
      to_date: endDate,
    };
    const updatedData = await fetchWeeklyTimesheetData(data, date);
    timesheetDispatch({
      type: TIMESHEET_ACTIONS.CHANGE_WEEK,
      payload: {
        data: updatedData,
      },
    });
  };

  const renderWeekNumber = () => {
    const weekNumber = Math.ceil(
      (initialWeekAndDayPropsState.currentDate -
        new Date(initialWeekAndDayPropsState.currentDate.getFullYear(), 0, 1)) /
        (7 * 24 * 60 * 60 * 1000)
    );
    return <div className="text-center mb-3">Week {weekNumber}</div>;
  };

  const saveTImesheet = async (invoice = false) => {
    let timesheetData = {
      ...initialTimesheetState,
      timesheet_data: initialTimesheetState?.timesheet_data?.filter((plan) => plan?.plan_id),
      isInvoiced: (invoice != 'yes'),
    };
    if (timesheetData?.timesheet_data) {
      let data = {
        data: {
          ...timesheetData,
          employee_id: initialEmployeeState.employee_id,
          project_id: projectState.project_id,
        },
      };
      setLoading(true);
      await ApiCall.service(CREATE_TIMESHEET, "POST", data, false, M_MASTER_DATA)
        .then(async (response) => {
          if (response.success) {
            CustomNotify({ type: "success", message: response.message });
            (invoice) && handleModalDispatch("invoicenow");
            dispatchFilterOptions({type: TIMESHEET_FILTER_ACTIONS.REFRESH, payload: {}})
          } else {
            CustomNotify({ type: "error", message: response.message });
          }
        }).catch((error) => {
          CustomNotify({ type: "error", message: error });
        });
      setLoading(false);
    }
  };

  const handleModalDispatch = (modalType) => {
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: !initialTimesheetModalState[modalType].isOpen,
        modalType: modalType,
        data: [],
        date: "",
        planId: ""
      },
    });
  };

  return (
    <div className="h-100 overflow-auto d-flex flex-column">
      <LoadingOverlay display={loading ? "block" : "none"} />
      <div className="row mx-0">
        <div className="col-9 ms-auto">
          <div className="d-flex mx-3 justify-content-between">
            <div
              className="planning_cal_btn "
              onClick={() => handleWeekChange(-7)}
              style={{ cursor: "pointer" }}
            >
              &larr;
            </div>
            <span>{renderWeekNumber()}</span>
            <div
              className="planning_cal_btn"
              onClick={() => handleWeekChange(7)}
              style={{ cursor: "pointer" }}
            >
              &rarr;
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 flex-1 overflow-auto">
        {/* First Column with Toggle */}
        {isFirstColumnOpen && ( <div className="col-3 h-100 overflow-auto">
            <div className="row h-100">
              <div className="col text-center p-0 sidebar_bg h-100 overflow-auto">
                <TimesheetsProjects />
              </div>
              <div className="col text-center p-0 sidebar_bg h-100 overflow-auto">
                <TimesheetsEmployees />
              </div>
            </div>
        </div>)}
        <div className={` col-${(isFirstColumnOpen && isLastColumnOpen) ? 12 - 5 : (isFirstColumnOpen ? 12 - 3 : (isLastColumnOpen ? 12 - 2 : 12))} text-center p-0 sidebar_bg h-100 overflow-auto`}>
          <table className="w-100  h-100 overflow-auto">
            <thead className="position-sticky top-0 bg-lightskyblue">
              <WeekCalendar />
            </thead>
            <tbody>
              <PlanTimings />
              <Work />
              <Regime
                planTimingsData={timesheet_data}
                header1="Regime"
                header2=""
                name="regime"
              />
              <CommonCheckBox
                planTimingsData={timesheet_data}
                header1="Holiday"
                header2=""
                name={"holiday"}
              />
              <CommonCheckBox
                planTimingsData={timesheet_data}
                header1="Invoice milage"
                header2=""
                total={""}
                name={"invoice_milage"}
              />
              <WorkTimings
                planTimingsData={timesheet_data}
                header1="Worksheet no."
                header2=""
                isDisable={true}
                type="text"
                name="worksheet_number"
              />
              <WorkTimings
                planTimingsData={timesheet_data}
                header1="Invoice no."
                header2=""
                isDisable={true}
                type="text"
                name={"invoice_no"}
              />
              <CommonCheckBox
                planTimingsData={timesheet_data}
                header1="Purchase invoice"
                header2=""
                name={"purchase_invoice"}
              />
              <ProjectParameters planTimingsData={timesheet_data} />
            </tbody>
          </table>
          <div className="d-flex justify-content-between position-sticky bottom-0">
            <button
              className="cursor-pointer sidebar-icon rounded-3"
              onClick={() => setIsFirstColumnOpen(!isFirstColumnOpen)}
            >
              {isFirstColumnOpen ? <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />}
            </button>
            <button
              className="cursor-pointer sidebar-icon rounded-3"
              onClick={() => setIsLastColumnOpen(!isLastColumnOpen)}
            >
              {!isLastColumnOpen ?  <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />}
            </button>
          </div>
        </div>
        {/* Last Column with Toggle */}
        {isLastColumnOpen && (
        <div className="col-2 h-100 overflow-auto">
            <div className="row h-100">
              <div className="col text-center p-0 sidebar_bg h-100 overflow-auto">
                <ProjectParameterHeader
                  headerColumns={initialProjectParameterState || {}}
                />
              </div>
            </div>
        </div>)}
        <div className=" d-flex justify-content-end ">
          {initialTimesheetModalState["invoice"].isOpen && (
            <Popup
              title={"Invoice"}
              body={
                <InvoiceModal
                  onClose={() => { handleModalDispatch("invoice")}}
                />
              }
              cancel={() => { handleModalDispatch("invoice")}}
              modalSize="lg"
              bodyclassName="text-start"
            />
          )}
          {initialTimesheetModalState["performance"].isOpen && (
            <Popup
              title={"Perfomance"}
              body={
                <PerformanceModal
                  onClose={() => { handleModalDispatch("performance")}}
                />
              }
              cancel={() => { handleModalDispatch("performance")}}
              modalSize="lg"
              bodyclassName="text-start"
            />
          )}
          {initialTimesheetModalState["reimbursement"].isOpen && (
            <Popup
              title={"Reimbursements"}
              body={
                <ReimbursementModal
                  onClose={() => { handleModalDispatch("reimbursement")}}
                />
              }
              cancel={() => { handleModalDispatch("reimbursement")}}
              modalSize="lg"
              bodyclassName="text-start"
            />
          )}
          {initialTimesheetModalState["invoicenow"]?.isOpen && (
            <Popup
              title={"Confirmation"}
              body="Do you want to invoise this now?"
              close={() => { handleModalDispatch("invoicenow")}}
              modalSize="lg"
              bodyclassName="text-start"
              yestext="Yes"
              notext="no"
              submit={() => saveTImesheet('yes')}
              cancel={() => saveTImesheet('no')}
            />
          )}
        </div>
      </div>
      <div className="my-2 d-flex justify-content-between">
        <div className="col-md-6 align-self-center mb-3">
            <Link to="/dashboard" className='text-uppercase back-btn text-decoration-underline'>Back</Link>
        </div>
        <Button
          title="Validate"
          type="submit"
          handleClick={() => (initialTimesheetState?.isInvoiced) ? saveTImesheet() : handleModalDispatch("invoicenow")}
          className="btn form-button  shadow-none text-uppercase px-3 search-btns"
        />
      </div>
    </div>
  );
}
