import React from "react";
import { EmployeeProvider } from "./timesheets-redux/context/EmployeeContext/EmployeeContext";
import PageLayout from "./timesheetsTemplate";
import TimesheetsOverview from "./organisms/timesheetsOverview";
import ProjectProvider from "./timesheets-redux/context/ProjectContext/ProjectContext";
import WeekAndDayProvider from "./timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import TimesheetProvider from "./timesheets-redux/context/TimesheetContext/TImesheetContex";
import ProjectParameterProvider from "./timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";
import TimeSheetModalProvider from "./timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import { FilterOptionsProvider } from "./timesheets-redux/context/FiltersContext/FiltersContext";

export default function Timesheets() {
  return (
    <WindowHeightComponent>
      <FilterOptionsProvider>
        <TimesheetProvider>
          <TimeSheetModalProvider>
            <WeekAndDayProvider>
              <ProjectProvider>
                <ProjectParameterProvider>
                  <EmployeeProvider>
                    <PageLayout>
                      <TimesheetsOverview />
                    </PageLayout>
                  </EmployeeProvider>
                </ProjectParameterProvider>
              </ProjectProvider>
            </WeekAndDayProvider>
          </TimeSheetModalProvider>
        </TimesheetProvider>
      </FilterOptionsProvider>
    </WindowHeightComponent>
  );
}
