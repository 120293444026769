import React, { useContext, createContext, useReducer } from "react";
import { timesheetReducer } from "../../reducers/TimesheetReducer/TimesheetReducer";


const TimesheetContext = createContext(null);
const TimesheetDispatchContext = createContext(null);

const TimesheetProvider = ({ children }) => {
    const [initialTimesheetState, timesheetDispatch] = useReducer(timesheetReducer, {})
    return (
        <TimesheetDispatchContext.Provider value={{ timesheetDispatch }}>
            <TimesheetContext.Provider value={{ initialTimesheetState }}>
                {children}
            </TimesheetContext.Provider>
        </TimesheetDispatchContext.Provider>
    )
}

export default TimesheetProvider

export const useTImesheetContext = () => {
    return useContext(TimesheetContext)
}

export const useTimesheetDispatchContext = () => {
    return useContext(TimesheetDispatchContext)
}

