import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from "react-signature-canvas";

interface SignatureModalProps {
  handleClose: () => void;
  handleClearSignature: () => void;
  handleSaveSignature: () => void;
  signatureRef: React.RefObject<SignatureCanvas>;
  signatureErrorMessage: string;
  signatureData?: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  handleClose,
  handleClearSignature,
  handleSaveSignature,
  signatureRef,
  signatureErrorMessage,
  signatureData,
}) => {
  
  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="bitter-italic-normal-medium-24">
          {t("Add Signature")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-8">
          {signatureData ? (              
             <img
                src={signatureData}
                alt="Signature"
               width="400"
                height="200"
                style={{ border: "1px solid #000" }}
              />
            ) : (
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: "signature-canvas",
                  style: { border: "1px solid #000" },
                }}
              />
            )}

          </div>
        </div>
        {signatureErrorMessage && (
                <p style={{ color: "red" }}>{signatureErrorMessage}</p>
              )}
        <div className="row mt-3 justify-content-between">
          <div className="col-md-4 text-end">
            <Button
              type="button"
              title={t("Clear Signature")}
              handleClick={handleClearSignature}
              className="btn form-button px-3 shadow-none text-uppercase"
            />
          </div>
          <div className="col-md-4 text-start">
            <Button
              type="button"
              title={t("Save Signature")}
              handleClick={handleSaveSignature}
              className="btn form-button px-3 shadow-none text-uppercase"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignatureModal;
