import { Option } from "utils/TypeAnnotations";
import React from "react";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";

interface dataProps {
  data: object[];
  pcChangeHandler: (value: any, type: string) => void;
  pcDeleteHandler: (value: any, type: string) => void;
  type: string;
}

const PCTable: React.FC<dataProps> = ({
  data,
  pcChangeHandler,
  type,
  pcDeleteHandler,
}) => {  
  return (
    <>
      {data && data.length > 0 && (
        <div className="col-lg-12">
          <table className="table table-hover general-agreement-table">
            <thead>
              <tr className="TableHeader">
                <th>Paritair comité {type}</th>
                <th>Employee types</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr key={index} className="border mb-3 box-shadow align-middle">
                  <td className="text-break" data-label="Pairtair comité">
                    {item.PC.label}
                  </td>
                  <td className="text-break" data-label="Employee types">
                    {item.employeeType.map((empType: any, index: number) =>
                      index === 0 ? empType.label : `, ${empType.label}`
                    )}
                  </td>
                  <td className="table-action-icons">
                    <button
                      type="button"
                      onClick={() => pcChangeHandler(index, type)}
                      className="bg-transparent border-0 shadow-none"
                      title="Edit"
                    >
                      <EditIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => pcDeleteHandler(index, type)}
                      className="bg-transparent border-0 shadow-none"
                      title="Delete"
                    >
                      {" "}
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PCTable;
