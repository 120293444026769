import {
    validateEmail,
    validateLinkedinUrl,
    validateMultiSelectField,
    validatePhoneNumber,
    validateRequired,
    validateSelectField,
    validateTextFieldAlpha,
    validateStartDate,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";


// GeneralTab
export const GeneralTabValidationRules: ValidationRules = {
    businessUnitNumber: [validateRequired, validateMultiSelectField],
    projectName: [validateRequired, validateTextFieldAlpha],
    projectCode: [validateRequired, validateTextFieldAlpha],
    projectType: [validateRequired, validateSelectField],
    company: [validateRequired, validateSelectField],
    locations: [validateRequired, validateMultiSelectField],
    startDate: [],
    endDate: [],
    info: [validateTextFieldAlpha],
    isActive: [],
};

export const GeneralTabSubformLocationRules: ValidationRules = {
    company: [validateRequired, validateSelectField],
    location: [validateRequired],
    phone: [validateRequired],
    street: [validateRequired],
    number: [validateRequired],
    // box: [],
    zipcode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
    // info: [],
};

// ContactsTab
export const ContactsTabValidationRules: ValidationRules = {
    selectedContactPersons: [validateRequired, validateMultiSelectField],
    timeSheet: [validateMultiSelectField, validateRequired],
    billing: [validateMultiSelectField, validateRequired],
    leave: [validateMultiSelectField, validateRequired],
    sickness: [validateMultiSelectField, validateRequired],
    holiday: [validateMultiSelectField, validateRequired],
    safety: [validateMultiSelectField, validateRequired],
}

export const ContactsTabSubformValidationRules: ValidationRules = {
    fName: [validateRequired, validateTextFieldAlpha],
    lName: [validateRequired, validateTextFieldAlpha],
    email: [validateRequired, validateEmail],
    company: [validateSelectField], // validateRequired
    mobNumber: [validatePhoneNumber],
    //   phNumber: [validatePhoneNumber],
    gender: [validateSelectField],
    //  language: [validateSelectField],
    // linkedIn: [validateLinkedinUrl],
    // roles: [validateMultiSelectField],
    // functionTitle: [validateSelectField],
    // locations: [validateMultiSelectField],
    //  info: [],
    selectedContactPersons: [validateRequired, validateMultiSelectField],
    timeSheet: [validateMultiSelectField, validateRequired],
    billing: [validateMultiSelectField, validateRequired],
    leave: [validateMultiSelectField, validateRequired],
    sickness: [validateMultiSelectField, validateRequired],
    holiday: [validateMultiSelectField, validateRequired],
    safety: [validateMultiSelectField, validateRequired],
};

//billing tab
export const BillingTabValidationRules: ValidationRules = {
    vatRate: [validateSelectField],
    paymentTerm: [validateSelectField],
    billingStatus: [validateSelectField]
};

export const ShiftsTabValidationRules: ValidationRules = {
    shifts: [validateMultiSelectField],
};

export const SequenceTabValidationRules: ValidationRules = {
    sequenceName: [validateRequired],
};

export const ProjectParametersTabValidationRules: ValidationRules = {
    templates: [validateSelectField],
    fromdate: [validateRequired],
};
