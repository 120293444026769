import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import LabelField from "components/atoms/LabelField";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface SurchargeMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SurchargeMolecule: React.FC<SurchargeMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
}) => {
  const renderField = () => {
    switch (field.field_name) {
      case "Fixed":
        return (
          <>
            <LabelField title={t("Regime")} />
            <LabelWithInputFieldAndUnit
              isMandatory={true}
              label={t(name)}
              value={value}
              handleChange={handleChange}
              type="text"
              isDisabled={disabled}
              placeholder={t(field?.field_name)}
              error={t(error)}
              unit={unit}
            />
          </>
        );
      case "Night":
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={true}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={t(error)}
            unit={unit}
          />
        );
    }
  };

  return <>{renderField()}</>;
};

export default SurchargeMolecule;
