import React from 'react';
import EmployeeBox from '../atoms/employeeBox';
import { useEmployee, useEmployeeDispatch } from '../planning-redux/context/EmployeeContext/EmployeeContext';

const PlanningEmployees = () =>  {
    const { initialEmployeeState } = useEmployee();
    const { dispatch } = useEmployeeDispatch();
  
    return (
        <>
            <table className='w-100 border-end'>
                <thead>
                    <tr className='border-0'>
                        <th className='py-3'>medweker</th>
                    </tr>
                </thead>
                <tbody>
                    { initialEmployeeState.employees && initialEmployeeState.employees.length > 0 && initialEmployeeState.employees.map(employee => (
                        <tr key={employee.id} className='border'>
                            <td style={{ height: "50px"}} >
                                {/* <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"white"} color={"black"} /> */}
                                <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"#e8f8fb"} color={"black"} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default PlanningEmployees;

// import React from 'react';
// import EmployeeBox from '../atoms/employeeBox';
// import { useEmployee, useEmployeeDispatch } from '../planning-redux/context/EmployeeContext/EmployeeContext';

// const PlanningEmployees = () =>  {
//     const { initialEmployeeState } = useEmployee();
//     const { dispatch } = useEmployeeDispatch();
  
//     const setEmployees = () => {
//         return Object.values(initialEmployeeState).map((employee) => (
//             <tr key={employee.id} className='border-0'>
//             <td style={{ height: "70px"}} >
//                 {/* <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"white"} color={"black"} /> */}
//                 <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"#e8f8fb"} color={"black"} />
//             </td>
//         </tr>
//         ));
            
//       };

//     return (
//         <>
//             <table className='w-100 border-end'>
//                 <thead>
//                     <tr className='border-0'>
//                         <th className='py-3'>Employees</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {initialEmployeeState && Object.keys(initialEmployeeState).length > 0 && 
//                     setEmployees()
//                     }
//                 </tbody>
//             </table>
//         </>
//     );
// }

// export default PlanningEmployees;

