import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface OvertimeMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  data: any
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const OvertimeMolecule: React.FC<OvertimeMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
  data }) => {
  const renderField = () => {
    switch (field.field_name) {
      case "Surcharge on overtime":
        return (
          <div className="mb-3">
            <LabelWithInputFieldAndUnit
              isMandatory={true}
              label={t(name)}
              value={value}
              handleChange={handleChange}
              type="text"
              isDisabled={disabled}
              placeholder={t(field?.field_name)}
              error={t(error)}
              unit={t("%")}
              containerClassName="col-12"
            />
          </div>
        );
      case "Weekly limit":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value === "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Day limit":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Exception friday":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value === "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "From":
        return (
          (
            <div className="col-4">
              {data?.[field?.field_id - 3]?.field_value == "1" && (
                <LabelWithInputFieldAndUnit
                  isMandatory={false}
                  label={t(field?.field_name)}
                  value={value}
                  handleChange={handleChange}
                  type={data?.[field?.field_id - 3]?.field_id == '17' ? "text" : "time"}
                  isDisabled={disabled}
                  placeholder={t(field?.field_name)}
                  error={t(error)}
                  containerClassName="w-100"
                />
              )}
            </div>
          )
        );
      case "Exception saturday":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Exception saturday & holidays":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Exception monday":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Exception tuesday":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Exception wednesday":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
      case "Exception thursday":
        return (
          <CheckBoxField
            label={t(field?.field_name)}
            name={field?.field_name}
            isChecked={field.field_value == "1"}
            onChangeHandler={handleChange}
            disable={disabled}
            className="col-4"
          />
        );
    }
  };

  return <>{renderField()}</>;
};

export default OvertimeMolecule;
