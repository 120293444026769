import React from "react";

const TickIcon: React.FC = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.392 11.022L11.71 5.624L10.93 5L7.248 9.601L4.64 7.428L4 8.196L7.392 11.022Z" fill="currentcolor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM14 7.5C14 11.0899 11.0899 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5Z" fill="currentcolor" />
        </svg>
    )

}
export default TickIcon