import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateNumber,
  validatePercentage,
  validateRequired,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { ApiCall } from "services/ApiServices";
import {
  CREATE_SALARY_BENEFITS,
  GET_SALARY_BENEFITS,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_SALARY_BENEFITS_MANAGE } from "constants/Paths";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Calender from "components/molecules/Calender";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputFieldAndUnit } from "../templates/atoms/LabelWithInputFieldAndUnit";
import { ValidationRules } from "utils/TypeAnnotations";

export const  occurenceFieldOptions = [
  { label: "Hour", value: 1 },
  { label: "Day", value: 2 },
  { label: "Month", value: 3 },
  { label: "Year", value: 4 },
  { label: "Manual benefit type", value: 5 }
];

function CreateSalaryBenefits() {
  const [formData, setFormData] = useState<any>({
    name: "",
    salary_benefit_type: null,
    salary_benefit_value: null,
    benefit_value: null,
    occurence: null,
    // start_date: null,
    is_mandatory: false,
    is_benefit_granted: false,
  });
  const { id } = useParams<{ id: string }>();
  const [showSalaryBenefitValues, setShowSalaryBenefitValues] = useState(true);
  const [unit, setUnit] = useState("€");
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({
    name: "",
    salary_benefit_type: "",
    salary_benefit_value: "",
    benefit_value: "",
    occurence: "",
    // start_date: "",
    is_mandatory: "",
    is_benefit_granted: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (id) {
      try {
        const editData = { id: id };
        const response = await ApiCall.service(
          GET_SALARY_BENEFITS,
          "POST",
          editData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          setFormData(response?.data);
          let data = response?.data;
          if (data.salary_benefit_type == 2) {
            setShowSalaryBenefitValues(false);
          }
          if (data.salary_benefit_value == 2) {
            setUnit("%");
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData: any) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    }
    validateInput(name, value);
  };

  const handleSelectChange = (selectedOption: Option, name: string) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: selectedOption }));
    if (selectedOption.name === "wagecode") {
      setShowSalaryBenefitValues(false);
    }
    if (selectedOption.name === "premium") {
      setShowSalaryBenefitValues(true);
    }
    if (selectedOption.name === "percentage") {
      setUnit("%");
    }
    if (selectedOption.name === "euro") {
      setUnit("€");
    }
    validateInput(name, selectedOption);
  };

  const validateInput = (
    name: string | any,
    value: string | boolean | any,
    isSingleFieldValidation: boolean = false
  ) => {
    let selectfields = ['occurence', 'salary_benefit_type', 'salary_benefit_value']
    let validationRules: ValidationRules = {
      name: [validateRequired],
      salary_benefit_type: [validateRequired],
      occurence: [validateRequired],
      // start_date: [validateRequired],
    };

    if (showSalaryBenefitValues) {
      validationRules = {
        ...validationRules,
        benefit_value: unit === '%' ? [validatePercentage] : [validateNumber],
      };
    }
    const validationErrors = validateForm(
      { ...formData, [name]: selectfields?.includes(name) ? (value?.value ?? value) : value },
      validationRules
    );

    if (!isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    let isFormValid = true;
    let errors = {
      name: validateInput("name", formData.name, true),
      salary_benefit_type: validateInput("salary_benefit_type", formData.salary_benefit_type, false),
      salary_benefit_value: validateInput("salary_benefit_value", formData.salary_benefit_value, false),
      occurence: validateInput("occurence", formData.occurence, false),
      // start_date: validateInput("start_date", formData.start_date, false),
    }
    isFormValid = !Object.values(errors).some((item: boolean) => !item);
    if (isFormValid) {
      try {
        const response = await ApiCall.service(
          CREATE_SALARY_BENEFITS,
          "POST",
          formData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: response?.message,
          });
          navigate(PATH_SALARY_BENEFITS_MANAGE);
        } else {
          console.error("Error occurred while submitting the form");
        }
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
      }
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Salary benefits",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">
              {id ? t("Edit salary benefit") : t("Create salary benefit")}
            </h1>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-border p-5">
            <div className="row">
              <div className="form-group col-12">
                <LabelWithInputField
                  isMandatory={true}
                  name="name"
                  handleChange={(e) => changeHandler(e)}
                  value={formData.name}
                  id="name"
                  label={t("Name")}
                  placeholder={t("Name")}
                  type="text"
                  error={errors.name}
                />
              </div>

              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Salary benefit types")}
                  name="salary_benefit_type"
                  id="salary_benefit_type"
                  isMandatory={true}
                  search={true}
                  options={[
                    { name: "premium", label: "Premium", value: "1" },
                    { name: "wagecode", label: "Wagecode", value: "2" },
                  ]}
                  placeHolder="Select"
                  value={formData.salary_benefit_type}
                  onChange={(e) => handleSelectChange(e, "salary_benefit_type")}
                  isMulti={false}
                  className="select-field"
                  containerClassName="col-md-12 mb-4 position-relative"
                  error={errors.salary_benefit_type}
                />
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  {showSalaryBenefitValues && (
                    <SelectWithSearch
                      title={t("Salary benefit value")}
                      name="salary_benefit_value"
                      id="salary_benefit_value"
                      isMandatory={true}
                      search={true}
                      options={[
                        { name: "euro", label: "Salary in euro", value: "1" },
                        {
                          name: "percentage",
                          label: "Salary in percentage",
                          value: "2",
                        },
                      ]}
                      placeHolder="Select"
                      value={formData.salary_benefit_value}
                      onChange={(e) =>
                        handleSelectChange(e, "salary_benefit_value")
                      }
                      isMulti={false}
                      className="select-field"
                      containerClassName="col-12 mb-4 position-relative"
                      error={errors.salary_benefit_value}
                    />
                  )}
                </div>

                <div className="form-group col-md-6">
                  {showSalaryBenefitValues && (
                    <LabelWithInputFieldAndUnit
                      label={t("Benefit value")}
                      labelClassName="invisible form-label field-label"
                      name="benefit_value"
                      id="benefit_value"
                      handleChange={(e) => changeHandler(e)}
                      value={formData.benefit_value}
                      placeholder={t("Select")}
                      type="text"
                      error={errors.benefit_value}
                      // unit="€"
                      unit={unit}
                      containerClassName="col-12 mb-4 position-relative"
                    />
                  )}
                </div>
              </div>

              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Occurence")}
                  name="occurence"
                  id="occurence"
                  isMandatory={true}
                  search={true}
                  options={occurenceFieldOptions}
                  placeHolder="Select"
                  value={formData.occurence}
                  onChange={(e) => handleSelectChange(e, "occurence")}
                  isMulti={false}
                  className="select-field"
                  error={errors.occurence}
                />
              </div>

              {/* <div className="form-group col-md-6">
                <Calender
                  isMandatory={true}
                  name="start_date"
                  onChange={(date: any) => {
                    handleSelectChange(date, "start_date");
                  }}
                  label="Start date"
                  minDate={new Date()}
                  error={errors.start_date}
                  selectedDate={formData.start_date}
                />
              </div> */}
              {/* <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Start date")}
                  name="start_date"
                  id="start_date"
                  isMandatory={true}
                  search={true}
                  options={DATE_OPTIONS}
                  placeHolder="Select"
                  value={formData.start_date}
                  onChange={(e) => handleSelectChange(e, "start_date")}
                  isMulti={false}
                  className="select-field"
                  error={errors.start_date}
                />
              </div> */}

              <div className="row">
                <div className="form-group col-md-6">
                  <CheckBoxField
                    name="is_mandatory"
                    label="Is this mandatory?"
                    isChecked={formData.is_mandatory}
                    id="is_mandatory"
                    onChangeHandler={changeHandler}
                  />
                </div>
                <div className="form-group col-md-6">
                  <CheckBoxField
                    name="is_benefit_granted"
                    label="Is the benfit granted in case of absence of the employee?"
                    isChecked={formData.is_benefit_granted}
                    id="is_benefit_granted"
                    onChangeHandler={changeHandler}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row my-3">
            <div className="col-md-12 text-lg-end text">
              <Button
                title={t("Save")}
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 button-widths"
              />
            </div>
          </div>
        </form>

        <div className="d-flex justify-content-between my-3 align-items-center">
          <div className="">
            <Link
              to={PATH_SALARY_BENEFITS_MANAGE}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
      </>
    </AccessControl>
  );
}
export default CreateSalaryBenefits;
