import { Fragment } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";

import CloseFile from "static/images/CloseFile";
import { Link } from "react-router-dom";
import RightArrow from "static/images/RightArrow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FollowUpData } from "./TodoDashboard";


const FollowUpTable: React.FC<{ followUps: FollowUpData[], handleFollowUpClose: (id: string) => void }> = ({
  followUps, handleFollowUpClose
}) => {
  return <>
    <div className="dashboard-work-table">
      <table className="table table-borderless">
        <tbody>
          <>
            {followUps && followUps.length > 0 ? (
              followUps.map((followUp, index) => (
                <Fragment key={`follow-up-${index}`}>
                  <tr
                    key={`follow-up-${index}`}
                    className="mb-2 box-shadow align-middle"
                  >
                    <td
                      className="ps-lg-4 text-truncate w-25"
                      data-label={t("BusinessUnit")}
                    >
                      {followUp?.businessUnit}
                    </td>
                    <td
                      className="ps-lg-4 text-truncate w-25"
                      data-label={t("Follow-up for")}
                    >
                      {followUp?.followUpFor}
                    </td>
                    <td
                      className="ps-lg-4"
                      data-label={t("Follow-up type")}
                    >
                      {followUp?.taskType}
                    </td>
                    <td
                      className="ps-lg-4"
                      data-label={t("Date")}
                    >
                      {followUp?.date}
                    </td>
                    <td className="table-action-icons">
                      {followUp?.status !== 1 &&
                        <span
                          className="p-0 border-0 me-2 cursor-pointer"
                          title={t("Close")}
                          onClick={() => handleFollowUpClose(followUp?.todoId)}
                        >
                          <CloseFile />
                        </span>
                      }
                      <Link key={`link2${index}`}
                        to={`/todo/create/${followUp?.todoId}?destination=/dashboard`}
                        state={{ origin: "dashboard" }}
                        title={t("View")}
                      >
                        <RightArrow width={40} height={40} />
                      </Link>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr className="border rounded-3">
                <td
                  colSpan={4}
                  className="border-0 text-center py-3 no-records"
                >
                  <span className="text-danger w-100 d-block text-center">
                    {
                      <>
                        {t("No assigned follow-ups for you")}
                      </>
                    }
                  </span>
                </td>
              </tr>)
            }
          </>
        </tbody>
      </table>
    </div>
    <Link
      to={"/todo"}
      className="link-btn text-decoration-none p-2 ms-lg-4 rounded-3 light-button "
    >
      {t("Overview")} <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
    </Link>
  </>
}
export default FollowUpTable;