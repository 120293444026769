import { ITagAtomProps } from "components/atoms/TagAtom";
import {
  SWITCH_TAB,
  SET_OPTIONS,
  SHIFT_UPDATE_FIELD,
  ADD_CONTACT,
  REMOVE_CONTACT,
  UPDATE_SELECTED_CONTACT_FIELD,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_CURRENT_TAB,
  UPDATE_RESPONSIBLE_PERSON_FIELD,
  SET_FIELD_GENERAL_TAB,
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_BILLING_FIELD,
  SET_CREATE_LOCATION_GENERAL_TAB,
  SET_ERROR_SUBFIELD_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_SHIFT_TIME,
  UPDATE_SELECTED_CONTACT_TAG_EDIT,
  PROJECT_OVERVIEW,
  UPDATE_FIELD_ERROR,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB,
  UPDATE_ERRORS_BILLING_TAB,
  UPDATE_ERRORS_SHIFT_TAB,
  UPDATE_ERRORS_PARAMETER_TAB,
  INITIAL_STATE,
  SIGNATURE_IMAGE,
  SET_SEQUENCE_NAME,
  ADD_ANOTHER_SEQUENCE,
  SET_WEEKLY_SHIFTS,
  EDIT_SEQUENCE,
  DELETE_SEQUENCE,
  UPDATE_ERRORS_SEQUENCE_TAB,
} from "pages/microservices/project/context/Constants";
import { isValidTime } from "services/validation/ValidationService";
import { Option } from "components/common/CommonInterfaces";
import {
  CoeffObject,
  ContactPersonErrors,
  ContactPersonsData,
  ContactsPersons,
  LocationTabData,
} from "./Interface";
import {
  ADD_BLUE_COLLAR_FIELD,
  ADD_EMPLOYEE_TYPE,
  ADD_FUNCTION_PROFILE,
  ADD_WHITECOLLAR_FIELD,
  CLONE_FUNCTION_TYPE,
  DELETE_EMPLOYEE_TYPE,
  DELETE_FUNCTION_TYPE,
  REMOVE_BLUE_PC,
  REMOVE_WHITE_PC,
  SET_BLUE_COLLAR,
  SET_COEFFICIENT_DATA,
  SET_COMPOSITION_DATA,
  SET_ERRORS_ADDRESS_TAB,
  SET_ERRORS_LOCATION_TAB,
  SET_FIELD_ERROR_ADDRESS_TAB,
  SET_FIELD_INVOICE_TAB,
  SET_PC_TABLE_DATA,
  SET_WHITE_COLLAR,
  UPDATE_COEFFCIENT_TAB_FIELD,
  UPDATE_EMPLOYEE_TYPE_PC,
  UPDATE_SPECIAL_AGREEMENT_FIELD,
  UPDATE_WHITE_COLLOR_FIELD_ERROR,
} from "./Constants";

type TempAggencyReducerProps = {
  state: any;
  action: any;
};

const TempAgencyReducer = (
  state: TempAggencyReducerProps["state"],
  action: TempAggencyReducerProps["action"]
) => {
  switch (action.type) {
    case SWITCH_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SET_OPTIONS:
      const options = { ...state.options };
      options[action.field] = action.value;
      return { ...state, options };

    // Contact Tab
    case ADD_CONTACT:
      // Add a new contact to the contactsPerson data array
      const newContact: ContactsPersons = {
        id: "",
        fName: "",
        lName: "",
        email: "",
        company: null,
        mobNumber: "",
        phNumber: "",
        functionTitle: "",
        language: "",
        info: "",
        locations: [],
        linkedIn: "",
        gender: null,
        roles: [],
      };
      // Add a new contact error to the contactsPerson errors array
      const newError: ContactPersonErrors = {
        fName: "",
        lName: "",
        email: "",
        company: "",
        mobNumber: "",
        phNumber: "",
        functionTitle: "",
        language: "",
        info: "",
        locations: [],
        linkedIn: "",
        gender: "",
        roles: [],
      };
      // Create a new array by spreading the existing contacts and adding the new contact
      const updatedContact = [
        ...state.contactsPersons.data.contactsPersons,
        newContact,
      ];
      // Create a new array by spreading the existing errors and adding the new error
      const updatedErrors = [
        ...state.contactsPersons.errors.contactsPersons,
        newError,
      ];

      // Create a new object for contactsPersons to maintain immutability
      const updatedContactsPersons = {
        ...state,
        data: {
          ...state.contactsPersons.data,
          selectedContactPersons:
            state.contactsPersons.data.selectedContactPersons,
          selectedContactsTagList:
            state.contactsPersons.data.selectedContactsTagList,
          contactSubformIsVisible:
            state.contactsPersons.data.contactSubformIsVisible,
          contactsPersons: updatedContact,
        },
        errors: {
          ...state.contactsPersons.errors,
          selectedContactPersons:
            state.contactsPersons.errors.selectedContactPersons,
          selectedContactsTagList:
            state.contactsPersons.errors.selectedContactsTagList,
          contactsPersons: updatedErrors,
        },
      };
      return { ...state, contactsPersons: updatedContactsPersons };

    case REMOVE_CONTACT:
      const contactPersons = state.contactsPersons;

      const updatedContacts = [...contactPersons.data.contactsPersons];
      updatedContacts.splice(action.indexToRemove, 1);

      const updatedContactErrors = [...contactPersons.errors.contactsPersons];
      updatedContactErrors.splice(action.indexToRemove, 1);

      const updatedContactsWithPersonsRemoved = {
        ...state,
        contactsPersons: {
          data: {
            ...state.contactsPersons.data,
            selectedContactPersons:
              state.contactsPersons.data.selectedContactPersons,
            selectedContactsTagList:
              state.contactsPersons.data.selectedContactsTagList,
            contactSubformIsVisible:
              state.contactsPersons.data.contactSubformIsVisible,
            contactsPersons: updatedContacts,
          },
          errors: {
            ...state.contactsPersons.errors,
            selectedContactPersons:
              state.contactsPersons.errors.selectedContactPersons,
            selectedContactsTagList:
              state.contactsPersons.errors.selectedContactsTagList,
            contactsPersons: updatedContactErrors,
          },
        },
      };

      return updatedContactsWithPersonsRemoved;

    case UPDATE_SELECTED_CONTACT_FIELD:
      let updatedSelectedContactTabData = state.contactsPersons.data;
      updatedSelectedContactTabData[action.field] = action.value;

      const updatedSelectedContactTabDataWithSelection = {
        ...state,
        contactsPersons: {
          data: updatedSelectedContactTabData,
          errors: state.contactsPersons.errors,
        },
      };

      return updatedSelectedContactTabDataWithSelection;

    case UPDATE_SELECTED_CONTACT_TAG_EDIT:
      // todo: currently unused, update code after, confirmation!

      let updatedSelectedTagListContactTabData: ContactPersonsData =
        state.contactsPersons.data;

      updatedSelectedTagListContactTabData.selectedContactsTagList[
        action.index
      ].isSelected = action.tagIsSelected;

      // Open subform
      if (!updatedSelectedTagListContactTabData.contactSubformIsVisible) {
        updatedSelectedTagListContactTabData.contactSubformIsVisible = true;
      }

      // Update subform
      const newContactRowVal = {
        id: 2,
        fName: "Matt",
        lName: "Smith",
        email: "matt.smith@example.com",
        company: null,
        mobNumber: "+44123456789",
        phNumber: "+44123456789",
        gender: null,
        language: "ENG",
        linkedIn: "linkedin link",
        roles: [],
        functionTitle: "Actor",
        locations: [],
        info: "",
        isNew: false,
      };

      let subformContainsNewContactRow =
        updatedSelectedTagListContactTabData.contactsPersons.filter(function (
          newContactRow: ContactsPersons
        ) {
          return newContactRowVal.id === newContactRow.id;
        }).length;

      if (!subformContainsNewContactRow) {
        updatedSelectedTagListContactTabData.contactsPersons.unshift(
          newContactRowVal
        );
      }

      const updatedSelectedTagListContactTabInfo = {
        ...state,
        contactsPersons: {
          data: updatedSelectedTagListContactTabData,
          errors: state.contactsPersons.errors,
        },
      };
      return updatedSelectedTagListContactTabInfo;

    case UPDATE_CONTACT_SUBFORM_FIELD:
      let updatedSubformContactPersonData =
        state.contactsPersons.data.contactsPersons.map(
          (contact: any, index: any) => {
            if (index === action.index) {
              const updatedContact = {
                ...contact,
                [action.field]: action.value,
              };
              return updatedContact;
            }
            return contact;
          }
        );

      const updatedSubformContactPersonDataState = {
        ...state,
        contactsPersons: {
          data: {
            ...state.contactsPersons.data,
            selectedContactPersons:
              state.contactsPersons.data.selectedContactPersons,
            selectedContactsTagList:
              state.contactsPersons.data.selectedContactsTagList,
            contactSubformIsVisible:
              state.contactsPersons.data.contactSubformIsVisible,
            contactsPersons: updatedSubformContactPersonData,
          },
          errors: state.contactsPersons.errors,
        },
      };
      return updatedSubformContactPersonDataState;

    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };

    // General tab
    case SET_FIELD_GENERAL_TAB:
      let updatedGeneralTabData = state.general.data;
      updatedGeneralTabData[action.field] = action.value;

      const updatedGeneralTabWithData = {
        ...state,
        general: {
          data: updatedGeneralTabData,
          errors: state.general.errors,
        },
      };
      return updatedGeneralTabWithData;

    case SET_FIELD_ERROR_GENERAL_TAB:
      let updatedGeneralTabErrors = state.general.errors;
      updatedGeneralTabErrors[action.fieldName] = action.error;

      const updatedGeneralTabWithFieldError = {
        ...state,
        general: {
          data: state.general.data,
          errors: updatedGeneralTabErrors,
        },
      };
      return updatedGeneralTabWithFieldError;

    case SET_ERRORS_GENERAL_TAB:
      const updatedGeneralTabWithErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: action.errors,
        },
      };
      return updatedGeneralTabWithErrors;

    case SET_ERROR_SUBFIELD_GENERAL_TAB:
      let generalTabErrors = state.general.errors;
      generalTabErrors.createLocation[action.field] = action.error;

      const updatedWithCreateLocationErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: generalTabErrors,
        },
      };
      return updatedWithCreateLocationErrors;
    // Overall tab related
    case UPDATE_TAB_DATA:
      const tabData = state[action.field];
      tabData.data = action.value;
      return { ...state, [action.field]: tabData };

    /**
     * Project parameter state update
     */
    // case UPDATE_EMPLOYEE_TYPE_PC:
    //   const { whiteCollar, blueCollar } = action.value;

    //   if (action.field === "employeePc") {
    //     console.log(whiteCollar);
    //     return {
    //       ...state,
    //       employeeCoefficient: {
    //         ...state.employeeCoefficient,
    //         blueCollar: blueCollar || [],
    //         whiteCollar: whiteCollar || [],
    //       },
    //     };
    //   }
    //   return state;
    // return {
    //   ...state,
    //   employeeCoefficient: {
    //     ...state.employeeCoefficient,
    //     whiteCollar: [whiteCollar],
    //     blueCollar: [blueCollar],
    //   },
    // };

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabs: action.tabs,
      };

    case UPDATE_FIELD_ERROR:
      //* used from https://git.infanion.com/i-projects/maxicon-frontend/-/commit/a8730988ddac2d576c298684a898659ed7456978
      const tabId = action.tab;
      const tabState = state[tabId];
      const updatedTabState = {
        ...tabState,
        errors: {
          ...tabState.errors,
          [action.fieldName]: action.error,
        },
      };

      return {
        ...state,
        [tabId]: updatedTabState,
      };

    case "UPDATE_TAB_ERROR":
      return {
        ...state,
        tabs: state.tabs.map((tab: any, index: any) => ({
          ...tab,
          error: index === action.tabIndex ? action.error : tab.error,
          draft: action.draft,
        })),
      };

    case UPDATE_ERRORS_CONTACTPERSONS_TAB:
      const updatedContactPersonsTabWithErrors = {
        ...state,
        contactsPersons: {
          data: state.contactsPersons.data,
          errors: action.errors,
        },
      };

      return updatedContactPersonsTabWithErrors;

    case UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB:
      const updatedRespPersonsTabWithErrors = {
        ...state,
        responsiblePerson: {
          data: state.responsiblePerson.data,
          errors: action.errors,
        },
      };
      return updatedRespPersonsTabWithErrors;

    case INITIAL_STATE:
      return { ...JSON.parse(JSON.stringify(action.payload)) };

    case SIGNATURE_IMAGE:
      return {
        ...state,
        signatureImage: action.payload.value,
      };
    case "ADD_LOCATION":
      // Create a new empty location object based on the LocationTabData interface
      const emptyLocation: LocationTabData = {
        locationName: "",
        locationEmail: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCity: "",
        locationCountry: [],
      };

      return {
        ...state,
        location: {
          data: [...state.location.data, emptyLocation],
          errors: [
            ...state.location.errors,
            {
              locationNameErrors: "",
              locationEmailErrors: "",
              locationStreetErrors: "",
              locationNumberErrors: "",
              locationBoxErrors: "",
              locationZipCodeErrors: "",
              locationCityErrors: "",
              locationCountryErrors: "",
            },
          ],
        },
      };

    case "REMOVE_LOCATION":
      const { indexToRemove } = action;
      // Remove the location at the specified index
      const newLocationArray = [...state.location.data];
      const newLocationErrors = [...state.location.errors];
      newLocationArray.splice(indexToRemove, 1);
      newLocationErrors.splice(indexToRemove, 1);

      return {
        ...state,
        location: {
          data: newLocationArray,
          errors: newLocationErrors,
        },
      };

    case "UPDATE_ADDRESS_FIELD":
      return {
        ...state,
        address: {
          ...state.address, // Ensure you spread the existing `address` state
          data: {
            ...state.address.data, // Only update the `data` part
            [action.field]: action.value,
          },
        },
      };
    case SET_FIELD_ERROR_ADDRESS_TAB:
      let updatedAddressTabErrors = state.address.errors;
      updatedAddressTabErrors[action.fieldName] = action.error;

      const updatedAddressTabWithFieldError = {
        ...state,
        addressl: {
          data: state.address.data,
          errors: updatedAddressTabErrors,
        },
      };
      return updatedAddressTabWithFieldError;
    case SET_ERRORS_ADDRESS_TAB:
      const updatedAddressTabWithErrors = {
        ...state,
        address: {
          data: state.address.data,
          errors: action.errors,
        },
      };
      return updatedAddressTabWithErrors;

    case "UPDATE_LOCATION_FIELD":
      const { field, index, value } = action;
      const updatedData = state.location.data.map((location: any, idx: any) =>
        idx === index ? { ...location, [field]: value } : location
      );

      return {
        ...state,
        location: {
          ...state.location,
          data: updatedData,
        },
      };
    case SET_ERRORS_LOCATION_TAB:
      return {
        ...state,
        location: {
          ...state.location,
          errors: action.errors,
        },
      };
    case "SET_FIELD_ERROR_LOCATION_TAB":
      return {
        ...state,
        location: {
          ...state.location,
          errors: state.location.errors.map((error: any, index: any) =>
            index === action.index
              ? { ...error, [action.field]: action.error }
              : error
          ),
        },
      };
    case SET_FIELD_INVOICE_TAB:
      return {
        ...state,
        inVoice: {
          ...state.inVoice, // Ensure you spread the existing `address` state
          data: {
            ...state.inVoice.data, // Only update the `data` part
            [action.field]: action.value,
          },
        },
      };
    case SET_COMPOSITION_DATA:
      return {
        ...state,
        wageElement: action.value.wage_elements,
      };
    case UPDATE_SPECIAL_AGREEMENT_FIELD:
      return {
        ...state,
        wageElement: state.wageElement.map((item: any) => {
          if (item.wageId === action.index) {
            return {
              ...item,
              [action.field]: action.value,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_BLUE_COLLAR:
      return {
        ...state,
        employeeCoefficient: {
          ...state.employeeCoefficient,
          blueCollar: {
            ...state.employeeCoefficient.blueCollar,
            [action.field]: action.value,
          },
        },
      };

    case SET_WHITE_COLLAR:
      return {
        ...state,
        employeeCoefficient: {
          ...state.employeeCoefficient,
          whiteCollar: {
            ...state.employeeCoefficient.whiteCollar,
            [action.field]: action.value,
          },
        },
      };
    case UPDATE_WHITE_COLLOR_FIELD_ERROR:
      return {
        ...state,
        employeeCoefficient: {
          ...state.employeeCoefficient,
          errors: {
            ...state.employeeCoefficient.errors,
            [action.field]: action.error,
          },
        },
      };
    case SET_PC_TABLE_DATA: {
      if (action.field === "companyParitairCommittee") {
        const newBlueCollar = action.value.blueCollar || [];
        const newWhiteCollar = action.value.whiteCollar || [];

        // Handle cases where newBlueCollar or newWhiteCollar are empty
        const updatedBlueCollar =
          newBlueCollar.length > 0
            ? [
                // Filter out duplicates from the existing blueCollar data
                ...(state.employeeCoefficient.blueCollar || []).filter(
                  (item: { id: any }) =>
                    !newBlueCollar.some(
                      (newItem: { id: any }) => newItem.id === item.id
                    )
                ),
                ...newBlueCollar,
              ]
            : []; // Set to empty if newBlueCollar is empty

        const updatedWhiteCollar =
          newWhiteCollar.length > 0
            ? [
                // Filter out duplicates from the existing whiteCollar data
                ...(state.employeeCoefficient.whiteCollar || []).filter(
                  (item: { id: any }) =>
                    !newWhiteCollar.some(
                      (newItem: { id: any }) => newItem.id === item.id
                    )
                ),
                ...newWhiteCollar,
              ]
            : [];
        return {
          ...state,
          employeeCoefficient: {
            ...state.employeeCoefficient,
            blueCollar: updatedBlueCollar,
            whiteCollar: updatedWhiteCollar,
          },
        };
      }

      return state;
    }

    case UPDATE_EMPLOYEE_TYPE_PC:
      const { whiteCollar, blueCollar } = action.value;
      if (action.field === "employeePc") {
        return {
          ...state,
          employeeCoefficient: {
            ...state.employeeCoefficient,
            blueCollar: [
              ...(state.employeeCoefficient.blueCollar || []).filter(
                (item: { id: any }) =>
                  !blueCollar.some(
                    (newItem: { id: any }) => newItem.id === item.id
                  )
              ),
              ...(blueCollar || []),
            ],
            whiteCollar: [
              ...(state.employeeCoefficient.whiteCollar || []).filter(
                (item: { id: any }) =>
                  !whiteCollar.some(
                    (newItem: { id: any }) => newItem.id === item.id
                  )
              ),
              ...(whiteCollar || []),
            ],
          },
        };
      }
      return state;

    case ADD_WHITECOLLAR_FIELD:
      const pcId = state.employeeCoefficient.whiteCollar.findIndex(
        (item: any) => item?.pc?.value === action.newData?.pc?.value
      );

      if (pcId !== -1) {
        // If found, update the existing item
        return {
          ...state,
          employeeCoefficient: {
            ...state.employeeCoefficient,
            whiteCollar: state.employeeCoefficient.whiteCollar.map(
              (item: any, index: number) =>
                index === pcId ? action.newData : item
            ),
          },
        };
      } else {
        // If not found, add `newData` as a new item
        return {
          ...state,
          employeeCoefficient: {
            ...state.employeeCoefficient,
            whiteCollar: [
              ...state.employeeCoefficient.whiteCollar,
              action.newData,
            ],
          },
        };
      }

    case ADD_BLUE_COLLAR_FIELD:
      const pcsId = state.employeeCoefficient.blueCollar.findIndex(
        (item: any) => item?.pc?.value === action.newData?.pc?.value
      );

      if (pcsId !== -1) {
        // If found, update the existing item
        return {
          ...state,
          employeeCoefficient: {
            ...state.employeeCoefficient,
            blueCollar: state.employeeCoefficient.blueCollar.map(
              (item: any, index: number) =>
                index === pcsId ? action.newData : item
            ),
          },
        };
      } else {
        // If not found, add `newData` as a new item
        return {
          ...state,
          employeeCoefficient: {
            ...state.employeeCoefficient,
            blueCollar: [
              ...state.employeeCoefficient.blueCollar,
              action.newData,
            ],
          },
        };
      }

    case REMOVE_WHITE_PC:
      // Update the whiteCollar array by removing the item at the specified index
      const updatedWhiteCollar = [...state.employeeCoefficient.whiteCollar];
      const removedWhiteCollarItem = updatedWhiteCollar.splice(
        action.indexToRemove,
        1
      )[0];

      // Filter the coefficient array by removing the item that matches the removed whiteCollar item's pc and employeeType
      const updatedCoefficient = state.coefficient.filter(
        (coefficientItem: any) => {
          return !(
            coefficientItem.pc.value === removedWhiteCollarItem.pc.value &&
            coefficientItem.functions.some((func: any) =>
              func.employeeTypes.some((empType: any) =>
                removedWhiteCollarItem.employeeType.some(
                  (removedEmpType: any) =>
                    removedEmpType.value === empType.empType[0].value
                )
              )
            )
          );
        }
      );

      return {
        ...state,
        employeeCoefficient: {
          ...state.employeeCoefficient,
          whiteCollar: updatedWhiteCollar,
        },
        coefficient: updatedCoefficient,
      };

    case REMOVE_BLUE_PC:
      // Update the blueCollar array by removing the item at the specified index
      const updatedBlueCollar = [...state.employeeCoefficient.blueCollar];
      const removedBlueCollarItem = updatedBlueCollar.splice(
        action.indexToRemove,
        1
      )[0];

      // Filter the coefficient array by removing the item that matches the removed blueCollar item's pc and employeeType
      const updatedBlueCoefficient = state.coefficient.filter(
        (coefficientItem: any) => {
          return !(
            coefficientItem.pc.value === removedBlueCollarItem.pc.value &&
            coefficientItem.functions.some((func: any) =>
              func.employeeTypes.some((empType: any) =>
                removedBlueCollarItem.employeeType.some(
                  (removedEmpType: any) =>
                    removedEmpType.value === empType.empType[0].value
                )
              )
            )
          );
        }
      );

      return {
        ...state,
        employeeCoefficient: {
          ...state.employeeCoefficient,
          blueCollar: updatedBlueCollar,
        },
        coefficient: updatedBlueCoefficient,
      };

    case SET_COEFFICIENT_DATA:
      if (action.field === "companyParitairCommittee") {
        const data = action.value;
        const combinedMap: { [key: number]: any } = {};
        const mergeData = (data: any[]) => {
          data.forEach(({ pc, employeeType, pcFunctions }) => {
            if (!combinedMap[pc.id]) {
              combinedMap[pc.id] = { pc, employeeType, pcFunctions };
            } else {
              combinedMap[pc.id].employeeType = [
                ...combinedMap[pc.id].employeeType,
                ...employeeType,
              ];
              combinedMap[pc.id].pcFunctions = [
                ...combinedMap[pc.id].pcFunctions,
                ...pcFunctions,
              ];
            }
          });
        };

        mergeData(data);

        const coefficientObject: CoeffObject = {};
        state.options.coefficient.forEach((item: any) => {
          const coefficientName: string = item.name
            .replace(/\s+/g, "_")
            .toLowerCase();
          coefficientObject[coefficientName] = "";
        });

        // Map combined data to coefficientItems with default values
        const coefficientItems = Object.values(combinedMap).map(
          ({ pc, employeeType, pcFunctions }) => ({
            pc,
            empTypeDropdownValues: employeeType,
            functionDropdownValues: pcFunctions,
            functions: [
              {
                function: null, // Set default function value
                employeeTypes: [
                  {
                    empType: null,
                    empTypeErrorStatus: false,
                    level: null,
                    startDate: new Date(),
                    coefficients: [coefficientObject],
                  },
                ],
              },
            ],
          })
        );

        return {
          ...state,
          coefficient: [...state.coefficient, ...coefficientItems],
        };
      }
      return state;
    case ADD_EMPLOYEE_TYPE:
      const empCoefficientObject: CoeffObject = {};
      state.options.coefficient.forEach((item: any) => {
        const coefficientName: string = item.name
          .replace(/\s+/g, "_")
          .toLowerCase();
        empCoefficientObject[coefficientName] = "";
      });
      const { pcIndex, funcIndex } = action;
      const newFields = {
        empType: null,
        empTypeErrorStatus: false,
        level: null,
        startDate: new Date(),
        coefficients: [empCoefficientObject],
      };

      return {
        ...state,
        coefficient: state.coefficient.map((pc: any, i: number) => {
          if (i === pcIndex) {
            return {
              ...pc,
              functions: pc.functions.map((func: any, j: number) => {
                if (j === funcIndex) {
                  return {
                    ...func,
                    employeeTypes: [...func.employeeTypes, newFields],
                  };
                }
                return func;
              }),
            };
          }
          return pc;
        }),
      };
    case DELETE_EMPLOYEE_TYPE:
      return {
        ...state,
        coefficient: state.coefficient.map((pc: any, i: number) => {
          if (i === action.pcIndex) {
            return {
              ...pc,
              functions: pc.functions.map((func: any, j: number) => {
                if (j === action.funcIndex) {
                  return {
                    ...func,
                    employeeTypes: func.employeeTypes.filter(
                      (emplType: any, k: number) => k !== action.empRowIndex
                    ),
                  };
                }
                return func;
              }),
            };
          }
          return pc;
        }),
      };
    case ADD_FUNCTION_PROFILE:
      const funcCoefficientObject: CoeffObject = {};
      state.options.coefficient.forEach((item: any) => {
        const coefficientName: string = item.name
          .replace(/\s+/g, "_")
          .toLowerCase();
        funcCoefficientObject[coefficientName] = "";
      });
      const newFunctionFields = {
        function: null,
        employeeTypes: [
          {
            empType: null,
            empTypeErrorStatus: false,
            level: null,
            coefficients: [funcCoefficientObject],
          },
        ],
      };
      return {
        ...state,
        coefficient: state.coefficient.map((pc: any, i: any) => {
          if (i === action.pcIndex) {
            return {
              ...pc,
              functions: [...pc.functions, newFunctionFields],
            };
          }
          return pc;
        }),
      };
    case DELETE_FUNCTION_TYPE:
      return {
        ...state,
        coefficient: state.coefficient.map((pc: any, i: number) => {
          if (i === action.pcIndex) {
            const updatedFunctions = pc.functions.filter(
              (func: any, j: number) => j !== action.funcIndex
            );

            return {
              ...pc,
              functions: updatedFunctions,
            };
          }
          return pc;
        }),
      };
    case CLONE_FUNCTION_TYPE:
      const clonedFunction = {
        ...state.coefficient[action.pcIndex].functions[action.funcIndex],
        function: null,
      };

      return {
        ...state,
        coefficient: state.coefficient.map((pc: any, i: number) => {
          if (i === action.pcIndex) {
            return {
              ...pc,
              functions: [...pc.functions, clonedFunction],
            };
          }
          return pc;
        }),
      };
    case UPDATE_COEFFCIENT_TAB_FIELD:
      const { coeffield, coeffValue, empRowIndex, coeffIndex } = action;
      return {
        ...state,
        coefficient: state.coefficient.map((item: any, i: number) => {
          if (i === action.pcIndex) {
            return {
              ...item,
              functions: item.functions.map((func: any, j: number) => {
                if (j === action.funcIndex) {
                  let updatedFunc = { ...func };
                  if (
                    coeffield === "function" ||
                    coeffield === "funcErrorStatus" ||
                    coeffield === "funcChanged"
                  ) {
                    updatedFunc = {
                      ...updatedFunc,
                      [coeffield]: coeffValue,
                    };
                  }
                  // Update the coeffield in each employeeType
                  const updatedEmployeeTypes = func.employeeTypes.map(
                    (empRow: any, k: number) => {
                      if (k === empRowIndex) {
                        let updatedEmp = { ...empRow };
                        if (
                          coeffield === "empType" ||
                          coeffield === "empTypeErrorStatus" ||
                          coeffield === "level" ||
                          coeffield === "levelErrorStatus" ||
                          coeffield === "startDate" ||
                          coeffield === "empTypeChanged" ||
                          coeffield === "levelChanged" ||
                          coeffield === "startDateChanged"
                        ) {
                          updatedEmp = {
                            ...updatedEmp,
                            [coeffield]: coeffValue as Option | null | boolean,
                          };
                        }
                        return {
                          ...updatedEmp,
                          coefficients: empRow.coefficients.map(
                            (coeff: any, l: number) => {
                              if (l === coeffIndex) {
                                return {
                                  ...coeff,
                                  [coeffield]: coeffValue,
                                };
                              }
                              return coeff;
                            }
                          ),
                        };
                      }
                      return empRow;
                    }
                  );

                  // Return the updated function with updated employeeTypes
                  return {
                    ...updatedFunc,
                    employeeTypes: updatedEmployeeTypes,
                  };
                }
                return func;
              }),
            };
          }
          return item;
        }),
      };

    default:
      return state;
  }
};

export default TempAgencyReducer;
