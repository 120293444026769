import React, { ChangeEvent } from "react";
import LabelField from "../atoms/LabelField";
import Search from "../atoms/Search";

export interface SearchWithLabelProps {
  label?: string;
  isMandatory?: boolean;
  showLabel?: boolean;
  name: string;
  type: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  handleChange: (event: ChangeEvent<any>) => void; // Use ChangeEvent<any>
  className?: string;
  rows?: number | undefined;
}

const SearchWithLabel: React.FC<SearchWithLabelProps> = ({
  label = "",
  isMandatory = false,
  showLabel = false,
  name = "",
  type = "text",
  value = "",
  placeholder = "",
  isDisabled = false,
  handleChange,
  className = "",
}) => {
  return (
    <>
      <div className={`${className ? className : "col-3"} shadow-none`}>
        {showLabel && (
          <>
            <LabelField title={label ?? name} isMandatory={isMandatory} />
          </>
        )}
        <>
          <Search
            name={name}
            value={value}
            placeholder={placeholder}
            handleChange={handleChange}
          />
        </>
      </div>
    </>
  );
};

export default SearchWithLabel;
