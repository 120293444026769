import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "../translation/Translation";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { CONFIG_HEADERS } from "TableHeader";
import TableActions from "components/organism/Actions/TableAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PATH_CONFIG_ELEMENTS_CREATE, PATH_MASTER_DATA } from "constants/Paths";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  GET_MASTER_DATA_CONFIG_ELEMENT_LIST,
  MASTER_DATA_CONFIG_ELEMENT_ARCHIVE,
  MASTER_DATA_CONFIG_ELEMENT_DATA,
  MASTER_DATA_CONFIG_ELEMENT_UNARCHIVE,
} from "routes/ApiEndpoints";
import { Option } from "react-multi-select-component";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import HelpInfoIcon from "static/images/HelpIcon";
import CustomNotify from "components/atoms/CustomNotify";

const ManageMasterDataElements: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [configElements, setConfigElements] = useState<Option[]>([]);
  const [message, setMessage] = useState("");
  const [selectedElement, setSelectedElement] = useState<any>(
    location.state?.element ?? "gender"
  );
  const [search, setSearch] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    currentPage: number;
    totalPages: number;
  }>({ currentPage: 1, totalPages: 0 });
  const [tableHeader, setTableHeaders] = useState<any>([]);

  const handleCategoryChange = (e: any) => {
    setSearch({});
    if (e?.label !== 'Select') {
      setPagination({ currentPage: 1, totalPages: 0 });
      setSelectedElement(e.value);
    }
  };

  useEffect(() => {
    setTableHeaders(CONFIG_HEADERS);
    const getData = async () => {
      try {
        let postData = {
          configElement: selectedElement,
          search: search?.name,
          page: pagination.currentPage,
        };
        let response = await fetchData(
          MASTER_DATA_CONFIG_ELEMENT_DATA,
          postData
        );
        setData(response?.data);
        setMessage(response?.message);
        setPagination({ ...pagination, totalPages: response.totalPages });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [selectedElement, pagination.currentPage]);

  const handleSearchChange = (e: any) => {
    const { name, value } = e.target;
    setSearch((pre: any) => ({ ...pre, [name]: value }));
  };

  const handleSearchSubmit = async (e: any, clear: boolean = false) => {
    e.preventDefault();
    let searchData = search?.name;
    if (clear) {
      setSearch({});
      searchData = "";
    }
    let postData = {
      configElement: selectedElement,
      search: searchData,
    };
    let response = await fetchData(MASTER_DATA_CONFIG_ELEMENT_DATA, postData);
    if (response?.status == 200) {
      setData(response?.data);
      setPagination({ currentPage: 1, totalPages: response?.totalPages });
    } else {
      console.log("issue while fetching data");
    }
  };

  const handleCreate = () => {
    navigate(`${PATH_CONFIG_ELEMENTS_CREATE}/?element=${selectedElement}`);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let postData = {};
        let response = await fetchData(
          GET_MASTER_DATA_CONFIG_ELEMENT_LIST,
          postData
        );
        setConfigElements(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, []);

  const fetchData = async (url: string, postData: any) => {
    return await ApiCall.service(url, "POST", postData, true, M_MASTER_DATA);
  };

  const handleEdit = (e: any) => {
    navigate(
      `${PATH_CONFIG_ELEMENTS_CREATE}/?id=${e.id}&element=${selectedElement}`
    );
  };

  const handleArchive = async (e: any) => {    
    let postData = {
      id: e?.id,
      configElement: selectedElement,
      status: e?.status ? 'false' :  'true'
    };
    let response = await fetchData(
      MASTER_DATA_CONFIG_ELEMENT_ARCHIVE,
      postData
    );
    if (response?.status === 200) {
      setData((pre: any) => pre.map((item: any) => {
        return (item.id === e.id ?  { ...item, status: !e?.status } : item);
      }));
      CustomNotify({ type: "success", message: `Element ${e?.status ? "archived" : "unarchived"} successfully` });
    }
  };

  const onPageChange = (number: number) => {
    setPagination({ ...pagination, currentPage: number });
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleSaveChanges = () => { };

  const userData=useSelector(selectAuth);
  
  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Config elements",
        read:true,
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="">
        <div className="row header-sticky position-sticky">
          <h1 className="py-4 page-title mb-0">{t("Manage master data configuration")}</h1>
          <div className="row align-items-center">
            <div className="col-6 pe-0">
              <div className="d-flex align-items-center">
                <SelectWithSearch
                  search={true}
                  onChange={handleCategoryChange}
                  title={t("Select element type")}
                  options={configElements}
                  placeHolder={t("Select")}
                  isMulti={false}
                  name={"configCat"}
                  isMandatory={false}
                  value={selectedElement}
                  error={""}
                  containerClassName="w-100 mb-4 position-relative"
                  id={"config-cat"}
                />
              </div>
            </div>
            <div className="col-2 ps-0">
              <AccessControl
                requiredPermissions={[{
                  permission: "Config elements",
                  create: true
                }]}
                override={userData.isSuperAdmin}
              >
                <Button
                  title={t("Create")}
                  handleClick={handleCreate}
                  className="btn form-button  shadow-none text-uppercase px-3 mt-2 ms-2 search-btns"
                  tooltip={t("Create")}
                  disabled={selectedElement ? false : true}
                />
              </AccessControl>
            </div>

            <div className="col-4">
              <div className="d-flex align-items-center justify-content-end">
                <SearchWithLabel
                  label={t("Project name")}
                  isMandatory={false}
                  showLabel={false}
                  name={"name"}
                  className="me-3"
                  type={"text"}
                  value={search?.name}
                  placeholder={t("Search by name...")}
                  isDisabled={false}
                  handleChange={handleSearchChange}
                />
                <TableActions
                  isAction={true}
                  handleSearchSubmit={handleSearchSubmit}
                  permission={null}
                />
              </div>
            </div>
          </div>
        </div>

        <p className="col-6 d-flex align-items-center">
          <span>
            <HelpInfoIcon />
          </span>
          <span className="ps-2"> {message}</span>
        </p>
        <div className="table-responsive manage-users">
          <div className="p-5 border rounded-4 table-custom">
            <DynamicTableStructure
              isAction
              headers={tableHeader}
              data={data}
              handleEdit={handleEdit}
              handleArchive={handleArchive}
              name={"config_elements"}
              permission={"Config elements"}
            />
          </div>
        </div>
        <div>
          <div className="pagination justify-content-center align-items-center my-3">
            <Pagination
              currentPage={pagination?.currentPage}
              totalPages={pagination?.totalPages}
              onPageChange={onPageChange}
            />
          </div>
          <div className="my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>

        <div>
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={t("Archive confirmation")}
            body={t("Are you sure want to archive?")}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            closeTitle={t("No")}
            confirmTitle={t("Yes")}
          />
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageMasterDataElements;
