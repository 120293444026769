import AccessControl from "services/AccessControl";
import ManageTranslationsOrganism from "./ManageTranslationsOrganism";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";

const ManageTranslations: React.FC = () => {
    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[{
                permission: "Translations",
                read: true,
            }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <ManageTranslationsOrganism />
        </AccessControl>
    );
}

export default ManageTranslations;