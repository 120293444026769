import React from "react";

const LinkIcon: React.FC = () => {
  return (
    <>
     <span title="Linked">
      <svg
        width="22"
        height="22"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.6923 0C28.794 0 29.8506 0.437636 30.6295 1.21663C31.4085 1.99563 31.8462 3.05218 31.8462 4.15385C31.8462 5.25551 31.4085 6.31206 30.6295 7.09106C29.8506 7.87006 28.794 8.30769 27.6923 8.30769C26.5907 8.30769 25.5341 7.87006 24.7551 7.09106C23.9761 6.31206 23.5385 5.25551 23.5385 4.15385C23.5385 3.05218 23.9761 1.99563 24.7551 1.21663C25.5341 0.437636 26.5907 0 27.6923 0ZM27.6923 10.3846C32.2823 10.3846 36 12.2435 36 14.5385V16.6154H19.3846V14.5385C19.3846 12.2435 23.1023 10.3846 27.6923 10.3846Z"
          fill="currentColor"
        />
        <path
          d="M17.3167 3.93093C10.8729 4.49884 5.51939 9.2617 4.17912 15.6147C3.97467 16.5839 3.80052 18.3407 3.86866 18.7268C3.97467 19.2948 4.70917 19.4992 5.0802 19.06C5.23922 18.8707 5.27708 18.7496 5.27708 18.4164C5.27708 17.3109 5.57239 15.592 5.95857 14.4259C7.22311 10.6398 10.1308 7.61098 13.9093 6.14956C15.136 5.68009 16.5217 5.39235 18.0209 5.30905C18.7479 5.27119 18.8766 5.2409 19.0659 5.08189C19.4369 4.77143 19.3536 4.18838 18.9069 3.96122C18.6721 3.84006 18.4298 3.83249 17.3167 3.93093Z"
          fill="currentColor"
        />
        <path
          d="M32.1501 18.1513C31.9608 18.3482 31.9306 18.4315 31.9306 18.8177C31.9306 19.06 31.8927 19.5749 31.8548 19.9611C31.1885 26.2459 26.2969 31.1602 20.0045 31.8644C19.6334 31.9023 19.111 31.9402 18.8459 31.9402C18.4143 31.9402 18.3386 31.9629 18.1417 32.1598C17.7328 32.5687 17.9297 33.2426 18.49 33.3486C18.6339 33.3713 19.2018 33.3562 19.747 33.3107C23.3438 32.9927 26.5165 31.5237 29.0153 29.0249C31.037 26.9956 32.4379 24.4211 33.0285 21.6118C33.233 20.6426 33.4071 18.8858 33.339 18.4997C33.233 17.9393 32.559 17.7424 32.1501 18.1513Z"
          fill="currentColor"
        />
        <path
          d="M8.30769 19.3846C9.40936 19.3846 10.4659 19.8222 11.2449 20.6012C12.0239 21.3802 12.4615 22.4368 12.4615 23.5385C12.4615 24.6401 12.0239 25.6967 11.2449 26.4757C10.4659 27.2547 9.40936 27.6923 8.30769 27.6923C7.20602 27.6923 6.14948 27.2547 5.37048 26.4757C4.59148 25.6967 4.15385 24.6401 4.15385 23.5385C4.15385 22.4368 4.59148 21.3802 5.37048 20.6012C6.14948 19.8222 7.20602 19.3846 8.30769 19.3846ZM8.30769 29.7692C12.8977 29.7692 16.6154 31.6281 16.6154 33.9231V36H0V33.9231C0 31.6281 3.71769 29.7692 8.30769 29.7692Z"
          fill="currentColor"
        />
      </svg>
      </span>
    </>
  );
};

export default LinkIcon;
