import React, { useState } from "react";
import { CompanyData } from "./ManageCompanies";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import ArchiveIcon from "../../../../../static/images/ArchiveIcon";
import EditIcon from "../../../../../static/images/EditIcon";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { ARCHIVE_COMPANY } from "routes/ApiEndpoints";
import Pagination from "components/atoms/Pagination";
import Cooperation from "static/images/Cooperation";
import { M_COMPANY_CREATION } from "constants/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableActions from "components/organism/Actions/TableAction";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import FollowUpIcon from "static/images/FollowUpIcon";
import ViewIcon from "static/images/ViewIcon";
import { IconData } from "services/IconData";

interface ManageCompanyPageProps {
  compObj: CompanyData[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  bussinessUnit: any;
}

export const ManageCompaniesPage: React.FC<ManageCompanyPageProps> = ({
  compObj,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
  bussinessUnit,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  let pageIndex = (currentPage - 1) * itemsPerPage + 1;
  const AgreementStatus = ["Not completed", "Completed"];
  const CompanyStatus = ["Drafted company ", "Registered company"];
  const Type = ["Prospect", "Customer"];
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [sideBarWidth, setSidebarWidth] = useState("");

  const sortedType = Type.map((type, index) => ({
    type,
    originalIndex: index,
  }));
  sortedType.sort((a, b) => a.type.localeCompare(b.type));
  const sortedAgreementStatus = AgreementStatus.map((agrestatus, index) => ({
    agrestatus,
    originalIndex: index,
  }));
  sortedAgreementStatus.sort((a, b) =>
    a.agrestatus.localeCompare(b.agrestatus)
  );

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "city") {
      const alphabetRegex = /^[A-Za-z\s]*$/;
      if (!alphabetRegex.test(value)) {
        return; // Ignore non-alphabet input
      }
    }
    // Validation for phoneNumber: allows only numbers
    if (name === "phoneNumber") {
      const numericRegex = /^[0-9]*$/;
      if (!numericRegex.test(value)) {
        return; // Ignore non-numeric input
      }
    }
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const deleteCompany = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const response = await ApiCall.getService(
        `${ARCHIVE_COMPANY}/${deleteID}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleFollowup = (
    event: any,
    id: string | number | null,
    businessUnit: string | number | null
  ) => {
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  const userData = useSelector(selectAuth);
  function handleClick(company: any): void {
    const companyId = company.id;
    navigate(`/company/${companyId}`);
  }
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="row header-sticky position-sticky">
            <div className="col-md-12">
              <h1 className="py-4 page-title mb-0">{t("Manage companies")}</h1>
            </div>
          </div>
          <div className="row pb-4 search-bar">
            <div className="col-3 mb-3">
              <div className="">
                <label className="form-label field-label">{t("Company")}</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder="Company name"
                name="name"
                value={searchData?.name}
              />
            </div>
            {/* <div className="col mb-3">
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder="Company official name"
                name="officialName"
                value={searchData?.officialName}
              />
            </div> */}
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">{t("Vat number")}</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder="VAT number"
                name="vatNumber"
                value={searchData?.vatNumber}
              />
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">{t("City")}</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder="City"
                name="city"
                value={searchData?.city}
              />
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">{t("Phone number")}</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder="Phone number"
                name="phoneNumber"
                value={searchData?.phoneNumber}
              />
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">{t("Business unit")}</label>
              </div>
              <select
                className="form-select shadow-none"
                name="bussinessUnit"
                id="bussinessUnit"
                onChange={handleSearchChange}
                value={searchData.bussinessUnit}
              >
                {bussinessUnit && bussinessUnit.length > 0 ? (
                  <>
                    <option value="">{t("Select business unit")}</option>
                    {bussinessUnit.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No business available")}</option>
                )}
              </select>
            </div>

            <div className="col-3">
              <div className="">
                <label className="form-label field-label">{t("Status")}</label>
              </div>
              <select
                className="form-select shadow-none"
                name="cs"
                id="cs"
                onChange={handleSearchChange}
                value={searchData.cs}
              >
                <option value="">{t("Status")}</option>
                {CompanyStatus?.map((optn, key) => (
                  <option key={key} value={key}>
                    {optn}
                  </option>
                ))}
              </select>
            </div>
            <div className="col mt-auto text-end">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Company",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to="/company"
                  className="table-button"
                >
                  + {t("Create company")}
                </Link>
              </AccessControl>
              {/* <AccessControl
                requiredPermissions={[{
                  permission: "Location",
                  read: true
                }]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to="/manage-locations"
                  className="form-button float-end d-flex align-items-center px-3 py-1 text-decoration-none text-uppercase create-candidate-btn me-md-2 justify-content-center"
                >
                  {t("Manage Location")}
                </Link>
              </AccessControl> */}
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Contact person",
                    read: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to="/manage-contact/person"
                  className="table-button"
                >
                  {t("Manage Contact Person")}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive manage-companies">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {/* <th className="ps-lg-4">#</th> */}
                    <th>{t("Company name")}</th>
                    <th>{t("Businees unit")}</th>
                    <th>{t("VAT number")}</th>
                    {/* <th>{t("Company official name")}</th> */}
                    <th>{t("Location")}</th>
                    <th>{t("City")}</th>
                    <th>{t("Email")}</th>
                    <th>{t("Phone number")}</th>

                    <th>{t("Status")}</th>
                    <th className="actions table_actions">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Company",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {compObj && compObj.length > 0 ? (
                    compObj.map((company, index) => {
                      let color_as =
                        company.status === 0
                          ? "bg-warning"
                          : company.status === 1
                          ? "bg-success"
                          : "bg-danger";
                      let color_cs =
                        company.cs === 0
                          ? "bg-warning"
                          : company.cs === 1
                          ? "bg-success"
                          : "bg-danger";

                      return (
                        <tr
                          key={company.id}
                          className="border mb-3 box-shadow align-middle"
                        >
                          <td className="d-block d-md-block d-lg-none p-0">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="text-break px-0">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger="click"
                                      rootClose
                                      overlay={
                                        <Tooltip>
                                          {AgreementStatus[company.status ?? 0]}{" "}
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <span
                                          className={`d-inline-block status-icon ${color_as}`}
                                        />
                                        <span>
                                          <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            className="ms-2"
                                          />
                                        </span>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="d-block d-md-block d-lg-none p-0">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="text-break px-0">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger="click"
                                      rootClose
                                      overlay={
                                        <Tooltip>
                                          {CompanyStatus[company.cs ?? 0]}{" "}
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <span
                                          className={`d-inline-block status-icon ${color_cs}`}
                                        />
                                        <span>
                                          <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            className="ms-2"
                                          />
                                        </span>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          {/* Company name */}
                          <td
                            onClick={() => {
                              handleClick(company);
                            }}
                            className="cursor-pointer nameHoverClass"
                          >{`${company.name}`}</td>
                          <td className="text-break" data-label="Type">
                            {company.bussinessUnit}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.vatNumber}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.location}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.city}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.email}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.phoneNumber}
                          </td>

                          {/* company status */}
                          <td className="text-break ps-3">
                            <span
                              className={`d-inline-block rounded-circle status-icon ${color_cs}`}
                              data-toggle="tooltip"
                              title={CompanyStatus[company.cs ?? 0]}
                            ></span>
                          </td>
                          {/* actions */}
                          <td className="table-action-icons px-2">
                            {/* Desktop */}
                            <div className="d-none d-md-none d-lg-block">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Company",
                                    update: true,
                                  },
                                ]}
                                override={userData.isSuperAdmin}
                              >
                                <Button
                                  title={<EditIcon />}
                                  handleClick={() => navigate(`/company/${company.id}`)}
                                  className={"table-action-btn"}
                                  tooltip={t("Edit")}
                                />
                                <Button
                                  title={<ViewIcon />}
                                  handleClick={() => navigate(`/company/${company.id}?mode=view`)}
                                  className={"table-action-btn"}
                                  tooltip={t("View")}
                                />
                              </AccessControl>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Company",
                                    delete: true,
                                  },
                                ]}
                                override={userData.isSuperAdmin}
                              >
                                <Button
                                  title={<ArchiveIcon />}
                                  handleClick={() => deleteCompany(company.id)}
                                  className={"table-action-btn"}
                                  tooltip={t("Archive")}
                                />
                              </AccessControl>
                              {company.cs == 1 && (
                                <Button
                                  title={<FollowUpIcon />}
                                  handleClick={(e) => {
                                    handleFollowup(
                                      e,
                                      company.id ? company.id : "",
                                      company?.get_bussiness_unit
                                        ?.business_unit_id
                                    );
                                  }}
                                  className={"table-action-btn"}
                                  tooltip={t("Followup")}
                                />
                              )}
                              {/* <Link
                              to={`/create/cooperation-agreement/${company.id}`}
                              className="btn p-0 border-0 me-2"
                              title="Link agreement"
                            >
                              <Cooperation />
                            </Link> */}
                            </div>

                            {/* PWA */}
                            <div className="d-block d-md-block d-lg-none text-center">
                              <div className="mb-2">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Company",
                                      update: true,
                                    },
                                  ]}
                                  override={userData.isSuperAdmin}
                                >
                                  <Link
                                    to={`/company/${company.id}`}
                                    className="btn p-0 border-0 w-100"
                                    title="Edit"
                                  >
                                    <Button
                                      title="Edit"
                                      className="text-uppercase mb-2 button-width edit-btn"
                                    />
                                  </Link>
                                </AccessControl>
                              </div>
                              <div>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Company",
                                      delete: true,
                                    },
                                  ]}
                                  override={userData.isSuperAdmin}
                                >
                                  <button
                                    className="btn p-0  text-uppercase mb-2 button-width delete-btn"
                                    title="Archive"
                                    onClick={() => deleteCompany(company.id)}
                                  >
                                    Archive
                                  </button>
                                </AccessControl>
                              </div>
                              <div>
                                {company.cs == 1 && (
                                  <button
                                    className="btn p-0 border-0  me-2"
                                    title="Followup"
                                    onClick={(e) => {
                                      handleFollowup(
                                        e,
                                        company.id ? company.id : 0,
                                        company?.get_bussiness_unit?.id
                                      );
                                    }}
                                  >
                                    <FollowUpIcon />
                                  </button>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border">
                      <td colSpan={6} className="border-0 text-center py-3 px-2">
                        <span className="text-danger fw-bold">No records</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
        <div className="align-self-center my-4">
          <Link
            to="/"
            className="text-uppercase back-btn text-decoration-underline"
          >
            Back
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title="Archive confirmation"
          body="Are you sure want to archive?"
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {followupSideBar && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"company"}
            businessUnit={entity.businessUnit}
            entityId={entity.entityId}
            onClose={handleCloseSidebar}
            getWidth={getWidth}
            recordsLimit={10}
            className="right-sidebar p-3"
            titleClassName="mt-4"
            destination="/manage-companies"
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageCompaniesPage;