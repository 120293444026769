export const UserTableHeader = [
  { name: "First name", width: 15 },
  { name: "Last name", width: 15 },
  { name: "Email", width: 30 },
  { name: "Role", width: 15 },
  { name: "Status", width: 16 },
];
export const CandidateTableHeader = [
  { name: "First name", width: 15 },
  { name: "Last name", width: 15 },
  { name: "Email", width: 30 },
  // { name: "Role", width: 15 },
  { name: "Status", width: 16 },
];
export const RoleTableHeader = [
  { name: "Name", width: 80 }
];
export const BusinessUnitTableHeader = [
  { name: "Business unit name", alias: "name", width: 35 },
  { name: "Business unit number", alias: "number", width: 25 },
  { name: "Company", alias: "company", width: 30 },
  // {name: "Template", alias: "template", width: 10},
  // {name: "Phone number", alias: "phone", width: 15},
  // {name: "Address", alias: "country", width: 15},
  // {name: "Status", alias: "status", width: 10}
];
export const ContactPersonTableHeader = [
  { name: "Contact person", alias: "first_name", width: 15 },
  { name: "Company", alias: "company_id", width: 20 },
  { name: "Location", alias: "location", width: 20 },
  { name: "Email", alias: "email", width: 20 },
  { name: "Phone number", alias: "phoneNumber", width: 20 },
]

export const LocationTableHeader = [
  { name: "Location", alias: "location", width: 80 },
  { name: "Company", alias: "company", width: "" },
]

export const ApplicationConfiguration = [
  { name: "Name", alias: "entity_name", width: 80 },
]

export const LocationColumn = ["location", "company"];

export const ExcludeLocationCompanyData = ['id', 'company_id', 'location'];

export const ExcludeContactCompanyData = ['id', 'name',];

export const EmployeeTypeTableHeader = [
  { name: "Employee type name", alias: "name" },
  { name: "category", alias: "category" }
];

export const TagHeader = [
  { name: "Tag name", alias: "name", width: 15 },
];

export const ShiftHeader = [

  { name: "Shifts name", alias: "shift_name", width: 50 },
];

export const CONFIG_HEADERS = [
  { name: "Name", alias: "name", width: 30 },
  { name: "Status", alias: "status", width: 30 }
]

export const CompetenceHeader = [
  { name: "Name of competence", alias: "competence_name" },
];

export const DocumentHeader = [
  { name: "Document", alias: "file_name" },
  { name: "Company", alias: "company" },
  { name: "Employee", alias: "employee" },
];

export const PCHeader = [
  { name: "Paritair comitee name", alias: "pc_name", width: 50 },
  // {name : "Category", alias:"category_name", width: 30}
];

export const HotlistEmployeeTableHeader = [
  { name: "Employee", alias: "name", width: "" },
  { name: "Location", alias: "location", width: "" },
  { name: "Contract type", alias: "contractType", width: "" },
  { name: "Function", alias: "function", width: "" },
  { name: "From", alias: "startDate", width: "" },
  { name: "To", alias: "endDate", width: "" },
  { name: "Contract sign", alias: "status", width: "" },
]

export const HotlistProjectTableHeader = [
  { name: "Project", alias: "name", width: "" },
  { name: "Company", alias: "companyName", width: "" },
  { name: "Location ", alias: "location", width: "" },
  { name: "Start date", alias: "startDate", width: "" },
  { name: "End date", alias: "endDate", width: "" },
  // {name: "Project status", alias:"projectStatus", width: ""},
]

//Questions
export const QuestionsTableHeader = [
  { name: "PC", alias: "PC", width: 30 },
  { name: "Function", alias: "functnName", width: 30 }
]

export const GeneralQuestionsTableHeader = [
  { name: "Category", alias: "category" }
]

//Salary benefits
export const SalaryBenefitHeader = [
  { name: "Name", alias: "name", width: 30 },
  { name: "Salary benefit type", alias: "salary_benefit_type", width: 30 },
  // {name : "Start date", alias:"start_date", width: 30},
];
//Manage public holidays
export const ManagePublicHolidays = [
  { name: "Holiday name", alias: "holiday_name", width: "" },
  { name: "Date", alias: "date", width: "" },
  { name: "Country", alias: "country", width: "" },
  { name: "Region", alias: "region", width: "" },
]

//Manage holidays per PC
export const ManageHolidaysPerPC = [
  { name: "Paritair committee", alias: "name", width: 80 },
]

//Salary benefits
export const TodoHeader = [
  { name: "Todo date", alias: "todo_date" },
  { name: "Type of followup", alias: "entity_type" },
  { name: "Todo type", alias: "todo_type" },
  { name: "Info", alias: "info" },
  { name: "Bussiness unit", alias: "business_unit" },
  { name: "Assigned to", alias: "assigned_to" },
  { name: "Status", alias: "todo_status" },
];

//Contracts
export const ContractTemplateHeader = [
  { name: "Name", alias: "name" },
  { name: "Value", alias: "value" }
]

//Manage contracts
export const ManageContractTemplateHeader = [
  { name: "Category", alias: "category" },
  { name: "Type", alias: "template" },
  { name: "Document type", alias: "documentType" },
]
//Manage employee leave
export const ManageEmployeeLeaves = [
  { name: "Title", alias: 'leaveType', width: '' },
  { name: 'Reason', alias: 'reason', width: '' },
  { name: 'From', alias: 'from', width: '' },
  { name: 'To', alias: 'to', width: '' },
  { name: 'Unit', alias: 'unit', width: '' }
]

