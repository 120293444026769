import React from "react";
// import { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import DocViewer from "@cyntler/react-doc-viewer/dist/esm/DocViewer.js";


interface Props {
  fileUrl: string;
  fileType: string;
}

const DocView: React.FC<Props> = ({ fileType, fileUrl }) => {
  if (!fileUrl) return null;

  return (
    <div>
      {/* <DocViewer
        documents={[{ uri: fileUrl }]}
        pluginRenderers={DocViewerRenderers}
        style={{ width: "100%", height: 500 }}
        config={{ header: { disableHeader: true } }}
      /> */}
    </div>
  );
};

export default DocView;
