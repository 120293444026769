import { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import { Accordion } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints"
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { M_MASTER_DATA } from "constants/Constants";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import { PATH_MANAGE_CANDIDATES } from "constants/Paths";

interface ScreeningProps {
  questions: any;
}

const ScreeningAssessment: React.FC = () => {
  const [formData, setFormData] = useState<ScreeningProps>({
    questions: [],
  });
  const { screeningId, candidateId } = useParams<{
    screeningId: string;
    candidateId: string;
  }>();
  const navigate = useNavigate();

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupIndex: number,
    questionIndex: number
  ) => {};

  useEffect(() => {
    const fetchScreeningDetails = async () => {
      const response = await ApiCall.getService(
        `${ENDPOINTS.GET_SCREENING_ASSESSMENT}/${screeningId}`,
        "GET",
        M_MASTER_DATA
      );
      
      setFormData((prevData) => ({
        ...prevData,
        questions: response.data,
      }));
    };
    fetchScreeningDetails();
  }, []);

  const handleClick =  (value:boolean)=>{
    let candidateResult = true;
    if(value){
      candidateResult = true;
      navigate(`/employee/edit/${candidateId}?candidateId=${candidateId}`);
    }else{
      candidateResult = false;
      navigate(`${PATH_MANAGE_CANDIDATES}`);
    } 
    const postData = {
      id: candidateId,
      candidateResult: candidateResult
    }
    ApiCall.service(`${ENDPOINTS.CANDIDATE_RESULT}`, "POST", postData , true, M_MASTER_DATA).then((response)=>{
      if(response.status === 200){        
        CustomNotify({type: "success", message: response.message});
      }
    });      
  }

  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Screening",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
        <Title title={t("View screening")} />
        <div className="px-4">
          {formData?.questions?.length > 0 && (
            <div className="screening-heightAssessment">
              <div className="position-relative mb-5">
                <div className="prepare-questions-height">
                  <div className="position-relative mb-5">
                    <div className="form-border p-5">
                      <div className="pb-4">
                        {formData.questions.map(
                          (questionGroup: any, groupIndex: number) => (
                            <div key={groupIndex}>
                              <div className="mb-3">
                                <Accordion>
                                  <Accordion.Item eventKey={`${groupIndex}`}>
                                    <Accordion.Header className="fw-bold">
                                      {questionGroup.function.label}
                                    </Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {questionGroup?.questions?.length &&
                                      questionGroup?.questions?.length > 0 ? (
                                        <>
                                          {questionGroup.questions.map(
                                            (question: any, index: number) => (
                                              <div
                                                key={index}
                                                className={` p-3 rounded-3 mb-3 border ${
                                                  question.disabled
                                                    ? " border question-not-applicable-section opacity-50 p-3 rounded-3 mb-3"
                                                    : ""
                                                }`}
                                              >
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <h5
                                                    className="questionName"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        index +
                                                        1 +
                                                        ") " +
                                                        question.question,
                                                    }}
                                                  />
                                                </div>
                                                {question.questionType === 1 &&
                                                  question?.options?.length >
                                                    0 && (
                                                    <div
                                                      className="my-1"
                                                      key={index}
                                                    >
                                                      {question.options.map(
                                                        (
                                                          option: any,
                                                          optionIndex: number
                                                        ) => (
                                                          <div
                                                            key={optionIndex}
                                                            className="screening-checkbox d-flex align-items-center mb-2"
                                                          >
                                                            <CheckBoxField
                                                              name={`answers-${index}-${optionIndex}`}
                                                              id={`answers-${index}-${optionIndex}`}
                                                              onChangeHandler={(
                                                                event
                                                              ) =>
                                                                handleFieldChange(
                                                                  event,
                                                                  groupIndex,
                                                                  index
                                                                )
                                                              }
                                                              value={
                                                                optionIndex
                                                              }
                                                              disable={true}
                                                              isChecked={
                                                                Array.isArray(
                                                                  question.answers
                                                                ) &&
                                                                question.answers.includes(
                                                                  String(
                                                                    optionIndex
                                                                  )
                                                                )
                                                              }
                                                            />

                                                            <span
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  option.replace(
                                                                    /<a/g,
                                                                    '<a target="_blank"'
                                                                  ),
                                                              }}
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}

                                                {question.questionType === 3 &&
                                                  question?.options?.length >
                                                    0 && (
                                                    <div
                                                      className="my-1"
                                                      key={index}
                                                    >
                                                      {question.options.map(
                                                        (
                                                          option: any,
                                                          optionIndex: number
                                                        ) => (
                                                          <div
                                                            key={optionIndex}
                                                            className="d-flex screening-checkbox align-items-center mb-2"
                                                          >
                                                            <RadioField
                                                                      className="ps-1"
                                                                      id={`answers-${index}-${optionIndex}`}
                                                                      value={optionIndex}
                                                                      name={`answers-${groupIndex}-${index}`}
                                                                      handleChange={(
                                                                          event
                                                                      ) => {
                                                                          handleFieldChange(
                                                                              event,
                                                                              groupIndex,
                                                                              index
                                                                          );
                                                                      } }
                                                                      disable={true}
                                                                      ischecked={Array.isArray(
                                                                          question.answers
                                                                      ) &&
                                                                          question.answers.includes(
                                                                              String(
                                                                                  optionIndex
                                                                              )
                                                                          )} 
                                                                    label={undefined}                                                            />
                                                            <span
                                                              dangerouslySetInnerHTML={{
                                                                __html: option,
                                                              }}
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                {question.questionType ===
                                                  2 && (
                                                  <LabelWithTextAreaField
                                                    name="description"
                                                    handleChange={(event) =>
                                                      handleFieldChange(
                                                        event,
                                                        groupIndex,
                                                        index
                                                      )
                                                    }
                                                    label=""
                                                    value={
                                                      question?.answers?.length >
                                                      0
                                                        ? question.answers[0]
                                                        : ""
                                                    }
                                                    isDisabled={true}
                                                  />
                                                )}
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <div className="text-danger p-2 text-center">
                                          {t(
                                            "No general and function related questions added"
                                          )}
                                        </div>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                <div>
                                <Button
                                  title={t("REJECTED")}
                                  type="submit"
                                  handleClick={()=>handleClick(false)}
                                  className="btn float-end form-button rounded-3 shadow-none  px-3 mt-3 "
                                />
                              </div>
                              <div>
                              <Button
                                title={t("SELECTED")}
                                type="submit"
                                handleClick={()=>handleClick(true)}
                                className="btn float-start form-button rounded-3 shadow-none  px-3 mt-3"
                              />
                              </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-6 align-self-center py-4">
            <Link
              to={`/manage-screening/${candidateId}`}
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
      {/* </AccessControl> */}
    </>
  );
};
export default ScreeningAssessment;
