import React from "react";

interface FormatDataProps {
  data: any;
  excludeData?: string[];
  containerClassName?: string;
  itemClassName?: string;
}

const FormatData: React.FC<FormatDataProps> = ({
  data,
  excludeData,
  containerClassName,
  itemClassName,
}) => {
  if (data !== null && data !== undefined) {
    return (
      <div className={containerClassName || "row"}>
        {Object?.entries(data).map((data: [string, any]) => {
          if (!excludeData?.includes(data[0])) {
            const keyName = data[0].replace(/_/g, " ");
            const formattedKey =
              typeof keyName === "string"
                ? keyName.charAt(0).toUpperCase() + keyName.slice(1)
                : keyName;
            const formattedValue = data[1];
            return (
              <div key={data[0]} className={itemClassName || "col-6"}>
                <>
                  <strong>{formattedKey}:</strong> <span>{formattedValue}</span>
                </>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
};

export default FormatData;
