import { EMPLOYEE_ACTIONS } from "../../actions/EmployeesActions";

export const employeeReducer = (employees, actions) => {
  switch (actions.type) {
    case EMPLOYEE_ACTIONS.SET_EMPLOYEE: {
      return set_employee(employees, actions);
    }
    case EMPLOYEE_ACTIONS.SET_EMPLOYEE_DATA:        
      return { ...employees, employees: actions.payload, employee_id: actions.payload?.[0]?.value };
    default:
      throw Error("Unknown action: " + actions.type);
  }
};

function set_employee(employees, actions) {
  let updatedData = { ...employees, employee_id: actions.payload }
  return updatedData
}
