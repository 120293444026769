import React from "react";
import { Link } from "react-router-dom";
import {
  PATH_MANAGE_EMPLOYEE_TYPE,
  PATH_MANAGE_TAGS,
  PATH_MANAGE_BUSINESS_UNIT,
  PATH_EMAIL_TEMPLATE_MANAGE,
  PATH_MANAGE_SHIFTS,
  PATH_MANAGE_TEMPLATE,
  PATH_PC_MANAGE,
  PATH_COMPETENCE_MANAGE,
  PATH_MANAGE_TIMETABLE,
  PATH_CONFIG_ELEMENTS_MANAGE,
  PATH_APPLICATION_CONFIGURATION,
  PATH_HOTLIST,
  PATH_DOCUMENT,
  MANAGE_QUESIONS,
  PATH_SALARY_BENEFITS_MANAGE,
  PATH_TODO_MANAGE,
  PATH_MANAGE_CONTRACT_TEMPLATE,
  PATH_MANAGE_TEMP_AGENCY,
  PATH_MANAGECOEFF_DEVIATION,
  PATH_MANAGE_WAGE_ELEMENT,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const ConfigAndSettingsPage: React.FC = () => {
  const userData = useSelector(selectAuth);
  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky mb-3">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">{t("Master data")}</h1>
          </div>

          <div className="row mb-3">
            <div className="col-md-12 dashboard-tabs pt-0">
              {/* <Link to="/manage-city-profile" className='dashboard-tab-links'>
                       <div className="tab">
                           Manage city profile
                       </div>
                   </Link>
                   <Link to="/manage/function-profile" className='dashboard-tab-links'>
                       <div className="tab">
                           Manage function profile
                       </div>
                   </Link>
                   <Link to="/manage-checklist" className='dashboard-tab-links'>
                       <div className="tab">
                           Document checklist
                       </div>
                   </Link>
                   <Link to="/manage/default/low-coefficients" className='dashboard-tab-links'>
                       <div className="tab">
                           Manage low coefficients
                       </div>
                  </Link>
                   <Link to="/work-type/extra" className='dashboard-tab-links'>
                       <div className="tab">
                           Work type extra
                       </div>
                   </Link>
                   <Link to="/manage-extras" className='dashboard-tab-links'>
                       <div className="tab">
                           Manage extras
                       </div>
                   </Link> */}
              {/* <Link to="/manage/inflations" className='dashboard-tab-links'>
                       <div className="tab">
                           Manage inflations
                       </div>
                   </Link> */}
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Business unit",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_MANAGE_BUSINESS_UNIT}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Business unit")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Email template",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_EMAIL_TEMPLATE_MANAGE}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Email templates")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Shifts",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_SHIFTS} className="dashboard-tab-links">
                  <div className="tab">{t("Shifts")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Templates",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_MANAGE_TEMPLATE} className="dashboard-tab-links">
                  <div className="tab">{t("Project template")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Paritair commitee",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_PC_MANAGE} className="dashboard-tab-links">
                  <div className="tab">{t("Paritair comitee")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Competence",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_COMPETENCE_MANAGE}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Competence")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Timetable",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_MANAGE_TIMETABLE}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Timetable")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Application configuration",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_APPLICATION_CONFIGURATION}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Application configurations")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Config elements",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_CONFIG_ELEMENTS_MANAGE}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Config elements")}</div>
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Hotlist",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={`${PATH_HOTLIST}`} className="dashboard-tab-links">
                  <div className="tab">{t("Hotlist")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Document",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={`${PATH_DOCUMENT}`} className="dashboard-tab-links">
                  <div className="tab">{t("Documents")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Questions",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={`${MANAGE_QUESIONS}`} className="dashboard-tab-links">
                  <div className="tab">{t("Questions")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Salary benefits",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_SALARY_BENEFITS_MANAGE}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Salary benefits")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Todo",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_TODO_MANAGE}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Todo")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Contract template",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_MANAGE_CONTRACT_TEMPLATE}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Contract template")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Temp agency",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_MANAGE_TEMP_AGENCY}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Temp Agency")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Coefficient deviation",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_MANAGECOEFF_DEVIATION}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Coefficient deviation")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Wage elements",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_MANAGE_WAGE_ELEMENT}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Wage element")}</div>{" "}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Employee type",
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={`${PATH_MANAGE_EMPLOYEE_TYPE}`}
                  className="dashboard-tab-links"
                >
                  <div className="tab">{t("Employee Type")}</div>{" "}
                </Link>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6 align-self-center">
          <Link
            to="/"
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ConfigAndSettingsPage;
