import React, { ChangeEvent } from 'react';

interface Props {
  type?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?:string;
  value?:string | undefined;
}

const Search: React.FC<Props> = ({
  type = 'text',
  placeholder = '',
  handleChange,
  className = 'shadow-none',
  name = '',
  value = ''
}) => {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        className={`${className} form-control shadow-none`}
        name={name}
        autoComplete="off"
        value={value}
      />
    </>
  );
};

export default Search;