import React, { useEffect, useState, Suspense } from "react";
import { ApiCall } from "services/ApiServices";
import { MANAGE_COMPANY } from "routes/ApiEndpoints";
import ManageCompaniesPage from "./ManageCompaniesPage";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { M_COMPANY_CREATION } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export interface CompanyData {
  id?: number;
  name: string;
  status: any;
  cs: any;
  type: any;
  officialName?: string;
  vatNumber?: string;
  bussinessUnit?: any;
  get_bussiness_unit?: any;
  location?: any;
  city?: any;
  email?: any;
  phoneNumber?: any;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: Partial<CompanyData>;
}

const ManageCompanies: React.FC = () => {
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState<Partial<CompanyData>>({
    name: "",
    status: "",
    cs: "",
    type: "",
    bussinessUnit: "",
    vatNumber: "",
    city: "",
    phoneNumber: "",
  });
  const [bussinessUnitOptions, setBusinessUnitData] = useState([]);

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
    };

    fetchCompaniesDetails(data);
  }, [currentPage, submit]);

  const fetchCompaniesDetails = async (data: DataProps) => {
    const response = await ApiCall.service(
      MANAGE_COMPANY,
      "POST",
      data,
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 200) {
      setCompanies(response?.data);
      setBusinessUnitData(response?.bussinessUnit);
      setTotalPages(response.totalPages);
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleDeleteData = (deleteId: number) => {
    setCompanies((prevdata) =>
      prevdata.filter((company) => company.id !== deleteId)
    );
    setCurrentPage(1);
  };

  const createQueryParams = (data: Partial<CompanyData>) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        name: "",
        status: "",
        cs: "",
        type: "",
        bussinessUnit: "",
        vatNumber: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }

    setSubmit(!submit);
    setCurrentPage(1);
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <Suspense
          fallback={<div className="text-center text-danger">Loading...</div>}
        >
          <ManageCompaniesPage
            compObj={companies}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            searchValue={handleSearch}
            deleteFilter={handleDeleteData}
            handleSubmitAction={handleSubmit}
            searchData={search}
            bussinessUnit={bussinessUnitOptions}
          />
        </Suspense>
      </>
    </AccessControl>
  );
};
export default ManageCompanies;
