import { EMPLOYMENT_DETAILS, STORE_EMPLOYMENT } from "routes/ApiEndpoints";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps, ValidationRules } from "utils/TypeAnnotations";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { M_MASTER_DATA } from "../../../../constants/Constants";
import {
  validateForm,
  validateRequired,
  validateSelectField,
  Option,
} from "services/validation/ValidationService";

interface EmploymentProps {
  contract: Option | null;
  employment: Option | null;
  hrs: number | string;
  regime: Option | null;
  shift: Option | null;
}

const Employment = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [formData, setFormData] = useState<EmploymentProps>({
    contract: null,
    employment: null,
    hrs: "",
    regime: null,
    shift: null,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    contract: "",
    employment: "",
    hrs: "",
    regime: "",
    shift: "",
  });

  const [options, setOptions] = useState({
    contractOptions: [] as OptionProps[],
    employmentOptions: [] as OptionProps[],
    regimeOptions: [] as OptionProps[],
    shiftOptions: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchEmploymentDetails = async () => {
      const url = id ? `${EMPLOYMENT_DETAILS}/${id}` : EMPLOYMENT_DETAILS;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response.status === 200) {
        console.log(response.data);
        const contractOptions = mapToSelect(response.data.contracts);
        const employmentOptions = mapToSelect(response.data.employments);
        const regimeOptions = mapToSelect(response.data.regimes);
        const shiftOptions = mapToSelect(response.data.shifts);

        setOptions({
          contractOptions: contractOptions,
          employmentOptions: employmentOptions,
          regimeOptions: regimeOptions,
          shiftOptions: shiftOptions,
        });
      }
    };
    fetchEmploymentDetails();
  }, []);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      contract: [validateSelectField],
      employment: [validateSelectField],
      hrs: [validateRequired],
      regime: [validateSelectField],
      shift: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if ((parseInt(value) <= 24 && parseInt(value) >= 0) || value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    validation(name, value, true);
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
    validation(name, selectedOption, true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    console.log(event.target);

    if (validation(name, value)) {
      console.log(formData);
      const url = `${STORE_EMPLOYMENT}/${id}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_MASTER_DATA
      );
      if (response.status === 200) {
        console.log("set");
      }

      console.log(formData);
      navigate(`/offer/${id}`);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="text-center py-4 page-title mb-0`">
          <h1>Create vacancy</h1>
          <p>Excellent!</p>
          <p>
            You're making remarkable strides towards creating unparalleled
            opportunities for aspiring talents.
          </p>
          <h2>Employment details</h2>
        </div>

        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Contract type"
              name="contract"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.contractOptions}
              value={formData.contract}
              onChange={(e) => handleSelectChange(e, "contract")}
              isMulti={false}
              className="select-field"
              error={errors.contract}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Employment"
              name="employment"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.employmentOptions}
              value={formData.employment}
              onChange={(e) => handleSelectChange(e, "employment")}
              isMulti={false}
              className="select-field"
              error={errors?.employment}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="hrs"
              handleChange={handleFieldChange}
              value={formData.hrs}
              id="hrs"
              label="Number of Hours"
              type="number"
              error={errors.hrs}
              max={24}
              min={1}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Regime"
              name="regime"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.regimeOptions}
              value={formData.regime}
              onChange={(e) => handleSelectChange(e, "regime")}
              isMulti={false}
              className="select-field"
              error={errors.regime}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Shift"
              name="shift"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.shiftOptions}
              value={formData.shift}
              onChange={(e) => handleSelectChange(e, "shift")}
              isMulti={false}
              className="select-field"
              error={errors.shift}
            />
          </div>
        </div>
        <div className="d-none d-md-block d-lg-block">
          <div className="row mb-4">
            <div className="col-md-4 align-self-center">
              <Link
                to={`/sector/function/${id}`}
                className="text-uppercase back-btn text-decoration-underline"
              >
                Back
              </Link>
            </div>
            <div className="col-md-8 text-end">
              <Button
                title="Next"
                type="submit"
                className="btn form-button rounded-0 shadow-none text-uppercase"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Employment;
