import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { useProjectParameterContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";

const Timings = (props) => {
  const { planTimingsData, header1, header2, total, type, isDisable, name } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()
  const { initialProjectParameterState } = useProjectParameterContext();

  const handleChange = (event, plan) => {
    const { value, name } = event.target;
    if (plan.plan_id) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: plan.plan_id,
          value: plan?.plan_date?.split?.(" ")?.[0] + " " + value,
          name: name,
          extraprops: {
            filterOptionsState,
            initialProjectParameterState,
          }
        },
      });
    }
  };

  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className="border">
        {header1}
      </td>,
      <td key={"heading2"} className="border">{header2}</td>,
    ];
    const dataToRender =
      planTimingsData && planTimingsData.length > 0
        ? planTimingsData
        : Array(7).fill({});
    dataToRender.forEach((plan, i) => {
      const value = plan[name] ? plan[name].split(" ")[1] : "";
      weekDays.push(
        <td key={i} className="border">
          <LabelWithInputField
            labelClassName="d-none"
            errorclass="d-none"
            label={""}
            name={name}
            isMandatory={false}
            value={value}
            handleChange={(event) => handleChange(event, plan)}
            id={name}
            type={type ? type : "time"}
            error={""}
            isDisabled={isDisable}
          />
        </td>
      );
    });
    return weekDays;
  };
  return (
    <tr>
      {renderItems()}
      <td key={'last'}>{total}</td>
    </tr>
  );
};
export default Timings;
