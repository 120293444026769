import { dateUtils } from 'pages/microservices/planning/utils/dateUtils';
import data from '../../../molecules/data.json';
import { PLANNING_VIEW_OPTIONS, SALES_VIEW_OPTIONS } from 'pages/microservices/planning/constants/planningConstants';
import { setCurrentViewUnit } from 'pages/microservices/planning/utils/utils';

export const initialPlanningProps = {
    view: PLANNING_VIEW_OPTIONS[0],
    currentViewUnit: setCurrentViewUnit(PLANNING_VIEW_OPTIONS[0]),
    currentViewYear: new Date().getFullYear(),
    planningEvents: [],
    type:'',
    salesview: SALES_VIEW_OPTIONS[0]
}


