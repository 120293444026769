import React, { useEffect, useState } from "react";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import Calender from "components/molecules/Calender";
// import RenderInflationInputFields from "./RenderInflationInputFields";
import { LabelWithInputFieldNoError } from "components/molecules/LabelWithInputFieldNoError";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import { Option, OptionProps } from "utils/TypeAnnotations";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { mapToSelect } from "utils/MapToSelect";
import { scrollToTop } from "services/validation/ValidationService";
import CustomNotify from "components/atoms/CustomNotify";
import "./inflation.css";
import { M_COMPANY_CREATION } from "../../../../../../constants/Constants";

interface RegionCountry {
  region: Option | null;
  regionError: boolean;
  country: Option | null;
  countryError: boolean;
  fromDate: Date | null;
  fromDateError: boolean;
  tableStatus: boolean;
  dbError: string | null;
  dbDate: Date | null;
}
interface RangeProfileTypes {
  min: string | null;
  desired: string | null;
  max: string | null;
}

interface EmployeeTypeObj {
  categotyExist: boolean;
  category: string | null;
  employeeType: string;
  inflationFactor: string;
  inflationFactorStatus: boolean;
  [key: number]: RangeProfileTypes;
}

interface EmployeeCategoryType {
  [key: number]: EmployeeTypeObj;
}

interface InitialState {
  [key: number]: EmployeeCategoryType;
}

const InflationCoefficientsForm = () => {
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const navigate = useNavigate();
  const isViewMode = location.pathname.includes("view");
  //initialize basic state
  const [formFields, setFormFields] = useState<RegionCountry>({
    region: null,
    regionError: false,
    country: null,
    countryError: false,
    fromDate: null,
    fromDateError: false,
    tableStatus: false,
    dbError: null,
    dbDate: null,
  });
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [data, setData] = useState<InitialState>({});
  const [dropdownDetails, setDropdownDetails] = useState({
    regions: [] as OptionProps[],
    countries: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchNestedInflationsObj = async () => {
      const url =
        id !== undefined
          ? `${ENDPOINTS.GET_NESTED_INFLATIONS}/${id}`
          : ENDPOINTS.GET_NESTED_INFLATIONS;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_COMPANY_CREATION,
        false
      );
      setData(response.data.inflationObj);
      setDropdownDetails({
        regions: mapToSelect(response.data["regions"]),
        countries: response.data["countries"],
      });
      setFormFields((prevData) => ({
        ...prevData,
        region: response.data["region"],
        country: response.data["country"],
        fromDate:
          response.data["fromDate"] !== null
            ? new Date(response.data["fromDate"])
            : null,
        dbDate:
          id && response.data["fromDate"] !== null
            ? new Date(response.data["fromDate"])
            : null,
      }));
    };
    fetchNestedInflationsObj();
  }, [id]);

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    fileIdError: string
  ) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setFormFields((prevData) => ({
        ...prevData,
        country: null,
      }));
    }
    setFormFields((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption.value !== "" ? selectedOption : null,
      [fileIdError]: selectedOption.value === "" ? true : false,
    }));
  };

  const handleDateChange = (date: Date | null) => {
    const updatedDate = date !== null ? date : null;
    setFormFields((prevData) => ({
      ...prevData,
      fromDate: updatedDate,
      fromDateError: updatedDate === null ? true : false,
      dbError: null,
    }));
  };

  const updateIndexBasedCoefficients = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string
  ) => {
    const value = event.target.value;
    const employeeType = parseInt(empType);
    const newData = { ...data };
    const newValue = value.replace(/[^0-9,]/g, "");
    const regex = /^(\d{1,2})(,\d{0,4})?$/;
    let matches = regex.exec(newValue);
    if (matches || value === "") {
      newData[category][employeeType]["inflationFactor"] = newValue;
      if (value === "" || value === null) {
        newData[category][employeeType]["inflationFactorStatus"] = true;
      } else {
        newData[category][employeeType]["inflationFactorStatus"] = false;
      }
    }
    setData(newData);
  };

  function hasNonEmptyInflation(nestedObj: any) {
    var booleanStatus = false;
    for (const key in nestedObj) {
      for (const key1 in nestedObj[key]) {
        const newObj = { ...data };
        if (
          nestedObj[key][key1].inflationFactor === null ||
          nestedObj[key][key1].dbFromDateinflationFactor === ""
        ) {
          newObj[parseInt(key)][parseInt(key1)].inflationFactorStatus = true;
          setData(newObj);
          booleanStatus = true;
        } else {
          newObj[parseInt(key)][parseInt(key1)].inflationFactorStatus = false;
          setData(newObj);
        }
      }
    }
    return booleanStatus;
  }

  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const regionBasedCountries = dropdownDetails.countries.filter(
        (country) =>
          country.region_id !== undefined &&
          selectedRegion === country.region_id
      );
      return mapToSelect(regionBasedCountries);
    }
    return [];
  };

  const checkFormValidation = () => {
    setFormFields((prevData) => ({
      ...prevData,
      regionError:
        formFields?.region?.hasOwnProperty("value") &&
        formFields.region.value !== null
          ? false
          : true,
      countryError:
        formFields?.country?.hasOwnProperty("value") &&
        formFields.country.value !== null
          ? false
          : true,
      fromDateError: formFields.fromDate !== null ? false : true,
      tableStatus: hasNonEmptyInflation(data),
    }));
    return (
      formFields?.region?.hasOwnProperty("value") &&
      formFields.region.value !== null &&
      formFields?.country?.hasOwnProperty("value") &&
      formFields.country.value !== null &&
      formFields.fromDate !== null &&
      hasNonEmptyInflation(data)
    );
  };

  const decideFromDate = (date: Date | null, flow: string) => {
    if (flow === "edit" && date !== null && date > new Date()) {
      return date;
    } else {
      return new Date();
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formIsValid = checkFormValidation();

    if (
      formIsValid !== undefined &&
      formIsValid !== true &&
      formFields.fromDate !== null
    ) {
      const dataToStore = {
        fromDate: formFields.fromDate ? formFields.fromDate : null,
        region: formFields.region,
        country: formFields.country,
        inflations: data,
      };
      const url =
        id !== undefined
          ? `${ENDPOINTS.SAVE_INFLATIONS}/${id}`
          : ENDPOINTS.SAVE_INFLATIONS;
      const response = await ApiCall.service(
        url,
        "POST",
        dataToStore,
        false,
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        navigate("/manage/inflations");
        CustomNotify({
          type: "success",
          message: response.msg,
          autoClose: 2000,
        });
      }
      if (response.status === 400) {
        setFormFields((prevData) => ({
          ...prevData,
          dbError: response.msg,
        }));
      }
    } else {
      scrollToTop();
      CustomNotify({
        type: "error",
        message: "Please check the errors",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      <div className="">
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">Add inflations</h1>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {formFields.dbError && (
          <span className="text-danger">{formFields.dbError}</span>
        )}
        <div className="row">
          <div className="col-4">
            <Calender
              onChange={(date) => handleDateChange(date)}
              selectedDate={formFields.fromDate}
              label="From date"
              name="inflationStartDate"
              minDate={
                id
                  ? decideFromDate(formFields.dbDate, "edit")
                  : decideFromDate(new Date(), "create")
              }
              maxDate={null}
              isMandatory={true}
              isDisabled={isViewMode}
              error={formFields.fromDateError ? "This field is required" : null}
            />
          </div>
          <div className="col-4">
            <SelectWithSearch
              title="Region"
              search={true}
              options={dropdownDetails.regions}
              isDisabled={id ? true : false}
              placeHolder="Select region"
              onChange={(e) => handleSelectChange(e, "region", "regionError")}
              isMulti={false}
              className="select-field"
              name="region"
              value={formFields.region}
              error={formFields.regionError ? "This field is required" : null}
            />
            {}
          </div>
          <div className="col-4">
            <SelectWithSearch
              title="Country"
              search={true}
              options={getCountriesForRegion()}
              placeHolder="Select country"
              isDisabled={id ? true : false}
              onChange={(e) => handleSelectChange(e, "country", "countryError")}
              isMulti={false}
              className="select-field"
              name="country"
              value={formFields.country}
              error={formFields.countryError ? "This field is required" : null}
            />
          </div>
        </div>
        <div className="">
          {formFields.tableStatus && (
            <p className="text-danger">
              Fill in all the blank fields that are highlighted.
            </p>
          )}
        </div>
        <table className="table table-bordered manage-inflation-table">
          <thead>
            <tr className="TableHeader align-middle">
              <th className="text-center">Employee category types</th>
              <th className="text-center align-middle" scope="rowgroup">
                Employee type
              </th>
              <th className="text-center align-middle" scope="rowgroup">
                Inflations
              </th>
              {/* <th colSpan={3} className="text-center" scope="colgroup">
                  Payroll
                </th>
                <th colSpan={3} className="text-center" scope="colgroup">
                  Selectie
                </th> */}
            </tr>
            {/* <tr className="">
                <th className="text-center">
                  <strong>Min</strong>
                </th>
                <th className="text-center">
                  <strong>Desired</strong>
                </th>
                <th className="text-center">
                  <strong>Nice to have</strong>
                </th>
                <th className="text-center">
                  <strong>Min</strong>
                </th>
                <th className="text-center">
                  <strong>Desired</strong>
                </th>
                <th className="text-center">
                  <strong>Nice to have</strong>
                </th>
              </tr> */}
          </thead>
          <tbody>
            {data &&
              Object.keys(data).map(
                (key: any) =>
                  data[key] &&
                  Object.entries(data[key]).map(([key1, value]) => (
                    <tr key={key1} className="align-middle ">
                      {value["categotyExist"] && (
                        <th
                          className="text-center text-break"
                          rowSpan={Object.keys(data[key]).length}
                          scope="rowgroup"
                        >
                          {value["category"]}
                        </th>
                      )}
                      <th scope="row" className="text-break">
                        {value["employeeType"]}
                      </th>
                      <td
                        scope="row"
                        className={`form-group p-0 ${
                          data[key][parseInt(key1)].inflationFactorStatus
                            ? "coefficientBorderHighlight"
                            : ""
                        }`}
                      >
                        <LabelWithInputFieldNoError
                          type="text"
                          className="coefficient w-100 h-100 border-0 rounded-0"
                          value={
                            value["inflationFactor"]
                              ? value["inflationFactor"]
                              : ""
                          }
                          handleChange={(e) =>
                            updateIndexBasedCoefficients(e, key, key1)
                          }
                          isDisabled={isViewMode}
                        />
                      </td>
                      {/* <RenderInflationInputFields
                          inputObj={value[0]}
                        />
                        <RenderInflationInputFields
                          inputObj={value[1]}
                        /> */}
                    </tr>
                  ))
              )}
          </tbody>
        </table>
        <div className="row mb-3">
          <div className="col-md-4 align-self-center">
            <Link
              to="/manage/inflations"
              className="text-uppercase back-btn text-decoration-underline"
            >
              Back
            </Link>
          </div>
          {!isViewMode && (
            <div className="col-md-8">
              <Button
                title="submit"
                type="submit"
                className="btn form-button float-end text-uppercase rounded-0 shadow-none"
              />
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default InflationCoefficientsForm;
