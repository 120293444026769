export const activeFunction = (payload:any,dependentFields:any)=>{
    let { fieldData, state } = payload;
    const fieldValue = fieldData.value;
    let newState = state.map((item: any) => {
      if (item.name === fieldData.field) {
        item.value = fieldValue;
        item.error = fieldData.error;
      }
      return item
 } )
return newState

}