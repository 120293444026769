import { ApiCall } from 'services/ApiServices';
import { FETCH_PLANNING, FETCH_SALES } from 'routes/ApiEndpoints';

const fetchEmployeeData = async (params, type) => {
    let url = FETCH_PLANNING;
    if(type == 'sales') 
        url = FETCH_SALES;

    const response = await ApiCall.service(
        url,
        "POST",
        params,
        true,
        "m-masterdata"
    );
    const employees = [];
    const plans = []
    const emplist = [];
    if (response?.status == 200 && response?.data) {
        let data = response.data.details ? response.data.details : response.data;
        data.map((employee) => {
            employees.push({ id: employee.employee_number, name: employee.employee_name });
            emplist.push({ value: employee.employee_number, label: employee.employee_name });
            plans[employee.employee_number] = employee
        });
    }
    return {employees : employees, plans : plans, emplist: emplist};

}

export const initialEmployeeProps = async (params, type) => {
    // const [employees, plandetails] = await fetchEmployeeData();
    // return [ employees, plandetails ];
    const response = await fetchEmployeeData(params, type);
        return response;
    
}
