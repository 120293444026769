import React from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";

interface ShiftTableProps {
  title?:string,
  options: any[];
  value: any;
  onChange: (selectedOptions: any) => void;
  error?: any;
}

const ShiftTableMolecule: React.FC<ShiftTableProps> = ({
  title = t("Time Sheet"),
  options,
  value,
  onChange,
  error,
}) => {  
  return (
    <div className="col-sm-12 col-md-12">
      <SelectWithSearch
        title={title}
        name={"shift_name"}
        isMandatory={true}
        search={true}
        options={options}
        placeHolder={t("Select")}
        value={value}
        onChange={onChange}
        isMulti={true}
        className={"select-field"}
        error={error}
      />
    </div>
  );
};

export default ShiftTableMolecule;
