import React from 'react'

const HrManage = () => {

  const days = ["1", "2", "3", "4", "5"];
  const weeks = ["4.12", "5.12", "6.12", "7.12", "8.12", "9.12", "10.12",]
  const blocks = ["", "", "", "", "", "", ""]
  const inner = ["1", "2", "3", "4", "5", "6", "7"]

  interface BoxProps {
    width?: any;
    data?: any;
    bg_color?: any;
    color?: any;
  }

  const Box: React.FC<BoxProps> = ({ width, data, bg_color, color }) => {
    return (
      <span className="d-flex align-items-center justify-content-center" style={{ width: `${width}`, height: "50px", background: `${bg_color ? bg_color : "grey"}`, color: `${color ? color : "white"}` }}>{data}</span>
    );
  };

  return (
    <>


      <div className="d-flex justify-content-between mb-4">
        <input type="search" className='btn btn-light' />
        <button className='btn btn-info'>button1</button>
        <button className='btn btn-info'>button2</button>
        <button className='btn btn-info'>button3</button>
        <button className='btn btn-info'>button4</button>
        <input type="search" className='btn btn-light' />
        <button className='btn border'>Datum van</button>
        <button className='btn border'>Datum tot</button>
      </div>


      <div className="row">
        <div className="col-2"></div>
        <div className="col-10 ms-auto">
          <div className="d-flex justify-content-between">
            <div> &larr;  deze week</div>
            <div>  volgende week &rarr; </div>
          </div>
        </div>
        <div className="col-2 text-center p-0">
          <table className='w-100'>
            <thead>
              <tr><th className='py-3'>medweker</th></tr>
            </thead>
            <tbody>
              {
                days.map(items =>
                  <tr><td ><div className="mx-1  mx-1 bg-secondary" style={{ height: "60px", }}>{items}</div></td></tr>
                )
              }
            </tbody>
          </table>
        </div>

        <div className="col-10">
          <div className="row">
            <div className="col-6 ps-0">
              <table className='w-100 text-center'>
                <thead>
                  <tr>
                    {weeks.map(weeks =>
                      <th className='py-3'>{weeks}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                </tbody>
              </table>
            </div>


            <div className="col-6 ps-0">
              <table className='w-100 text-center'>
                <thead>
                  <tr>
                    {weeks.map(weeks =>
                      <th className='py-3'>{weeks}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                  <tr>
                    {
                      blocks.map(blocks =>
                        <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                      )
                    }
                  </tr>
                </tbody>
              </table>
            </div>

          </div>



        </div>
      </div>


      <div className="mt-4">
        <div className="row">
          <div className="col-2 text-center p-0">
            <table className='w-100'>
              <thead>
                <tr><th className='py-3'>medweker</th></tr>
              </thead>
              <tbody>
                {
                  days.map(items =>
                    <tr><td ><div className="mx-1  mx-1 bg-secondary" style={{ height: "60px", }}>{items}</div></td></tr>
                  )
                }
              </tbody>
            </table>
          </div>

          <div className="col-10">
            <div className="row">
              <div className="col-6 ps-0">
                <table className='w-100 text-center'>
                  <thead>
                    <tr>
                      {weeks.map(weeks =>
                        <th className='py-3'>{weeks}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary " style={{ height: "50px", }}>{blocks}</div></td>
                        )
                      }
                    </tr>
                  </tbody>
                </table>
              </div>


              <div className="col-6 ps-0">
                <table className='w-100 text-center'>
                  <thead>
                    <tr>
                      {weeks.map(weeks =>
                        <th className='py-3'>{weeks}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary d-flex justify-content-around" style={{ height: "50px", }}>
                            {
                              inner.map(inner =>
                                <span className='bg-danger'>{inner}</span>
                              )
                            }
                          </div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary d-flex justify-content-around" style={{ height: "50px", }}>
                            {
                              inner.map(inner =>
                                <span className='bg-danger'>{inner}</span>
                              )
                            }
                          </div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary d-flex justify-content-around" style={{ height: "50px", }}>
                            {
                              inner.map(inner =>
                                <span className='bg-danger'>{inner}</span>
                              )
                            }
                          </div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary d-flex justify-content-around" style={{ height: "50px", }}>
                            {
                              inner.map(inner =>
                                <span className='bg-danger'>{inner}</span>
                              )
                            }
                          </div></td>
                        )
                      }
                    </tr>
                    <tr>
                      {
                        blocks.map(blocks =>
                          <td className=''><div className="mx-1 bg-secondary mx-1 bg-secondary d-flex justify-content-around" style={{ height: "50px", }}>
                            {
                              inner.map(inner =>
                                <span className='bg-danger'>{inner}</span>
                              )
                            }
                          </div></td>
                        )
                      }
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>



          </div>
        </div>

      </div>



      
    </>
  )
}

export default HrManage


// import React from 'react'
// import Competence from 'components/molecules/Competence'
// const HrManage = () => { 
//   return (
//     <>
//     <Competence 
//     handleClose={() => {}}
//     handleAdd={() => {}}
//     competenceOptions={() => {}}
//     />
//     </>
//   )
// }

// export default HrManage