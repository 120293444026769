import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_PROJECTS_CREATE,
  PATH_PROJECTS_EDIT,
  PATH_MATCHING,
  PATH_PROJECTS_MANAGE,
  PATH_EMPLOYEE_MANAGE,
  PATH_PROJECT_PROPOSAL,
} from "constants/Paths";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  PROJECT_ARCHIVE,
  DOWNLOAD_PROJECT_PDF,
  COMPANY_LIST,
  ADD_TO_PROJECT,
  GET_FILTERED_PROJECTS,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import {
  getAgreementStatusColor,
  getAgreementTooltipMessage,
  getProjectStatusColor,
  getProjectTooltipMessage,
  getStatusColor,
  getTooltipMessage,
} from "services/util/UtilService";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AddPlanningIcon from "static/images/AddPlanningIcon";
import EncryptDecryptService from "services/EncryptDecryptService";
import RightSideBar from "../masterData/follow-up-todo/RightSideBar";
import { IconData } from "services/IconData";
import Calender from "components/molecules/Calender";

interface ManageProjectProps {
  data?: any[];
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number | Date) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  action?: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

export const ManageProjects: React.FC<ManageProjectProps> = ({
  data,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  action,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [companyList, setCompanyList] = useState<CompanyItem[] | null>(null);
  const projectStatus = ["Inactive project", "Active project"];
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [sideBarWidth, setSidebarWidth] = useState("");

  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    searchValue(name, value);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.getService(
        `${PROJECT_ARCHIVE}/${id}`,
        "GET",
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        action((prev: any) => !prev);
        // handleData((prevData: any) =>
        //   prevData.filter((data: any) => data.id !== id)
        // );
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      const response = await ApiCall.getService(
        COMPANY_LIST,
        "get",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setCompanyList(response?.data);
      } else {
        console.error(ERROR);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_PROJECTS_EDIT}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const handleDownload = async (id: number) => {
    const response = await ApiCall.getService(
      `${DOWNLOAD_PROJECT_PDF}/${id}`,
      "GET",
      M_MASTER_DATA
    );
    if (response && response.download_url) {
      const link = document.createElement("a");
      link.href = response.download_url;
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    } else {
      //   console.error("Invalid response or missing download URL");
    }
  };
  const handleSendProjectProposal = (
    projectId: number,
    clientMailStatus: any,
    managerMailStatus: any,
    user: any
  ) => {
    const userIds = user.map((item: { user_id: any }) => item.user_id);
    const data = {
      id: projectId,
      clientMailStatus: clientMailStatus,
      ManagerMailStatus: managerMailStatus,
      users: userIds,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_PROJECT_PROPOSAL}/?data=${encodedData}`);
  };
  const handleMatching = (projectId: number, companyId: number) => {
    navigate(`${PATH_MATCHING}/${projectId}/${companyId}`);
  };
  const handleLink = (projectId: number, companyId: number) => {
    navigate(`${PATH_EMPLOYEE_MANAGE}/${projectId}/${companyId}`);
  };

  const handleFollowup = (
    event: any,
    id: string | number | null,
    businessUnit: string | number | null
  ) => {
    // event.preventDefault(); // Prevent the default link action
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };
  const handleDateChangeCheck = (value: Date | null, fieldName: string) => {
    const updatedDate = value ?? new Date();

    // Update startDate or endDate based on the fieldName
    let startDate =
      fieldName === "startDate"
        ? updatedDate
        : searchData.startDate ?? new Date();
    let endDate =
      fieldName === "endDate" ? updatedDate : searchData.endDate ?? new Date();

    // Call searchValue with the correct fieldName and updatedDate
    searchValue(fieldName, updatedDate);
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Project",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="row header-sticky position-sticky">
            <div className="col-md-12">
              <h1 className="py-4 page-title mb-0">{t("Manage projects")}</h1>
            </div>
          </div>
          <div className="row pb-4 search-bar">
            <SearchWithLabel
              label={t("Project name")}
              isMandatory={false}
              showLabel
              name={"project"}
              type={"text"}
              value={searchData?.project}
              placeholder={t("Project name")}
              isDisabled={false}
              handleChange={handleSearchChange}
              className="col"
            />
            <div className="col">
              <div className="">
                <label className="form-label field-label">{t("Company")}</label>
              </div>
              <select
                className="form-select shadow-none rounded"
                name="company"
                id="company"
                onChange={handleSearchChange}
                value={searchData?.company}
              >
                {companyList && companyList.length > 0 ? (
                  <>
                    <option value="">{t("Company")}</option>
                    {companyList.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No companies available")}</option>
                )}
              </select>
            </div>

            {/* <SearchWithLabel
              label={t("Start date or end date")}
              isMandatory={false}
              showLabel
              name={"date"}
              type={"date"}
              value={searchData?.date}
              placeholder={t("Start date or end date")}
              isDisabled={false}
              handleChange={handleSearchChange}
              className="col"
            /> */}
            <div className="col">
              <Calender
                onChange={(date) => handleDateChangeCheck(date, "startDate")}
                selectedDate={searchData.startDate}
                label={t("Start date")}
                isMandatory={false}
                name={"startDate"}
                isDisabled={false}
              ></Calender>
            </div>
            <div className="col">
              <Calender
                onChange={(date) => handleDateChangeCheck(date, "endDate")}
                selectedDate={searchData.endDate}
                label={t("End date")}
                isMandatory={false}
                name={"endDate"}
                isDisabled={false}
              ></Calender>
            </div>
            <div className="col">
              <div className="">
                <label className="form-label field-label">{t("Status")}</label>
              </div>
              <select
                className="form-select shadow-none"
                name="status"
                id="status"
                onChange={handleSearchChange}
                value={searchData.status}
              >
                <option value="">{t("Status")}</option>
                {projectStatus?.map((optn, key) => (
                  <option key={key} value={key + 1}>
                    {optn}
                  </option>
                ))}
              </select>
            </div>
            <div className="col text-end mt-auto">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
          </div>

          <div className="p-5 border rounded-4 table-custom">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Project",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to={PATH_PROJECTS_CREATE} className="table-button">
                  + {t("Create Project")}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive manage-companies">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Project")}</th>
                    <th>{t("Company")}</th>
                    <th>{t("Location")}</th>
                    <th>{t("Start date")}</th>
                    <th>{t("End date")}</th>
                    <th>{t("Project status")}</th>
                    <th>{t("Agreement status")}</th>
                    <th className="table_actions">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record, key) => {
                      const canDownload =
                        record.proposal_status === 1 &&
                        record?.project_documents?.project_document_path
                          ?.status;
                      const canViewProposal =
                        record.proposal_status === 0 ? true : false;
                      return (
                        <tr key={key}>
                          <td className="align-middle">{record.name}</td>
                          <td className="align-middle">
                            {record?.company?.name}
                          </td>
                          <td className="align-middle">
                            {record?.locations[0]?.name}
                          </td>
                          <td className="align-middle">{record?.startdate}</td>
                          <td className="align-middle">{record?.enddate}</td>

                          <td className="align-middle">
                            <div
                              className={getProjectStatusColor(record.isActive)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getProjectTooltipMessage(record.isActive)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <div
                              className={getAgreementStatusColor(
                                record.proposal_status
                              )}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getAgreementTooltipMessage(
                                record.proposal_status
                              )}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleArchive={handleArchive}
                              handleSendProjectProposal={
                                record.proposal_status !== 1
                                  ? () =>
                                      handleSendProjectProposal(
                                        record.id,
                                        record.mail_send_client,
                                        record.mail_send_manager,
                                        record.project_contact_persons
                                      )
                                  : undefined
                              }
                              // handleDownload={handleDownload}
                              handleDownload={
                                canDownload ? handleDownload : undefined
                              }
                              handleMatching={
                                record.isActive == 2 &&
                                record.proposal_status == 1
                                  ? () =>
                                      handleMatching(
                                        record.id,
                                        record?.company?.value
                                      )
                                  : undefined
                              }
                              linkIcon={record && record.status.length > 0}
                              handleLink={() =>
                                handleLink(record.id, record?.company?.value)
                              }
                              followupIcon={record && record.isActive === "2"}
                              handleFollowup={(e) => {
                                handleFollowup(
                                  e,
                                  record.id,
                                  record?.project_business_units
                                );
                              }}
                              value={record.id}
                              permission={"Project"}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 align-self-center my-4">
          <Link
            to={"/"}
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {followupSideBar && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"project"}
            businessUnit={entity.businessUnit}
            entityId={entity.entityId}
            onClose={handleCloseSidebar}
            getWidth={getWidth}
            recordsLimit={10}
            className="right-sidebar p-3"
            titleClassName="mt-4"
            destination="/projects"
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageProjects;
