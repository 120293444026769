import React from "react";
import RadioField from "components/atoms/RadioField";
import LabelField from "components/atoms/LabelField";

interface TitleFieldMoleculeProps {
  name?: string;
  label?: string;
  fields?: any;
  value?: string | number | undefined;
  handleChange: any;
  error: string;
}

const RadioMolecule: React.FC<TitleFieldMoleculeProps> = ({
  name,
  label,
  fields,
  value,
  handleChange,
  error,
}) => {
  return (
    <div className="col-6 mb-3">
      <LabelField
        title={label}
        isMandatory
        htmlfor={name}
        className={" form-label field-label col-12"}
      />
      {fields.map((field: any, index: number) => (
        <RadioField
          key={index}
          name={name}
          value={field?.value}
          ischecked={field?.value === value}
          handleChange={handleChange}
          label={field?.label}
          id={field?.label}
        />
      ))}
    </div>
  );
};
export default RadioMolecule;
