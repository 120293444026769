import React from "react";
import ShiftInputCell from "./ShiftInputCell";
import { SHIFT_FIELDS } from "pages/microservices/project/context/Constants";
import { error } from "console";

interface ShiftRowProps {
  day: string;
  shiftData: any;
  onInputChange: (day: string, field: string, value: string) => void;
  disabled?: any;
  error?: any;
}

const ShiftColumn: React.FC<ShiftRowProps> = ({
  day,
  shiftData,
  onInputChange,
  disabled,
  error = ""
}) => {  
  return (
      <tbody>
        {Object.keys(SHIFT_FIELDS).map((field: string, index: number) => (
          <ShiftInputCell
            key={`${day}-${field}`}
            value={shiftData[field]}
            type={field}
            error={error?.[field]}
            disabled={disabled}
            onChange={(value: string) => onInputChange(day, field, value)}
          />
        ))}
      </tbody>
  );
};

export default ShiftColumn;
