import { ManageHolidaysPerPC } from "TableHeader";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import {
  EDIT_LINK_HOLIDAY_PER_PC,
  HOLIDAY_CONFIGS,
  LINK_HOLIDAY_PER_PC,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DELETE_HOLIDAY_PER_PC,
  GET_HOLIDAYS_AND_PC,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";

interface SearchData {
  pc_name: any;
}
interface DataProps {
  page: number;
  search?: any;
}
interface PC {
  name: string;
  id: number;
}
interface PcOptions{
  label:any;
  name:any;
  value:any;
}
const ManageHolidayPerPC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    pc_name: queryParams.get("year") ?? "",
  });
  const [pcData, setPcData] = useState<PC[]>([]);
  //const [holidaysData, setHolidaysData] = useState([...holidayDetails]);
  const [id, setId] = useState<number | undefined>(undefined);
  const [pcOptions,setPcOptions]=useState<PcOptions[]>([]);
  //fetch data of PCs which are linked previously

  //onChange event handler for select element
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({ pc_name: "" });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: SearchData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //onClick edit handler
  const handleEdit = (entity: any) => {
    navigate(`${EDIT_LINK_HOLIDAY_PER_PC}/${entity.id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (entity: any) => {
    setId(entity.id);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_HOLIDAY_PER_PC,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        setPcData((prevdata) =>
          (prevdata as PC[]).filter((pcData) => pcData.id !== id)
        );

        setCurrentPage(1);
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };
  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };
    fetchPcPerHolidays(data); //To get all public holiday details
  }, [currentPage, submit]);

  const fetchPcPerHolidays = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_HOLIDAYS_AND_PC,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setPcOptions(response?.pcOptions);
        setPcData(response?.data);
        //conconsolest { data, totalPages } = response.data;
        //   setTotalPages(totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };
  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <TitleFieldMolecule title={t("Manage holiday per paritair committee")} />
          <div className="row pb-4 mb-4 search-bar">
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">
                  {t("PC")}
                </label>
              </div>
              <select
                className="form-select shadow-none rounded"
                name="pc_name"
                id="pc_name"
                onChange={handleSelectChange}
                value={search.pc_name}
              >
                {pcOptions && pcOptions.length > 0 ? (
                  <>
                    <option value="">{t("Select pc name")}</option>
                    {pcOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No pc available")}</option>
                )}
              </select>
            </div>
            <div className="col mt-auto text-end">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
          </div>
            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                <Link
                  to={LINK_HOLIDAY_PER_PC}
                  className="table-button"
                >
                  + {t("Link holiday per PC")}
                </Link>
              </div>
              <DynamicTableStructure
                isAction={true}
                data={pcData}
                headers={ManageHolidaysPerPC}
                name={"manage_public_holidays"}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center my-4">
        <Link
          to={HOLIDAY_CONFIGS}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidayPerPC;
