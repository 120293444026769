import { LabelWithInputFieldNoError } from "components/molecules/LabelWithInputFieldNoError";
import "./lowDefaultCoefficient.css";
import React from "react";

interface ChildProps {
  inputObj: any;
  employeeCategoryId: number;
  employeeType: string;
  payType: number;
  updateCofficientObj: (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string,
    payType: number,
    key: string
  ) => void;
}

const RenderInputFields: React.FC<ChildProps> = ({
  inputObj,
  employeeCategoryId,
  employeeType,
  payType,
  updateCofficientObj,
}) => {
  return (
    <>
      <td
        className={`form-group ${
          inputObj.minStatus ? "coefficientBorderHighlight" : ""
        }`}
      >
        <LabelWithInputFieldNoError
          type="text"
          className="coefficient w-100 h-100 border-0 rounded-0"
          value={inputObj.min ? inputObj.min : ""}
          handleChange={(e) =>
            updateCofficientObj(
              e,
              employeeCategoryId,
              employeeType,
              payType,
              "min"
            )
          }
        />
      </td>
      <td
        className={`form-group ${
          inputObj.desiredStatus ? "coefficientBorderHighlight" : ""
        }`}
      >
        <LabelWithInputFieldNoError
          type="text"
          className="coefficient w-100 h-100 border-0 rounded-0"
          value={inputObj.desired ? inputObj.desired : ""}
          handleChange={(e) =>
            updateCofficientObj(
              e,
              employeeCategoryId,
              employeeType,
              payType,
              "desired"
            )
          }
        />
      </td>
      <td
        className={`form-group ${
          inputObj.maxStatus ? "coefficientBorderHighlight" : ""
        }`}
      >
        <LabelWithInputFieldNoError
          type="text"
          className="coefficient w-100 h-100 border-0 rounded-0"
          value={inputObj.max ? inputObj.max : ""}
          handleChange={(e) =>
            updateCofficientObj(
              e,
              employeeCategoryId,
              employeeType,
              payType,
              "max"
            )
          }
        />
      </td>
    </>
  );
};

export default RenderInputFields;
