import React from 'react'
import ManagePayrollFilters from '../molecules/ManagePayrollFilters'
import ManagePayrollHeaders from '../molecules/ManagePayrollHeaders'


interface WorkPayBillRollFiltersProps {
    handlePrint: (type: string, page?: string) => void;
}

const WorkPayBillRollFilters: React.FC<WorkPayBillRollFiltersProps> = ({ handlePrint }) => {
    return (
        <>
            <ManagePayrollHeaders handlePrint={handlePrint} />
            <ManagePayrollFilters />
        </>
    )
}

export default WorkPayBillRollFilters