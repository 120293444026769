import React, { useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { EmployeeTypeTableHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  ARCHIVE_EMPLOYEE_TYPE,
  CREATE_EMPLOYEE_TYPE,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { EmployeeType } from "./Manage";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_CREATE_EMPLOYEE_TYPE, PATH_MASTER_DATA } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface ManageEmployeeTypePageProps {
  employeeTypeObj: EmployeeType[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

const ManageEmployeeTypePage: React.FC<ManageEmployeeTypePageProps> = ({
  employeeTypeObj,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const transformedData = employeeTypeObj.map((item) => {
    let category;
    if (item.category_id === 1) {
      category = "White collar";
    } else if (item.category_id === 2) {
      category = "Blue collar";
    } else {
      category = "Unknown"; // default value if category_id is not 1 or 2
    }

    return {
      ...item,
      category, // add the new category field
    };
  });
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };
  let pageIndex = (currentPage - 1) * itemsPerPage + 1;

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${ARCHIVE_EMPLOYEE_TYPE}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleDelete = (EmployeeType: EmployeeType | any) => {
    setShowModal(true);
    setDeleteId(EmployeeType.id);
  };

  const handleEdit = (EmployeeType: EmployeeType | any) => {
    if (EmployeeType.id) {
      navigate(`${PATH_CREATE_EMPLOYEE_TYPE}/${EmployeeType.id}`);
    }
  };

  const userdata = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Employee type",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userdata.isSuperAdmin}
    >
      <div>
        <div className="form-height-dashboard">
          <TitleFieldMolecule title={t("Manage employee type")} />
          <div className="row pb-3 search-bar">
            <div className="col-8">
              <div className="">
                <label className="form-label field-label">
                  {t("Employee type")}
                </label>
              </div>
              <Search
                className="form-control col-md-12 col-lg-12 mb-3"
                handleChange={handleSearchChange}
                placeholder={t("Search")}
                name="employee_type_name"
                value={searchData}
              />
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Employee type",
                    create: true,
                  },
                ]}
                override={userdata.isSuperAdmin}
              >
                <Link
                  to={PATH_CREATE_EMPLOYEE_TYPE}
                  className="table-button"
                >
                  + {t("Create Employee type")}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive">
              <DynamicTableStructure
                isAction
                headers={EmployeeTypeTableHeader}
                data={transformedData}
                handleEdit={handleEdit}
                handleArchive={handleDelete}
                permission={"Employee type"}
              />
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          <div className="my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
    </AccessControl>
  );
};
export default ManageEmployeeTypePage;
