import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";

import { M_MASTER_DATA } from "constants/Constants";
import { CARS, FETCH_COMPANY_BU, GET_TEMP_AGENCY } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ManageTempAgencyPage from "./ManageTemAgencyPage";

// Define the prop types for the component
interface TempAgencyProps {
  id?: string | number;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: Partial<TempAgencyProps>;
}

// Functional component using TypeScript
const ManageTempAgency: React.FC<TempAgencyProps> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const itemsPerPage = 10;
  const [materialData, setMaterialData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState<any>({
    company: queryParams.get("company") ?? "",
    businessUnit: queryParams.get("businessUnit") ?? "",
    tempAgency: queryParams.get("tempAgency") ?? "",
    
  });

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const postData: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
    };
    getTempAgencyData(postData);
  }, [currentPage, submit]);

  const getTempAgencyData = async (postData: DataProps) => {
    const response = await ApiCall.service(
      GET_TEMP_AGENCY,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setMaterialData(response?.data);
      setTotalPages(response?.totalPages);
    } else {
      console.log("error");
    }
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch: any) => ({ ...prevSearch, [name]: value }));
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: {
    [key: string]: string | number | boolean;
  }) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]: [string, string | number | boolean]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSearchSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        company: "",
        businessUnit: "",
        tempAgency: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }

    setSubmit(!submit);
    setCurrentPage(1);
  };
  return (
    <>
      <Suspense
        fallback={
          <div className="text-center text-danger">{t("Loading")}...</div>
        }
      >
        <ManageTempAgencyPage
          data={materialData}
          handleData={setMaterialData}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          searchValue={handleSearch}
          handleSubmitAction={handleSearchSubmit}
          searchData={search}
          handleGetVacany={getTempAgencyData}
        />
      </Suspense>
    </>
  );
};

export default ManageTempAgency;
