import LabelField from "components/atoms/LabelField";
import MonthPicker from "components/atoms/MonthPicker";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import TitleFieldMolecule from "components/molecules/TitleField";
import React, { useState } from "react";
import { t } from "../translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import Button from "components/atoms/Button";
import ArrowsIcon from "static/images/Arrows";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import DatePicker from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { PATH_MANAGE_PARTENA } from "constants/Paths";
import { BusinessUnitProps, FormDataProps, initialAddPartenaData } from "./PartenaInterface";

const AddPartenaCalculation = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormDataProps>(initialAddPartenaData);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const businessUnits = [
    { value: 1, label: "business1" },
    { value: 2, label: "business2" },
    { value: 3, label: "business3" },
    { value: 4, label: "businessunit4" }
  ];

  const onChange = (selectedOption: BusinessUnitProps, field: string) => {
    setFormData((prevData: any) => {
      if (field === "businessUnit") {
        const exists = prevData.assignedBusinessUnits.some((unit: BusinessUnitProps) => unit?.value === selectedOption?.value);
        if (exists) {
          return prevData;
        } else {
          return {
            ...prevData,
            assignedBusinessUnits: [...prevData.assignedBusinessUnits, selectedOption]
          };
        }
      } else if (field === "month") {
        return {
          ...prevData,
          month: selectedOption
        };
      }
      return prevData; // Default case if field is not recognized
    });
  };

  const handleCheckBoxChange = () => {
    const newStatus: boolean = !checkBoxStatus;
    setCheckBoxStatus(newStatus);
    const businessUnit = newStatus ? businessUnits : [];
    setFormData((prevData: FormDataProps) => ({ ...prevData, assignedBusinessUnits: businessUnit }));

  }
  const handleSubmit = (e: React.FormEvent, reset: boolean) => {    
    e.preventDefault();
    if (reset) {
      setFormData((prevData) => ({ ...prevData, assignedBusinessUnits: [], month: "select" }))
    }
    const postData: { data: FormDataProps } = {
      data: formData
    }

    //  const response = ApiCall.service("storePartenaCalculation", "POST", postData, true, M_MASTER_DATA)
    const postdata = {
      data: {
        "assignedBusinessUnits": [
          { value: 1, label: 'business1' },
          { value: 2, label: 'business2' }
        ],
        "month": [
          { label: "March", value: 2 }
        ]
      }
    }    
    // if(response.status === 200){
    // CustomNotify();
    navigate("/partena");
    // }
  };
  const handleDateChange = (date: any, name: any) => {
    const value = date.year;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className=" row ">
      <div className="col">
        <MonthPicker
          label={t("Choose a month")}
          name="month"
          handleChange={(value) => onChange(value, "month")}
          value={formData.month}
          error=""
        />
      </div>
      <div className="col">
        <div className="row">
          <LabelField title={t("Choose a year")} />
        </div>
        <div className="row">
          <DatePicker
            //  value={search.year ? new Date(search.year, 0, 1) : null}
            inputClass="form-control field-shadow"
            name="date"
            placeholder={new Date().getFullYear().toString()}
            onChange={(e) => handleDateChange(e, "year")}
            format="YYYY"
            onlyYearPicker={true}
            minDate={new Date(2014, 0, 1)}
            zIndex={1000}
          // filterDate={(date: Date) => date.getFullYear() >= minimumYear} // Filter out dates before the minimum year
          />
        </div>

      </div>

      <div className="d-flex">
        <div className="border border-dark cursor-pointer flex-1">
          {businessUnits && businessUnits.length > 0 &&
            businessUnits.map((record: any, index) => (
              <>
                {index == 0 && <div className=" p-2 border-bottom-3 border-bottom border-secondary font-weight-bold">Available business unit</div>}
                <div className="p-2" onClick={() => onChange(record, "businessUnit")} key={index}>{record.label}</div>

              </>
            ))}
        </div>
        <div className="d-flex align-items-center mx-5">
          <ArrowsIcon />
        </div>
        <div className="border border-dark cursor-pointer flex-1">
          <div className="p-2 border-bottom-3 border-bottom border-secondary border-dark">Assigned business unit</div>

          {formData?.assignedBusinessUnits && formData?.assignedBusinessUnits?.length > 0 &&
            formData?.assignedBusinessUnits.map((record: any, index) => (
              <>
                <div className="p-2" onClick={() => onChange(record, "businessUnit")} key={index}>{record.label}</div>
              </>

            ))}
        </div>

      </div>
      <div className="my-3">
        <CheckBoxField
          label="Select all"
          name="selectAll"
          isChecked={checkBoxStatus}
          onChangeHandler={() => handleCheckBoxChange()}
        />
      </div>
      <div className=" d-flex justify-content-end">
        <Button
          title={t("Cancel")}
          handleClick={(e) => handleSubmit(e, true)}
          className={"btn form-button me-3  rounded shadow-none px-5"}
        />
        <Button
          title={t("Save")}
          handleClick={(e) => handleSubmit(e, false)}
          className={"btn form-button  rounded shadow-none px-5"}
        />
      </div>
    </div>
  );
}

export default AddPartenaCalculation;