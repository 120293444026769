import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { ApiCall } from "services/ApiServices";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps, ValidationRules, Option } from "utils/TypeAnnotations";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { M_MASTER_DATA } from "../../../../constants/Constants";

interface SectorProps {
  pc: Option | null;
  sector: Option | null;
  function: Option | null;
  employee: Option | null;
  coefficient: string;
  target: Option | null;
  positions: Option | null;
  invoice: Option | null;
}

const SectorAndFunction = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [formData, setFormData] = useState<SectorProps>({
    pc: null,
    sector: null,
    function: null,
    employee: null,
    coefficient: "",
    target: null,
    positions: null,
    invoice: null,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    pc: "",
    function: "",
    employee: "",
    coefficient: "",
    tartget: "",
    positions: "",
    invoice: "",
  });

  const [options, setOptions] = useState({
    pcOptions: [] as OptionProps[],
    sectorOptions: [] as OptionProps[],
    functionOptions: [] as OptionProps[],
    employeeOptions: [] as OptionProps[],
    targetOptions: [] as OptionProps[],
    positionsOptions: [] as OptionProps[],
    invoiceOptions: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const response = await ApiCall.getService(
        "getEmploymentDetails",
        "GET",
        M_MASTER_DATA
      );
      if (response.status === 200) {
        console.log(response);
        const pcOptions = mapToSelect(response.data);
        const sectorOptions = mapToSelect(response.data);

        setOptions({
          pcOptions: pcOptions,
          sectorOptions: sectorOptions,
          functionOptions: pcOptions,
          employeeOptions: pcOptions,
          targetOptions: pcOptions,
          positionsOptions: pcOptions,
          invoiceOptions: pcOptions,
        });
      }
    };
    fetchCompanyDetails();
  }, []);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      pc: [validateSelectField],
      sector: [validateSelectField],
      function: [validateSelectField],
      employee: [validateSelectField],
      coefficient: [validateRequired],
      positions: [validateSelectField],
      target: [validateMultiSelectField],
      invoice: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
    validation(name, selectedOption, true);
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validation(name, value, true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;

    if (validation(name, value)) {
      console.log(formData);
      navigate(`/employment/${id}`);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="text-center py-4 page-title mb-0`">
          <h1>Create vacancy</h1>
          <p>Cheer Up!</p>
          <p>You're just a few steps away from creating your ideal vacancy</p>
          <h2>Sector and function details</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="PC"
              name="pc"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.pcOptions}
              value={formData.pc}
              onChange={(e) => handleSelectChange(e, "pc")}
              isMulti={false}
              className="select-field"
              error={errors.pc}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Sector"
              name="sector"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.sectorOptions}
              value={formData.sector}
              onChange={(e) => handleSelectChange(e, "sector")}
              isMulti={false}
              className="select-field"
              error={errors.sector}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Function"
              name="function"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.functionOptions}
              value={formData.function}
              onChange={(e) => handleSelectChange(e, "function")}
              isMulti={false}
              className="select-field"
              error={errors.function}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Employee type"
              name="employee"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.employeeOptions}
              value={formData.employee}
              onChange={(e) => handleSelectChange(e, "employee")}
              isMulti={false}
              className="select-field"
              error={errors.employee}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="coefficient"
              handleChange={handleFieldChange}
              value={formData.coefficient}
              id="coefficient"
              label="Coefficient"
              type="text"
              error={errors.coefficient}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Target group"
              name="target"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.positionsOptions}
              value={formData.target}
              onChange={(e) => handleSelectChange(e, "target")}
              isMulti={true}
              className="select-field"
              error={errors.target}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Number of positions"
              name="positions"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.positionsOptions}
              value={formData.positions}
              onChange={(e) => handleSelectChange(e, "positions")}
              isMulti={false}
              className="select-field"
              error={errors.positions}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Invoice type"
              name="invoice"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.invoiceOptions}
              value={formData.invoice}
              onChange={(e) => handleSelectChange(e, "invoice")}
              isMulti={false}
              className="select-field"
              error={errors.invoice}
            />
          </div>
        </div>
        <div className="d-none d-md-block d-lg-block">
          <div className="row mb-4">
            <div className="col-md-4 align-self-center">
              <Link
                to={`/vacancies/${id}`}
                className="text-uppercase back-btn text-decoration-underline"
              >
                Back
              </Link>
            </div>
            <div className="col-md-8 text-end">
              <Button
                title="Next"
                type="submit"
                className="btn form-button rounded-0 shadow-none text-uppercase"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SectorAndFunction;
