import React from "react";
import DaysOfWeekTable from "./Shift"
import { t } from "../translation/Translation";
import { TIMETABLE_WEEK_DAYS } from "constants/Constants";
interface ShiftTableProps {
  selectedOption: any;
  setShifts: any;
  shifts: any;
  validate?: (day: any, value: any) => void;
  error?: any;
}

const ShiftTable: React.FC<ShiftTableProps> = ({
  selectedOption,
  setShifts,
  shifts,
  validate,
  error
}) => {
  const calculateWeeklyTotal = (shift: any) => {
    const totalMinutes = TIMETABLE_WEEK_DAYS
      .reduce((sum, day) => {
        const dayShift = shift[day];
        if (dayShift) {
          const [hours, minutes] = (dayShift.total || "00:00").split(":").map(Number);
          return sum + hours * 60 + minutes;
        }
        return sum;
      }, 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
  };
const currentShift = shifts[selectedOption.value];
return (
  <div key={selectedOption.value}>
    <div className="d-flex justify-content-between">
      <h3>{selectedOption.label}</h3>
      {currentShift && <>
        <h3>{t("Total weekly time")}: {calculateWeeklyTotal(currentShift)}</h3>
      </>}
    </div>
      <DaysOfWeekTable
        validate={validate}
        error={selectedOption?.value && error?.[selectedOption?.value]}
        selectedShift={selectedOption}
        tableIndex={selectedOption.value}
        setShifts={setShifts}
        shifts={shifts}
      />
    </div>
  );
};

export default ShiftTable;
