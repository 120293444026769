import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import {
  GET_PARTICULAR_TEMPLATE_DETAILS,
  TEMPLATE_CREATE,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import { PATH_MANAGE_TEMPLATE } from "constants/Paths";
import FormSection from "./FormSection";
import {
  validateAmount,
  validateForm,
  validateMaxTimeFormat,
  validateNumber,
  validatePercentage,
  validateRequired,
  validateTimeFormat,
} from "services/validation/ValidationService";
import CustomNotify from "components/atoms/CustomNotify";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { OverTimeDependency, OverTimeFieldDependency } from "./constants/Constants";

export type FormDataItem = {
  template_name: string;
  section_weight: number;
  form_section_title: string;
  field_name: string;
  field_id: number;
  field_type: string;
  field_weight: number;
  field_value: string | null;
  field_status: boolean;
};

type RequiredFields = {
  section: string;
  fieldId: number;
}[];

const EditTemplate: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const [doNotInvoice, setDoNotInvoice] = useState(false);
  const { id } = useParams<{ id: string }>();
  const isView = window.location.pathname.includes("view");
  const formMode = isView ? "view" : "edit";
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const editData = { id: id };
        const response = await ApiCall.service(
          GET_PARTICULAR_TEMPLATE_DETAILS,
          "POST",
          editData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          const { data } = response;
          if (data && data.length > 0) {
            setTemplateName(data[0].template_name);
            if (data[0].template_name === "Daily rate")
              setDoNotInvoice(data[3]["field_value"] == 1 ? true : false);
            let sectionsList: { [key: string]: { [key: number]: any } } = {};
            data.forEach((field: any) => {
              const { form_section_title, field_id } = field;
              if (!sectionsList[form_section_title]) {
                sectionsList[form_section_title] = {};
              }
              sectionsList[form_section_title][field_id] = field;
            });
            setFormData(sectionsList);
          }
        }
      }
    };
    fetchData();
  }, [id]);

  const handleInputChange = (
    section: string,
    fieldId: number,
    value: any,
    type: any
  ) => {
    var updatedValue;
    switch (type) {
      case "radio":
        var currentValue = formData[section][fieldId].field_value;
        updatedValue = currentValue === "0" ? "1" : "0";
        break;
      case "checkbox":
        currentValue = formData[section][fieldId].field_value;
        updatedValue = currentValue === "0" ? "1" : "0";
        setDoNotInvoice(updatedValue == "1" ? true : false);
        break;
      default:
        updatedValue = value;
        break;
    }

    if (fieldId === 5 || fieldId === 6 || fieldId === 7 || fieldId === 43) {
      updateState(section, fieldId, updatedValue, "field_value");
      if (id == "2") {
        if (doNotInvoice) {
          updateState(
            section,
            (fieldId = 7),
            (updatedValue = "1"),
            "field_value"
          );
          updateState(
            section,
            (fieldId = 6),
            (updatedValue = "0"),
            "field_value"
          );
        } else {
          updateState(
            section,
            fieldId === 6 ? 7 : 6,
            updatedValue === "0" ? "1" : "0",
            "field_value"
          );
        }
      }
      if (id == "1") {
        updateState(
          section,
          fieldId === 43 ? 6 : 43,
          updatedValue === "0" ? "1" : "0",
          "field_value"
        );
      }
    } else {
      updateState(section, fieldId, updatedValue, "field_value");
    }

    checkFieldValidation(section, fieldId, updatedValue, true);
  };

  const checkFieldValidation = (
    section: string,
    name: any,
    value: any,
    isSingleFieldValidation: boolean
  ) => {
    let validationRules: Record<string, Array<Function>> = {};

    switch (name) {
      case 9:
      case 10:
        validationRules = {
          [name]: [validateNumber],
        };
        break;

      case 11:
      case 36:
      case 37:
      case 39:
      case 40:
        validationRules = {
          [name]: [validateRequired, validateNumber],
        };
        break;

      case 20:
        validationRules = {
          [name]: [validateRequired, validateMaxTimeFormat],
        };
        break;
      case 21:
      case 22:
      case 26:
      case 27:
      case 28:
      case 32:
      case 33:
      case 34:
        validationRules = {
          [name]: [validateRequired, validateTimeFormat],
        };
        break;

      case 13:
      case 14:
      case 15:
      case 16:
      case 35:
      case 38:
      case 12:
        validationRules = {
          [name]: [validateRequired, validatePercentage],
        };
        break;

      case 41:
      case 8:
        validationRules = {
          [name]: [validateRequired, validateAmount],
        };
        break;

      default:
        validationRules = {
          [name]: [validateRequired],
        };
        break;
    }

    const validationResult = validation(
      name,
      value,
      isSingleFieldValidation,
      validationRules
    );

    let error =
      Object.keys(validationResult).length === 0
        ? ""
        : validationResult?.[name];

    updateState(section, name, error, "field_error");
    return error;
  };

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false,
    validationRules: any
  ) => {
    const validationErrors = validateForm(
      { [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined,
      value
    );
    return validationErrors;
  };

  const updateState = (
    section: string,
    fieldId: string | number,
    value: any,
    fieldName: string
  ) => {
    setFormData((prevFormData: any) => {
      const dependencyFields: any = {};
      const currentFormData = prevFormData[section];


      if (Object.keys(OverTimeDependency).includes(fieldId.toString()) && fieldName == 'field_value') {
        dependencyFields[fieldId] = {
          ...currentFormData[fieldId],
          field_value: value,
        }
        for (let eachId of OverTimeDependency[fieldId.toString()]) {
          if (currentFormData[eachId].field_value == '1') {
            if (Object.keys(OverTimeFieldDependency).includes(eachId.toString())) {
              for (let Id of OverTimeFieldDependency[eachId.toString()]) {
                dependencyFields[Id] = {
                  ...currentFormData[Id],
                  field_value: '00:00',
                };
              }
            }
            dependencyFields[eachId] = {
              ...currentFormData[eachId],
              field_value: '0',
            }
          }
          // 
          if (Object.keys(OverTimeFieldDependency).includes(fieldId.toString())) {
            for (let i of OverTimeFieldDependency[fieldId.toString()]) {
              dependencyFields[i] = {
                ...currentFormData[i],
                field_value: value == '0' ? '00:00' : currentFormData[i].field_value,
              };
            }

          }
        }
        return {
          ...prevFormData,
          [section]: {
            ...currentFormData,
            ...dependencyFields,
          }
        }
      }
      else if ((Object.keys(OverTimeFieldDependency).includes(fieldId.toString())) && (fieldName == 'field_value')) {
        for (let eachId of OverTimeFieldDependency[fieldId.toString()]) {
          dependencyFields[eachId] = {
            ...currentFormData[eachId],
            field_value: value == '0' ? '00:00' : currentFormData[eachId].field_value,
          };
        }

      }

      // Update the main field
      dependencyFields[fieldId] = {
        ...currentFormData[fieldId],
        [fieldName]: value,
      };

      return {
        ...prevFormData,
        [section]: {
          ...currentFormData,
          ...dependencyFields,
        },
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // formData["Project rate"][4] = id === "1" ? "Rate($/hr)" : "Rate($/day)";
    const isFormValid = validateFormOnSubmit();

    if (isFormValid) {
      formData["template_id"] = id;
      const response = await ApiCall.service(
        TEMPLATE_CREATE,
        "POST",
        formData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        navigate(`${PATH_MANAGE_TEMPLATE}`);
        CustomNotify({ type: "success", message: response.message });
      } else {
        console.log("error");
      }
    }
  };

  const validateFormOnSubmit = () => {
    const emptyFields: RequiredFields = [];

    const premiumValue = formData?.Premium?.['44']?.field_value;
    const premiumDependencyFields = [36, 37, 38, 39];


    for (const section in formData) {
      for (const fieldId in formData[section]) {
        const fieldValue = formData[section][fieldId];
        const premiumValidate = premiumValue == '0' ? (premiumDependencyFields.includes(parseInt(fieldId)) ? true : false) : false
        if ((typeof fieldValue === "boolean") || (premiumValidate)) {
          continue;
        }

        const { field_id, field_value } = fieldValue;
        if (
          ![43, 41, 40, 8].includes(field_id) &&
          checkFieldValidation(section, field_id, field_value, true) !== ""
        ) {
          emptyFields.push({ section, fieldId: parseInt(field_id) });
        }
      }
    }
    return emptyFields.length === 0;
  };

  const permissionType = formMode === "view" ? "read" : "update";
  const permissionObject: any = {
    permission: "Templates",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <form onSubmit={handleSubmit}>
          <>
            <h3 style={{ textAlign: "center" }}>{templateName}</h3>
          </>
          <>
            <div className="form-height">
              <div className="form-border p-5 mb-4">
                <div className="row">
                  {Object.keys(formData).map((index: any, key: number) => (
                    <FormSection
                      key={index}
                      section={index}
                      fields={formData?.[index]}
                      handleInputChange={handleInputChange}
                      templateTitle={templateName}
                      formMode={formMode}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
          <div className="row my-3">
            <div className="col-md-12 d-flex justify-content-between">
              <Link
                to={PATH_MANAGE_TEMPLATE}
                className="text-uppercase back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
              {!isView && (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="btn form-button  shadow-none text-uppercase px-3 button-width"
                />
              )}
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
};

export default EditTemplate;
