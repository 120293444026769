import { PATH_HOME, PATH_PROFILE_EDIT } from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GET_PROFILE, SAVE_PROFILE } from "routes/ApiEndpoints";
import FormBuilder from "services/form/FormBuilder";
import { ProfileValidation } from "services/validation/ValidationRules";
interface MyProfileProps {
  id?: number | string;
}

const MyProfile: React.FC<MyProfileProps> = ({ id }) => {
  const location = useLocation();
  const userAuth = useSelector(selectAuth);
  const path = location.pathname;
  const userId = userAuth.userId;

  return (
    <FormBuilder
      actionType={path === PATH_PROFILE_EDIT ? "Edit" : "Create"} // action type  edit or create
      entryId={userId} // required only if actionType is edit
      getDataAPI={GET_PROFILE} //required only if actionType is edit
      SaveDataAPI={SAVE_PROFILE} //Post's data to this endpoint
      formName={"MyProfile"} //Form name
      title={"My profile"} // Form title
      redirect={PATH_HOME} // After submit redirect to this screen/route
      validationRules={ProfileValidation} //Validation rules to validate form on submit
    />
  );
};

export default MyProfile;
