import React, { ReactNode, MouseEventHandler } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface Props {
  title: ReactNode;
  icon?: IconProp;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  tooltip?:string;
  children?:React.ReactNode;
}
/**
 * @param param0 
 * @returns
 * 
 * @example
 * 
  <Button
    title={t("")}
    handleClick={}
    className={"btn form-button  text-uppercase rounded-0 shadow-none "}
    tooltip={t("")}
    disabled={false}
    type={"button"}
    icon={faPlus}
  ></Button>;
 */
const Button: React.FC<Props> = ({ title, handleClick, className = '',tooltip, disabled = false, type = 'button' ,icon,children}) => {
  return (
    <>
      <button 
        type={type} 
        className={className} 
        onClick={handleClick} 
        disabled={disabled}
        title={tooltip}
      >
         {icon && <FontAwesomeIcon icon={icon} />} {/* Render the icon if it exists */}
        {title}
        {children}
      </button>
    </>
  );
};

export default Button;
