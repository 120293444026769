import React, { useState, ChangeEventHandler } from "react";

const style = {};

interface Props {
  id?: string;
  className?: string;
  value?: string | number;
  checked?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  name?: string;
  error?: string;
  readOnly?: boolean;
  rows?: number;
  cols?: number;
}

const TextArea: React.FC<Props> = ({
  id = "",
  className = "form-control shadow-none rounded-0",
  value,
  isDisabled = false,
  placeholder = "",
  customStyle = {},
  handleChange,
  name = "",
  error,
  readOnly,
  rows = 4,
  cols = 40,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <textarea
        style={{ ...style, ...customStyle }}
        id={id}
        className={className}
        disabled={isDisabled}
        placeholder={isFocused ? "" : placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
        rows={rows}
        cols={cols}
      />
      <div className="mb-2 height-20">
        {error && <span className="text-danger">{error}</span>}
      </div>
    </>
  );
};

export default TextArea;
