import React from "react";

import { useContext, createContext, useReducer } from "react";
import { initialProjectProps } from "./initialProjectProps";

import { ProjectReducer } from "../../reducers/ProjectReducer/ProjectReducer";

const ProjectContext = createContext(null);
const ProjectDispatchContext = createContext(null);

const initialProjectState = {
  projects: [],
  project_id: null,
  refresh: false
};
export default function ProjectProvider({ children }) {
  const [projectState, projectDispatch] = useReducer(
    ProjectReducer,
    initialProjectState
  );

  return (
    <ProjectContext.Provider value={{projectState}}>
      <ProjectDispatchContext.Provider value={{projectDispatch}}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}

export const useProject = () => {
  return useContext(ProjectContext);
};

export const useProjectDispatch = () => {
  return useContext(ProjectDispatchContext);
};
