import React, { useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { CompetenceHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { COMPETENCE_ARCHIVE } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_COMPETENCE_CREATE,
  PATH_COMPETENCE_EDIT,
  PATH_COMPETENCE_VIEW,
  PATH_MASTER_DATA,
} from "constants/Paths";
import { Competence } from "./Manage";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";

interface ManageCompetencePageProps {
  competenceObject: Competence[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

const ManageCompetencePage: React.FC<ManageCompetencePageProps> = ({
  competenceObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${COMPETENCE_ARCHIVE}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleDelete = (Competence: Competence | any) => {
    setShowModal(true);
    setDeleteId(Competence.id);
  };

  const handleEdit = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_EDIT}/${competence.id}`);
    }
  };

  const handleView = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_VIEW}/${competence.id}`);
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Competence",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="form-height-dashboard">
        <TitleFieldMolecule title={t("Manage competence")} />
        <div className="row pb-4 search-bar">
          <div className="col-12">
            <div className="row">
              <div className="col-xxl-9 col-md-12 mb-3">
                <div className="row">
                  <div className="col-9">
                    <div className="">
                      <label className="form-label field-label">
                        {t("Competence name")}
                      </label>
                    </div>
                    <Search
                      className="form-control"
                      handleChange={handleSearchChange}
                      placeholder={t("Competence name")}
                      name="competenceName"
                      value={searchData.competenceName}
                    />
                  </div>
                </div>
              </div>
              <div className="col mt-auto text-end">
                <Button
                  title={IconData.ResetIcon}
                  type="submit"
                  handleClick={(e) => handleSubmit(e, true)}
                  className="btn delete-btn  shadow-none text-uppercase me-3 "
                />
                <Button
                  title={t("Search")}
                  type="submit"
                  handleClick={handleSubmit}
                  className="btn form-button shadow-none text-uppercase px-3 search-btns position-relative text-center"
                />
              </div>
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom mt-4">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[{
                  permission: "Competence",
                  create: true
                }]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_COMPETENCE_CREATE}
                  className="table-button"
                >
                  + {t("Create competence")}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive">
              <DynamicTableStructure
                isAction
                headers={CompetenceHeader}
                data={competenceObject}
                handleEdit={handleEdit}
                handleArchive={handleDelete}
                //   handleView={handleView}
                permission={"Competence"}
              />
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          <div className="my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={t("Archive confirmation")}
            body={t("Are you sure want to archive?")}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            closeTitle={t("No")}
            confirmTitle={t("Yes")}
          />
        </div>
      </div>
    </AccessControl>
  );
};
export default ManageCompetencePage;

{
  /* <div className="table-responsive">
<DynamicTableStructure
  isAction
  headers={CompetenceHeader}
  data={competenceObject}
  handleEdit={handleEdit}
  handleArchive={handleDelete}
  handleView={handleView}
  permission={"Competence"}
/>
</div>
<div className="pagination justify-content-center align-items-center my-3">
<Pagination
  currentPage={currentPage}
  totalPages={totalPages}
  onPageChange={onPageChange}
/>
</div>
<div className="">
<Link
  to={PATH_MASTER_DATA}
  className="text-uppercase back-btn text-decoration-underline"
>
  {t("Back")}
</Link>
</div> */
}
