import React from "react";
import ProjectParameterHeader from "../atoms/projectParameterHeader";
import WeekCalendar from "./weekCalendar";
import PerformanceAndInvoice from "../atoms/performanceAndInvoice";
import { useProjectParameterContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
const ProjectParameters = (props) => {
  const { planTimingsData } = props;
  const { filterOptionsState } = useFilterOptions();

  return (
    <>
      {/* <tr>
        <td colSpan={10}>
          <div className="text-center py-2">Project parameters</div>
        </td>
      </tr> */}

      {/* <ProjectParameterHeader
        headerCloumns={
          initialProjectParameterState ? initialProjectParameterState : []
        }
      /> */}

      <WeekCalendar />

      <PerformanceAndInvoice
        title="Performance"
        performanceAndInvoiceData={planTimingsData}
        modalName="performance"
        values={[
          {key: 'type', name: 'Type', options: filterOptionsState?.state?.performanceTypes},
          {key: 'hours', name: 'Hours'},
          {key: 'standardPrice', name: 'Standard price'},
          {key: 'totalPrice', name: 'Total price'}
        ]}
        calculateAll
      />
      <PerformanceAndInvoice
        title="Reimbursements"
        performanceAndInvoiceData={planTimingsData}
        modalName="reimbursement"
        values={[
          {key: 'type', name: 'Type', options: filterOptionsState?.state?.reimbursementTypes},
          {key: 'numbers', name: 'Number'},
          {key: 'price', name: 'Price'},
          {key: 'totalPrice', name: 'Total price'}
        ]}
        calculateAll
      />
      {/* <PerformanceAndInvoice
        title="Invoice"
        performanceAndInvoiceData={planTimingsData}
        modalName="invoice"
      /> */}
    </>
  );
};
export default ProjectParameters;
