import React from 'react'
import { useLocation } from 'react-router-dom';

const GetQueryParams = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    return queryParams;
}

export default GetQueryParams