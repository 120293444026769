import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import Search from "components/atoms/Search";
import TitleFieldMolecule from "components/molecules/TitleField";
import TableActions from "components/organism/Actions/TableAction";
import { M_MASTER_DATA } from "constants/Constants";
import {
  CREATE_HOLIDAY_CODES,
  EDIT_HOLIDAY_CODE,
  HOLIDAY_CONFIGS,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DELETE_HOLIDAY_CODE,
  GET_HOLIDAY_CODES_DATA,
  GET_OPTIONS_FOR_HOLIDAY_CODES,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";

interface HolidaysData {
  contract_type: any;
  holiday_type: any;
  employee_category: any;
}

interface DataProps {
  page: number;
  search?: any;
}

const ManageHolidayCodes = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    holiday_name: queryParams.get("holiday_name") ?? "",
    holiday_code: queryParams.get("") ?? "",
    contract_type: queryParams.get("contract_type") ?? "",
    holiday_type: queryParams.get("holiday_type") ?? "",
    employee_category: queryParams.get("employee_category") ?? "",
  });
  const [holidayCodesData, setHolidaysData] = useState<any>([]);
  const [id, setId] = useState<number | undefined>(undefined);

  const [options, setOptions] = useState<any>({
    contract_types: [],
    holiday_type: [],
    employee_category: [],
  });
  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_OPTIONS_FOR_HOLIDAY_CODES,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        const { contractTypes, holidayType, employeeCategory } = response.data;
        setOptions((prevState: any) => ({
          ...prevState,
          contract_types: [...contractTypes],
          holiday_type: [...holidayType],
          employee_category: [...employeeCategory],
        }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error("Error occured ", error);
    }
  };

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };
    fetchHolidayCodesData(data);
  }, [currentPage, submit]);

  const fetchHolidayCodesData = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_HOLIDAY_CODES_DATA,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const { data, totalPages } = response.data;
        setHolidaysData(data);
        setTotalPages(totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({
        contract_type: "",
        holiday_type: "",
        employee_category: "",
        holiday_code: "",
        holiday_name: "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: HolidaysData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //onClick edit handler
  const handleEdit = (id: number) => {
    navigate(`${EDIT_HOLIDAY_CODE}/${id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (entityID: number) => {
    setId(entityID);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_HOLIDAY_CODE,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        setSubmit((prevState) => !prevState);
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleSearchChange = (event: any) => {
    const { name, value } = event.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <TitleFieldMolecule title={t("Manage holiday codes")} />
          <div className="row pb-4 search-bar mb-4">
            <div className="col-3 mb-3">
              <div className="">
                <label className="form-label field-label">Name</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder={t("Select name")}
                name="holiday_name"
                value={search.holiday_name}
              />
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">Holiday code</label>
              </div>
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder={t("Select holiday code")}
                name="holiday_code"
                value={search.holiday_code}
              />
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">
                  {t("Contract type")}
                </label>
              </div>
              <select
                className="form-select shadow-none"
                name="contract_type"
                id="contract_type"
                onChange={handleSearchChange}
                value={search.contract_type}
              >
                <option value="">{t("Select contract type")}</option>
                {options.contract_types?.map((optn: any, key: any) => (
                  <option key={key} value={optn.value}>
                    {optn.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">
                  {t("Holiday type")}
                </label>
              </div>
              <select
                className="form-select shadow-none"
                name="holiday_type"
                id="holiday_type"
                onChange={handleSearchChange}
                value={search.holiday_type}
              >
                <option value="">{t("Select holiday type")}</option>
                {options.holiday_type?.map((optn: any, key: any) => (
                  <option key={key} value={optn.value}>
                    {optn.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <div className="">
                <label className="form-label field-label">
                  {t("Employee category")}
                </label>
              </div>
              <select
                className="form-select shadow-none"
                name="employee_category"
                id="employee_category"
                onChange={handleSearchChange}
                value={search.employee_category}
              >
                <option value="">{t("Select employee category")}</option>
                {options.employee_category?.map((optn: any, key: any) => (
                  <option key={key} value={optn.value}>
                    {optn.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col mt-auto text-end">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
            </div>

            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                <Link
                  to={CREATE_HOLIDAY_CODES}
                  className="table-button"
                >
                  + {t("Add holiday code")}
                </Link>
              </div>
              <div className="table-responsive manage-companies">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th>{t("Name")}</th>
                      <th>{t("Code")}</th>
                      <th>{t("Counts")}</th>
                      <th>{t("Contract type")}</th>
                      <th>{t("Holiday type")}</th>
                      <th>{t("Employee category")}</th>

                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holidayCodesData && holidayCodesData.length > 0 ? (
                      holidayCodesData.map((record: any, key: number) => {
                        return (
                          <tr key={key}>
                            <td className="align-middle">
                              {record.holiday_name}
                            </td>
                            <td className="align-middle">
                              {record.holiday_code}
                            </td>
                            <td className="align-middle">
                              {record.holiday_count +
                                (record.count_type == 1 ? " Hours" : " Days")}
                            </td>
                            {record.contract_type &&
                            record.contract_type.length > 0 ? (
                              <td className="align-middle">
                                {record.contract_type
                                  .map((fun: any) => fun.label)
                                  .join(", ")}
                              </td>
                            ) : null}
                            <td className="align-middle">
                              {record.holiday_type?.label}
                            </td>
                            {record.employee_category &&
                            record.employee_category.length > 0 ? (
                              <td className="align-middle">
                                {record.employee_category
                                  .map((category: any) => category.label)
                                  .join(", ")}
                              </td>
                            ) : null}
                            <td className="align-middle">
                              <TableActions
                                isAction={true}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                value={record.id}
                                permission={"Holiday codes"}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center">
                          {t("No records found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center my-4">
        <Link
          to={HOLIDAY_CONFIGS}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidayCodes;
