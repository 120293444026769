import { VACANCY_EDIT, VACANCY_CREATE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_VACANCY_MANAGE } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import {
  commonFunction,
  experineceFunction,
  filterFunction,
  getPcFunctionOptions,
  numberOfHoursFunction,
  getVdabCompetences
} from "./CustomVacancyFunctions";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export type CustomFnType = (payload: any,dataObject:any) => any;

export const CreateVacancies = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = (payload: any, dataObject:any) => {
    switch (payload.type) {
      case "jobsites":
        return commonFunction(payload, []);
      case "foreignCountry":
        return commonFunction(payload, []);
      case "experience":
        return experineceFunction(payload, []);
      case "numberOfHours":
        return numberOfHoursFunction(payload, []);
      case "filterProjets":
        return filterFunction(payload,[],dataObject)
      case "filterFunctions":        
        return getPcFunctionOptions(payload);
      case "vdabTemplate":
        return getVdabCompetences(payload);
      default:
        return "Called with wrong key";
    }
  };

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Vacancy"
  }
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <>
      <AccessControl
        requiredPermissions={[
          permissionObject
        ]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <FormProviderWrapper
          backToManage={PATH_VACANCY_MANAGE}
          formName={"Vacancies"}
          dataSubmissionEndPoint={VACANCY_CREATE}
          microservice={M_MASTER_DATA}
          dataFetchEndPoint={VACANCY_EDIT}
          entryId={routeId}
          customFn={customActions}
        />
      </AccessControl>
    </>
  );
};
