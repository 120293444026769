import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from "react";
import { companyFormData, TabProps } from "../annotations/CompanyAnnotations";
import { Option } from "services/validation/ValidationService";
import { OptionProps } from "utils/TypeAnnotations";

const initialFormData: companyFormData = {
  tabDetails: [
    {
      id: "basic",
      title: "Basic",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "address",
      title: "Address",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "companyLocation",
      title: "Location",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "contacts",
      title: "Contacts",
      showStatus: false,
      error: false,
      draft: false,
    },
  ],
  basic: {
    vat: "",
    companyName: "",
    officialName: "",
    pc: [],
    naceCodes: [],
    companyType: null,
    isBussinessUnit: false,
    bussinessUnit: null,
    website: "",
    hq: 0,
    parentCompanies: null,
    coEfficient: 0,
    companyStatus: 0,
  },
  address: {
    // location: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    country: null,
    city: "",
    biStreet: "",
    biNumber: "",
    biBox: "",
    biZipCode: "",
    biCountry: null,
    biCity: "",
    hqAddress: 0,
    sameAddress: 1,
  },
  companyLocation: [
    {
      locationName: "",
      phoneNumber: "",
      locationStreet: "",
      locationNumber: "",
      locationBox: "",
      locationZipCode: "",
      locationCountry: null,
      locationCity: "",
      id: "",
      locationInfo: "",
    },
  ],

  contacts: [
    {
      fName: "",
      lName: "",
      email: "",
      mobileNumber: "",
      functionTitle: "",
      phoneNumber: "",
      language: null,
      info: "",
      // title: null,
      location: [],
      linkedIn: "",
      gender: null,
      roles: null,
      id: "",
    },
  ],
  selectedContact: {
    personcontact: [],
  },

  fieldError: {
    vat: "",
    companyName: "",
    officialName: "",
    pc: "",
    location: "",
    website: "",
    hq: "",
    parentCompanies: "",
    naceCodes: "",
    companyType: "",
    bussinessUnit: "",
    coEfficient: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    country: "",
    city: "",
    biStreet: "",
    biNumber: "",
    biBox: "",
    biZipCode: "",
    biCountry: "",
    biCity: "",
    hqAddress: "",
    sameAddress: "",
    locationName: "",
    lphoneNumber: "",
    locationStreet: "",
    locationNumber: "",
    locationBox: "",
    locationZipCode: "",
    locationCountry: "",
    locationCity: "",
    mobileNumber:"",
    phoneNumber:""
  },
  countryList: [],
  pcList: [],
  locationList: [],
  companiesList: [],
  rolesList: [],
  languageList:[],
  // locationIds: [],
  naceCodesList: [],
  bussinessUnitList: [],
  // cities: [],
  genders: [],
  contactsList: [], //Dropdown list declaring
  dynamicErrors: [
    {
      fName: "",
      lName: "",
      email: "",
      mobileNumber: "",
      phoneNumber: "",
      functionTitle: "",
      language: "",
      info: "",
      gender: "",
      linkedIn: "",
      location: "",
    },
  ],
  dynamicLocationErrors: [
    {
      locationName: "",
      phoneNumber: "",
      locationStreet: "",
      locationNumber: "",
      locationBox: "",
      locationZipCode: "",
      locationCountry: "",
      locationCity: "",
      locationInfo: "",
    },
  ],
};

// Define form actions
type FormAction =
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  | {
      type: "SET_DROPDOWN";
      dropdownValues: OptionProps[];
      field: keyof companyFormData;
    }
  | { type: "UPDATE_TAB_DETAILS"; tabDetails: TabProps[] }
  | { type: "UPDATE_FIELD_ERROR"; fieldName: string; error: string }
  | { type: "UPDATE_TAB_ERROR"; tabIndex: number; error: boolean }
  | {
      type: "UPDATE_CONTACTS_FIELD_ERROR";
      field: string;
      error: string;
      index: number;
    }
  | {
      type: "UPDATE_COMPANY_LOCATION_FIELD_ERROR";
      field: string;
      error: string;
      index: number;
    }
  | {
      type: "UPDATE_BASIC_FIELD";
      field: string;
      value: string | number | null | boolean | Option;
    }
  | {
      type: "UPDATE_SELECT_FIELD";
      field: string;
      value: string | number | null | boolean | Option;
    }
  | {
      type: "UPDATE_ADDRESS_FIELD";
      field: string;
      value: string | number | null | boolean | Option;
    }
  // | {
  //     type: "UPDATE_LOCTION_ID";
  //     field: string;
  //     value: LocationIds[];
  //   }
  | {
      type: "ADD_UPDATE_DYNAMIC";
      field: string;
      value: string | number | Date | null | undefined;
      index: number;
    }
  | {
      type: "ADD_UPDATE_DYNAMIC_COMPANY_LOCATION";
      field: string;
      value: string | number | Date | null | undefined;
      index: number;
    }
  | {
      type: "UPDATE_CONTACTS_FIELD";
      field: string;
      value: string | number | Date | null | undefined;
      index: number;
    }
  | {
      type: "UPDATE_COMPANYLOCATION_FIELD";
      field: string;
      value: string | number | Date | null | undefined | Option;
      index: number;
    }
  | { type: "ADD_CONTACT" }
  | { type: "ADD_LOCATION" }
  | { type: "REMOVE_LOCATION"; indexToRemove: number }
  | { type: "REMOVE_CONTACT"; indexToRemove: number };

type Dispatch = (action: FormAction) => void;

const FormContext = createContext<
  | {
      state: companyFormData;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

// Define a reducer to handle form actions
const formReducer: Reducer<companyFormData, FormAction> = (state, action) => {
  switch (action.type) {
    case "NEXT_STEP":
      const currentTab = state.tabDetails.find((tab) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab) => tab.id === currentTab.id
        );
        const nextIndex = (currentIndex + 1) % state.tabDetails.length;

        const updatedTabDetails = state.tabDetails.map((tab) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case "PREVIOUS_STEP":
      const currentTabPrev = state.tabDetails.find((tab) => tab.showStatus);
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab) => tab.id === currentTabPrev.id
        );
        const previousIndex =
          (currentIndexPrev - 1 + state.tabDetails.length) %
          state.tabDetails.length;

        const updatedTabDetailsPrev = state.tabDetails.map((tab) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case "SET_DROPDOWN":
      return {
        ...state,
        [action.field]: action.dropdownValues,
      };

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabDetails: action.tabDetails,
      };
    // case "UPDATE_LOCTION_ID":
    //   return {
    //     ...state,
    //     locationIds: action.value,
    //   };

    case "UPDATE_TAB_ERROR":
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab, index) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };
    case "UPDATE_BASIC_FIELD":
      return {
        ...state,
        basic: {
          ...state.basic,
          [action.field]: action.value,
        },
      };

    case "UPDATE_SELECT_FIELD":
      return {
        ...state,
        selectedContact: {
          ...state.selectedContact,
          [action.field]: action.value,
        },
      };

    //  case "UPDATE_SHIFT_SELECT_FIELD":
    //   return {
    //     ...state
    //   }

    case "UPDATE_ADDRESS_FIELD":
      return {
        ...state,
        address: {
          ...state.address,
          [action.field]: action.value,
        },
      };

    case "UPDATE_CONTACTS_FIELD":
      return {
        ...state,
        contacts: state.contacts.map((contact, index) => {
          if (index === action.index) {
            return {
              ...contact,
              [action.field]: action.value,
            };
          }
          return contact;
        }),
      };
    case "UPDATE_COMPANYLOCATION_FIELD":
      return {
        ...state,
        companyLocation: state.companyLocation.map((location, index) => {
          if (index === action.index) {
            return {
              ...location,
              [action.field]: action.value,
            };
          }
          return location;
        }),
      };

    case "ADD_LOCATION":
      const newLoction = {
        locationName: "",
        phoneNumber: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCountry: null,
        locationCity: "",
        id: "",
        locationInfo: "",
      };
      const newLocationErros = {
        locationName: "",
        phoneNumber: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCountry: "",
        locationCity: "",
        // id: "",
        locationInfo: "",
      };

      return {
        ...state,
        companyLocation: [...state.companyLocation, newLoction],
        dynamicLocationErrors: [
          ...state.dynamicLocationErrors,
          newLocationErros,
        ],
      };

    case "ADD_CONTACT":
      // Add a new contact to the contacts array
      const newContact = {
        fName: "",
        lName: "",
        email: "",
        location: [],
        mobileNumber: "",
        phoneNumber: "",
        functionTitle: "",
        language: null,
        info: "",
        linkedIn: "",
        gender: null,
        roles: null,
      };
      const newContactErros = {
        fName: "",
        lName: "",
        email: "",
        mobileNumber: "",
        phoneNumber: "",
        functionTitle: "",
        language: "",
        info: "",
        title: "",
        location: "",
        gender: "",
        // linkedIn: "",
      };
      return {
        ...state,
        contacts: [...state.contacts, newContact],
        dynamicErrors: [...state.dynamicErrors, newContactErros],
      };

    case "ADD_UPDATE_DYNAMIC":
      if (action.index === state.contacts.length) {
        const newContact = {
          fName: "",
          lName: "",
          email: "",
          mobileNumber: "",
          phoneNumber: "",
          language: null,
          info: "",
          location: [],
          functionTitle: "",
          linkedIn: "",
          gender: null,
          roles: null,
        };

        const newContactErrors = {
          fName: "",
          lName: "",
          email: "",
          mobileNumber: "",
          phoneNumber: "",
          functionTitle: "",
          language: "",
          info: "",
          location: "",
          gender: "",
          linkedIn: "",
        };

        return {
          ...state,
          contacts: [...state.contacts, newContact],
          dynamicErrors: [...state.dynamicErrors, newContactErrors],
        };
      }

      return {
        ...state,
        contacts: state.contacts.map((contact, index) =>
          index === action.index
            ? { ...contact, [action.field]: action.value }
            : contact
        ),
      };
    case "ADD_UPDATE_DYNAMIC_COMPANY_LOCATION":
      if (action.index === state.companyLocation.length) {
        const newLoc = {
          locationName: "",
          phoneNumber: "",
          locationStreet: "",
          locationNumber: "",
          locationBox: "",
          locationZipCode: "",
          locationCountry: null,
          locationCity: "",
          id: "",
          locationInfo: "",
        };
        const newLocError = {
          locationName: "",
          phoneNumber: "",
          locationStreet: "",
          locationNumber: "",
          locationBox: "",
          locationZipCode: "",
          locationCountry: "",
          locationCity: "",
          id: "",
          locationInfo: "",
        };

        return {
          ...state,
          companyLocation: [...state.companyLocation, newLoc],
          dynamicLocationErrors: [...state.dynamicLocationErrors, newLocError],
        };
      }

      return {
        ...state,
        companyLocation: state.companyLocation.map((loc, index) =>
          index === action.index
            ? { ...loc, [action.field]: action.value }
            : loc
        ),
      };

    case "REMOVE_CONTACT":
      // Remove the contact at the specified index
      const updatedContacts = [...state.contacts];
      updatedContacts.splice(action.indexToRemove, 1);
      const updatedContactsErrors = [...state.dynamicErrors];
      updatedContactsErrors.splice(action.indexToRemove, 1);
      return {
        ...state,
        contacts: updatedContacts,
        dynamicErrors: updatedContactsErrors,
      };
    case "REMOVE_LOCATION":
      const updatedCompanyLocation = [...state.companyLocation];
      updatedCompanyLocation.splice(action.indexToRemove, 1);
      const updatedLocationErrors = [...state.dynamicLocationErrors];
      updatedLocationErrors.splice(action.indexToRemove, 1);

      return {
        ...state,
        companyLocation: updatedCompanyLocation,
        dynamicLocationErrors: updatedLocationErrors,
      };

    case "UPDATE_FIELD_ERROR":
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.fieldName]: action.error,
        },
      };

    case "UPDATE_CONTACTS_FIELD_ERROR":
      return {
        ...state,
        dynamicErrors: state.dynamicErrors.map((error, index) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };
    case "UPDATE_COMPANY_LOCATION_FIELD_ERROR":
      return {
        ...state,
        dynamicLocationErrors: state.dynamicLocationErrors.map(
          (error, index) => {
            if (index === action.index) {
              return {
                ...error,
                [action.field]: action.error,
              };
            }
            return error;
          }
        ),
      };
    default:
      return state;
  }
};

// Create a FormProvider component to wrap your form
interface FormProviderProps {
  children: ReactNode;
}

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialFormData);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
