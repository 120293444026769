import React, { useEffect, useState } from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateSelectField,
} from "services/validation/ValidationService";
import ProjectParameters from "./ProjectParameterTemplate";
import { t } from "pages/microservices/masterData/translation/Translation";
import Calender from "components/molecules/Calender";

interface ProjectsProps {
  id?: string | number;
}

const ProjectParameter: React.FC<ProjectsProps> = () => {
  const { state, dispatch } = useFormContext();
  const [fetchStatus, setFetchStatus] = useState<boolean>(false);

  const TemplateComponents: { [key: string | number]: React.ReactNode } = {};
  const template = state?.projectParameters.data?.templates;

  const handleTemplateChange = (selectedOption: any, name: string) => {
    setFetchStatus(true);
    dispatch({
      type: "UPDATE_TEMPLATE_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const handleDateChangeCheck = (value: any, fieldName: string) => {
    if (value) {
      const date = `${value.getFullYear()}-${(value.getMonth() + 1) < 10 ? ('0' + (value.getMonth() + 1)) : (value.getMonth() + 1)}-${value.getDate() < 10 ? ('0' + value.getDate()) : (value.getDate())}`;
      dispatch({
        type: "UPDATE_TEMPLATE_FIELD",
        field: fieldName,
        value: date,
      });
      validation(fieldName, value, true);
    }
  }

  const data = state.projectParameters.data;
  const errors = state.projectParameters.errors;
  const options = state.options;

  const templateId = data?.templates?.value;
  if (templateId) {
    TemplateComponents[templateId] = (
      <ProjectParameters
        templateId={templateId.toString()}
        fetchStatus={fetchStatus}
        setFetchStatus={setFetchStatus}
      />
    );
  }

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      templates: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...state.projectParameters.data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      const updatedErrors = {
        ...state.projectParameters.errors,
        [name]: "",
      };

      state.projectParameters.errors = updatedErrors;
    } else {
      const updatedErrors = {
        ...state.projectParameters.errors,
        [name]: validationErrors[name],
      };
      state.projectParameters.errors = updatedErrors;
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };


  return (
    <form>
      <div className="form-border p-5 mb-3 mt-3">
        <div className="row">
          <div className="col-6 mb-3">
            <SelectWithSearch
              title={t("Template name")}
              name="templates"
              isMandatory={true}
              search={true}
              options={options?.templates}
              placeHolder={t("Select")}
              isMulti={false}
              className="select-field"
              value={template?.value ?? ""}
              onChange={(e) => handleTemplateChange(e, "templates")}
              error={errors.templates}
            />
          </div>

          <div className="col-6 position-relative">
            <Calender
              onChange={(date) => handleDateChangeCheck(date, "fromdate")}
              selectedDate={data?.fromdate}
              label={t("From date")}
              isMandatory={true}
              name={"fromdate"}
              error={errors?.fromdate}
              isDisabled={false}
            ></Calender>
          </div>

          {template.value !== undefined && template.value !== null && (
            <div key={template.value}>
              {TemplateComponents[template.value.toString()]}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ProjectParameter;
