import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface WeekendAndHolidayMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const WeekendAndHolidayMolecule: React.FC<WeekendAndHolidayMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
}) => {
  const renderField = () => {
    switch (field.field_name) {
      case "Saturdays":
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={true}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={t(error)}
            unit={unit}
          />
        );
      case "Sundays and holidays":
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={true}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={t(error)}
            unit={unit}
          />
        );
    }
  };

  return <>{renderField()}</>;
};

export default WeekendAndHolidayMolecule;
