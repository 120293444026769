import { PayRollReducerTypes, initialFilterProps } from "../constants/WorkPayBillRollConstants";

//To handle filter change
const handleFilterChange = async (state: any, dispatch: any, name: any, value?: any,) => {        
    return new Promise(async (resolve, reject) => {
        try {
            let payload: any = { type: PayRollReducerTypes?.SET_FILTER, value: state };    
            switch (name) {
                case 'business_unit':
                case 'employee':
                case 'contract_type':
                case 'selected_date':
                    payload = { ...payload, value: { ... payload?.value, [name]: value }}
                    break;
                case 'reset':
                    payload = { ...payload, value: { ...initialFilterProps, refresh: !state?.refresh, selected_date: state?.selected_date}}
                    break;
                case 'submit':
                    payload = { ...payload, value: { ...payload?.value, popup: { ...payload?.popup?.export, export: { from: null, to: null, show: false } }, refresh: !state?.refresh}}
                    break;
                case 'export':       
                    payload = { ...payload, value: {...payload?.value, popup: {...payload?.value?.popup, export: value}}};
                    break;
                default:
                    break;
            }    
            dispatch({type: payload?.type, payload: payload?.value});
            resolve(true);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });         
}

//To handle table checkboxes, print and export
const handleTableDataChange = (state: any, dispatch: any, name: string, value?: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data: any[] = [];
            let payload: any = { type: PayRollReducerTypes.SET_TABLE_DATA, value: { ...state }};
            switch (name) {
                case 'select_all':
                    data = value ? state?.data?.map((item: any) => item?.id) : [];
                    payload.value = { ...payload.value, select_all: value, selected_data: data };
                    break;
                case 'selected_data':
                    const isChecked = state?.selected_data?.includes(value?.id);
                    data = isChecked
                        ? state?.selected_data?.filter((item: any) => item !== value?.id)
                        : [...state?.selected_data, value?.id];
                    payload.value = { ...payload.value, selected_data: data, select_all: selectAllIsChecked(state?.totalCount, data)};
                    break;
                case 'overview':
                    let selecteddata = (value?.id ? [value?.id] : (payload?.value?.overview?.id ? [] : payload?.value?.selected_data));
                    payload = { ...payload, value: { ...payload?.value, selected_data: selecteddata, overview: value}}
                    break;
                default:
                    payload.value = { ...payload.value, [name]: value };
                    break;
            }
            dispatch({ type: payload.type, payload: payload.value });
            resolve(true);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}; 

const selectAllIsChecked = (totalCount: any, selected: any) => {    
    return (selected?.length === totalCount);
}

const getDate = (dateString: any, options: any) => {
    return new Date(dateString).toLocaleString('default', options);
}

export {
    handleFilterChange,
    handleTableDataChange,
    selectAllIsChecked,
    getDate
}