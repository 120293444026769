import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import HelpInfoIcon from "static/images/HelpIcon";
import Button from "components/atoms/Button";
import { RANGE_VALUE } from "constants/Constants";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceModalProps {
  handleClose: () => void;
  handleAdd: (selectedOptions: {
    [id: string]: {
      key: string;
      label: string;
      options: Option[];
    };
  }) => void;
  selectedCompetenceOptions: {
    [id: string]: {
      key: string;
      label: string;
      options: Option[];
    };
  };
  setSelectedCompetenceOptions: React.Dispatch<
    React.SetStateAction<{
      [id: string]: {
        key: string;
        label: string;
        options: Option[];
      };
    }>
  >;
  competenceData: CompetenceSection[];
}

interface CompetenceSection {
  id: any;
  key: string;
  label: string;
  options: { label: string; value: string }[];
}

const CompetenceModal: React.FC<CompetenceModalProps> = ({
  handleClose,
  handleAdd,
  selectedCompetenceOptions,
  setSelectedCompetenceOptions,
  competenceData,
}) => {
  const handleSelect = (sectionId: string, selected: Option[], key: any) => {
    setSelectedCompetenceOptions((prevState) => ({
      ...prevState,
      [sectionId]: {
        ...prevState[sectionId],
        options: selected,
      },
    }));
  };

  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    sectionId: string,
    index: number
  ) => {
    const newOptions = [...selectedCompetenceOptions[sectionId].options];
    newOptions[index] = {
      ...newOptions[index],
      range: e.target.value,
    };

    setSelectedCompetenceOptions((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        options: newOptions,
      },
    }));
  };

  const handleAddClick = () => {
    handleAdd(selectedCompetenceOptions);
  };

  return (
    <Modal
      size="xl"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="bitter-italic-normal-medium-24">
          {t("Competence")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={competenceData[0]?.key} alwaysOpen>
          {competenceData.map((section) => (
            <Accordion.Item key={section.key} eventKey={section.key}>
              <Accordion.Header>{section.label}</Accordion.Header>
              <Accordion.Body>
                <SelectWithSearch
                  name={section.key}
                  isMandatory={false}
                  search={true}
                  placeHolder="Select"
                  options={section.options}
                  value={selectedCompetenceOptions[section.id]?.options || []}
                  onChange={(selected) =>
                    handleSelect(section.id, selected, section.label)
                  }
                  isMulti={true}
                  className="select-field"
                  error={""}
                />
                <div className="row border m-0 mb-2">
                  {selectedCompetenceOptions[section.id]?.options?.length >
                    0 && (
                    <div
                      className="text-end mt-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title={`A. Basic user (A1/A2: beginner)\nB. Independent user (B1/B2: advanced)\nC. Skilled user (C1/C2: advanced)`}
                    >
                      <HelpInfoIcon />
                    </div>
                  )}
                  {selectedCompetenceOptions[section.id]?.options?.map(
                    (option, index) => (
                      <div key={index} className="col-6 my-3">
                        <div className="row">
                          <div className="col-3">
                            <span className="me-3 fw-bolder">
                              {option.label}
                            </span>
                          </div>
                          <div className="col">
                            <div className="w-100">
                              <input
                                type="range"
                                min="1"
                                max="6"
                                title={getTitleForRating(option.range)}
                                value={option.range}
                                className="w-100"
                                onChange={(e) =>
                                  handleChange(e, section.id, index)
                                }
                              />
                              <div className="d-flex justify-content-between">
                                {RANGE_VALUE.map((value) => (
                                  <span key={value}>{value}</span>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <div className="col-md-6 align-self-center ">
          <p
            className="poppins-regular-18px text-uppercase text-decoration-underline mt-5"
            onClick={handleClose}
          >
            {t("Cancel")}
          </p>
        </div>
        <div className="row ">
          <div className="col-md-12 mb-4">
            <Button
              title={t("Add")}
              handleClick={handleAddClick}
              className="btn form-button float-end text-uppercase rounded-0 shadow-none"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompetenceModal;
