import { CLOTHES_CREATE, CLOTHES_EDIT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_CLOTHES } from "constants/Paths";
import { useLocation, useParams } from "react-router-dom";

import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FormBuilder from "services/form/FormBuilder";
import { PATH_CREATE_CLOTHES } from "constants/Paths";
import { ClothesValidation } from "services/validation/ValidationRules";

export type CustomFnType = (payload: any) => any;

const AddClothes = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();


  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Clothes",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <>
      <FormBuilder
        actionType={path === PATH_CREATE_CLOTHES ? "create" : "edit"} // action type create or view
        entryId={routeId} // required only if actionType is view
        getDataAPI={CLOTHES_EDIT} //required only if actionType is view
        SaveDataAPI={CLOTHES_CREATE} //Post's data to this endpoint
        formName={"Clothes"} //Form name
        title={path === PATH_CREATE_CLOTHES ? "Create clothes" : "Edit clothes"} // Form title
        redirect={"/fleetOverview/clothes"} // After submit redirect to this screen/route
        validationRules={ClothesValidation} //Validation rules to validate form on submit
        microserviceName={M_MASTER_DATA}
      />
    </>
  );
};

export default AddClothes;