import React, { useContext, createContext, useReducer, ReactNode, Dispatch } from 'react';
import { initialFilterProps } from '../../constants/WorkPayBillRollConstants';
import { filtersReducer } from '../reducers/filtersReducer';
import { PayRollAction, FilterOptionsState } from '../actions/Interfaces';

interface FilterOptionsProviderProps {
    children: ReactNode;
}

const FilterOptionsContext = createContext<FilterOptionsState | null>(null);
const FilterOptionsDispatchContext = createContext<Dispatch<PayRollAction> | null>(null);

const initialFilterOptions: FilterOptionsState = initialFilterProps;

export function FilterOptionsProvider({ children }: FilterOptionsProviderProps) {
    const [filterOptionsState, dispatchFilterOptions] = useReducer(filtersReducer, initialFilterOptions);

    return (
        <FilterOptionsContext.Provider value={filterOptionsState}>
            <FilterOptionsDispatchContext.Provider value={dispatchFilterOptions}>
                {children}
            </FilterOptionsDispatchContext.Provider>
        </FilterOptionsContext.Provider>
    );
}

export function useFilterOptions() {
    const context = useContext(FilterOptionsContext);
    if (context === null) {
        throw new Error('useFilterOptions must be used within a FilterOptionsProvider');
    }
    return context;
}

export function useFilterOptionsDispatch() {
    const context = useContext(FilterOptionsDispatchContext);
    if (context === null) {
        throw new Error('useFilterOptionsDispatch must be used within a FilterOptionsProvider');
    }
    return context;
}
