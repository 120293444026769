
import React, { useEffect } from 'react';
import PlanningFilters from './molecules/planningFilters'
import { usePlanningsDispatch } from './planning-redux/context/PlanningContext/PlanningContext';
import { PLANNING_ACTIONS } from './planning-redux/actions/PlanningActions';

const PageLayout = ({ children, ...props }) => {
  const { dispatchPlanning } = usePlanningsDispatch();
  useEffect(()=>{
    dispatchPlanning({
      type: PLANNING_ACTIONS.SET_TYPE,
      payload: props.type,
    });
  },[props.type])
  return (
    <div className="page-layout">
      <header className='header-sticky position-sticky pt-2'>
        {/* Add SearchBar and FilterBar components here */}
         <PlanningFilters type={props.type}/>
        {/* <PlanningFilters /> */}
      </header>
      <div className="content">{children}</div>
    </div>
  );
};

export default PageLayout;
