import InputTextfield from "components/atoms/InputTextField"
import { useFormContext } from "../context/Context";

const TableAllowance = (props: any) => {

    const { day, index } = props;
    const { state, dispatch } = useFormContext();

    const { durationAndAllowance } = state?.data;

    const handleChange = (event: any, index: number, entityType: string) => {
        const { name, value } = event.target;
        dispatch({
            type: "UPDATE_INPUT_FIELDS",
            payload: {
                value,
                entityType,
                name,
                index
            }
        })
    }
 
    const { projectAllowance, technicianAllowance } = state?.errors?.durationAndAllowance?.[index];
    return (
        <>
            <tr key={index}>
                <td>{day}</td>
                <td>{durationAndAllowance?.[index]?.['hours']}</td>
                <td>
                    <InputTextfield
                        type="text"
                        handleChange={(event) => { handleChange(event, index, 'durationAndAllowance') }}
                        placeholder="0"
                        value={durationAndAllowance?.[index]?.['projectAllowance']}
                        name={"projectAllowance"}
                        id={`projectAllowance-${index + 1}`}
                        title={projectAllowance}
                        className={`col-4 form-control ${(projectAllowance !== '') ? ('border-danger') : ''}`}
                    />
                </td>
                <td>
                    <InputTextfield
                        type="text"
                        handleChange={(event) => { handleChange(event, index, 'durationAndAllowance') }}
                        value={durationAndAllowance[index]['technicianAllowance']}
                        name={'technicianAllowance'}
                        id={`technicianAllowance-${index + 1}`}
                        title={technicianAllowance}
                        placeholder="0"
                        className={`col-4 form-control ${(technicianAllowance !== '') ? ('border-danger') : ''}`}
                    />
                </td>
                <td>
                    {durationAndAllowance?.[index]?.['revenue']}
                </td>
                <td>
                    {durationAndAllowance?.[index]?.['cost']}
                </td>
                <td>
                    {durationAndAllowance?.[index]?.['marginCost']}
                </td>
                <td>
                    {durationAndAllowance?.[index]?.['marginPercent']}
                </td>
            </tr>
        </>
    )
}
export default TableAllowance