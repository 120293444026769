import LabelWithSelectField from "components/molecules/LabelwithSelectField";
import React, { useEffect, useState } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import Calender from "components/molecules/Calender";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import CustomNotify from "components/atoms/CustomNotify";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { ApiCall } from "services/ApiServices";
import {
  CONTRACT_DATA,
  CONTRACT_OPTIONS,
  CREATE_CONTRACT,
  DELETE_CONTRACT,
  DOWNLOAD_CONTRACT_PDF,
  GET_CONTRACT_DATA,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { Link, useParams } from "react-router-dom";
import { PATH_CONTRACT_OVERVIEW, PATH_EMPLOYEE_MANAGE } from "constants/Paths";
import { useNavigate } from "react-router-dom";
import Popup from "components/molecules/Popup";
import { exit } from "process";
import EncryptDecryptService from "services/EncryptDecryptService";
import ModalPopup from "components/atoms/ModalPopup";

interface TemplateProps {
  userId?: number | string | undefined | null;
  contractType?: string;
  fromDate?: string;
  businessUnit?: string;
  paritairComitee?: string;
  timetable?: string;
  numberOfHours?: string;
  bonus?: string;
  salary?: string;
  amountPerHour?: string;
  car?: string;
  withHoldingtax?: string;
  netExpense?: string;
  mealVoucher?: string;
  startDateOfMealVoucher?: string;
  mobilityAllowance?: boolean;
  info?: string;
}
interface ValidationRules {
  [key: string]: Function[];
}

interface ContractItem {
  value: number;
  name: string;
}
interface BusinessUnitItems {
  value: number;
  label: string;
}

const CreateContract: React.FC<TemplateProps> = () => {
  const url = new URL(window.location.href);
  const disableStatus = url.searchParams.get("data");
  const [id, setId] = useState(null);
  const [status, setstatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [contractPath, setContractPath] = useState("");
  useEffect(() => {
    if (disableStatus != undefined) {
      const decodedData = decodeURIComponent(disableStatus);
      const append = EncryptDecryptService.decryptData(decodedData);
      const parsedData = JSON.parse(append);
      if (parsedData.id !== null && parsedData.status !== null) {
        setContractStatus(parsedData.contract_status);
        setContractPath(parsedData.contract_path);
        setFormData((prevState) => ({
          ...prevState,
          userId: parsedData.id,
        }));
        setId(parsedData.id);
        let statusBoolean;
        if (parsedData.status === "true") {
          statusBoolean = true;
        } else if (parsedData.status === "false") {
          statusBoolean = false;
        } else {
          // Handle other cases if needed
          statusBoolean = Boolean(parsedData.status); // Fallback to generic boolean conversion
        }

        setstatus(statusBoolean);
      }
    }
  }, [disableStatus != undefined]);
  useEffect(() => {
    if (id !== null && contractStatus !== null) {
      getContractOptions();
      getContractData();
      setFormData((prevState) => ({
        ...prevState,
        userId: id,
      }));
    }
  }, [id != null]);
  const [formData, setFormData] = useState<TemplateProps>({
    userId: id,
    contractType: "",
    fromDate: "",
    businessUnit: "",
    paritairComitee: "",
    timetable: "",
    numberOfHours: "",
    bonus: "",
    salary: "",
    amountPerHour: "",
    car: "",
    withHoldingtax: "",
    netExpense: "",
    mealVoucher: "",
    startDateOfMealVoucher: "",
    mobilityAllowance: false,
    info: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState<TemplateProps>({}); //maintaining errors object
  const [businessUnitError, setBusinessUnitError] = useState(false);
  const [contractOptions, setContractOptions] = useState({
    contractTypes: [],
    businessUnits: [],
    paritairComitees: [],
    carOptions: [],
    salaryOptions: [],
    withHoldingTaxOptions: [],
    timeTables: [],
    mobility: "",
  });
  const [showModel, setShowModel] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const requestData = {
    id: id,
  };
  const getContractOptions = async () => {
    try {
      const url = `${CONTRACT_OPTIONS}/${id}`;
      setFormData((prevState) => ({
        ...prevState,
        userId: id,
      }));
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        setContractOptions({
          contractTypes: response?.data.contractTypes,
          businessUnits: response?.data.businessUnits,
          paritairComitees: response?.data.paritairComitees,
          carOptions: response?.data.car,
          salaryOptions: response?.data.salary,
          withHoldingTaxOptions: response?.data.withHoldingTax,
          timeTables: response?.data.timeTables,
          mobility: response?.data.mobility,
        });
        setFormData((prevState) => ({
          ...prevState,
          ["contractType"]: response?.data.contractTypes[0].value,
          ["mobilityAllowance"]: response?.data.mobility,
          ["businessUnit"]: response?.data.businessUnits[0].value,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getContractData = async () => {
    try {
      const url = `${GET_CONTRACT_DATA}/${id}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      setFormData((prevState) => ({
        ...prevState,
        userId: id,
      }));
      if (response?.status === 200) {
        const contractData = response.data[0]; // Assuming you expect only one contract data object
        setFormData({
          userId: id,
          contractType: contractData.contractType.toString(),
          fromDate: contractData.fromDate,
          businessUnit: contractData.businessUnit.toString(),
          paritairComitee: contractData.paritairComitee.toString(),
          timetable: contractData.timetable.toString(),
          numberOfHours: contractData.numberOfHours.toString(),
          bonus: contractData.bonus.toString(),
          salary: contractData.salary.toString(),
          amountPerHour: contractData.amountPerHour.toString(),
          car: contractData.car.toString(),
          withHoldingtax: contractData.withHoldingtax.toString(),
          netExpense: contractData.netExpense.toString(),
          mealVoucher: contractData.mealVoucher,
          startDateOfMealVoucher: contractData.startDateOfMealVoucher,
          mobilityAllowance: contractData.mobilityAllowance,
          info: contractData.info,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChangeCheck = (value: Date | null, fieldName: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    validation(fieldName, value, true);
  };
  const cancel = () => {
    setShowModel(false);
    navigate(PATH_EMPLOYEE_MANAGE);
  };
  const sendLetar = () => {
    navigate(PATH_EMPLOYEE_MANAGE);
  };

  const sendNow = async () => {
    try {
      const url = `${CONTRACT_DATA}`;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const htmlPath = response?.data?.html_file_path;
        navigate(`${PATH_CONTRACT_OVERVIEW}?htmlPath=${htmlPath}&id=${id}`);
      } else {
        //CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //console.error(error);
    }
    setLoading(false);
  };
  // const approveNow = async () => {
  //   try {
  //     const url = `${CONTRACT_DATA}`;
  //     const response = await ApiCall.service(
  //       url,
  //       "POST",
  //       requestData,
  //       false,
  //       M_MASTER_DATA
  //     );
  //     if (response?.status === 200) {
  //       const htmlPath = response?.data?.html_file_path;
  //       navigate(
  //         `${PATH_CONTRACT_OVERVIEW}?htmlPath=${htmlPath}&id=${id}&approve=${true}`
  //       );
  //     } else {
  //       //CustomNotify({ type: "error", message: `Error: ${response.message}` });
  //     }
  //   } catch (error) {
  //     //console.error(error);
  //   }
  // };
  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      contractType: [validateSelectField],
      fromDate: [validateRequired],
      businessUnit: [validateSelectField],
      paritairComitee: [validateSelectField],
      timetable: [validateSelectField],
      numberOfHours: [validateRequired],
      bonus: [validateRequired],
      salary: [validateRequired],
      amountPerHour: [validateRequired],
      car: [validateSelectField],
      withHoldingtax: [validateSelectField],
      netExpense: [validateRequired],
      mealVoucher: [validateRequired],
      startDateOfMealVoucher: [validateRequired],
      //mobilityAllowance: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  /** Update the form data fields statue onChange event*/
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = event.target;
    let updatedValue: string | boolean | number;

    // Regular expression to allow ., and numbers
    const pattern = /^[0-9.,]+$/;

    if (type === "checkbox") {
      updatedValue = !formData.mobilityAllowance ? true : false;
    } else {
      if (
        name === "netExpense" ||
        name === "bonus" ||
        name === "amountPerHour"
      ) {
        // Check if the value matches the pattern
        if (pattern.test(value) || value === "") {
          updatedValue = value;
        } else {
          // If the value doesn't match the pattern, don't update
          return;
        }
      } else {
        updatedValue = value;
      }
    }

    setFormData((formData) => ({ ...formData, [name]: updatedValue }));
    validation(name, updatedValue, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const businessUnitError = formData.businessUnit?.length == 0 ? true : false;
    setBusinessUnitError(businessUnitError);
    const updatedFormData = {
      ...formData,
      userId: id,
    };
    setFormData(updatedFormData);
    // Validate each entry in the formData, excluding businessUnit
    const isValid = Object.entries(updatedFormData).every(([key, value]) =>
      key === "businessUnit" ? true : validation(key, value, true)
    );
    if (isValid && !businessUnitError) {
      const response = await ApiCall.service(
        CREATE_CONTRACT,
        "POST",
        formData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setShowModel(true);
        CustomNotify({ type: "success", message: `${response.message}` });
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    }
  };
  const handleMultiSelect = (selectedOption: any, fieldName: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    setBusinessUnitError(false);
  };
  const handleEdit = () => {
    setstatus(false);
  };
  const handleViewContract = () => {
    if (contractPath) {
      const serverToken = `${process.env.REACT_APP_CONTRACT_URL}/${contractPath}`;
      const url = new URL(serverToken);
      const link = document.createElement("a");
      link.href = url.toString();
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    } else {
      console.error("Invalid response or missing download URL");
    }
  };
  const handleCancelContract = () => {
    setShowModal(true);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_CONTRACT,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        navigate(PATH_EMPLOYEE_MANAGE);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };

  return (
    <>
      <div>
        <h1 className="page-title mb-0">
          {status ? t("Edit contract") : t("Create contract")}
        </h1>

        <div className="mt-3">
          {contractPath ? (
            <>
              <Button
                title={
                  contractStatus
                    ? t("View approved contract")
                    : t("View generated contract")
                }
                handleClick={handleViewContract}
                className="btn form-button text-uppercase shadow-none"
              />
              {!contractStatus && (
                <Button
                  title={t("Cancel Contract")}
                  handleClick={handleCancelContract}
                  className="btn form-button mx-4 align-items-center px-3 py-1 text-uppercase shadow-none"
                />
              )}
            </>
          ) : null}
        </div>
        <>
          {status && !contractPath && (
            <div className="">
              <h1
                className=" page-title mb-0"
                style={{ color: "red", textAlign: "center" }}
              >
                {t("Contract was not generated")}
              </h1>
            </div>
          )}
          <div className="py-4 mb-5">
            {status && (
              <Button
                title={t("Edit")}
                handleClick={handleEdit}
                className="btn form-button float-end text-uppercase shadow-none"
              />
            )}
          </div>
        </>
      </div>
      <form>
        <div className="form-border mb-3 p-5">
          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6">
              <LabelWithSelectField
                label={t("Employee contract type")}
                options={contractOptions.contractTypes}
                placeholder={t("Select")}
                selectedValue={formData.contractType}
                handleChange={changeHandler}
                disabled={status != null ? !!status : !!status}
                isMultiSelect={false}
                isMandatory={true}
                name="contractType"
                className=""
                error={errors.contractType}
              />
            </div>
            <div className="col-6 ">
              <Calender
                onChange={(date) => handleDateChangeCheck(date, "fromDate")}
                selectedDate={formData.fromDate}
                label={t("From")}
                isMandatory={true}
                name={"fromDate"}
                error={errors.fromDate}
                isDisabled={!!status != null ? !!status : false}
              />
            </div>
          </div>

          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6">
              {/* <SelectWithSearch
                title="Business unit"
                name="businessUnit"
                isMandatory={true}
                search={true}
                options={contractOptions.businessUnits}
                placeHolder="Select"
                value={formData.businessUnit}
                onChange={(e) => handleMultiSelect(e, "businessUnit")}
                isMulti={false}
                className="select-field"
                error={businessUnitError ? "This field is required" : ""}
                isDisabled={!!status != null ? !!status : false}
              /> */}
              <LabelWithSelectField
                label={t("Business unit")}
                options={contractOptions.businessUnits}
                placeholder={t("Select")}
                selectedValue={formData.businessUnit}
                handleChange={changeHandler}
                disabled={status != null ? !!status : !!status}
                isMultiSelect={false}
                isMandatory={true}
                name="businessUnit"
                className=""
                error={businessUnitError ? "This field is required" : ""}
              />
            </div>
            <div className="col-6 ">
              <LabelWithSelectField
                label={t("Paritair comitee")}
                options={contractOptions.paritairComitees}
                placeholder={t("Select")}
                selectedValue={formData.paritairComitee}
                handleChange={changeHandler}
                disabled={!!status != null ? !!status : false}
                isMultiSelect={false}
                isMandatory={true}
                name="paritairComitee"
                className=""
                error={errors.paritairComitee}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6">
              <LabelWithSelectField
                label={t("Timetable")}
                options={contractOptions.timeTables}
                placeholder={t("Select")}
                selectedValue={formData.timetable}
                handleChange={changeHandler}
                disabled={!!status != null ? !!status : false}
                isMultiSelect={false}
                isMandatory={true}
                name="timetable"
                className=""
                error={errors.timetable}
              />
            </div>
            <div className="col-6 ">
              <LabelWithInputField
                label={t("No of hours/week")}
                name="numberOfHours"
                isMandatory={true}
                value={formData.numberOfHours}
                handleChange={changeHandler}
                id="numberOfHours"
                type="number"
                error={errors.numberOfHours}
                isDisabled={!!status != null ? !!status : false}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6">
              <LabelWithInputField
                label={t("Q-Bonus")}
                name="bonus"
                isMandatory={true}
                value={formData.bonus}
                handleChange={changeHandler}
                id="bonus"
                type=""
                error={errors.bonus}
                isDisabled={!!status != null ? !!status : false}
              />
            </div>
            <div className="col-6 ">
              <LabelWithSelectField
                label={t("Car")}
                options={contractOptions.carOptions}
                placeholder={t("Select")}
                selectedValue={formData.car}
                handleChange={changeHandler}
                disabled={!!status != null ? !!status : false}
                isMultiSelect={false}
                isMandatory={true}
                name="car"
                className=""
                error={errors.car}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6 ">
              <LabelWithSelectField
                label={t("Salary")}
                options={contractOptions.salaryOptions}
                placeholder={t("Select")}
                selectedValue={formData.salary}
                handleChange={changeHandler}
                disabled={!!status != null ? !!status : false}
                isMultiSelect={false}
                isMandatory={true}
                name="salary"
                className=""
                error={errors.salary}
              />
            </div>
            <div className="col-6">
              <LabelWithInputField
                label={t("Amount (per hour)")}
                name="amountPerHour"
                isMandatory={true}
                value={formData.amountPerHour}
                handleChange={changeHandler}
                id="amountPerHour"
                type="text"
                error={errors.amountPerHour}
                isDisabled={!!status != null ? !!status : false}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6 ">
              <LabelWithSelectField
                label={t("With holding tax")}
                options={contractOptions.withHoldingTaxOptions}
                placeholder={t("Select")}
                selectedValue={formData.withHoldingtax}
                handleChange={changeHandler}
                disabled={!!status != null ? !!status : false}
                isMultiSelect={false}
                isMandatory={true}
                name="withHoldingtax"
                className=""
                error={errors.withHoldingtax}
              />
            </div>
            <div className="col-6 ">
              <LabelWithInputField
                label={t("Net expense allowance/month")}
                name="netExpense"
                isMandatory={true}
                value={formData.netExpense}
                handleChange={changeHandler}
                id="netExpense"
                type="text"
                isDisabled={!!status != null ? !!status : false}
                error={errors.netExpense}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-6 d-flex row">
            <div className="col-6">
              <LabelWithInputField
                label={t("Meal voucher")}
                name="mealVoucher"
                isMandatory={true}
                value={formData.mealVoucher}
                handleChange={changeHandler}
                id="mealVoucher"
                type="text"
                error={errors.mealVoucher}
                isDisabled={!!status != null ? !!status : !!status}
              />
            </div>
            <div className="col-6 ">
              <Calender
                onChange={(date) =>
                  handleDateChangeCheck(date, "startDateOfMealVoucher")
                }
                selectedDate={formData.startDateOfMealVoucher}
                label={t("Start date of meal voucher")}
                isMandatory={true}
                name={"startDateOfMealVoucher"}
                error={errors.startDateOfMealVoucher}
                isDisabled={!!status != null ? !!status : !!status}
              />
            </div>
          </div>
          <div className="mb-3">
            <CheckBoxField
              label="Mobility allowance"
              name="mobilityAllowance"
              onChangeHandler={changeHandler}
              isChecked={formData.mobilityAllowance}
              disable={status != null ? status : false}
            />
          </div>
          <LabelWithTextAreaField
            label="Info"
            name="info"
            value={formData.info}
            placeholder=""
            handleChange={changeHandler}
            isDisabled={status != null ? status : false}
          />
          <div className="">
            <Link
              to={PATH_EMPLOYEE_MANAGE}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="">
            {/* {!contractStatus && ( */}
            <Button
              title={status ? t("Send Now") : t("Save and send")}
              handleClick={status ? sendNow : handleSubmit}
              className="btn form-button float-end text-uppercase shadow-none"
            />
            {/* )} */}
          </div>
        </div>
      </form>
      {showModel && (
        <Popup
          body={t("Send contract")}
          nowSendText={t("Send now")}
          letarSendText={t("Send later")}
          //approveNowText={t("Approve Now")}
          cancel={cancel}
          sendLetar={sendLetar}
          sendNow={sendNow}
          //approveNow={approveNow}
          footerclass="justify-content-between"
        />
      )}
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to cancel?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};

export default CreateContract;
