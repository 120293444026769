import React, { useState } from "react";
import { UserStatus } from "constants/Constants";

interface StatusComponentProps {
  initialStatus: number;
  tooltip?: string
}

const StatusComponent: React.FC<StatusComponentProps> = ({ initialStatus, tooltip }) => {
  return (
    <div>
      <span
        className={`d-inline-block rounded-circle status-icon bg-${
            initialStatus === 0
            ? "warning"
            : initialStatus === 1
            ? "success"
            : "danger"
        }`}
        data-toggle="tooltip"
        title={tooltip}
      ></span>
    </div>
  );
};

export default StatusComponent;
