import React, { Suspense, useState, useEffect, useRef } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageFleet from "./ManageSupplier";
import { M_MASTER_DATA } from "constants/Constants";
import { SUPPLIERS, VACANCIES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ManageCar from "./ManageSupplier";
import ManageSupplier from "./ManageSupplier";

// Define the prop types for the component
interface SupplierProps {
    id?: string | number;
}

interface DataProps {
    page: number;
    nOR: number;
    search?: Partial<SupplierProps>;
    type: string;
}

// Functional component using TypeScript
const Supplier: React.FC<SupplierProps> = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const itemsPerPage = 10;
    const [supplierData, setSupplierData] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [submit, setSubmit] = useState(true);
    const [search, setSearch] = useState<any>({
        supplierType: queryParams.get("supplierType") ?? "",
        name: queryParams.get("name") ?? "",
        vatNumber: queryParams.get("vatNumber") ?? "",
        email: queryParams.get("email") ?? "",
    });


    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const postData: DataProps = {
            page: currentPage,
            nOR: itemsPerPage,
            search: search,
            type: "supplier",
        };
        getSupplierData(postData);
    }, [currentPage, submit]);

    const getSupplierData = async (postData: DataProps) => {
        const response = await ApiCall.service(
            SUPPLIERS,
            "POST",
            postData,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setSupplierData(response.data);
            setTotalPages(response.totalPages);
        } else {
            console.log("error");
        }
    };

    const handleSearch = (name: string, value: string | number) => {
        setSearch((prevSearch: any) => ({ ...prevSearch, [name]: value }));
    };

    // Function to create query parameters from the search object
    const createQueryParams = (data: {
        [key: string]: string | number | boolean;
    }) => {
        const queryParams = Object.entries(data)
            .filter(([key, value]) => value !== undefined && value !== "")
            .map(
                ([key, value]: [string, string | number | boolean]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");

        return queryParams;
    };

    const handleSearchSubmit = (clear: boolean) => {
        if (clear) {
            navigate({ search: "" });
            setSearch({
                supplierType: "",
                name: "",
                vatNumber: "",
                email: "",
            });
        }

        if (createQueryParams(search) !== "" && !clear) {
            navigate(`?${createQueryParams(search)}`);
        } else if (!clear) {
            CustomNotify({ type: "warning", message: "Search input not provided." });
        }

        setSubmit(!submit);
        setCurrentPage(1);
    };



    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}...</div>
                }
            >

                <ManageSupplier
                    data={supplierData}
                    handleData={setSupplierData}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    searchValue={handleSearch}
                    handleSubmitAction={handleSearchSubmit}
                    searchData={search}
                    handleGetSupplier={getSupplierData}
                />
            </Suspense>
        </>
    );
};

export default Supplier;
