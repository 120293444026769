import React from "react";
import { useContext, useReducer, createContext } from "react";
import { ProjectParameterReducer } from "../../reducers/ProjectParameterReducer/ProjectParameterReducer";

const ProjectParemeterContext = createContext(null);
const ProjectParameterDispatchContext = createContext(null);

export default function ProjectParameterProvider({ children }) {
  const [initialProjectParameterState, projectParameterDispatch] = useReducer(
    ProjectParameterReducer,
    {}
  );

  return (
    <ProjectParameterDispatchContext.Provider
      value={{ projectParameterDispatch }}
    >
      <ProjectParemeterContext.Provider
        value={{ initialProjectParameterState }}
      >
        {children}
      </ProjectParemeterContext.Provider>
    </ProjectParameterDispatchContext.Provider>
  );
}

export const useProjectParameterContext = () => {
  return useContext(ProjectParemeterContext);
};

export const useProjectParameterDispatchContext = () => {
  return useContext(ProjectParameterDispatchContext);
};
