import React, { useEffect, useState } from "react";
import Calender from "components/molecules/Calender";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import Button from "components/atoms/Button";
import { useEmployee } from "../timesheets-redux/context/EmployeeContext/EmployeeContext";
import { useProject } from "../timesheets-redux/context/ProjectContext/ProjectContext";
import { useTimesheetModalContext } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";

export default function InvoiceModal() {
  const [clientCodeData, setClientCodeData] = useState([
    { fromTime: "", toTime: "", clientCode: "" },
  ]);
  const { projectState } = useProject();
  const { initialEmployeeState } = useEmployee();
  const { initialTimesheetModalState } = useTimesheetModalContext();

  const handleSubmit = () => {};

  const handleSelectChange = (value, fieldName, isMultiSelect) => {};

  const handleInputChange = (index, field, value) => {
    const updatedData = [...clientCodeData];
    updatedData[index][field] = value;
    setClientCodeData(updatedData);
  };

  const handleAddItem = () => {
    setClientCodeData([
      ...clientCodeData,
      { fromTime: "", toTime: "", clientCode: "" },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedData = clientCodeData.filter((_, i) => i !== index);
    setClientCodeData(updatedData);
  };

  const employee = initialEmployeeState.employees.filter((item) => {
    if (initialEmployeeState.employee_id == item.value) {
      return item;
    }
  });

  const project = projectState?.projects?.filter((item) => {
    if (projectState?.project_id == item?.value) {
      return item;
    }
  });
  const date = initialTimesheetModalState.invoice.date;

  const renderItems = () => {
    return clientCodeData.map((item, i) => (
      <div className="d-flex justify-content-between w-75 mb-2" key={i}>
        <div className="">
          <LabelWithInputField
            label={"From"}
            name="from"
            isMandatory={true}
            value={item.fromTime}
            handleChange={(e) =>
              handleInputChange(i, "fromTime", e.target.value)
            }
            id="from"
            type="time"
            error={""}
          />
        </div>
        <div className="">
          <LabelWithInputField
            label={"To"}
            name="to"
            isMandatory={true}
            value={item.toTime}
            handleChange={(e) => handleInputChange(i, "toTime", e.target.value)}
            id="to"
            type="time"
            error={""}
          />
        </div>
        <div className="">
          <LabelWithInputField
            label={"Project info/ Client code"}
            name="clientCode"
            isMandatory={true}
            value={item.clientCode}
            handleChange={(e) =>
              handleInputChange(i, "clientCode", e.target.value)
            }
            id="clientCode"
            type="text"
            error={""}
          />
        </div>
        {i === clientCodeData.length - 1 ? (
          <Button
            title={"+"}
            className={"btn border-1"}
            handleClick={handleAddItem}
          />
        ) : (
          <Button
            title={"-"}
            className={"btn border-1"}
            handleClick={() => handleRemoveItem(i)}
          />
        )}
      </div>
    ));
  };

  return (
    <div className="popup-container">
      <div className="popup">
        <form onSubmit={handleSubmit}>
          <div className="d-flex row mb-2">
            <p>{`Project: ${project[0] ? project[0].label : ""}`}</p>
            <p>{`Employee: ${employee[0] ? employee[0].label : ""}`}</p>
          </div>
          <div className="w-50 form-group mx-2 position-relative">
            <Calender
              onChange={(date) => handleSelectChange(date, "start_date", false)}
              selectedDate={new Date(date)}
              label={"Date"}
              isMandatory={true}
              name={"start_date"}
              error={""}
              isDisabled={false}
            ></Calender>
          </div>
          {renderItems()}
          <div className="d-flex w-50 ">
            <div className="">
              <LabelWithInputField
                label={"Extra mileage"}
                name="extraMileage"
                isMandatory={false}
                value={""}
                handleChange={() => {}}
                id="extraMileage"
                type="text"
                error={""}
              />
            </div>
            <div className="">
              <LabelWithInputField
                label={"Remarks"}
                name="remarks"
                isMandatory={false}
                value={""}
                handleChange={() => {}}
                id="remarks"
                type="text"
                error={""}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="">
              <MaxiconFileUpload
                label={"Upload document"}
                name={"uploadDocument"}
                id={"uploadDocument"}
                isMandatory={false}
                edit={false}
                fileObj={""}
                multiple={false}
                uploadPath="candidate-certificate"
                formats={""}
                handleChange={() => {}}
                maxFileSize={10000}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
