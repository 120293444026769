import React from 'react';
import PlanningBox from '../atoms/planningBox';
import { useEmployee } from '../planning-redux/context/EmployeeContext/EmployeeContext';
import { usePlanning } from '../planning-redux/context/PlanningContext/PlanningContext';
import { getRandomInt, renderPlannedEvents } from '../utils/utils';
import { usePlanningsModalDispatch } from '../planning-redux/context/PlanningModalContext/PlanningModalContext';
import { PLANNING_MODAL_ACTIONS } from '../planning-redux/actions/PlanningModalActions';
import { YEAR_KEYWORD } from '../constants/planningConstants';
import { SALES_VIEW_EXCEPTION_LIST } from '../constants/planningConstants';

const PlanningYear = ({yearlyDates, openPlanningModal, type}) => {
  const {initialEmployeeState} = useEmployee();
  const {planningState} = usePlanning();
  const {dispatchPlanningModal} = usePlanningsModalDispatch();

  const handleWeeklyClickEventOnPlannedUnit = (e, employee_id, date, planid = '') => {

    if(SALES_VIEW_EXCEPTION_LIST.includes(employee_id)) return;
    
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
      payload: {
        employee_id: employee_id,
        start_date: date,
        frequency: 'yearly'
      }
    });
    if(planid != '') {
      dispatchPlanningModal({
        type: PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODEL_FIELD,
        payload: {
          field: 'id',
          value: planid
        }
      });
    }
    openPlanningModal(e);
  }


  const setYearPlanningUnit = () => {
    if(initialEmployeeState.employees && initialEmployeeState.employees.length > 0) {
    return initialEmployeeState.employees.map((employee) => (
   
      <tr key={getRandomInt(1, 10000)}>
        {yearlyDates.map((date) => (
          <td key={getRandomInt(1, 10000)}
          style={{
            position: 'relative'
          }}
          >
            <PlanningBox date={date['value']} employee_id={employee.id} bg_color={"#ebebeb"} width={"100%"} handleWeeklyClickEventOnPlannedUnit={handleWeeklyClickEventOnPlannedUnit}/>
            {renderPlannedEvents(planningState, employee, date, handleWeeklyClickEventOnPlannedUnit, YEAR_KEYWORD)}
          </td>
        ))}
      </tr>
    ));
    }
  };


  const getMonthName = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return monthName;
  }

  return (
      <table className='w-100 text-center'>
        <thead>
          <tr className='sidebar_bg border-0'>
           <th colSpan={yearlyDates.length} key={getRandomInt(1, 10000)} className='py-3 align-items-center justify-content-center'>{getMonthName(yearlyDates[0]['value'])}</th>
          </tr>
        </thead>
        <tbody>
          {setYearPlanningUnit()}
        </tbody>
      </table>
  );
};

export default PlanningYear;
