import { WeekAndDayActions } from "../../actions/WeekAndDayActions";

export const WeekAndDayReducer = (WeekAndDayData, actions) => {
  switch (actions.type) {
    case WeekAndDayActions.CHANGE_WEEK:
      return handleWeek(WeekAndDayData, actions);

    case WeekAndDayActions.CHANGE_YEAR:
      return { ...WeekAndDayData, currentDate: actions.payload };
  }
};

const handleWeek = (data, actions) => {
  const week = new Date(data.currentDate);
  week.setDate(week.getDate() + actions.payload);
  let newObject = { ...data, currentDate: week };

  return newObject;
};
