import React from "react";

export const calculateTotalTime = (
  from: string,
  till: string,
  pause: string
) => {
  // Helper function to format minutes back to "HH:mm"
  const formatTime = (minutes: any) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };
  const fromMinutes = convertToMinutes(from);
  const tillMinutes = convertToMinutes(till);
  const pauseMinutes = pause ? convertToMinutes(pause) : 0;
  // Calculate total working minutes considering the midnight span
  let totalWorkingMinutes;
  if (tillMinutes >= fromMinutes) {
    totalWorkingMinutes = tillMinutes - fromMinutes;
  } else {
    totalWorkingMinutes = (1440 - fromMinutes) + tillMinutes;
  }
  // Calculate net working minutes considering the pause
  const netWorkingMinutes = totalWorkingMinutes - pauseMinutes;
  // Ensure the result is positive
  const positiveWorkingMinutes = Math.abs(netWorkingMinutes);
  return formatTime(positiveWorkingMinutes);
};

// Helper function to convert time to total minutes
const convertToMinutes = (time: any) => {
  if (!time) return 0; // Return 0 if time is not provided

  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};
