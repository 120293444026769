export const WEEK_VIEW_NUMBER  = 2;
export const MONTH_VIEW_NUMBER = 2;
export const YEAR_VIEW_NUMBER = 6;

export const PLANNING_VIEW_OPTIONS = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
]

export const TIMESHEET_FILTERS = 
    {
        state: {
            employee: [],
            company: [],
            project: [],
            startdate: "",
            enddate: "",
            business_unit: [],
        },
    }   
