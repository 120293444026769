import React, { useState } from "react";
import LabelWithSelectField from "components/molecules/LabelwithSelectField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { useProjectParameterContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";

const Regime = (props) => {
  const { planTimingsData, header1, header2, total, name } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()
  const { initialProjectParameterState } = useProjectParameterContext();
  const hadleChange = (event, planId) => {
    const { value, name } = event?.target;
    if (planId) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: planId,
          value: value,
          name: name,
          extraprops: {
            filterOptionsState,
            initialProjectParameterState,
          }
        },
      });
    }
  };
  const renderRegime = () => {
    const weekDays = [
      <td key={"heading1"} className="border">
        {header1}
      </td>,
      <td key={"heading2"} className="border">{header2}</td>,
    ];

    planTimingsData.map((data, i) => {
      weekDays.push(
        data?.plan_id ? (
          <td key={i} className="border">
            <LabelWithSelectField
              label={""}
              name={name}
              placeholder={"Select"}
              selectedValue={data[name] ?? undefined}
              handleChange={(event) => hadleChange(event, data?.plan_id)}
              options={filterOptionsState?.state?.regimen?.map((item) => {
                return { ...item, name: item?.label}
              })}
              id={name}
              error={""}
              labelClassName="d-none"
              errorClass="d-none"
              emptyOptionNotRequired
            />
          </td>
        ) : ( 
          <td key={i} className="border"></td>
        )
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderRegime()}
      <td key={'last'} className="border">{total}</td>
    </tr>
  );
};
export default Regime;
