import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { t } from "pages/microservices/masterData/translation/Translation";
import CardTitle from "components/atoms/CardTitle";
import { IconData } from "services/IconData";

const Budget: React.FC<{ params: any }> = ({ params }) => {
  const { userAuth } = params;
  const percentage = 66;

  return (
    <>
      <div className="col-md-12 col-lg-12 col-xl-4 pt-2 mt-3 ms-auto ">
        <div className="card low-light-skyblue-bg border-0 card-shadow p-4 position-relative dashboard-myBudget-wrapper justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faAngleLeft} className="color-dark-pink" />
            <CardTitle
              title={t("My budget")}
              className={`mx-3 my-budget-title`}
            />
            <FontAwesomeIcon icon={faAngleRight} className="color-dark-pink" />
          </div>
          <div className="position-relative">
            <div
              className={`circular-progress-bar-custom recruitment-card-title `}
              data-label=" €22.000"
            >
              <div>
                <CircularProgressbarWithChildren
                  value={percentage}
                  className="CircularProgressbarWithChildren"
                >
                  <div className="text-center CircularProgressbar-text text-uppercase">
                    {t("Goal")}
                    <br />
                    <div> € 229.000</div>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
          <div>
            <span className="text-center me-2">{IconData.EclipseIcon}</span> {t("Achieved")}
          </div>
        </div>
      </div>
    </>
  );
};
export default Budget;
