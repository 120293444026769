import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import LabelField from "./LabelField";
// import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
// import RequiredIcon from "static/images/RequiredIcon";
interface CKEditorChangeEvent  {
  target: {
    value: string;
  };
};
interface EditorProps {
  index: number;
  title: string;
  name: string;
  required: boolean;
  CustomStyle: string;
  value: string;
  handleChange: CKEditorChangeEvent;
  // setValue: (index: number, name: string, data: string) => void; // Assuming setValue is a function
  error?: string | undefined; //  Assuming error is a string
  styleMargin: string; //  Assuming styleMargin is a string
  isDisabled?: boolean;
}

export const Editor: React.FC<EditorProps> = ({
  index,
  title,
  name,
  required,
  CustomStyle,
  value,
  handleChange,
  // setValue,
  error,
  styleMargin,
  isDisabled = false,
}) => {
  // const CustomConfig = {
  //   toolbar: {
  //     items: [
  //       "heading",
  //       "|",
  //       "fontColor",
  //       "fontSize",
  //       "fontFamily",
  //       "fontBackgroundColor",
  //       "highlight",
  //       "findAndReplace",
  //       "|",
  //       "bold",
  //       "italic",
  //       "underline",
  //       "subscript",
  //       "superscript",
  //       "link",
  //       "|",
  //       "alignment",
  //       "outdent",
  //       "indent",
  //       "|",
  //       "bulletedList",
  //       "numberedList",
  //       "|",
  //       "insertTable",
  //       "mediaEmbed",
  //       "codeBlock",
  //       "imageInsert",
  //       "blockQuote",
  //       "selectAll",
  //       "|",
  //       "undo",
  //       "redo",
  //     ],
  //   },
  // };

  return (
    <div className={'mb-4 position-relative'}>
        {!!title && <LabelField title={title} isMandatory={required} />}
      <CKEditor
        editor={ClassicEditor as any} //  'as any' to bypass type checking temporarily
        // config={CustomConfig}
        disabled={isDisabled}
        data={value}
        onChange={handleChange as any}
      />
      {error && (
        <p className="pt-1 pb-0 m-1 text-danger required-bg rounded font-weight-normal">
          {/* <img
              className="box mr-1 mb-1"
              //   src={<RequiredIcon/>}
              alt="Required"
            ></img> */}
          {error}
        </p>
      )}
    </div>
  );
};
