import { OverviewState, PayRollAction } from "../actions/Interfaces";

export const overviewReducer = (state: OverviewState, action: PayRollAction): OverviewState => {
    switch (action.type) {
        case 'SET_OVERVIEW_DATA':
            return {
                ...state,
                ...action?.payload
            };
        default:
            return state;
    }
};
