import { error } from "console";
import { M_MASTER_DATA } from "constants/Constants";
import { FLEET_AND_MATERIAL_FILTER_OPTIONS, USERS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";

export const fetchOptions = async () => {
    const response = await ApiCall.getService(
        FLEET_AND_MATERIAL_FILTER_OPTIONS,
        "get",
        M_MASTER_DATA
    );
    return response;
};





export const getOptions = (fieldValue: any, dataObj: any) => {
    return ApiCall.service(
        USERS,
        'POST',
        { type: fieldValue, isEdit: dataObj },
        false,
        M_MASTER_DATA,
    )
        .then((res: any) => {
            return res;
        })
        .catch((error: any) => {
            console.error(error);
            throw error;  // Re-throw the error after logging it
        });
};



