import TitleFieldMolecule from "components/molecules/TitleField"
import { t } from "../../masterData/translation/Translation"
import { LabelWithInputFieldAndUnit } from "../../masterData/templates/atoms/LabelWithInputFieldAndUnit"
import { useFormContext } from "../context/Context"
import SelectWithSearch from "components/atoms/SelectWithSearch"
import { validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService"
import { customFunction } from "../CustomFunctions"

const ProjectParameter = () => {
    const { state, dispatch } = useFormContext();
    const { fixedProjectParameters } = state?.data;
    const {
        project,
        projectParameters,
        timeTable,
        hourlyRate,
        noOfKms,
        euroPerKmRate,
        hourlyWage,
        noOfKmsWage,
        euroPerKm
    } = fixedProjectParameters

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        dispatch({
            type: "UPDATE_INPUT_FIELDS",
            payload: {
                value,
                entityType: 'fixedProjectParameters',
                name,
            }
        })
        validationFunction(name, value, true);
    }

    const handleSelectChange = async (event: any, type: string, dependencyFields: any = null) => {
        dispatch({
            type: "UPDATE_INPUT_FIELDS",
            payload: {
                value: event,
                entityType: 'fixedProjectParameters',
                name: type,
            }
        })
        validationFunction(type, event, true);
        if (dependencyFields) {
            const payload = {
                ...(type == 'project' ? { ...fixedProjectParameters, timeTable: '', projectParameters: '', hourlyRate: '', noOfKms: '', euroPerKmRate: '' } : { ...fixedProjectParameters }),
                [type]: event.value,
                classification: 'project',
            }
            const response = await customFunction(payload);
            dispatch({
                type: "UPDATE_OPTIONS",
                payload: {
                    data: response,
                    key: type,
                },
            });
        }
    }

    const validationFunction = (
        name: any = null,
        value: any = null,
        isSingleFieldValidation = false,
    ) => {
        const validationRules: any = {
            project: [validateSelectField],
            projectParameters: [validateSelectField],
            timeTable: [validateSelectField],
            hourlyRate: [validateRequired],
            noOfKms: [validateRequired],
            euroPerKmRate: [validateRequired],
            hourlyWage: [validateRequired],
            noOfKmsWage: [validateRequired],
            euroPerKm: [validateRequired]
        };

        const validationErrors = validateForm(
            { ...state.data?.['fixedProjectParameters']['form'], [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        const isFieldValid = Object.keys(validationErrors).length === 0;

        let updatedErrors;
        if (isSingleFieldValidation) {
            updatedErrors = {
                ...state.errors['fixedProjectParameters'],
                [name]: isFieldValid ? "" : validationErrors[name],
            };
            dispatch({
                type: "UPDATE_FIELD_ERRORS",
                payload: {
                    id: 'fixedProjectParameters',
                    errors: updatedErrors,
                },
            });
        } else {
            updatedErrors = {
                ...state.errors['fixedProjectParameters'],
                ...validationErrors,
            };
            dispatch({
                type: "UPDATE_FIELD_ERRORS",
                payload: {
                    id: 'fixedProjectParameters',
                    errors: updatedErrors,
                },
            });
            return Object.keys(validationErrors).length;;
        }
    };

    return (
        <>
            {/* <TitleFieldMolecule
                classname="top-0"
                title={t("Fixed project parameters")}
            /> */}
            <div className="page-title position-sticky top-0 bg-white z-6">
                {t("Fixed project parameters")}
            </div>
            <div className="row">
                <div className="col-12">
                    <SelectWithSearch
                        onChange={(event) => { handleSelectChange(event, 'project', { options: ['project', 'projectParameters', 'timeTable'] }) }}
                        title={t("Project")}
                        placeHolder={t("Select project")}
                        search={true}
                        options={state?.data?.options?.project}
                        isMulti={false}
                        name={'project'}
                        id="project"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['project']}
                        value={project}
                    />
                </div>
                <div className="col-6">
                    <SelectWithSearch
                        onChange={(event) => { handleSelectChange(event, 'projectParameters') }}
                        title={t("Project parameters")}
                        placeHolder={t("Select project parameters")}
                        search={true}
                        options={state?.data?.options?.template}
                        isMulti={false}
                        name={'projectParameters'}
                        id="projectParameters"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['projectParameters']}
                        value={projectParameters}
                    />
                </div>
                <div className="col-6">
                    <SelectWithSearch
                        onChange={(event) => { handleSelectChange(event, 'timeTable', { options: 'timeTable' }) }}
                        title={t("Timetable")}
                        placeHolder={t("Select timetable")}
                        search={true}
                        options={state?.data?.options?.timeTable}
                        value={timeTable}
                        isMulti={false}
                        name={'timeTable'}
                        id="timeTable"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['timeTable']}
                    />
                </div>
                <div className="col-4">
                    <LabelWithInputFieldAndUnit
                        type="text"
                        placeholder="Hourly rate"
                        handleChange={handleChange}
                        label={t("Hourly rate")}
                        value={hourlyRate}
                        name={'hourlyRate'}
                        id="hourlyRate"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['hourlyRate']}
                        min={0}
                        unit={"€"}
                        containerClassName="col-12"
                    />
                </div>
                <div className="col-4">
                    <LabelWithInputFieldAndUnit
                        type="text"
                        placeholder="No of km"
                        handleChange={handleChange}
                        label={t("Number of km")}
                        value={noOfKms}
                        name={'noOfKms'}
                        id="noOfKms"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['noOfKms']}
                        min={0}
                        unit={"km"}
                        containerClassName="col-12"
                    />
                </div>
                <div className="col-4">
                    <LabelWithInputFieldAndUnit
                        type="text"
                        placeholder="Euro/km"
                        handleChange={handleChange}
                        label={t("Euro/km")}
                        value={euroPerKmRate}
                        name={'euroPerKmRate'}
                        id="euroPerKmRate"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['euroPerKmRate']}
                        min={0}
                        unit={"€"}
                        containerClassName="col-12"
                    />
                </div>
                <div className="col-4">
                    <LabelWithInputFieldAndUnit
                        type="text"
                        placeholder="Hourly wage"
                        handleChange={handleChange}
                        label={t("Hourly wage")}
                        value={hourlyWage}
                        name={'hourlyWage'}
                        id="hourlyWage"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['hourlyWage']}
                        min={0}
                        unit={"€"}
                        containerClassName="col-12"
                    />
                </div>
                <div className="col-4">
                    <LabelWithInputFieldAndUnit
                        type="text"
                        placeholder="Number of km"
                        handleChange={handleChange}
                        label={t("Number of km")}
                        value={noOfKmsWage}
                        name={'noOfKmsWage'}
                        id="noOfKmsWage"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['noOfKmsWage']}
                        min={0}
                        unit={"km"}
                        containerClassName="col-12"
                    />
                </div>
                <div className="col-4">
                    <LabelWithInputFieldAndUnit
                        type="text"
                        placeholder="Euro/km"
                        handleChange={handleChange}
                        label={t("Euro/km")}
                        value={euroPerKm}
                        name={'euroPerKm'}
                        id="euroPerKm"
                        isMandatory={true}
                        error={state?.errors['fixedProjectParameters']['euroPerKm']}
                        min={0}
                        unit={"€"}
                        containerClassName="col-12"
                    />
                </div>

            </div>

        </>
    )

}
export default ProjectParameter