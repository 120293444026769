import React, { useState, ChangeEvent } from "react";

const style = {};

interface Props {
  id?: string;
  type?: string;
  className?: string;
  value?: any;
  checked?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange: (value: string) => void;
  name?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  defaultValue?: string;
  title?: string;
}

const TimeField: React.FC<Props> = ({
  id = "",
  type = "time",
  min,
  max,
  step,
  className = "",
  value,
  isDisabled = false,
  placeholder = "",
  customStyle = {},
  handleChange,
  name = "",
  error,
  checked,
  readOnly,
  defaultValue,
  title = ""
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      {/* <span className={className} title={title}>
        <TimeField
          value={value}
          onChange={(event, value) => { handleChange(value) }}
          colon=":"
          showSeconds={false}
          style={{ ...style, ...customStyle }}
        />
      </span> */}
      <input
        style={{ ...style, ...customStyle }}
        id={id}
        title={title}
        type={type}
        className={className}
        disabled={isDisabled}
        placeholder={isFocused ? "" : placeholder}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default TimeField;
