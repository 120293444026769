import React from "react";
import Accordion from "react-bootstrap/Accordion";
// import { useFormContext } from "utils/multiTabForm/context/Context";

interface TreeComponentProps {
  functionName?: { [key: string]: string[] };
}

const TreeComponent: React.FC<TreeComponentProps> = ({ functionName }) => {
  if (!functionName) return null; 

  // const { state, dispatch } = useFormContext();

  const accordionNames = Object.keys(functionName);

  return (
    <Accordion defaultActiveKey={accordionNames[0]} className="my-3">
      {accordionNames.map((accordionName, index) => (
        <Accordion.Item key={index} eventKey={accordionName}>
          <Accordion.Header>{accordionName}</Accordion.Header>
          <Accordion.Body>
            <ul>
              {functionName[accordionName].map((funcName, funcIndex) => (
                <li key={funcIndex}>{funcName}</li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default TreeComponent;
