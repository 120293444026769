import React from 'react';
interface Option {
  value?: number;
  name?: string;
}
interface MultiSelectFieldProps {
  options: Option[];
  placeholder: string;
  selectedValue: string | undefined; // Update the type to allow undefined
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
  isMandatory?: boolean;
  name: string;
  className?: string;
  error?: string;
  id?: string;
  errorClass?: string;
  emptyOptionNotRequired?: boolean;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  options,
  placeholder,
  selectedValue,
  handleChange,
  disabled,
  isMultiSelect,
  name,
  className,
  error,
  id,
  errorClass,
  emptyOptionNotRequired = false
}) => {

  return (
    <div>
      <select
        className={`form-select ${className} rounded-0 shadow-none`}
        name={name}
        value={selectedValue}
        onChange={handleChange ?? undefined}
        disabled={disabled}
        multiple={isMultiSelect}
        id={id}
      >
        {!emptyOptionNotRequired && <option value="">{placeholder}</option>}
        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.name}
          </option>
        ))}
      </select>
      <div className={`height-20 mb-2 ${errorClass}`}>
        {error && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
};

export default MultiSelectField;
