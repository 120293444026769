import { REQUIREMENT_DETAILS } from "routes/ApiEndpoints";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { ApiCall } from "services/ApiServices";
import {
  validateForm,
  validateMultiSelectField,
  validateSelectField,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "utils/TypeAnnotations";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { M_MASTER_DATA } from '../../../../constants/Constants';

interface RequirementProps {
  experience: Option | null;
  driverLic: Option | null;
  education: object[];
  additional: object[];
  other: object[];
}

const Requirement = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [formData, setFormData] = useState<RequirementProps>({
    experience: null,
    driverLic: null,
    education: [],
    additional: [],
    other: [],
  });

  const [options, setOptions] = useState({
    experienceOptions: [] as OptionProps[],
    driverLicOptions: [] as OptionProps[],
    educationOptions: [] as OptionProps[],
    additionalOptions: [] as OptionProps[],
    otherOptions: [] as OptionProps[],

  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    experience: "",
    driverLic: "",
    education: "",
    additional: "",
    other: "",
  });

  useEffect(() => {
    const fetchRequirementDetails = async () => {
      const url = id ? `${REQUIREMENT_DETAILS}/${id}` : REQUIREMENT_DETAILS;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response.status === 200) {
        console.log(response.data);
        const experienceOptions = mapToSelect(response.data.experiences);
        const driverLicOptions = mapToSelect(response.data.driverLicenses);
        const educationOptions = mapToSelect(response.data.educational);
        const additionalOptions = mapToSelect(response.data.educational);
        const otherOptions = mapToSelect(response.data.educational);

        setOptions({
          experienceOptions: experienceOptions,
          driverLicOptions: driverLicOptions,
          educationOptions: educationOptions,
          additionalOptions: additionalOptions,
          otherOptions: otherOptions,
        });
      }
    };
    fetchRequirementDetails();
  }, []);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      experience: [validateSelectField],
      driversLic: [validateSelectField],
      education: [validateMultiSelectField],
      additional: [validateMultiSelectField],
      other: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
    validation(name, selectedOption, true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    console.log(formData);
    navigate(`/employment/${id}`);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="text-center py-4 page-title mb-0`">
          <h1>Create vacancy</h1>
          <p>Hey, thanks for providing the general info and competences, please go ahead to provide the offer and requirement details</p>
          <p>so we can craft a perfect Job description for your vacancy</p>
          <h2>Requirement details</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Years of experience"
              name="experience"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.experienceOptions}
              value={formData.experience}
              onChange={(e) => handleSelectChange(e, "experience")}
              isMulti={false}
              className="select-field"
              error={errors.experience}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Driver's License"
              name="driverLic"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.driverLicOptions}
              value={formData.driverLic}
              onChange={(e) => handleSelectChange(e, "driverLic")}
              isMulti={false}
              className="select-field"
              error={errors.driverLic}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Educational certificate"
              name="education"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.educationOptions}
              value={formData.education}
              onChange={(e) => handleSelectChange(e, "education")}
              isMulti={true}
              className="select-field"
              error={errors.education}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title="Additional certificate"
              name="additional"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.experienceOptions}
              value={formData.additional}
              onChange={(e) => handleSelectChange(e, "additional")}
              isMulti={false}
              className="select-field"
              error={errors.additional}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title="Other certificate"
              name="other"
              placeHolder=""
              isMandatory={true}
              search={true}
              options={options.otherOptions}
              value={formData.other}
              onChange={(e) => handleSelectChange(e, "other")}
              isMulti={false}
              className="select-field"
              error={errors.other}
            />
          </div>
        </div>
        <div className="d-none d-md-block d-lg-block">
          <div className="row mb-4">
            <div className="col-md-4 align-self-center">
              <Link
                to={`/offer/${id}`}
                className="text-uppercase back-btn text-decoration-underline"
              >
                Back
              </Link>
            </div>
            <div className="col-md-8 text-end">
              <Button
                title="Next"
                type="submit"
                className="btn form-button rounded-0 shadow-none text-uppercase"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Requirement;
