import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_CREATE_CLIENT, PATH_EDIT_CLIENT,
} from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_DELETE
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { IconData } from "services/IconData";

interface ManageClientProps {
  data?: any[];
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetClient: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageClient: React.FC<ManageClientProps> = ({
  data,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetClient,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    clientType: [],
  })

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        CLIENT_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetClient({
          page: 1,
          nOR: 10,
          type:'client',
          search: {
            name: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData()
  }, [id]);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState, clientType: filterData.data.clients,
        }));
      }
      else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    }
    catch (error) {
      console.error(error)
    }

  }

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CLIENT}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Client",
        read: true,
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="row header-sticky position-sticky">
            <div className="col-md-12">
              <h1 className="py-4 page-title mb-0">{t("Manage clients")}</h1>
            </div>
          </div>
          <div className="row pb-4 search-bar">
            <FilterElementsRender
              data={filterOptions?.clientType}
              type="multi-select"
              handleChange={(event) =>
                handleSearchChange(event, "clientType", "select")
              }
              value={searchData?.clientType}
              placeHolder={"Client type"}
              name="clientType"
            />

            <FilterElementsRender
              data={searchData?.name}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "name", "text")
              }
              value={searchData?.name}
              placeHolder={"Name"}
              name="name"
            />


            <FilterElementsRender
              data={searchData?.vatNumber}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "vatNumber", "text")
              }
              value={searchData?.vatNumber}
              placeHolder={"VAT number"}
              name="vatNumber"
            />
            <FilterElementsRender
              data={searchData?.email}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "email", "text")
              }
              value={searchData?.email}
              placeHolder={"Email"}
              name="email"
            />

            <div className="col mt-auto text-end ">
                  <Button
                    title={IconData.ResetIcon}
                    type="submit"
                    handleClick={(e) => handleSubmit(e, true)}
                    className="btn delete-btn  shadow-none text-uppercase me-3 "
                  />
                  <Button
                    title="Search"
                    type="submit"
                    handleClick={handleSubmit}
                    className="btn form-button  shadow-none text-uppercase px-3 search-btns"
                  />      
            </div>
          </div>
          <div className="p-5 border rounded-4 table-custom">
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[{
                  permission: "Client",
                  create: true,
                }]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_CREATE_CLIENT}
                  className="table-button"
                >
                  + {"Create client"}
                </Link>
              </AccessControl>
            </div>
            <div className="table-responsive manage-companies">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Client type")}</th>
                    <th>{t("Name")}</th>
                    <th>{t("VAT number")}</th>
                    <th>{t("Email")}</th>
                    <th className="table_actions">
                      <AccessControl
                        requiredPermissions={[{
                          permission: "Client",
                          update: true,
                          delete: true,
                        }]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl></th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record, key) => {
                      return (
                        <tr key={key}>

                          <td className="align-middle">{record.type.name}</td>
                          <td className="align-middle">{record.name}</td>
                          <td className="align-middle">{record.vatNumber}</td>
                          <td className="align-middle">{record.email}</td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleDelete={handleArchive}
                              value={record.id}
                              permission={"Client"}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManageClient;
