import React from 'react';
// Make sure the import statements are like this:
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import LabelField from '../atoms/LabelField';

interface LabelWithCKEditorFieldProps {
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMandatory?: boolean;
  handleChange?: (event: any, editor: any) => void; // CKEditor passes the editor object too
  error?: string;
  id?: string;
}

const LabelWithCKEditorField: React.FC<LabelWithCKEditorFieldProps> = ({
  label,
  name,
  value,
  placeholder = '',
  isDisabled = false,
  isMandatory,
  handleChange,
  error,
  id,
}) => {
  return (
    <div>
      <LabelField title={label} isMandatory={isMandatory} />
      <CKEditor
        editor={ ClassicEditor as any}
        data={value}
        disabled={isDisabled}
        config={{
          placeholder: placeholder,
        }}
        onChange={handleChange}
        id={id}
      />
      <div className='height-20 mb-2'>
        {error && <div className='text-danger'>{error}</div>}
      </div>
    </div>
  );
};

export default LabelWithCKEditorField;
