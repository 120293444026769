import React from 'react'

const ViewIcon:React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24.891" height="16.983" viewBox="0 0 24.891 16.983">
        <path id="View" d="M12.472,2.441A12.872,12.872,0,0,1,20.036,4.8a13.3,13.3,0,0,1,4.856,6.13,13.307,13.307,0,0,1-4.856,6.13,12.868,12.868,0,0,1-7.563,2.362,12.991,12.991,0,0,1-7.59-2.362A13.246,13.246,0,0,1,0,10.933,13.251,13.251,0,0,1,4.883,4.8,12.989,12.989,0,0,1,12.472,2.441Zm0,14.17a5.378,5.378,0,0,0,3.98-1.672A5.511,5.511,0,0,0,18.1,10.933a5.511,5.511,0,0,0-1.645-4.007,5.378,5.378,0,0,0-3.98-1.672A5.464,5.464,0,0,0,8.466,6.926a5.469,5.469,0,0,0-1.672,4.007,5.462,5.462,0,0,0,1.672,4.007A5.467,5.467,0,0,0,12.472,16.611Zm-.026-9.075a3.339,3.339,0,0,1,2.415.982,3.238,3.238,0,0,1,1.008,2.415,3.238,3.238,0,0,1-1.008,2.415,3.339,3.339,0,0,1-2.415.982,3.251,3.251,0,0,1-2.388-.982,3.284,3.284,0,0,1-.983-2.415,3.353,3.353,0,0,1,3.37-3.4Z" transform="translate(0 -2.441)" fill="currentColor" />
      </svg>

    </>
  )
}

export default ViewIcon