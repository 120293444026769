import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import Button from "components/atoms/Button";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "components/atoms/Pagination";
import CustomNotify from "components/atoms/CustomNotify";
import { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  GET_ALL_EMPLOYEES_LEAVE_COUNT,
  GET_BUSINESS_UNIT_AND_EMPLOYEE,
  GET_OPTIONS_FOR_EMPLOYER_LEAVE_COUNT,
} from "routes/ApiEndpoints";
import { MANAGE_EMPLOYER_LEAVES } from "constants/Paths";
import { PanelMenu } from "primereact/panelmenu";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import LeaveTreeTable from "./TreeTable";
import { IconData } from "services/IconData";

interface LeaveCountData {
  business_unit: any;
  employees: any;
}

interface DataProps {
  page: number;
  search?: any;
}

interface Options {
  businessUnits: any;
  employees: any;
}

const LeaveCountOfEmployer = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    business_unit: queryParams.get("business_unit") ?? "",
    employees: queryParams.get("employee") ?? "",
  });
  const [leaveCountOfEmployees, setLeaveCountOfEmployees] = useState<any>([]);
  const [options, setOptions] = useState<Options>({
    businessUnits: [],
    employees: [],
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_BUSINESS_UNIT_AND_EMPLOYEE,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        const { businessUnit, employee } = response;
        setOptions((prevState: any) => ({
          ...prevState,
          businessUnits: [...businessUnit],
          employees: [...employee],
        }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error("Error occured ", error);
    }
  };

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };
    fetchLeaveCountOfEmployees(data); //To get leave count details of all employees
  }, [currentPage, submit]);

  const fetchLeaveCountOfEmployees = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_ALL_EMPLOYEES_LEAVE_COUNT,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const { data } = response?.data;

        setLeaveCountOfEmployees(response?.data);
        setTotalPages(response.totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };

  //onChange event handler for select element
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({ business_unit: "", employees: "" });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: LeaveCountData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div>
        <TitleFieldMolecule title={t("Leaves count")} />
        <div className="row mb-3">
          <div className="col">
            <div className="">
              <label className="form-label field-label">
                {t("Business unit")}
              </label>
            </div>
            <select
              className="form-select shadow-none"
              name="business_unit"
              id="business_unit"
              onChange={handleSelectChange}
              value={search.business_unit}
            >
              {options.businessUnits && options.businessUnits.length > 0 ? (
                <>
                  <option value="">{t("Select business unit")}</option>
                  {options.businessUnits.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No business unit available")}</option>
              )}
            </select>
          </div>
          <div className="col">
            <div className="">
              <label className="form-label field-label">
                {t("Employee")}
              </label>
            </div>
            <select
              className="form-select shadow-none"
              name="employees"
              id="employee"
              onChange={handleSelectChange}
              value={search.employees}
            >
              {options.employees && options.employees.length > 0 ? (
                <>
                  <option value="">{t("Select employee")}</option>
                  {options.employees.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No employee available")}</option>
              )}
            </select>
          </div>
          <div className="col text-end mt-auto">
            <Button
              title={IconData.ResetIcon}
              type="submit"
              handleClick={(e) => handleSubmit(e, true)}
              className="btn delete-btn  shadow-none text-uppercase me-3 "
            />
            <Button
              title="Search"
              type="submit"
              handleClick={handleSubmit}
              className="btn form-button  shadow-none text-uppercase px-3 search-btns"
            />
          </div>
        </div>
      </div>
      <div className="p-5 border rounded-4 table-custom">
        <LeaveTreeTable data={leaveCountOfEmployees} />
        <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <div className="col-md-6 align-self-center my-4">
        <Link
          to={`${MANAGE_EMPLOYER_LEAVES}`}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
    </>
  );
};
export default LeaveCountOfEmployer;
