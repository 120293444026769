import React from "react";
import styles from "./AccessDenied.module.css";
import Button from "../../components/atoms/Button";
import { useNavigate } from "react-router-dom";

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();
  const handleUpdate = () => {
    navigate("/dashboard")
  }
  return (
    <>
      <div className="row">
        {/* <div className={`col-6 ${styles.imagage_block}  pe-5 ps-0`}>
          <img src="../../images/Curved.png" alt="" style={{ width: "100%", height: "90vh" }} />
        </div> */}
        <div className="col-6 ps-5 m-auto">
          <p className={`${styles.accessthree} mb-0`}>403</p>
          <p className={`${styles.accessDenied}`}>Access Denied</p>
        
          <Button
            className="submit-btn"
            title={"Go Home"}
            handleClick={handleUpdate}
          />
        </div>
      </div>
    </>
  );
};

export default AccessDenied;
