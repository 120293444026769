import React from "react";
import { FormProvider } from "pages/microservices/project/context/Context";
import { ProjectForm } from "pages/microservices/project/Index";

export const ProjectFormProvider = () => {
  return (
    <>
      <FormProvider>
        <ProjectForm />
      </FormProvider>
    </>
  );
};
