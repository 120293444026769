import ModalPopup from "components/atoms/ModalPopup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "../../context/Context";
import "../css/Agreement.css";
import GeneralTab from "./GeneralTab";
import BillingTab from "./BillingTab";
import ContactsTab from "./ContactsTab";
import CompositionTab from "./CompositionTab";
import InvoiceTab from "./InvoiceTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { M_COMPANY_CREATION } from '../../../../../../constants/Constants';

type DynamicActionType = "UPDATE_GENERAL_FIELD";

export const AgreementForm = () => {
  const { modalId } = useParams<{ modalId: string }>();
  const { companyId } = useParams<{ companyId: string }>();
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (modalId === "" || modalId === null || modalId === undefined) {
      dispatch({ type: "MODAL_STATUS", value: true });
    }

    const getAgreementDetails = async () => {
      const response = await ApiCall.getService(
        `getAgreementDetails/${companyId}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        const {
          blueCollarPCs,
          whiteCollarPCs,
          employeeTypes,
          generalDetails,
          extraInfoTitle,
          extraInfoData,
          contactDetails
        } = response.data;

        const employeeOptions = mapToSelect(employeeTypes);
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: blueCollarPCs,
          field: "blueCollarPCs",
        });
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: whiteCollarPCs,
          field: "whiteCollarPCs",
        });
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: employeeOptions,
          field: "employeeTypes",
        });

        const fieldMappings = [
          {
            fieldPrefix: "general",
            data: generalDetails,
          },
        ];

        fieldMappings.forEach(({ fieldPrefix, data }) => {
          if (data) {
            Object.entries(data).forEach(([field, value]) => {
              const dynamicActionType: DynamicActionType =
                `UPDATE_${fieldPrefix.toUpperCase()}_FIELD` as DynamicActionType;
              dispatch({
                type: dynamicActionType,
                field,
                value: value as string | null | number,
              });
            });
          }
        });

        if (extraInfoTitle && extraInfoData && extraInfoData.length > 0) {
          extraInfoData.forEach((info: Record<string, any>, index: number) => {
            const updatedInfo = { ...info };
            const matchingTitle = extraInfoTitle.find(
              (titleItem: any) => titleItem.id === updatedInfo.extraInfoId
            );

            if (matchingTitle) {
              updatedInfo.title = matchingTitle.name;

              // Dispatch an action for each field in the modified contact
              Object.entries(updatedInfo).forEach(([field, value]) => {
                dispatch({
                  type: "ADD_COMPOSITION_FIELD",
                  field,
                  value,
                  index,
                });
              });
            }
          });
        }

        if (contactDetails && contactDetails.length > 0) {
          contactDetails.forEach(
            (contact: Record<string, any>, index: number) => {
              const updatedContact = { ...contact };
              // Modify the 'phNumber' field
              if (updatedContact.hasOwnProperty("phNumber")) {
                updatedContact.phNumber = "+" + updatedContact.phNumber;
              }
              if (updatedContact.hasOwnProperty("dob") && typeof updatedContact.dob === "string") {
                updatedContact.dob = new Date(updatedContact.dob);
              }
              // Dispatch an action for each field in the modified contact
              Object.entries(updatedContact).forEach(([field, value]) => {
                dispatch({
                  type: "ADD_UPDATE_DYNAMIC",
                  field,
                  value,
                  index,
                });
              });
            }
          );
        }
      }
    };
    getAgreementDetails();
  }, [modalId, dispatch]);

  const handleYesButton = () => {
    const newId = 1;

    navigate(`/create/cooperation-agreement/${companyId}/${newId}`);
    dispatch({ type: "MODAL_STATUS", value: false });
  };

  const handleNoButton = () => {
    const newId = 0;
    navigate(`/create/cooperation-agreement/${companyId}/${newId}`);
    dispatch({ type: "MODAL_STATUS", value: false });
  };

  const handleModalClose = () => {
    navigate(`/`);
  };

  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex((tab) => tabId === tab.id);
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "general":
        return <GeneralTab />;
      case "billing":
        return <BillingTab />;
      case "contact":
        return <ContactsTab />;
      case "compositionData":
        return modalId === "0" ? null : <CompositionTab />;
      case "invoice":
        return <InvoiceTab />;
      default:
        break;
    }
  };

  return (
    <>
      <nav className="agreement-step-tabs py-3">
        <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
          {state.tabDetails.map((item: any, index) =>
            modalId === "0" && item.id === "compositionData" ? null : (
              <button
                key={item.id}
                className={`nav-link rounded-0 shadow-none me-lg-3 text-break ${
                  item.error ? "error" : ""
                } ${item.showStatus ? "active" : "inactive"}`}
                id={`${item.id}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.id}`}
                type="button"
                role="tab"
                aria-controls={`nav-${item.id}`}
                aria-selected={item.showStatus}
                onClick={() => handleTabClick(item.id)}
              >
                <div className="steps rounded-circle">{modalId === "0" && item.id === 'invoice' ? 4 : `${index + 1}`}</div>
                <div className="step-title">
                  {item.title}
                  {item.error && (
                    <span
                      className="exclamation-icon"
                      title={`Please fill all the mandatory fields`}
                      data-toggle="tooltip"
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </span>
                  )}
                </div>
              </button>
            )
          )}
        </div>
      </nav>

      <div className="tab-content" id="nav-tabContent">
        {state.tabDetails.map((item: any) => (
          <div
            key={item.id}
            className={`tab-pane fade ${item.showStatus ? "show" : ""} ${
              item.showStatus ? "active" : ""
            }`}
            id={item.id}
            role="tabpanel"
            aria-labelledby={`${item.id}-tab`}
          >
            {ConstructTabs(item.id, item.showStatus)}
          </div>
        ))}
      </div>

      <ModalPopup
        show={state.modalStatus}
        onHide={handleModalClose}
        title="coefficient"
        body="Coefficient is included or not"
        onCloseButtonClick={handleNoButton}
        onConfirmButtonClick={handleYesButton}
        closeTitle="No"
        confirmTitle="Yes"
        className="slide-in-modal"
      />
    </>
  );
};
