import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";
import { Accordion } from "react-bootstrap";
import { LabelWithInputFieldAndUnit } from "../templates/atoms/LabelWithInputFieldAndUnit";
import { t } from "../translation/Translation";
import { Key, MouseEventHandler } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

interface CommonComponent {
  handleSelectChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    category: string
  ) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDateChange: (date: Date | null, name: string) => void;
  handleOnSubmit: MouseEventHandler<HTMLButtonElement>;
  leavesType: any;
  radioButtonState: any;
  checkboxState: any;
  leaveData: any;
  leaveDataError: any;
  fileMode: string;
  hrOptions?: any;
  leaveBalance?: any;
}

interface FullDayComponent {
  handleDateChange: (date: Date | null, name: string) => void;
  leaveData: any;
  leaveDataError: any;
  fileMode: string;
}

interface HalfDayComponent {
  leaveData: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDateChange: (date: Date | null, name: string) => void;
  leaveDataError: any;
  fileMode: string;
}

interface HourLeaveComponent {
  handleDateChange: (date: Date | null, name: string) => void;
  leaveData: any;
  leaveDataError: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  fileMode: string;
}

const FullDayComponent: React.FC<FullDayComponent> = ({
  handleDateChange,
  leaveData,
  leaveDataError,
  fileMode,
}) => {
  return (
    <>
      <div className="col-12 mt-2">
        <div className="row">
          <div className="col-6 position-relative">
            <Calender
              onChange={(event) => {
                handleDateChange(event, "from_date");
              }}
              selectedDate={leaveData.from_date}
              label={t("From date")}
              isMandatory={true}
              name={"from_date"}
              isDisabled={fileMode === "view"}
              error={leaveDataError.from_date}
            />
          </div>
          <div className="col-6 position-relative">
            <Calender
              onChange={(event) => {
                handleDateChange(event, "to_date");
              }}
              selectedDate={leaveData.to_date}
              label={t("To date")}
              name={"to_date"}
              isDisabled={fileMode === "view"}
              error={leaveDataError.to_date}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const HalfDayComponent: React.FC<HalfDayComponent> = ({
  leaveData,
  handleDateChange,
  leaveDataError,
  fileMode,
}) => {
  return (
    <>
      <div className="col-12 mt-2">
        <div className="row">
          <div className="col-6 position-relative">
            <Calender
              onChange={(event) => {
                handleDateChange(event, "leave_date");
              }}
              selectedDate={leaveData.leave_date}
              label={t("Leave date")}
              isMandatory={true}
              name={"leave_date"}
              error={leaveDataError.leave_date}
              isDisabled={fileMode === "view"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const HourLeaveComponent: React.FC<HourLeaveComponent> = ({
  handleDateChange,
  leaveData,
  leaveDataError,
  handleInputChange,
  fileMode,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-6 position-relative">
          <Calender
            onChange={(event) => {
              handleDateChange(event, "from_date");
            }}
            selectedDate={leaveData.from_date}
            label={t("From")}
            isMandatory={true}
            name={"from_date"}
            error={leaveDataError.from_date}
            isDisabled={fileMode === "view"}
          />
        </div>
        <div className="col-6 position-relative">
          <Calender
            onChange={(event) => {
              handleDateChange(event, "to_date");
            }}
            selectedDate={leaveData.to_date}
            label={t("To")}
            name={"to_date"}
            error={leaveDataError.to_date}
            isDisabled={fileMode === "view"}
          />
        </div>
        <div className="col-6">
          <LabelWithInputFieldAndUnit
            label={t("Hours")}
            type="number"
            name="no_of_hours"
            id="no_of_hours"
            placeholder={"Enter no of hours"}
            handleChange={handleInputChange}
            isMandatory={true}
            value={leaveData.no_of_hours}
            unit={"Hours"}
            error={leaveDataError.no_of_hours}
            isDisabled={fileMode === "view"}
          />
        </div>
      </div>
    </>
  );
};

const CommonComponent: React.FC<CommonComponent> = ({
  handleSelectChange,
  handleInputChange,
  handleDateChange,
  leavesType,
  radioButtonState,
  checkboxState,
  leaveData,
  leaveDataError,
  fileMode,
  hrOptions,
  leaveBalance,
}) => {
  return (
    <>
      <div className="col-12 mb-2">
        {fileMode != "view" ? (
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("Leaves and count")}</Accordion.Header>
              <Accordion.Body>
                <table className="table table-hover">
                  <thead className="TableHeader">
                    <tr>
                      <th className="border-0">{t("Leaves")}</th>
                      <th className="border-0">{t("Balance")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(leaveBalance) &&
                      leaveBalance.map((item, index) => (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>{item.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <></>
        )}
      </div>
      <div className="col-6">
        <SelectWithSearch
          onChange={(event) => {
            handleSelectChange(event, "leave_type");
          }}
          title={t("Types")}
          placeHolder={t("Select type")}
          search={true}
          options={leavesType}
          value={leaveData.leave_type}
          isDisabled={fileMode === "view"}
          isMandatory={true}
          error={leaveDataError.leave_type}
          name={"leave_type"}
          id={"leave_type"}
        />
      </div>
      <div className="col-6">
        <div className="row">
          {leaveData.count_type === "2"
            ? checkboxState
                .filter(
                  (eachPeriod: { label: string }) =>
                    eachPeriod.label !== "Hours"
                )
                .map(
                  (
                    eachPeriod: { label: string | undefined },
                    index: Key | null | undefined
                  ) => (
                    <div className="col-4 mt-5" key={index}>
                      <CheckBoxField
                        label={eachPeriod.label}
                        name="is_full_day"
                        id={eachPeriod.label}
                        isChecked={eachPeriod.label === leaveData.is_full_day}
                        onChangeHandler={handleInputChange}
                        disable={fileMode === "view"}
                      />
                    </div>
                  )
                )
            : leaveData.count_type === "1"
            ? checkboxState
                .filter(
                  (eachPeriod: { label: string }) =>
                    eachPeriod.label === "Hours"
                )
                .map(
                  (
                    eachPeriod: { label: string | undefined },
                    index: Key | null | undefined
                  ) => (
                    <div className="col-4 mt-5" key={index}>
                      <CheckBoxField
                        label={eachPeriod.label}
                        name="is_full_day"
                        id={eachPeriod.label}
                        isChecked={eachPeriod.label === leaveData.is_full_day}
                        onChangeHandler={handleInputChange}
                        disable={fileMode === "view"}
                      />
                    </div>
                  )
                )
            : null}

          <div className="d-flex mt-3">
            {leaveData.is_full_day === "Half day" &&
            leaveData.count_type === "2" ? (
              radioButtonState.map((eachItem: any, index: number) => (
                <div className="">
                  <RadioField
                    name="leave_period"
                    ischecked={eachItem.label === leaveData.leave_period}
                    handleChange={handleInputChange}
                    label={t(eachItem.label)}
                    id={eachItem.label}
                    key={index}
                    disable={fileMode === "view"}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <>
        {leaveData.is_full_day === "Full day" &&
        leaveData.count_type === "2" ? (
          <FullDayComponent
            leaveData={leaveData}
            fileMode={fileMode}
            handleDateChange={handleDateChange}
            leaveDataError={leaveDataError}
          />
        ) : leaveData.is_full_day === "Half day" &&
          leaveData.count_type === "2" ? (
          <HalfDayComponent
            leaveData={leaveData}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            leaveDataError={leaveDataError}
            fileMode={fileMode}
          />
        ) : leaveData.count_type === "1" ? (
          <HourLeaveComponent
            leaveData={leaveData}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            leaveDataError={leaveDataError}
            fileMode={fileMode}
          />
        ) : null}
      </>
      <div className="col-6">
        <LabelWithInputField
          handleChange={handleInputChange}
          label={t("Reason")}
          value={leaveData.reason}
          name="reason"
          id="reason"
          error={leaveDataError.reason}
          isDisabled={fileMode === "view"}
        />
      </div>
      <div className="col-6">
        <SelectWithSearch
          onChange={(event) => {
            handleSelectChange(event, "request_to");
          }}
          title={t("Send request to")}
          placeHolder={t("Select")}
          search={true}
          options={hrOptions}
          value={leaveData.request_to}
          isMandatory={true}
          error={leaveDataError.request_to}
          name={"request_to"}
          id={"request_to"}
          isDisabled={fileMode === "view"}
        />
      </div>
    </>
  );
};
export default CommonComponent;
