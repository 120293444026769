import MarginCalculator from ".";
import { MarginProvider } from "./context/Context";

export const MarginProviderWrapper = () => {
    return (
        <>
            <MarginProvider>
                <MarginCalculator />
            </MarginProvider>
        </>
    );
};