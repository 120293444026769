import React, { useState, useEffect, useRef } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "pages/microservices/masterData/translation/Translation";
import { renderFormElements } from "./FormElements";
import { validateForm } from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate } from "react-router-dom";
import { GET_ENTITY, GET_FORM_DATA } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "../../constants/Constants";
import SignatureCanvas from "react-signature-canvas";
import SignatureModal from "pages/microservices/project/Proposals/SignatureModel";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import Popup from "components/molecules/Popup";
import CommonServices from "services/CommonService";

interface ValidationRules {
  [key: string]: Function[];
}
interface FormBuilderProps {
  entryId?: number | string | null;
  actionType?: string;
  getDataAPI?: any;
  SaveDataAPI?: any;
  getFormUrl?: any;
  formName: string;
  title?: string;
  formType?: string;
  redirect: string;
  validationRules: ValidationRules;
  loadingforapi?: boolean;
  microserviceName?: string;
  handleDateChange?: any;
  handleAddSignature?: any;
  mode?: any;
  customFunction?: any
  ignoredKeys?: any;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  entryId,
  actionType,
  getDataAPI,
  SaveDataAPI,
  getFormUrl = GET_FORM_DATA,
  formName,
  title,
  formType,
  redirect,
  validationRules,
  microserviceName = "m-identitymanager",
  loadingforapi = false,
  mode,
  customFunction,
  ignoredKeys = []
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [formInputs, setFormInputs] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [warning, setWarning] = useState({
    popup: false,
    popupdata: []
  });
  const [showSignatureModal, setSignatureModal] = useState(false);
  const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
  const [signatureData, setSignatureData] = useState<any>(null);

  useEffect(() => {
    fetchData(formName);
  }, [formName]);

  const fetchData = async (formName: string) => {
    try {
      const postData = {
        method: "POST",
        form: formName,
      };
      const response = await ApiCall.service(
        getFormUrl,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );
      setFormInputs(response.data);
      Object.values(response.data).forEach((value: any) => {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [value.name]: value.value,
        }));
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryId) {
      const editId = { id: entryId };
      const fetchDataForEditing = async (editId: { id: string | number }) => {
        try {
          const response = await ApiCall.service(
            getDataAPI,
            "POST",
            editId,
            loadingforapi,
            microserviceName
          );
          if (response.status === 200) {
            const data = response.data;
            let formData: any = [];
            //updating input fields
            formInputs.map((record: any) => {
              return Object.keys(record).map((key: string) => {
                if (key === "show") {
                  customFunction(formInputs, { dependencyValue: data[record.alias] });
                }
              });
            })
            let formElements = formInputs;

            Object.values(formElements).forEach((value: any) => {
              if (actionType === "clone") {
                if (value.type !== "multi-select") {
                  formData[value.name] = data[value.alias];
                }
              } else {
                formData[value.name] =
                  "phone_number" === value.alias
                    ? "+" + data[value.alias]
                    : data[value.alias];
              }
            });
            setFormData((prev: any) => ({ ...prev, ...formData }));
            if (response?.data?.signature) {
              setSignatureData(response.data.signature);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchDataForEditing(editId);
    }
  }, [entryId, formInputs]);

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    if (entryId && formData[name as keyof FormBuilderProps] === value) {
      return true;
    }

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name as keyof FormBuilderProps],
      }));
    } else {
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const fileChangeHandler = async (event: any, field: any) => {
    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "employee");
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [field.name]: JSON.stringify(fileData),
    }));
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    const errorMessage = validation(name, value, true);
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const handleDateChange = (date: any, fieldName: string) => {
    if (date != null) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      setFormData((prevData: any) => ({
        ...prevData,
        [fieldName]: formattedDate,
      }));
    }
  };

  const handleAddSignature = () => {
    setSignatureModal(true);
  };

  const handleModalClose = () => {
    setSignatureModal(false);
  };

  const handleClearSignature = () => {
    signatureData ? setSignatureData(null) : signatureRef.current?.clear();
  };

  const handleSaveSignature = () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL(); //stores image url into a variable
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        signature: signatureImage,
      }));
      setSignatureData(signatureImage);
      handleModalClose();
      setSignatureErrorMessage("");
    } else {
      setSignatureErrorMessage(t("Please add signature before saving."));
    }
  };

  const handleSelectChange = (selectedOption: any, name: string, dependency: boolean = false) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: selectedOption,
    }));
    if (dependency) {
      formInputs.map((record: any) => {
        return Object.keys(record).map((key: string) => {
          if (key === "show") {
            customFunction(formInputs, selectedOption);
          }
        });
      })
    }
    const errorMessage = validation(name, selectedOption, true);
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const handlePhoneNumberChange = (e: string) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, mobNumber: e }));

    const errorMessage = validation("mobNumber", e, true);
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      mobNumber: errorMessage,
    }));
  };

  const handleLocationChange = (event: any, value: any) => {
    let data = { lat: event.detail.latLng.lat, lng: event.detail.latLng.lng };
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [value.name]: data,
    }));
  };


  //handle editor data
  const setValues = (value: any, data: any) => {
    if (value.name === "template") {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [value.name]: data,
      }));
      const errorMessage = validation(value.name, data, true);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [value.name]: errorMessage,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {    
    setLoading(true);
    e && e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { id: entryId, ...formData };
    if (warning?.popup && warning?.popupdata?.length) {
      data['ignore'] = warning?.popupdata;
    }
    if (validation(name, value)) {
      let response = await ApiCall.service(
        SaveDataAPI,
        "POST",
        data,
        loadingforapi,
        microserviceName
      );
      if (response.status === 200) {
        navigate(redirect);
        CustomNotify({ type: "success", message: response.message });
      } else if (response.status === 400) {
        let errors: any = [];
        let warningdata: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
          if (ignoredKeys?.includes(key)) {
            warningdata?.push(key);
          }
        }
        setErrors({ ...errors });
        // Check if there are other errors besides the ignored ones
        const hasNonIgnoredErrors = Object.keys(errors).some(key => !ignoredKeys?.includes(key));
        if (!hasNonIgnoredErrors && warningdata.length > 0) {
          setWarning((prev) => ({ ...prev, popup: true, popupdata: warningdata}))
        } else {
          setWarning((prev) => ({ ...prev, popup: false, popupdata: []}))
        }
        if (response.message) {
          CustomNotify({ type: "warning", message: response?.message });
        }
      }
    }

    setLoading(false);
  };

  return (
    <>
      <TitleFieldMolecule title={title} />
      <div className="form-height">
        <div className="form-border p-5 mb-4">
          <div className="row">
            {Object.entries(formInputs).map(([key, value]) => {
              return (
                <React.Fragment key={key}>
                  {renderFormElements(
                    { type: value.type, name: key, value },
                    value.type === "mobile-number"
                      ? handlePhoneNumberChange
                      : value.type === "multi-select"
                        ? (
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLSelectElement
                          >
                        ) => handleSelectChange(e, value.name, value?.dependency)
                        : value.type === "file"
                          ? (e: any) => fileChangeHandler(e, value)
                          : value.type === "editor"
                            ? (event: any, editor: any) =>
                              setValues(value, editor.getData())
                            : value.type == "location"
                              ? (
                                e: React.ChangeEvent<
                                  HTMLInputElement | HTMLSelectElement
                                >
                              ) => handleLocationChange(e, value)
                              : changeHandler,
                    formData,
                    errors,
                    formName,
                    formType,
                    handleDateChange,
                    handleAddSignature,
                    mode,
                    actionType
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        {/* {/ DESKTOP /} */}
        <div className="d-none d-md-block d-lg-block">
          <div className="row mb-4">
            <div className="col-md-4 align-self-center">
              <Link
                to={redirect}
                className="text-uppercase back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-md-8 text-end">
              {!mode ? (
                !loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="btn form-button px-3 shadow-none text-uppercase"
                    handleClick={handleSubmit}
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#00a5ce"
                    className="float-end"
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* {/ PWA and IPAD /} */}
        <div className="row mt-3 d-block d-md-none d-lg-none">
          <div className="col-12 text-end mb-3">
            {!loading ? (
              <Button
                title={t("Save")}
                type="submit"
                className="btn form-button  shadow-none text-uppercase w-100"
                handleClick={handleSubmit}
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#00a5ce"
                className="float-end"
              />
            )}
          </div>
          <div className="col-12 mb-3">
            <Link
              to={redirect}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        {/* {/ Signature /} */}
        <div>
          {showSignatureModal && (
            <>
              <SignatureModal
                handleClose={handleModalClose}
                handleClearSignature={handleClearSignature}
                handleSaveSignature={handleSaveSignature}
                signatureRef={signatureRef}
                signatureErrorMessage={signatureErrorMessage}
                signatureData={signatureData}
              />
            </>
          )}
        </div>
        {/* {/ Warning popup /} */}
        <div>
          {warning?.popup && (
            <>
              <Popup
                title={t("Warning")}
                body={
                  <>
                    <span>
                      {warning.popupdata?.map((item: any, index: number) => (
                        <b key={index}>{CommonServices.capitalizeLabel(item ?? "")}{index < warning.popupdata.length - 1 ? ", " : ""}</b>
                      ))}
                    </span>
                    <span>{t(" field value(s) already exist, do you still want to submit") + "?"}</span>
                  </>
                }
                yestext={t("Yes")}
                notext={t("No")}
                submit={(e) => handleSubmit(e)}
                cancel={() => setWarning((prev) => ({ ...prev, popup: false, popupdata: []}))}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
