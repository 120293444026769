import LabelField from "components/atoms/LabelField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import React from "react";

interface WorkTypeName {
  whAj: number | null;
  whClient: number | null;
  wtAj: number | null;
  wtClient: number | null;
  whtAj: number | null;
  whtClient: number | null;
}

interface propsObj {
  data:WorkTypeName,
  id: number;
  label:any,
  changeHandler: (value: any, index: number) => void;
}
const ChildComponent: React.FC<propsObj> = ({ data, id, label, changeHandler }) => {    
  return (
    <>
      {Object.entries(data).map(([key, value]) => (
        <tr key={key} className="align-middle border">
          <td className="ps-4">
            <LabelField title={label[id]} className="col-md-12 align-self-center" />
          </td>
          <td className="function-level">
            <LabelWithInputField
              handleChange={(event) => changeHandler(event, id)}
              name={key}
              min={0}
              step={0.2}
              type="number"
              value={value}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default ChildComponent;
