import React, { useState } from "react";
import { useWeekAndDay } from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import { getWeekDays } from "../utils/utils";

const WeekCalendar = () => {
  const { initialWeekAndDayPropsState } = useWeekAndDay();
  const currentDate = new Date(); // Get current date

  const renderWeekDays = () => {
    const days = getWeekDays(initialWeekAndDayPropsState.currentDate);
    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return weekdays.map((weekday, index) => {
      const isCurrentDay = days[index].getDate() === currentDate.getDate(); // Check if it's the current day
      return (
        <td
          key={index}
          style={{ backgroundColor: isCurrentDay ? "#00a5ce" : "" }}
        >
          <div className="text-center font-weight-bold">{weekday}</div>
          <div className="text-center">
            {days[index].toLocaleDateString("en-US", {
              day: "numeric",
              month: "numeric",
            })}
          </div>
        </td>
      );
    });
  };

  return (
    <tr>
      <td className="border"></td>
      <td className="border"></td>
      {renderWeekDays()}
      <td className="border"></td>
    </tr>
  );
};

export default WeekCalendar;
