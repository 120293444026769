import { FormMode } from "components/common/CommonEnums";

export const initialState = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "general",
      title: "General",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "contactsPersons",
      title: "Contacts Persons",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "projectParameters",
      title: "Project Parameters",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "shifts",
      title: "Shifts",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "sequence",
      title: "Sequence",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "billing",
      title: "Billing",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  general: {
    data: {
      id: "",
      businessUnitNumber: "",
      projectName: "",
      projectType: "",
      projectCode: "",
      company: "",
      locations: "",
      startDate: undefined,
      endDate: undefined,
      createLocationIsVisible: false,
      info: "",
      isActive: true,
      createLocation: {
        company: null,
        location: "",
        phone: "",
        street: "",
        number: "",
        box: "",
        zipcode: "",
        city: "",
        country: null,
        info: "",
      },
    },
    errors: {
      businessUnitNumber: "",
      projectName: "",
      projectType: "",
      projectCode: "",
      company: "",
      locations: "",
      startDate: "",
      endDate: "",
      info: "",
      createLocation: {
        company: null,
        location: "",
        phone: "",
        street: "",
        number: "",
        box: "",
        zipcode: "",
        city: "",
        country: null,
        info: "",
      },
      createLocationHasError: "",
    },
  },
  contactsPersons: {
    data: {
      selectedContactPersons: [],
      contactSubformIsVisible: false,
      contactsPersons: [],
      timeSheet: [],
      billing: [],
      leave: [],
      sickness: [],
      holiday: [],
      safety: [],
    },
    errors: {
      selectedContactPersons: "",
      contactsPersons: [],
      timeSheet: "",
      billing: "",
      leave: "",
      sickness: "",
      holiday: "",
      safety: ""
    },
  },

  responsiblePerson: {
    data: {
      timeSheet: [],
      billing: [],
      leave: [],
      sickness: [],
      holiday: [],
      safety: [],
    },
    errors: {
      timeSheet: "",
      billing: "",
      leave: "",
      sickness: "",
      holiday: "",
      safety: ""
    },
  },
  projectParameters: {
    data: {
      templates: "",
      fromdate: "",
      sections: [],
    },
    errors: {
      templates: "",
      fromdate: "",
    },
  },
  shifts: {
    data: {
      shifts: [],
      shiftTable: [],
    },
    errors: {
      name: "",
      shifts: []
    },
  },
  sequence: {
    data: {
      sequenceName: "",
      weeklyShifts: {},
      sequence: [],
      edit: ''
    },
    errors: {},
  },
  billing: {
    data: {
      vatRate: "",
      billingStatus: "",
      paymentTerm: "",
      email: [],
      proformaInvoice: false,
      splitInvoicePerPerson: false,
      referenceRequired: false,
      poNumber: "",
      reference: "",
    },
    errors: {
      vatRate: "",
      billingStatus: "",
      paymentTerm: "",
    },
  },

  currentTab: "general",
  projectOverview: false,
  signatureImage: "",

  options: {
    businessUnit: [],
    projectTypes: [],
    company: [],
    locations: [],
    companyUsers: [],
    gender: [],
    language: [],
    roles: [],
    functions: [],
    templates: [],
    shifts: [],
    vatRate: [],
    billingRate: [],
    paymentTerm: [],
    countries: [],
  },
};
