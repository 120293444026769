import {
  addDynamicForm,
  competenceRange,
  competenceSelected,
  competenceType,
  getUpdatedForm,
  manageIndex,
  removeDynamicForm,
  updateFieldDependency,
  updateSalaryBenefit,
  updateSalaryBenefitFields,
  updateTabErrors,
} from "../components/common/ReducerService";
import {
  ADD_DYNAMIC_FORM,
  COMPETENCE_RANGE,
  COMPETENCE_SELECTED,
  MANAGE_INDEX,
  REMOVE_DYNAMIC_FORM,
  SET_FORM_ELEMENTS,
  SWITCH_TAB,
  UPDATE_DEPENDENT_TAB,
  UPDATE_FIELD_DATA,
  UPDATE_SALARY_BENEFIT,
  UPDATE_SALARY_BENEFIT_FIELD_DATA,
  UPDATE_TAB_FIELD,
} from "./Constants";

type FormReducerProps = {
  state: any;
  action: any;
};

const FormReducer = (
  state: FormReducerProps["state"],
  action: FormReducerProps["action"]
) => {
  switch (action.type) {
    case SWITCH_TAB:
    case SET_FORM_ELEMENTS:
      return { ...state, [action.field]: action.value };
    case UPDATE_TAB_FIELD:
      return updateTabErrors(state, action);
    case UPDATE_FIELD_DATA:
      return { ...state, form: getUpdatedForm(state, action) };
    case ADD_DYNAMIC_FORM:
      return addDynamicForm(state, action);
    case REMOVE_DYNAMIC_FORM:
      return removeDynamicForm(state, action);
    case UPDATE_DEPENDENT_TAB:
      return updateFieldDependency(state, action);
    case MANAGE_INDEX:
      return manageIndex(state, action);
    case COMPETENCE_SELECTED:
      return competenceSelected(state, action);
    case COMPETENCE_RANGE:
      return competenceRange(state, action);
    case UPDATE_SALARY_BENEFIT:
      return updateSalaryBenefit(state, action);
    case UPDATE_SALARY_BENEFIT_FIELD_DATA:
      return updateSalaryBenefitFields(state, action);
    default:
      return state;
  }
};

export default FormReducer;
