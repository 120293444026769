import React, { ReactNode } from 'react';

// Define the prop types for the Button component
interface ButtonProps {
  onClick: () => void;
  children: ReactNode;
  className?: string;
  title?: string
  // You can add other props specific to your button component here
}

// Create the Button component using React.FC<ButtonProps>
const ButtonWithChildren: React.FC<ButtonProps> = ({ onClick, children, className="", title="" }) => {
  return (
    <button className={className} onClick={onClick} title={title}>
      {children}
    </button>
  );
};

export default ButtonWithChildren;