export const addressFunction = (payload: any) => {
  let dependentFields = [
    "d_street",
    "d_number",
    "d_box",
    "d_zipCode",
    "d_city",
    "d_country",
  ];
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    if (dependentFields.includes(item.name)) {
      const sourceField = getSourceField(item.name.substring(2), state);
      item.value = fieldValue === true ? sourceField.value : "";
      item.disabled = fieldValue;
    }
    return item;
  });
  return newState;
};

export const addressFieldFunction = (payload: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  const isSameAddress = state.find(
    (data: any) => data.name === "sameAsResidenceAddress"
  )?.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    if (isSameAddress) {
      if (`d_${fieldData.field}` == item.name) {
        item.value = fieldValue;
      }
    }
    return item;
  });
  return newState;
};

export const getSourceField = (name: string, state: any) => {
  return state.find((item: any) => item.name === name);
};

export const experienceFunction = (payload: any, dependentFields: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let fieldIndex = 0;
  state[0]?.form.forEach((group: any, index: number) => {
    group.forEach((item: any) => {
      if (item.name === fieldData.field) {
        const name = (item.name).split('-');
        fieldIndex = name[1];
        item.value = fieldValue;
        item.error = fieldData.error;
      }
      const name = (item.name).split('-');
      const fieldName = name[0];
      console.log("index condition check", fieldIndex, item);
      const inedxCheck = index !== 0 ? (item.name).includes(fieldIndex) : true;
      if ((dependentFields).includes(fieldName) && inedxCheck) {
        item.display = fieldValue === "no" ? false : true;
      }
      return item;
    });
  });
  return state;
};

export const documentsFunction = (payload: any, dependentFields: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    if (dependentFields.includes(item.name)) {
      item.display = fieldValue === true ? false : true;
    }
    return item;
  });
  return newState;
};
