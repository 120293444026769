import React, { useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { ShiftHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ARCHIVE_SHIFT, ARCHIVE_TIMETABLE } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_CREATE_SHIFT,
  PATH_EDIT_SHIFT,
  PATH_MASTER_DATA,
  PATH_TIMETABLE_CREATE,
  PATH_TIMETABLE_EDIT,
} from "constants/Paths";
import { Shift } from "./Manage";
import Search from "components/atoms/Search";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";

interface ManageShiftsPageProps {
  shiftObject: Shift[];
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  searchData: any;
  isTimeTable?: any;
  handleSubmitAction: (clear: boolean) => void;
  currentPage: any;
  setCurrentPage: any;
  totalPages: any;
}

const ManageShiftsPage: React.FC<ManageShiftsPageProps> = ({
  shiftObject,
  searchValue,
  deleteFilter,
  searchData,
  handleSubmitAction,
  isTimeTable,
  currentPage,
  setCurrentPage,
  totalPages
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      let apiToDelete = isTimeTable ? ARCHIVE_TIMETABLE : ARCHIVE_SHIFT;
      const url = `${apiToDelete}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);

      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response?.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (Shift: Shift | any) => {
    setShowModal(true);
    setDeleteId(Shift.id);
  };

  const handleEdit = (Shift: Shift | any) => {
    if (Shift.id) {
      let navigatePath = isTimeTable ? PATH_TIMETABLE_EDIT : PATH_EDIT_SHIFT;
      navigate(`${navigatePath + "/" + Shift.id}`);
    }
  };
  const permissionType = isTimeTable ? "Timetable" : "Shifts";
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: permissionType,
        read: true,
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <TitleFieldMolecule
              title={isTimeTable ? t("Manage timetable") : t("Manage shifts")}
            />
            <div className="row pb-4 search-bar">
              <div className="col-12">
                <div className="row">
                  <div className="col-xxl-9 col-md-12 mb-3 mb-lg-0">
                    <div className="row">
                      <div className="col-3">
                        <div className="">
                          <label className="form-label field-label">
                            {t("Shift name")}
                          </label>
                        </div>
                        <Search
                          className="form-control"
                          handleChange={handleSearchChange}
                          placeholder={t("Shift")}
                          name="shift_name"
                          value={searchData.shift_name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col text-end mt-auto">
                    <Button
                      title={IconData.ResetIcon}
                      type="submit"
                      handleClick={(e) => handleSubmit(e, true)}
                      className="btn delete-btn  shadow-none text-uppercase me-3 "
                    />
                    <Button
                      title={t("Search")}
                      type="submit"
                      handleClick={handleSubmit}
                      className="btn form-button shadow-none text-uppercase px-3 search-btns position-relative text-center"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                <AccessControl
                  requiredPermissions={[{
                    permission: permissionType,
                    create: true,
                  }]}
                  override={userData.isSuperAdmin}
                >
                  <Link
                    to={isTimeTable ? PATH_TIMETABLE_CREATE : PATH_CREATE_SHIFT}
                    className="table-button"
                  >
                    + {isTimeTable ? t("Create timetable") : t("Create shift")}
                  </Link>
                </AccessControl>
              </div>
              <div className="table-responsive">
                <DynamicTableStructure
                  isAction
                  headers={ShiftHeader}
                  data={shiftObject}
                  handleEdit={handleEdit}
                  handleArchive={handleDelete}
                  name={"manage shifts"}
                  permission={permissionType}
                />
              </div>
              <div className="pagination justify-content-center align-items-center my-3 ">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          <div className="my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};
export default ManageShiftsPage;
