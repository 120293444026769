import { useFormContext } from "pages/microservices/tempAgancy/context/Context";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";
// import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { ChangeEvent, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import CloseFile from "static/images/CloseFile";
import Button from "components/atoms/Button";
import { COMPANY_DETAILS, REGISTER_COMPANY } from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  scrollToTop,
  validateForm,
  validateFormField,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import { ValidationRules } from "utils/TypeAnnotations";
import { LocationTabValidationRules } from "../validationRules/TempAgencyValidationRules";

const Location = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const { companyId } = useParams<{ companyId: string }>();

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // const { name, value, type, checked } = event.target;
    // const processedValue = type === "number" ? String(value) : value;
    // dispatch({
    //   type: "UPDATE_COMPANYLOCATION_FIELD",
    //   field: name,
    //   value: value,
    //   index,
    // });
    // validation(name, processedValue, index, true);
  };
  //   const validation = (
  //     name: string,
  //     value: string | boolean | Date | object[] | undefined,
  //     index: number,
  //     isSingleFieldValidation: boolean = false
  //   ) => {
  //     const validationRules: ValidationRules = {
  //       locationName: [validateRequired],
  //       locationStreet: [validateRequired],
  //       locationNumber: [validateRequired],
  //       //  locationBox: string;
  //       locationZipCode: [validateRequired],
  //       locationCountry: [validateSelectField],
  //       locationCity: [validateRequired],
  //       phoneNumber: [validateRequired],
  //     };
  //     const validationErrors = validateForm(
  //       { ...state.companyLocation[index], [name]: value },
  //       validationRules,
  //       isSingleFieldValidation ? name : undefined
  //     );

  //     const isFieldValid = Object.keys(validationErrors).length === 0;

  //     if (isFieldValid) {
  //       state.dynamicLocationErrors[index][name] = "";
  //     } else {
  //       state.dynamicLocationErrors[index][name] = validationErrors[name];
  //     }

  //     if (Object.keys(validationErrors).length > 0) {
  //       return false;
  //     }

  //     return true;
  //   };

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    // dispatch({
    //   type: "UPDATE_COMPANYLOCATION_FIELD",
    //   field: name,
    //   index: index,
    //   value: selectedOption,
    // });
    // validation(name, selectedOption, index, true);
  };

  //   const validStatus = (validation: any) => {
  //     setValidationStatus({
  //       isValid: true,
  //       // isValid: validation.isValid,
  //       type: validation.type,
  //     });
  //   };
  const handleRemoveLocation = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_LOCATION", indexToRemove });
  };

  const handleAddLocation = () => {
    dispatch({ type: "ADD_LOCATION" });
  };

  const handleLocationFieldChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, type, checked } = event.target;
    dispatch({
      type: "UPDATE_LOCATION_FIELD",
      field: name,
      index: index,
      value: value,
    });

    const currentFormData = { ...state.location.data[index], [name]: value };

    // Validate the changed field
    const validatedFieldError = validateFormField(
      currentFormData,
      LocationTabValidationRules,
      name,
      value
    );
    console.log(validatedFieldError);
    dispatch({
      type: "SET_FIELD_ERROR_LOCATION_TAB",
      field: name,
      error: validatedFieldError,
      index: index,
    });
  };
  const handleLlocationSelectChange = (
    value: number | string | undefined | null,
    name: string,
    index: number
  ) => {
  
    // const { name, value, type, checked } = event.target;
    dispatch({
      type: "UPDATE_LOCATION_FIELD",
      field: name,
      index: index,
      value: value,
    });
    const currentFormData = { ...state.location.data[index], [name]: value };

    // Validate the changed field
    const validatedFieldError = validateFormField(
      currentFormData,
      LocationTabValidationRules,
      name,
      value
    );
    dispatch({
      type: "SET_FIELD_ERROR_LOCATION_TAB",
      field: name,
      error: validatedFieldError,
      index: index,
    });
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row form-border p-5">
          {
            <div className=" mb-3">
              {state.location.data.map((companyLocation, index) => (
                <div
                  className="field-set  px-3 pt-4 pb-2 mb-4 rounded-2"
                  key={index}
                >
                  {index > 0 && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-block d-md-block d-lg-none">
                          <Button
                            title="Remove"
                            handleClick={() => handleRemoveLocation(index)}
                            className="link-button float-end text-uppercase rounded-0 shadow-none my-2"
                          />
                        </div>
                        <div className="d-none d-md-none d-lg-block">
                          <span
                            title="Delete"
                            onClick={() => handleRemoveLocation(index)}
                            className="table-action-icons cursor-pointer float-end text-uppercase rounded-0 shadow-none"
                          >
                            <CloseFile />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationName"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationName ?? ""}
                        id={`locationName-${index}`}
                        label="Location name"
                        placeholder="Location name"
                        error={state.location.errors[index]?.locationName || ""}
                        className="form-control shadow-none rounded-0"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationEmail"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationEmail ?? ""}
                        id={`locationEmail-${index}`}
                        label="Email"
                        placeholder="Email"
                        error={
                          state.location.errors[index]?.locationEmail || ""
                        }
                        className="form-control shadow-none rounded-0"
                      />
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationStreet"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationStreet ?? ""}
                        id={`street-${index}`}
                        label="Street"
                        placeholder="Street"
                        error={
                          state.location.errors[index]?.locationStreet || ""
                        }
                        className="form-control shadow-none rounded-0"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationNumber"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationNumber ?? ""}
                        id={`number-${index}`}
                        label="Number"
                        placeholder="Number"
                        error={
                          state.location.errors[index]?.locationNumber || ""
                        }
                        className="form-control shadow-none rounded-0"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={false}
                        name="locationBox"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationBox}
                        id={`box-${index}`}
                        label="Box"
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationZipCode"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationZipCode}
                        id={`zipCode-${index}`}
                        label="Zip code"
                        type="text"
                        error={
                          state.location.errors[index]?.locationZipCode || ""
                        }
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationCity"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationCity}
                        id={`city-${index}`}
                        label="City"
                        type="text"
                        error={state.location.errors[index]?.locationCity || ""}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Country"
                        name="locationCountry"
                        isMandatory={true}
                        search={true}
                        options={state.options.countries}
                        placeHolder="Select"
                        value={companyLocation.locationCountry}
                        onChange={(country) =>
                          handleLlocationSelectChange(
                            country,
                            "locationCountry",
                            index
                          )
                        }
                        isMulti={false}
                        className="select-field"
                        error={state.location.errors[index]?.locationCountry || ""}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="row my-4">
                <div className="col-md-12">
                  <Button
                    title="+ Add another"
                    handleClick={handleAddLocation}
                    className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Location;
