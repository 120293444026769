import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Option,
  validateEmail,
  validateForm,
  validateMultiSelectField,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import { ApiCall } from "services/ApiServices";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

import { t } from "pages/microservices/masterData/translation/Translation";
import CloseFile from "static/images/CloseFile";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelField from "components/atoms/LabelField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";

import {
  CREATE_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
  FETCH_LOCATION_BY_COMP_ID,
  GET_COMPANY,
} from "routes/ApiEndpoints";
import {
  CONTACT_PERSON_CREATED_SECCESFULL,
  M_COMPANY_CREATION,
} from "constants/Constants";

import { OptionProps } from "utils/TypeAnnotations";
import { MANAGE_CONTACT_PERSON } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { E164Number } from "libphonenumber-js";

interface InputItem {
  firstName: any;
  lastName: any;
  company: Option | null;
  gender: Option | null;
  email: any;
  language: Option | null;
  location: [];
  role: [];
  linkedIn?: any;
  functionData: any;
  info: any;
  phoneNumber: any;
  mobileNumber?: E164Number | string;
}

interface ValidationRules {
  [key: string]: Function[];
}

function CreateContact() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const editId = urlSearchParams.get("edit");
  const [companyOption, setCompanyOption] = useState<OptionProps[]>([]);
  const [genderOption, setgenderOption] = useState<OptionProps[]>([]);
  const [languageOption, setLanguageOption] = useState<OptionProps[]>([]);
  const [locationOption, setLocationOption] = useState<OptionProps[]>([]);
  const [roleOption, setRoleOption] = useState<OptionProps[]>([]);
  const [functionOption, setFunctionOption] = useState<OptionProps[]>([]);
  const [isEdit, setIsEdit] = useState(editId ? true : false);
  const [formData, setFormData] = useState<InputItem[]>([
    {
      firstName: "",
      lastName: "",
      company: null,
      gender: null,
      email: "",
      language: null,
      location: [],
      role: [],
      linkedIn: "",
      functionData: "",
      info: "",
      phoneNumber: "",
      mobileNumber: "" as E164Number,
    },
  ]);

  const [errors, setErrors] = useState<any>([]);
  const navigate = useNavigate();

  const changeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const list = formData.map((item) => item);
    list[index][name as keyof InputItem] = value;
    validateInput(name, value, index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          GET_COMPANY,
          "GET",
          M_COMPANY_CREATION,
          false
        );

        setCompanyOption(response.company);
        setgenderOption(response.gender);
        setLanguageOption(response.language);
        setRoleOption(response.role);
        // setFunctionOption(response.function);
        setLocationOption(response.location);
      } catch (error) {
        // console.error(error);
      }
    };
    fetchData();
  }, []);
  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    const handleCommonLogic = (value: any) => {
      setFormData((prevData) => {
        const newData = [...prevData];
        const updatedObject = { ...newData[index], [name]: value };
        newData[index] = updatedObject;
        validateInput(name, value, index);
        return newData;
      });
    };
    switch (name) {
      case "company":
        const companyId = selectedOption ? selectedOption.value : null;
        const getContactPersonCompany = async (companyId: any) => {
          try {
            const compId = { id: companyId };
            const companyIDResponse = await ApiCall.service(
              FETCH_LOCATION_BY_COMP_ID,
              "POST",
              compId,
              false,
              M_COMPANY_CREATION
            );
            setLocationOption(companyIDResponse.data);
          } catch (error) {
            console.error(error);
          }
        };
        handleCommonLogic(companyId);
        getContactPersonCompany(companyId);
        break;
      case "gender":
        const genderId = selectedOption ? selectedOption.value : null;
        handleCommonLogic(genderId);
        break;
      case "language":
        const languageId = selectedOption ? selectedOption.value : null;
        handleCommonLogic(languageId);
        break;
      case "location":
        const locationId =
          selectedOption !== null
            ? selectedOption.map((value: any) => value.value)
            : [];
        handleCommonLogic(locationId);
        break;
      case "role":
        const roleId =
          selectedOption !== null
            ? selectedOption.map((value: any) => value.value)
            : [];
        handleCommonLogic(roleId);
        break;
      // case "functionData":
      //   const functionId = selectedOption ? selectedOption.value : null;
      //   handleCommonLogic(functionId);
      //   break;
      default:
        break;
    }
  };
  const handlePhoneNumberChange = (
    mobileNumber: string | E164Number,
    index: number
  ) => {
    const updatedFormData = [...formData]; // Create a copy of the array
    updatedFormData[index] = {
      ...updatedFormData[index],
      mobileNumber: mobileNumber,
    };
    setFormData(updatedFormData);
    validateInput("mobileNumber", mobileNumber, index);
  };
  useEffect(() => {
    if (editId) {
      getContactPerson();
    }
  }, [editId]);

  const getContactPerson = async () => {
    try {
      const url = `${EDIT_CONTACT_PERSON}/${editId}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_COMPANY_CREATION,
        false
      );
      if (response?.status === 200) {
        const { data } = response;
        setFormData([
          {
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            email: response.data.email,
            phoneNumber: response.data.phoneNumber,
            mobileNumber: "+" + response.data.mobile_number,
            functionData: response.data.functionData,
            info: response.data.info,
            language: response.data.language,
            company: response.data.company,
            location: response.data.location,
            linkedIn: response.data.linkedIn,
            gender: response.data.gender,
            role: response.data.role,
          },
        ]); // Wrap the object in an array if formData is an array
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  const validateInput = (
    name: string,
    value: string | boolean | Date | object[] | undefined | Option | null,
    index: number
  ): boolean => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired],
      lastName: [validateRequired],
      email: [validateRequired, validateEmail],
      company: [validateSelectField],
      gender: [validateSelectField],
      //language: [validateSelectField],
      mobileNumber: [validatePhoneNumber],
      functionData: [validateSelectField],
      info: [],
    };

    const formDataAtIndex = { ...formData[index], [name]: value };
    const validationErrors = validateForm(formDataAtIndex, validationRules);
    setErrors((prevErrors: any) => {
      const newErrors = [...prevErrors];
      newErrors[index] = {
        ...newErrors[index],
        [name]: validationErrors[name],
      };
      return newErrors;
    });
    return !validationErrors[name];
  };

  const handleRemove = (index: number) => {
    const list = [...formData];
    const errorList = [...errors];
    list.splice(index, 1);
    errorList.splice(index, 1);
    setFormData(list);
    setErrors(errorList);
  };

  const handleAddClick = () => {
    setFormData([
      ...formData,
      {
        firstName: "",
        lastName: "",
        company: null,
        gender: null,
        email: "",
        language: null,
        location: [],
        role: [],
        linkedIn: "",
        functionData: null,
        info: "",
        phoneNumber: "",
        mobileNumber: "",
      },
    ]);
    setErrors([
      ...errors,
      {
        firstName: "",
        lastName: "",
        company: null,
        gender: null,
        email: "",
        language: null,
        location: [],
        role: [],
        linkedIn: "",
        functionData: null,
        info: "",
        phoneNumber: "",
        mobileNumber: "",
      },
    ]);
  };
  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;
    formData.forEach((contact, index) => {
      if (contact.email.trim() !== "") {
        if (emailSet.has(contact.email.trim())) {
          errors[index].email = "Duplicate email";
          hasDuplicate = true;
        } else {
          emailSet.add(contact.email);
        }
      }

      if (
        contact.mobileNumber != undefined &&
        contact.mobileNumber.trim() !== ""
      ) {
        if (phoneSet.has(contact.mobileNumber)) {
          errors[index].mobileNumber = "Duplicate phone number";
          hasDuplicate = true;
        } else {
          phoneSet.add(contact.mobileNumber);
        }
      }
    });

    return hasDuplicate;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const duplicates = checkForDuplicates();
    const { name, value } = e.target as HTMLInputElement;
    const isValid = formData.map((item: any, index: number) => {
      const data = Object.entries(item).map(([key, value]: [string, any]) => {
        return validateInput(key, value, index);
      });
      return !data.includes(false);
    });

    if (!isValid.includes(false) && !duplicates) {
      const formDataToSave = formData.map((item) => ({
        firstName: item.firstName,
        lastName: item.lastName,
        company: item.company,
        location: item.location,
        language: item.language,
        linkedIn: item.linkedIn,
        role: item.role,
        email: item.email,
        functionData: item.functionData,
        info: item.info,
        phoneNumber: item.phoneNumber,
        mobileNumber: item.mobileNumber,
        gender: item.gender,
        id: editId,
      }));
      const url = editId
        ? `${CREATE_CONTACT_PERSON}/${editId}`
        : CREATE_CONTACT_PERSON;
      const response = await ApiCall.service(
        url,
        "POST",
        formDataToSave,
        true,
        M_COMPANY_CREATION
      );

      if (response?.status === 200) {
        CustomNotify({
          type: "success",
          message: response.msg,
        });
        navigate(MANAGE_CONTACT_PERSON);
      } else if (response?.status === 400) {
        for (let item of Object.keys(response?.errors)) {
          let keys = item.split(".");
          let index = parseInt(keys[0]);
          let particularkey = keys[1];
          errors[index][particularkey] = response?.errors[item][0];
        }
        setErrors(errors);
      } else {
        //   console.error(t("error"));
      }
    }
  };

  const handleEdit = () => {
    setIsEdit(false);
  };

  const location = useLocation();
  const path = location.pathname;

  const permissionType =
    path === "/create/contact-person" ? "create" : "update";
  const permissionObject: any = {
    permission: "Contact person",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">
              {path === "/create/contact-person"
                ? t("Create contact person")
                : t("Edit  contact person")}
            </h1>
          </div>
          <div>
            {editId !== null ? (
              <Button
                title={t("Edit")}
                handleClick={() => handleEdit()}
                className="btn form-button float-end text-uppercase shadow-none"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <form>
          {formData.map((contact, i) => (
            <div className="create-form-height-company" key={i}>
              <div className="row align-items-end mb-4"></div>
              <div className="form-border p-5 pb-0">
                <div
                  className="field-set border px-3 pt-4 pb-2 mb-4 rounded-0"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-block d-md-block d-lg-none">
                        {formData.length !== 0 && i !== 0 && (
                          <Button
                            title={t("Remove")}
                            handleClick={() => handleRemove(i)}
                            className="link-button float-end text-uppercase rounded-0 shadow-none my-2"
                            disabled={isEdit}
                          />
                        )}
                      </div>
                      <div className="d-none d-md-none d-lg-block">
                        {i !== 0 && (
                          <span
                            title={t("Delete")}
                            onClick={() => handleRemove(i)}
                            className="table-action-icons cursor-pointer float-end text-uppercase rounded-0 shadow-none"
                          >
                            <CloseFile />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="firstName"
                        handleChange={(e) => changeHandler(e, i)}
                        value={contact.firstName ?? " "}
                        id={`firstName-${i}`}
                        label="First name"
                        placeholder="First name"
                        className="form-control shadow-none rounded-0"
                        error={errors[i]?.firstName}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="lastName"
                        handleChange={(e) => changeHandler(e, i)}
                        value={contact.lastName ?? ""}
                        id={`lastName-${i}`}
                        label="Last name"
                        placeholder="Last name"
                        error={errors[i]?.lastName}
                        className="form-control shadow-none rounded-0"
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="email"
                        handleChange={(e) => changeHandler(e, i)}
                        value={contact.email ?? ""}
                        id={`email-${i}`}
                        label="Email"
                        placeholder="Email"
                        type="email"
                        error={errors[i]?.email}
                        className="form-control shadow-none rounded-0"
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Company"
                        name="company"
                        isMandatory={true}
                        search={true}
                        options={companyOption}
                        placeHolder="Select"
                        value={
                          companyOption.filter(
                            (company: any) => company.value === contact.company
                          ) ?? ""
                        }
                        onChange={(e) => handleSelectChange(e, "company", i)}
                        isMulti={false}
                        className="select-field"
                        error={errors[i]?.company?.toString() ?? ""}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelField
                        title="Mobile number"
                        isMandatory={true}
                        key="PhoneInput"
                      />
                      <div className="form-control rounded-0 shadow-none">
                        <PhoneInput
                          defaultCountry="BE"
                          international
                          placeholder="Enter phone number"
                          onChange={(mobileNumber: E164Number) =>
                            handlePhoneNumberChange(mobileNumber, i)
                          }
                          value={contact.mobileNumber}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="height-20 mb-2 text-danger">
                        {errors[i]?.mobileNumber && (
                          <span className="text-danger">
                            {errors[i]?.mobileNumber}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="phoneNumber"
                        handleChange={(e) => changeHandler(e, i)}
                        value={formData[i].phoneNumber ?? ""}
                        id={`phoneNumber-${i}`}
                        label="Phone number"
                        placeholder="Phone number"
                        type="text"
                        className="form-control shadow-none rounded-0"
                        error={errors[i]?.phoneNumber}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Gender"
                        name="gender"
                        isMandatory={true}
                        search={true}
                        placeHolder="Select"
                        options={genderOption}
                        value={
                          genderOption.filter(
                            (gender: any) => gender.value === contact.gender
                          ) ?? ""
                        }
                        onChange={(e) => handleSelectChange(e, "gender", i)}
                        isMulti={false}
                        className="select-field"
                        error={errors[i]?.gender?.toString() ?? ""}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Language"
                        name="language"
                        isMandatory={false}
                        search={true}
                        placeHolder="Select"
                        options={languageOption}
                        value={
                          languageOption.filter(
                            (language: any) =>
                              language.value === contact.language
                          ) ?? ""
                        }
                        onChange={(e) => handleSelectChange(e, "language", i)}
                        isMulti={false}
                        className="select-field"
                        error={errors[i]?.language?.toString() ?? ""}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="linkedIn"
                        handleChange={(e) =>
                          changeHandler(e, formData.length - 1)
                        }
                        value={formData[i].linkedIn ?? ""}
                        id="linkedIn"
                        label="Linkedin website url"
                        type="text"
                        error={errors[i]?.linkedIn}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        title="Roles"
                        name="role"
                        isMandatory={false}
                        search={true}
                        options={roleOption}
                        placeHolder="Select"
                        value={
                          roleOption.filter((role) =>
                            contact.role.some((loc) => loc === role.value)
                          ) ?? ""
                        }
                        onChange={(e) => handleSelectChange(e, "role", i)}
                        isMulti={true}
                        className="select-field"
                        error={errors[i]?.role?.toString() ?? ""}
                        isDisabled={isEdit}
                      />
                    </div>

                    <div className="col-sm-12 col-md-6">
                      {/* <SelectWithSearch
                        title="Function"
                        name="functionData"
                        isMandatory={true}
                        search={true}
                        placeHolder="Select"
                        options={functionOption}
                        value={
                          functionOption.filter(
                            (functionData: any) =>
                              functionData.value === contact.functionData
                          ) ?? ""
                        }
                        onChange={(e) => handleSelectChange(e, "functionData", i)}
                        isMulti={false}
                        className="select-field"
                        error={errors[i]?.functionData?.toString() ?? ""}
                        isDisabled={isEdit}
                      /> */}
                      <LabelWithInputField
                        isMandatory={false}
                        name="functionData"
                        handleChange={(e) => changeHandler(e, i)}
                        value={contact.functionData ?? " "}
                        label="Function"
                        placeholder="function"
                        className="form-control shadow-none rounded-0"
                        error={errors[i]?.function}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                      <SelectWithSearch
                        title="Location"
                        name="location"
                        isMandatory={false}
                        search={true}
                        placeHolder="Select"
                        options={locationOption}
                        value={
                          locationOption.filter((location) =>
                            contact.location.some(
                              (loc) => loc === location.value
                            )
                          ) ?? ""
                        }
                        onChange={(e) => handleSelectChange(e, "location", i)}
                        isMulti={true}
                        className="select-field"
                        error={errors[i]?.location?.toString() ?? ""}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-lg-12">
                      <LabelWithTextAreaField
                        label="Info"
                        name="info"
                        value={formData[i].info}
                        placeholder=""
                        handleChange={(event: any) => changeHandler(event, i)}
                        isDisabled={isEdit}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 mb-4">
                    {path === "/create/contact-person" &&
                      i === formData.length - 1 && (
                        <Button
                          title={t("+ Add another")}
                          handleClick={handleAddClick}
                          className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                          disabled={isEdit}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between align-items-center my-4">
            <div className="">
              <Link
                to={MANAGE_CONTACT_PERSON}
                className="text-uppercase back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="">
              <Button
                title="Save"
                handleClick={handleSubmit}
                className="btn form-button float-end text-uppercase shadow-none"
                disabled={isEdit}
              />
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
}
export default CreateContact;
