import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";

import SelectWithSearch from "components/atoms/SelectWithSearch";

import Calender from "components/molecules/Calender";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { Option } from "components/common/CommonInterfaces";

import { SET_FIELD_INVOICE_TAB } from "../context/Constants";

import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { LabelWithInputFieldAndUnit } from "pages/microservices/masterData/templates/atoms/LabelWithInputFieldAndUnit";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
}

// Functional component using TypeScript
const Invoice: React.FC<IProjectFormGeneralTabProps> = () => {
  const { state, dispatch } = useFormContext();
  const selectOptions = state.options;
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target) {
      const { name, value, type, checked } = event.target as HTMLInputElement;
      dispatch({
        type: SET_FIELD_INVOICE_TAB,
        field: name,
        value: value,
      });
    }
  };
  const handleSelectChange = (
    value: Option | Option[],
    fieldName: string,
    isMultiSelect: boolean = false
  ) => {
    // if (isMultiSelect) {
    dispatch({
      type: SET_FIELD_INVOICE_TAB,
      field: fieldName,
      value: value as Option[],
    });
  };
  const inVoice = state.inVoice.data;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {};
  const handleDateChangeCheck = (value: Date | null, fieldName: string) => {
    dispatch({
      type: SET_FIELD_INVOICE_TAB,
      field: fieldName,
      value: value,
    });
  };
  return (
    <div className="container-fluid ">
      <div className="row form-border p-5">
        <div className="col-sm-12 col-md-6">
          <SelectWithSearch
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "paymentTerm", false)
            }
            title={t("Payment term")}
            placeHolder={t("Select")}
            options={selectOptions.paymentTerm}
            value={inVoice.paymentTerm}
            isDisabled={false}
            isMulti={false}
            isMandatory={false}
            error={""}
            name={"paymentTerm"}
            id={"paymentTermId"}
          ></SelectWithSearch>
        </div>
        <div className="col-sm-12 col-md-6">
          <LabelWithInputFieldAndUnit
            type={"number"}
            handleChange={handleFieldChange}
            label={t("Amount")}
            value={inVoice.amount}
            readOnly={false}
            isDisabled={false}
            isMandatory={false}
            // error={generalErrors.name}
            name={"amount"}
            id={"amountId"}
            unit={"€"}
            containerClassName="col-12"
          ></LabelWithInputFieldAndUnit>
        </div>
        <div className="col-sm-12 col-md-6">
          <LabelWithInputField
            type={"text"}
            handleChange={handleFieldChange}
            label={t("Invoice number")}
            value={inVoice.inVoiceNumber}
            readOnly={false}
            isDisabled={false}
            isMandatory={false}
            // error={generalErrors.name}
            name={"inVoiceNumber"}
            id={"inVoiceNumberId"}
          ></LabelWithInputField>
        </div>
        <div className="col-sm-12 col-md-6 position-relative">
          <Calender
            onChange={(date) => handleDateChangeCheck(date, "inVoiceDate")}
            selectedDate={inVoice.inVoiceDate}
            label={t("Invoice date")}
            isMandatory={false}
            name={"inVoiceDate"}
            error={""}
            isDisabled={false}
          ></Calender>
        </div>
        <div className="col-sm-12 col-md-6">
          <MaxiconFileUpload
            label={t("Invoice document")}
            name={"inVoiceDocument"}
            id={"inVoiceDocument"}
            isMandatory={false}
            edit={false}
            fileId={null}
            fileObj={""}
            multiple={false}
            uploadPath="candidate-certificate"
            formats={".pdf,.docx,.doc"}
            handleChange={handleChange}
            maxFileSize={10000}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <SelectWithSearch
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "inVoiceStatus", false)
            }
            title={t("Invoice status")}
            placeHolder={t("Select")}
            options={selectOptions.InvoiceStatus}
            value={inVoice.inVoiceStatus}
            isDisabled={false}
            isMulti={false}
            isMandatory={false}
            error={""}
            name={"paymentTerm"}
            id={"paymentTermId"}
          ></SelectWithSearch>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
