import React, { useEffect, useState } from "react";
import { filterValues, MANAGE_TRANSLATION_CONSTANTS } from "./State";
import { t } from "./Translation";
import Button from "components/atoms/Button";
import { renderFormElements } from "services/form/FormElements";
import { IconData } from "services/IconData";
const ManageFilters: React.FC<any> = ({
    handleChange,
    options,
    data,
    handleSubmit,
    handleClear
}) => {
    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        let row = filterValues(options);
        setRows(row);
    }, [options]);

    return (
        <>
            <div className="d-flex gap-3">
                    {rows.map((value: any) => (                    
                        <div className={`flex-fill w-25`} key={value?.name}>
                            {
                                renderFormElements(
                                    { type: value?.type, name: value?.name, value },
                                    (e: any) => handleChange(e, value?.name),
                                    data,
                                )
                            }
                        </div>
                    ))}
                <div className="d-flex m-auto">
                    <Button
                        className="btn delete-btn  shadow-none text-uppercase me-3 "
                        handleClick={handleClear}
                        // title={t(MANAGE_TRANSLATION_CONSTANTS?.RESET)}
                        title={IconData.ResetIcon}
                    />
                    <Button
                        className='btn form-button shadow-none text-uppercase px-3 search-btns'
                        handleClick={handleSubmit}
                        title={t(MANAGE_TRANSLATION_CONSTANTS?.SUBMIT)}
                    />
                </div>
            </div>
        </>
    );
};

export default ManageFilters;
