import { useEffect, useState } from "react"
import Car from "./car/Car";
import Material from "./material/Material";
import Clothes from "./clothes/Clothes";
import Supplier from "./supplier/Supplier";
import Client from "./client/Client";
import { Link, useParams, useNavigate } from "react-router-dom";
import { t } from "../masterData/translation/Translation";
import PurchaseOrder from "./purchaseOrder/PurchaseOrder";

const FleetOverview = () => {
    const staticTabs = [
        {
            id: 'car',
            tabName: 'Cars',
            component: <Car />,
        },
        {
            id: 'material',
            tabName: 'Material',
            component: <Material />
        },
        {
            id: 'clothes',
            tabName: 'Clothes',
            component: <Clothes />
        },
        {
            id: 'supplier',
            tabName: 'Supplier',
            component: <Supplier />
        },
        {
            id: 'client',
            tabName: 'Client',
            component: <Client />
        },
        {
            id: 'purchaseOrder',
            tabName: 'Purchase order',
            component: <PurchaseOrder />
        }

    ];
    const [tabs, setTabsData] = useState(staticTabs);
    const params = useParams();
    const navigate = useNavigate();

    const [activeTab, setActiveTabs] = useState(tabs[0]);

    useEffect(() => {
        updateCurrentTab();
    }, [params.type]);

    const updateCurrentTab = () => {
        const tabType = params.type;
        const updatedCurrentTab = tabs.filter((eachTab: any) => {
            if (eachTab.id == tabType) {
                return eachTab;
            }
            return false;
        });
        setActiveTabs(updatedCurrentTab[0]);
    }

    const handleChangeTab = (event: any) => {
        const { id } = event.target;
        const updatedTab = tabs.filter((eachItem) => {
            if (eachItem.tabName === event.target.textContent) {
                setActiveTabs(eachItem)
                return { ...eachItem };
            }
            else {
                return { ...eachItem };
            }
        });
        //setTabsData(updatedTab);
        navigate(`/fleetOverview/${id}`);
    }

    return (
        <>
            <div className="w-50 mx-auto d-flex justify-content-center gap-3 fleettabs_btn">
                {tabs.map((eachItem) =>
                    <button className={`company-step-tabs-btn ${eachItem.id == activeTab.id ? 'active' : 'inactive'}`} id={eachItem.id} onClick={handleChangeTab} >{eachItem.tabName}</button>)}
            </div>
            {activeTab.component}
            <div className="col-md-6 align-self-center my-4">
                <Link
                    to={"/dashboard"}
                    className="text-uppercase back-btn text-decoration-underline"
                >
                    {t("Back")}
                </Link>
            </div>
        </>
    )
}
export default FleetOverview