import React, { useState } from "react";
import MyProfile from "./MyProfile";

const UsersProfile: React.FC = () => {
  
  return (
    <>
   <MyProfile/>
    </>
  );
};


export default UsersProfile;