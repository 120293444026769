import Modal from "react-bootstrap/Modal";
import { t } from "../masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";

const FleetEmployeeModal = (props: any) => {
    const { data, setFleetData, handleClose, handleSave, handleRemove, errors, validationFn } = props;

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFleetData((prevState: any) => ({ ...prevState, [name]: value }));
        validationFn(name, value, true);
    }
    return (
        <Modal
            size="lg"
            show={true}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="bitter-italic-normal-medium-24">
                    {t(`Select assign from and assign to dates`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data?.type == 'clothes' ? (<p>Remaining units: {data?.remainingUnits}</p>) : (<></>)}
                <div className="">
                    <LabelWithInputField
                        type={"date"}
                        placeholder={t("From date")}
                        handleChange={handleInputChange}
                        label={t("From date")}
                        value={data.fromDate}
                        isMandatory={true}
                        error={errors.fromDate}
                        name={"fromDate"}
                        id={"fromDate"}
                    />
                    <LabelWithInputField
                        type={"date"}
                        placeholder={t("To date")}
                        handleChange={handleInputChange}
                        label={t("To date")}
                        value={data.toDate}
                        isMandatory={true}
                        error={errors.toDate}
                        name={"toDate"}
                        id={"toDate"}
                    />
                    {data?.type == 'clothes' ? (<LabelWithInputField
                        type={"number"}
                        placeholder={t("No of units")}
                        handleChange={handleInputChange}
                        label={t("No of units")}
                        value={data?.count}
                        isMandatory={true}
                        error={errors.count}
                        name={"count"}
                        id={"count"}
                        min={0}
                    />) : (<></>)}
                </div>
                <div className="row align-items-center ">
                    <div className="col-6  ">
                        <p
                            className="text-uppercase  mb-0 cursor-pointer"
                            onClick={handleClose}
                        >
                            {t("Cancel")}
                        </p>
                    </div>
                    <div className="col-6">
                        <Button
                            title={data.status ? t("update") : t("Add")}
                            handleClick={handleSave}
                            className="btn form-button float-end text-uppercase rounded-0 shadow-none mx-2"
                        />
                        {data.status ? (<Button
                            title={"Remove"}
                            handleClick={handleRemove}
                            className="btn form-button float-end text-uppercase rounded-0 shadow-none"
                        />) : <></>}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default FleetEmployeeModal