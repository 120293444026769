import React from 'react'

const VerifiedUser = () => {
  return (
    <>
      <svg width="35" height="30" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M192.718 249.241C249.756 249.241 298.703 176.026 298.703 116.019C298.703 57.7537 251.086 10.24 192.718 10.24C134.248 10.24 86.6331 57.7537 86.6331 116.019C86.6331 176.026 135.581 249.241 192.718 249.241ZM252.828 357.684C252.828 330.65 260.816 305.561 274.638 284.467C256.923 277.299 237.875 273.614 218.421 273.614H161.386C79.3606 273.614 13.0056 337.511 10.2406 419.431V491.52C10.2406 497.151 14.8507 501.76 20.4806 501.76H359.323C365.058 501.76 369.563 497.151 369.563 491.52V490.906C303.721 482.099 252.828 425.676 252.828 357.684Z" fill="currentColor" />
        <path d="M387.586 243.711C324.611 243.711 273.308 294.911 273.308 357.684C273.308 420.556 324.611 471.654 387.586 471.654C450.561 471.654 501.761 420.556 501.761 357.684C501.761 294.911 450.561 243.711 387.586 243.711ZM441.361 332.186L372.641 400.906C370.641 402.906 368.021 403.906 365.401 403.906C362.781 403.906 360.161 402.906 358.161 400.906L333.761 376.506C329.761 372.506 329.761 366.026 333.761 362.026C337.761 358.026 344.241 358.026 348.241 362.026L365.401 379.186L426.881 317.706C430.881 313.706 437.361 313.706 441.361 317.706C445.361 321.706 445.361 328.186 441.361 332.186Z" fill="currentColor" />
      </svg>

    </>
  )
}

export default VerifiedUser