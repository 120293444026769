import { calculateTotalTime } from "services/util/TotalTimeCalculator";
import { DurationAndAllowance, EmployeeTypeDependency } from "./Constants";
import { duration } from "@mui/material";

export const MarginReducer = (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        case "UPDATE_INPUT_FIELDS":
            const { value, entityType, name, index, subKey } = payload;

            if (index !== undefined) {
                if ((entityType == 'durationAndAllowance') && (name == 'startTime' || name == 'endTime' || name == 'pauseTime')) {
                    const { startTime, endTime, pauseTime } = state?.data?.durationAndAllowance[index];
                    const sTime = name == 'startTime' ? value : startTime;
                    const eTime = name == 'endTime' ? value : endTime;
                    const pTime = name == 'pauseTime' ? value : pauseTime;
                    const updatedValue = calculateTotalTime(sTime, eTime, pTime)
                    state.data[entityType][index][name] = value;
                    state.data[entityType][index]['hours'] = updatedValue;
                    return {
                        ...state,
                    }
                }
                state.data[entityType][index][name] = value;
                return {
                    ...state,
                }
            }

            if (name == 'project') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [entityType]: {
                            ...state.data?.[entityType],
                            [name]: value,
                            timeTable: '', projectParameters: '', hourlyRate: '', noOfKms: '', euroPerKmRate: ''
                        }
                    }

                }
            }

            if (subKey) {
                let dependencyFields: any = {};
                if (Object.keys(EmployeeTypeDependency).includes(payload.entityType) && name == 'name') {
                    for (let type of EmployeeTypeDependency[payload.entityType]) {
                        dependencyFields[type] = {
                            ...state.data[type],
                            [payload.subKey]: {
                                ...state.data[entityType][subKey],
                                empType: value,
                            }
                        }
                    }
                }

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [payload.entityType]: {
                            ...state.data[entityType],
                            [payload.subKey]: {
                                ...state.data[entityType][subKey],
                                [name]: value,
                            },
                        },
                        ...dependencyFields,
                    }
                };
            }
            //state.data[entityType][name] = value;
            return {
                ...state,
                data: {
                    ...state.data,
                    [entityType]: {
                        ...state.data[entityType],
                        [name]: value,
                    }
                }
            }

        case "ADD_ANOTHER_ELEMENT":

            const filteredOptions = state.data.options?.[payload.entityType].filter((eachItem: any) => {
                return eachItem.value !== state.data[payload.entityType].form.name.value;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        dataList: [
                            ...state.data[payload.entityType].dataList,
                            { ...state.data[payload.entityType].form }
                        ],
                        form: {
                            name: '',
                            amount: '',
                            coefficient: '',
                            perHour: false,
                            invoicing: false,
                        }
                    },
                    options: {
                        ...state.data.options,
                        [payload.entityType]: filteredOptions,
                    }
                }
            };

        case "REMOVE_DYNAMIC_ELEMENT":
            const updatedDataList = [...state.data[payload.entityType].dataList];
            const removedData = updatedDataList.splice(payload.index, 1);
            const filteredList = [removedData[0].name, ...state.data.options[payload.entityType]];

            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        dataList: updatedDataList
                    },
                    options: {
                        ...state.data.options,
                        [payload.entityType]: filteredList,
                    }

                }
            };

        case "EDIT_DYNAMIC_ELEMENT":
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        activeIndex: payload.index,
                        isEdit: true,
                        form: state.data[payload.entityType].dataList[payload.index]
                    }
                }
            };

        case "UPDATE_DATA_LIST":
            const listDetails = [...state.data[payload.entityType].dataList];
            const currentData = { ...state.data[payload.entityType].form };
            const activeIndex = state.data[payload.entityType].activeIndex;
            listDetails.splice(activeIndex, 1, currentData);
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        activeIndex: '',
                        isEdit: false,
                        dataList: listDetails,
                        form: {
                            name: '',
                            amount: '',
                            coefficient: '',
                            perHour: false,
                            invoicing: false,
                        }
                    }
                }
            };

        case "UPDATE_FIELD_ERRORS":
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.id]: action.payload.errors,
                },
            };

        case "UPDATE_OPTIONS":
            if (payload?.key == 'timeTable') {
                const durationAndAllowance = [
                    ...state?.data?.durationAndAllowance
                ];
                const updatedState = durationAndAllowance.map((eachItem: any, index: number) => {
                    return { ...eachItem, ...payload.data?.['shifts']?.[index] };
                });
                return {
                    ...state,
                    data: {
                        ...state.data,
                        durationAndAllowance: updatedState,
                    }
                }
            }

            if (payload?.key == 'project') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        options: {
                            ...state.data.options,
                            ...payload.data,
                        },
                        fixedProjectParameters: {
                            ...state.data.fixedProjectParameters,
                            ...payload.data.fixedProjectParameters,
                        },
                        durationAndAllowance: DurationAndAllowance,
                    },
                }
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        ...payload.data,
                    }
                }
            }

        case "UPDATE_OVERALL_ERRORS":
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...payload.errors,
                }
            }
        case "UPDATE_MARGIN_DATA":
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload.data,
                }
            }
        default:
            return state;

    }
}