import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";
import Button from "components/atoms/Button";
import ContactForm from "components/organism/contactForm";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import {
  validateForm,
  validateFormField,
} from "services/validation/ValidationService";
import { ITagAtomProps } from "components/atoms/TagAtom";
import { Option } from "components/common/CommonInterfaces";
import {
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_FIELD_ERROR,
  UPDATE_SELECTED_CONTACT_FIELD,
} from "../context/Constants";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  ContactsTabSubformValidationRules,
  ContactsTabValidationRules,
} from "../validationRules/TempAgencyValidationRules";
interface ProjectsProps {
  id?: string | number;
}

const ContactsPersons: React.FC<ProjectsProps> = () => {
  const { state, dispatch } = useFormContext();
  const contactsData = state.contactsPersons.data;
  const contactErrors = state.contactsPersons.errors;
 

  const options = state.options;

  // Component based
  const selectedContactPersons: Option[] = contactsData.selectedContactPersons;
  const selectedContactsTagList: ITagAtomProps[] =
    contactsData.selectedContactsTagList;

  // Options
  const contactPersonOptions = options.companyUsers;

  // Subform
  const contactListSubform = contactsData.contactsPersons;
  const contactSubformIsVisible = contactsData.contactSubformIsVisible;

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false,
    index?: any
  ) => {
    const validationErrors = validateForm(
      { ...contactListSubform[index], [name]: value },
      // validationRules,
      ContactsTabSubformValidationRules,
      isSingleFieldValidation ? name : undefined
    );

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (index == undefined) {
      if (isFieldValid) {
        const updatedErrors = {
          // ...state.contactsPersons.errors[index],
          ...contactErrors.contactsPersons[index],
          [name]: "",
        };
        // state.contactsPersons.errors[index] = updatedErrors;
        contactErrors.contactsPersons[index] = updatedErrors;
      }
    } else {
    }

    if (isFieldValid) {
      const updatedErrors = {
        // ...state.contactsPersons.errors[index],
        ...contactErrors.contactsPersons[index],
        [name]: "",
      };
      // state.contactsPersons.errors[index] = updatedErrors;
      contactErrors.contactsPersons[index] = updatedErrors;
    } else {
      const updatedErrors = {
        // ...state.contactsPersons.errors[index],
        ...contactErrors.contactsPersons[index],
        [name]: validationErrors[name],
      };
      // state.contactsPersons.errors[index] = updatedErrors;
      contactErrors.contactsPersons[index] = updatedErrors;
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleAddContact = (e: any) => {
    dispatch({
      type: "ADD_CONTACT",
    });
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({
      type: "REMOVE_CONTACT",
      indexToRemove,
    });
  };

  const handleContactChange = (selectedOption: Option[], fieldName: string) => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: fieldName,
      value: selectedOption,
    });

    // todo: add dispatch for error update
    let validatedFieldError = validateFormField(
      contactsData,
      ContactsTabValidationRules,
      fieldName,
      selectedOption
    );
    dispatch({
      type: UPDATE_FIELD_ERROR,
      fieldName: fieldName,
      error: validatedFieldError,
      tab: "contactsPersons",
    });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      field: name,
      value,
      index,
    });
    validation(name, value, true, index);
  };

  const handlePhoneNumberChange = (
    fieldName: string,
    value: string | undefined,
    index: number
  ) => {
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      index,
      field: fieldName,
      value: value,
    });
    validation(fieldName, value, true, index);
  };

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index?: number
  ) => {
    if (index !== undefined) {
      dispatch({
        type: UPDATE_CONTACT_SUBFORM_FIELD,
        field: name,
        value: selectedOption,
        index,
      });
      validation(name, selectedOption, true, index);
    } else {
      dispatch({
        type: UPDATE_SELECTED_CONTACT_FIELD,
        field: name,
        value: selectedOption,
      });
      validation(name, selectedOption, true);
    }
  };

  const toggleFormVisibility = () => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: "contactSubformIsVisible",
      value: !contactSubformIsVisible,
    });

    // setIsFormVisible((prevIsVisible) => !prevIsVisible);
  };


  const optionsToTagList = (newOptions: Option[]) => {
    let tagList: ITagAtomProps[] = [];

    newOptions.forEach((option: Option, index) => {
      if (option.label && option.value) {
        const tag: ITagAtomProps = {
          label: option.label,
          value: option.value,
          isSelected: false,
        };
        tagList.push(tag);
      } else {
        console.warn("Option's label or value not set");
      }
    });

    return tagList;
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row form-border p-5">
          <div className="container-fluid">
            <div className="d-flex align-items-center mb-4 form-border p-5 mt-3">
              <div className="w-100 me-4 position-relative">
                <SelectWithSearch
                  title="Select contact person"
                  name="personcontact"
                  isMandatory={true}
                  containerClassName="mb-4"
                  search={true}
                  options={contactPersonOptions}
                  value={selectedContactPersons}
                  placeHolder={t("Select")}
                  onChange={(e) =>
                    handleContactChange(e, "selectedContactPersons")
                  }
                  isMulti={true}
                  className="select-field"
                  error={contactErrors.selectedContactPersons}
                />
              </div>
              <div className="">
                <Button
                  title={""}
                  handleClick={toggleFormVisibility}
                  className={
                    "btn form-button  text-uppercase mt-2 shadow-none align-self-end"
                  }
                  tooltip={""}
                  disabled={false}
                  type={"button"}
                  icon={contactSubformIsVisible ? faMinus : faPlus}
                ></Button>
              </div>
            </div>
            {contactSubformIsVisible && (
              <>
                {Object.entries(contactListSubform).map(
                  ([key, contact], index) => {
                    return (
                      <ContactForm
                        key={key}
                        index={index}
                        contacts={contact}
                        contactErrors={contactErrors.contactsPersons}
                        onRemoveContact={handleRemoveContact}
                        onFieldChange={handleFieldChange}
                        onPhoneNumberChange={handlePhoneNumberChange}
                        onSelectChange={handleSelectChange}
                        companyOptions={options.company}
                        genderOptions={options.gender}
                        languageOptions={options.language}
                        rolesOptions={options.roles}
                        functionsOptions={[
                          { value: 1, label: "Function 1" },
                          { value: 2, label: "Function 2" },
                        ]}
                        locationsOptions={options.locations}
                        fromType={"project"}
                        mode={"tempAgency"}
                      />
                    );
                  }
                )}
                <div className="row my-4">
                  <div className="col-md-12">
                    <Button
                      title="+ Add another"
                      handleClick={(e) => handleAddContact(e)}
                      className="btn form-button float-end text-uppercase  shadow-none"
                    />
                  </div>
                </div>
              </>
            )}

            {/* <Navigation /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsPersons;
