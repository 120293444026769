import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { GET_SALARY_BENEFITS } from "routes/ApiEndpoints";
import { DATE_OPTIONS, M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageSalaryBenefitPage from "./Manage";

export interface SalaryBenefits {
  id?: number;
  name: string;
  salary_benefit_type?: string;
  salary_benefit_value?: string;
  occurence?: string;
  start_date?: string;
  is_mandatory?: boolean;
  is_benefit_granted?: boolean;
}

interface DataProps {
  page: number;
  search?: any;
}

const ManageSalaryBenefits: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [SalaryBenefit, setSalaryBenefit] = useState<SalaryBenefits[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    name: queryParams.get("name") ?? "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };

    fetchData(data);
  }, [currentPage, submit]);

  const fetchData = async (data: DataProps) => {
  try {
    const response = await ApiCall.service(
      GET_SALARY_BENEFITS,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      let data = response?.data?.map((item: any) => {
        const dateOption = DATE_OPTIONS?.find((date: any) => date?.value == item?.start_date);
        return {
          ...item,
          start_date: dateOption ? dateOption?.label : ''
        };
      });
      setSalaryBenefit(data);
      setTotalPages(response.totalPages);
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    console.error(error);
  }
};

const handlePageChange = (pageNumber: number) => {
  setCurrentPage(pageNumber);
};

const handleSearch = (name: string, value: string | number) => {
  setSearch((prevSearch) => ({
    ...prevSearch,
    [name]: value,
  }));
};


  const createQueryParams = (data: SalaryBenefits) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleDeleteData = (deleteId: number) => {
    setSalaryBenefit((prevdata) =>
      prevdata.filter((salaryBenefit) => salaryBenefit.id !== deleteId)
    );
    setCurrentPage(1);
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        name: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  return (
    <ManageSalaryBenefitPage
      salaryBenefitObject={SalaryBenefit}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      handleSubmitAction={handleSubmit}
      searchData={search}
    />
  );
};

export default ManageSalaryBenefits;
