export const OverTimeDependency: any = {
    "17": ["18"],
    "18": ["17"],
}

export const OverTimeFieldDependency: any = {
    "17": ["20"],
    "18": ["21"],
    "19": ["22"],
    "23": ["26"],
    "24": ["27"],
    "25": ["28"],
    "29": ["32"],
    "30": ["33"],
    "31": ["34"],
}