import React,{useState } from 'react'
import { Navigation } from '../formNavigation/Navigation';
import { useFormContext } from '../../context/Context';

const BillingTab = () => {
  const { state, dispatch } = useFormContext();
  const [isValid, setIsValid] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  
  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  }


  return (
    <>
      <div>Step2</div>
      <form onSubmit={handleSubmit}>
        Billing
        <Navigation validStatus={validStatus} />

      </form>
    </>

  )
}
export default BillingTab;


