import React, {useContext, createContext, useReducer, useState} from 'react'
import { initialPlanningModalProps } from './intialPlanningModalProps';
import { planningModalReducer } from '../../reducers/planningModalReducer/PlanningModalReducer';

const PlanningModalContext = createContext(null);
const PlanningModalDispatchContext = createContext(null);

const initialPlanningModal = initialPlanningModalProps;

export function PlanningModalProvider({children}){
    const [planningModalState, dispatchPlanningModal] = useReducer(planningModalReducer, initialPlanningModal);

    return (
        <PlanningModalContext.Provider value={{planningModalState}}>
            <PlanningModalDispatchContext.Provider value={{dispatchPlanningModal}}>
            {children}
            </PlanningModalDispatchContext.Provider>
        </PlanningModalContext.Provider>
    )
}


export function usePlanningModal(){
    return useContext(PlanningModalContext);
}


export function usePlanningsModalDispatch(){
    return useContext(PlanningModalDispatchContext);
}
