import React, { useState } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import InputTextfield from "components/atoms/InputTextField";
import InputTextAreaField from "components/atoms/InputTextAreaField";
import { UPDATE_SPECIAL_AGREEMENT_FIELD } from "../context/Constants";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
}

// Functional component using TypeScript
const SpecialAgreement: React.FC<IProjectFormGeneralTabProps> = () => {
  const { state, dispatch } = useFormContext();
  // const { agreementId } = useParams<{ agreementId: string }>();
  // const { companyId } = useParams<{ companyId: string }>();
  const [loading, setLoading] = useState(false);
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = e.target;
    if (name === "niceToHave") {
      const regex = /^[\d,.]*$/;
      let matches = value.match(regex);
      if (matches || value === "") {
        dispatch({
          type: UPDATE_SPECIAL_AGREEMENT_FIELD,
          field: name,
          value: value,
          index: index,
        });
      }
    } else {
      const newValue = type === "radio" ? parseInt(value) : value;
      const fieldName = type === "radio" ? "inclusiveOption" : name;
      dispatch({
        type: UPDATE_SPECIAL_AGREEMENT_FIELD,
        field: fieldName,
        value: newValue,
        index: index,
      });
    }
  };

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    dispatch({
      type: UPDATE_SPECIAL_AGREEMENT_FIELD,
      field: fieldName,
      value: selectedOption,
      index: index,
    });
  };
  return (
    <div className="agreement-wage-element">
      <div className="position-relative">
        <div className="form-border px-lg-5 py-5 px-4">
          <div className="form pb-4 pt-2">
            <div className="pwa">
              <table className="table composition-co-efficient-table">
                <thead>
                  <tr className="border-0 TableHeader bg-white">
                    <th className="border-0 py-3">
                      {t("Composition co-efficient")}
                    </th>
                    <th className="border-0 text-center">{t("Included")}</th>
                    <th className="border-0 text-center">
                      {t("Not-included")}
                    </th>
                    <th className="border-0">{t("Value")}</th>
                    <th className="border-0">{t("Remarks")}</th>
                  </tr>
                </thead>
                <tbody>
                  {state.wageElement &&
                    state.wageElement.map((item: any, index: number) => (
                      <tr
                        className="border mb-3 box-shadow align-middle agreementData"
                        key={index}
                      >
                        <td
                          className="border-0 text-break align-middle"
                          data-label={t("Composition co-efficient")}
                        >
                          {t(`${item.name}`)}
                        </td>
                        <td
                          className="border-0 text-break text-center"
                          data-label={t("Included")}
                        >
                          <RadioField
                            handleChange={(e) => changeHandler(e, item.wageId)}
                            label=""
                            ischecked={item.inclusiveOption === 1}
                            name={`inclusive-${item.wageId}`}
                            value={1}
                          />
                        </td>
                        <td
                          className="border-0 text-break text-center"
                          data-label={t("Not-included")}
                        >
                          <RadioField
                            handleChange={(e) => changeHandler(e, item.wageId)}
                            ischecked={item.inclusiveOption === 0}
                            label=""
                            name={`inclusive-${item.wageId}`}
                            value={0}
                          />
                        </td>
                        <td
                          className="border-0 text-break "
                          data-label={t("Value")}
                        >
                          <div className="input-group field-shadow rounded-3 agreement-wage-elements">
                            {item.type === 1 ? (
                              <SelectWithSearch
                                key={item.wageId}
                                search={true}
                                options={state.options.coefficient}
                                onChange={(e) =>
                                  handleSelectChange(e, "value", item.wageId)
                                }
                                isMulti={false}
                                name="value"
                                value={item.value}
                                className={`${
                                  item.errorStatus ? "error-border" : ""
                                }`}
                                placeHolder={""}
                              />
                            ) : (
                              <>
                                <InputTextfield
                                  name="niceToHave"
                                  handleChange={(event) =>
                                    changeHandler(event, item.wageId)
                                  }
                                  value={item.niceToHave ?? ""}
                                  id={`niceToHave-${index}`}
                                  className={`form-control shadow-none ${
                                    item.errorStatus &&
                                    item.approvalStatus !== 2
                                      ? "error-border"
                                      : "border-0"
                                  } rounded-start-3 ${
                                    item.errorStatus &&
                                    item.approvalStatus !== 2
                                      ? "rounded-3"
                                      : ""
                                  }`}
                                />
                                <div className="input-group-append euroSignAgreement">
                                  €
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        <td
                          className="border-0 text-break"
                          data-label={t("Remarks")}
                        >
                          <div className="row remarksTextArea">
                            <div>
                              <InputTextAreaField
                                handleChange={(e) =>
                                  changeHandler(e, item.wageId)
                                }
                                name="remarks"
                                value={item.remarks ?? ""}
                                rows={1}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialAgreement;
