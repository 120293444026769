import React from "react";
import TimesheetsFilters from "./molecules/timesheetsFilters";

const PageLayout = ({ children }) => {
  return (
    <div className="page-layout d-flex flex-column h-100 overflow-auto">
      <header>
        <TimesheetsFilters />
      </header>
      <div className="content flex-1 overflow-auto">{children}</div>
    </div>
  );
};

export default PageLayout;
