import React, { useState } from "react";
import { Navigation} from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import RadioField from "components/atoms/RadioField";
import LabelField from "components/atoms/LabelField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import { useFormContext } from "../../context/Context";
import {
  validateForm,
  validateRequired,
  Option,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";

const InvoiceTab = () => {
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  
  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const { state, dispatch } = useFormContext();

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = parseInt(value);
    dispatch({ type: "UPDATE_INVOICE_FIELD", field: name, value: newValue });
    validation(name, newValue, true);
  };

  const validation = (
    name: string,
    value:
      | string
      | number
      | boolean
      | Date
      | Option
      | object[]
      | null
      | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      payment: [validateRequired],
      billingMode: [validateRequired],
      invoiceType: [validateRequired],
      status: [validateRequired],
      corrections: [validateRequired],
      comments: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...state.invoice, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: name,
        error: validationErrors[name],
      });
    } else {
      dispatch({ type: "UPDATE_FIELD_ERRORS", errors: validationErrors });
    }

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectField = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_INVOICE_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    if (validation(name, value)) {
    }
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    // setFormData((prevData) => ({ ...prevData, [name]: content }));
    // setContentChanged(true);
    // if (contentChanged) {
    //   validation(name, content, true);
    // }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-border p-3">
          <div className="row">
            <div className="col-md-12">
              <SelectWithSearch
                title="Number of days payment term (standard: 14)"
                isMandatory={true}
                isMulti={false}
                onChange={(e) => {
                  handleSelectField(e, "payment");
                }}
                search={false}
                options={[]}
                value={state.invoice.payment}
                placeHolder="Select"
                name="payment"
                className="select-field"
                error={state.fieldError.payment}
              />
            </div>
            <div className="col-lg-12">
              <div className="row">
                <LabelField title="Billing mode" isMandatory={true} />
              </div>
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="By e-mail"
                  name="billingMode"
                  value={0}
                  ischecked={state.invoice.billingMode === 0}
                />
              </div>
              <div>
                <RadioField
                  handleChange={changeHandler}
                  label="By postmail"
                  name="billingMode"
                  value={1}
                  ischecked={state.invoice.billingMode === 1}
                />
              </div>
            </div>
            <div className="height-20 mb-2 text-danger">
              {state.fieldError.billingMode}
            </div>
            <div className="col-lg-12">
              <LabelField
                title="How should invoicing be done (grouped/by branch/by candidate)?"
                isMandatory={true}
              />
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="Grouped invoicing (all on one invoice)"
                  name="invoiceType"
                  value={0}
                  ischecked={state.invoice.invoiceType === 0}
                />
              </div>
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="Invoice per branch (candidates at one branch within one contract (week/day))"
                  name="invoiceType"
                  value={1}
                  ischecked={state.invoice.invoiceType === 1}
                />
              </div>
              <div>
                <RadioField
                  handleChange={changeHandler}
                  label="Invoice per cost centre (candidates on multiple cost centres within one contract (week/day))"
                  name="invoiceType"
                  value={2}
                  ischecked={state.invoice.invoiceType === 2}
                />
              </div>
            </div>
            <div className="height-20 mb-2 text-danger">
              {state.fieldError.invoiceType}
            </div>
            <div className="col-lg-12">
              <LabelField
                title="Breakdown by status (clerk/job student/flexi)Grouped invoice (all on one invoice)"
                isMandatory={true}
              />
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="Yes"
                  name="status"
                  value={0}
                  ischecked={state.invoice.status === 0}
                />
              </div>
              <div className="">
                <RadioField
                  handleChange={changeHandler}
                  label="No"
                  value={1}
                  name="status"
                  ischecked={state.invoice.status === 1}
                />
              </div>
            </div>
            <div className="height-20 mb-2 text-danger">
              {state.fieldError.status}
            </div>
            <div className="col-lg-12">
              <LabelField
                title="How should corrections be settled?"
                isMandatory={true}
              />
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="Credit full invoice and re-invoice (2 documents)"
                  name="corrections"
                  value={0}
                  ischecked={state.invoice.corrections === 0}
                />
              </div>
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="Credit contract and re-invoice (2 documents)"
                  name="corrections"
                  value={1}
                  ischecked={state.invoice.corrections === 1}
                />
              </div>
              <div className="mb-2">
                <RadioField
                  handleChange={changeHandler}
                  label="Credit/invoice only the difference (1 document)"
                  name="corrections"
                  value={2}
                  ischecked={state.invoice.corrections === 2}
                />
              </div>
            </div>
            <div className="height-20 mb-2 text-danger">
              {state.fieldError.corrections}
            </div>
            <div className="col-lg-12">
              <LabelWithCKEditorField
                name="comments"
                label="Are there any comments?"
                value=""
                handleChange={(event, editor) =>
                  handleCKEditorChange(event, editor, "comments")
                }
                isMandatory={false}
                error={state.fieldError.comments}
              />
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};
export default InvoiceTab;
