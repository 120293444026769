import { PLANNING_FILTER_ACTIONS } from "../../actions/PlanningFilterActions";

export const filtersReducer = (filterState, actions) => {
    switch(actions.type){
        case PLANNING_FILTER_ACTIONS.UPDATE_PLANNING_SELECTED_FIELD: 
            {return update_selected_field(filterState, actions);}
        
        case PLANNING_FILTER_ACTIONS.BULK_UPDATE_PLANNING_SELECTED_FILEDS:
            {return update_bulk_selected_fields(filterState, actions)}
        
        case PLANNING_FILTER_ACTIONS.UPDATE_PLANNING_OPTIONS_STATE:
            {return update_options_state_field(filterState, actions)}

        case PLANNING_FILTER_ACTIONS.BULK_UPDATE_PLANNING_OPTIONS_STATE:
            {return update_builk_planning_options_state(filterState, actions)}

        default:
            throw Error('Unknown action: ' + actions.type);
    }
}

function update_selected_field(filterState, actions) {
    return {
        ...filterState,
        filteredState: {
            ...filterState.filteredState,
            [actions.payload.field]: actions.payload.value
        }
    };
}

function update_bulk_selected_fields(filterState, actions) {
    return {
        ...filterState,
        filteredState: {
            ...filterState.filteredState,
            ...actions.payload
        }
    };
}

function update_options_state_field(filterState, actions) {
    return {
        ...filterState,
        filteredState: {
            ...filterState.state,
            [actions.payload.field]: actions.payload.value
        }
    };
}

function update_builk_planning_options_state(filterState, actions){
    return {
        ...filterState,
        state: {
            ...filterState.state,
            ...actions.payload
        }
    };
}