import { ITagAtomProps } from "components/atoms/TagAtom";
import {
  SWITCH_TAB,
  SET_OPTIONS,
  SHIFT_UPDATE_FIELD,
  ADD_CONTACT,
  REMOVE_CONTACT,
  UPDATE_SELECTED_CONTACT_FIELD,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_CURRENT_TAB,
  UPDATE_RESPONSIBLE_PERSON_FIELD,
  SET_FIELD_GENERAL_TAB,
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_BILLING_FIELD,
  UPDATE_SHIFT_TABLE,
  SET_CREATE_LOCATION_GENERAL_TAB,
  SET_ERROR_SUBFIELD_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_SHIFT_TIME,
  NEW_SHIFT,
  UPDATE_SELECTED_CONTACT_TAG_EDIT,
  PROJECT_OVERVIEW,
  UPDATE_FIELD_ERROR,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB,
  UPDATE_ERRORS_BILLING_TAB,
  UPDATE_ERRORS_SHIFT_TAB,
  UPDATE_ERRORS_PARAMETER_TAB,
  INITIAL_STATE,
  SIGNATURE_IMAGE,
  SET_SEQUENCE_NAME,
  ADD_ANOTHER_SEQUENCE,
  SET_WEEKLY_SHIFTS,
  EDIT_SEQUENCE,
  DELETE_SEQUENCE,
  UPDATE_ERRORS_SEQUENCE_TAB,
  TITLE_ALREADY_EXISTS,
  UPDATE_PARAMETERS_ERRORS,
  OverTimeDependency,
  OverTimeFieldDependency,
  UPDATE_CONTACT_FIELD_ERROR,
  SET_CONTACT_PERSON_OPTION,
} from "pages/microservices/project/context/Constants";
import {
  ContactPersonErrors,
  ContactPersonsData,
  ContactsPersons,
} from "./Interfaces";
import { isValidTime } from "services/validation/ValidationService";
import { calculateTotalTime } from "services/util/TotalTimeCalculator";

type ProjectReducerProps = {
  state: any;
  action: any;
};

const ProjectReducer = (
  state: ProjectReducerProps["state"],
  action: ProjectReducerProps["action"]
) => {
  switch (action.type) {
    case SWITCH_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SET_OPTIONS:
      const options = { ...state.options };
      options[action.field] = action.value;
      return { ...state, options };

    // Contact Tab
    case ADD_CONTACT:
      // Add a new contact to the contactsPerson data array
      const newContact: ContactsPersons = {
        id: "",
        fName: "",
        lName: "",
        email: "",
        company: null,
        mobNumber: "",
        phNumber: "",
        functionTitle: "",
        language: "",
        info: "",
        locations: [],
        linkedIn: "",
        gender: null,
        roles: [],
      };
      // Add a new contact error to the contactsPerson errors array
      const newError: ContactPersonErrors = {
        fName: "",
        lName: "",
        email: "",
        company: "",
        mobNumber: "",
        phNumber: "",
        functionTitle: "",
        language: "",
        info: "",
        locations: [],
        linkedIn: "",
        gender: "",
        roles: [],
      };
      // Create a new array by spreading the existing contacts and adding the new contact
      const updatedContact = [
        ...state.contactsPersons.data.contactsPersons,
        newContact,
      ];
      // Create a new array by spreading the existing errors and adding the new error
      const updatedErrors = [
        ...state.contactsPersons.errors.contactsPersons,
        newError,
      ];

      // Create a new object for contactsPersons to maintain immutability
      const updatedContactsPersons = {
        ...state,
        data: {
          ...state.contactsPersons.data,
          selectedContactPersons:
            state.contactsPersons.data.selectedContactPersons,
          selectedContactsTagList:
            state.contactsPersons.data.selectedContactsTagList,
          contactSubformIsVisible:
            state.contactsPersons.data.contactSubformIsVisible,
          contactsPersons: updatedContact,
        },
        errors: {
          ...state.contactsPersons.errors,
          selectedContactPersons:
            state.contactsPersons.errors.selectedContactPersons,
          selectedContactsTagList:
            state.contactsPersons.errors.selectedContactsTagList,
          contactsPersons: updatedErrors,
        },
      };
      return { ...state, contactsPersons: updatedContactsPersons };

    case REMOVE_CONTACT:
      const contactPersons = state.contactsPersons;

      const updatedContacts = [...contactPersons.data.contactsPersons];
      updatedContacts.splice(action.indexToRemove, 1);

      const updatedContactErrors = [...contactPersons.errors.contactsPersons];
      updatedContactErrors.splice(action.indexToRemove, 1);

      const updatedContactsWithPersonsRemoved = {
        ...state,
        contactsPersons: {
          data: {
            ...state.contactsPersons.data,
            selectedContactPersons:
              state.contactsPersons.data.selectedContactPersons,
            selectedContactsTagList:
              state.contactsPersons.data.selectedContactsTagList,
            contactSubformIsVisible:
              state.contactsPersons.data.contactSubformIsVisible,
            contactsPersons: updatedContacts,
          },
          errors: {
            ...state.contactsPersons.errors,
            selectedContactPersons:
              state.contactsPersons.errors.selectedContactPersons,
            selectedContactsTagList:
              state.contactsPersons.errors.selectedContactsTagList,
            contactsPersons: updatedContactErrors,
          },
        },
      };

      return updatedContactsWithPersonsRemoved;

    case UPDATE_SELECTED_CONTACT_FIELD:
      let updatedSelectedContactTabData = state.contactsPersons.data;
      updatedSelectedContactTabData[action.field] = action.value;

      const updatedSelectedContactTabDataWithSelection = {
        ...state,
        contactsPersons: {
          data: updatedSelectedContactTabData,
          errors: state.contactsPersons.errors,
        },
      };

      return updatedSelectedContactTabDataWithSelection;

    case UPDATE_SELECTED_CONTACT_TAG_EDIT:
      // todo: currently unused, update code after, confirmation!

      let updatedSelectedTagListContactTabData: ContactPersonsData =
        state.contactsPersons.data;

      updatedSelectedTagListContactTabData.selectedContactsTagList[
        action.index
      ].isSelected = action.tagIsSelected;

      // Open subform
      if (!updatedSelectedTagListContactTabData.contactSubformIsVisible) {
        updatedSelectedTagListContactTabData.contactSubformIsVisible = true;
      }

      // Update subform
      const newContactRowVal = {
        id: 2,
        fName: "Matt",
        lName: "Smith",
        email: "matt.smith@example.com",
        company: null,
        mobNumber: "+44123456789",
        phNumber: "+44123456789",
        gender: null,
        language: "ENG",
        linkedIn: "linkedin link",
        roles: [],
        functionTitle: "Actor",
        locations: [],
        info: "",
        isNew: false,
      };

      let subformContainsNewContactRow =
        updatedSelectedTagListContactTabData.contactsPersons.filter(function (
          newContactRow: ContactsPersons
        ) {
          return newContactRowVal.id === newContactRow.id;
        }).length;

      if (!subformContainsNewContactRow) {
        updatedSelectedTagListContactTabData.contactsPersons.unshift(
          newContactRowVal
        );
      }

      const updatedSelectedTagListContactTabInfo = {
        ...state,
        contactsPersons: {
          data: updatedSelectedTagListContactTabData,
          errors: state.contactsPersons.errors,
        },
      };
      return updatedSelectedTagListContactTabInfo;

    case UPDATE_CONTACT_SUBFORM_FIELD:
      let updatedSubformContactPersonData =
        state.contactsPersons.data.contactsPersons.map(
          (contact: any, index: any) => {
            if (index === action.index) {
              const updatedContact = {
                ...contact,
                [action.field]: action.value,
              };
              return updatedContact;
            }
            return contact;
          }
        );

      const updatedSubformContactPersonDataState = {
        ...state,
        contactsPersons: {
          data: {
            ...state.contactsPersons.data,
            selectedContactPersons:
              state.contactsPersons.data.selectedContactPersons,
            selectedContactsTagList:
              state.contactsPersons.data.selectedContactsTagList,
            contactSubformIsVisible:
              state.contactsPersons.data.contactSubformIsVisible,
            contactsPersons: updatedSubformContactPersonData,
          },
          errors: state.contactsPersons.errors,
        },
      };
      return updatedSubformContactPersonDataState;

    // Shift tab
    case SHIFT_UPDATE_FIELD:
      return {
        ...state,
        shifts: {
          data: action.value.data,
          errors: action.value.errors,
        },
      };

    case UPDATE_SHIFT_TIME:
      const shifts = state.shifts;
      const shiftTable = shifts.data.shiftTable;
      const singleShift = JSON.parse(
        JSON.stringify(shiftTable[action?.shiftName])
      );
      let day: any = singleShift[action.day];
      day[action.field] = action.value;
      if (
        !isValidTime(day?.from) ||
        !isValidTime(day?.till) ||
        (day?.from === "00:00" && day?.till === "00:00")
      ) {
        day = { ...day, pause: "00:00", total: "00:00" };
      }
      day["total"] = calculateTotalTime(day?.from, day?.till, day?.pause);
      day["total"] = calculateTotalTime(day?.from, day?.to, day?.pause);
      if (
        !isValidTime(day?.from) ||
        !isValidTime(day?.till) ||
        (day?.from === "00:00" && day?.till === "00:00")
      ) {
        day = { ...day, pause: "00:00", total: "00:00" };
      }
      console.log(day);
      singleShift[action.day] = day;
      shiftTable[action.shiftName] = singleShift;
      shifts.data.shiftTable = shiftTable;
      return { ...state, shifts };

    // Responsible persons tab
    case UPDATE_RESPONSIBLE_PERSON_FIELD:
      let updatedResponsiblePerson = state.responsiblePerson.data;
      updatedResponsiblePerson[action.field] = action.value;

      const updatedResponsiblePersonData = {
        ...state,
        responsiblePerson: {
          data: updatedResponsiblePerson,
          errors: state.responsiblePerson.errors,
        },
      };
      return updatedResponsiblePersonData;

    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };

    // General tab
    case SET_FIELD_GENERAL_TAB:
      let updatedGeneralTabData = state.general.data;
      updatedGeneralTabData[action.field] = action.value;

      const updatedGeneralTabWithData = {
        ...state,
        general: {
          data: updatedGeneralTabData,
          errors: state.general.errors,
        },
      };
      return updatedGeneralTabWithData;

    case SET_FIELD_ERROR_GENERAL_TAB:
      let updatedGeneralTabErrors = state.general.errors;
      updatedGeneralTabErrors[action.fieldName] = action.error;

      const updatedGeneralTabWithFieldError = {
        ...state,
        general: {
          data: state.general.data,
          errors: updatedGeneralTabErrors,
        },
      };
      return updatedGeneralTabWithFieldError;

    case SET_ERRORS_GENERAL_TAB:
      const updatedGeneralTabWithErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: action.errors,
        },
      };
      return updatedGeneralTabWithErrors;

    case SET_CREATE_LOCATION_GENERAL_TAB:
      let generalTabData = state.general.data;
      generalTabData.createLocation[action.field] = action.value;

      const updatedWithCreateLocationData = {
        ...state,
        general: {
          data: generalTabData,
          errors: state.general.errors,
        },
      };
      return updatedWithCreateLocationData;

    case SET_ERROR_SUBFIELD_GENERAL_TAB:
      let generalTabErrors = state.general.errors;
      generalTabErrors.createLocation[action.field] = action.error;

      const updatedWithCreateLocationErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: generalTabErrors,
        },
      };
      return updatedWithCreateLocationErrors;

    case UPDATE_BILLING_FIELD:
      const billing = state.billing;
      billing.data[action.field] = action.value;
      return { ...state, billing };

    // Overall tab related
    case UPDATE_TAB_DATA:
      const tabData = state[action.field];
      tabData.data = action.value;
      return { ...state, [action.field]: tabData };

    case "UPDATE_TEMPLATE_FIELD":
      const templateData = state.projectParameters.data;
      // templateData[action.field] = action.value;
      return {
        ...state,
        projectParameters: {
          data: {
            ...templateData,
            [action.field]: action.value,
          },
          errors: state.projectParameters.errors,
        },
      };

    /**
     * Project parameter state update
     */
    case "UPDATE_PROJECT_PARAMETERS":
      let parameterData = state.projectParameters.data;
      let section = parameterData.sections;
      section = {
        ...section,
        ...action.payload,
      };

      parameterData.sections = action.payload;
      return {
        ...state,
        projectParameters: {
          data: parameterData,
          errors: state.projectParameters.errors,
        },
      };

    /**
     * Project parameter field state update.
     */
    case "UPDATE_PARAMETER_FORM":
      const { fieldId, updatedValue } = action;
      let dependencyFields: any = {};

      const currentFormData =
        state.projectParameters.data.sections[action.section];

      if (Object.keys(OverTimeDependency).includes(fieldId.toString())) {
        dependencyFields[fieldId] = {
          ...currentFormData[fieldId],
          field_value: updatedValue,
        };
        for (let eachId of OverTimeDependency[fieldId.toString()]) {
          if (currentFormData[eachId].field_value == "1") {
            if (
              Object.keys(OverTimeFieldDependency).includes(eachId.toString())
            ) {
              for (let Id of OverTimeFieldDependency[eachId.toString()]) {
                dependencyFields[Id] = {
                  ...currentFormData[Id],
                  field_value: "00:00",
                };
              }
            }
            dependencyFields[eachId] = {
              ...currentFormData[eachId],
              field_value: "0",
            };
          }
          //
          if (
            Object.keys(OverTimeFieldDependency).includes(fieldId.toString())
          ) {
            for (let i of OverTimeFieldDependency[fieldId.toString()]) {
              dependencyFields[i] = {
                ...currentFormData[i],
                field_value:
                  updatedValue == "0"
                    ? "00:00"
                    : currentFormData[i].field_value,
              };
            }
          }
        }
        return {
          ...state,
          projectParameters: {
            ...state.projectParameters,
            data: {
              ...state.projectParameters.data,
              sections: {
                ...state.projectParameters.data.sections,
                [action.section]: {
                  ...state.projectParameters.data.sections[action.section],
                  ...dependencyFields,
                },
              },
            },
          },
        };
      } else if (
        Object.keys(OverTimeFieldDependency).includes(fieldId.toString())
      ) {
        for (let eachId of OverTimeFieldDependency[fieldId.toString()]) {
          dependencyFields[eachId] = {
            ...currentFormData[eachId],
            field_value:
              updatedValue == "0"
                ? "00:00"
                : currentFormData[eachId].field_value,
          };
        }
      }

      // Update the main field
      dependencyFields[fieldId] = {
        ...currentFormData[fieldId],
        field_value: updatedValue,
      };

      return {
        ...state,
        projectParameters: {
          ...state.projectParameters,
          data: {
            ...state.projectParameters.data,
            sections: {
              ...state.projectParameters.data.sections,
              [action.section]: {
                ...state.projectParameters.data.sections[action.section],
                ...dependencyFields,
              },
            },
          },
        },
      };

    // let param = state.projectParameters.data;
    // if (
    //   param.sections[action.section] &&
    //   param.sections[action.section][action.fieldId]
    // ) {
    //   param.sections[action.section][action.fieldId].field_value =
    //     action.updatedValue;
    // }
    // return { ...state };

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabs: action.tabs,
      };

    case UPDATE_FIELD_ERROR:
      //* used from https://git.infanion.com/i-projects/maxicon-frontend/-/commit/a8730988ddac2d576c298684a898659ed7456978
      const tabId = action.tab;
      const tabState = state[tabId];
      const updatedTabState = {
        ...tabState,
        errors: {
          ...tabState.errors,
          [action.fieldName]: action.error,
        },
      };
      return {
        ...state,
        [tabId]: updatedTabState,
      };

    case "UPDATE_TAB_ERROR":
      return {
        ...state,
        tabs: state.tabs.map((tab: any, index: any) => ({
          ...tab,
          error: index === action.tabIndex ? action.error : tab.error,
          draft: action.draft,
        })),
      };
    case PROJECT_OVERVIEW:
      let sequenceData = state.sequence;

      // adding the unsaved sequences to sequence array
      sequenceData.data.sequence = sequenceData.data.sequence.filter(
        (sequence: any) =>
          sequence.name.toLocaleLowerCase() !==
          sequenceData.data.edit?.toLocaleLowerCase()
      );
      if (sequenceData.data.sequenceName) {
        sequenceData.data.sequence.push({
          name: sequenceData.data.sequenceName,
          weeks: sequenceData.data.weeklyShifts,
        });
      }
      sequenceData.data.sequenceName = "";
      sequenceData.data.weeklyShifts = {};
      sequenceData.data.edit = "";

      return {
        ...state,
        sequence: sequenceData,
        projectOverview: action.payload.value,
      };

    case UPDATE_ERRORS_CONTACTPERSONS_TAB:
      const updatedContactPersonsTabWithErrors = {
        ...state,
        contactsPersons: {
          data: state.contactsPersons.data,
          errors: action.errors,
        },
      };

      return updatedContactPersonsTabWithErrors;

    case UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB:
      const updatedRespPersonsTabWithErrors = {
        ...state,
        responsiblePerson: {
          data: state.responsiblePerson.data,
          errors: action.errors,
        },
      };
      return updatedRespPersonsTabWithErrors;

    case UPDATE_ERRORS_BILLING_TAB:
      const updatedBillingTabWithErrors = {
        ...state,
        billing: {
          data: state.billing.data,
          errors: action.errors,
        },
      };
      return updatedBillingTabWithErrors;

    case UPDATE_ERRORS_PARAMETER_TAB:
      const updatedParameterTabWithErrors = {
        ...state,
        projectParameters: {
          data: state.projectParameters.data,
          errors: action.errors,
        },
      };
      return updatedParameterTabWithErrors;

    case UPDATE_ERRORS_SHIFT_TAB:
      const updatedShiftsTabWithErrors = {
        ...state,
        shifts: {
          data: state.shifts.data,
          errors: action.errors,
        },
      };
      return updatedShiftsTabWithErrors;

    case INITIAL_STATE:
      return { ...JSON.parse(JSON.stringify(action.payload)) };

    case SIGNATURE_IMAGE:
      return {
        ...state,
        signatureImage: action.payload.value,
      };

    /**
     * Handling the change of sequence name in sequence tab
     */
    case SET_SEQUENCE_NAME:
      let updatedSequenceTabData = state.sequence.data;
      updatedSequenceTabData[action.field] = action.value;

      const updatedSequenceTabWithData = {
        ...state,
        sequence: {
          data: updatedSequenceTabData,
          errors: state.sequence.errors,
        },
      };
      return updatedSequenceTabWithData;

    /**
     * Handling the change of weeks select tags
     */
    case SET_WEEKLY_SHIFTS:
      let shiftsData = state.sequence.data;

      if (action.value.value === "") {
        delete shiftsData[action.field][action.handle];
      } else {
        shiftsData[action.field] = {
          ...state.sequence.data.weeklyShifts,
          [action.handle]: { ...action.value },
        };
      }

      const updatedShiftsData = {
        ...state,
        sequence: {
          data: shiftsData,
          errors: state.sequence.errors,
        },
      };
      return updatedShiftsData;

    /**
     * Adding sequence
     */
    case ADD_ANOTHER_SEQUENCE:
      let addAnotherSequenceTabData = state.sequence.data;
      const newName = addAnotherSequenceTabData.sequenceName;

      if (newName) {
        // Adding the seuqence only if it is not empty
        addAnotherSequenceTabData.sequence =
          addAnotherSequenceTabData?.sequence?.filter(
            (sequence: any) =>
              sequence?.name?.toLocaleLowerCase() !==
              addAnotherSequenceTabData?.edit?.toLocaleLowerCase()
          );
        addAnotherSequenceTabData.sequence.push({
          name: newName,
          weeks: addAnotherSequenceTabData.weeklyShifts,
        });
        addAnotherSequenceTabData.sequenceName = "";
        addAnotherSequenceTabData.weeklyShifts = {};
        addAnotherSequenceTabData.edit = "";
      }

      const addAnotherData = {
        ...state,
        sequence: {
          data: addAnotherSequenceTabData,
          errors: {},
        },
      };
      return addAnotherData;

    /**
     * Handling the edit sequence, making the seuqence editable by prefilling in form
     */
    case EDIT_SEQUENCE:
      let editSequencedata = state.sequence.data;
      let sequence = state.sequence.data.sequence.find(
        (sequence: any) => sequence.name === action.handle
      );
      editSequencedata.sequenceName = sequence.name;
      editSequencedata.edit = action.handle;
      editSequencedata.weeklyShifts = sequence.weeks;

      return {
        ...state,
        sequence: {
          data: editSequencedata,
          errors: {},
        },
      };

    /**
     * Handling the delete sequence
     */
    case DELETE_SEQUENCE:
      let deleteSequencedata = state.sequence.data;
      deleteSequencedata.sequence = deleteSequencedata.sequence.filter(
        (sequence: any) => sequence.name !== action.handle
      );

      return {
        ...state,
        sequence: {
          data: deleteSequencedata,
          errors: {},
        },
      };

    /**
     * Updating the errors in sequence tab
     */
    case UPDATE_ERRORS_SEQUENCE_TAB:
      const updatedSequenceTabWithErrors = {
        ...state,
        sequence: {
          data: state.sequence.data,
          errors: action.errors,
        },
      };
      return updatedSequenceTabWithErrors;

    //Updating errors in project parameters tab
    case UPDATE_PARAMETERS_ERRORS:
      return {
        ...state,
        projectParameters: {
          ...state.projectParameters,
          data: {
            ...state.projectParameters.data,
            sections: {
              ...state.projectParameters.data.sections,
              [action.section]: {
                ...state.projectParameters.data.sections?.[action.section],
                [action.fieldId]: {
                  ...state.projectParameters.data.sections?.[action.section]?.[
                    action.fieldId
                  ],
                  field_error:
                    Object.keys(action.value).length > 0
                      ? action.value[action.fieldId]
                      : "",
                },
              },
            },
          },
        },
      };
    case "UPDATE_CONTACT_FIELD_ERROR": {
      const { fieldName, error, tab, index } = action;

      // Check if the tab is 'contactsPersons'
      if (tab === "contactsPersons") {
        return {
          ...state,
          contactsPersons: {
            ...state.contactsPersons,
            errors: {
              ...state.contactsPersons.errors,
              contactsPersons: state.contactsPersons.errors.contactsPersons.map(
                (person: any, i: any) =>
                  i === index ? { ...person, [fieldName]: error } : person
              ),
            },
          },
        };
      }

      // Return the original state if tab is not 'contactsPersons'
      return state;
    }
    case SET_CONTACT_PERSON_OPTION:
      const contactOptions = { ...state.options };

      // Check if the field already has an array, if not, initialize it as an empty array
      if (!Array.isArray(contactOptions[action.field])) {
        contactOptions[action.field] = [];
      }

      // Add new data to the existing array
      contactOptions[action.field] = [
        ...contactOptions[action.field],
        action.value,
      ];

      return { ...state, options: contactOptions };

    default:
      return state;
  }
};

export default ProjectReducer;
