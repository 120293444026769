import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_CAR, PATH_EDIT_CAR } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  CAR_DELETE,
  FLEET_EMP_DELETE,
  FLEET_EMP_CREATE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import FleetEmployeeModal from "../FleetEmployeeModal";
import { validateForm, validateRequired } from "services/validation/ValidationService";
import { IconData } from "services/IconData";

interface ManageCarProps {
  data?: any;
  fleetProps?: any;
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetVacany: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageCar: React.FC<ManageCarProps> = ({
  data,
  fleetProps,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    status: [],
    carType: [],
  });
  const { id: routeId } = useParams();
  const [showFleetEmployeeModal, setShowFleetEmployeeModal] = useState(false);
  const [fleetData, setFleetData] = useState({
    fromDate: '',
    toDate: '',
    type: "car",
    userId: routeId,
    fleetId: '',
    status: false,
    purchaseDate: '',
  })

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  /** Adding fleet material to employee */
  const handleAddFleetEmp = async () => {
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          FLEET_EMP_CREATE,
          "POST",
          fleetData,
          false,
          M_MASTER_DATA,
        );
        if (response.status == 200) {
          CustomNotify({ "type": "success", "message": response.message });
          setShowFleetEmployeeModal(false);
          handleGetVacany({
            page: 1,
            nOR: 10,
            type: 'car',
            userId: routeId ?? null,
            search: {
              company: "",
              businessUnit: "",
              brand: "",
              model: "",
              carType: '',
              licencePlate: "",
              driver: "",
              chassisNumber: "",
              contractStartDate: "",
              contractEndDate: "",
              fromDate: "",
              toDate: "",
              status: 1,
              contractNumber: "",
            },
          });
        }
        else {
          CustomNotify({ "type": "warning", "message": response.message });
        }
      }
      catch (error) {
        console.log("Error");
      }
    }
  }

  const handleFleetEmpModalClose = () => {
    setFleetData({
      fromDate: '',
      toDate: '',
      type: "car",
      userId: routeId,
      fleetId: '',
      status: false,
      purchaseDate: '',
    });
    setShowFleetEmployeeModal(false)
  };

  const handleRemoveCar = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_EMP_DELETE,
        "POST",
        { fleetId: fleetData.fleetId, userId: routeId, type: 'car' },
        false,
        M_MASTER_DATA
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response?.message });
        setShowFleetEmployeeModal(false);
        handleGetVacany({
          page: 1,
          nOR: 10,
          type: 'car',
          userId: routeId ?? null,
          search: {
            company: "",
            businessUnit: "",
            brand: "",
            model: "",
            carType: '',
            licencePlate: "",
            driver: "",
            chassisNumber: "",
            contractStartDate: "",
            contractEndDate: "",
            fromDate: "",
            toDate: "",
            status: 1,
            contractNumber: "",
          },
        });
      }
      else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
    catch (error) {
      console.log("Error");
    }
  }

  const handleLink = (id: number) => {
    setShowFleetEmployeeModal(true);
    setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
  }

  const handleRemove = (id: any) => {
    const filteredData = data.filter((eachItem: any) => (eachItem.status == 3 && eachItem.id == id));
    if (filteredData.length > 0) {
      const { fromDate, toDate, purchaseDate } = filteredData[0];
      setFleetData({ fromDate: fromDate, toDate: toDate, type: 'car', userId: routeId, fleetId: id, status: true, purchaseDate });
    }
    else {
      setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    }
    setShowFleetEmployeeModal(true);
  }

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        CAR_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetVacany({
          page: 1,
          nOR: 10,
          type: 'car',
          userId: routeId ?? null,
          search: {
            company: "",
            businessUnit: "",
            brand: "",
            model: "",
            carType: '',
            licencePlate: "",
            driver: "",
            chassisNumber: "",
            contractStartDate: "",
            contractEndDate: "",
            fromDate: "",
            toDate: "",
            status: "",
            contractNumber: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const [error, setErrors] = useState({
    fromDate: '',
    toDate: '',
  })

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      fromDate: [validateRequired],
      toDate: [validateRequired],
    }
    const validationErrors: any = validateForm(
      { ...fleetData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      }
      else {
        const fromDate = new Date(fleetData.fromDate);
        const lastDate = new Date(fleetData.toDate);
        const purchaseDate = new Date(fleetData.purchaseDate);
        if (fromDate > lastDate) {
          setErrors((prevState: any) => ({ ...prevState, fromDate: "From date should be on or before to date" }));
          return false;
        }
        if (fromDate < purchaseDate) {
          setErrors((prevState: any) => ({ ...prevState, fromDate: "From date should be on or after purchase date" }));
          return false;
        }
        return true;
      }
    }
  };


  useEffect(() => {

    getFilterData()
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState, businessUnit: filterData.data.businessUnit,
          status: filterData.data.status,
          carType: filterData.data.carType,
        }));
      }
      else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    }
    catch (error) {
      console.error(error)
    }

  }

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CAR}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Car",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="row header-sticky position-sticky">
            {fleetProps !== "fleet" ? (
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">{t("Manage car")}</h1>
              </div>
            ) : (<></>)}
          </div>
          {fleetProps !== "fleet" ? (<div className="row pb-4 search-bar">
            <FilterElementsRender
              data={searchData?.brand}
              searchclass="mb-4"
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "company", "text")
              }
              value={searchData?.company}
              placeHolder={"Company"}
              name="company"
            />

            <FilterElementsRender
              data={filterOptions.businessUnit}
              type="multi-select"
              handleChange={(event) =>
                handleSearchChange(event, "businessUnit", "select")
              }
              value={searchData?.businessUnit}
              placeHolder={"Business unit"}
              name="businessUnit"
            />
            <FilterElementsRender
              data={filterOptions.carType}
              type="multi-select"
              handleChange={(event) =>
                handleSearchChange(event, "carType", "select")
              }
              value={searchData?.carType}
              placeHolder={"Car type"}
              name="carType"
            />

            <FilterElementsRender
              data={searchData?.brand}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "brand", "text")
              }
              value={searchData?.brand}
              placeHolder={"Brand"}
              name="brand"
            />
            <FilterElementsRender
              data={searchData?.model}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "model", "text")
              }
              value={searchData?.model}
              placeHolder={"Model"}
              name="model"
            />

            <FilterElementsRender
              data={searchData?.licencePlate}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "licencePlate", "text")
              }
              value={searchData?.licencePlate}
              placeHolder={"Licence plate"}
              name="licencePlate"
            />
            <FilterElementsRender
              data={searchData?.driver}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "driver", "text")
              }
              value={searchData?.driver}
              placeHolder={"Driver"}
              name="driver"
            />
            <FilterElementsRender
              data={searchData?.chassisNumber}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "chassisNumber", "text")
              }
              value={searchData?.chassisNumber}
              placeHolder={"Chassis number"}
              name="chassisNumber"
            />
            <FilterElementsRender
              searchclass="mb-4"
              data={searchData?.contractNumber}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "contractNumber", "text")
              }
              value={searchData?.contractNumber}
              placeHolder={"Contract number"}
              name="contractNumber"
            />

            <FilterElementsRender
              data={searchData?.contractStartDate}
              type="date"
              handleChange={(event) =>
                handleSearchChange(event, "contractStartDate", "date")
              }
              value={searchData?.contractStartDate}
              placeHolder={"Contract start date"}
              name="contractStartDate"
            />
            <FilterElementsRender
              data={searchData?.contractEndDate}
              type="date"
              handleChange={(event) =>
                handleSearchChange(event, "contractEndDate", "date")
              }
              value={searchData?.contractEndDate}
              placeHolder={"Contract end date"}
              name="contractEndDate"
            />
            <FilterElementsRender
              data={searchData?.fromDate}
              type="date"
              handleChange={(event) =>
                handleSearchChange(event, "fromDate", "date")
              }
              value={searchData?.fromDate}
              placeHolder={"From date"}
              name="fromDate"
            />
            <FilterElementsRender
              data={searchData?.toDate}
              type="date"
              handleChange={(event) =>
                handleSearchChange(event, "toDate", "date")
              }
              value={searchData?.toDate}
              placeHolder={"To date"}
              name="toDate"
            />
            <FilterElementsRender
              data={filterOptions.status}
              type="multi-select"
              handleChange={(event) =>
                handleSearchChange(event, "status", "select")
              }
              value={searchData?.status}
              placeHolder={"Status"}
              name="status"
            />
            <div className="col mt-auto text-end">
              <Button
                title={IconData.ResetIcon}
                type="submit"
                handleClick={(e) => handleSubmit(e, true)}
                className="btn delete-btn  shadow-none text-uppercase me-3 "
              />
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="btn form-button  shadow-none text-uppercase px-3 search-btns"
              />
            </div>
          </div>) : (<></>)}
          <div className="p-5 border rounded-4 table-custom">
            {fleetProps !== "fleet" && (<div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Car",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={PATH_CREATE_CAR}
                  className="table-button"
                >
                  + {"Create car"}
                </Link>
              </AccessControl>
            </div>)}
            <div className="table-responsive manage-companies">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Company")}</th>
                    <th>{t("Business unit")}</th>
                    <th>{t("Brand")}</th>
                    <th>{t("Model ")}</th>
                    <th>{t("Car type")}</th>
                    <th>{t("Contract number")}</th>
                    <th>{t("Licence plate")}</th>
                    <th>{t("Chassis number")}</th>
                    <th>{t("Driver")}</th>
                    <th>{t("Contract start date ")}</th>
                    <th>{t("Contract end date ")}</th>
                    <th>{t("From")}</th>
                    <th>{t("To ")}</th>
                    <th>{t("Status")}</th>
                    <th className="table_actions">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Car",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                {/* if isStatus=false then enable all the options else give option for only status is 3 for other things disable that */}
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">{record.ownerCompany}</td>
                          {record.businessUnit && record.businessUnit.length > 0 ? (
                            <td className="align-middle">
                              {record.businessUnit
                                .map((fun: any) => fun.label)
                                .join(", ")}
                            </td>
                          ) : <td></td>}
                          <td className="align-middle">{record.brand}</td>
                          <td className="align-middle">{record.model}</td>
                          <td className="align-middle">{record.type?.label}</td>
                          <td className="align-middle">{record.contracNumber}</td>
                          <td className="align-middle">{record.licencePlate}</td>
                          <td className="align-middle">{record.chassisNumber}</td>
                          <td className="align-middle">{record.driver}</td>
                          <td className="align-middle">{record.startDate}</td>
                          <td className="align-middle">{record.endDate}</td>
                          <td className="align-middle">{record.guaranteeStarts}</td>
                          <td className="align-middle">{record.guaranteeEnds}</td>
                          <td className="align-middle">
                            <div
                              className={getStatusColor(record.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getTooltipMessage(record.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              {...(fleetProps !== 'fleet'
                                ? { handleEdit: handleEdit, handleArchive: handleDelete }
                                : (record.status == 1 ? { handleAddFleet: handleLink } : { handleRemove: handleRemove })
                              )}
                              value={record.id}
                              permission={"Car"}
                              isDisable={(record.isStatus == false) ? false : (record.status == 1 ? true : false)}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {showFleetEmployeeModal && (
          <FleetEmployeeModal
            handleClose={handleFleetEmpModalClose}
            data={fleetData}
            errors={error}
            setFleetData={setFleetData}
            handleSave={handleAddFleetEmp}
            handleRemove={handleRemoveCar}
            validationFn={validationFunction}
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageCar;
