import React from "react";

interface RightArrowProps {
  width?: string | number;
  height?: string | number;
}

const RightArrow: React.FC<RightArrowProps> = ({ width, height }) => {
  const defaultHeight = height !== undefined ? height : '16px';
  const defaultWidth = width !== undefined ? width : '10px';

  const PositionStyle: React.CSSProperties = {
    width,
    height
  };

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={defaultHeight}
        width={defaultWidth}
        viewBox="0 0 320 512"
        style={PositionStyle}
      >
        <path
          d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default RightArrow;
