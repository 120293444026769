import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";

import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import Calender from "components/molecules/Calender";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { Option } from "components/common/CommonInterfaces";
import {
  validateDateRange,
  validateFormField,
  validateTimeRange,
} from "../../../../services/validation/ValidationService";
import {
  SET_CREATE_LOCATION_GENERAL_TAB,
  SET_ERROR_SUBFIELD_GENERAL_TAB,
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_FIELD_GENERAL_TAB,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_SELECTED_CONTACT_FIELD,
} from "../context/Constants";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { validateDateIsAfter } from "services/validation/ValidationService";
import {
  GeneralTabSubformLocationRules,
  GeneralTabValidationRules,
} from "../validationRules/ProjectFormValidationRules";
import { GeneralTabData, GeneralTabErrors } from "../context/Interfaces";
import { FormMode } from "components/common/CommonEnums";
import LocationFormOrganism from "components/organism/location/LocationFormOrganism";
import {
  getProjectStatusColor,
  getStatusColor,
} from "services/util/UtilService";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
}

// Functional component using TypeScript
const General: React.FC<IProjectFormGeneralTabProps> = () => {
  const { state, dispatch } = useFormContext();

  // Init
  const selectOptions = state.options;
  const generalData: GeneralTabData = state.general.data;

  const generalErrors: GeneralTabErrors = state.general.errors;
  const createLocationFormIsVisible: boolean =
    generalData.createLocationIsVisible as boolean;

  // Helper functions
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target) {
      const { name, value, type, checked } = event.target as HTMLInputElement;

      if (type === "checkbox") {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: name,
          value: checked,
        });
      } else {
        if (name === "projectName") {
          const truncatedValue = value.slice(0, 6);
          dispatch({
            type: SET_FIELD_GENERAL_TAB,
            field: "projectCode",
            value: truncatedValue,
          });
        }

        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: name,
          value: value,
        });
      }

      let validatedFieldError = validateFormField(
        generalData,
        GeneralTabValidationRules,
        name,
        value
      );

      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: name,
        error: validatedFieldError,
      });
    }
  };

  const handleSelectChange = (
    value: Option | Option[],
    fieldName: string,
    isMultiSelect: boolean = false
  ) => {
    if (isMultiSelect) {
      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: fieldName,
        value: value as Option[],
      });
    } else {
      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: fieldName,
        value: value,
      });
      const fieldNames = [
        "timeSheet",
        "billing",
        "leave",
        "sickness",
        "holiday",
        "safety",
      ];

      fieldNames.forEach((fieldName) => {
        dispatch({
          type: UPDATE_SELECTED_CONTACT_FIELD,
          field: fieldName,
          value: [],
        });
      });
      if (fieldName === "company") {
        dispatch({
          type: UPDATE_CONTACT_SUBFORM_FIELD,
          field: "company",
          value: value,
          index: 0,
        });
      }
    }

    let validatedFieldError = validateFormField(
      generalData,
      GeneralTabValidationRules,
      fieldName,
      value
    );

    dispatch({
      type: SET_FIELD_ERROR_GENERAL_TAB,
      fieldName: fieldName,
      error: validatedFieldError,
    });
  };

  const handleDateChangeCheck = (value: Date | null, fieldName: string) => {
    const updatedDate = value ?? new Date();

    let startDate =
      fieldName === "startDate"
        ? updatedDate
        : generalData.startDate ?? new Date();
    let endDate =
      fieldName === "endDate" ? updatedDate : generalData.endDate ?? new Date();

    let validatedFieldErrorDateSequence = validateDateRange(startDate, endDate);

    // Update the field with the new date value
    dispatch({
      type: SET_FIELD_GENERAL_TAB,
      field: fieldName,
      value: updatedDate,
    });

    if (validatedFieldErrorDateSequence) {
      if (fieldName === "startDate" && startDate! > endDate!) {
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "startDate",
          error: validatedFieldErrorDateSequence,
        });
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "endDate",
          error: "",
        });
      } else if (fieldName === "endDate") {
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "endDate",
          error: validatedFieldErrorDateSequence,
        });
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "startDate",
          error: "",
        });
      }
    } else {
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: "startDate",
        error: "",
      });
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: "endDate",
        error: "",
      });
    }

    // Perform additional field-specific validation if any
    let validatedFieldError = validateFormField(
      generalData,
      GeneralTabValidationRules,
      fieldName,
      value
    );

    // If there's a field-specific validation error and it's not for the start or end date fields, dispatch it
    if (
      validatedFieldError &&
      fieldName !== "startDate" &&
      fieldName !== "endDate"
    ) {
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: fieldName,
        error: validatedFieldError,
      });
    }
  };

  const handleButtonClick = () => {
    dispatch({
      type: SET_FIELD_GENERAL_TAB,
      field: "createLocationIsVisible",
      value: !generalData.createLocationIsVisible,
    });
  };

  // Create location subform handling
  const handleCreateLocationSubformFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target) {
      const { name, value, type, checked } = event.target as HTMLInputElement;

      dispatch({
        type: SET_CREATE_LOCATION_GENERAL_TAB,
        field: name,
        value: type === "checked" ? checked : value,
      });

      let validatedFieldError = validateFormField(
        generalData.createLocation,
        GeneralTabSubformLocationRules,
        name,
        type === "checked" ? checked : value
      );

      dispatch({
        type: SET_ERROR_SUBFIELD_GENERAL_TAB,
        field: name,
        error: validatedFieldError,
      });
    }
  };

  const handleSelectChangeLocationSubform = (
    value: Option | Option[],
    fieldName: string,
    isMultiSelect: boolean = false
  ) => {
    // set value
    dispatch({
      type: SET_CREATE_LOCATION_GENERAL_TAB,
      field: fieldName,
      value: isMultiSelect ? (value as Option[]) : [value as Option],
    });

    // validate field
    let validatedFieldError = validateFormField(
      generalData.createLocation,
      GeneralTabSubformLocationRules,
      fieldName,
      value
    );

    dispatch({
      type: SET_ERROR_SUBFIELD_GENERAL_TAB,
      field: fieldName,
      error: validatedFieldError,
    });
  };
  const renderLocationCreateForm = () => {
    return createLocationFormIsVisible ? (
      <LocationFormOrganism
        formMode={state.mode}
        formOptions={{
          companyOptions: selectOptions.company,
          countryOptions: selectOptions.countries,
        }}
        formData={generalData.createLocation}
        company={generalData.company}
        tab={"general"}
        formErrors={generalErrors.createLocation}
        formErrorCompany={generalErrors}
        handleChange={handleCreateLocationSubformFieldChange}
        handleSelectChange={handleSelectChangeLocationSubform}
      />
    ) : null;
  };
  return (
    <div className="container-fluid ">
      <div className="row form-border p-5">
        {/* row 1 */}
        <div className="col-sm-12 col-md-6">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "company", false)}
            title={t("Company")}
            placeHolder={t("Select")}
            options={selectOptions.company}
            value={generalData.company}
            isDisabled={false}
            isMulti={false}
            isMandatory={true}
            error={generalErrors.company}
            name={"company"}
            id={"companyId"}
          ></SelectWithSearch>
        </div>
        <div className="col-sm-12 col-md-6">
          <SelectWithSearch
            onChange={(value) =>
              handleSelectChange(value, "businessUnitNumber", true)
            }
            title={t("Maxicon Business Unit")}
            placeHolder={t("Select")}
            search={true}
            options={selectOptions.businessUnit}
            value={generalData.businessUnitNumber}
            isDisabled={false}
            isMulti={true}
            isMandatory={true}
            error={generalErrors.businessUnitNumber}
            name={"businessUnitNumber"}
            id={"businessUnitNumberId"}
          ></SelectWithSearch>
        </div>

        <div className="col-sm-12 col-md-6">
          <LabelWithInputField
            type={"text"}
            handleChange={handleFieldChange}
            label={t("Project name")}
            value={generalData.projectName}
            readOnly={false}
            isDisabled={false}
            isMandatory={true}
            error={generalErrors.projectName}
            name={"projectName"}
            id={"projectNameId"}
          ></LabelWithInputField>
        </div>
        <div className="col-sm-12 col-md-6">
          <LabelWithInputField
            type={"text"}
            handleChange={handleFieldChange}
            label={t("Project code")}
            value={generalData.projectCode}
            readOnly={false}
            isDisabled={false}
            isMandatory={true}
            error={generalErrors.projectCode}
            name={"projectCode"}
            id={"projectCodeId"}
            maxLenght={6}
          ></LabelWithInputField>
        </div>

        {/* row 2 */}
        <div className="col-sm-12 col-md-6">
          <SelectWithSearch
            onChange={(value) =>
              handleSelectChange(value, "projectType", false)
            }
            title={t("Type of project")}
            placeHolder={t("Select")}
            options={selectOptions.projectTypes}
            value={generalData.projectType}
            search={true}
            isDisabled={false}
            isMulti={false}
            isMandatory={true}
            error={generalErrors.projectType}
            name={"projectType"}
            id={"projectTypeId"}
          ></SelectWithSearch>
        </div>

        {/* row 3 */}

        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6">
            <SelectWithSearch
              search={true}
              onChange={(value) => handleSelectChange(value, "locations", true)}
              title={t("Location(s)")}
              placeHolder={t("Select")}
              options={selectOptions.locations}
              value={generalData.locations}
              isDisabled={false}
              isMulti={true}
              isMandatory={true}
              error={generalErrors.locations}
              name={"locations"}
              id={"locationsId"}
            ></SelectWithSearch>
          </div>
          <div className="col-sm-12 col-md-2 d-flex">
            {!generalData.id && (
              <div className="row justify-content-center align-self-end">
                <Button
                  title=""
                  handleClick={handleButtonClick}
                  className="btn form-button text-uppercase mt-2 shadow-none align-self-end"
                  tooltip=""
                  disabled={false}
                  type="button"
                  icon={createLocationFormIsVisible ? faMinus : faPlus}
                />
              </div>
            )}
          </div>
          {/* </div> */}
        </div>

        {renderLocationCreateForm()}

        {/* row 4 */}

        <div className="col-sm-12 col-md-6 position-relative">
          <Calender
            onChange={(date) => handleDateChangeCheck(date, "startDate")}
            selectedDate={generalData.startDate}
            label={t("Start date")}
            isMandatory={true}
            name={"startDate"}
            error={generalErrors.startDate}
            isDisabled={false}
          ></Calender>
        </div>
        <div className="col-sm-12 col-md-6 position-relative">
          <Calender
            onChange={(date) => handleDateChangeCheck(date, "endDate")}
            selectedDate={generalData.endDate}
            label={t("End date")}
            isMandatory={false}
            name={"endDate"}
            error={generalErrors.endDate}
            isDisabled={false}
          ></Calender>
        </div>
        {/* row 5 */}

        <div className="col-sm-12 col-md-12">
          <LabelWithTextAreaField
            label={t("Info")}
            name={"info"}
            value={generalData.info}
            isDisabled={false}
            isMandatory={true}
            handleChange={handleFieldChange}
            error={generalErrors.info}
          ></LabelWithTextAreaField>
        </div>
        {/* row 6 */}

        <div className="col-sm-12 col-md-12">
          <CheckBoxField
            label={t("Active")}
            name={"isActive"}
            id={"isActiveId"}
            isChecked={
              getProjectStatusColor(generalData.isActive) === "success"
                ? true
                : false
            }
            onChangeHandler={handleFieldChange}
            disable={false}
          ></CheckBoxField>
        </div>
      </div>
    </div>
  );
};

export default General;
