import React, { useState } from "react";
import {
  useTimesheetModalContext,
  useTimesheetModalDispatch,
} from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import { useWeekAndDay } from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import { getWeekDays } from "../utils/utils";
import CommonServices from "services/CommonService";
import { calculateTotalForWholeWeek } from "../utils/calculateUtils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { useProjectParameterContext } from "../timesheets-redux/context/ProjectParameterContext/ProjectParameterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";

const PerformanceAndInvoice = (props) => {
  const { title, performanceAndInvoiceData, modalName, calculateAll } = props;
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const { initialWeekAndDayPropsState } = useWeekAndDay();
  const { filterOptionsState } = useFilterOptions()
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { initialProjectParameterState } = useProjectParameterContext();

  const handleModalDispatch = (modalStatus, index, planId, item = []) => {       
    const actualDate = new Date(initialWeekAndDayPropsState.currentDate);
    let weekDays = getWeekDays(actualDate);
    const date = new Date(weekDays[index]);
    const yy = String(date.getFullYear())
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yy}-${mm}-${dd}`;
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: !modalStatus,
        modalType: modalName,
        date: formattedDate,
        planId: planId,
        data: item ?? []
      },
    });
  };
  const isValid = () => (performanceAndInvoiceData && performanceAndInvoiceData?.length > 0);
  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className={`py-3 border ${(calculateAll && isValid()) ? 'position-relative' : ""}`} style={{ height: '100px' }}>
        {title}
        {(calculateAll && isValid) && (<span
          onClick={() => calculateTotalForWholeWeek({
            data: performanceAndInvoiceData, key: modalName, parameters: initialProjectParameterState,
            options: filterOptionsState?.state, dispatch: timesheetDispatch
          })}
          className="position-absolute top-0 end-0 m-1 cursor-pointer"
          title="Calculate for whole week"
        >
          <FontAwesomeIcon icon={faCalculator} />
        </span>)}
      </td>,
      <td key={"heading2"} className="border"></td>,
    ];    
    const dataToRender = isValid ? performanceAndInvoiceData : Array(7).fill({});    
    dataToRender?.map((item, i) => {     
      weekDays.push(
        item?.plan_id ? (
          <td
            key={i}
            className="border p-2"
          >
            {(item?.[modalName] && Object?.values(item?.[modalName])?.length > 0) ? (
              <div>
                {Object?.values(item?.[modalName])?.map((value, index) => (                                  
                  <div className="timesheethovermain cursor-pointer mb-2 p-2 text-white rounded" key={index} onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id, value)}>
                    {
                      props?.values?.map((val) => (
                        val?.options && <div className="">{val?.options?.find((opt) => value?.[val?.key] == opt?.value)?.label}</div>
                      ))
                    }
                    <div className="timesheethoverchild cursor-pointer">
                      {
                        props?.values?.map((val) => (
                          val?.options
                            ?(<div className="d-flex justify-content-between w-100">
                                <span>{CommonServices?.capitalizeLabel(val?.key) + ": "}</span>
                                <span>{val?.options?.find((opt) => value?.[val?.key] == opt?.value)?.label}</span>
                              </div>)
                            :
                              (<div className="d-flex justify-content-between w-100">
                                <span>{CommonServices?.capitalizeLabel(val?.key) + ": "} </span>
                                <span>{value[val?.key] ?? ""}</span>
                              </div>)
                        ))
                      }
                    </div>
                  </div>
                ))}
                <div title="Add new" className="my-2 cursor-pointer" onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id)}>
                  <div className="border bg-white">+</div>
                </div>
              </div>
            ) : (
              <div title="Add new" className="my-2 cursor-pointer" onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id)}>
                <div className="border bg-white">+</div>
              </div>
            )}
          </td>
        ) : (
          <td key={i} className="border"></td>
        )
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItems()}
      <td></td>
    </tr>
  );
};
export default PerformanceAndInvoice;
