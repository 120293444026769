import { getRefreshToken } from "routes/ApiEndpoints";
import EncryptDecryptService from "./EncryptDecryptService";

export async function GenerateRefreshToken(): Promise<any> {
  const body: any = {
    api_key: process.env.REACT_APP_SERVICE_API,
  };
  const tokenData: any = localStorage.getItem("token");

  if (tokenData) {
    const decryptedToken = JSON.parse(
      EncryptDecryptService.decryptData(tokenData)
    );
    const utcTimestamp = decryptedToken?.data?.expiry;
    const utcDate = new Date(utcTimestamp);
    const currentDate = new Date();
    if (!tokenData || currentDate > utcDate) {
      localStorage.setItem("token", "");
      const newToken = await getNewToken(body);

      return newToken;
    } else {
      return decryptedToken?.data?.token as string;
    }
  } else {
    const newToken = await getNewToken(body);
    return newToken;
  }
}

const getNewToken = async (body: any) => {
  const response = await fetch(getRefreshToken + "/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  const encryptedResponse = EncryptDecryptService.encryptData(
    JSON.stringify({ data })
  );

  if (data.token !== "" || data.token != null) {
    const token = data.token;
    localStorage.setItem("token", encryptedResponse);
    return token;
  }
};
