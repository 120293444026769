import React, { useState, useEffect } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import { ApiCall } from "services/ApiServices";
import "static/css/email-template.css";
import Button from "components/atoms/Button";
import { GET_TEMPLATES } from "routes/ApiEndpoints";
import { t } from "../translation/Translation";
import { M_MASTER_DATA } from "../../../../constants/Constants";
import {
  PATH_EMAIL_TEMPLATE_CREATE,
  PATH_EMAIL_TEMPLATE_EDIT,
  PATH_EMAIL_TEMPLATE_VIEW,
  PATH_MASTER_DATA,
} from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const ITEMS_PER_PAGE = 10;

interface EmailTemplate {
  id: number;
  name: string;
  module_name: string;
}

const ManageEmailTemplatesPage: React.FC = () => {
  const [searchData, setSearchData] = useState({
    name: "",
  });

  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]); // State to hold the fetched email templates
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAllEmailTemplates();
  }, []);

  const fetchAllEmailTemplates = async () => {
    //api call to get all the templates created
    try {
      const response = await ApiCall.getService(
        GET_TEMPLATES,
        "GET",
        M_MASTER_DATA
      );
      if (response.status === 200) {
        setEmailTemplates(response.data); // Update the state with fetched email templates
      }
    } catch (error) {
      // Handle API error
      console.error("Error fetching email templates:", error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    setCurrentPage(1);
  };

  const filteredEmailTemplates = emailTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchData.name.toLowerCase())
  );

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = filteredEmailTemplates.slice(startIndex, endIndex);
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Email template"
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <div className="row header-sticky position-sticky">
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">
                  {t("Manage email templates")}
                </h1>
              </div>
            </div>

            <div className="row pb-4 search-bar">
              <div className="col-8">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Search by name...")}
                  name="name"
                  value={searchData.name}
                />
              </div>
            </div>
            <div className="p-5 border rounded-4 table-custom">
              <div className="table-top-buttons-block">
                <AccessControl
                  requiredPermissions={[{
                    permission: "Email template",
                    create: true,
                  }]}
                  override={userData.isSuperAdmin}
                >
                  <Link
                    to={PATH_EMAIL_TEMPLATE_CREATE}
                    className="table-button"
                  >
                    + {t("Create email Template")}
                  </Link>
                </AccessControl>
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Name")}</th>
                    <th>{t("Module name")}</th>
                    <th className="table_actions">
                      <AccessControl
                        requiredPermissions={[{
                          permission: "Email template",
                          update: true,
                          read: true
                        }]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.length > 0 ? (
                    paginatedData.map((template, index) => (
                      <tr
                        key={template.id}
                        className="border mb-3 box-shadow align-middle"
                      >

                        <td className="text-break" data-label="Name">
                          {template.name}
                        </td>
                        <td className="text-break" data-label="Module name">
                          {template.module_name}
                        </td>
                        <td className="table-action-icons px-2">
                          <div className="d-none d-md-none d-lg-block">
                            <AccessControl
                              requiredPermissions={[{
                                permission: "Email template",
                                update: true
                              }]}
                              override={userData.isSuperAdmin}
                            >
                              <Button
                                title={<EditIcon />}
                                className={"table-action-btn"}
                                handleClick={() => navigate(`${PATH_EMAIL_TEMPLATE_EDIT + "/" + template.id}`)}
                                tooltip={t("Edit")}
                              />
                            </AccessControl>
                            <AccessControl
                              requiredPermissions={[{
                                permission: "Email template",
                                read: true
                              }]}
                              override={userData.isSuperAdmin}
                            >
                              <Button
                                title={<ViewIcon />}
                                className={"table-action-btn"}
                                handleClick={() => navigate(`${PATH_EMAIL_TEMPLATE_VIEW}/${template.id}`)}
                                tooltip={t("View")}
                              />
                            </AccessControl>
                          </div>
                          <div className="d-block d-md-block d-lg-none">
                            <div className="mb-2">
                              <AccessControl
                                requiredPermissions={[{
                                  permission: "Email template",
                                  update: true
                                }]}
                                override={userData.isSuperAdmin}
                              >
                                <Link
                                  to={`${PATH_EMAIL_TEMPLATE_EDIT}/${template.id}`}
                                  className="btn p-0 border-0 me-2 w-100"
                                  title={t("Edit")}
                                >
                                  <Button
                                    title="Edit"
                                    className="text-uppercase mb-2 button-width edit-btn"
                                  />
                                </Link>
                              </AccessControl>
                            </div>
                            <div className="mb-2">
                              <AccessControl
                                requiredPermissions={[{
                                  permission: "Email template",
                                  read: true
                                }]}
                                override={userData.isSuperAdmin}
                              >
                                <Link
                                  to={`${PATH_EMAIL_TEMPLATE_VIEW}/${template.id}`}
                                  className="btn p-0 border-0 me-2 w-100"
                                  title={t("View")}
                                >
                                  <Button
                                    title="View"
                                    className="text-uppercase mb-2 button-width view-btn"
                                  />
                                </Link>
                              </AccessControl>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="border">
                      <td colSpan={4} className="border-0 text-center py-3">
                        <span className="text-danger fw-bold">
                          {t("No records")}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination justify-content-center align-items-center my-3">
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(
                    filteredEmailTemplates.length / ITEMS_PER_PAGE
                  )}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center my-4">
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
      </>
    </AccessControl>
  );
};

export default ManageEmailTemplatesPage;
